import { ClientType } from '~/logic/contexts/client/domain/enums/clientType.enum'
import { Organization } from '~/logic/contexts/client/domain/entity/organization.entity'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'

export const useOpenClientFormSidePanel = () => {
  const clientStore = useClientStore()

  return ({ type, organization }: {type: ClientType, organization?: Organization}) => {
    if (type === ClientType.CONTACT_ORGANIZATION) {
      clientStore.selectedOrganizationContactOrganization.value = organization
    }
    clientStore.toggleClientFormSidePanel(true, type)
  }
}
