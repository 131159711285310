import {
  ClientAddress,
  BillingLocale,
  CurrencyCode,
  BankInformations,
} from '@abby/shared'
import { OrganizationAction } from '~/logic/contexts/client/domain/enums/organizationAction.enum'
import { ContactItem } from '~/logic/contexts/client/domain/entity/contactItem.entity'

export type OrganizationProps = {
  id: string
  customerId?: string,
  name: string
  commercialName?: string
  siret?: string
  vatNumber?: string
  countContacts: number
  hasBillings: boolean
  test: boolean
  billingAddress?: ClientAddress
  deliveryAddress?: ClientAddress
  notes?: string
  language?: BillingLocale,
  currency?: CurrencyCode,
  paymentMethods?: string[],
  createdAt: Date
  archivedAt?: Date
  associatedContacts?: ContactItem[]
  bankInformations?: BankInformations
}

export class Organization {
  readonly id: string
  customerId?: string
  readonly name: string
  readonly commercialName?: string
  readonly siret?: string
  readonly vatNumber?: string
  readonly countContacts: number
  readonly hasBillings: boolean
  readonly test: boolean
  readonly billingAddress?: ClientAddress
  readonly deliveryAddress?: ClientAddress
  readonly language?: BillingLocale
  readonly currency?: CurrencyCode
  readonly paymentMethods?: string[]
  readonly createdAt: Date
  readonly archivedAt?: Date
  readonly associatedContacts?: ContactItem[]
  notes?: string // pas readonly pour pouvoir refresh les notes

  readonly bankInformations?: {
    iban: string;
    bic: string;
    holder: string;
  }

  actions: OrganizationAction[] = [];

  private constructor (props: OrganizationProps) {
    this.id = props.id
    this.customerId = props.customerId
    this.name = props.name
    this.commercialName = props.commercialName
    this.siret = props.siret
    this.vatNumber = props.vatNumber
    this.countContacts = props.countContacts
    this.hasBillings = props.hasBillings
    this.test = props.test
    this.billingAddress = props.billingAddress
    this.deliveryAddress = props.deliveryAddress
    this.notes = props.notes
    this.language = props.language
    this.currency = props.currency
    this.paymentMethods = props.paymentMethods
    this.createdAt = props.createdAt
    this.archivedAt = props.archivedAt
    this.associatedContacts = props.associatedContacts
    this.bankInformations = props.bankInformations

    this.computeActions()
  }

  public static create (props: OrganizationProps): Organization {
    return new Organization(props)
  }

  computeActions () {
    this.actions.push(
      OrganizationAction.EDIT,
      OrganizationAction.ARCHIVE,
      OrganizationAction.UNARCHIVE,
      OrganizationAction.DELETE,
      OrganizationAction.VIEW_DETAILS,
      OrganizationAction.ADMIN_COPY_ORGANIZATION_ID,
      OrganizationAction.CREATE_INVOICE,
      OrganizationAction.CREATE_ESTIMATE,
      OrganizationAction.CREATE_ASSET,
      OrganizationAction.CREATE_PURCHASE_ORDER,
      OrganizationAction.GO_TO_CLIENT_PORTAL,
      OrganizationAction.EDIT_NOTES,
    )
  }
}
