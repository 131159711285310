import type { CreateCompanyApiKey, ReadCompanyApiKey } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class ApiKey extends Resource {
  createCompanyApiKey (payload: CreateCompanyApiKey): Promise<{ result: ReadCompanyApiKey, secret: string }> {
    return this.axios.$post('/apiKey/company', payload)
  }

  getAllCompanyApiKeys (): Promise<ReadCompanyApiKey[]> {
    return this.axios.$get('/apiKey/company/all')
  }

  removeCompanyApiKey (id: string) {
    return this.axios.$delete(`/apiKey/company/${id}`)
  }
}
