import { PlanFeature } from '@abby/core-legacy'
import { PersonalServiceActivity } from '@abby/shared'
import { useProductRepository } from '~/logic/contexts/product/infrastructure/repository/product.repository'
import { ProductForm } from '~/logic/contexts/product/domain/form/product.form'
import { useToasterManager } from '~/composables/shared/manager/useToasterManager'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'

export const useCreateProductPSA = () => {
  const productRepository = useProductRepository()
  const planManager = usePlanManager()
  const toasterManager = useToasterManager()

  const createProduct = async (product: ProductForm) => {
    const newProduct = await productRepository.createProductPersonalServiceActivity({
      ...product,
      personalServiceActivity: product.personalServiceActivity || PersonalServiceActivity.AIDE_HUMAINE, // uniquement à but de typage
    })
    toasterManager.success({ message: 'Le produit a bien été créé' })
    return newProduct
  }

  const handleError = (error: any) => {
    toasterManager.autoError(error)
    if (error?.response?.data?.message === 'catalog.product.too_many_products') {
      planManager.openPlansModal(PlanFeature.CREATE_PRODUCT)
    }
  }

  return async (product: ProductForm) => {
    try {
      return await createProduct(product)
    } catch (e) {
      handleError(e)
    }
  }
}
