export enum Code {
  internalServerError = 'internal_server_error',
  authenticationFailure = 'authentication_credentials_failure',
  notAuthorized = 'not_authorized',
  tokenFailure = 'token_failure',
  validationError = 'validation_error',
  notFound = 'not_found',
  badRequest = 'bad_request',
  sponsorship = 'sponsorship',
  disposableEmail = 'disposable_email',
  forbidden = 'forbidden',
  urssafTp = 'urssaf_tp',
  urssafTdae = 'urssaf_tdae',
  billing = 'billing',
  customer = 'customer',
  provider = 'provider',
  product = 'product',
  bank = 'bank',
  purchaseRegister = 'purchase_register',
  incomeBook = 'income_book',
  entryManager = 'entry_manager',
  declaration = 'declaration',
  taxReturnDocument = 'tax_return_document',
  quotaReached = 'quota_reached',
  maintenanceError = 'maintenance_error',
  resourceGone = 'resource_gone',
}