import { PlanFeature } from '@abby/core-legacy'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'

export const useOpenBillingReminderModal = () => {
  const planManager = usePlanManager()
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    if (!planManager.hasAccessToFeature(PlanFeature.BILLING_REMINDER)) {
      planManager.openPlansModal(PlanFeature.BILLING_REMINDER)
      return
    }
    await billingStore.openBillingReminderModal(billing)
  }
}
