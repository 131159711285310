import {
  ProductType, ProductUnit,
  PersonalServiceActivities,
  Vat,
  Money,
  CurrencyCode,
  CatalogOrderBy,
  OrderDirection,
  VatCode,
} from '@abby/shared'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { useContext } from '@nuxtjs/composition-api'
import {
  sdk,
  ReadProductListItemDto,
  UpdatePersonalServiceActivityDto,
  UpdateProductDto,
  CreateProductDto,
  CreatePersonalServiceActivityDto,
} from '@abby/sdk'

import { Ref } from 'vue'
import { ProductItem } from '~/logic/contexts/product/domain/entity/productItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export type ProductFilterQuery = {
  search: Ref<string | undefined>,
}

export type ProductPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
  orderBy: Ref<CatalogOrderBy>,
  orderDirection: Ref<OrderDirection>,
} & ProductFilterQuery

export const useProductRepository = () => {
  const queryClient = useQueryClient()
  const alertManager = useAlertManager()
  const { i18n } = useContext()

  const productItemMap = (doc: ReadProductListItemDto) => {
    const firstMajLetter = (word: string) => {
      return word.length > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    }

    return ProductItem.create({
      id: doc.id,
      designation: doc.designation,
      description: doc.description || null,
      reference: doc.reference || null,
      personalServiceActivity: PersonalServiceActivities.find(item => item.value === doc.personalServiceActivity)?.label || null,
      typeEnum: doc.type as ProductType,
      unitEnum: doc.unit as ProductUnit,
      type: i18n.t(`productTypes.${doc.type.toUpperCase()}`) as string,
      unit: firstMajLetter(i18n.t(`productUnits.${doc.unit.toUpperCase()}`) as string),
      vatText: Vat.getVatItem(doc.vatCode as VatCode)?.label || null,
      isDeliveryOfGood: doc.isDeliveryOfGood,
      unitPrice: Money.create({ amountInCents: doc.unitPrice }).format(CurrencyCode.EUR, { keepExraDecimals: true }),
      priceWithTaxes: Money.create({ amountInCents: doc.priceWithTaxes }).format(),
      priceWithoutTaxes: Money.create({ amountInCents: doc.priceWithoutTaxes }).format(),
      vatAmount: doc.vatAmount,
      taxesIncluded: doc.taxesIncluded,
      stock: doc.stock,
    })
  }

  const paginate = ({ page, limit, search, orderBy, orderDirection }: ProductPaginateQuery) => {
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['product', { page, limit, search, orderBy, orderDirection }],
      queryFn: async ({ signal }) => {
        const { data } = await sdk.Catalog.paginate({
          signal,
          query: {
            page: page.value,
            search: search.value?.length ? search.value : undefined,
            limit: limit.value,
            orderBy: orderBy?.value,
            orderDirection: orderDirection?.value,
          },
        })
        return {
          countWithoutFilters: data?.countWithoutFilters || 0,
          totalDocs: data?.totalDocs || 0,
          limit: data?.limit || 0,
          totalPages: data?.totalPages || 0,
          hasNextPage: data?.hasNextPage || false,
          hasPrevPage: data?.hasPrevPage || false,
          nextPage: data?.nextPage || 0,
          page: data?.page || 0,
          prevPage: data?.prevPage || 0,
          docs: data?.docs.map(productItemMap) || [],
        }
      },
      onError: (error) => {
        alertManager.autoError(error)
      },
      keepPreviousData: true,
    })
  }

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries(['product'])
  }

  const getProductById = async (productId: string) => {
    const { data } = await sdk.Catalog.retrieve({
      path: {
        productId,
      },
    })
    return data
  }

  const { mutateAsync: createProduct } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (newProduct: CreateProductDto) => sdk.Catalog.create({
      body: newProduct,
    }),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: updateProduct } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (productToUpdate: UpdateProductDto & { id: string }) => {
      const { id, ...body } = productToUpdate
      return sdk.Catalog.update({
        body,
        path: {
          productId: id,
        },
      })
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: createProductPersonalServiceActivity } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (newProduct: CreatePersonalServiceActivityDto) => {
      return sdk.Catalog.createProductPersonalServiceActivity({
        body: newProduct,
      })
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: updateProductPersonalServiceActivity } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (productToUpdate: UpdatePersonalServiceActivityDto & { id: string }) => {
      const { id, ...body } = productToUpdate
      return sdk.Catalog.updatePersonalServiceActivity({
        path: {
          productId: id,
        },
        body,
      })
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const removeProduct = async (id: string) => {
    await sdk.Catalog.remove({
      path: {
        productId: id,
      },
    })
    await refreshPaginate()
  }

  return {
    paginate,
    refreshPaginate,
    getProductById,
    createProduct,
    updateProduct,
    createProductPersonalServiceActivity,
    updateProductPersonalServiceActivity,
    removeProduct,
  }
}
