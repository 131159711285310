/*
   * Core Legacy
   * {@link https://abby.fr}
   * @copyright Abby
   * @license All rights reserved
   */import{PaymentRequestStatusUrssafTp as e}from"./PaymentRequestStatusUrssafTp.js";const t=[{code:e.ERR_DATE_FUTUR,text:"Erreur - Date future",detail:"La date est dans le futur<br>Info compl\xE9mentaire non transmise par API:<br>Erreur si la p\xE9riode d\u2019emploi de la Demande de Paiement est dans le futur"},{code:e.ERR_DATE_PAIEMENT_AVANT_EXP,text:"Erreur - Date paiement ant\xE9rieure \xE0 l'exp\xE9rimentation",detail:`La date de paiement est ant\xE9rieure \xE0 la date de lancement de l\u2019exp\xE9rimentation
Info compl\xE9mentaire non transmise par API:
Erreur si la p\xE9riode d\u2019emploi de la Demande de Paiement est ant\xE9rieure \xE0 la date de lancement de l\u2019exp\xE9rimentation`},{code:e.ERR_PERIODE_EMPLOI_MOIS_NON_UNIQUE,text:"Erreur - P\xE9riode d'emploi mois non unique",detail:"La p\xE9riode d\u2019emploi de la demande de paiement est sur deux mois calendaires"},{code:e.ERR_DATE_FIN_AVANT_DATE_DEB,text:"Erreur - Date fin avat date d\xE9but",detail:"La date de fin de p\xE9riode est inf\xE9rieure \xE0 la date de d\xE9but de p\xE9riode d\u2019emploi"},{code:e.ERR_MONTANT_ACOMPTE,text:"Erreur - Montant acompte",detail:"Le montant de l\u2019acompte sup\xE9rieur au montant total de la facture"},{code:e.ERR_TOTAL_PRESTATIONS,text:"Erreur - Montant total prestations",detail:`Le montant total des listes de prestation est diff\xE9rent du montant total de la facture
Info compl\xE9mentaire non transmise par API:
Erreur si le montant de la somme des listes de prestation est diff\xE9rent (modulo une tol\xE9rance li\xE9e aux arrondis) du montant total de la facture`},{code:e.ERR_CODE_NATURE,text:"Erreur - Code nature inconnu",detail:`Code nature inexistant
Info compl\xE9mentaire non transmise par API:
Le code nature doit \xEAtre connu du r\xE9f\xE9rentiel des natures`},{code:e.ERR_CODE_ACTIVITE,text:"Erreur - Code activit\xE9 inconnu",detail:`Code activit\xE9 inexistant
Info compl\xE9mentaire non transmise par API:
Le code activit\xE9 doit \xEAtre connu du r\xE9f\xE9rentiel des activit\xE9s`},{code:e.ERR_CODE_ACTIVITE_NATURE,text:"Erreur - Code activit\xE9 nature",detail:"Le code activit\xE9 n\u2019est pas rattach\xE9 \xE0 cette nature"},{code:e.ERR_MNT_PREST_HT_TVA,text:"Erreur - Montant prestation HT / TVA pas correct",detail:`Le montant prestation HT ou TVA n\u2019est pas correct
Info compl\xE9mentaire non transmise par API:
Le montant prestation HT + montant prestation TVA =
montant prestation TTC (modulo une tol\xE9rance li\xE9e aux
arrondis)`},{code:e.ERR_MNT_PREST_TTC,text:"Erreur - Montant TTC pas \xE9gal",detail:`Le montant prestation TTC n\u2019est pas \xE9gal \xE0 la quantit\xE9 * montant unitaire
Info compl\xE9mentaire non transmise par API:
Contr\xF4ler le montant de prestation TTC = quantit\xE9 * montant unitaire (modulo une tol\xE9rance li\xE9e aux arrondis)`},{code:e.ERR_NBRE_PREST_MAX,text:"Erreur - Nombre max prestations",detail:`Le nombre maximum de transmission de factures a \xE9t\xE9 d\xE9pass\xE9
Info compl\xE9mentaire non transmise par API:
Ne dois pas d\xE9passer la valeur max d\xE9finie par param\xE8tre. (intialis\xE9 \xE0 10)`},{code:e.ERR_VALEUR_NEGATIVE,text:"La valeur d'un champ est n\xE9gative",detail:"La valeur d'un champ est n\xE9gative alors qu'elle doit \xEAtre positive"},{code:e.ERR_PARTICULIER_INCONNU,text:"Erreur - Particulier inconnu",detail:`L'identifiant du particulier n\u2019est pas reconnu ou la date de
naissance ne correspond pas \xE0 celle connue`},{code:e.ERR_PRESTATAIRE_EMETTEUR_INCONNU,text:"Erreur - Tiers de prestation inconnu",detail:`Seconde v\xE9rification de l\u2019identifiant du tiers de prestation
qui doit \xEAte connu et actif. `},{code:e.ERR_LIEN_PARTICULIER_PRESTATAIRE,text:"Erreur - Lien particulier / prestataire inconnu",detail:`Il n\u2019existe aucun lien entre le particulier et le prestataire sur la p\xE9riode d\u2019emploi de la demande de paiement
Info compl\xE9mentaire non transmise par API:
Veuillez v\xE9rifier que le particulier a bien activ\xE9 son compte
et que la date/heure de d\xE9but de la p\xE9riode d'emploi est
bien post\xE9rieure ou \xE9gale \xE0 la date/heure d'activation de
l'inscription du particulier`},{code:e.ERR_PRESTATAIRE_FACTURATION_INCONNU,text:"Erreur - Tiers de prestation factur\xE9 inconnu",detail:"L\u2019identifiant du tiers de prestation factur\xE9 doit \xEAtre connu de l\u2019annuaire (et \xEAtre actif)"},{code:e.ERR_FACTURE_DOUBLON,text:"Erreur - R\xE9f\xE9rence de facture d\xE9j\xE0 existante",detail:`Si le num\xE9ro de facture du prestataire a d\xE9j\xE0 une demande
de paiement enregistr\xE9e, la nouvelle demande de
paiement sera rejet\xE9e.`},{code:e.ERR_CRITERE_RECHERCHE_VIDE,text:"Erreur - Aucun crit\xE8re de recherche renseign\xE9.",detail:"Un crit\xE8re de recherche doit \xEAtre renseign\xE9 "},{code:e.ERR_DGFIP_R2P_INCONNU,text:"Erreur - Identification DGFIP non abouti",detail:`Aucun SPI ne correspond \xE0 la personne apr\xE8s l'appel \xE0 l'API
DGFiP R2P, v\xE9rifier l\u2019\xE9tat civil communiqu\xE9`},{code:e.ERR_DATE_NAISSANCE_FUTUR,text:"Erreur - Date de naissance dans le futur",detail:"La date de naissance est dans le futur"},{code:e.ERR_LIEU_NAISSANCE_DEPT,text:"Erreur - D\xE9partement non renseign\xE9",detail:`Le Code INSEE du d\xE9partement de naissance est
obligatoire quand le code Pays de naissance correspond \xE0
celui de la France.`},{code:e.ERR_LIEU_NAISSANCE_COMN,text:"Erreur - Commune non renseign\xE9e",detail:`Le Code INSEE de la commune de naissance est obligatoire
quand le code Pays de naissance correspond \xE0 celui de la France`},{code:e.ERR_LIEU_NAISSANCE_PAYS,text:"Erreur - D\xE9partement / commune renseign\xE9",detail:`Le Code INSEE de la commune de naissance ou du
d\xE9partement ne doivent pas \xEAtre renseign\xE9 quand le code
Pays n'est pas la France`},{code:e.ERR_DGFIP_IMPOTPART_INCONNU,text:"Erreur - Aucune p\xE9riode tax\xE9e",detail:`Aucune p\xE9riode tax\xE9e n\u2019est retourn\xE9e pour le particulier
apr\xE8s 'appel \xE0 l'API DGFiP Imp\xF4tPart. Le particulier ne peut
pas avoir acc\xE8s \xE0 l\u2019avance imm\xE9diate de cr\xE9dit d\u2019imp\xF4t
pour la p\xE9riode. R\xE9essayer apr\xE8s le 1er ao\xFBt de la m\xEAme
ann\xE9e ou apr\xE8s le 1er janvier de l\u2019ann\xE9e suivante.`},{code:e.ERR_CONTACT,text:"Erreur - Contact",detail:`Des informations concernant le t\xE9l\xE9phone, mail ou adresse
ne sont pas compl\xE8tes ou invalides.`},{code:e.ERR_CONTACT_ADRESSE_TYPE_VOIE,text:"Erreur - Type de voie contact introuvable",detail:"Le type de voie renseign\xE9 est introuvable."},{code:e.ERR_CONTACT_ADRESSE_LETTRE_VOIE,text:"Erreur - Lettre de voie contact introuvable",detail:"La Lettre de voie renseign\xE9e est introuvable."},{code:e.ERR_CONTACT_ADRESSE_TYPEVOIE,text:"Erreur - Type de voie contact introuvable",detail:"Un type de voie doit \xEAtre s\xE9lectionn\xE9 si un libell\xE9 est renseign\xE9."},{code:e.ERR_CONTACT_ADRESSE_LIBELLEVOIE,text:"Erreur - Libell\xE9 voie contact introuvable",detail:"Un libell\xE9 doit \xEAtre renseign\xE9 si un type de voie est s\xE9lectionn\xE9."},{code:e.ERR_CONTACT_ADRESSE_INFORMATION_SUPPLEMENTAIRE,text:"Erreur - Informations contact incompl\xE8tes",detail:`Le type de voie et le libell\xE9 ou le compl\xE9ment d'adresse ou
le lieu dit doit \xEAtre renseign\xE9.`},{code:e.ERR_CONTACT_ADRESSE_COMMUNE,text:"Erreur - Commune contact introuvable",detail:"La commune renseign\xE9e est introuvable."},{code:e.ERR_COORDONNEES_BANCAIRES,text:"Erreur - BIC ou IBAN invalide",detail:"Le bic ou l'iban est invalide."},{code:e.ERR_COMPTE_BLOQUE,text:"Erreur - Compte indisponible",detail:`Compte indisponible.
Le compte du particulier a \xE9t\xE9 bloqu\xE9 suite \xE0 un retour en
impay\xE9. L\u2019action souhait\xE9e (cr\xE9ation d\u2019une demande de
paeiement ou inscription) ne pourra pas aboutir. `},{code:e.ERR_FORMAT_VALEUR,text:"Erreur - Format de la valeur",detail:`Vous avez utilis\xE9 des montants avec des virgules.
Cela provoque un probl\xE8me d'arrondi du c\xF4t\xE9 de l'Urssaf.
Veuillez contacter le support Abby
`}];export{t as ErrorCodeUrssafTp};
