
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { BillingActionHistory, BillingState, BillingType, Frequency, IBilling, PlanFeature, BillingActivityEvent } from '@abby/core-legacy'

@Component
export default class BillingChips extends Vue {
  @Prop()
  billing!: IBilling

  @Prop({ default: 18 })
  size!: number

  get hasAccessToArchivage () {
    return this.$planManager.hasAccessTo(this.$planManager.features.billing_archive_documents)
  }

  get iconSize () {
    return `${this.size - 6}px`
  }

  get chipSize () {
    return `${this.size}px`
  }

  get BillingType () {
    return BillingType
  }

  get BillingState () {
    return BillingState
  }

  get frequency () {
    return this.billing.frequency
  }

  get isFrequencyActivated () {
    return this.frequency && this.billing.frequencyId && this.billing.frequency?.activatedAt && this.$dayjs(this.billing.frequency?.stopAt).isAfter(this.$dayjs(this.billing.date))
  }

  get nextBillingDate () {
    if (!this.frequency?.interval || !this.frequency?.offset) { return '' }
    const emissionDate = this.$dayjs(this.billing.date)
    const startDate = this.$dayjs(this.frequency.startAt)
    if (emissionDate.isBefore(startDate)) {
      return startDate.add(this.frequency.offset, this.frequencyIndexUnit)
    }

    return emissionDate.add(this.frequency.offset, this.frequencyIndexUnit)
  }

  get hasActiveReminder () {
    return this.billing.reminder?.active
  }

  get hasAccessToReminder () {
    return this.$planManager.hasAccessTo(PlanFeature.BILLING_REMINDER)
  }

  get hasAccessToFrequency () {
    return this.$planManager.hasAccessTo(PlanFeature.BILLING_FREQUENCY)
  }

  get frequencyIndexUnit () {
    switch (this.frequency?.interval) {
    case Frequency.DAY: return 'd'
    case Frequency.WEEK: return 'w'
    case Frequency.MONTH: return 'M'
    case Frequency.YEAR: return 'y'
    default: return 'd'
    }
  }

  get lastSendReminderDate () {
    if (!this.billing.history) { return null }
    const _historic = [...this.billing.history]
    const sortedHistoric = _historic.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    const lastSendAction = sortedHistoric.find(h => h.action === BillingActionHistory.SEND_REMINDER)
    if (!lastSendAction) { return null }
    return this.$date.formatDDMMYYYYHmm(lastSendAction.date)
  }

  get lastSendByEmailDate () {
    if (!this.billing.history) { return null }
    const _historic = [...this.billing.history]
    const sortedHistoric = _historic.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    const lastSendAction = sortedHistoric.find(h => h.action === BillingActionHistory.SEND_BY_EMAIL)
    if (!lastSendAction) { return null }
    return this.$date.formatDDMMYYYYHmm(lastSendAction.date)
  }

  get lastDownloadDate () {
    if (!this.billing.activity) { return null }
    const _activity = [...this.billing.activity]
    const sortedActivity = _activity.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    const lastSendAction = sortedActivity.find(h => h.action === BillingActivityEvent.DOWNLOADED)
    if (!lastSendAction) { return null }
    return this.$date.formatDDMMYYYYHmm(lastSendAction.date)
  }

  get createFromEstimateNumber () {
    return this.billing.createdFromEstimate?.number
  }

  get createFromPurchaseOrderNumber () {
    return this.billing.createdFromPurchaseOrder?.number
  }

  get createFromInvoiceNumber () {
    return this.billing.createdFromInvoice?.number
  }

  // Savoir si l'avoir provient d'un bon de commande
  get isFromInvoice () {
    return !!this.billing.createdFromInvoiceId
  }

  // Savoir si la facture est une facture finale d'un devis
  get isFinalInvoice () {
    return (this.billing.createdFromEstimateId || this.billing.createdFromPurchaseOrderId) && this.billing.billingType === BillingType.INVOICE
  }
}
