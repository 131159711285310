import dayjs from 'dayjs';
import * as fr from 'dayjs/locale/fr';
import { ProductUnit, ReadTimeRecord } from '../../types';

const relativeTime = require('dayjs/plugin/relativeTime');
const durationPlugin = require('dayjs/plugin/duration');

// @ts-ignore
dayjs.extend(relativeTime);
// @ts-ignore
dayjs.extend(durationPlugin);
// @ts-ignore
dayjs.locale(fr);

export interface ITimeRecordsGroup {
  period: string,
  timeRecords: ReadTimeRecord[]
}

export type TimeUnit = ProductUnit.HEURE | ProductUnit.MINUTE | ProductUnit.JOUR;

export const calculateQuantity = (seconds: number, unit: TimeUnit = ProductUnit.HEURE): number => {
  // @ts-ignore
  const duration = dayjs.duration(seconds, 'seconds');
  const days = duration.get('days');
  const hours = duration.get('hours') || 0;
  const minutes = duration.get('minutes') || 0;

  const totalHours = days * 24 + hours;

  const hoursInDecimal = +totalHours + (minutes / 60);
  const daysInDecimal = hoursInDecimal / 24;

  return {
    [ProductUnit.JOUR]: +daysInDecimal.toFixed(2),
    [ProductUnit.HEURE]: +hoursInDecimal.toFixed(2),
    [ProductUnit.MINUTE]: Math.ceil(seconds / 60),
  }[unit];
};

export const calculateTotalPrice = ({ seconds, unit, unitRate }: { seconds?: number, unit?: TimeUnit | null, unitRate?: number | null }): number => {
  if (!seconds || !unit) {
    return 0;
  }
  const quantity = calculateQuantity(seconds, unit);

  return quantity * (unitRate || 0);
};

export const formattedDuration = (seconds: number) => {
  // @ts-ignore
  const duration = dayjs.duration(seconds, 'seconds');
  const month = duration.get('month');
  const days = duration.get('days');
  const hours = duration.get('hours');
  const minutes = duration.get('minutes');

  return `${month ? `${month}mois` : ''} ${days ? `${days}j` : ''} ${hours}h ${minutes}min`.trim();
};

// @ts-ignore
export const calculateDuration = (start?: Date | string, end?: Date | string) => dayjs(end).diff(start, 'seconds');

export const groupTimeRecordsByDate = (timeRecords: ReadTimeRecord[]): ITimeRecordsGroup[] => timeRecords.reduce((acc: ITimeRecordsGroup[], record: ReadTimeRecord) => {
  const date = new Date(record.endDate);

  // on défini sur quel periode le record est
  const period = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const groupIndex = acc.findIndex((group) => group.period === period);

  // si la periode n'existe pas, on crée un nouveau groupe
  if (groupIndex === -1) {
    return [
      ...acc,
      {
        period,
        timeRecords: [record],
      },
    ];
  }

  // si la periode existe, on push le record dans le groupe trouvé
  acc[groupIndex].timeRecords.push(record);
  return acc;
}, []);
