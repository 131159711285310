import { ReadCustomer } from '@abby/shared'
import { Customer } from '~/logic/contexts/billing/domain/entity/customer.entity'

export class CustomerHttpMapper {
  static toDomain (customer: ReadCustomer): Customer {
    return Customer.create({
      id: customer.id,
      email: customer.emails?.[0],
      phone: customer.phoneNumber,
      contactId: customer.contactId,
      organizationId: customer.organizationId,
      ...(customer.billingAddress && {
        line1: customer.billingAddress.line1,
        line2: customer.billingAddress.line2,
        city: customer.billingAddress.city,
        country: customer.billingAddress.country,
        zipCode: customer.billingAddress.zipCode,
      }),
      firstname: customer.firstname,
      isDefault: customer.isDefault,
      test: !!customer.test,
      legacyId: customer.legacyId,
      lastname: customer.lastname,
      token: customer.token,
      ...(customer.parent && { parent: CustomerHttpMapper.toDomain(customer.parent) }),
      tiersPrestationIsActivated: customer.tiersPrestationIsActivated,
      tiersPrestation: customer.tiersPrestation
        ? {
          idClient: customer.tiersPrestation.idClient,
          status: customer.tiersPrestation.status,
          registeredAt: customer.tiersPrestation.registeredAt ? new Date(customer.tiersPrestation.registeredAt * 1000) : undefined,
        }
        : undefined,
      ...(customer.name || customer.commercialName
        ? {
          company: {
            name: customer.name || '',
            commercialName: customer.commercialName,
          },
        }
        : {}),
    })
  }
}
