import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import { DeclarationState } from '@abby/core-legacy'
import type {
  CA,
  CreateDeclaration,
  CreatePayment,
  IUrssafDeclarationItem,
  ReadDeclarations,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export enum DeclarationStep {
  INTRODUCTION = 1,
  DECLARATION,
  PAYMENT,
  END
}

export interface DeclarationsQuery {}

export interface DeclarationsPaginationQuery { page: number, limit: number }

export interface DeclarationsState {
  declarationForCurrentPeriod: IUrssafDeclarationItem | null,
  declarationToDisplay: IUrssafDeclarationItem | null,
  pagination: ReadDeclarations | null;
  query: DeclarationsQuery;
  queryLoading: boolean;
  initialLoading: boolean;
  declarationModalIsOpen: boolean;
  paginationQuery: DeclarationsPaginationQuery;
  declarationStep: DeclarationStep | null
}

export const state = (): DeclarationsState => ({
  declarationForCurrentPeriod: null,
  declarationToDisplay: null,
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
  },
  query: {},
  queryLoading: true,
  declarationModalIsOpen: false,
  initialLoading: true,
  declarationStep: null,
})

export const getters: GetterTree<DeclarationsState, RootState> = {
  pagination (state: DeclarationsState) {
    return state.pagination
  },
  noPaymentNeeded (state: DeclarationsState) {
    return state.declarationForCurrentPeriod && state.declarationForCurrentPeriod.montants?.montantTotal === 0
  },
  isDeclarationDone (state: DeclarationsState) {
    if (!state.declarationForCurrentPeriod) {
      return false
    }
    return [DeclarationState.PAID, DeclarationState.ALREADY_DECLARED_ELSEWHERE].includes(state.declarationForCurrentPeriod.declarationState)
  },
  declarationStep (state: DeclarationsState) {
    return state.declarationStep
  },
  query (state: DeclarationsState) {
    return state.query
  },
  paginationQuery (state: DeclarationsState) {
    return state.paginationQuery
  },
  declarations (state: DeclarationsState) {
    return state.pagination?.docs || []
  },
  queryLoading (state: DeclarationsState) {
    return state.queryLoading
  },
  initialLoading (state: DeclarationsState) {
    return state.initialLoading
  },
  declarationForCurrentPeriod (state: DeclarationsState) {
    return state.declarationForCurrentPeriod
  },
  declarationToDisplay (state: DeclarationsState) {
    return state.declarationToDisplay
  },
  declarationModalIsOpen (state: DeclarationsState) {
    return state.declarationModalIsOpen
  },
  hasDeclareElsewhere (state: DeclarationsState) {
    if (!state.declarationForCurrentPeriod) {
      return false
    }

    return state.declarationForCurrentPeriod.declarationState === DeclarationState.ALREADY_DECLARED_ELSEWHERE
  },
}

export const mutations = {
  SET_PAGINATION_QUERY (state: DeclarationsState, paginationQuery: DeclarationsPaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: DeclarationsState, pagination: ReadDeclarations | null): void {
    state.pagination = pagination
  },
  SET_DECLARATION_STEP (state: DeclarationsState, declarationStep: DeclarationStep): void {
    state.declarationStep = declarationStep
  },
  SET_INITIAL_LOADING (state: DeclarationsState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: DeclarationsState, value: boolean): void {
    state.queryLoading = value
  },
  UPDATE_QUERY (_state: DeclarationsState, query: Partial<DeclarationsQuery>): void {
    _state.query = {
      ..._state.query,
      ...query,
    }
    const newPaginationQuery = state().paginationQuery
    _state.paginationQuery = Object.assign(_state.paginationQuery, newPaginationQuery)
  },
  RESET (_currentState: DeclarationsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_DECLARATION_FOR_CURRENT_PERIOD (state: DeclarationsState, declarationForCurrentPeriod: IUrssafDeclarationItem | null) {
    state.declarationForCurrentPeriod = declarationForCurrentPeriod
  },
  SET_DECLARATION_TO_DISPLAY (state: DeclarationsState, declaration: IUrssafDeclarationItem | null) {
    state.declarationToDisplay = declaration
  },
  SET_DECLARATION_MODAL_IS_OPEN (state: DeclarationsState, value: boolean) {
    state.declarationModalIsOpen = value
  },
  RESET_QUERY (_currentState: DeclarationsState) {
    const newQuery = state().query
    const newPaginationQuery = state().paginationQuery
    _currentState.query = Object.assign(_currentState.query, newQuery)
    _currentState.paginationQuery = Object.assign(_currentState.paginationQuery, newPaginationQuery)
  },
}

export const actions: ActionTree<any, any> = {
  openDeclarationModal ({ commit }, step: DeclarationStep | null = null) {
    commit('SET_DECLARATION_STEP', step)
    commit('urssaf/OPEN_MODAL', 'declaration', { root: true })
  },
  setDeclarationStep ({ commit }, step: DeclarationStep) {
    commit('SET_DECLARATION_STEP', step)
  },
  async fetchDeclarations ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.urssaf.getAll({
        ...getters.paginationQuery as DeclarationsPaginationQuery,
        ...getters.query,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  debounceFetchDeclarations: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchDeclarations')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<DeclarationsQuery>) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchDeclarations')
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<DeclarationsPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchDeclarations')
  },
  async fetchDeclarationForCurrentPeriod ({ commit, rootState }) {
    if (!rootState.urssaf?.currentEstimationsFromUrssaf) {
      return
    }

    const { periode } = rootState.urssaf.currentEstimationsFromUrssaf
    if (periode) {
      const declaration = await this.$api.urssaf.getByPeriod(periode)
      commit('SET_DECLARATION_FOR_CURRENT_PERIOD', declaration || null)
    }
  },
  seeDeclarationInDetail ({ commit }, declaration: IUrssafDeclarationItem) {
    commit('SET_DECLARATION_TO_DISPLAY', declaration)
    commit('SET_DECLARATION_MODAL_IS_OPEN', true)
  },
  async setHasAlreadyDeclareElsewhere ({ commit, rootState, dispatch }) {
    const { periode } = rootState.urssaf.currentEstimationsFromUrssaf
    if (!periode) {
      return
    }
    const declaration = await this.$api.urssaf.setHasAlreadyDeclareElsewhere(periode)
    commit('SET_DECLARATION_FOR_CURRENT_PERIOD', declaration || null)
    await dispatch('fetchDeclarations')
  },
  async createDeclaration ({ commit, rootState, dispatch, rootGetters }, ca: Partial<CA> | null) {
    if (!rootGetters['urssaf/mandatBelongsToUs']) {
      return
    }

    const { siret } = rootState.company.company || {}
    const { periode } = rootState.urssaf.currentEstimationsFromUrssaf || {}
    const { caVente = 0, caPrestationService = 0, caPrestationAutres = 0, recettesCipav = 0, caLocation = 0 } = ca || rootState.urssaf.currentEstimationsFromUrssaf?.ca || {}

    const declarationPayload : CreateDeclaration = {
      periode,
      isCipav: rootGetters['urssaf/isCipav'],
      ca: { // convertion en centimes
        caVente: caVente * 100,
        caPrestationService: caPrestationService * 100,
        caPrestationAutres: caPrestationAutres * 100,
        caLocation: caLocation * 100,
        recettesCipav: recettesCipav * 100,
      },
    }

    const declaration = await this.$api.urssaf.createDeclaration({ siret }, declarationPayload)
    commit('SET_DECLARATION_FOR_CURRENT_PERIOD', declaration)
    await dispatch('fetchDeclarations')
  },
  async createPayment ({ commit, rootState, dispatch, rootGetters }, payload: CreatePayment) {
    if (!rootGetters['urssaf/mandatBelongsToUs']) {
      return
    }

    const { siret } = rootState.company.company
    const declaration = await this.$api.urssaf.createPayment({ siret }, payload)

    commit('SET_DECLARATION_FOR_CURRENT_PERIOD', declaration)
    await dispatch('fetchDeclarations')
  },
}
