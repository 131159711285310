import { IAssurance } from '../Interface/models/IAssurance';
import { CommercialOffers, CommercialOffersUTMMedium } from '../../utils/analytics';

export const Assurance: Array<IAssurance> = [
  {
    name: 'Groupama',
    code: 'groupama',
  },
  {
    name: 'Allianz',
    code: 'allianz',
  },
  {
    name: 'Generali',
    code: 'generali',
  },
  {
    name: 'Axa',
    code: 'axa',
  },
  {
    name: 'AG2R',
    code: 'ag2r',
  },
  {
    name: 'SwissLife',
    code: 'swisslife',
  },
  {
    name: 'Hiscox',
    code: 'hiscox',
  },
  {
    name: 'Société Générale',
    code: 'societe-generale',
  },
  {
    name: 'Aviva',
    code: 'aviva',
  },
  {
    name: 'BPCE',
    code: 'bpce',
  },
  {
    name: 'Covéa (MMA, MAAf, GMF)',
    code: 'covea',
  },
  {
    name: 'Crédit Mutuel',
    code: 'credit-mutuel',
  },
  {
    name: 'Matmut',
    code: 'matmut',
  },
  {
    name: 'BNP Paribas',
    code: 'bnp-paribas',
  },
  {
    name: 'AIG',
    code: 'aig',
  },
  {
    name: 'Banque Postale',
    code: 'banque-postale',
  },
  {
    name: 'Caisse d\'épargne',
    code: 'caisse-epargne',
  },
  {
    name: 'CIC',
    code: 'cic',
  },
  {
    name: 'LCL',
    code: 'lcl',
  },
  {
    name: 'MAIF',
    code: 'maif',
  },
  {
    name: 'Assurup',
    code: 'assurup',
    description: 'L\'assurance professionnelle qui vous facilite la vie',
    content: 'Assurup est la solution d’assurance de tous les professionnels qui pourra vous aider sur les enjeux d’assurance de votre entreprise. Plus de 1000 activités assurées !',
    offer: 'Audit gratuit & 5 % de remise',
    action: { commercialOffer: CommercialOffers.ASSURUP, medium: CommercialOffersUTMMedium.ADVANTAGE_CARD },
  },
  {
    name: 'Riskee',
    code: 'riskee',
  },
  {
    name: 'Heyme',
    code: 'heyme',
  },
  {
    name: 'Simplis',
    code: 'simplis',
    description: 'Décennale des auto-entrepreneurs du bâtiment',
    content: 'L\'assurance obligatoire des artisans du bâtiment (BTP). Obtenez le meilleur tarif de France pour votre Garantie Décennale.',
    offer: 'Frais de dossier offerts',
    action: { commercialOffer: CommercialOffers.SIMPLIS, medium: CommercialOffersUTMMedium.ADVANTAGE_CARD },
  },
  {
    name: 'April',
    code: 'april',
  },
  {
    name: 'Coover',
    code: 'coover',
  },
  {
    name: 'Simple+',
    code: 'simple+',
  },
  {
    name: 'Quartz',
    code: 'quartz',
  },
  {
    name: 'EasyBlue',
    code: 'easy-blue',
  },
  {
    name: 'Autre',
    code: 'autre',
  },
];
