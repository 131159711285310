import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'

export const useUnlinkBilling = () => {
  const { $confirmManager } = useContext()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    const confirm = await $confirmManager.ask({
      title: 'Enlever les liens de création entre les documents',
      content: 'Cette action est irréversible. Voulez-vous vraiment enlever les liens entre une facture, un acompte et son devis ?',
      confirmText: 'Continuer',
    })
    if (!confirm) {
      return
    }

    await billingRepository.unlinkBilling(billing)
  }
}
