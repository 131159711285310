import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useFetchBillingUrssafSyncStatus = () => {
  const billingRepository = useBillingRepository()
  const alertManager = useAlertManager()

  return async (billing: BillingItem) => {
    const result = await billingRepository.fetchBillingUrssafSyncStatus(billing)
    alertManager.success('Le statut urssaf du document a bien été actualisé.')
    return result
  }
}
