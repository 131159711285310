import { Code, Type } from '@abby/core-legacy'

export class CustomError extends Error {
  public message: string;
  public code: Code;
  public type: Type;
  constructor (message: string, code: Code, type: Type) {
    super(message)
    this.message = message
    this.code = code
    this.type = type

    Object.setPrototypeOf(this, CustomError.prototype)
  }
}
