export enum PaymentMethod {
  TRANSFER = 'transfer',
  DIRECT_DEBIT = 'direct_debit',
  CREDIT_CARD = 'credit_card',
  CHEQUE = 'cheque',
  UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE = 'universal_employment_service_cheque',
  CASH = 'cash',
  PAYPAL = 'paypal',
  STRIPE = 'stripe',
  // TODO: activate when billing refactoring fixed
  // LYDIA = 'lydia',
  // SUMUP = 'sumup',
  // CHORUS_PRO = 'chorus_pro',
  // WISE = 'wise',
  // MALT = 'malt',
  // REVOLUT = 'revolut',
  // LYFPAY = 'lyfpay',
  // LE_BON_COIN = 'le_bon_coin',
  // EASYTRANSAC = 'easytransac',
  // GO_CARD = 'go_card',
  // PAYLIB = 'paylib',
  // VINTED = 'vinted',
  // ETSY = 'etsy',
  // EBAY = 'ebay',
  OTHER = 'other',
}
