import type {
  ReadCampaigns, UpdateCampaigns,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Campaigns extends Resource {
  getCompanyCampaigns (): Promise<ReadCampaigns> {
    return this.axios.$get('/campaigns')
  }

  getCampaignsByCompanyId (companyId: string): Promise<ReadCampaigns> {
    return this.axios.$get(`/campaigns/${companyId}`)
  }

  updateCompanyCampaigns (params: UpdateCampaigns): Promise<ReadCampaigns> {
    // eslint-disable-next-line no-param-reassign
    delete params.companyId
    return this.axios.$put('/campaigns', params)
  }

  updateCampaignsByCompanyId (params: UpdateCampaigns): Promise<ReadCampaigns> {
    const { companyId, ...body } = params
    return this.axios.$put(`/campaigns/${companyId}`, body)
  }
}
