import {
  BankInformations,
  ClientAddress,
  ClientPreferences,
} from '@abby/shared'

export type OrganizationFormProps = {
  id?: string;
  name: string;
  commercialName?: string;
  siret?: string;
  vatNumber?: string;
  billingAddress?: ClientAddress;
  deliveryAddress?: ClientAddress;
  bankInformations?: BankInformations;
  preferences?: ClientPreferences;
};

export class OrganizationForm {
  id?: string;
  name: string;
  commercialName?: string;
  siret?: string;
  vatNumber?: string;
  billingAddress?: ClientAddress;
  deliveryAddress?: ClientAddress;
  bankInformations?: BankInformations;
  preferences?: ClientPreferences;

  private constructor (props: OrganizationFormProps) {
    this.id = props.id
    this.name = props.name
    this.commercialName = props.commercialName
    this.siret = props.siret
    this.vatNumber = props.vatNumber
    this.billingAddress = props.billingAddress
    this.deliveryAddress = props.deliveryAddress
    this.bankInformations = props.bankInformations
    this.preferences = props.preferences
  }

  public static create (props: OrganizationFormProps): OrganizationForm {
    return new OrganizationForm(props)
  }
}
