import { ReadContact, Time } from '@abby/shared'
import { ContactForm } from '../../domain/form/contact.form'

export class ContactFormHttpMapper {
  public static toDomain (doc: ReadContact): ContactForm {
    return ContactForm.create({
      id: doc.id,
      firstname: doc.firstname,
      lastname: doc.lastname,
      emails: doc.emails,
      jobTitle: doc.jobTitle,
      phone: doc.phone,
      billingAddress: doc.billingAddress,
      deliveryAddress: doc.deliveryAddress,
      bankInformations: doc.bankInformations,
      preferences: {
        language: doc.language,
        currency: doc.currency,
        paymentMethods: doc.paymentMethods,
      },
    })
  }
}
