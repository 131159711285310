import { ThirdPartyType } from './ThirdPartyType';

export enum OperationType {
  SALE = 'sale',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  ASSET = 'asset',
}

export const OperationTypeToThirtyParty = {
  [OperationType.SALE]: ThirdPartyType.CUSTOMER,
  [OperationType.ASSET]: ThirdPartyType.CUSTOMER,
  [OperationType.REFUND]: ThirdPartyType.PROVIDER,
  [OperationType.PURCHASE]: ThirdPartyType.PROVIDER,
};
