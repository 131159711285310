import { CountryCode } from '../enums/countryCode.enum';
import { VatCode } from '../enums/vatCode.enum';
import { mapVatToLegacyVatId } from '../mapper';

export type AvailableCountryCode = 'FR';

export type VatItem = {
  code: VatCode;
  label: string;
  percentage: number;
};

export type VatList = { [CountryCode in AvailableCountryCode]: VatItem[] };

const vatList: VatList = {
  [CountryCode.France]: [
    { code: VatCode.FR_210, label: '2,10 %', percentage: 2.1 },
    { code: VatCode.FR_550, label: '5,50 %', percentage: 5.5 },
    { code: VatCode.FR_850, label: '8,50 %', percentage: 8.5 },
    { code: VatCode.FR_1000, label: '10,00 %', percentage: 10 },
    { code: VatCode.FR_2000, label: '20,00 %', percentage: 20 },
    { code: VatCode.FR_00HT, label: 'Hors Taxe', percentage: 0 },
    { code: VatCode.FR_00UE, label: 'Union Européenne', percentage: 0 },
    { code: VatCode.FR_0HUE, label: 'Hors UE', percentage: 0 },
  ],
};

export class Vat {
  public static vatList: VatList = vatList;

  public static list(): VatList {
    return this.vatList;
  }

  public static listByCountry(countryCode: AvailableCountryCode): VatItem[] {
    return this.vatList[countryCode];
  }

  /**
   * Permet de retrouver un VatItem à partir d'un vatCode de type `FR_2000`.
   * { code: 'FR_2000', label: '20 %', percentage: 20 }
   * @param vatCode - Le vatCode recherché au format 'countryCode_vat*100'. (Ex: 'FR_2000' pour un taux de tva en France de 20%)
   */
  public static getVatItem(vatCode: VatCode): VatItem | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [countryCode, _] = vatCode.split('_');
    return this.vatList[countryCode as AvailableCountryCode].find(
      (vat: VatItem) => vat.code === vatCode,
    );
  }

  /**
   * Permet de dire si un vatCode de type `FR_2000` est disponible dans Abby.
   * @param vatCode - Le vatCode que l'on souhaite vérifié au format 'countryCode_vat*100'. (Ex: 'FR_2000' pour un taux de tva en France de 20%)
   */
  public static isAvailable(vatCode: VatCode): boolean {
    return !!this.getVatItem(vatCode);
  }

  /**
   * Permet de dire si un vatCode de type `FR_2000` est non disponible dans Abby.
   * @param vatCode - Le vatCode que l'on souhaite vérifié au format 'countryCode_vat*100'. (Ex: 'FR_2000' pour un taux de tva en France de 20%)
   */
  public static isNotAvailable(vatCode: VatCode): boolean {
    return !this.isAvailable(vatCode);
  }

  public static fromVatCodeToLegacyId(vatCode: VatCode): number {
    return mapVatToLegacyVatId[vatCode];
  }
}

interface IVatLegacyIdToVatCode {
  [x: number]: VatCode;
}

export const vatLegacyIdToVatCode: IVatLegacyIdToVatCode = {
  1: VatCode.FR_2000,
  2: VatCode.FR_1000,
  3: VatCode.FR_550,
  4: VatCode.FR_210,
  5: VatCode.FR_00HT,
  6: VatCode.FR_850,
  7: VatCode.FR_00UE,
  8: VatCode.FR_0HUE,
};

interface IVatCodeToLegacyId {
  [x: string]: number;
}

export const vatCodeToVatLegacyId: IVatCodeToLegacyId = {
  [VatCode.FR_2000]: 1,
  [VatCode.FR_1000]: 2,
  [VatCode.FR_550]: 3,
  [VatCode.FR_210]: 4,
  [VatCode.FR_00HT]: 5,
  [VatCode.FR_850]: 6,
  [VatCode.FR_00UE]: 7,
  [VatCode.FR_0HUE]: 8,
};
