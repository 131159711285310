export enum ExportCategory {
  DOCUMENTS = 'documents',
  DIRECTORY = 'directory',
  ACCOUNTING = 'accounting',
}

export enum ExportSubcategory {
  INVOICES = 'invoices',
  ESTIMATES = 'estimates',
  PURCHASE_ORDERS = 'purchase_orders',
  ASSETS = 'assets',

  CONTACTS = 'contacts',
  ORGANIZATIONS = 'organizations',
  PROVIDERS = 'providers',
  PRODUCTS = 'products',

  INCOME_BOOK = 'income_book',
  PURCHASE_REGISTER = 'purchase_register',
  OLD_PURCHASE_REGISTER = 'old_purchase_register',
  INCOME_STATEMENT = 'income_statement',
  FEC = 'fec',
  TRANSACTIONS = 'transactions',
}
