import { useContext, getCurrentInstance } from '@nuxtjs/composition-api'
import Vue, { VueConstructor } from 'vue'

export const useI18nManager = () => {
  const { app } = useContext()

  const i18nInstance = app.i18n

  if (!i18nInstance) {
    throw new Error('nuxt-i18n not initialized')
  }

  const i18n = i18nInstance

  const instance = getCurrentInstance()
  const vm =
    instance?.proxy ||
    (instance as unknown as InstanceType<VueConstructor>) ||
    new Vue({})

  return {
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm),
  }
  // const { i18n } = useContext()
  // return { t: i18n.t }
}
