import { Context, Plugin } from '@nuxt/types'

export interface BusManagerInstance {
  emit: (event: string, data: any) => any;
  on: (event: string, callback: (e: any) => any) => any;
  off: (event: string) => any;
}

function makeid () {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength))
  }
  return result
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BusManagerPlugin: Plugin = ({ store, app }: Context, inject) => {
  const events: { [K in string]: { [T in string]: (data: any) => {} } } = {}

  const executeCallback = (event: string) => (data: any) => {
    const observers = events[event]
    if (!observers) { return }
    Object.values(observers).forEach(observer => observer(data))
  }

  inject('busManager', {
    emit: (event: string, data: any) => {
      if (!process.browser) { return }
      executeCallback(event)(data)
    },
    on: (event: string, callback: (e: any) => any) => {
      if (!process.browser) { return }

      if (!events[event]) {
        events[event] = {}
      }
      const uid = makeid()
      events[event] = {
        ...events[event],
        [uid]: callback,
      }
      return uid
    },
    off: (uid: string) => {
      if (!process.browser) { return }

      const event = Object.entries(events).find(([_, observers]) => {
        return Object.keys(observers).find(k => k === uid)
      })
      if (!event && events[uid]) {
        delete events[uid]
        return
      }
      if (!event) { return }
      const key = event[0]
      delete events[key][uid]

      setTimeout(() => {
        if (!Object.keys(events[key]).length) {
          delete events[uid]
        }
      }, 0)
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $busManager: BusManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $busManager: BusManagerInstance
  }
  interface Context {
    $busManager: BusManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $busManager: BusManagerInstance
  }
}

export default BusManagerPlugin
