export enum LegalStatus {
  MICRO_ENTREPRISE = 1,
  SAS,
  SARL,
  SASU,
  EI,
  EURL,
  EIRL,
  SA,
  SNC,
  OTHER,
  SASU_SAS,
  EURL_SARL,
  SCI,
  ASSOCIATION,
}
