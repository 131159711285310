export enum Action {
  // MARKETING
  PAGE_VIEWED = 'PageViewed', // ❤️
  BUTTON_CLICKED = 'ButtonClicked',
  MODAL_OPENED = 'ModalOpened',
  NPS_NOTE_ADDED = 'NPSNoteAdded',

  // Global
  GUIDE_STEP_COMPLETED = 'GuideStepCompleted', // ✅❤️

  // USER
  USER_REGISTERED = 'UserRegistered', // ✅❤️
  ONBOARDING_COMPLETED = 'OnboardingCompleted', // ✅❤️
  FREE_TRIAL_EXTENSION_STARTED_AUTOMATICALLY = 'FreeTrialExtensionStartedAutomatically', // ✅❤️
  NEW_TRIAL_FOR_EXISTING_SUBSCRIPTION_ADDED_AUTOMATICALLY = 'NewTrialForExistingSubscriptionAddedAutomatically', // ✅❤️

  BASIC_CUSTOMIZATION_DOCUMENT_COMPLETED = 'BasicCustomizationDocumentCompleted', // ✅❤️
  ADVANCED_CUSTOMIZATION_DOCUMENT_COMPLETED = 'AdvancedCustomizationDocumentCompleted', // ✅❤️
  BRAND_LOGO_REMOVED = 'BrandLogoRemoved', // ✅❤️

  // BILLING
  BILLING_EMAIL_CUSTOMIZED = 'BillingEmailCustomized', // ✅❤️
  BILLING_FREQUENCY_ACTIVATED = 'BillingFrequencyActivated', // ✅❤️
  BILLING_REMINDER_ACTIVATED = 'BillingReminderActivated', // ✅❤️
  BILLING_RECURRING_ACTIVATED = 'BillingRecurringActivated', // ✅❤️
  BILLING_DRAFTED = 'BillingDrafted', // ✅❤️
  BILLING_FINALIZED = 'BillingFinalized', // ✅❤️
  BILLING_PAID = 'BillingPaid', // ✅❤️
  BILLING_DOWNLOADED = 'BillingDownloaded', // ✅❤️
  BILLING_PAID_ONLINE = 'BillingPaidOnline', // ✅
  BILLING_SIGNED_ONLINE = 'BillingSignedOnline', // ✅
  BILLING_SIGNATURE_ONLINE_REQUESTED = 'BillingSignatureOnlineRequested', // ✅❤️
  BILLING_PAID_ONLINE_REQUESTED = 'BillingPaidOnlineRequested', // ✅
  BILLING_SIGNED = 'BillingSigned', // ✅❤️
  BILLING_ARCHIVED = 'BillingArchived', // ✅❤️
  BILLING_TEST_MODE_DISABLED = 'BillingTestModeDisabled', // ✅❤️
  BILLING_ALL_DOWNLOADED = 'BillingAllDownloaded', // ✅❤️
  BILLING_SENT_BY_EMAIL = 'BillingSentByEmail', // ✅❤️
  BILLING_SENT_TEST_REMINDER = 'BillingSentTestReminder', // ✅❤️

  // CASH BILLING
  MARK_AS_CASHED_MODAL_SUGGESTED_TRANSACTION_SELECTED = 'MarkAsCashedModalSuggestedTransactionSelected',
  MARK_AS_CASHED_MODAL_TRANSACTION_ADDED_MANUALLY = 'MarkAsCashedModalTransactionAddedManually',
  DOCUMENT_MARKED_AS_CASHED = 'DocumentMarkedAsCashed',

  // SUBSCRIPTION
  SUBSCRIPTION_STARTED = 'SubscriptionStarted', // ✅❤️
  SUBSCRIPTION_UPDATED = 'SubscriptionUpdated', // ✅
  CE_SUBSCRIPTION_STARTED = 'CeSubscriptionStarted',
  SUBSCRIPTION_CANCELED = 'SubscriptionCanceled', // ✅
  REVENUE_GENERATED = 'RevenueGenerated', // ✅

  // TASK
  TASK_CREATED = 'TaskCreated', // ✅❤️
  TASK_COMPLETED = 'TaskCompleted', // ✅❤️
  TASK_UPDATED = 'TaskUpdated', // ✅❤️
  TASK_DELETED = 'TaskDeleted', // ✅❤️

  // OPPORTUNITY
  OPPORTUNITY_CREATED = 'OpportunityCreated', // ✅❤️
  OPPORTUNITY_STATUS_UPDATED = 'OpportunityStatusUpdated', // ✅❤️
  OPPORTUNITY_UPDATED = 'OpportunityUpdated', // ✅❤️
  OPPORTUNITY_DELETED = 'OpportunityDeleted', // ✅❤️

  // PRODUCT
  PRODUCT_CREATED = 'ProductCreated', // ✅❤️
  PRODUCT_UPDATED = 'ProductUpdated', // ✅❤️
  PRODUCT_DELETED = 'ProductDeleted', // ✅❤️
  PRODUCT_DOWNLOADED = 'ProductDownloaded', // ✅❤️

  // TIME TRACKING
  TIME_TRACKING_CREATED = 'TimeTrackingCreated', // ✅❤️
  TIME_TRACKING_UPDATED = 'TimeTrackingUpdated', // ✅❤️
  TIME_TRACKING_DELETED = 'TimeTrackingDeleted', // ✅❤️
  TIME_TRACKING_BILLED = 'TimeTrackingBilled', // ✅❤️

  // PROVIDER
  PROVIDER_CREATED = 'ProviderCreated', // ✅❤️
  PROVIDER_UPDATED = 'ProviderUpdated', // ✅❤️
  PROVIDER_DELETED = 'ProviderDeleted', // ✅❤️
  PROVIDER_DOWNLOADED = 'ProviderDownloaded', // ✅❤️

  // CUSTOMER
  CUSTOMER_CREATED = 'CustomerCreated', // ✅❤️
  CUSTOMER_UPDATED = 'CustomerUpdated', // ✅❤️
  CUSTOMER_DELETED = 'CustomerDeleted', // ✅❤️
  CUSTOMER_DOWNLOADED = 'CustomerDownloaded', // ✅❤️
  TAX_RECEIPT_GENERATED = 'TaxReceiptGenerated', // ✅

  BANK_ACCOUNT_CONNECTED = 'BankAccountConnected', // ✅
  BANK_ACCOUNT_DISCONNECTED = 'BankAccountDisconnected', // ✅
  BANK_TRANSACTION_ANNOTATED = 'BankTransactionAnnotated', // ✅
  BANK_TRANSACTION_CATEGORIZATION_RULE_ADDED = 'BankTransactionCategorizationRuleAdded', // ✅
  PURCHASE_PROOF_ADDED_TO_WAITING_LIST = 'PurchaseProofAddedToWaitingList', // ✅
  PURCHASE_PROOF_LINKED_TO_TRANSACTION = 'PurchaseProofLinkedToTransaction', // ✅

  INCOME_BOOK_ENTRY_ADDED = 'IncomeBookEntryAdded', // ✅❤️
  INCOME_BOOK_ENTRY_UPDATED = 'IncomeBookEntryUpdated', // ✅❤️
  INCOME_BOOK_ENTRY_DELETED = 'IncomeBookEntryDeleted', // ✅❤️
  INCOME_BOOK_DOWNLOADED = 'IncomeBookEntryDownloaded', // ✅❤️
  INCOME_BOOK_ENTRY_IMPORTED = 'IncomeBookEntryImported', // ✅❤️

  PURCHASE_REGISTER_ENTRY_ADDED = 'PurchaseRegisterEntryAdded', // ✅❤️
  PURCHASE_REGISTER_ENTRY_UPDATED = 'PurchaseRegisterEntryUpdated', // ✅❤️
  PURCHASE_REGISTER_ENTRY_DELETED = 'PurchaseRegisterEntryDeleted', // ✅❤️
  PURCHASE_REGISTER_ENTRY_DOWNLOADED = 'PurchaseRegisterEntryDownloaded', // ✅❤️

  URSSAF_CONTRIBUTION_ESTIMATED = 'UrssafContributionEstimated', // ✅
  URSSAF_DECLARATION_SENT = 'UrssafDeclarationSent', // ✅
  URSSAF_DECLARATION_PAID = 'UrssafDeclarationPaid', // ✅
  URSSAF_ACCOUNT_CONNECTED = 'UrssafAccountConnected', // ✅
  URSSAF_ACCOUNT_DISCONNECTED = 'UrssafAccountDisconnected', // ✅
  URSSAF_REMINDER_ACTIVATED = 'UrssafReminderActivated', // ✅
  URSSAF_REMINDER_DEACTIVATED = 'UrssafReminderDeactivated', // ✅

  // STRIPE
  STRIPE_CONFIGURATION_STARTED = 'StripeConfigurationStarted', // ✅
  STRIPE_CONFIGURATION_ENDED = 'StripeConfigurationEnded', // ✅

  // API TP
  API_TP_CONFIGURATION_STARTED = 'ApiTpConfigurationStarted', // ✅❤️
  API_TP_CONFIGURATION_ENDED = 'ApiTpConfigurationEnded', // ✅❤️
  API_TP_DISABLED = 'ApiTpDisabled', // ✅❤️
  API_TP_CONTACT_REGISTERED = 'ApiTpContactRegistered', // ✅❤️

  // VAT
  VAT_DECLARATION_SENT = 'VatDeclarationSent', // ✅
  VAT_DECLARATION_ESTIMATED = 'VatDeclarationEstimated', // ✅

  // REFERAL
  REFERAL_INVITE_SENT = 'ReferalInviteSent',
  REFERAL_USER_SIGNED_UP = 'ReferalUserSignedUp',
  REFERAL_USER_VALIDATED = 'ReferalUserValidated',
  REFERAL_USER_WITHDRAW = 'ReferalUserWithdraw',

  // CONTACTS & ORGANIZATIONS
  IMPORT_CONTACT_BUTTON_CLICKED = 'ImportContactButtonClicked',
  IMPORT_CONTACT_FILE_UPLOADED = 'ImportContactFileUploaded',
  CONTACTS_IMPORTED = 'ContactsImported',

  // STOCK
  INVENTORY_MANAGEMENT_ACTIVATED = 'InventoryManagementActivated',

  RELOGGED = 'Relogged', // ✅❤️

  CHECKOUT_PAGE_VIEWED = 'CheckoutPageViewed',

  // COMPANY
  COMPANY_INFO_COMPLETED = 'CompanyInfoCompleted',

  // SETTINGS
  CUSTOM_SIGNATURE_SET = 'CustomSignatureSet',

  // PLANS
  UPGRADE_PLAN_MODALE_VIEWED = 'UpgradePlanModaleViewed',
  UPGRADE_PLAN_BUTTON_CLICKED = 'UpgradePlanButtonClicked',

  // File

  FILE_UPLOADED = 'FileUploaded', // ✅❤️

}
