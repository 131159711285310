import type {
  ReadUserPreferences,
  UpdateUserPreferences,
  ReadUsers,
  QueryUser,
  ReadConnectUser,
  CreateInviteUser,
  ReadUser,
  UpdateUserProvider,
  UpdateUser, QueryLog, ReadLogs, ReadUsersKanban, IUser, UpdateUserEmail, DeleteAccount,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class User extends Resource {
  get (): Promise<IUser> {
    return this.axios.$get('/user')
  }

  update (payload: UpdateUser): Promise<ReadUser> {
    return this.axios.$patch('/user', payload)
  }

  updateById (id: string, payload: UpdateUser): Promise<ReadUser> {
    return this.axios.$patch(`/user/${id}`, payload)
  }

  updateEmail (payload: UpdateUserEmail): Promise<ReadUser> {
    return this.axios.$patch('/user/email', payload)
  }

  sendOneTimeCodeVerification () {
    return this.axios.$post('/user/verification-code')
  }

  changeProvider (payload: UpdateUserProvider): Promise<ReadUser> {
    return this.axios.$patch('/user/provider', payload)
  }

  getById (id: string): Promise<ReadUser> {
    return this.axios.$get(`/user/retrieve/${id}`)
  }

  getAll (params: QueryUser): Promise<ReadUsers> {
    return this.axios.$get('/user/all', { params })
  }

  getUsersAutocomplete (params: QueryUser): Promise<ReadUser[]> {
    return this.axios.$get('/user/allAutocomplete', { params })
  }

  getAllKanban (params: QueryUser): Promise<ReadUsersKanban[]> {
    return this.axios.$get('/user/allKanban', { params })
  }

  getOneColKanban (params: QueryUser): Promise<ReadUsersKanban> {
    return this.axios.$get('/user/colKanban', { params })
  }

  getAdminUsers (): Promise<ReadUser> {
    return this.axios.$get('/user/allAdmin')
  }

  getLogs (params: QueryLog): Promise<ReadLogs> {
    return this.axios.$get('/user/logs', { params })
  }

  connect (id: string): Promise<ReadConnectUser> {
    return this.axios.$post(`/user/connect/${id}`)
  }

  getPreferences (): Promise<ReadUserPreferences> {
    return this.axios.$get('/user/preferences')
  }

  invite (payload: CreateInviteUser) {
    return this.axios.$post('/user/invite', payload)
  }

  updatePreferences (payload: UpdateUserPreferences): Promise<ReadUserPreferences> {
    return this.axios.$put('/user/preferences', payload)
  }

  deleteAccount (params: DeleteAccount): Promise<boolean> {
    return this.axios.$delete('/gdpr', { params })
  }

  deleteAccountByAdmin (id: string): Promise<boolean> {
    return this.axios.$delete(`/gdpr/${id}`)
  }
}
