import dayjs from 'dayjs';

import {
  CA, IIncomeBookItem,
  ITransaction,
  Periode, ReadDeclarationTotals, Recurrence,
} from '../types';
import 'dayjs/plugin/quarterOfYear';

const isBetween = require('dayjs/plugin/isBetween');
const QuarterOfYear = require('dayjs/plugin/quarterOfYear');
const fr = require('dayjs/locale/fr');

dayjs.extend(isBetween);
dayjs.extend(QuarterOfYear);
dayjs.locale(fr);

export type DeclarationDates = {
  openingDate: dayjs.Dayjs,
  dueDate: dayjs.Dayjs,
  period: {
    from: dayjs.Dayjs,
    to: dayjs.Dayjs,
  },
};

export type UrssafEstimationsForm = {
  merchandise: {
    amount: number,
    transactions: ITransaction[] | IIncomeBookItem[],
  },
  servicePrestations: {
    amount: number,
    transactions: ITransaction[] | IIncomeBookItem[],
  },
  otherPrestations?: {
    amount: number,
    transactions: ITransaction[] | IIncomeBookItem[],
  },
  cipav?: {
    amount: number,
    transactions: ITransaction[] | IIncomeBookItem[],
  },
  renting?: {
    amount: number,
    transactions: ITransaction[] | IIncomeBookItem[],
  },
};

export const getRecurrenceText = (declarationRecurrence: Recurrence | undefined): string => {
  if (!declarationRecurrence) return '';
  if (declarationRecurrence === Recurrence.YEAR) return 'Annuelle';
  if (declarationRecurrence === Recurrence.QUARTER) return 'Trimestrielle';
  return 'Mensuelle';
};

// @ts-ignore
const today = dayjs();

export const getPeriodText = (periode?: Periode, _date?: dayjs.Dayjs | Date, recurrence?: Recurrence): string => {
  if (periode) {
    const text = periode.mois
    // @ts-ignore
      ? dayjs(`${periode.annee}-${periode.mois}-01`).format('MMMM YYYY')
      : `Trimestre ${periode.trimestre} - ${periode.annee}`;
    return text[0].toUpperCase() + text.slice(1);
  }
  // @ts-ignore
  const date = dayjs(_date || new Date());

  if (recurrence === Recurrence.QUARTER) {
    const year = date.format('YYYY');
    const quarter = (date as any).quarter();
    return `Trimestre ${quarter} - ${year}`;
  }

  return date.format('MMMM YYYY');
};

export const calculTotalCotisations = (estimations?: ReadDeclarationTotals | null) => {
  if (!estimations) {
    return 0;
  }
  const totalsCotisations = Object.entries(estimations?.cotisations || {}).reduce((acc, [_, value]) => (value as number) + acc, 0);
  const totalsTaxes = Object.entries(estimations?.taxes || {}).reduce((acc, [_, value]) => (value as number) + acc, 0);
  return totalsTaxes + totalsCotisations;
};

export const calculTotalBalance = (estimations: ReadDeclarationTotals) => Object.entries(estimations?.balances || {}).reduce((acc, [_, value]) => (value as number) + acc, 0);

export const calculatePercentage = (balance: number, cotisation: number, acre?: number) => {
  if (acre) { return acre; }
  const percentage = Number(cotisation / balance);
  if (Number.isNaN(percentage)) { return null; }
  return (Math.round(percentage * 1000) / 1000) * 100;
};

export const getCotisationsDetails = (estimations: ReadDeclarationTotals | null) => {
  const {
    cotisations, taxes, // acreRates, balances,
  } = estimations || {};

  const details = [
    {
      text: 'Vente de marchandises (BIC)',
      amount: (cotisations?.merchandise || 0) / 100,
    },
    {
      text: 'Prestations de services (BIC)',
      amount: (cotisations?.servicePrestations || 0) / 100,
    },
    {
      text: 'Prestations de services (BNC)',
      amount: (cotisations?.otherPrestations || 0) / 100,
    },
    {
      text: 'Formation commerçant obligatoire',
      amount: (cotisations?.formation || 0) / 100,
    },
    {
      text: 'Taxe chambre consulaire vente',
      amount: (cotisations?.taxeVentes || 0) / 100,
    },
    {
      text: 'Taxe chambre consulaire prestations de services',
      amount: (cotisations?.taxePrestations || 0) / 100,
    },
  ];

  if (taxes) {
    return [
      ...details,
      {
        text: 'Versement libératoire prestations BIC',
        amount: taxes.servicePrestations / 100,
      },
      {
        text: 'Versement libératoire vente BIC',
        amount: taxes.merchandise / 100,
      },
      {
        text: 'Versement libératoire prestations BNC',
        amount: taxes.otherPrestations / 100,
      },
    ];
  }

  return details;
};

export const isWaitingForFirstDeclaration = (_creationDate: dayjs.Dayjs | Date, recurrence: Recurrence): boolean => {
  // @ts-ignore
  const creationDate = dayjs(_creationDate);

  if (recurrence === Recurrence.MONTH) {
    return creationDate.isSame(new Date(), 'month');
  }

  return creationDate.isSame(new Date(), 'quarter' as any);
};

export const concatUrssafEstimationsAndTransactions = ({
  ca, transactions, isCipav,
}: { ca?: CA | null, transactions?: ReadDeclarationTotals['transactions'], isCipav: boolean }): UrssafEstimationsForm => {
  const {
    caVente = 0, caPrestationAutres = 0, caPrestationService = 0, recettesCipav = 0,
  } = ca || {};

  const { otherPrestations = [], servicePrestations = [], merchandise = [] } = transactions || {};

  return {
    merchandise: {
      amount: caVente,
      transactions: merchandise,
    },
    servicePrestations: {
      amount: caPrestationService,
      transactions: servicePrestations,
    },
    ...isCipav
      ? {
        cipav: {
          amount: recettesCipav,
          transactions: otherPrestations,
        },
      }
      : {
        otherPrestations: {
          amount: caPrestationAutres,
          transactions: otherPrestations,
        },
      },
  };
};

export const isDeclarationPeriodOpen = (_creationDate?: dayjs.Dayjs | Date | null, recurrence?: Recurrence | null): boolean => {
  // @ts-ignore

  if (!_creationDate || !recurrence) {
    return false;
  }

  // @ts-ignore
  const creationDate = dayjs(_creationDate);

  if (isWaitingForFirstDeclaration(creationDate, recurrence)) {
    return false;
  }

  if (recurrence === Recurrence.MONTH) {
    return true;
  }

  return today.startOf('quarter' as any).isSame(today, 'month');
};

export const getNextDeclaration = (recurrence: Recurrence): DeclarationDates => {
  if (recurrence === Recurrence.MONTH) {
    return {
      openingDate: today.endOf('month').add(1, 'day'),
      dueDate: today.endOf('month').add(1, 'day').endOf('month'),
      period: {
        from: today.startOf('month'),
        to: today.endOf('month'),
      },
    };
  }

  return {
    openingDate: today.endOf('quarter' as any).add(1, 'day'),
    dueDate: today.endOf('quarter' as any).add(1, 'day').endOf('month'),
    period: {
      from: today.startOf('quarter' as any),
      to: today.endOf('quarter' as any),
    },
  };
};

export const isFirstDeclarations = (_creationDate: Date | dayjs.Dayjs, recurrence: Recurrence): boolean => {
  // @ts-ignore
  const creationDate = dayjs(_creationDate);
  const dateExigibilite = recurrence === Recurrence.MONTH
    ? creationDate.add(4, 'month').endOf('month')
    : creationDate.add(1, 'quarter' as any).add(1, 'month').endOf('month');

  // @ts-ignore
  return dayjs().isBetween(creationDate, dateExigibilite, 'day', '[]');
};

export const getCurrentDeclaration = (recurrence: Recurrence): DeclarationDates => {
  // @ts-ignore
  if (recurrence === Recurrence.MONTH) {
    return {
      dueDate: today.endOf('month'),
      openingDate: today.startOf('month'),
      period: {
        from: today.subtract(1, 'month').startOf('month'),
        to: today.subtract(1, 'month').endOf('month'),
      },
    };
  }

  return {
    dueDate: today.endOf('month'),
    openingDate: today.startOf('month'),
    period: {
      from: today.subtract(1, 'quarter' as any).startOf('quarter' as any),
      to: today.subtract(1, 'quarter' as any).endOf('quarter' as any),
    },
  };
};
