import { BankItem } from '~/logic/contexts/bank/domain/entity/bankItem.entity'

type BankAccountProps = {
    id: string;
    name: string;
    balance: number;
    isSynced: boolean;
    isSyncing?: boolean;
    bankItem?: BankItem;
    transactionsToAnnotate: number;
}

export class BankAccount {
    readonly id: string;
    readonly name: string;
    readonly isSynced: boolean;
    readonly balance: number;
    readonly bankItem?: BankItem;
    readonly transactionsToAnnotate: number;
    isSyncing: boolean;

    private constructor (props: BankAccountProps) {
      this.id = props.id
      this.name = props.name
      this.isSynced = props.isSynced
      this.balance = props.balance
      this.isSyncing = props.isSyncing || false
      this.bankItem = props.bankItem
      this.transactionsToAnnotate = props.transactionsToAnnotate || 0
    }

    static create (props: BankAccountProps): BankAccount {
      return new BankAccount(props)
    }
}
