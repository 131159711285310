import type {
  CreateTask, CreateTaskNote, QueryTasks, ReadCountTasks, ReadTask, ReadTaskNote, UpdateTask,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Task extends Resource {
  createTask (payload: CreateTask): Promise<ReadTask> {
    return this.axios.$post('/task', payload)
  }

  getTasks (params: QueryTasks): Promise<{ totalItems: number; data: ReadTask[] }> {
    return this.axios.$get('/tasks', { params })
  }

  createTaskNote (id: string, payload: CreateTaskNote): Promise<ReadTaskNote> {
    return this.axios.$post(`/task/${id}/note`, payload)
  }

  removeTaskNote (id: string) {
    return this.axios.$delete(`/task/note/${id}`)
  }

  getTaskNotes (id: string): Promise<ReadTaskNote[]> {
    return this.axios.$get(`/task/${id}/notes`)
  }

  updateTask (id: string, payload: UpdateTask): Promise<ReadTask> {
    return this.axios.$patch(`/task/${id}`, payload)
  }

  removeTask (id: string) {
    return this.axios.$delete(`/task/${id}`)
  }

  countTasks (): Promise<ReadCountTasks> {
    return this.axios.$get('/task/counts')
  }
}
