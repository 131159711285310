import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { OnboardingStepsCreation, OnboardingStepsGestion, SignupType } from '@abby/core-legacy'

export interface StartState {
  type: SignupType | null;
  step: OnboardingStepsGestion | OnboardingStepsCreation | null;
}

export const state = (): StartState => ({
  type: null,
  step: null,
})

export const getters: GetterTree<StartState, StartState> = {
  type: state => state.type,
  step: state => state.step,
}

export const mutations: MutationTree<StartState> = {
  SET_TYPE (state, value) {
    state.type = value
  },
  SET_STEP (state, value) {
    state.step = value
  },
  RESET (_currentState: StartState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<StartState, StartState> = {
  async fetchOnboarding ({ commit }) {
    const result = await this.$api.onboarding.get()
    commit('SET_TYPE', result.type)
    commit('SET_STEP', result.step)
  },
}
