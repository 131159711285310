import map from 'lodash/map'
import debounce from 'lodash/debounce'
import deburr from 'lodash/deburr'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import reject from 'lodash/reject'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { Plugin } from '@nuxt/types'
import uniqBy from 'lodash/uniqBy'

type LodashStatic = {
  map: typeof map,
  deburr: typeof deburr,
  debounce: typeof debounce,
  cloneDeep: typeof cloneDeep,
  throttle: typeof throttle,
  reject: typeof reject,
  merge: typeof merge,
  isEqual: typeof isEqual,
  pick: typeof pick,
  pickBy: typeof pickBy,
  uniqBy: typeof uniqBy,
  omit: typeof omit,
  set: typeof set,
}

const lodash: LodashStatic = {
  map,
  deburr,
  debounce,
  cloneDeep,
  throttle,
  reject,
  merge,
  isEqual,
  pick,
  pickBy,
  uniqBy,
  omit,
  set,
}

const LodashPlugin: Plugin = (_, inject) => {
  inject('lodash', lodash)
}

export default LodashPlugin

declare module 'vue/types/vue' {
  interface Vue {
    $lodash: LodashStatic
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $lodash: LodashStatic
  }
  interface Context {
    $lodash: LodashStatic
  }
}

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $lodash: LodashStatic
  }
}
