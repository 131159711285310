import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'

export const useOpenBillingTitleModal = () => {
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    return await billingStore.openBillingTitleModal(billing.id)
  }
}
