import { ReadOpportunityCategory } from '@abby/shared'
import { Category } from '~/logic/contexts/opportunity/domain/entity/category.entity'

export class CategoryHttpMapper {
  static toDomain (category: ReadOpportunityCategory): Category {
    return Category.create({
      id: category.id,
      name: category.name,
      showCustomer: category.showCustomer,
      showDueDate: category.showDueDate,
      showAmount: category.showAmount,
    })
  }
}
