import { Plugin } from '@nuxt/types'

export interface Site {
  isDashboard: boolean;
  isLanding: boolean;
  isMobile: boolean;
  isAndroid: boolean;
}

const SitePlugin: Plugin = ({ req }, inject) => {
  inject('site', {
    get isDashboard () {
      if (process.browser) {
        return !!/^dashboard/.exec(window.location.host)
      }
      return !!/^dashboard/.exec(req.headers.host || '')
    },
    get isLanding () {
      return !!process.env.LANDING_MODE
    },
    get isMobile () {
      if (process.browser) {
        return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.exec(window.navigator.userAgent)
      }
      return false
    },
    get isAndroid () {
      if (process.browser) {
        return !!/Android/i.exec(window.navigator.userAgent)
      }
      return false
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $site: Site
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $site: Site
  }
  interface Context {
    $site: Site
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $site: Site
  }
}

export default SitePlugin
