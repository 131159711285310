
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ModalSkeleton from '~/components-legacy/skeletons/ModalSkeleton.vue'

@Component({
  components: {
    ModalSkeleton,
  },
})
export default class Modal extends Vue {
  @Prop({ default: false })
  loading!: boolean

  @Prop({ required: false, default: false })
  hideClose!: boolean;

  @Prop({ required: false, default: false })
  heightFull!: boolean;

  close () {
    this.$emit('close')
  }

  get _heightFull () {
    return this.heightFull || typeof this.heightFull === 'string'
  }

  get _hideClose () {
    return this.hideClose || typeof this.hideClose === 'string'
  }
}
