import { Money, PaymentMethod } from '@abby/shared'

export type BillingPaymentProps = {
  id: string
  transactionId?: string
  method: PaymentMethod
  otherMethod?: string;
  amount: Money
  receivedAt: number
};

export class BillingPayment {
  readonly id: string;
  readonly transactionId?: string;
  readonly method: PaymentMethod;
  readonly otherMethod?: string;
  readonly amount: Money;
  readonly receivedAt: number;

  private constructor (props: BillingPaymentProps) {
    this.id = props.id
    this.transactionId = props.transactionId
    this.method = props.method
    this.otherMethod = props.otherMethod
    this.amount = props.amount
    this.receivedAt = props.receivedAt
  }

  public static create (props: BillingPaymentProps): BillingPayment {
    return new BillingPayment(props)
  }
}
