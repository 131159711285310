import { VatCode } from '../enums';

export const mapVatToLegacyVatId = {
  [VatCode.FR_2000]: 1,
  [VatCode.FR_1000]: 2,
  [VatCode.FR_850]: 6,
  [VatCode.FR_550]: 3,
  [VatCode.FR_210]: 4,
  [VatCode.FR_00HT]: 5,
  [VatCode.FR_00UE]: 7,
  [VatCode.FR_0HUE]: 8,
};

export const mapLegacyVatIdToVat = {
  1: VatCode.FR_2000,
  2: VatCode.FR_1000,
  6: VatCode.FR_850,
  3: VatCode.FR_550,
  4: VatCode.FR_210,
  5: VatCode.FR_00HT,
  7: VatCode.FR_00UE,
  8: VatCode.FR_0HUE,
};

export const mapLegacyVatRateToVat = {
  210: VatCode.FR_210,
  550: VatCode.FR_550,
  850: VatCode.FR_850,
  1000: VatCode.FR_1000,
  2000: VatCode.FR_2000,
};
