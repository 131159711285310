import { IProduct, findVatFromId } from '@abby/core-legacy'
import {
  mapLegacyProductTypeToProductType,
  mapLegacyUnitToUnit,
  UrssafProductTypeNatureCode,
  mapCodeNatureToActivity,
  mapLegacyVatIdToVat,
} from '@abby/shared'
import { ProductForm } from '../../domain/form/product.form'

export const mapLegacyProductToProductForm = (legacyProduct: Partial<IProduct>) : Partial<ProductForm> => {
  return {
    id: legacyProduct._id,
    type: legacyProduct.productType ? mapLegacyProductTypeToProductType[legacyProduct.productType] : undefined,
    unit: legacyProduct.productUnit ? mapLegacyUnitToUnit[legacyProduct.productUnit] : undefined,
    designation: legacyProduct.designation,
    amountInCents: legacyProduct.unitPrice,
    taxesIncluded: false,
    vatCode: legacyProduct.vatId ? mapLegacyVatIdToVat[legacyProduct.vatId as 1|2|3|4|5|6|7|8] : undefined,
    isDeliveryOfGood: legacyProduct.isDeliveryOfGood,
    description: legacyProduct.description ?? '',
    reference: legacyProduct.reference,
    personalServiceActivity: legacyProduct.codeNature ? mapCodeNatureToActivity[legacyProduct.codeNature as UrssafProductTypeNatureCode] : undefined,
  }
}
