export enum PersonalServiceActivity {
  GARDE_ENFANT_HANDICAPE = 'GARDE_ENFANT_HANDICAPE',
  ACCOMPAGNEMENT_ENFANT_HANDICAPE = 'ACCOMPAGNEMENT_ENFANT_HANDICAPE',
  AIDE_HUMAINE = 'AIDE_HUMAINE',
  CONDUITE_VEHICULE_PERSONNEL = 'CONDUITE_VEHICULE_PERSONNEL',
  AIDE_AU_DEPLACEMENT = 'AIDE_AU_DEPLACEMENT',
  ENTRETIEN_DE_LA_MAISON = 'ENTRETIEN_DE_LA_MAISON',
  JARDINAGE = 'JARDINAGE',
  BRICOLAGE = 'BRICOLAGE',
  GARDE_ENFANT_PLUS_DE_6_ANS = 'GARDE_ENFANT_PLUS_DE_6_ANS',
  SOUTIEN_SCOLAIRE_COURS_A_DOMICILE = 'SOUTIEN_SCOLAIRE_COURS_A_DOMICILE',
  SOIN_ESTHETIQUE_A_DOMICILE = 'SOIN_ESTHETIQUE_A_DOMICILE',
  PREPARATION_REPAS_A_DOMICILE = 'PREPARATION_REPAS_A_DOMICILE',
  LIVRAISON_REPAS_A_DOMICILE = 'LIVRAISON_REPAS_A_DOMICILE',
  COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE = 'COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE',
  LIVRAISON_COURSES_A_DOMICILE = 'LIVRAISON_COURSES_A_DOMICILE',
  ASSISTANCE_INFORMATIQUE = 'ASSISTANCE_INFORMATIQUE',
  SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES = 'SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES',
  MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE = 'MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE',
  ASSISTANCE_ADMINISTRATIVE = 'ASSISTANCE_ADMINISTRATIVE',
  ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS = 'ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS',
  TELEASSISTANCE = 'TELEASSISTANCE',
  INTERPRETE_LANGUE_DES_SIGNES = 'INTERPRETE_LANGUE_DES_SIGNES',
  CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE = 'CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE',
  ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE = 'ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE',
  ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE = 'ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE',
  COORDINATION_ET_GESTION = 'COORDINATION_ET_GESTION',
  DIVERS_NON_ELIGIBLE = 'DIVERS_NON_ELIGIBLE',
}