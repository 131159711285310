import { GuideLevelItem, GuideStepItem } from '@abby/core-legacy'

export const configBuilderGroupB = (getters: any, rootGetters: any, xpm: any, options: any) => [
  {
    title: 'Facturez en quelques clics',
    duration: 'Environ 8 min',
    level: GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY,
    iconUrl: '/onboarding/guide/levels/facturation.png',
    steps: [
      {
        step: GuideStepItem.CREATE_YOUR_FIRST_DOCUMENT,
        title: 'Créer votre premier document',
        content: `
        Pour commencer avec la facturation, créez votre premier document en quelques étapes simples. Saisissez les informations de votre client, ajoutez les produits ou services fournis, et générez un document professionnel en un rien de temps. 
        <br><br>
        Cette étape vous permettra de gérer facilement vos transactions et de maintenir une comptabilité précise.
        `,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/facture.png',
        videoUrl: '',
        action: {
          text: 'Créer mon premier document',
          icon: '',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.DISABLE_TEST_MODE,
        title: 'Désactivez le mode test',
        content: `
        Pour assurer la conformité avec la loi en cas de contrôle, désactivez le mode test de l'application. 
        <br><br>
        Cela permet de commencer à attribuer des numéros uniques aux factures dans une séquence chronologique continue, garantissant que chaque transaction est correctement enregistrée et suivie. 
        `,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/test-mode.png',
        videoUrl: '',
        action: {
          text: 'Désactiver le mode test',
          icon: 'toggle_off',
          iconDirection: 'left',
          disabled: !options.canDisableTestMode,
        },
      },
    ],
  },
  {
    title: 'Laissez nous faire la comptabilité à votre place',
    level: GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY,
    duration: 'Environ 5 min',
    iconUrl: '/onboarding/guide/levels/comptabilite.png',
    steps: [
      {
        isDisplayable: true,
        step: GuideStepItem.ADD_AN_ENTRY_TO_YOUR_SALES_BOOK,
        title: 'Ajoutez des recettes dans votre livre des recettes',
        iconUrl: '',
        content: 'Simplifiez votre gestion comptable en nous laissant éditer votre livre des recettes. Abby compile automatiquement toutes vos entrées financières, générant un document clair et conforme aux exigences légales.',
        imageUrl: '/onboarding/guide/steps/livre-recettes.png',
        videoUrl: '',
        action: {
          text: 'Ajouter mes recettes',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.DISCOVER_URSSAF_SPACE,
        title: 'Estimez et déclarez votre URSSAF',
        iconUrl: '',
        content: `
        Simplifiez vos obligations administratives en utilisant notre fonctionnalité d'estimation et de déclaration de l'URSSAF. Grâce à notre partenariat officiel, vous pouvez estimer précisément vos cotisations sociales et soumettre vos déclarations directement dans Abby. 
        <br><br>
        Cette fonctionnalité vous aide à rester en conformité avec les régulations en vigueur, vous libérant du temps pour vous concentrer sur la gestion et la croissance de votre entreprise.
        `,
        imageUrl: '/onboarding/guide/steps/urssaf.png',
        videoUrl: '',
        action: {
          text: 'Découvrir l\'espace URSSAF',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.DISCOVER_STATISTICS_SPACE,
        title: 'Accédez à des statistiques détaillées sur votre activité',
        iconUrl: '',
        content: `
        Optimisez la gestion de votre entreprise en accédant à des statistiques détaillées sur votre activité directement dans Abby. Analysez vos performances financières, identifiez les tendances et prenez des décisions éclairées grâce à des rapports clairs et précis. 
        <br><br>
        Cette fonctionnalité vous permet de suivre votre progression, d'ajuster vos stratégies et de favoriser la croissance de votre entreprise en toute simplicité.
        `,
        imageUrl: '/onboarding/guide/steps/statistiques.png',
        videoUrl: '',
        action: {
          text: 'Décrouvrir la page analyse',
          iconDirection: 'left',
        },
      },

    ],
  },
  {
    title: 'Organisez votre travail',
    level: GuideLevelItem.GET_ORGANIZE_EASILY,
    duration: 'Environ 2 min',
    iconUrl: '/onboarding/guide/levels/organisation.png',
    steps: [
      {
        step: GuideStepItem.DISCOVER_PRODUCTIVITY_PRODUCTS,
        title: 'Découvrez les outils de productivité',
        iconUrl: '',
        content: `
        Améliorez votre efficacité en explorant les outils de productivité disponibles dans Abby. Gérez vos tâches, planifiez vos projets et optimisez votre temps avec des fonctionnalités conçues pour simplifier votre travail quotidien. 
        <br><br>
        Ces outils vous aident à rester organisé, à mieux prioriser vos activités et à maximiser votre productivité, vous permettant de vous concentrer sur l'essentiel.
        `,
        imageUrl: '/onboarding/guide/steps/productivite.png',
        videoUrl: '',
        action: {
          text: 'Découvrir les outils de productivité',
          iconDirection: 'left',
        },
      },

    ],
  },
]
