export enum CommercialOffers {
  MEETING_WITH_AN_EXPERT = 'meeting_with_an_expert',
  ABBY_PLUS = 'abby_plus',
  ABBY_PRO = 'abby_pro',
  ABBY_CONTACT = 'abby_contact',
  ACRE = 'acre',
  CFE = 'cfe',
  EDIT_ENTREPRISE = 'edit_entreprise',
  FORMATION_CLIENT_COMMUNIQUER_DISCOUNT = 'formation_client_communiquer_discount',
  ABBY_ACADEMIE_MARKETING_COMMUNICATION = 'abby_academie_marketing_communication',
  ABBY_ACADEMIE_CREATION = 'abby_academie_creation',
  STRIPE = 'stripe',
  ADVANCED_CRM = 'advanced_crm',
  PROJECT_MANAGEMENT = 'project_management',
  MARKETPLACE = 'marketplace',
  AUTOMATED_IMPORT = 'automated_import',
  WEBSITE_PLUGIN = 'website_plugin',
  FORMATION_CLIENT = 'formation_client',
  FORMATION_COMMUNIQUER = 'formation_communiquer',
  FORMATIONS = 'formations',
  BILLING_TEMPLATE_2 = 'billing_template_2',
  BILLING_TEMPLATE_3 = 'billing_template_3',

  LUKO = 'luko',
  SHINE = 'shine',
  KANDBAZ = 'kandbaz',

  ASSURUP = 'assurup',
  AUDIT_ASSURUP = 'audit_assurup',
  MOBEYE = 'mobeye',
  SITE_W = 'site_w',
  SIMPLIS = 'simplis',
  CARAVEL = 'caravel',
  FINFROG = 'finfrog',
  MUTUMUTU = 'mutumutu',
  JE_GERE_MA_MICRO_ENTREPRISE = 'je_gere_ma_micro_entreprise',
  SOCIC = 'socic',
  PYLOTE_IO = 'pylote_io',
  MON_PETIT_PLACEMENT = 'mon_petit_placement',
  CAPTAIN_CONTRAT_CONSEIL = 'captaincontratconseil',
  CAPTAIN_CONTRAT_DEMARCHES = 'captaincontratdemarches',
  SUMUP = 'sumup',
  CHERRYPICK = 'cherrypick',
  BUREAU132 = 'bureau132',
  STUART = 'stuart',
  WEBMYDAY = 'webmyday',

  BANK = 'bank',
  INSURANCE = 'insurance',
  CHALLENGE_MY_CONTRACTS = 'challenge_my_contracts',
  POCKET_MONEY = 'pocket_money',
  RETIREMENT = 'retirement',
  BORROW_QUICKLY = 'borrow_quickly',
  INVEST = 'invest',
  VISIBILITY = 'visibility',
  ACCOUNTANT = 'accountant',
}

export enum UTMCampaignItem {
  INSCRIPTION = 'inscription',
  TRAFFIC = 'traffic',
  COMMERCIAL_OFFER = 'commercial_offer',
  INSIGHT = 'insight',
  PERFORMANCE = 'performance',
}

export enum UTMMediumItem {
  PROMO_CARD = 'promo_card',
  ADVANTAGE_CARD = 'advantage_card',
  PREMIUM = 'premium',
  ARTICLE = 'article',
  EMAIL_ARTICLE = 'email_article',
  EMAIL_BUTTON = 'email_button',
  EMAIL_BANNER = 'email_banner',
  GROUP_COMMENT = 'group_comment',
  GROUP_POST = 'group_post',
  PAGE_COMMENT = 'page_comment',
  PAGE_POST = 'page_post',
  BIO_SOCIAL = 'bio_social',
  AFFILAE = 'affilae',
}

export enum UTMSourceItem {
  APP_WEB = 'app_web',
  SITE_WEB = 'site_web',
  FACEBOOK = 'facebook',
  FACEBOOK_GROUP = 'facebook_group',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  GOOGLE_MY_BUSINESS = 'google_my_business',
  TIKTOK = 'tiktok',
  YOUTUBE = 'youtube',
  HELP_DESK = 'help_desk',
  NEWSLETTER = 'newsletter',
  CUSTOMER_JOURNEY = 'customer_journey',
  ENZO_MUSUMECI = 'enzo_musumeci',
  BENJAMIN_GARDIEN = 'benjamin_gardien',
  CORENTIN_ALLEMAND = 'corentin_allemand',
  MYRIAM_KISSARI = 'myriam_kissari',
  LOUIS_RIBOULOT = 'louis_riboulot',
  LEA_ROLLAUER = 'lea_rollauer',
  LOU_SERNAGLIA = 'lou_sernaglia',
  AFFILAE = 'affilae',
}

export enum AbbyPixelEvent {
  CLICK = 'click',
  IMPRESSION = 'impression',
  CONVERSION = 'conversion',
  LEAD = 'lead',
  COLLECT_FEES = 'collect_fees',
  INSIGHT = 'insight',
  FID = 'fid',
}

export enum CommercialOffersUTMMedium {
  PROMO_CARD = 'promo_card',
  COURSE_SHOWCASE = 'course_showcase',
  LESSON_VIDEO_PLAYER = 'lesson_video_player',
  DOCUMENTS = 'documents',
  FEATURES_PLAN = 'features_plan',
  FORMATION_CARD = 'formation_card',
  ADVANTAGE_CARD = 'advantage_card',
  PREMIUM = 'premium',
  CREATION_STARTING = 'creation_starting',
  DRAWER_SHOWCASE = 'drawer_showcase',
  PRICING_PLANS = 'pricing_plans',
  FINFROG_INTRUSIVE_MODAL = 'finfrog_intrusive_modal',
  FINFROG_MODAL = 'finfrog_modal',
  CFE_INTRUSIVE_MODAL = 'cfe_intrusive_modal',
  CFE_MODAL = 'cfe_modal',
  ACRE_CREATION_FORM_STEP = 'acre_creation_form_step',
  UPSELLS_MODAL = 'upsells_modal',
  CLIENT_PORTAL = 'client_portal',
  DISCOVERY = 'discovery',
  ABBY_STORE_PAGE = 'abby_store_page',
  ABBY_STORE_PRODUCTS = 'abby_store_products',
  ABBY_STORE_PRODUCT = 'abby_store_product',
  ABBY_STORE_PRODUCT_S1 = 'abby_store_product_s1',
  DEPOSIT_DOCUMENT_MODAL_END = 'deposit_document_modal_end',
}

export const CommercialOffersUrls = {
  [CommercialOffers.LUKO]: 'https://app-abby.com/go/luko',
  [CommercialOffers.CARAVEL]: 'https://app-abby.com/go/caravel',
  [CommercialOffers.SHINE]: 'https://app-abby.com/go/shine',
  [CommercialOffers.KANDBAZ]: 'https://app-abby.com/go/kandbaz',
  [CommercialOffers.ASSURUP]: 'https://app-abby.com/go/assurup',
  [CommercialOffers.MOBEYE]: 'https://app-abby.com/go/mobeye',
  [CommercialOffers.SITE_W]: 'https://app-abby.com/go/sitew',
  [CommercialOffers.SIMPLIS]: 'https://app-abby.com/go/simplis',
  [CommercialOffers.MEETING_WITH_AN_EXPERT]: 'https://app-abby.com/go/accompagnement',
  [CommercialOffers.ABBY_PLUS]: 'https://app-abby.com/go/abby-plus',
  [CommercialOffers.EDIT_ENTREPRISE]: 'https://app-abby.com/go/modifier-mon-ae',
  [CommercialOffers.FINFROG]: 'https://app-abby.com/go/finfrog',
  [CommercialOffers.MUTUMUTU]: 'https://app-abby.com/go/mutumutu',
  [CommercialOffers.PYLOTE_IO]: 'https://app-abby.com/go/pylote',
  [CommercialOffers.STUART]: 'https://app-abby.com/go/stuart',
  [CommercialOffers.SUMUP]: 'https://app-abby.com/go/sumup',
  [CommercialOffers.WEBMYDAY]: 'https://app-abby.com/go/webmyday',
};
