/*
   * Core Legacy
   * {@link https://abby.fr}
   * @copyright Abby
   * @license All rights reserved
   */import{IssueLabelType as E}from"./IssueLabelType.js";const e=[{type:E.BUG,description:`Date de l'erreur : **DATE** 

Informations sur l'erreur : **CODE ERREUR, MESSAGE D'ERREUR, ETC** 

Actions pour reproduire l'erreur : **PROCESSUS REPRODUCTION DU PROBLEME RENCONTR\xC9**`},{type:E.DATA,description:""},{type:E.NEW_FEATURE,description:""},{type:E.SUPPORT,description:""},{type:E.IMPROVEMENT,description:`Probl\xE9matique : **PROBLEMATIQUE** 

Id\xE9e d'am\xE9lioration : **IDEES POUR RESOUDRE LA PROBLEMATIQUE**`}];export{e as IssueTemplate};
