import { GetterTree, ActionTree, MutationTree } from 'vuex'
import type { QueryLog, ReadLogs } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface UserState {
  isAvatarUpdateModalOpened: boolean,
  isCivilityUpdateModalOpened: boolean,
  isEmailUpdateModalOpened: boolean,
  isProviderUpdateModalOpened: boolean,
  isDeleteAccountModalOpened: boolean,
  isPhoneNumberUpdateModalOpened: boolean,
  isNameUpdateModalOpened: boolean,
  isSponsorshipUpdateModalOpened: boolean,
  isBirthDateUpdateModalOpened: boolean,
  paginationLogsQuery: QueryLog,
  logs: ReadLogs | null,
  queryLogsLoading: boolean,
}

export const state = (): UserState => ({
  isAvatarUpdateModalOpened: false,
  isCivilityUpdateModalOpened: false,
  isEmailUpdateModalOpened: false,
  isPhoneNumberUpdateModalOpened: false,
  isProviderUpdateModalOpened: false,
  isDeleteAccountModalOpened: false,
  isNameUpdateModalOpened: false,
  isSponsorshipUpdateModalOpened: false,
  isBirthDateUpdateModalOpened: false,
  paginationLogsQuery: {
    page: 1,
    limit: 5,
  },
  logs: null,
  queryLogsLoading: false,
})

export const getters: GetterTree<UserState, RootState> = {
  isAvatarUpdateModalOpened: state => state.isAvatarUpdateModalOpened,
  isCivilityUpdateModalOpened: state => state.isCivilityUpdateModalOpened,
  isEmailUpdateModalOpened: state => state.isEmailUpdateModalOpened,
  isProviderUpdateModalOpened: state => state.isProviderUpdateModalOpened,
  isDeleteAccountModalOpened: state => state.isDeleteAccountModalOpened,
  isPhoneNumberUpdateModalOpened: state => state.isPhoneNumberUpdateModalOpened,
  isNameUpdateModalOpened: state => state.isNameUpdateModalOpened,
  isSponsorshipUpdateModalOpened: state => state.isSponsorshipUpdateModalOpened,
  isBirthDateUpdateModalOpened: state => state.isBirthDateUpdateModalOpened,
  paginationLogsQuery: state => state.paginationLogsQuery,
  logs: state => state.logs,
  queryLogsLoading: state => state.queryLogsLoading,
}

export const mutations: MutationTree<UserState> = {
  SET_IS_UPDATE_AVATAR_MODAL_OPENED: (state, value: boolean) => {
    state.isAvatarUpdateModalOpened = value
  },
  SET_IS_UPDATE_CIVILITY_MODAL_OPENED: (state, value: boolean) => {
    state.isCivilityUpdateModalOpened = value
  },
  SET_IS_UPDATE_BIRTH_DATE_MODAL_OPENED: (state, value: boolean) => {
    state.isBirthDateUpdateModalOpened = value
  },
  SET_IS_UPDATE_EMAIL_MODAL_OPENED: (state, value: boolean) => {
    state.isEmailUpdateModalOpened = value
  },
  SET_IS_UPDATE_PROVIDER_MODAL_OPENED: (state, value: boolean) => {
    state.isProviderUpdateModalOpened = value
  },
  SET_IS_DELETE_ACCOUNT_MODAL_OPENED: (state, value: boolean) => {
    state.isDeleteAccountModalOpened = value
  },
  SET_IS_UPDATE_PHONE_NUMBER_MODAL_OPENED: (state, value: boolean) => {
    state.isPhoneNumberUpdateModalOpened = value
  },
  SET_IS_UPDATE_NAME_MODAL_OPENED: (state, value: boolean) => {
    state.isNameUpdateModalOpened = value
  },
  SET_IS_UPDATE_SPONSORSHIP_MODAL_OPENED: (state, value: boolean) => {
    state.isSponsorshipUpdateModalOpened = value
  },
  SET_QUERY_LOGS_LOADING: (state, value: boolean) => {
    state.queryLogsLoading = value
  },
  SET_LOGS: (state, value: ReadLogs) => {
    state.logs = value
  },
  UPDATE_LOG_QUERY: (state, query: Partial<QueryLog>) => {
    state.paginationLogsQuery = {
      ...state.paginationLogsQuery,
      ...query,
    }
  },
  RESET (_currentState: UserState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<UserState, RootState> = {
  async fetchLogs ({ commit, state }) {
    commit('SET_QUERY_LOGS_LOADING', true)
    try {
      const results = await this.$api.user.getLogs({
        ...state.paginationLogsQuery,
      })
      commit('SET_LOGS', results)
    } finally {
      commit('SET_QUERY_LOGS_LOADING', false)
    }
  },
  updateLogsQuery ({ commit }, query) {
    commit('UPDATE_LOG_QUERY', query)
  },
  setSponsorshipUpdateModal ({ commit }, value: boolean) {
    commit('SET_IS_UPDATE_SPONSORSHIP_MODAL_OPENED', value)
  },
}
