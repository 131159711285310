
import { Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import { Prop } from 'vue-property-decorator'
import ConfirmModal from '~/components-legacy/modals/ConfirmModal.vue'

@Component({
  components: { ConfirmModal },
})
export default class AppSkeletonLoader extends Vue {
  @Getter('app/appLoading') appLoading!: boolean;

  @Prop()
  animation!: boolean

  @Prop()
  mode!: 'normal' | 'zoom'

  timeOutId: any = null

  @Watch('animation')
  handleAnimation () {
    if (this.animation) {
      // setTimeout(() => {
      this.$emit('animation-finished')
      // }, 800)
    }
  }

  startLoading () {
    this.timeOutId = setTimeout(async () => {
      if (!this.appLoading) { return }
      const confirm = await this.$confirmManager.ask({
        title: 'Le chargement semble long',
        confirmText: 'Recharger la page',
        cancelText: 'Continuer',
        content: 'Il est parfois nécessaire de recharger la page pour accéder au logiciel.',
        confirmButton: { color: 'info', depressed: true },
      })
      if (!confirm) { return }
      this.reloadPage()
    }, 15_000)
  }

  @Watch('appLoading')
  appLoadingHandler (value: boolean) {
    if (value) {
      this.startLoading()
    } else {
      this.$confirmManager.clear()
      !!this.timeOutId && clearTimeout(this.timeOutId)
    }
  }

  beforeDestroy () {
    this.$confirmManager.clear()
    !!this.timeOutId && clearTimeout(this.timeOutId)
  }

  reloadPage () {
    // @ts-ignore
    window.location = '/'
  }
}

