
import Vue from 'vue'
import { Component, Getter, Mutation } from 'nuxt-property-decorator'
import { CommercialOffersModals, CommercialOffersState } from '~/store/commercialOffers'
import Page from '~/components-legacy/layout/containers/Page.vue'
import { AuthState } from '~/store/auth'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'

@Component({
  components: {
    Page,
    ASDialog,
  },
})
export default class MeetingWithAnExpert extends Vue {
  close () {
    this.closeModal('meetingWithAnExpert')
  }

  @Mutation('commercialOffers/CLOSE_MODAL')
  closeModal!: (key: CommercialOffersModals) => void

  @Getter('commercialOffers/modals')
  modals!: CommercialOffersState['modals']

  @Getter('auth/user')
  user!: AuthState['user']

  get calendlyLink () {
    const query = {
      email: this.user?.email,
      name: this.user?.fullName,
    }

    function serialize (obj: any) {
      const str = '?' + Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
      return str
    }

    const _query = serialize(query)

    return `https://calendly.com/abby-customer-success/${this.calendlyEvent}${_query === '?' || _query === '' ? '' : _query || ''}`
  }

  get price (): number {
    return 34.99
  }

  get calendlyEvent (): string {
    return 'echangez-avec-un-conseiller-expert-1'
  }
}
