
import { Component, ModelSync, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import AddressInput, { AddressResponse } from '~/components-legacy/inputs/AddressInput.vue'

@Component({
  components: { AddressInput, AsTextField },
})
export default class AddressForm extends Vue {
  @ModelSync('value', 'input', { default: () => ({}) })
  addressForm!: {
    address?: string,
    zipCode?: string,
    cityCode?: string,
    cityName?: string,
    additionalAddress?: string,
  }

  freeAddressInput = false
  isMounted = false
  cityName: string | null = null;

  @Prop({ default: false })
  required!: boolean

  @Prop({ default: false })
  otherCountry!: boolean

  @Prop({ default: true })
  showAddressesAndAdditionnalFields!: boolean

  @Prop({ default: false })
  isForBirthTiersPrestation!: boolean

  @Prop({ default: false })
  tiersPrestationIsActivated!: boolean

  onFocusAddress = false;

  @Ref('address-input')
  addressInput!: AddressInput;

  // Scotch pour faire correspondre les valeurs cityName
  @Watch('addressForm.cityName')
  handleAddressCityNameUpdate (value: string | undefined) {
    if (this.cityName === this.addressForm.cityName) { return }
    this.addressForm.cityName = value
    this.cityName = value || null
  }

  // Scotch pour faire correspondre les valeurs cityName
  @Watch('cityName')
  handleCityNameUpdate (value: string | undefined) {
    if (this.cityName === this.addressForm.cityName) { return }
    this.addressForm.cityName = value
    this.cityName = value || null
  }

  debouncePostCode = this.$lodash.debounce(this.searchCity, 300, { leading: true })

  mounted () {
    this.isMounted = true
    this.cityName = this.addressForm?.cityName || null
  }

  retrievePlaceHolder (value: string) {
    let placeholder = ''

    if (value === 'zipCode') {
      if (this.isForBirthTiersPrestation) {
        placeholder = 'Code postal de naissance'
      } else {
        placeholder = 'Code postal'
      }
    } else if (value === 'city') {
      if (this.isForBirthTiersPrestation) {
        placeholder = 'Commune de naissance'
      } else {
        placeholder = 'Commune'
      }
    }
    if (this.required || this.tiersPrestationIsActivated) {
      placeholder += ' *'
    }
    return placeholder
  }

  async searchCity (value: string) {
    if (value.length !== 5) {
      return
    }
    this.setCityCode()

    if (this.otherCountry) {
      return
    }
    const results = await this.addressInput.loadAddress(value, true)
    if (results.length === 0) {
      this.freeAddressInput = true
      return
    }
    this.freeAddressInput = false
  }

  setCityCode (address?: AddressResponse) {
    this.addressForm.cityCode = address?.citycode || ''
    this.addressForm.cityName = this.cityName = address?.city || ''
    if (!this.addressForm.zipCode) {
      this.addressForm.zipCode = address?.postcode?.trim() || ''
    }
  }
}

