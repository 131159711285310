import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { TrackAdvertising } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface TrackingState {}

export const state = (): TrackingState => ({})

export const getters: GetterTree<TrackingState, RootState> = {}

export const mutations: MutationTree<TrackingState> = {}

export const actions: ActionTree<TrackingState, RootState> = {
  trackNew ({ rootGetters }, payload: any) {
    try {
      const user = rootGetters['auth/user']
      const { action, feature, ...data } = payload
      if (user) {
        this.$api.advertising.trackV2({
          action,
          feature,
          ...data ? { metadata: data } : {},
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        })
      } else {
        this.$api.advertising.trackUnauthenticatedV2({
          action,
          feature,
          ...data ? { metadata: data } : {},
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        })
      }
    } catch {}
  },
}
