
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { SegmentEvent } from '@abby/core-legacy'

@Component({})
export default class SignupButton extends Vue {
  @Prop()
  source!: string;

  @Prop({ required: false, default: false })
  creation!: boolean;

  @Prop({ required: false, default: () => ({}) })
  additionalQuery: any;

  @Prop({ required: false, default: 'Gérer mon entreprise' })
  placeholder!: string;

  @Prop({ required: false, default: 'https://app.abby.fr' })
  baseUrl!: string;

  get isCreationPage () {
    return this.$route.path === '/produits/creation'
  }

  goToSignup () {
    let anId = null
    try {
      this.$ap.segmentTrack(SegmentEvent.SIGN_UP_FOR_FREE_CLICKED, {
        actionLocation: this.source,
      })
      // permet d'envoyer des event pour identifier plus précisément
      if (this.additionalQuery?.feature === 'tiers_prestation') {
        this.$ap.segmentTrack(SegmentEvent.CTA_TIERS_PRESTATION_CLICKED, {
          actionLocation: 'cta_banner',
        })
      }
      // @ts-ignore
      anId = window?.analytics?.user()?.anonymousId()
    } catch (e) {}

    const affiliationUrl = JSON.parse(localStorage.getItem('affiliationUrl') || '{}')
    // abnp = abnumberPricing pour l'ABC testing du prix
    const abnumberPricing = localStorage.getItem('abnp')
    const brandBaseUrl = localStorage.getItem('brandBaseUrl')
    const queryToForward = {
      ...Object.fromEntries(Object.entries(affiliationUrl).filter(([_, v]) => !!v && v !== 'undefined')),
      affilaeClickId: localStorage.getItem('affilaeClickId') || undefined,
      ...this.additionalQuery,
      ...(anId
        ? {
          anId,
        }
        : {}),
      ...(abnumberPricing
        ? {
          abnp: abnumberPricing,
        }
        : {}),
    }
    setTimeout(() => {
      const qs = Object.keys(queryToForward).map((key: string) => {
        if ((queryToForward as any)[key]) {
          return `${key}=${(queryToForward as any)[key]}`
        } return null
      }).filter(qse => !!qse).join('&')
      // @ts-ignore
      window.location = `${brandBaseUrl || this.baseUrl}/start/${this.creation ? 'creation/activity' : 'gestion/company'}` + (qs ? ('?' + qs) : '')
    }, 100)
  }
}
