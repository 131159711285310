import { Currency, CurrencySymbol } from '../types/Enum/Currency';

export interface Amount {
  cents: number
  decimals: number
  decimalsPart: string
  centsPart: string
  decimalSeparator: string
  currency: string
  text: string
}

export const currencyPattern = {
  [Currency.EUR]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.EUR, pattern: '# !', negativePattern: '- # !', isoCode: 'fr-FR',
  },
  [Currency.USD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.USD, pattern: '!#', negativePattern: '- !#', isoCode: 'en-US',
  },
  [Currency.GBP]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.GBP, pattern: '!#', negativePattern: '- !#', isoCode: 'en-GB',
  },
  [Currency.CHF]: {
    precision: 2, decimal: '.', separator: ' ', symbol: CurrencySymbol.CHF, pattern: '#!', negativePattern: '- # !', isoCode: 'fr-CH',
  },
  [Currency.JPY]: {
    precision: 0, decimal: '.', separator: ',', symbol: CurrencySymbol.JPY, pattern: '!#', negativePattern: '- !#', isoCode: 'ja-JP',
  },
  [Currency.CNH]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.CNH, pattern: '!#', negativePattern: '- !#', isoCode: 'zh-CN',
  },
  [Currency.CZK]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.CZK, pattern: '# !', negativePattern: '- # !', isoCode: 'cs-CZ',
  },
  [Currency.DKK]: {
    precision: 2, decimal: ',', separator: '.', symbol: CurrencySymbol.DKK, pattern: '# !', negativePattern: '- # !', isoCode: 'da-DK',
  },
  [Currency.BGN]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.BGN, pattern: '# !', negativePattern: '- # !', isoCode: 'bg-BG',
  },
  [Currency.PLN]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.PLN, pattern: '# !', negativePattern: '- # !', isoCode: 'pl-PL',
  },
  [Currency.HUF]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.HUF, pattern: '# !', negativePattern: '- # !', isoCode: 'hu-HU',
  },
  [Currency.RON]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.RON, pattern: '# !', negativePattern: '- # !', isoCode: 'ro-RO',
  },
  [Currency.SEK]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.SEK, pattern: '# !', negativePattern: '- # !', isoCode: 'sv-SE',
  },
  [Currency.NOK]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.NOK, pattern: '# !', negativePattern: '- # !', isoCode: 'nb-NO',
  },
  [Currency.TRY]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.TRY, pattern: '# !', negativePattern: '- # !', isoCode: 'tr-TR',
  },
  [Currency.BRL]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.BRL, pattern: '# !', negativePattern: '- # !', isoCode: 'pt-BR',
  },
  [Currency.HKD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.HKD, pattern: '!#', negativePattern: '- !#', isoCode: 'zh-HK',
  },
  [Currency.ILS]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.ILS, pattern: '!#', negativePattern: '- !#', isoCode: 'he-IL',
  },
  [Currency.INR]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.INR, pattern: '!#', negativePattern: '- !#', isoCode: 'hi-IN',
  },
  [Currency.KRW]: {
    precision: 0, decimal: '.', separator: ',', symbol: CurrencySymbol.KRW, pattern: '!#', negativePattern: '- !#', isoCode: 'ko-KR',
  },
  [Currency.MXN]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.MXN, pattern: '!#', negativePattern: '- !#', isoCode: 'es-MX',
  },
  [Currency.AUD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.AUD, pattern: '!#', negativePattern: '- !#', isoCode: 'en-AU',
  },
  [Currency.CAD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.CAD, pattern: '!#', negativePattern: '- !#', isoCode: 'en-CA',
  },
};

export function roundDecimal(number: number, _precision?: number) {
  const precision = _precision == null ? 0 : Math.min(Number(_precision), 292);
  if (precision && Number.isFinite(number)) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = (`${String(number)}e`).split('e');
    const value = Math.round(Number(`${pair[0]}e${+pair[1] + precision}`));

    pair = (`${String(value)}e`).split('e');

    return +(`${pair[0]}e${+pair[1] - precision}`);
  }
  return Math.round(number);
}

export function roundAmount(value: number, decimals = 2) {
  return +(Math.round((value + Number.EPSILON) * 100) / 100).toFixed(decimals);
}

export function formatDecimals(value: number | string, currency = Currency.EUR): Amount {
  const pattern = currencyPattern[currency];
  const _value = roundDecimal(Number(value) * 100);
  const formattedValue = new Intl.NumberFormat(pattern.isoCode, {
    minimumFractionDigits: pattern.precision, maximumFractionDigits: pattern.precision, style: 'currency', currency,
  }).format(_value / 100);

  const centsPart = (_value / 100).toString().split('.')[1]?.padEnd(2, '0') ?? '00';
  const decimalsPart = (_value / 100).toString().split('.')[0];
  // Replace special char code from formattedValue
  // See this https://github.com/foliojs/pdfkit/issues/1331#issuecomment-1025118454 for more information
  return {
    cents: _value,
    decimals: _value / 100,
    decimalsPart,
    centsPart,
    decimalSeparator: pattern.decimal,
    currency: pattern.symbol,
    text: formattedValue.replace(/[\u00A0\u1680\u180e\u2000-\u2009\u200a\u200b\u202f\u205f\u3000]/g, ' '),
  };
}

export function formatCents(val: number | string, currency = Currency.EUR, subCents: undefined | number = undefined): Amount {
  const value = val || 0;
  const pattern = currencyPattern[currency];
  const subCentsLength = (value).toString().split('.')[1]?.length ?? 0;
  let precisionValue = subCentsLength && subCents ? subCentsLength + 2 : pattern.precision;
  if (subCentsLength && subCents && precisionValue >= subCents) {
    precisionValue = subCents;
  }
  const decimalForm = Number((Number(value) / 100).toPrecision(15));
  const formattedValue = new Intl.NumberFormat(pattern.isoCode, {
    minimumFractionDigits: precisionValue, maximumFractionDigits: precisionValue, style: 'currency', currency,
  }).format(Number(value) / 100);

  const centsPart = decimalForm.toString().split('.')[1]?.padEnd(precisionValue, '0') ?? '00';
  const decimalsPart = decimalForm.toString().split('.')[0];
  // Replace special char code from formattedValue
  // See this https://github.com/foliojs/pdfkit/issues/1331#issuecomment-1025118454 for more information
  return {
    cents: Number(value),
    centsPart,
    currency: pattern.symbol,
    decimalSeparator: pattern.decimal,
    decimals: decimalForm,
    decimalsPart,
    text: formattedValue.replace(/[\u00A0\u1680\u180e\u2000-\u2009\u200a\u200b\u202f\u205f\u3000]/g, ' '),
  };
}

export function formatVatRate(value: number): string {
  if (!value) { return ''; }
  return `${value / 100} %`;
}

export const groupBy = (xs: any[], key: string | number) => xs.reduce((rv, x) => {
  // eslint-disable-next-line no-param-reassign
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

export const removeUndefined = (obj: any): any => {
  const object = obj;
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'undefined') {
      delete object[key];
    }
  });
  return object;
};

export const isHumanNameValidYousign = (name: string): boolean => {
  if (!name || name.length > 150) { return false; }
  return /^(?!\s)[0-9A-Za-zĄÀÁÂÃÄÅÇĆÈÉÊËĘÌÍÎÏŁÑŃÒÓÔÕÖŚÙÚÛÜÝŹŻąàáâãäåæçćèéêëęìíîïłñńòóôõöśùúûüýÿźżß`'()\- ]+(?!\s)$/.test(name);
};

export const isMailValidYousign = (mail: string): boolean => {
  if (!mail) { return false; }
  return /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(mail);
};
