import cloneDeep from 'lodash/cloneDeep';
import { roundDecimal } from '..';
import { BillingType } from '../../types/Enum/BillingConfiguration';
import { DiscountMode } from '../../types/Enum/DiscountMode';
import { IBilling } from '../../types/Interface/schemas/IBilling';
import { IDiscount } from '../../types/Interface/api/billing/IDiscount';
import { ILineProduct } from '../../types/Interface/schemas/IProduct';
import { IPrice } from '../../types/Interface/api/billing/IPrice';

import { groupBy } from '../common/groupBy';

export const getProrata = (amount: number, rate: number) => Math.round(amount * (rate / 100));

export const convertAmountToCents = (amount: number) => Math.round(amount * 100);

export const convertAmountToEuros = (amount: number) => Math.round(amount / 100);

export const calculateVat = (price: number, vat: number): number => Math.round(price * (vat / 100 / 100));

export const calculateAllTaxesIncluded = (price: number, vat: number): number => calculateVat(price, vat) + price;

export const convertCurrencyAmount = (amount: number, rate: number) => amount * (rate || 1);

/**
 * Tous les montants doivent être exprimés en centimes. Cette méthode permet de calculer le détail de prix de chaque ligne
 */
export const getLineAmount = (data: ILineProduct, currencyRate = 1): IPrice => {
  const {
    unitPrice, vatPercentage, discountMode, discountAmount, quantity,
  } = data;
  const priceWithoutTaxBeforeDiscount = roundDecimal((quantity || 0) * (unitPrice || 0));
  const discount = roundDecimal(
    convertCurrencyAmount(
      discountAmount
        ? (discountMode === DiscountMode.AMOUNT
          ? roundDecimal(discountAmount, 0)
          : roundDecimal(priceWithoutTaxBeforeDiscount * (discountAmount / 100 / 100), 0))
        : 0,
      currencyRate,
    ),
    2,
  );
  const priceWithoutTax = roundDecimal(convertCurrencyAmount(priceWithoutTaxBeforeDiscount, currencyRate) - discount);
  const vat = vatPercentage && vatPercentage !== 0 ? vatPercentage / 100_00 : 0;
  let tax = 0;
  let priceTotalTax = 0;

  if (vat === 0) {
    priceTotalTax = priceWithoutTax;
  } else {
    tax = roundDecimal(priceWithoutTax * vat);
    priceTotalTax = roundDecimal(priceWithoutTax + tax);
  }

  return {
    priceWithoutTaxBeforeDiscount: priceWithoutTax + discount,
    priceWithoutTax,
    discount,
    vatAmount: priceTotalTax - priceWithoutTax,
    priceTotalTax,
  };
};

export const getLineAmountUrssafTp = (data: ILineProduct, currencyRate = 1): IPrice => {
  const {
    unitPrice, vatPercentage, discountMode, discountAmount, quantity,
  } = data;
  const priceWithoutTaxBeforeDiscount = roundDecimal((quantity || 0) * (unitPrice || 0));
  const discount = convertCurrencyAmount(discountAmount ? (discountMode === DiscountMode.AMOUNT ? discountAmount : priceWithoutTaxBeforeDiscount * (discountAmount / 100 / 100)) : 0, currencyRate);
  const priceWithoutTax = convertCurrencyAmount(priceWithoutTaxBeforeDiscount, currencyRate) - discount;
  const vat = vatPercentage && vatPercentage !== 0 ? vatPercentage / 100_00 : 0;
  const tax = roundDecimal(priceWithoutTax * vat);
  const priceTotalTax = roundDecimal(priceWithoutTax + tax);
  return {
    priceWithoutTaxBeforeDiscount: priceWithoutTax + discount,
    priceWithoutTax,
    discount,
    vatAmount: priceTotalTax - priceWithoutTax,
    priceTotalTax,
  };
};

// export const getLineAmount = (data: ILineProduct, currencyRate = 1): IPrice => {
//   const {
//     vatPercentage, discountMode, discountAmount,
//   } = data;
//
//   const unitPrice = data.unitPrice || 0;
//   const quantity = data.quantity || 0;
//
//   const floorQuantity = Math.floor(quantity);
//   const decimalQuantity = quantity - floorQuantity;
//   const priceWithoutTaxBeforeDiscount = unitPrice * quantity;
//
//   const discount = discountAmount ? (discountMode === DiscountMode.AMOUNT ? discountAmount : Math.round(priceWithoutTaxBeforeDiscount * (discountAmount / 100_00))) : 0;
//
//   let priceTotalTax = 0;
//   for (let i = 0; i < floorQuantity; i += 1) {
//     priceTotalTax += vatPercentage && vatPercentage !== 0 ? unitPrice * ((100_00 + vatPercentage) / 100_00) : unitPrice;
//     console.log(vatPercentage && vatPercentage !== 0 ? unitPrice * ((100_00 + vatPercentage) / 100_00) : unitPrice);
//     console.log(priceTotalTax);
//   }
//
//   if (decimalQuantity > 0) {
//     const prorata = unitPrice * decimalQuantity;
//     priceTotalTax += vatPercentage && vatPercentage !== 0 ? Math.round(prorata * ((100_00 + vatPercentage) / 100_00)) : Math.round(prorata);
//   }
//
//   const priceWithoutTax = Math.round(priceWithoutTaxBeforeDiscount - discount);
//
//   let discountTotalTax = discountAmount ? (discountMode === DiscountMode.AMOUNT ? discountAmount : Math.round(priceWithoutTaxBeforeDiscount * (discountAmount / 100_00))) : 0;
//   if (vatPercentage) {
//     discountTotalTax *= ((100_00 + vatPercentage) / 100_00);
//   }
//   priceTotalTax = Math.floor(priceTotalTax - discountTotalTax);
//
//   return {
//     priceWithoutTaxBeforeDiscount: convertCurrencyAmount(Math.round(priceWithoutTaxBeforeDiscount), currencyRate),
//     priceWithoutTax: convertCurrencyAmount(priceWithoutTax, currencyRate),
//     discount: convertCurrencyAmount(discount, currencyRate),
//     vatAmount: convertCurrencyAmount(Math.round(priceTotalTax - priceWithoutTax), currencyRate),
//     priceTotalTax: convertCurrencyAmount(priceTotalTax, currencyRate),
//   };
// };

/**
 *
 * Tous les montants doivent être exprimés en centimes. Cette méthode permet de récupérer le détail des montants de tva en fonction du pourcentage
 * Il faut donc passer un Array de produit ainsi que la valeur du discount global appliqué à la facture
 *
 */

export const getVatDetails = (data: Array<ILineProduct>,
  globalDiscount: IDiscount, ratio = 1, currencyRate = 1): Array<{ vatPercentage: number, amount: number }> => {
  const result = data.map((el) => ({
    ...getLineAmount(el),
    vatPercentage: el.vatPercentage ? el.vatPercentage : 0,
  }));
  const productLineGroupByVat = groupBy(result, 'vatPercentage');

  const hasDiscount = globalDiscount.discountAmount && globalDiscount.discountAmount !== 0;
  const vatDetail = [];
  if (!hasDiscount) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(productLineGroupByVat)) {
      // @ts-ignore
      const amount = value.reduce((acc, el) => acc + el.vatAmount, 0);
      if (amount !== 0 && +key !== 0) {
        vatDetail.push({
          vatPercentage: +key,
          amount: roundDecimal(convertCurrencyAmount(amount * ratio, currencyRate)),
        });
      }
    }
    return vatDetail;
  }
  if (hasDiscount && globalDiscount.discountMode === DiscountMode.POURCENTAGE) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(productLineGroupByVat)) {
      // @ts-ignore
      const vatAmount = value.reduce((acc, el) => acc + el.vatAmount, 0);
      // @ts-ignore
      const amount = vatAmount - vatAmount * (globalDiscount.discountAmount / 100 / 100);
      if (amount !== 0) {
        vatDetail.push({
          vatPercentage: +key,
          amount: roundDecimal(convertCurrencyAmount(amount * ratio, currencyRate)),
        });
      }
    }
    return vatDetail;
  }

  const priceWithoutTaxBeforeDiscount = result.reduce((acc, el) => acc + el.priceWithoutTaxBeforeDiscount, 0);
  const discount = result.reduce((acc, el) => acc + (Number.isNaN(el.discount) ? 0 : el.discount), 0);
  const priceWithoutTax = result.reduce((acc, el) => acc + (Number.isNaN(el.priceWithoutTax) ? 0 : el.priceWithoutTax), 0);
  const globalVatAmount = result.reduce((acc, el) => acc + (Number.isNaN(el.vatAmount) ? 0 : el.vatAmount), 0);
  const priceTotalTax = result.reduce((acc, el) => acc + (Number.isNaN(el.priceTotalTax) ? 0 : el.priceTotalTax), 0);

  const globalProductPrices = {
    priceWithoutTaxBeforeDiscount,
    discount,
    priceWithoutTax,
    globalVatAmount,
    priceTotalTax,
  };

  // si la facture a un discount global, on va le chercher à le convertir en pourcentage quoi qu'il arrive
  // @ts-ignore
  const pourcentageDiscountFromAmountDiscount = ((globalDiscount.discountAmount) / globalProductPrices.priceWithoutTax) * 100;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(productLineGroupByVat)) {
    // @ts-ignore
    const vatAmount = value.reduce((acc, el) => acc + el.vatAmount, 0);
    const amount = vatAmount - vatAmount * (pourcentageDiscountFromAmountDiscount / 100);
    // eslint-disable-next-line no-continue
    if (amount === 0) { continue; }
    vatDetail.push({
      vatPercentage: +key,
      amount: roundDecimal(convertCurrencyAmount(amount * ratio, currencyRate)),
    });
  }
  return vatDetail;
};

/**
 *
 * Tous les montants doivent être exprimés en centimes. Cette méthode permet de calculer le détail de prix d'une facture complète.
 * Il faut donc passer un Array de produit ainsi que la valeur du discount global appliqué à la facture
 *
 */

export const getTotalAmount = (data: Array<ILineProduct>,
  globalDiscount: IDiscount, ratio = 1, currencyRate = 1): IPrice => {
  if (!data) {
    return {
      priceWithoutTaxBeforeDiscount: 0,
      discount: 0,
      priceWithoutTax: 0,
      vatAmount: 0,
      priceTotalTax: 0,
    };
  }
  const result = data.map((el) => getLineAmount(el));
  // on récupère les prix de tous les produits cumulés.

  const priceWithoutTaxBeforeDiscount = result.reduce((acc, el) => acc + (Number.isNaN(el.priceWithoutTax) ? 0 : el.priceWithoutTax), 0);
  const discount = result.reduce((acc, el) => acc + (Number.isNaN(el.discount) ? 0 : el.discount), 0);
  const priceWithoutTax = result.reduce((acc, el) => acc + (Number.isNaN(el.priceWithoutTax) ? 0 : el.priceWithoutTax), 0);
  const vatAmount = result.reduce((acc, el) => acc + (Number.isNaN(el.vatAmount) ? 0 : el.vatAmount), 0);
  const priceTotalTax = result.reduce((acc, el) => acc + (Number.isNaN(el.priceTotalTax) ? 0 : el.priceTotalTax), 0);

  const globalProductPrices = {
    priceWithoutTaxBeforeDiscount,
    discount,
    priceWithoutTax,
    vatAmount,
    priceTotalTax,
  };

  // si il n'y a pas de discount global, on peut return les calculs obtenus avec les prix.
  if (!globalDiscount.discountAmount || globalDiscount.discountAmount === 0) {
    return {
      priceWithoutTaxBeforeDiscount: convertCurrencyAmount(Math.round(globalProductPrices.priceWithoutTaxBeforeDiscount * ratio), currencyRate),
      priceWithoutTax: convertCurrencyAmount(Math.round(globalProductPrices.priceWithoutTax * ratio), currencyRate),
      vatAmount: convertCurrencyAmount(Math.round(globalProductPrices.vatAmount * ratio), currencyRate),
      priceTotalTax: convertCurrencyAmount(Math.round(globalProductPrices.priceTotalTax * ratio), currencyRate),
      discount: 0,
      ratio,
    };
  }

  // si la facture a un discount global, on va le chercher à le convertir en pourcentage quoi qu'il arrive
  let pourcentageDiscount = globalDiscount.discountMode === DiscountMode.POURCENTAGE ? globalDiscount.discountAmount / 100 : 0;
  if (globalDiscount.discountMode === DiscountMode.AMOUNT) {
    pourcentageDiscount = ((globalDiscount.discountAmount) / globalProductPrices.priceWithoutTax) * 100;
  }

  // on calcul le montant des discount sur les montant préalablement générés avec les produits
  const discountAmount = Math.round(globalProductPrices.priceWithoutTax * (pourcentageDiscount / 100));
  const discountVatAmount = Math.round(globalProductPrices.vatAmount * (pourcentageDiscount / 100));

  return {
    priceWithoutTaxBeforeDiscount: convertCurrencyAmount(Math.round(globalProductPrices.priceWithoutTax * ratio), currencyRate),
    discount: convertCurrencyAmount(Math.round(discountAmount * ratio), currencyRate),
    priceWithoutTax: convertCurrencyAmount(Math.round((globalProductPrices.priceWithoutTax - discountAmount) * ratio), currencyRate),
    vatAmount: convertCurrencyAmount(Math.round((globalProductPrices.vatAmount - discountVatAmount) * ratio), currencyRate),
    priceTotalTax: convertCurrencyAmount(Math.round((globalProductPrices.priceWithoutTax - discountAmount + globalProductPrices.vatAmount - discountVatAmount) * ratio), currencyRate),
    ratio,
  };
};

export function getCompleteVatDetails(item: IBilling | undefined, createFromBilling?: IBilling | undefined, overwriteCurrencyRate = 0): Array<{ vatPercentage: number, amount: number }> {
  if (!item) { return []; }
  const currencyRate = overwriteCurrencyRate || item.currencyRate || 1;
  // Si on crée un acompte depuis un devis, on va récupérer la TVA de manière proportionnelle au montant de l'acompte renseigné
  if (createFromBilling?.billingType && [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(createFromBilling.billingType) && [BillingType.ADVANCE, BillingType.ASSET].includes(item.billingType)) {
    // On récupère le seul produit que comporte un acompte
    const advanceProduct = item?.product?.[0];
    if (!advanceProduct) { return []; }
    // Si le montant est à 0 alors on retourne un tableau vide (évite d'afficher des NaN où le vatDetails du devis)
    const createFromBillingPrice = getTotalAmount(createFromBilling?.product || [], { discountAmount: createFromBilling.discountAmount, discountMode: createFromBilling.discountMode });

    if (!advanceProduct.unitPrice) { return []; }
    // Calcul du ratio pour calculer les taux de TVA relatif au montant de l'acompte
    const ratio = advanceProduct.unitPrice / createFromBillingPrice.priceWithoutTax;

    return getVatDetails(createFromBilling?.product || [], { discountAmount: createFromBilling.discountAmount, discountMode: createFromBilling.discountMode }, ratio || 1, currencyRate);
  }
  return getVatDetails(item?.product || [], { discountAmount: item?.discountAmount, discountMode: item?.discountMode }, currencyRate);
}

/**
 *
 * Encapsule la méthode getTotalAmount pour gérer les totaux relatifs quand un document dépend d'un autre
 * Ex: Devis -> Acompte -> Facture finale
 *
 */

const calculateAdvancePriceTotalTax = (item: IBilling | undefined, createFromBilling?: IBilling, currencyRate = 1) => {
  // On récupère le seul produit que comporte un acompte
  const advanceProduct = item?.product?.[0];
  // Calcul du ratio pour calculer les totaux relatif au montant de l'acompte
  if (!createFromBilling) { return getTotalAmount([], {}); }
  const createFromBillingPrice = getTotalAmount(createFromBilling?.product || [], { discountAmount: 0, discountMode: DiscountMode.AMOUNT });
  const ratio = (advanceProduct?.unitPrice || 0) / createFromBillingPrice.priceWithoutTax;
  // Si il n'y a pas de unit price, de ratio ou de devis on retourne un IPrice vide
  if (!advanceProduct?.unitPrice || !ratio) { return getTotalAmount([], {}); }
  return {
    ...getTotalAmount(createFromBilling?.product || [], { discountAmount: 0, discountMode: DiscountMode.AMOUNT }, ratio || 1, currencyRate),
    vatDetails: getCompleteVatDetails(item, createFromBilling, currencyRate),
  };
};

export function getCompleteTotalAmount(item: IBilling | undefined, createFromBilling?: IBilling | undefined, overwriteCurrencyRate = 0): IPrice {
  if (!item) { return getTotalAmount([], {}); }
  const currencyRate = overwriteCurrencyRate || item.currencyRate || 1;
  // Si le document provient d'un devis et que le type est INVOICE alors on génère une facture finale. Donc on inclue le reste à payer
  if (createFromBilling?.billingType && item.billingType === BillingType.INVOICE && [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(createFromBilling.billingType)) {
    const estimateBillingChilds = createFromBilling.childBilling || [];
    const advances = estimateBillingChilds.filter((d) => d.billingType === BillingType.ADVANCE);
    const total = getTotalAmount(item.product, { discountMode: item.discountMode, discountAmount: item.discountAmount }, currencyRate);

    const _price = getTotalAmount(item.product, { discountMode: item.discountMode, discountAmount: item.discountAmount }, currencyRate);

    const billingVatDetails = getCompleteVatDetails(item, createFromBilling, currencyRate);
    const remainingVatDetails = advances.length ? cloneDeep(billingVatDetails) : [];

    let totalAdvanceWithoutTax = 0;
    let totalAdvanceWithTax = 0;
    const associatedAssetDetails = [];

    for (let i = 0; i < advances.length; i += 1) {
      const advance = advances[i];
      const asset = advance.childBilling?.some((d) => d.billingType === BillingType.ASSET);
      // eslint-disable-next-line no-continue
      if (asset || advance.canceledAt) { continue; }
      const vatDetails = getCompleteVatDetails(advance, createFromBilling, currencyRate);
      const { priceWithoutTax, priceTotalTax } = calculateAdvancePriceTotalTax(advance, createFromBilling, currencyRate);
      totalAdvanceWithoutTax += priceWithoutTax;
      totalAdvanceWithTax += priceTotalTax;

      associatedAssetDetails.push({
        number: advance.number || '',
        priceWithoutTax,
        priceTotalTax,
        id: advance._id,
      });

      for (let j = 0; j < vatDetails.length; j += 1) {
        const vatDetail = vatDetails[j];
        const remainingVatDetail = remainingVatDetails.find((v) => v.vatPercentage === vatDetail.vatPercentage);
        if (remainingVatDetail) {
          remainingVatDetail.amount -= vatDetail.amount;
          // eslint-disable-next-line no-continue
          continue;
        }
        remainingVatDetails.push({
          vatPercentage: vatDetail.vatPercentage,
          amount: -vatDetail.amount,
        });
      }
    }

    return {
      ..._price,
      remainingPriceToPayTotalTax: total.priceTotalTax - totalAdvanceWithTax,
      remainingPriceToPayWithoutTax: total.priceWithoutTax - totalAdvanceWithoutTax,
      totalAssociatedAsset: totalAdvanceWithTax,
      associatedAssetDetails,
      vatDetails: billingVatDetails,
      remainingVatDetails,
      remainingVatAmount: remainingVatDetails.reduce((acc, v) => acc + v.amount, 0),
    };
  }

  if (createFromBilling?.billingType && [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(createFromBilling.billingType) && item.billingType === BillingType.ADVANCE) {
    return calculateAdvancePriceTotalTax(item, createFromBilling, currencyRate);
  }
  const amount = {
    ...getTotalAmount(item.product, { discountMode: item.discountMode, discountAmount: item.discountAmount }, currencyRate),
    vatDetails: getCompleteVatDetails(item, createFromBilling, currencyRate),
  };

  if (item.billingType === BillingType.ASSET && item.createdFromInvoice && item.createdFromInvoice.billingType === BillingType.ADVANCE && (item.createdFromInvoice?.createdFromEstimate || item.createdFromInvoice?.createdFromPurchaseOrder)) {
    amount.vatDetails = getCompleteVatDetails(item.createdFromInvoice as IBilling, item.createdFromInvoice.createdFromEstimate || item.createdFromInvoice.createdFromPurchaseOrder);
    amount.vatAmount = roundDecimal(amount.vatDetails.reduce((acc, value) => acc + value.amount, 0), 0);
    amount.priceTotalTax += roundDecimal(amount.vatAmount, 0);
  }

  return amount;
}

export default {
  getProrata, convertAmountToCents, calculateVat, getLineAmount,
};
