import { ReadOrganization, Time } from '@abby/shared'
import { OrganizationForm } from '../../domain/form/organization.form'

export class OrganizationFormHttpMapper {
  public static toDomain (doc: ReadOrganization): OrganizationForm {
    return OrganizationForm.create({
      id: doc.id,
      name: doc.name,
      commercialName: doc.commercialName,
      siret: doc.siret,
      vatNumber: doc.vatNumber,
      billingAddress: doc.billingAddress,
      deliveryAddress: doc.deliveryAddress,
      bankInformations: doc.bankInformations,
      preferences: {
        language: doc.language,
        currency: doc.currency,
        paymentMethods: doc.paymentMethods,
      },
    })
  }
}
