import { GetterTree, ActionTree, MutationTree } from 'vuex'
import type {
  ReadNumberOfUsersPerBrand,
} from '@abby/core-legacy'

export interface DashboardStatisticsState {
  numberOfUsersPerBrand: Array<ReadNumberOfUsersPerBrand> | []
  evolutionOfCreationState: any | {}
}

export const state = (): DashboardStatisticsState => ({
  numberOfUsersPerBrand: [],
  evolutionOfCreationState: {},
})

export const getters: GetterTree<DashboardStatisticsState, DashboardStatisticsState> = {
  numberOfUsersPerBrand: state => state.numberOfUsersPerBrand,
  evolutionOfCreationState: state => state.evolutionOfCreationState,
}

export const mutations: MutationTree<DashboardStatisticsState> = {
  SET_NUMBER_OF_USERS_PER_BRAND (state, value: Array<ReadNumberOfUsersPerBrand> | []) {
    state.numberOfUsersPerBrand = value
  },
  SET_EVOLUTION_OF_CREATION_STATE (state, value: any | {}) {
    state.evolutionOfCreationState = value
  },
  RESET (_currentState: DashboardStatisticsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<DashboardStatisticsState, DashboardStatisticsState> = {
  async fetchNumberOfUsersPerBrand ({ commit }) {
    const result = await this.$api.statistics.getNumberOfUsersPerBrand()
    commit('SET_NUMBER_OF_USERS_PER_BRAND', result)
  },
  async fetchEvolutionOfCreationState ({ commit }) {
    const result = await this.$api.statistics.getEvolutionOfCreationState()
    commit('SET_EVOLUTION_OF_CREATION_STATE', result)
  },
}
