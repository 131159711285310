import { useContext } from '@nuxtjs/composition-api'

export const useMoveManager = () => {
  const { $move } = useContext()
  return {
    move: (url: string) => {
      $move(url)
    },
  }
}
