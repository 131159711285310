export enum DeleteAccountChurnSurveyQuestion {
  COST = 'COST',
  NO_USE = 'NO_USE',
  BETTER_SERVICE = 'BETTER_SERVICE',
  TECHNICAL_PROBLEMS = 'TECHNICAL_PROBLEMS',
  EXPECTATIONS = 'EXPECTATIONS',
  BAD_EXPERIENCE = 'BAD_EXPERIENCE',
  LACK_OF_FEATURES = 'LACK_OF_FEATURES',
  ONLY_TRIAL = 'ONLY_TRIAL',
  COMPLEXITY_OF_USE = 'COMPLEXITY_OF_USE',
  DIFFICULTY_OF_MIGRATION = 'DIFFICULTY_OF_MIGRATION',
  OTHER = 'OTHER',
}
