import { ErrorCodeUrssafTp, PaymentRequestStatusUrssafTp } from '@abby/core-legacy'
import { BillingState } from '@abby/shared'
import dayjs from 'dayjs'

type PaymentRequestProps = {
    dateDebutEmploi: Date;
    dateFinEmploi: Date;
    dateVirement?: Date;
    idDemandePaiement?: string;
    mntVirement?: number;
    statut?: PaymentRequestStatusUrssafTp;
    state: BillingState
    customerRegisteredAt: Date;
    advanceAlreadyPayed: number;
}

export class PaymentRequest {
    dateDebutEmploi: Date;
    dateFinEmploi: Date;
    readonly dateVirement?: Date;
    readonly idDemandePaiement?: string;
    readonly mntVirement?: number;
    readonly statut?: PaymentRequestStatusUrssafTp;
    readonly state: BillingState;
    readonly customerRegisteredAt: Date;
    advanceAlreadyPayed: number;

    private constructor (props: PaymentRequestProps) {
      this.dateDebutEmploi = props.dateDebutEmploi
      this.dateFinEmploi = props.dateFinEmploi
      this.dateVirement = props.dateVirement
      this.idDemandePaiement = props.idDemandePaiement
      this.mntVirement = props.mntVirement
      this.statut = props.statut
      this.state = props.state
      this.customerRegisteredAt = props.customerRegisteredAt
      this.advanceAlreadyPayed = props.advanceAlreadyPayed
    }

    public static create (props: PaymentRequestProps): PaymentRequest {
      return new PaymentRequest(props)
    }

    get canRetrySendBillingTp () {
      if (this.state === BillingState.FINALIZED && !this.statut) { return true }
      const error = ErrorCodeUrssafTp.find(err => this.statut && [err.code].includes(this.statut) && ![PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON, PaymentRequestStatusUrssafTp.ERR_NBRE_PREST_MAX].includes(this.statut))
      if (error && this.state !== BillingState.PAID) { return error }
    }

    get hasInvoiceDuplicate () {
      const error = this.statut === PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON
      return error && this.state !== BillingState.PAID
    }

    get hasClientError () {
      return this.statut && [
        PaymentRequestStatusUrssafTp.ERR_PARTICULIER_INCONNU,
        PaymentRequestStatusUrssafTp.ERR_LIEN_PARTICULIER_PRESTATAIRE,
        PaymentRequestStatusUrssafTp.ERR_COMPTE_BLOQUE,
      ].includes(this.statut)
    }

    get isErrorSolvable () {
      return this.canRetrySendBillingTp || this.hasClientError
    }

    get hasValidTpDates () {
      const debutEmploi = dayjs(this?.dateDebutEmploi).startOf('d').toDate()
      const finEmploi = dayjs(this?.dateFinEmploi).startOf('d').toDate()
      const customerRegisteredAt = dayjs(this.customerRegisteredAt).startOf('d').toDate()
      return debutEmploi &&
            finEmploi &&
            customerRegisteredAt &&
            dayjs(debutEmploi).isSame(finEmploi, 'month') &&
            dayjs(debutEmploi).isSame(finEmploi, 'year') &&
            dayjs(debutEmploi).isSameOrAfter(customerRegisteredAt) &&
            dayjs(finEmploi).isSameOrAfter(debutEmploi)
    }
}
