import {
  DiscountAdvancePaymentItems,
  LatePenaltyItems,
  LumpSumCompensationItems,
  PaymentDelayItems,
  PaymentMethodItems, ReminderFrequency,
  VatMentionItems,
} from '../../Enum/BillingConfiguration';
import { BillingTemplate } from '../api/pdf/PDF';
import { ICustomEmail } from '../models/ICustomEmail';
import { IAdditionalLogos } from '../api/billing/IAdditionalLogos';
import { Currency } from '../../Enum/Currency';

export const BillingAdditionalLogoPresets = [
  {
    id: 'additional-logo-preset-1',
    extension: 'png',
    name: 'additional-logo-preset-1-sap',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/79a39212-c1f1-4719-879b-9367e17684ef-compressed.png?_se=*&Expires=1980062344&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC83OWEzOTIxMi1jMWYxLTQ3MTktODc5Yi05MzY3ZTE3Njg0ZWYtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5ODAwNjIzNDR9fX1dfQ__&Signature=UWGwa9IXPLnaXszsY0wilC9NhS24o48Y1QYjNRW4PiS6F58JRVskXFTshtkQ1Rr2m83vuszHKV~EW0q1lpfwX8WKDPObkyUkFnQg~owUnJegE6gEQv92LPGs7ChxlFTGKAt-EMdaPk2BJggxqKRkHhHo0QcLbR937mZqO2KoWlTxqaJDvRaN7gSMzSTu50UoGIAj8eJQnhMd5zuMmEolhqv6m6nO4S5CLCEM5jxXyioMG3NewNtJClIv2LcG7PxRyRRsUTS8YEcQYYsvooJhh6d6-09-ajQcRL4z9Dnsl~BCkoOs1rmbG7zZLfln1NBvZTVY7IAxaCLvsO0GlsDI~w__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'additional-logo-preset-1',
  },
  {
    id: 'additional-logo-preset-2',
    extension: 'png',
    name: 'additional-logo-preset-2-qualiopis',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/323aa239-ecc4-4c10-b063-85a48c1b8109-compressed.png?_se=*&Expires=1982943806&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy8zMjNhYTIzOS1lY2M0LTRjMTAtYjA2My04NWE0OGMxYjgxMDktY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5ODI5NDM4MDZ9fX1dfQ__&Signature=WXA0q84ndXDqSclPCbyuApFO19y9DK87vv6YSVYvFbItgwdnOwplZjYTcKB-wNDWJdPT5Ixb0yaAZarruoZYTJ4o-M2EARtHm1gh6y0He1EgDbe7cG-qpITxo0eE03XdNX9Kllf5S5HcbW4FdVW~Zb78jMRbKYAUgT~~MCGY8Ay5hvxRv-226Jv2T1mXFnSCCcFgabziq5eK4eghrCSk2qfKTeiXwS4xWzPilfjibGR6E-U7NF6aYt1WYVGhxRK9FRNfGGlmdOXcXe9GpL8I1FbKNRynaAnp2l97JjZpgy8Vlzjf-QRhaI0Z7C1LbJnStIGXgqQlvC3wrvM3bGFXcg__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'additional-logo-preset-2',
  },
  {
    id: 'additional-logo-preset-3',
    extension: 'png',
    name: 'additional-logo-preset-3-mon-compte-formation',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/a8c08f62-e9d8-4acb-8236-a08ca636348f-compressed.png?Expires=1981206735&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy9hOGMwOGY2Mi1lOWQ4LTRhY2ItODIzNi1hMDhjYTYzNjM0OGYtY29tcHJlc3NlZC5wbmciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5ODEyMDY3MzV9fX1dfQ__&Signature=DdTcC10m6js8pt80clWbJMjJVnSGQdQi9y5a5Gd5OHh~tpjAcomx5vDiTu50ivZeI55srSfP77PZNEhCMGCPKsgyjVr0O4eWcvnj5woBrniSuqKUZ3m5HLYLiwQQk~Kv-Mtu9FCI22Se36c94fLj45-n-KdjaFGwAPpP4-ZdRhCTvuDRdcolrpqjykeUbod8TvrqImuhxjMqdDFC8r-yvlLnuknngv9gtqC8ItXllkN-eeHCDo6pPXv5Qz8Ucf1J02JktsJ~W1iPQnNd~byGeBFoI0YqWeHUUh8BnHlcEAS5AFCqJw2Uzymi1d932ISgbtxDfsrORxK-n0m6~lzh3A__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'additional-logo-preset-3',
  },
];

export const BillingShapePresets = [
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6fedb-e335-4502-90d0-2d56ab6d8169-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmVkYi1lMzM1LTQ1MDItOTBkMC0yZDU2YWI2ZDgxNjktY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=NwUDLAEjYOt6us35jHKriwOtu8A548AfPDljB~nZn810z1ESi5wFfze~SH4IEIgYq4KO6aIQYFGEEq4iG-IdXD8ZuNsm7Z6wb8zpeBSVS63UiIImCiMp3~W6~D5LvMh~14wrZ~d5V-lmXpFJtX35ffbeWMETt-K36pbTQ6tMnez-LaD-f7mZpfHr4t-WgcY954mXgvi5QAYR16gUjDwK5K2PgwnHUgWZCHcYe5ZYAqXdmiqsJx~z5wqJIhOCeS0jMPZHf85g6-qnLrjoXH61mHYBfs6l07MaYXhFSI~JjF2UISwDbxAOGX3ZGm9t7dYcfmUaaRjmaMl6n5OemuB2jQ__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-1',
    extension: 'png',
    name: 'decoration-preset-1',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ffc3-4513-449c-a02e-d744e8e6bc70-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmZjMy00NTEzLTQ0OWMtYTAyZS1kNzQ0ZThlNmJjNzAtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=QVYK159oXrPexdHNZxlE-2YuCv0aK60iGree8aOy2Jn5ms90ytv8wFRVgSfZEsXqF0osOI9tkW9xzJ-o8U9w0uyUgfYtQpjX3qfJfSv9azdrLI9~7EDVsJV6ajj0maO1H0KE0CBKiQ9uuraXlVhoV3THFgdf8RXKsmcqxy0ohaNlKNd7sbzITwpesqWR-jqmlrgNoa0~VnvUMaPc3AE2WSjcP3WMo8gRHX-aZ7UIScJ7G12X69~P9OMzMKBAmXxuEpuwPsiOV0ZYsvlD~-hhuSAPgm-M6p47NGYp0NV2waUyCDI0tiTuQHXcUVS46oD0uiixbYAEfSB5jitmE9-Jzg__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-1',
  },
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6fef5-21db-403b-88b7-6883c4833692-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmVmNS0yMWRiLTQwM2ItODhiNy02ODgzYzQ4MzM2OTItY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=DZhH74xTAawAUTW8Lxp3gRTM9KusnW40nFDEy~6fXGOKn85RsE16DtL5PQk58vEZdL447MI-T5m67WP1MwSq791S3JyCngossTzEaKRfL4O2v9hnTeHHOtfbPOGvBylN-VlAopLKSr0rgIHcrMvg1ECFmbj4TV6gLpYE1ioHK3zBXXUhQCiQmqHj80V6Vbw3EuZyoRdaAfHrbImwrA2zeEF4xf9aqBHCrnIuP7-UAeKkYRXL1t6WnSOXWy6GnwM6yR1vdfs6RKPm~VrMwi2H64A3D7pnzwjKG1LuQFrz6Fpbcm0-sKUG7YmDjoEN75J4XI2KUqUbY~Dl9jfNPHNsTg__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-2',
    extension: 'png',
    name: 'decoration-preset-2',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ffd3-d434-436e-96f6-3af6c76b9067-compressed.png?_se=*&Expires=1983460195&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmZkMy1kNDM0LTQzNmUtOTZmNi0zYWY2Yzc2YjkwNjctY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5ODM0NjAxOTV9fX1dfQ__&Signature=RUZmjrv5l9RZt3pqkwtpX4vdHpM5FvxSdVFT~26m9aKnkSgGZKyC2vRPozBl5OE63t5Zdaeo3Xj7lAXpYNA~1mgRqkMWVpGI7ZiT7jdp0Z7b1-DZZ-T5oCea7jjCcldppDAzs3Wwto6u5ALHu9jXizDtAR0uuhjKVXzdaJYqRISXKbgRwFIohByHToJxblPj4hPTM5DBwgppeJ9va5J~~3NazxrdYT16G1-riuzhYB3~knC-OH9UJMom2iQ0k46njTYlxlbbDNVzyBpZE5hqGp6D4fvsZIi3iadBkWnRlI0rcw95~30CH6CE2vN2vxeI4pKusPXdLjnEf8Qmo2SVRw__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-2',
  },
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ff02-70e4-4564-b5d0-50ca0b644e6a-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmYwMi03MGU0LTQ1NjQtYjVkMC01MGNhMGI2NDRlNmEtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=ReP-ygqTPHBpXLraylKvjPOAz8KP18eGpWRmbuGJDv5DzWSbj76za0o2CKItFmlxyStrW4SHVzWy~-iqjUK9doBOkZMHvFILq4ebKx2FjEgd8tk59a1mWyGP-Ah1ttpZGL6NYPNdF3beifW1FHpcYUL~ntdHItIWBk1Wm1-nuaibUR6hx8YElMmz7BkiVm~F63IEBUYsgJeaKGQ0guUJZyV2HFLCsnahru30yOIrvkVzk~AOSHDQn5XC2gpgyQfoA9~gOIdRaqp9FGORxg2LVniIYLAi000p1WNuxoW7JzlzgpuOW3bBO3yu2axJiylKslr6S4J-38DzHPKG89j0bg__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-3',
    extension: 'png',
    name: 'decoration-preset-3',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ffe0-7184-4dcf-8300-04a9ff62b035-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmZlMC03MTg0LTRkY2YtODMwMC0wNGE5ZmY2MmIwMzUtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=lZWg8cWSYixZliqTS-gvCeZLb0b-OIkepZovKLgGGV~mrodWUqEq5uz7vI-ouVNr9Uz8YHDVWnEH6hpzS6tJD1pkQM1tL2CFoxKyREzY7OyRFubp6jlapDlp1WL2S0-BQrpgjbsfkmCeoIDmtYAX7niwulXUz5JiysIal1KTqab91Z~LF44zN7o6DzGKleQ9u8Lbllo3mNuECmfOvZo0XRruLsoT96uus-pmMEs43I6JvJq2eyChNMz1iSdvlGIFSuiNMQ4J53j5rawpbTqEEMTUeHfqP-2CeVsY657hY9e1kGd~pguV918qPoj8x5kuQbAHY6iJ-rnkcF2pPSxg5Q__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-3',
  },
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ff14-786f-47cb-8277-1437177daa78-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmYxNC03ODZmLTQ3Y2ItODI3Ny0xNDM3MTc3ZGFhNzgtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=eJzaSi-cWGRjW99GpYL9yKNHHzh8WVeK0Ye~ohV25yYFotd1vjeoO8IuLRQrlKBo4g8xIWBPGixyuEy487KlvUISHOAYSzZsu~LGoG9GjNiPhMDm5prQROXWxtIystYwY~uN-KFJCR0clTH~N3bMV-GTuVFxPXWZYrilLjy84Rkp8ZGTWX65mwqkzX7-BoWrnQcrGe0sS8LFJi9mHgPKj7t~IfXSUrCXxCVL8Pih4jlkmtWUrXPny83IF2jrjnM44P2XXoXYodigZfKEEXaD8ue4nsAFH-2TOCw0eUzGr1T-vYhmHkmH7HhYqyPzUSqZXMUKKDWas-8an5O9Crzqug__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-4',
    extension: 'png',
    name: 'decoration-preset-4',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ffef-0849-4587-aea4-1b6cc50e3027-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmZlZi0wODQ5LTQ1ODctYWVhNC0xYjZjYzUwZTMwMjctY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=LRbRZvDKEw-Y2Gpqc1Ph~PUmcavj0lssNcFMBKhXy73E~oAAZPW7R9e9iQ3ISTLqxOlQFT8LXVOwrBzFXxmMUI6kM0td9s4rTkHN4a2RHxaQi88n0DEweMDMBHCTEAHQqy020fMLsCbXjaPmlntyQ15GJ3CE8les9XgLs7LPjuCibZTxIJofkM5M73yzF3wcE6eWz~9SFrRol9PzPRt7bQibhTkysDMubtivV6MPupEtApJg2nPP9aWTxqNpT72rtdV-I8m47hBYc-CvGijDo6SBQHymdxM8zgk1a1~wCGqXTATG7YeDbE8WQJp9Vuo3V9sGH6eiZYT01p7BK9ibrQ__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-4',
  },
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ff29-8acc-473f-b151-9c137b33f935-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmYyOS04YWNjLTQ3M2YtYjE1MS05YzEzN2IzM2Y5MzUtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=Gab-gjElwokF0KRFqmAsPxBNaROmBam3QMoKn7nNWGTh0A0crgyTLqytDuhig3UarYU2zRKXrOGH12W0CAH2aSgXhqaIMdxLzcqQj9PcwJNgbccK2QojbbznAUOR7h4CLTShSY0sZXxtbN~HdaKPuIVBrdJgsdeg~LHmuMBkXS~uj~jGIm0Xtd79gciaVBYaIWSnheo-XMX7EE1az1dq15MZneuzE9qWnQlaTnC2Ulaw8oUw6YgpxZGGuFcuG9lrN78kYwFCy3ESlNhtpgB5Qu2nMbjzdIh6DJF9EKJ8QwVEAqOqEO6fZGWcJhBjTwbyU2RCmCnBpqPPYU-cSK-q0w__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-5',
    extension: 'png',
    name: 'decoration-preset-5',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d70000-34d4-47fc-b5e4-fd85118e499f-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ3MDAwMC0zNGQ0LTQ3ZmMtYjVlNC1mZDg1MTE4ZTQ5OWYtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=NZDTNsFePBrDW7o6G14dvd4nQAYeC2JpZEcEvXRB-3VViGF4Hbxqa08eFR2zalTPeLeX1zgr72F-kpDcqSGaCX5SB1Vu1Jbac4YwwYccVsDLnrZIn~201AK7szEDwXku6yLYl~zzte0dfPKcmRDltsOasBskKCqvYOUSYNluEhn9qV7tItlXY8s56cXYF6tSQHDfarKb5n0CoacbHaAJ6KoN1BqXRiQC015CQGeTuJoreLshfarI-W~CjzglS2wmSFvq3ebh-JntDgVhXLwul2eltwT2qysriZZTD49WmshR3hR2YO6aUydBDH1b13mSey0fnP1muD3-OVnBYApveQ__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-5',
  },
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ff3a-6f81-4a5e-b5a9-2d08bfcef89f-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmYzYS02ZjgxLTRhNWUtYjVhOS0yZDA4YmZjZWY4OWYtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=GqXFkTvGI2fxI1iChQ~tWl6lvbXaMswzIqBeMexJ8NqIK4SaDxmp9vTTKsV3VaFbbF-2ya5NMhBFqoy92C4g-ThdIIuzevDBsOV1DNldx~ZIOGzjlDgKSNfnb59Aaw-ChntP8806iRlREbA1DfAi4uPlvGgGTGujc-bNobCUr40ocCI~j~-bhSlHMtqOSeRPVT-K9uTqM-J2G5zkJHG1gkZP13GEgnuN1TF4yqYp-JaUem2DqX97L-O7lAxwUy2Z2AJKFH8bSr8lUSMagrf34gaESdTqLpMIrIIyN7JV18c7AERkeeasP3DDdo2ocbOcjG1lL0SE~JTr0FGCTqhwyQ__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-6',
    extension: 'png',
    name: 'decoration-preset-6',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d70010-6417-4047-855e-dcd9ff4f3efe-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ3MDAxMC02NDE3LTQwNDctODU1ZS1kY2Q5ZmY0ZjNlZmUtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=PLnPsihwQRASaD~hEt9pASvDM69hL2fHLH-1YF-cNLHtvtKAuYwlp65bdmHvTgs-E18lmF9uyI1zSi6-oE9KzNOhWkolXTz3xITgdwoiH-eiPU7-q2kezgFCleHAlW5SLrkOeuuTTwL0VhTDe9QPduSI5jfrZPd4HaP4dQFwlgrxeqoQdWenm7AfkSuqCO0YQsG-jKLr~oT5eJwB0EIx9lT1Twxaf-kmCnbOsi6cBiryGAYyUCuo2uJ~TKZzHirULEkjv6ZFrd5XJOzBLvXPJ3i8MVjionAWE3Txxtk8i9tDVtTsPrH2~jWBOHFZx96dOt-4Pa3o1VvqKdEcs8y-Og__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-6',
  },
  {
    previewUrl: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d6ff48-0092-44b5-bcb0-b5a6a86bbf97-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ2ZmY0OC0wMDkyLTQ0YjUtYmNiMC1iNWE2YTg2YmJmOTctY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=bWHhIzvcgCuKm1FjeOKiHpb82Ucc6cXYYg-7Y9g0uFunt18ZGvbqTS7shZ5PcETekmplZcu9qwyEH18NNXicC87rQNLPC2pD6SlQ1ZJm0fK372W~sqA51SATZFKARh8s58fw17z~1Rzhwdf45SUEXRYOOt~rIZXV~zNZ2PSD4uyG67eJmzVlURtIEH~BiBY-9SCLY1PeRuypXhDTzSwOjSiLs8U5By1aFf~jY7r4LWJTRWXYfDJMpxHH~o9J1jAvhEhS~XpF3KALweV1otZmSRPM8dOYdvQtyKIdicd9gLqskmX3dxJOLFEpbfrusUJrdnUHngy8UWGAHsSYNuhupA__&Key-Pair-Id=K15NQ6UPH261G4',
    id: 'decoration-preset-7',
    extension: 'png',
    name: 'decoration-preset-7',
    url: 'https://cdn.app-abby.com/60be550a-50a2-4871-a974-417384a624ec/61d7001e-030e-41dd-9ac0-6f57ea5b7471-compressed.png?_se=*&Expires=1974034028&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwYmU1NTBhLTUwYTItNDg3MS1hOTc0LTQxNzM4NGE2MjRlYy82MWQ3MDAxZS0wMzBlLTQxZGQtOWFjMC02ZjU3ZWE1Yjc0NzEtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzQwMjh9fX1dfQ__&Signature=IueKAv5L67PgH7wLTY9nM8VqCNo81XxiGe02uYrPEVlJ98y7I2JjiUmZxZRmcu5JlDahh2jz7vh6ADzPEi5yj9frqOcEDqBzIB6~dlFvBKCqDtyRGW9xiaoHXFy1z20NFw-0Hs7TAcQVuKY5jGf3ojgCJyhdq8uMFV0ZR6qHcRfGLY0ku9g3-exXQlizUnYbk0dbOwu8CMFXrnvW9LMk1uvle-kbc6w-9m0cbxxcgFyOVjBsaJBbqEZG6kc182tErTd6zmm3ljgZYPqBzmi2RnVpgiaTXA6bjfomRK~AzDrFMa4pBJjF8cZr4LF-gI3vNlcvJLk62cI7JVgaR8I29Q__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'decoration-preset-7',
  },
];

export const BillingLogoPresets = [
  {
    id: 'logo-preset-1',
    extension: 'png',
    name: 'logo-preset-1',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1d98-c323-43bc-896c-183d7ac1d1d7-compressed.png?_se=*&Expires=1974031769&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWQ5OC1jMzIzLTQzYmMtODk2Yy0xODNkN2FjMWQxZDctY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzE3Njl9fX1dfQ__&Signature=hsJUL4lAyscuAmgzfZMGK5EuOxTxLXtQyzb7Y8HW5ALecuXpQ2dKmzOq~-PUTy9TVH4Cfr5dnFBDg1TPV4g8FP5Kpkn0gz1AJev0kueaQwnRGVK8aitpAoJbvcZBahDgQYgtx8FN-HfUEor86wtpf9jo6Kp-rVxagmlxxYcdy3aKHMu7PV9tHqaqPMPuxn9y38yM3JWHGhcK7K3O3AVMISVknMcUGlX1CGPHVbPGjJizHC7UyPgTK1wHp~JEtbUC8sXsrwIYyaBMzR74pVYWAddR3XC00ql8KIUo9p92pHAm7~bBP9giki~nrCTdVvEtsoRskhBnKejoqX2HvlK1wg__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-1',
  },
  {
    id: 'logo-preset-2',
    extension: 'png',
    name: 'logo-preset-2',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1e03-6031-4be6-b115-9ae421503d33-compressed.png?_se=*&Expires=1974032373&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWUwMy02MDMxLTRiZTYtYjExNS05YWU0MjE1MDNkMzMtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzIzNzN9fX1dfQ__&Signature=jSB0KGCt2vdUwlAAa0wLe2m7HQvPIKlhwxvuF9aqDYUYQRiNUzG6cvSOQRN8Mx891GPvGndLJ-~ulbNDlT8WocjLfl-cWSwYgT1SgquWAomfj5lbl94oLNwLKNptVCmpdpVl4v5legfk9BRbt1aTFv8iPrH6PaAJhmD3jcGAY-CfiguhR~dQRyaEJknCSRMqGcIlSycSuWqjvS1DdN3Qe8Q6C9NKVTByc~aNc7XM5ETpWVrASsmDu4NqetPAEmHhLxw3jQXlrjzjnqRW-YZWB4G4wqA-RkO9TTqvHhUSBglg5Q5zQpV-xeMBav7QdI8I5u0oHINGPcM~cSwXAvAP6w__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-2',
  },
  {
    id: 'logo-preset-3',
    extension: 'png',
    name: 'logo-preset-3',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1e23-43b3-4a58-a514-d8319bb77236-compressed.png?_se=*&Expires=1974032792&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWUyMy00M2IzLTRhNTgtYTUxNC1kODMxOWJiNzcyMzYtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzI3OTJ9fX1dfQ__&Signature=I45ee7fWYyU7X90~UIxl2o~Ern4D4TwDPAn2yCXQsfHPTUasQK3Bkx3MKS6g7TVITyeh3TqJjft5SJ3ULSo7YSJgaI4i3ZTWDNa-wz1gxHi8IOD2b5-DFJhDCSYZ6UOZD7tQ~XlLlPlXFHpI4taAJexKolXUKI648ldesdc4IH9~GLHgdGgAZX1d-Ux0i9QGmcHv0-rFN9RdprxXkr1ZdqL6N6jecE8~ZTVirQEGY2mcDcSd0kIZormYqBWVf3hyj5dBIN~DcoqojwxqNWlj5APmyraGBUS2k1AWDtHj7PZwyY~UtyFUafE7WP1bkNK2cSxftRKgxhL6F~QXL~eACw__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-3',
  },
  {
    id: 'logo-preset-4',
    extension: 'png',
    name: 'logo-preset-4',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1e46-0cbc-4b54-a278-3e4bddfed98c-compressed.png?_se=*&Expires=1974032792&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWU0Ni0wY2JjLTRiNTQtYTI3OC0zZTRiZGRmZWQ5OGMtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzI3OTJ9fX1dfQ__&Signature=RJusuld66AW31zcq4vQf2xdB4U3AMb3T90GwXCy1TtodkQYTTn99E09B65UdkHke93w99JcJCNLgv6KiRdM6oGRFdfN5rpuT92P8DwW2o4m9q16cKhrLz4ssKUS2FcggbNyUb1DqbuOjxC4wbQB2MPNButHP2KvFjB1vVmy3HfS0nQY3WuUjQjyEhsoiKpe0XpQv-YFvnpB1~CYEhRY5hYp-Pr1jMNSltGBR9ehaDcZtO0rX0booDCah299QgCcnN-8gUmXpC13IGXOTW330Tgw9gwsJ4cQmPKYuyLmL31880mKo8KY2vIpInW5F2iy-dim7G0b29r-gtqa4LCH0Ew__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-4',
  },
  {
    id: 'logo-preset-5',
    extension: 'png',
    name: 'logo-preset-5',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1e66-3719-45d4-99f0-63fe48365e44-compressed.png?_se=*&Expires=1974032792&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWU2Ni0zNzE5LTQ1ZDQtOTlmMC02M2ZlNDgzNjVlNDQtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzI3OTJ9fX1dfQ__&Signature=SU64UYIb7Rb-3CX9-NGC1RsnGHwfCss9HqasQQawHdAHBTLaQB5v0Rk5sXt9Fdant~brzXqBPddCPNzUrV0xaSsaUtkfQhN5BZT47ex1~2cD-b7uYufSprJSMydV3VqN~P-kdy7l5b7gHw0XgWdGGSeAcIjDF5lS8K-cLVULbEE4Tpx98~8sJjOW--OyChQR~cKRNT2IMiexYARaaB3ncB-eOFHK49hJCRFjtszcaly9C3K3S10eXOJKouTFbz5CWcO-CNewtfLp9xWyQBGtiPcaLbWX4zrW0txDt1z7XJxRhFssamywRPhQmWuAj-VApQ-ZPzEF4gxq520htusrTg__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-5',
  },
  {
    id: 'logo-preset-6',
    extension: 'png',
    name: 'logo-preset-6',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1e82-e5e6-4750-97d4-8d5d531c880f-compressed.png?_se=*&Expires=1974032792&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWU4Mi1lNWU2LTQ3NTAtOTdkNC04ZDVkNTMxYzg4MGYtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzI3OTJ9fX1dfQ__&Signature=AICI-KRWuyfa~NUcIDjA~L3SbQRarZt-dkEIzaK3JN3jJJSFYOx9PSM~Zo20KnYObTMLF0XldneGmzjyD5K5i7wpde5VL30DwrXeO83lT35XPAesYAmNnWmhlLIqg~88n3xCLL7X0YqwWSTlfEhCHV9pJ-xcA~1rKr7nZMJWjbKQyirNyVUUsSexodfGnqYpzilADQy9sHpZDg3m7hd1Q9A9PPllTFulpVh9REqpWswh8XFlKkflmnxhblIfhDWARxhEgWYAMynZ0BWZdOwzgIJJEp-7W0weFLDDm6UvzhBDofuDt8ugl~5B~3a2CtgeoRGXrOo2yNiInTL8~LSf6Q__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-6',
  },
  {
    id: 'logo-preset-7',
    extension: 'png',
    name: 'logo-preset-7',
    url: 'https://cdn.app-abby.com/60cb051e-7fae-4ec5-b1a8-ee5d01f22ce4/61cf1ea1-719d-41ee-a042-9138f2961243-compressed.png?_se=*&Expires=1974032792&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9jZG4uYXBwLWFiYnkuY29tLzYwY2IwNTFlLTdmYWUtNGVjNS1iMWE4LWVlNWQwMWYyMmNlNC82MWNmMWVhMS03MTlkLTQxZWUtYTA0Mi05MTM4ZjI5NjEyNDMtY29tcHJlc3NlZC5wbmc~X3NlPSoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE5NzQwMzI3OTJ9fX1dfQ__&Signature=XbrL2fOL7Yh1~8T8PcuUgGVuOhIpnOrgoqqjG7UrkBCQhAhSntg-QR3MEUZd8v1t0VPZBpe~kaGn4XX6HsrPY-~yznF3-TJcc9aSCD7pXE1oHq6uNYOu52ZxelxkcSwDuiikbRQvWg-R9F8XlO8zQKqLwOn8iw0Cx4iyR8BXOWB8Eu0fdSQqKDGF348Zvla20DmP5-RgEL-NgeZtdRzGaViLVEia654fq8KdC1-eBJyRxZil81D81E6ptxy7A9gkQWNImMEN~wZCWwvwrB~dxrC1MguLFD8PSugLxs60cuqiorK5qBSMZbAgNF3g4yh20OtCShdH3lSGcensv8pr5A__&Key-Pair-Id=K15NQ6UPH261G4',
    presetId: 'logo-preset-7',
  },
];

export const BillingColorPresets = [
  {
    color: {
      primary: '#dd7c00 ',
    },
    background: {
      primary: '#dd7c00 ',
    },
    presetId: 'color-preset-1',
  },
  {
    color: {
      primary: '#ee7602',
    },
    background: {
      primary: '#ee7602',
    },
    presetId: 'color-preset-2',
  },
  {
    color: {
      primary: '#dd166b',
    },
    background: {
      primary: '#dd166b',
    },
    presetId: 'color-preset-3',
  },
  {
    color: {
      primary: '#701eb9',
    },
    background: {
      primary: '#701eb9',
    },
    presetId: 'color-preset-4',
  },
  {
    color: {
      primary: '#4646b8',
    },
    background: {
      primary: '#4646b8',
    },
    presetId: 'color-preset-5',
  },
  {
    color: {
      primary: '#06b68b',
    },
    background: {
      primary: '#06b68b',
    },
    presetId: 'color-preset-6',
  },
  {
    color: {
      primary: '#ffc71e',
    },
    background: {
      primary: '#ffc71e',
    },
    presetId: 'color-preset-7',
  },
  {
    color: {
      primary: '#fa9e34',
    },
    background: {
      primary: '#fa9e34',
    },
    presetId: 'color-preset-8',
  },
  {
    color: {
      primary: '#f34b94',
    },
    background: {
      primary: '#f34b94',
    },
    presetId: 'color-preset-9',
  },
  {
    color: {
      primary: '#9748e0',
    },
    background: {
      primary: '#9748e0',
    },
    presetId: 'color-preset-10',
  },
  {
    color: {
      primary: '#6867f6',
    },
    background: {
      primary: '#6867f6',
    },
    presetId: 'color-preset-11',
  },
  {
    color: {
      primary: '#23cfa5',
    },
    background: {
      primary: '#23cfa5',
    },
    presetId: 'color-preset-12',
  },
];

export interface IBillingStyle {
  color?: {
    primary?: string | null
  }
  background?: {
    primary?: string | null
  }
  presetId?: string
}

export enum BillingColumnName {
  NUMBER = 'number',
  DESCRIPTION = 'description',
  UNITY = 'unity',
  QUANTITY = 'quantity',
  UNIT_PRICE_WITHOUT_TAX = 'unit_price_without_tax',
  TVA = 'tva',
  TOTAL_WITHOUT_TAX = 'total_without_tax',
  TOTAL_WITH_TAX = 'total_with_tax',
}

export interface IBillingColumn {
  name: BillingColumnName
  order: number
  active: boolean
  mandatory: boolean
}

export interface IBillingConfiguration {
  __v?: number;
  _id?: string;
  companyId: string;
  bankInformation?: {
    bank?: string;
    iban?: string;
    bic?: string
  };
  // CGV
  generalTermsAndConditionsOfSale?: string;
  // si le paiement est en avance par rapport à la date attendue
  discountAdvancePayment?: DiscountAdvancePaymentItems;
  // indemnité forfaitaire pour paiement en retard
  lumpSumCompensation?: LumpSumCompensationItems;
  // Mention médiateur
  mentionMediator?: string;
  // format de numérotation des différents éléments factures / bon de commande / avoirs etc..
  numberFormat?: {
    invoice: Array<{ balise: any, id?: number }>;
    estimate: Array<{ balise: any, id?: number }>;
    asset: Array<{ balise: any, id?: number }>;
    advance: Array<{ balise: any, id?: number }>;
    purchaseOrder: Array<{ balise: any, id?: number }>;
  },
  // numéro de facture actuelle
  initialNumber?: {
    invoice?: number
    estimate?: number;
    asset?: number;
    purchaseOrder?: number;
  };
  // params de relance automatique de factures impayées
  reminder?: {
    recipients?: string[]
    active?: boolean,
    frequency?: ReminderFrequency
    inCopy?: boolean
    numberOfRemindersToSend?: number
    customEmail?: ICustomEmail
    customEmailEstimate?: ICustomEmail
  }
  // param des factures récurrentes
  recurring?: {
    inCopy?: boolean
    customEmail?: ICustomEmail
  }
  // param stripe payment
  stripePayment?: {
    inCopy?: boolean
  }
  inCopy?: boolean
  customEmail?: ICustomEmail
  customEmailEstimate?: ICustomEmail
  signatureEmail?: string,
  footerNote?: string;
  headerNote?: string;
  // mention obligatoire si l'entrepreneur est membre d'une association de gestion agréée
  displayMemberOfAnApprovedAssociation?: boolean;
  // afficher un intitulé bon de commande
  displayGoodForApproval?: boolean;
  // afficher le logo abby dans le footer
  displayAbbyLogo?: boolean;
  displayFullName?: boolean;
  displayEmail?: boolean;
  displayPhoneNumber?: boolean;
  displayTextEmail?: string | null;
  displayTextPhoneNumber?: string | null;
  displaySAPAgreement?: boolean;
  displayLegalStatus?: boolean;
  displayRequiredMentionsProduct?: boolean;
  paymentMethod?: PaymentMethodItems
  paymentDelay?: PaymentDelayItems
  latePenalty?: LatePenaltyItems
  vatMention?: VatMentionItems

  stripePaymentActivated?: boolean;
  tiersPrestationActivated?: boolean;
  tiersPrestationValidated?: boolean;
  tiersPrestationCredentials?: {
    clientId: string;
    clientSecret: string;
    iv?: string;
    novaNumber?: string;
  };

  logo?: {
    id: string;
    extension: string;
    name: string;
    url?: string;
    relativeUrl?: string;
    presetId?: string;
  };

  shape?: {
    id: string;
    extension: string;
    name: string;
    url?: string;
    previewUrl?: string;
    presetId?: string;
  }

  style?: IBillingStyle | null

  social?: {
    facebook?: string
    instagram?: string
    linkedin?: string
    website?: string
  }

  colors?: {
    primary?: string;
    primaryBg?: string | null;
  };

  additionalLogos?: IAdditionalLogos[];

  customAdditionalLogosUploaded?: IAdditionalLogos[];

  columns?: Array<IBillingColumn>

  template?: BillingTemplate;

  test?: boolean;

  preferredLocale?: string;

  preferredCurrency?: Currency;
  createdAt?: Date;

  updatedAt?: Date;
}
