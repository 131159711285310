import { useContext } from '@nuxtjs/composition-api'
import { OrganizationItem } from '~/logic/contexts/client/domain/entity/organizationItem.entity'

export const useCopyOrganizationID = () => {
  const { $clipboard } = useContext()

  return async ({ id }: OrganizationItem) => {
    await $clipboard.copy(id)
  }
}
