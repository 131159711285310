export enum CompanyDocumentType {
  ATTESTATION_NON_CONDAMNATION = 1,
  MANDAT_CREATION = 2,
  IDENTITY = 3,
  RESIDENCE = 4,
  FORMULAIRE_DEMANDE_ACRE = 5,
  ATTESTATION_CONJOINT_INFORME = 6,
  CARTE_IDENTITE_HEBERGEUR = 7,
  ATTESTATION_HEBERGEMENT = 8,
  JUSTIFICATIF_XP_DIPLOME = 9,
  CONTRAT_AGENCE = 10,
  CONTRAT_DOMICILIATION = 11,
  BAIL_COMMERCIAL = 12,
  SIGNATURE = 13,
}
