export enum OrganizationAction {
    ADMIN_COPY_ORGANIZATION_ID = 'adminCopyOrganizationId',

    EDIT = 'edit',
    DELETE = 'delete',
    ARCHIVE = 'archive',
    EDIT_NOTES = 'editNotes',
    UNARCHIVE = 'unarchive',
    VIEW_DETAILS = 'viewDetails',

    CREATE_BILLING = 'createBilling',
    CREATE_INVOICE = 'createInvoice',
    CREATE_ESTIMATE = 'createEstimate',
    CREATE_ASSET = 'createAsset',
    CREATE_PURCHASE_ORDER = 'createPurchaseOrder',

    GO_TO_CLIENT_PORTAL = 'goToClientPortal',
}
