
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import AlertsManager from '~/components-legacy/layout/managers/AlertsManager.vue'

@Component({
  middleware: ['redirection'],
  components: {
    AlertsManager,
  },
})
export default class ErrorLayout extends Vue {
  head () {
    return this.$brandManager.head()
  }

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;
}

