import { ReadContact, ReadOrganization } from '@abby/shared'
import { OrganizationContactForm } from '~/logic/contexts/client/domain/form/organizationContact.form'

export class OrganizationContactFormHttpMapper {
  public static toDomain (doc: ReadContact, organization: ReadOrganization): any {
    return OrganizationContactForm.create({
      id: doc.id,
      firstname: doc.firstname,
      lastname: doc.lastname,
      emails: doc.emails,
      jobTitle: doc.jobTitle,
      phone: doc.phone,
      defaultContact: doc.defaultContact,
      organization,
    })
  }
}
