
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import Modal from '~/components-legacy/layout/containers/Modal.vue'
import { ConfirmParams } from '~/plugins/confirmManager'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import AbbyPlanButton from '~/components-legacy/chips/AbbyPlanButton.vue'

@Component({
  components: {
    AbbyPlanButton,
    Modal,
    ASDialog,
  },
})
export default class ConfirmModal extends Vue {
  get isConfirmModalOpened (): boolean {
    return !!this.confirm
  }

  @Getter('confirmManager/confirm')
  confirm!: ConfirmParams

  _confirm () {
    this.confirm?.confirmCallback?.()
    this.$store.commit('confirmManager/SET_CONFIRM', null)
  }

  get icons (): any {
    return {
      remove: {
        icon: 'delete',
        color: 'red',
        background: 'red-lighten3',
        border: 'red-lighten4',
      },
      save: {
        icon: 'save',
        color: 'warning',
        background: 'orange-lighten3',
        border: 'orange-lighten4',
      },
    }
  }

  get icon () {
    return this.icons[this.confirm?.icon || ''] || null
  }

  get hasAccessToConfirm () {
    if (!this.confirm.feature) { return true }
    return this.confirm.feature && this.$planManager.hasAccessTo(this.confirm.feature)
  }

  openPlanModal () {
    if (!this.confirm.feature) { return }
    this.$planManager.openAbbyPlansModal({
      feature: this.confirm.feature,
      callbacks: {
        succeed: () => this._confirm(),
      },
    })
  }

  cancel () {
    this.confirm?.cancelCallback?.()
    this.$store.commit('confirmManager/SET_CONFIRM', null)
  }
}
