export enum UrssafProductTypeNatureCode {
  GARDE_ENFANT_HANDICAPE = '10',
  ACCOMPAGNEMENT_ENFANT_HANDICAPE = '20',
  AIDE_HUMAINE = '30',
  CONSUITE_VEHICULE_PERSONEL = '40',
  AIDE_AU_DEPLACEMENT = '50',
  ENTRETIEN_DE_LA_MAISON = '60',
  JARDINAGE = '70',
  BRICOLAGE = '80',
  GARDE_ENFANT_PLUS_DE_6_ANS = '90',
  SOUTIEN_SCOLAIRE_COURS_A_DOMICILE = '100',
  SOIN_ESTHETIQUE_A_DOMICILE = '110',
  PREPARATION_REPAS_A_DOMICILE = '120',
  LIVRAISON_REPAS_A_DOMICILE = '130',
  COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE = '140',
  LIVRAISON_COURSES_A_DOMICILE = '150',
  ASSISTANCE_INFORMATIQUE = '160',
  SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES = '170',
  MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE = '180',
  ASSISTANCE_ADMINISTRATIVE = '190',
  ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS = '200',
  TELEASSISTANCE = '210',
  INTERPRETE_LANGUE_DES_SIGNES = '220',
  CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE = '230',
  ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE = '240',
  ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE = '250',
  COORDINATION_ET_GESTION = '260',
  DIVERS_NON_ELIGIBLE = '270',
}