
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { customerClientFullName, customerEmail, customerName, customerPhone, ICustomer } from '@abby/core-legacy'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import AbbyPlanButton from '~/components-legacy/chips/AbbyPlanButton.vue'
import AbbyAvatar from '~/components/abby/status/AbbyAvatar.vue'
import ClientBadge from '~/components/client/items/ClientBadge.vue'
import { ClientType } from '~/logic/contexts/client/domain/enums/clientType.enum'
import { useOpenContactDetailsSidePanel } from '~/composables/client/contact/useOpenContactDetailsSidePanel'
import {
  useOpenOrganizationDetailsSidePanel,
} from '~/composables/client/organization/useOpenOrganizationDetailsSidePanel'

@Component({
  components: { ClientBadge, AbbyPlanButton, AbbyPlanChip, AbbyAvatar },
  setup () {
    const openContactDetailsSidePanel = useOpenContactDetailsSidePanel()
    const openOrganizationDetailsSidePanel = useOpenOrganizationDetailsSidePanel()
    return { openContactDetailsSidePanel, openOrganizationDetailsSidePanel }
  },
  computed: {
    ClientType () {
      return ClientType
    },
  },
})
export default class CustomerDetailsQuickPreview extends Vue {
  @Getter('billingCreation/isBillingEditionModalOpened')
  isBillingEditionModalOpened!: boolean

  @Getter('billingCreation/isBillingCreationModalOpened')
  isBillingCreationModalOpened!: boolean

  @Prop({ required: true })
  customer!: ICustomer

  @Prop({ default: false })
  opened!: boolean

  openContactDetailsSidePanel!: (id: string) => void
  openOrganizationDetailsSidePanel!: (id: string) => void

  get isModalOpened () {
    return this.isBillingCreationModalOpened || this.isBillingEditionModalOpened
  }

  get isOrganization () {
    return !!this.customer.organizationId && !this.customer.contactId
  }

  get companyDisplayName () {
    if (!this.customer) { return false }
    return customerName(this.customer, true)
  }

  get email () {
    if (!this.customer) { return '' }
    return customerEmail(this.customer)
  }

  get phone () {
    if (!this.customer) { return '' }
    return customerPhone(this.customer)
  }

  get customerDisplayName () {
    if (!this.customer) { return '' }
    return customerClientFullName(this.customer)
  }

  get city () {
    return this.customer?.location?.city
  }

  get country () {
    return this.customer?.location?.country
  }

  get location () {
    return this.city && this.country ? `${this.city}, ${this.country}` : null
  }

  get hasAccessToCustomerNote () {
    return this.$planManager.hasAccessTo(this.$planManager.features.billing_customer_note)
  }

  get syncWithTiersPrestation () {
    return this.customer?.tiersPrestationIsActivatedForThisCustomer && this.customer?.tiersPrestation?.registeredAt
  }

  get hasNotRegisteredToUrssaf () {
    return this.customer?.tiersPrestationIsActivatedForThisCustomer && this.customer?.tiersPrestation && !this.customer?.tiersPrestation?.idClient && !this.customer?.tiersPrestation?.registeredAt
  }

  openCustomerDetails () {
    if (this.isModalOpened) { return }
    if (this.customer.contactId) {
      this.openContactDetailsSidePanel(this.customer.contactId)
      return
    }
    if (this.customer.organizationId) {
      this.openOrganizationDetailsSidePanel(this.customer.organizationId)
    }
  }

  get note () {
    return `${this.customer.note?.replace(/<[^>]+>/g, ' ')?.replace(/\n/g, ' ') || ''}`
  }

  goToNote () {
    if (!this.hasAccessToCustomerNote) {
      this.$planManager.openAbbyPlansModal({
        feature: this.$planManager.features.billing_customer_note,
      })
      return
    }
    this.openCustomerDetails()
  }
}

