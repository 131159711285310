import { Plugin } from '@nuxt/types'
import { PlanFeature } from '@abby/core-legacy'

export interface ConfirmParams {
  title?: string
  subtitle?: string
  content?: string
  confirmText?: string
  confirmButton?: object
  confirmButtonIcon?: string
  icon?: 'remove' | 'save'
  cancelText?: string
  cancelButton?: object
  confirmCallback?: () => void
  cancelCallback?: () => void
  noCancelButton?: boolean
  maxWidth?: number
  feature?: PlanFeature
}

export interface ConfirmManagerInstance {
  ask(params?: ConfirmParams): Promise<boolean>;
  clear(): Promise<void>;
}

const ConfirmManagerPlugin: Plugin = (context, inject) => {
  const defaultConfirmConfig: ConfirmParams = {
    content: 'Êtes vous sur de vouloir continuer ?',
    confirmText: 'Confirmer',
    confirmButton: {
      disabled: false,
      depressed: true,
      color: 'error',
    },
    cancelText: 'Annuler',
    cancelButton: {
      text: true,
    },
    confirmCallback: () => {},
    cancelCallback: () => {},
  }

  inject('confirmManager', {
    ask: async (params: ConfirmParams): Promise<boolean> => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return await new Promise(function (resolve, reject) {
        context.store.commit('confirmManager/SET_CONFIRM', {
          ...defaultConfirmConfig,
          ...params,
          confirmCallback: () => { params?.confirmCallback?.(); resolve(true) },
          cancelCallback: () => { params?.cancelCallback?.(); resolve(false) },
        })
      })
    },
    clear () {
      context.store.dispatch('confirmManager/clearConfirm')
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $confirmManager: ConfirmManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $confirmManager: ConfirmManagerInstance
  }
  interface Context {
    $confirmManager: ConfirmManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $confirmManager: ConfirmManagerInstance
  }
}

export default ConfirmManagerPlugin
