import { OrganizationItem } from '~/logic/contexts/client/domain/entity/organizationItem.entity'
import { useBusManager } from '~/composables/shared/manager/useBusManager'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'

export const useRemoveOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const busManager = useBusManager()
  const alertManager = useAlertManager()
  const confirmManager = useConfirmManager()

  return async (organization: OrganizationItem) => {
    const confirm = await confirmManager.ask({
      title: 'Supprimer une organisation',
      content: 'Cette action est irréversible. Voulez vous vraiment supprimer cette organisation ?',
      confirmText: 'Supprimer',
      icon: 'remove',
      confirmButton: { color: 'error', depressed: true },
    })
    if (!confirm) {
      return false
    }
    await organizationRepository.removeOrganization(organization.id)
    busManager.emit('organizationRemoved', organization)
    alertManager.success('L\'organisation a été supprimée avec succès.')
  }
}
