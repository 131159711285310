import { INatureCode } from '../Interface/models/INatureCode';

export const NatureCode: Array<INatureCode> = [
  {
    code: '10',
    name: 'Garde d\'enfant handicapé',
  },
  {
    code: '20',
    name: 'Accompagnement d\'enfant handicapé',
  },
  {
    code: '30',
    name: 'Aide humaine',
  },
  {
    code: '40',
    name: 'Conduite du véhicule personnel (assistance)',
  },
  {
    code: '50',
    name: 'Aide au déplacement',
  },
  {
    code: '60',
    name: 'Entretien de la maison',
  },
  {
    code: '70',
    name: 'Jardinage',
  },
  {
    code: '80',
    name: 'Bricolage',
  },
  {
    code: '90',
    name: 'Garde d\'enfant + 6 ans',
  },
  {
    code: '100',
    name: 'Soutien scolaire à domicile / Cours à domicile (coach sportif ...)',
  },
  {
    code: '110',
    name: 'Soins d’esthétique à domicile',
  },
  {
    code: '120',
    name: 'Préparation de repas à domicile',
  },
  {
    code: '130',
    name: 'Livraison de repas à domicile',
  },
  {
    code: '140',
    name: 'Collecte et livraison de linge repassé',
  },
  {
    code: '150',
    name: 'Livraison de courses à domicile',
  },
  {
    code: '160',
    name: 'Assistance informatique',
  },
  {
    code: '170',
    name: 'Soins et promenades d’animaux de compagnie pour les personnes dépendantes',
  },
  {
    code: '180',
    name: 'Maintenance, entretien et vigilance de la résidence principale et secondaire',
  },
  {
    code: '190',
    name: 'Assistance administrative',
  },
  {
    code: '200',
    name: 'Accompagnement d\'enfant + 6 ans',
  },
  {
    code: '210',
    name: 'Téléassistance',
  },
  {
    code: '220',
    name: 'Interprète en langue des signes, technicien de l’écrit et codeur en langage parlé complété',
  },
  {
    code: '230',
    name: 'Conduite du véhicule personnel en cas d’invalidité temporaire',
  },
  {
    code: '240',
    name: 'Accompagnement dans les déplacements en dehors du domicile',
  },
  {
    code: '250',
    name: 'Assistance aux personnes temporairement dépendantes à domicile',
  },
  {
    code: '260',
    name: 'Coordination et gestion',
  },
  {
    code: '270',
    name: 'Divers – Non éligible',
  },
];
