import { Resource } from '../Resource';
import {
  CreateBankAttachment,
  QueryBankAttachment,
  ReadBankAttachment,
  ReadBankAttachments,
  UpdateBankAttachment,
} from '../../dto';

export class BankAttachment extends Resource {
  async create(payload: CreateBankAttachment): Promise<ReadBankAttachment> {
    return this.http.$post('/v2/banks/attachments', payload);
  }

  async update(id: string, payload: UpdateBankAttachment): Promise<ReadBankAttachment> {
    return this.http.$put(`/v2/banks/attachments/${id}`, payload);
  }

  async delete(id: string): Promise<void> {
    return this.http.$delete(`/v2/banks/attachments/${id}`);
  }

  async paginate(query: QueryBankAttachment): Promise<ReadBankAttachments> {
    return this.http.$get('/v2/banks/attachments', { params: query });
  }

  async retrieve(id: string): Promise<ReadBankAttachment> {
    return this.http.$get(`/v2/banks/attachments/${id}`);
  }

  async retrieveByFileId(fileId: string): Promise<ReadBankAttachment> {
    return this.http.$get(`/v2/banks/attachments/file/${fileId}`);
  }

  async associateTransaction(id: string, transactionId: string): Promise<void> {
    return this.http.$post(`/v2/banks/attachments/${id}/transactions/${transactionId}`);
  }
}
