import { useContext } from '@nuxtjs/composition-api'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'

export const useCopyClientUrssafEmailInfo = () => {
  const { $clipboard } = useContext()
  const clientStore = useClientStore()

  return async (content: string) => {
    const formattedContent = content.replaceAll('<br>', '\n')
    await $clipboard.copy(formattedContent)
    clientStore.toggleClientCopyTiersPrestationModal(false)
  }
}
