import { IReason } from '../Interface/models/IReason';
import { CompanyDocumentType } from './CompanyDocumentType';

export const Reason: Array<IReason> = [

  /**
   * COMMON
   */

  {
    id: 1,
    name: 'Information manquante',
    reason: 'Après avoir étudié les informations fournies dans le formulaire de déclaration, je m\'aperçois qu\'il manque l\' <INFORMATION>. '
        + 'Pouvez-vous me le transmettre ?',
  },
  {
    id: 2,
    name: 'Demande précision activité',
    reason: 'Après avoir étudié les informations fournies dans le formulaire de déclaration, je m\'aperçois que votre activité n\'est pas assez précise. '
      + 'Pouvez vous mieux me la décrire svp ? Je vous remercie d\'avance',
  },
  {
    id: 3,
    name: 'Demande diplôme',
    reason: 'Après avoir étudié les informations fournies dans le formulaire de déclaration, je m\'aperçois que votre activité est réglementée. '
      + 'Pouvez vous nous déposer un justificatif d\'expérience de 3 ans ou alors un diplôme justifiant de votre qualification professionnelle ?',
  },
  {
    id: 4,
    name: 'Frais mariage',
    reason: 'Nous avons procédé à la déclaration de votre activité. 🙂 Vous allez recevoir un email automatique de l\'Insee pour payer 8,03€ qui sont les frais '
        + 'liés à votre création car vous êtes marié sous la communauté des biens '
        + '(c\'est le greffe du tribunal de commerce qui encaisse l\'argent). Merci de réaliser au paiement rapidement pour que la formalité soit traité. 🙂',
  },
  {
    id: 5,
    name: 'Frais lié à une erreur client',
    reason: 'Nous avons procédé à la régularisation de votre dossier. 🙂 Une erreur s\'était glissé lors de la constitution de votre dossier. '
        + 'Vous allez recevoir un email automatique pour payer les frais liés à la régularisation de la formalité. '
        + 'Merci de réaliser le paiement rapidement pour que la formalité soit traité. 🙂 '
        + 'Si vous ne procédez pas au paiement dans les 15 jours, l\'organisme de l\'état va rejeter définitivement votre demande de création d\'auto-entreprise.',
  },

  /**
   * ALL DOCUMENTS
   */
  {
    id: 9000,
    name: 'Fichier impossible à ouvrir',
    allDocumentTypes: true,
    reason: 'Votre <DOCUMENT> n\'est pas conforme car nous n\'arrivons pas à ouvrir le fichier de notre côté. '
        + 'Pouvez-vous nous renvoyer ce document en utilisant un autre moyen de scan ou en l\'envoyant au format PDF ?',
  },
  {
    id: 9001,
    name: 'Faux documents',
    allDocumentTypes: true,
    reason: 'Votre <DOCUMENT> n\'est pas conforme car il semble avoir été modifié et sera donc refusé par les organismes de l’Etat. Pouvez-vous nous joindre à nouveau ce document ?',
  },
  {
    id: 9002,
    name: 'Mauvaise qualité',
    allDocumentTypes: true,
    reason: 'Votre <DOCUMENT> n\'est pas conforme car il n\'est pas de bonne qualité. Pouvez-vous renvoyer ce document en meilleure qualité ?',
  },

  /**
   * IDENTITY
   */
  {
    id: 107,
    name: 'Titre de séjour sans la bonne mention',
    documentType: CompanyDocumentType.IDENTITY,
    reason: 'Votre pièce d\'identité n\'est pas conforme car la mention vous autorisant à séjourner en France n\'est pas valable pour exercer une activité d\'auto-entreprise en France. Veuillez vous rapprocher de votre préfecture qui pourra modifier votre titre de séjour pour que vous puissiez créer votre auto-entreprise car en l’état il ne sera pas accepté par les différents organismes',
  },
  {
    id: 102,
    name: 'Pas recto-verso',
    documentType: CompanyDocumentType.IDENTITY,
    reason: 'Votre pièce d\'identité n\'est pas conforme car nous avons besoin de votre pièce d\'identité en recto-verso.',
  },
  {
    id: 103,
    name: 'Manque mention + recto-verso',
    documentType: CompanyDocumentType.IDENTITY,
    reason: 'Votre pièce d\'identité n\'est pas conforme car elle doit être en recto-verso et avec la mention manuscrite '
        + '"J\'atteste sur l\'honneur que cette pièce d\'identité est conforme à l\'originale" ainsi que la date et votre signature '
        + '(https://aide.abby.fr/fr/articles/6460256-quels-sont-les-documents-a-fournir-pour-la-creation-d-une-micro-entreprise-sur-abby).',
  },
  {
    id: 105,
    name: 'Pièce d\'identité périmée',
    documentType: CompanyDocumentType.IDENTITY,
    reason: 'Votre pièce d\'identité n\'est pas conforme car elle est périmée. Les deux types de document autorisés pour la création d\'une auto-entreprise sont un passeport ou une carte d\'identité.',
  },
  {
    id: 109,
    name: 'Mineur',
    documentType: CompanyDocumentType.IDENTITY,
    reason: 'Malheureusement les mineurs ne peuvent pas créer une micro entreprise à moins d’être émancipés par décision de justice.',
  },
  {
    id: 110,
    name: 'Pièce d’identité hors UE',
    documentType: CompanyDocumentType.IDENTITY,
    reason: 'Votre document n’est pas conforme car nous avons besoin d’une pièce d’identité d’un pays membre de l’Union Européenne.',
  },

  /**
   * JUSTIFICATIF DE DOMICILE
   */

  {
    id: 200,
    name: 'Plus de 3 mois',
    documentType: CompanyDocumentType.RESIDENCE,
    reason: 'Votre justificatif de domicile date de plus de 3 mois, il n\'est donc plus valable. Merci de renvoyer un justificatif émis depuis moins de 3 mois.',
  },
  {
    id: 201,
    name: 'Pas à son nom',
    documentType: CompanyDocumentType.RESIDENCE,
    reason: 'Votre justificatif de domicile n\'est pas conforme car il n\'est pas à votre nom',
  },
  {
    id: 204,
    name: 'Pas à la même adresse',
    documentType: CompanyDocumentType.RESIDENCE,
    reason: 'Votre justificatif de domicile n\'est pas conforme car il n\'est pas à la même adresse que celui renseigné dans votre formulaire. Merci de modifier l\'adresse du formulaire pour qu\'elle corresponde à celle '
      + 'du justificatif.',
  },
  {
    id: 202,
    name: 'Document pas conforme',
    documentType: CompanyDocumentType.RESIDENCE,
    reason: 'Votre justificatif de domicile n\'est pas conforme car nous avons besoin d\'un document de type facture téléphone fixe, box internet, quittance de loyer, électricité, eau... Malheureusement, les factures de téléphone portable, les échéanciers, les avis d\'échéances et les assurances habitations ne sont plus acceptés par les organismes de l\'état.',
  },

  /**
   * MANDAT_CREATION
   */

  {
    id: 300,
    name: 'Manque signature',
    documentType: CompanyDocumentType.MANDAT_CREATION,
    reason: 'Votre mandat de création n\'est pas conforme car il manque votre signature manuscrite.',
  },
  {
    id: 302,
    name: 'Mauvais document',
    documentType: CompanyDocumentType.MANDAT_CREATION,
    reason: 'Votre mandat de création n\'est pas conforme car ce n\'est pas le bon document. '
        + 'Vous devez télécharger le fichier depuis votre espace Abby, le remplir et le signer, puis le déposer sur la plateforme.',
  },
  {
    id: 303,
    name: 'SIRET Abby supprimé',
    documentType: CompanyDocumentType.MANDAT_CREATION,
    reason: 'Votre mandat de création n\'est pas conforme car vous avez supprimé les informations de Abby. '
        + 'Pouvez-vous télécharger à nouveau le document et remplir seulement les informations vous concernant ?',
  },

  /**
   * ATTESTATION_NON_CONDAMNATION
   */

  {
    id: 400,
    name: 'Nom jeune fille',
    documentType: CompanyDocumentType.ATTESTATION_NON_CONDAMNATION,
    reason: 'Votre attestation de non-condamnation n\'est pas conforme car vous devez indiquer le nom de jeune fille de votre mère.',
  },
  {
    id: 404,
    name: 'Nom des parents + signature',
    documentType: CompanyDocumentType.ATTESTATION_NON_CONDAMNATION,
    reason: 'Votre attestation de non-condamnation n\'est pas conforme car vous devez indiquer le nom / prénom de vos parents et signer le document. Si vous ne les connaissez pas'
      + 'merci d\'apposer la mention père/mère inconnu(e).',
  },
  {
    id: 402,
    name: 'Mauvais document',
    documentType: CompanyDocumentType.ATTESTATION_NON_CONDAMNATION,
    reason: 'Votre attestation de non-condamnation n\'est pas conforme car ce n\'est pas le bon document. '
        + 'Vous devez télécharger le fichier depuis votre espace Abby, le remplir et le signer, puis le déposer sur la plateforme.',
  },
  {
    id: 403,
    name: 'Manque signature',
    documentType: CompanyDocumentType.ATTESTATION_NON_CONDAMNATION,
    reason: 'Votre attestation de non-condamnation n\'est pas conforme car il manque votre signature manuscrite.',
  },

  /**
   * Attestation d'hébergement
   */

  {
    id: 500,
    name: 'Plus de 3 mois',
    documentType: CompanyDocumentType.ATTESTATION_HEBERGEMENT,
    reason: 'Votre attestation d\'hébergement date de plus de 3 mois, il n\'est donc plus valable. Merci de renvoyer un justificatif émis depuis moins de 3 mois.',
  },
  {
    id: 501,
    name: 'Manque signature',
    documentType: CompanyDocumentType.ATTESTATION_HEBERGEMENT,
    reason: 'Votre attestation d\'hébergement n\'est pas conforme car il manque votre signature manuscrite.',
  },

  /**
   * PIECE IDENTITE HEBERGEUR
   */

  {
    id: 600,
    name: 'Manque mention manuscrite',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'La pièce d\'identité de votre hébergeur n\'est pas conforme car il manque la mention manuscrite '
      + '"J\'atteste sur l\'honneur que cette pièce d\'identité est conforme à l\'originale" ainsi que la date et votre signature '
      + '(https://aide.abby.fr/fr/articles/6460256-quels-sont-les-documents-a-fournir-pour-la-creation-d-une-micro-entreprise-sur-abby).',
  },
  {
    id: 606,
    name: 'Manque date manuscrite',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'La pièce d\'identité de votre hébergeur n\'est pas conforme car il manque la DATE à côté de la mention manuscrite '
      + '"J\'atteste sur l\'honneur que cette pièce d\'identité est conforme à l\'originale" et votre signature.',
  },
  {
    id: 602,
    name: 'Pas recto-verso',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'La pièce d\'identité de votre hébergeur n\'est pas conforme car nous avons besoin de votre pièce d\'identité en recto-verso.',
  },
  {
    id: 603,
    name: 'Manque mention + recto-verso',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'La pièce d\'identité de votre hébergeur n\'est pas conforme car elle doit être en recto-verso et avec la mention manuscrite '
      + '"J\'atteste sur l\'honneur que cette pièce d\'identité est conforme à l\'originale" ainsi que la date et votre signature '
      + '(https://aide.abby.fr/fr/articles/6460256-quels-sont-les-documents-a-fournir-pour-la-creation-d-une-micro-entreprise-sur-abby).',
  },
  {
    id: 604,
    name: 'Manque signature',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'La pièce d\'identité de votre hébergeur n\'est pas conforme car il manque votre signature manuscrite.',
  },
  {
    id: 605,
    name: 'Pièce d\'identité périmée',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'La pièce d\'identité de votre hébergeur n\'est pas conforme car elle est périmée. Les deux types de document autorisés pour la création d\'une auto-entreprise sont un passeport ou une carte d\'identité.',
  },
  {
    id: 606,
    name: 'Pièce d’identité utilisateur et non hébergeur',
    documentType: CompanyDocumentType.CARTE_IDENTITE_HEBERGEUR,
    reason: 'Votre document n’est pas conforme car il s’agit de votre pièce d’identité et non celle de votre hébergeur.',
  },

  /**
   * CONTRAT DE MARIAGE
   */
  {
    id: 700,
    name: 'Rayé la mention contrat mariage',
    documentType: CompanyDocumentType.ATTESTATION_CONJOINT_INFORME,
    reason: 'L\'attestation d\'information du conjoint n\'est pas conforme car vous devez rayer la mention sans contrat de mariage ou bien avec un contrat de mariage.',
  },
  {
    id: 701,
    name: 'Manque signature',
    documentType: CompanyDocumentType.ATTESTATION_CONJOINT_INFORME,
    reason: 'L\'attestation d\'information du conjoint n\'est pas conforme car il manque votre signature manuscrite.',
  },

  /**
   * JUSTIFICATIF_XP_DIPLOME
   */
  {
    id: 800,
    name: 'Mauvais document coursier motorisé',
    documentType: CompanyDocumentType.JUSTIFICATIF_XP_DIPLOME,
    reason: 'Le justificatif d\'expérience ne correspond pas à celui nécessaire pour être motorisé. Contactez nous directement dans le tchat pour que nous vous aidions à trouver une solution.',
  },
  {
    id: 801,
    name: 'Justificatif pas conforme',
    documentType: CompanyDocumentType.JUSTIFICATIF_XP_DIPLOME,
    reason: 'Le justificatif d\'expérience n\'est pas conforme, pouvez vous nous transmettre un autre justificatif ? Contactez nous dans le tchat en cas de difficulté.',
  },

  /**
   * CONTRAT_DOMICILIATION
   */
  {
    id: 900,
    name: 'Mauvais document',
    documentType: CompanyDocumentType.CONTRAT_DOMICILIATION,
    reason: 'Vous souhaitez faire domicilier votre entreprise avec Kandbaz. C’est un très bon choix ! De quoi ne pas se faire embêter à votre domicile perso et d’économiser de l’argent sur votre cotisation foncière des entreprises. Vous ne nous avez pas fourni le bon contrat de domiciliation pour que nous puissions continuer la création de votre entreprise. Pouvez vous nous joindre le document de domiciliation en intégralité ? Si vous souhaitez l’obtenir vous pouvez joindre Kandbaz par téléphone au  01 44 70 70 70 ou par e-mail à l’adresse commercial@kandbaz.com.',
  },
  {
    id: 901,
    name: 'Contrat pas signé',
    documentType: CompanyDocumentType.CONTRAT_DOMICILIATION,
    reason: 'Le contrat doit être signé par les deux parties, pouvez vous contacter la société de domiciliation afin qu’elle signe également le document ? Merci à vous.',
  },
  {
    id: 902,
    name: 'Contrat pas en entier',
    documentType: CompanyDocumentType.CONTRAT_DOMICILIATION,
    reason: 'Pouvez vous nous joindre le contrat dans son intégralité ? Merci à vous.',
  },

  /**
   * CONTRAT_DOMICILIATION
   */
  {
    id: 1000,
    name: 'Non conforme',
    documentType: CompanyDocumentType.BAIL_COMMERCIAL,
    reason: 'Votre document n’est pas conforme car il ne s’agit pas d’un bail commercial. Si il s’agit d’une erreur et que vous souhaitez que votre entreprise soit domicilié à votre domicile merci de nous le signaler afin que nous puissions faire le nécessaire.',
  },

];
