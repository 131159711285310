import { Plugin } from '@nuxt/types'
import { Amount, Currency, formatCents, formatDecimals } from '@abby/core-legacy'

export interface AmountParams {
  currency?: Currency
}

export interface AmountInstance {
  formatCents(value: number | string, params?: AmountParams): Amount ;
  formatDecimals(value: number | string, params?: AmountParams): Amount;
  formatVatRate(value: number | string): string;
}

const AmountPlugin: Plugin = (_, inject) => {
  inject('amount', {
    formatDecimals: (value: number | string, params?: AmountParams): Amount => {
      return formatDecimals(value, params?.currency || Currency.EUR)
    },
    formatCents: (value: number | string, params?: AmountParams): Amount => {
      return formatCents(value, params?.currency || Currency.EUR)
    },
    formatVatRate: (value: number): string => {
      if (!value) { return '' }
      return `${value / 100} %`
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $amount: AmountInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $amount: AmountInstance
  }
  interface Context {
    $amount: AmountInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $amount: AmountInstance
  }
}

export default AmountPlugin
