
import { Component, Getter, Vue } from 'nuxt-property-decorator'

@Component({
  middleware: ['redirection'],
  components: {},
})
export default class Auth extends Vue {
  head () {
    return this.$brandManager.head()
  }

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;
}

