import { Context } from '@nuxt/types'
import { Api, resources } from './resource'

export default (context: Context, inject: any) => {
  return inject(
    'api',
    Api({
      url: '/api',
      // @ts-ignore
      axios: context.$axios,
    }),
  )
}

type ApiMethods = {
  setToken: (token: string) => {};
} & {
  [K in keyof typeof resources]: InstanceType<typeof resources[K]>;
};

declare module 'vue/types/vue' {
  interface Vue {
    $api: ApiMethods;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $api: ApiMethods;
  }
  interface Context {
    $api: ApiMethods;
  }
}

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $api: ApiMethods;
  }
}
