import { StockEvent } from '@abby/shared'

export interface StockMovementItemProps {
  id: string;
  date: number;
  companyId: string;
  productDesignation: string;
  productId: string;
  quantity: number;
  billingId?: string | null;
  billingNumber?: string | null;
  stockEvent: StockEvent;
  product?: string;
  productReference?: string | null;
}

export class StockMovementItem {
  readonly id: string;
  readonly date: number;
  readonly companyId: string;
  readonly productDesignation: string;
  readonly productId: string;
  readonly quantity: number;
  readonly billingId?: string | null;
  readonly billingNumber?: string | null;
  readonly stockEvent: StockEvent;
  readonly product?: string;
  readonly productReference?: string | null;

  private constructor (props: StockMovementItemProps) {
    this.id = props.id
    this.date = props.date
    this.companyId = props.companyId
    this.productDesignation = props.productDesignation
    this.productId = props.productId
    this.quantity = props.quantity
    this.billingId = props.billingId
    this.billingNumber = props.billingNumber
    this.stockEvent = props.stockEvent
    this.product = props.product
    this.productReference = props.productReference
  }

  static create (props: StockMovementItemProps) {
    return new StockMovementItem(props)
  }
}
