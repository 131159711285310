import { Context, Plugin } from '@nuxt/types'
import {
  EmailType,
  getAvailableVarsByType,
  getDefaultTemplateEmail,
  setTagsToDatas,
} from '@abby/core-legacy'
import type {
  IDefaultTemplateEmailCustomisation,
  IEmailCustomisationVariable,
} from '@abby/core-legacy'

export interface EmailManagerInstance {
  getAvailableVarsByType: (type: string | string []) => IEmailCustomisationVariable[]
  getDefaultTemplateEmail: (type: EmailType, hasVat: boolean) => IDefaultTemplateEmailCustomisation
  setTagsToDatas: (type: EmailType, data: any, preview?: boolean) => Promise<{ subject: string, email: string } | null>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EmailManagerPlugin: Plugin = ({ store, app, $planManager }: Context, inject) => {
  const manager = {
    getAvailableVarsByType: (type: string | string[]) => {
      return getAvailableVarsByType(type)
    },
    getDefaultTemplateEmail: (type: EmailType, hasVat: boolean) => {
      return getDefaultTemplateEmail(type, hasVat)
    },
    setTagsToDatas: (type: EmailType, data: any, preview = false) => {
      return setTagsToDatas(type, preview || $planManager.hasAccessTo($planManager.features.billing_email_customisation), data)
    },
  }

  inject('emailManager', {
    ...manager,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $emailManager: EmailManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $emailManager: EmailManagerInstance
  }
  interface Context {
    $emailManager: EmailManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $emailManager: EmailManagerInstance
  }
}

export default EmailManagerPlugin
