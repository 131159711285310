import {
  BankInformations,
  Civility,
  CountryCode,
  SapAddress,
} from '@abby/shared'

export type ContactTPFormProps = {
  id?: string;
  civility: Civility;
  birthname: string;
  lastname?: string;
  firstname: string;
  birthCountry: CountryCode;
  birthZipCode?: string;
  birthCity?: string;
  birthCityCode?: string;
  birthInseeDepartmentCode?: string;
  birthDate: Date;
  mobilePhone: string;
  email: string;
  address: SapAddress;
  bankInformation: BankInformations;
  contactId?: string;
  idClient?: string;
  registeredAt?: Date;
};

export class ContactTPForm {
  id?: string;
  civility: Civility;
  birthname: string;
  lastname?: string;
  firstname: string;
  birthCountry: CountryCode;
  birthZipCode?: string;
  birthCity?: string;
  birthCityCode?: string;
  birthInseeDepartmentCode?: string;
  birthDate: Date;
  mobilePhone: string;
  email: string;
  address: SapAddress;
  bankInformation: BankInformations;
  contactId?: string;
  idClient?: string;
  registeredAt?: Date;

  private constructor (props: ContactTPFormProps) {
    this.id = props.id
    this.civility = props.civility
    this.birthname = props.birthname
    this.lastname = props.lastname
    this.firstname = props.firstname
    this.birthCountry = props.birthCountry
    this.birthZipCode = props.birthZipCode
    this.birthCity = props.birthCity
    this.birthCityCode = props.birthCityCode
    this.birthInseeDepartmentCode = props.birthInseeDepartmentCode
    this.birthDate = props.birthDate
    this.mobilePhone = props.mobilePhone
    this.email = props.email
    this.address = props.address
    this.bankInformation = props.bankInformation
    this.contactId = props.contactId
    this.idClient = props.idClient
    this.registeredAt = props.registeredAt
  }

  public static create (props: ContactTPFormProps): ContactTPForm {
    return new ContactTPForm(props)
  }
}
