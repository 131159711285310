import { Plugin } from '@nuxt/types'

export interface IntercomInstance {
  init: () => void;
  toggleIntercomPosition: (value: boolean) => void;
  open: () => void;
}

export const IntercomPlugin: Plugin = ({ store, $site }, inject) => {
  const init = () => {
    if (store.getters['auth/connectedFromDashboard'] || $site.isDashboard || (window as any).Intercom) { return }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = '/scripts/intercom.js'
    const firstScript: any = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  }

  const toggleIntercomPosition = (value: boolean) => {
    try {
      // @ts-ignore
      window.Intercom?.('update', {
        vertical_padding: value ? 70 : 20,
      })
    } catch {}
  }

  const open = () => {
    try {
      // @ts-ignore
      window.Intercom?.('show')
    } catch {}
  }

  inject('intercom', {
    init,
    toggleIntercomPosition,
    open,
  })
}
export default IntercomPlugin

declare module 'vue/types/vue' {
  interface Vue {
    $intercom: IntercomInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $intercom: IntercomInstance
  }
  interface Context {
    $intercom: IntercomInstance
  }
}

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $intercom: IntercomInstance
  }
}
