import { StockMovementItem } from '~/services/stock/StockMovementItem.entity'

export interface StockMovementsPageItemProps {
  docs: StockMovementItem[];
  countWithoutFilters: number;
  totalDocs: number;
  limit: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number | null;
  page: number;
  prevPage: number | null;
}

export class StockMovementsPageItem {
  readonly docs: StockMovementItem[];
  readonly countWithoutFilters: number;
  readonly totalDocs: number;
  readonly limit: number;
  readonly totalPages: number;
  readonly hasNextPage: boolean;
  readonly hasPrevPage: boolean;
  readonly nextPage: number | null;
  readonly page: number;
  readonly prevPage: number | null;

  private constructor (props: StockMovementsPageItemProps) {
    this.docs = props.docs
    this.countWithoutFilters = props.countWithoutFilters
    this.totalDocs = props.totalDocs
    this.limit = props.limit
    this.totalPages = props.totalPages
    this.hasNextPage = props.hasNextPage
    this.hasPrevPage = props.hasPrevPage
    this.nextPage = props.nextPage
    this.page = props.page
    this.prevPage = props.prevPage
  }

  static create (props: StockMovementsPageItemProps) {
    return new StockMovementsPageItem(props)
  }

}
