import { ActionTree, GetterTree } from 'vuex'
import type { ITask, ReadTask } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface TaskDetailsState {
  task: ITask | null;
  openedTaskDetails: ReadTask[]
}

export const state = (): TaskDetailsState => ({
  task: null,
  openedTaskDetails: [],
})

export const getters: GetterTree<TaskDetailsState, RootState> = {
  openedTaskDetails: state => state.openedTaskDetails,
  task: state => state.task,
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.openedTaskDetails.slice(-1)?.[0]?.id === id,
}

export const mutations = {
  SET_OPENED_TASKS (state: TaskDetailsState, payload: ReadTask[]) {
    state.openedTaskDetails = payload
  },
  SPLICE_OPENED_TASKS (state: TaskDetailsState, index: number) {
    state.openedTaskDetails.splice(index)
  },
  RESET (_currentState: TaskDetailsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  openTaskDetails ({ commit, getters }, task: ReadTask) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(task.id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('SET_OPENED_TASKS', [
      ...getters.openedTaskDetails,
      task,
    ])
  },
  closeTaskDetails ({ commit }, index: number) {
    commit('SPLICE_OPENED_TASKS', index)
  },
}
