import { Resource } from '../Resource'

export class Experience extends Resource {
  getAll () {
    return this.axios.$get('/experience')
  }

  getUserExperience (activityId: number | null) {
    return this.axios.$get(`/experience/${activityId}`)
  }
}
