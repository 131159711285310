import type {
  BasePaginate, CreateTimeRecord, QueryTimeRecord, ReadTimeRecord, UpdateTimeRecord,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class TimeTracking extends Resource {
  getPaginated (query: QueryTimeRecord): Promise<BasePaginate<ReadTimeRecord>> {
    return this.axios.$get('/timeRecords/paginate', { params: query })
  }

  create (payload: CreateTimeRecord): Promise<ReadTimeRecord> {
    return this.axios.$post('/timeRecord', payload)
  }

  update (payload: UpdateTimeRecord, id: string): Promise<ReadTimeRecord> {
    return this.axios.$put(`/timeRecord/${id}`, payload)
  }

  delete (id: string): Promise<void> {
    return this.axios.$delete(`/timeRecord/${id}`)
  }

  bulkDelete (id: string[]): Promise<void> {
    return this.axios.$patch('/timeRecords/delete', id)
  }

  bulkUpdate (payload: UpdateTimeRecord, id: string[]): Promise<void> {
    return this.axios.$put('/timeRecords', { id, payload })
  }

  count (): Promise<number> {
    return this.axios.$get('/timeRecords/count')
  }
}
