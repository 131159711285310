export enum PaymentRequestStatus {
  // La demande de paiement a été reçue et a passé les différents contrôle de cohérence
  PERIODE_EMPLOI_MOIS_NON_UNIQUE,
  INTEGRATED = '10',
  // Le particulier a été prévenu que la demande de paiement est à valider/rejeter par lui
  WAITING_FOR_VALIDATION = '20',
  // La demande de paiement a été validée par le particulier et le circuit de prélèvement est lancé
  VALIDATED = '30',
  // La demande de paiement est refusée par le particulier : il a renseigné son rejet et un code motif associé
  REFUSED = '40',
  // La demande de paiement a été payée avec succès par le particulier (le RAC uniquement)
  DEBIT = '50',
  // Le prélèvement associé à la demande de paiement est revenu en erreur .La demande de paiement est en impayée.
  DEBIT_REFUSED = '60',
  // Le virement de paiement de la demande de paiement au prestataire est lancé (fichier de virement ok)
  PAYED = '70',
  // La demande de paiement a été annulée par le particulier.
  CANCELED = '110',
  CANCELED_AFTER_OUTSTANDING_PAYMENT = '111',
  CANCELED_AFTER_RECOVERY = '112',
  // Le client n'a pas activé son compte Urssaf particulier
  ERR_PARTICULIER_INCONNU = 'ERR_PARTICULIER_INCONNU',
  // Le client a son compte Urssaf bloqué
  ERR_COMPTE_BLOQUE = 'ERR_CMPT_BLOQUE',
  // Il y'a un doublon du côté de l'urssaf
  ERR_FACTURE_DOUBLON = 'ERR_FACTURE_DOUBLON',
  // Le nombre maximum de transmission de factures a été dépassé Ne dois pas dépasser la valeur max définie par paramètre. (intialisé à 10)
  ERR_NBRE_PREST_MAX = 'ERR_NBRE_PREST_MAX',

  // pas handle (pas forcément besoin de tout déplacer au dessus si handle)
  ERR_DATE_FUTUR = 'ERR_DATE_FUTUR',
  ERR_DATE_PAIEMENT_AVANT_EXP = 'ERR_DATE_PAIEMENT_AVANT_EXP',
  ERR_PERIODE_EMPLOI_MOIS_NON_UNIQUE = 'ERR_PERIODE_EMPLOI_MOIS_NON_UNIQUE',
  ERR_DATE_FIN_AVANT_DATE_DEB = 'ERR_DATE_FIN_AVANT_DATE_DEB',
  ERR_MONTANT_ACOMPTE = 'ERR_MONTANT_ACOMPTE',
  ERR_TOTAL_PRESTATIONS = 'ERR_TOTAL_PRESTATIONS',
  ERR_CODE_NATURE = 'ERR_CODE_NATURE',
  ERR_CODE_ACTIVITE = 'ERR_CODE_ACTIVITE',
  ERR_CODE_ACTIVITE_NATURE = 'ERR_CODE_ACTIVITE_NATURE',
  ERR_MNT_PREST_HT_TVA = 'ERR_MNT_PREST_HT_TVA',
  ERR_MNT_PREST_TTC = 'ERR_MNT_PREST_TTC',
  ERR_VALEUR_NEGATIVE = 'ERR_VALEUR_NEGATIVE',
  ERR_PRESTATAIRE_EMETTEUR_INCONNU = 'ERR_PRESTATAIRE_EMETTEUR_INCONNU',
  ERR_LIEN_PARTICULIER_PRESTATAIRE = 'ERR_LIEN_PARTICULIER_PRESTATAIRE',
  ERR_PRESTATAIRE_FACTURATION_INCONNU = 'ERR_PRESTATAIRE_FACTURATION_INCONNU',
  ERR_CRITERE_RECHERCHE_VIDE = 'ERR_CRITERE_RECHERCHE_VIDE',
  ERR_DGFIP_R2P_INCONNU = 'ERR_DGFIP_R2P_INCONNU',
  ERR_DATE_NAISSANCE_FUTUR = 'ERR_DATE_NAISSANCE_FUTUR',
  ERR_LIEU_NAISSANCE_DEPT = 'ERR_LIEU_NAISSANCE_DEPT',
  ERR_LIEU_NAISSANCE_COMN = 'ERR_LIEU_NAISSANCE_COMN',
  ERR_LIEU_NAISSANCE_PAYS = 'ERR_LIEU_NAISSANCE_PAYS',
  ERR_DGFIP_IMPOTPART_INCONNU = 'ERR_DGFIP_IMPOTPART_INCONNU',
  ERR_CONTACT = 'ERR_CONTACT',
  ERR_CONTACT_ADRESSE_TYPE_VOIE = 'ERR_CONTACT_ADRESSE_TYPE_VOIE',
  ERR_CONTACT_ADRESSE_LETTRE_VOIE = 'ERR_CONTACT_ADRESSE_LETTRE_VOIE',
  ERR_CONTACT_ADRESSE_TYPEVOIE = 'ERR_CONTACT_ADRESSE_TYPEVOIE',
  ERR_CONTACT_ADRESSE_LIBELLEVOIE = 'ERR_CONTACT_ADRESSE_LIBELLEVOIE',
  ERR_CONTACT_ADRESSE_INFORMATION_SUPPLEMENTAIRE = 'ERR_CONTACT_ADRESSE_INFORMATION_SUPPLEMENTAIRE',
  ERR_CONTACT_ADRESSE_COMMUNE = 'ERR_CONTACT_ADRESSE_COMMUNE',
  ERR_COORDONNEES_BANCAIRES = 'ERR_COORDONNEES_BANCAIRES',
  ERR_FORMAT_VALEUR = 'ERR_FORMAT_VALEUR',
}
