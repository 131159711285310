import type {
  CreateCompanyOpportunityCategory, CreateOpportunity, QueryOpportunities,
  ReadCompanyOpportunityCategory, ReadOpportunity, ReadOpportunityStatistics,
  UpdateCompanyOpportunityCategory, UpdateOpportunity,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Opportunity extends Resource {
  createCategory (payload: CreateCompanyOpportunityCategory): Promise<ReadCompanyOpportunityCategory> {
    return this.axios.$post('/opportunity/category', payload)
  }

  createOpportunity (payload: CreateOpportunity): Promise<ReadOpportunity> {
    return this.axios.$post('/opportunity', payload)
  }

  removeOpportunity (id: string) {
    return this.axios.$delete(`/opportunity/${id}`)
  }

  getCategories (): Promise<ReadCompanyOpportunityCategory[]> {
    return this.axios.$get('/opportunity/categories')
  }

  updateCategory (payload: UpdateCompanyOpportunityCategory & { id: string }): Promise<ReadCompanyOpportunityCategory> {
    const { id, ...data } = payload
    return this.axios.$put(`/opportunity/category/${id}`, data)
  }

  updateOpportunity (payload: UpdateOpportunity & { id: string }): Promise<ReadOpportunity> {
    const { id, ...data } = payload
    return this.axios.$put(`/opportunity/${id}`, data)
  }

  removeCategory (id: string, target?: string) {
    return this.axios.$delete(`/opportunity/category/${id}`, {
      ...(target
        ? {
          params: { target },
        }
        : {}),
    })
  }

  getOpportunities (query: QueryOpportunities): Promise<{ totalAmount: number, totalItems: number, data: ReadOpportunity[] }> {
    return this.axios.$get('/opportunities', { params: query })
  }

  getOpportunity (id: string): Promise<ReadOpportunity> {
    return this.axios.$get(`/opportunity/${id}`)
  }

  getStatistics (): Promise<ReadOpportunityStatistics> {
    return this.axios.$get('/opportunities/statistics')
  }

  count (): Promise<number> {
    return this.axios.$get('/opportunities/count')
  }
}
