import { PersonalServiceActivity, ProductType, ProductUnit, VatCode } from '@abby/shared'

export type ProductFormProps = {
  id?: string;
  type: ProductType;
  unit: ProductUnit;
  designation: string;
  amountInCents: number;
  taxesIncluded?: boolean;
  vatCode: VatCode;
  isDeliveryOfGood?: boolean;
  description?: string;
  reference?: string;
  personalServiceActivity?: PersonalServiceActivity;
  stock?: number;
}

export class ProductForm {
  readonly id?: string;
  readonly type: ProductType;
  readonly unit: ProductUnit;
  readonly designation: string;
  readonly amountInCents: number;
  readonly taxesIncluded?: boolean;
  readonly vatCode: VatCode;
  readonly isDeliveryOfGood?: boolean;
  readonly description?: string;
  readonly reference?: string;
  readonly personalServiceActivity?: PersonalServiceActivity;
  readonly stock?: number;

  private constructor (props: ProductFormProps) {
    this.id = props.id
    this.type = props.type
    this.unit = props.unit
    this.designation = props.designation
    this.amountInCents = props.amountInCents
    this.taxesIncluded = props.taxesIncluded
    this.vatCode = props.vatCode
    this.isDeliveryOfGood = props.isDeliveryOfGood
    this.description = props.description
    this.reference = props.reference
    this.personalServiceActivity = props.personalServiceActivity
    this.stock = props.stock
  }

  public static create (props: ProductFormProps): ProductForm {
    return new ProductForm(props)
  }
}
