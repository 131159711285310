import type {
  CreateDeclarationStep, CreateRefundRequest, DeclarationType,
  ReadDeclarationPeriod,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Declaration extends Resource {
  updateStep (payload: CreateDeclarationStep): Promise<ReadDeclarationPeriod> {
    return this.axios.$post('/declaration/step', payload)
  }

  getDeclarationPeriods (type: DeclarationType): Promise<ReadDeclarationPeriod[]> {
    return this.axios.$get('/declaration/periods', { params: { type } })
  }

  vatAskForRefund (payload: CreateRefundRequest): Promise<ReadDeclarationPeriod> {
    return this.axios.$post('/declaration/vat/refund', payload)
  }
}
