import type {
  CreateFileAccounting, QueryIncomeStatement, ReadFileAccounting,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Accounting extends Resource {
  getIncomeStatement (params: QueryIncomeStatement) {
    return this.axios.$get('/accounting/incomeStatement', { params })
  }

  downloadBook (params: QueryIncomeStatement) {
    return this.axios.get('/accounting/incomeStatement/download', { params, responseType: 'blob' })
  }

  addFile (id: string, body: CreateFileAccounting): Promise<ReadFileAccounting> {
    return this.axios.$post(`/accounting/${id}/file`, body)
  }

  getFiles (id: string): Promise<Array<ReadFileAccounting>> {
    return this.axios.$get(`/accounting/${id}/files`)
  }

  deleteFile (id: string, fileId: string) {
    return this.axios.$delete(`/accounting/${id}/file/${fileId}`)
  }
}
