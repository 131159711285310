import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_1393ae07 from 'nuxt_plugin_sentryserver_1393ae07' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_a44b8702 from 'nuxt_plugin_sentryclient_a44b8702' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_74759f3e from 'nuxt_plugin_plugin_74759f3e' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_plugin_1bc44456 from 'nuxt_plugin_plugin_1bc44456' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_52369aeb from 'nuxt_plugin_cookieuniversalnuxt_52369aeb' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_32ac3d7a from 'nuxt_plugin_axios_32ac3d7a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_03ae4950 from 'nuxt_plugin_pluginutils_03ae4950' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_65401aba from 'nuxt_plugin_pluginrouting_65401aba' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_a82098a8 from 'nuxt_plugin_pluginmain_a82098a8' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_portalvue_22325cf0 from 'nuxt_plugin_portalvue_22325cf0' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_workbox_6587119f from 'nuxt_plugin_workbox_6587119f' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_distplugin34f2fc17_fe5d9526 from 'nuxt_plugin_distplugin34f2fc17_fe5d9526' // Source: ./dist.plugin.34f2fc17.mjs (mode: 'all')
import nuxt_plugin_router_ba425a68 from 'nuxt_plugin_router_ba425a68' // Source: ./router.js (mode: 'all')
import nuxt_plugin_dayjs_62ca2828 from 'nuxt_plugin_dayjs_62ca2828' // Source: ../plugins/dayjs (mode: 'all')
import nuxt_plugin_tanstack_4a32bc20 from 'nuxt_plugin_tanstack_4a32bc20' // Source: ../plugins/tanstack (mode: 'all')
import nuxt_plugin_hotjar_0c58f587 from 'nuxt_plugin_hotjar_0c58f587' // Source: ../plugins/hotjar (mode: 'all')
import nuxt_plugin_updateManager_9110f4aa from 'nuxt_plugin_updateManager_9110f4aa' // Source: ../plugins/updateManager (mode: 'all')
import nuxt_plugin_firebase_491a0ea0 from 'nuxt_plugin_firebase_491a0ea0' // Source: ../plugins/firebase (mode: 'all')
import nuxt_plugin_api_280fbc3e from 'nuxt_plugin_api_280fbc3e' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_sdk_280f37fe from 'nuxt_plugin_sdk_280f37fe' // Source: ../plugins/sdk (mode: 'all')
import nuxt_plugin_axios_630a4306 from 'nuxt_plugin_axios_630a4306' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_backend_288d72db from 'nuxt_plugin_backend_288d72db' // Source: ../plugins/backend (mode: 'all')
import nuxt_plugin_abbyPixel_1f26b096 from 'nuxt_plugin_abbyPixel_1f26b096' // Source: ../plugins/abbyPixel (mode: 'all')
import nuxt_plugin_site_d9d79f40 from 'nuxt_plugin_site_d9d79f40' // Source: ../plugins/site (mode: 'all')
import nuxt_plugin_move_d9dce62c from 'nuxt_plugin_move_d9dce62c' // Source: ../plugins/move (mode: 'all')
import nuxt_plugin_campaignsManager_077f973a from 'nuxt_plugin_campaignsManager_077f973a' // Source: ../plugins/campaignsManager (mode: 'all')
import nuxt_plugin_planManager_144cdd0b from 'nuxt_plugin_planManager_144cdd0b' // Source: ../plugins/planManager (mode: 'all')
import nuxt_plugin_intercom_e6a48884 from 'nuxt_plugin_intercom_e6a48884' // Source: ../plugins/intercom (mode: 'client')
import nuxt_plugin_help_d9e1bf4c from 'nuxt_plugin_help_d9e1bf4c' // Source: ../plugins/help (mode: 'all')
import nuxt_plugin_brandManager_b6906382 from 'nuxt_plugin_brandManager_b6906382' // Source: ../plugins/brandManager (mode: 'all')
import nuxt_plugin_alertsManager_728a181d from 'nuxt_plugin_alertsManager_728a181d' // Source: ../plugins/alertsManager (mode: 'all')
import nuxt_plugin_toasterManager_d7a74c5c from 'nuxt_plugin_toasterManager_d7a74c5c' // Source: ../plugins/toasterManager (mode: 'all')
import nuxt_plugin_amount_0048bf31 from 'nuxt_plugin_amount_0048bf31' // Source: ../plugins/amount (mode: 'all')
import nuxt_plugin_lodash_1324f26e from 'nuxt_plugin_lodash_1324f26e' // Source: ../plugins/lodash (mode: 'all')
import nuxt_plugin_workerBilling_cb780fb8 from 'nuxt_plugin_workerBilling_cb780fb8' // Source: ../plugins/workerBilling (mode: 'client')
import nuxt_plugin_experienceManager_8ef07b2c from 'nuxt_plugin_experienceManager_8ef07b2c' // Source: ../plugins/experienceManager (mode: 'all')
import nuxt_plugin_busManager_03b39934 from 'nuxt_plugin_busManager_03b39934' // Source: ../plugins/busManager (mode: 'all')
import nuxt_plugin_clipboard_e89d57c6 from 'nuxt_plugin_clipboard_e89d57c6' // Source: ../plugins/clipboard (mode: 'all')
import nuxt_plugin_textsearchhighlighter_7c0379fe from 'nuxt_plugin_textsearchhighlighter_7c0379fe' // Source: ../filters/text-search-highlighter (mode: 'all')
import nuxt_plugin_date_d9e57e72 from 'nuxt_plugin_date_d9e57e72' // Source: ../plugins/date (mode: 'all')
import nuxt_plugin_confirmManager_69c62ea6 from 'nuxt_plugin_confirmManager_69c62ea6' // Source: ../plugins/confirmManager (mode: 'all')
import nuxt_plugin_veevalidate_5d132586 from 'nuxt_plugin_veevalidate_5d132586' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_i18n_d9e26992 from 'nuxt_plugin_i18n_d9e26992' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_emailManager_2d087c8a from 'nuxt_plugin_emailManager_2d087c8a' // Source: ../plugins/emailManager (mode: 'all')
import nuxt_plugin_stripe_1f63cf26 from 'nuxt_plugin_stripe_1f63cf26' // Source: ../plugins/stripe (mode: 'client')
import nuxt_plugin_file_d9e372d6 from 'nuxt_plugin_file_d9e372d6' // Source: ../plugins/file (mode: 'all')
import nuxt_plugin_onboardingManager_3b0c6c99 from 'nuxt_plugin_onboardingManager_3b0c6c99' // Source: ../plugins/onboardingManager (mode: 'all')
import nuxt_plugin_meta_dfc5abb2 from 'nuxt_plugin_meta_dfc5abb2' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"fr-FR"},"title":"Abby","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"og:local","content":"fr_FR"},{"name":"robots","content":"index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"},{"property":"og:locale","content":"fr_FR"},{"property":"og:type","content":"website"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Ffonts.cdnfonts.com","crossorigin":"anonymous"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.googleapis.com","crossorigin":"anonymous"},{"rel":"stylesheet preload prefetch","as":"style","crossorigin":"anonymous","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Material+Icons|Material+Icons+Round%7CRoboto:100,300,400,500,700,900&display=swap"},{"rel":"stylesheet preload prefetch","as":"style","crossorigin":"anonymous","href":"\u002Finter.css"}],"script":[{"src":"\u002Fscripts\u002Fsvg-inject.min.js","async":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_1393ae07 === 'function') {
    await nuxt_plugin_sentryserver_1393ae07(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_a44b8702 === 'function') {
    await nuxt_plugin_sentryclient_a44b8702(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_74759f3e === 'function') {
    await nuxt_plugin_plugin_74759f3e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_1bc44456 === 'function') {
    await nuxt_plugin_plugin_1bc44456(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_52369aeb === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_52369aeb(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_32ac3d7a === 'function') {
    await nuxt_plugin_axios_32ac3d7a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_03ae4950 === 'function') {
    await nuxt_plugin_pluginutils_03ae4950(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_65401aba === 'function') {
    await nuxt_plugin_pluginrouting_65401aba(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_a82098a8 === 'function') {
    await nuxt_plugin_pluginmain_a82098a8(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_22325cf0 === 'function') {
    await nuxt_plugin_portalvue_22325cf0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_6587119f === 'function') {
    await nuxt_plugin_workbox_6587119f(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin34f2fc17_fe5d9526 === 'function') {
    await nuxt_plugin_distplugin34f2fc17_fe5d9526(app.context, inject)
  }

  if (typeof nuxt_plugin_router_ba425a68 === 'function') {
    await nuxt_plugin_router_ba425a68(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_62ca2828 === 'function') {
    await nuxt_plugin_dayjs_62ca2828(app.context, inject)
  }

  if (typeof nuxt_plugin_tanstack_4a32bc20 === 'function') {
    await nuxt_plugin_tanstack_4a32bc20(app.context, inject)
  }

  if (typeof nuxt_plugin_hotjar_0c58f587 === 'function') {
    await nuxt_plugin_hotjar_0c58f587(app.context, inject)
  }

  if (typeof nuxt_plugin_updateManager_9110f4aa === 'function') {
    await nuxt_plugin_updateManager_9110f4aa(app.context, inject)
  }

  if (typeof nuxt_plugin_firebase_491a0ea0 === 'function') {
    await nuxt_plugin_firebase_491a0ea0(app.context, inject)
  }

  if (typeof nuxt_plugin_api_280fbc3e === 'function') {
    await nuxt_plugin_api_280fbc3e(app.context, inject)
  }

  if (typeof nuxt_plugin_sdk_280f37fe === 'function') {
    await nuxt_plugin_sdk_280f37fe(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_630a4306 === 'function') {
    await nuxt_plugin_axios_630a4306(app.context, inject)
  }

  if (typeof nuxt_plugin_backend_288d72db === 'function') {
    await nuxt_plugin_backend_288d72db(app.context, inject)
  }

  if (typeof nuxt_plugin_abbyPixel_1f26b096 === 'function') {
    await nuxt_plugin_abbyPixel_1f26b096(app.context, inject)
  }

  if (typeof nuxt_plugin_site_d9d79f40 === 'function') {
    await nuxt_plugin_site_d9d79f40(app.context, inject)
  }

  if (typeof nuxt_plugin_move_d9dce62c === 'function') {
    await nuxt_plugin_move_d9dce62c(app.context, inject)
  }

  if (typeof nuxt_plugin_campaignsManager_077f973a === 'function') {
    await nuxt_plugin_campaignsManager_077f973a(app.context, inject)
  }

  if (typeof nuxt_plugin_planManager_144cdd0b === 'function') {
    await nuxt_plugin_planManager_144cdd0b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intercom_e6a48884 === 'function') {
    await nuxt_plugin_intercom_e6a48884(app.context, inject)
  }

  if (typeof nuxt_plugin_help_d9e1bf4c === 'function') {
    await nuxt_plugin_help_d9e1bf4c(app.context, inject)
  }

  if (typeof nuxt_plugin_brandManager_b6906382 === 'function') {
    await nuxt_plugin_brandManager_b6906382(app.context, inject)
  }

  if (typeof nuxt_plugin_alertsManager_728a181d === 'function') {
    await nuxt_plugin_alertsManager_728a181d(app.context, inject)
  }

  if (typeof nuxt_plugin_toasterManager_d7a74c5c === 'function') {
    await nuxt_plugin_toasterManager_d7a74c5c(app.context, inject)
  }

  if (typeof nuxt_plugin_amount_0048bf31 === 'function') {
    await nuxt_plugin_amount_0048bf31(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_1324f26e === 'function') {
    await nuxt_plugin_lodash_1324f26e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workerBilling_cb780fb8 === 'function') {
    await nuxt_plugin_workerBilling_cb780fb8(app.context, inject)
  }

  if (typeof nuxt_plugin_experienceManager_8ef07b2c === 'function') {
    await nuxt_plugin_experienceManager_8ef07b2c(app.context, inject)
  }

  if (typeof nuxt_plugin_busManager_03b39934 === 'function') {
    await nuxt_plugin_busManager_03b39934(app.context, inject)
  }

  if (typeof nuxt_plugin_clipboard_e89d57c6 === 'function') {
    await nuxt_plugin_clipboard_e89d57c6(app.context, inject)
  }

  if (typeof nuxt_plugin_textsearchhighlighter_7c0379fe === 'function') {
    await nuxt_plugin_textsearchhighlighter_7c0379fe(app.context, inject)
  }

  if (typeof nuxt_plugin_date_d9e57e72 === 'function') {
    await nuxt_plugin_date_d9e57e72(app.context, inject)
  }

  if (typeof nuxt_plugin_confirmManager_69c62ea6 === 'function') {
    await nuxt_plugin_confirmManager_69c62ea6(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_5d132586 === 'function') {
    await nuxt_plugin_veevalidate_5d132586(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_d9e26992 === 'function') {
    await nuxt_plugin_i18n_d9e26992(app.context, inject)
  }

  if (typeof nuxt_plugin_emailManager_2d087c8a === 'function') {
    await nuxt_plugin_emailManager_2d087c8a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_stripe_1f63cf26 === 'function') {
    await nuxt_plugin_stripe_1f63cf26(app.context, inject)
  }

  if (typeof nuxt_plugin_file_d9e372d6 === 'function') {
    await nuxt_plugin_file_d9e372d6(app.context, inject)
  }

  if (typeof nuxt_plugin_onboardingManager_3b0c6c99 === 'function') {
    await nuxt_plugin_onboardingManager_3b0c6c99(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_dfc5abb2 === 'function') {
    await nuxt_plugin_meta_dfc5abb2(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
