
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'as-currency-field',
  components: {
    DefaultCurrencyField: () => import('~/components-legacy/inputs/currencyField/DefaultCurrencyField.vue'),
    FallbackCurrencyField: () => import('~/components-legacy/inputs/currencyField/FallbackCurrencyField.vue'),
  },
})
export default class ASCurrencyField extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @ModelSync('value', 'input')
  innerValue!: number;

  @Prop({ required: false })
  min?: number | string;

  @Prop({ required: false, default: 2 })
  precision!: number | string;

  displayValue = '0,00';

  input!: HTMLInputElement;

  isAndroid = false;

  get attrs () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value, ..._attrs } = this.$attrs
    return _attrs
  }

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  @Prop({ required: false })
  tooltip!: string;

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  mounted () {
    this.isAndroid = navigator.userAgent.toLowerCase().includes('android')
  }
}
