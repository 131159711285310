
import { Component, Vue } from 'nuxt-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-form',
  components: {
    ValidationObserver,
  },
})
export default class ASForm extends Vue {
  public validate = (silent: boolean = false) => {
    return (this.$refs.form as InstanceType<typeof ValidationProvider>).validate({ silent })
  }

  public reset = () => {
    return (this.$refs.form as InstanceType<typeof ValidationProvider>).reset()
  }

  get isValid () {
    return (this.$refs.form as InstanceType<typeof ValidationProvider>).value
  }
}
