
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class CountryFlag extends Vue {
  @Prop()
  country!: string

  get countryPath () {
    return `/country/${this.country}.svg`
  }
}
