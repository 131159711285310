import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  AcademyType,
  arrayToMap,
} from '@abby/core-legacy'
import debounce from 'lodash/debounce'
import type {
  BasePaginate,
  CreateUpdateUserAcademyReview,
  IChapter,
  IUserAcademyReview,
  IUserLessonProgression,
  ICourse,
  ILesson,
  UpdateUserLessonProgression,
  VimeoVideoProgress,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface AcademyState {
  courseReviewsPagination: {
    page: number,
    limit: number,
  },
  queryLoading: boolean,
  course: null | ICourse
  courses: null | ICourse[]
  lessons: null | ILesson[]
  currentLessonSlug: string | null
  userLessonProgression: IUserLessonProgression[] | null
  userAcademyReviews: IUserAcademyReview[] | null
  courseReviewsPaginated: BasePaginate<IUserAcademyReview> | null
  videoProgress: null | VimeoVideoProgress,
  modals: {
    evaluateLesson: boolean
    evaluateCourse: boolean
    courseShowcase: boolean
  }
}

export type AcademyModals = Extract<keyof AcademyState['modals'], string>

export const state = (): AcademyState => ({
  courseReviewsPagination: {
    page: 1,
    limit: 10,
  },
  queryLoading: true,
  course: null,
  lessons: null,
  courses: null,
  currentLessonSlug: null,
  userLessonProgression: [],
  userAcademyReviews: [],
  courseReviewsPaginated: null,
  videoProgress: null,
  modals: {
    evaluateLesson: false,
    evaluateCourse: false,
    courseShowcase: false,
  },
})

export const getters: GetterTree<AcademyState, RootState> = {
  course: state => state.course,
  courses: state => state.courses,
  currentLessonSlug: state => state.currentLessonSlug,
  lessons: (state) :{ [key: string]: ILesson } => arrayToMap(state.lessons || [], 'slug'),
  userLessonProgression: (state) :{ [key: string]: IUserLessonProgression } => arrayToMap(state.userLessonProgression || [], 'slug'),
  userAcademyReviews: (state) :{ [key: string]: IUserAcademyReview } => arrayToMap(state.userAcademyReviews || [], 'slug'),
  currentChapter: (state): IChapter | null => {
    return state.course?.chapters?.find(chapter => chapter.lessons.some(lesson => lesson.slug === state.currentLessonSlug)) || null
  },
  currentLesson (state, getters): ILesson | null {
    return getters.currentChapter?.lessons.find((l: ILesson) => l.slug === state.currentLessonSlug) || null
  },
  nextLesson (_, getters): ILesson | null {
    const index = getters.currentLesson.position - 1
    const lessonsLength = getters.currentChapter.lessons.length
    const nextIndex = (index + 1) % lessonsLength

    if (nextIndex === 0) {
      return null
    }

    return getters.currentChapter.lessons[nextIndex]
  },
  previousLesson (_, getters): ILesson | null {
    const index = getters.currentLesson.position - 1
    if (index === 0 || index === undefined) {
      return null
    }

    return getters.currentChapter.lessons[index - 1]
  },
  modals: state => state.modals,
  courseReviewsPagination: (state: AcademyState) => {
    return state.courseReviewsPagination
  },
  courseReviewsPaginated: (state: AcademyState) => {
    return state.courseReviewsPaginated
  },
}

export const mutations: MutationTree<AcademyState> = {
  OPEN_MODAL (state: AcademyState, key: AcademyModals) {
    state.modals[key] = true
  },
  CLOSE_MODAL (state: AcademyState, key: AcademyModals) {
    state.modals[key] = false
  },
  SET_COURSE_REVIEWS_PAGINATION (state: AcademyState, courseReviewsQuery) {
    state.courseReviewsPagination = courseReviewsQuery
  },
  RESET (_currentState: AcademyState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_COURSE (state: AcademyState, course) {
    state.course = course
  },
  SET_COURSES (state: AcademyState, courses: ICourse[]) {
    state.courses = courses
  },
  SET_LESSONS (state: AcademyState, lessons: ILesson[]) {
    state.lessons = lessons
  },
  SET_COMPANY_LESSON_PROGRESS (state: AcademyState, userLessonProgression: IUserLessonProgression[]) {
    state.userLessonProgression = userLessonProgression
  },
  UPDATE_COMPANY_LESSON_PROGRESS (state: AcademyState, userLessonProgression: IUserLessonProgression) {
    const index = state.userLessonProgression?.findIndex(cl => cl.slug === userLessonProgression.slug)
    if (index !== undefined && index !== -1) {
      state.userLessonProgression?.splice(index, 1, userLessonProgression)
      return
    }

    state.userLessonProgression?.push(userLessonProgression)
  },
  SET_COMPANY_ACADEMY_REVIEWS (state: AcademyState, userAcademyReviews: IUserAcademyReview[]) {
    state.userAcademyReviews = userAcademyReviews
  },
  SET_COURSE_REVIEWS (state: AcademyState, courseReviews: BasePaginate<IUserAcademyReview> | null) {
    state.courseReviewsPaginated = courseReviews
  },
  UPDATE_COMPANY_ACADEMY_REVIEWS (state: AcademyState, companyLessonReview: IUserAcademyReview) {
    const index = state.userAcademyReviews?.findIndex(review => review.slug === companyLessonReview.slug)
    if (index !== undefined && index !== -1) {
      state.userAcademyReviews?.splice(index, 1, companyLessonReview)
      return
    }

    state.userAcademyReviews?.push(companyLessonReview)
  },
  SET_CURRENT_LESSON_SLUG (state: AcademyState, slug: string) {
    state.currentLessonSlug = slug
  },
  SET_VIDEO_PROGRESS (state: AcademyState, progress: VimeoVideoProgress) {
    state.videoProgress = progress
  },
}

export const actions: ActionTree<AcademyState, RootState> = {
  async fetchCourses ({ commit }) {
    const results = await this.$api.course.getAll()

    const sortChapterLessons = (chapter: IChapter) => ({
      ...chapter,
      lessons: [...chapter.lessons].sort((a, b) => a.position - b.position),
    })

    const courses = results.map((course) => {
      const sortedChapters = [...course.chapters].sort((a, b) => a.position - b.position)
      return {
        ...course,
        chapters: sortedChapters.map(sortChapterLessons),
      }
    })
    commit('SET_COURSES', courses)
  },
  async fetchCourse ({ commit }, slug: string) {
    const course = await this.$api.course.get(slug)

    // trier les chapitres dans l'ordre
    course.chapters = course.chapters.sort((a, b) => a.position - b.position)

    // trier les leçons dans l'ordre
    for (const chapterKey in course.chapters) {
      course.chapters[chapterKey].lessons = course.chapters[chapterKey].lessons.sort((a, b) => a.position - b.position)
    }

    commit('SET_COURSE', course)
  },
  async fetchLessons ({ commit }) {
    const lessons = await this.$api.lesson.getAll()
    commit('SET_LESSONS', lessons)
  },
  setCurrentLessonSlug ({ commit, dispatch }, slug: string | null) {
    dispatch('saveVideoProgress')
    commit('SET_VIDEO_PROGRESS', null)

    this.$router.replace({
      query: { lesson: slug },
    }, () => {})
    commit('SET_CURRENT_LESSON_SLUG', slug)
  },
  async createUserLessonProgression ({ commit }, lesson: ILesson) {
    const userLessonProgression = await this.$api.course.createUserLessonProgression({
      slug: lesson.slug,
    })
    commit('UPDATE_COMPANY_LESSON_PROGRESS', userLessonProgression)
  },
  setVideoProgress ({ commit }, progress: VimeoVideoProgress) {
    commit('SET_VIDEO_PROGRESS', progress)
  },
  saveVideoProgress ({ state, dispatch, getters }) {
    if (!state.videoProgress || !state.currentLessonSlug) {
      return
    }
    const progressionId = getters.userLessonProgression?.[state.currentLessonSlug].id
    dispatch('updateUserLessonProgression', {
      slug: state.currentLessonSlug,
      videoProgress: state.videoProgress,
      id: progressionId,
    })
  },
  async updateUserLessonProgression ({ commit }, { slug, videoProgress, id }: UpdateUserLessonProgression) {
    const userLessonProgression = await this.$api.course.updateUserLessonProgression({ slug, videoProgress, id })
    commit('UPDATE_COMPANY_LESSON_PROGRESS', userLessonProgression)
  },
  async getUserLessonProgression ({ commit }) {
    const allUserLessonProgression = await this.$api.course.getUserLessonProgression()
    commit('SET_COMPANY_LESSON_PROGRESS', allUserLessonProgression)
  },
  closeEvaluateLessonModal ({ commit }) {
    commit('CLOSE_MODAL', 'evaluateLesson')
  },
  async updateCourseReviewsPagination ({ commit, dispatch }, pagination) {
    commit('SET_COURSE_REVIEWS_PAGINATION', pagination)
    await dispatch('debounceGetCourseReviewsPaginated')
  },
  debounceGetCourseReviewsPaginated: debounce(async ({ dispatch }, _) => {
    await dispatch('getCourseReviewsPaginated')
  }, 300),
  async getUserAcademyReviews ({ commit }) {
    const allUserAcademyReviews = await this.$api.course.getAllUserAcademyReview({})
    commit('SET_COMPANY_ACADEMY_REVIEWS', allUserAcademyReviews)
  },
  async getCourseReviewsPaginated ({ commit, state }) {
    const reviews = await this.$api.course.getUserAcademyReviewsPaginated({
      ...state.courseReviewsPagination,
      type: AcademyType.COURSE,
    })
    commit('SET_COURSE_REVIEWS', reviews)
  },
  async sendAcademyReview ({ commit, getters, dispatch }, payload: CreateUpdateUserAcademyReview) {
    const review = await this.$api.course.createOrUpdateUserAcademyReview({
      ...payload,
      postedAtDuration: getters.videoProgress?.duration,
    })

    if (payload.type === AcademyType.COURSE) {
      await dispatch('getCourseReviewsPaginated')
    }

    commit('UPDATE_COMPANY_ACADEMY_REVIEWS', review)
  },
  openCourseShowcase ({ commit }, course: ICourse) {
    commit('SET_COURSE', course)
    commit('OPEN_MODAL', 'courseShowcase')
  },
}
