import { CategoryType } from './CategoryType';

export interface ICategory {
  id: number,
  color: string,
  icon: string,
  slug: string,
  name: string,
  type: CategoryType,
  isHidden: boolean,
  isPersonal: boolean,
  displayOrder?: number,
}

export interface ICategoryConst {
  [key: string]: ICategory
}

export const Category: ICategoryConst = {
  PROFESSIONAL_FAVORITES: { // recettes
    id: -1,
    color: '#dd7c00 ',
    icon: 'star',
    slug: 'professional_favorites',
    name: 'Favoris',
    type: CategoryType.BOTH,
    isHidden: false,
    isPersonal: false,
    displayOrder: 1,
  },
  PERSONAL_FAVORITES: { // recettes
    id: 0,
    color: '#dd7c00 ',
    icon: 'star',
    slug: 'personal_favorites',
    name: 'Favoris',
    type: CategoryType.BOTH,
    isHidden: false,
    isPersonal: true,
    displayOrder: 1,
  },
  INCOMES: { // recettes
    id: 1,
    color: '#1dd05d',
    icon: 'savings',
    slug: 'incomes',
    name: 'Revenus',
    type: CategoryType.INCOMES,
    isHidden: false,
    isPersonal: false,
    displayOrder: 1,
  },
  HUMAN_RESOURCES: { // ressources humaines
    id: 2,
    color: '#06a7e7',
    icon: 'face',
    slug: 'human_resources',
    name: 'Ressources Humaines',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: false,
    displayOrder: 2,
  },
  OPERATION: { // fonctionnement
    id: 3,
    color: '#f7a00d',
    icon: 'swap_horiz',
    slug: 'operation',
    name: 'Fonctionnement',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: false,
    displayOrder: 3,
  },
  TRAVEL: { // déplacements
    id: 4,
    color: '#07d1a2',
    icon: 'flight_takeoff',
    slug: 'travel',
    name: 'Déplacements',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: false,
    displayOrder: 4,
  },
  FIXED_COSTS: { // frais fixes
    id: 5,
    color: '#0d6aa8',
    icon: 'credit_score',
    slug: 'fixed_costs',
    name: 'Frais fixes',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: false,
    displayOrder: 5,
  },
  CONTRIBUTIONS_AND_TAXES: { // Cotisations et taxes
    id: 6,
    color: '#a73a50',
    icon: 'account_balance',
    slug: 'contributions_and_taxes',
    name: 'Cotisations et taxes',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: false,
    displayOrder: 6,
  },
  ACCOUNTING: { // catégorie comptable (souvent hidden : exemple banque)
    id: 7,
    color: '#3865fd',
    icon: 'point_of_sale',
    slug: 'accounting',
    name: 'Catégorie comptable',
    type: CategoryType.BOTH,
    isHidden: true,
    isPersonal: false,
  },
  TO_CATEGORIZE: { // Tout ce qui n'a pas été annoté
    id: 8,
    color: '#5AACFF',
    icon: 'dashboard_customize',
    slug: 'to_categorize',
    name: 'À catégoriser',
    type: CategoryType.BOTH,
    isHidden: true,
    isPersonal: false,
  },

  /// Catégorie personnelle
  SUBSCRIPTION: { // les abonnements
    id: 9,
    color: '#fc275c',
    icon: 'loyalty',
    slug: 'subscription',
    name: 'Abonnements',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 2,
  },
  BUYING_AND_SHOPPING: { // achats et shopping
    id: 10,
    color: '#f09532',
    icon: 'store',
    slug: 'buying_and_shopping',
    name: 'Achats et shopping',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 3,
  },
  FOOD_AND_RESTAURANT: { // alimentation et restaurant
    id: 11,
    color: '#11bfb5',
    icon: 'restaurant',
    slug: 'food_and_restaurant',
    name: 'Alimentation et restaurant',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 4,
  },
  CAR_AND_TRANSPORT: { // auto et transports
    id: 12,
    color: '#2196f3',
    icon: 'two_wheeler',
    slug: 'car_and_transport',
    name: 'Auto et transports',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 5,
  },
  BANK: { // banque
    id: 13,
    color: '#116cde',
    icon: 'account_balance_wallet',
    slug: 'bank',
    name: 'Banques',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 6,
  },
  VARIOUS: { // Divers
    id: 14,
    color: '#7e94e3',
    icon: 'category',
    slug: 'various',
    name: 'Divers',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 7,
  },
  AESTHETICS_AND_CARE: { // Esthétique et soins
    id: 15,
    color: '#de5fa8',
    icon: 'clean_hands',
    slug: 'aesthetics_and_care',
    name: 'Esthétiques et soins',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 8,
  },
  TAXES_AND_DUTIES: { // taxes et impôts
    id: 16,
    color: '#e58825',
    icon: 'payment',
    slug: 'taxes_and_duties',
    name: 'Taxes et impôts',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 9,
  },
  HOUSING: { // logement
    id: 17,
    color: '#5fb7fd',
    icon: 'home',
    slug: 'housing',
    name: 'Logement',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 10,
  },
  LEISURE_AND_OUTINGS: { // loisirs et sorties
    id: 18,
    color: '#b248e5',
    icon: 'movie',
    slug: 'leisure_and_outings',
    name: 'Loisirs & sorties',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 11,
  },
  CHEQUE_AND_BANK_TRANSFER_WITHDRAWALS: { // retraits chèque et virement
    id: 19,
    color: '#fdb422',
    icon: 'payments',
    slug: 'cheque_and_bank_transfer_withdrawals',
    name: 'Retrait chèque & virement',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 12,
  },
  HEALTH: { // santé
    id: 20,
    color: '#e22954',
    icon: 'local_hospital',
    slug: 'health',
    name: 'Santé',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 13,
  },
  SCHOOLING_AND_CHILDREN: { // scolarité et enfants
    id: 21,
    color: '#2bd48f',
    icon: 'school',
    slug: 'schooling_and_children',
    name: 'Scolarité et enfants',
    type: CategoryType.EXPENSES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 14,
  },
  PERSONAL_INCOMES: { // revenus personnels
    id: 22,
    color: '#1bde73',
    icon: 'savings',
    slug: 'personal_incomes',
    name: 'Revenus',
    type: CategoryType.INCOMES,
    isHidden: false,
    isPersonal: true,
    displayOrder: 1,
  },
};
