import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import type { BasePaginate, ITransaction, IAccount } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export type PersonalType = 1 | 2 | undefined

export type NegativeType = 1 | 2 | undefined

export const Negative: { [key: string]: NegativeType } = {
  TRUE: 1,
  FALSE: 2,
  NONE: undefined,
}

export const Personal: { [key: string]: PersonalType } = {
  TRUE: 1,
  FALSE: 2,
  NONE: undefined,
}

export interface AccountingPageQuery {
  categoryId?: number,
  keywords?: string,
  toAnnotate?: boolean,
  startDate?: string,
  endDate?: string,
  negative?: boolean,
  personal?: boolean,
  min?: number,
  max?: number,
  sortBy?: string[],
  sortDesc?: boolean[],
}

export interface AccountingPagePaginationQuery { page: number, limit: number }

export interface AccountingPageState {
  selectedAccount: IAccount | null;
  pagination: BasePaginate<ITransaction> | null;
  initialLoading: boolean;
  transactionsLoading: boolean;
  query: AccountingPageQuery;
  queryLoading: boolean;
  paginationQuery: AccountingPagePaginationQuery
}

export const state = (): AccountingPageState => ({
  selectedAccount: null,
  pagination: null,
  initialLoading: true,
  transactionsLoading: true,
  paginationQuery: {
    page: 1,
    limit: 25,
  },
  query: {
    categoryId: undefined,
    keywords: undefined,
    toAnnotate: undefined,
    startDate: undefined,
    endDate: undefined,
    negative: undefined,
    personal: undefined,
  },
  queryLoading: true,
})

export const getters: GetterTree<AccountingPageState, RootState> = {
  selectedAccount (state: AccountingPageState) {
    return state.selectedAccount
  },
  pagination (state: AccountingPageState) {
    return state.pagination
  },
  query (state: AccountingPageState) {
    return state.query
  },
  paginationQuery (state: AccountingPageState) {
    return state.paginationQuery
  },
  transactions (state: AccountingPageState) {
    return state.pagination?.data || []
  },
  transactionsGroupedByMonth (_, getters): Array<{ period: string, transactions: ITransaction[] }> {
    return getters.transactions.reduce((acc: any, transaction: any) => {
      const date = new Date(transaction.valueDate)
      const _period = `${date.getFullYear()}-${date.getMonth() + 1}-01`
      const groupIndex = acc.findIndex(({ period }: any) => period === _period)
      if (groupIndex === -1) {
        return [
          ...acc,
          {
            period: _period,
            transactions: [transaction],
          },
        ]
      }
      acc[groupIndex].transactions.push(transaction)
      return acc
    }, [])
  },
  transactionsGroupedByDay (_, getters): Array<{ period: string, transactions: ITransaction[] }> {
    return getters.transactions.reduce((acc: any, transaction: any) => {
      const date = new Date(transaction.valueDate)
      const _period = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      const groupIndex = acc.findIndex(({ period }: any) => period === _period)
      if (groupIndex === -1) {
        return [
          ...acc,
          {
            period: _period,
            transactions: [transaction],
          },
        ]
      }
      acc[groupIndex].transactions.push(transaction)
      return acc
    }, [])
  },
  initialLoading (state: AccountingPageState) {
    return state.initialLoading
  },
  transactionsLoading (state: AccountingPageState) {
    return state.transactionsLoading
  },
  queryLoading (state: AccountingPageState) {
    return state.queryLoading
  },
}

export const mutations = {
  SET_SELECTED_ACCOUNT (state: AccountingPageState, account: IAccount): void {
    state.selectedAccount = account
  },
  UPDATE_SELECTED_ACCOUNT (state: AccountingPageState, account: Partial<IAccount>): void {
    state.selectedAccount = {
      ...state.selectedAccount as IAccount,
      ...account,
    }
  },
  SET_PAGINATION_QUERY (state: AccountingPageState, paginationQuery: AccountingPagePaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: AccountingPageState, pagination: BasePaginate<ITransaction> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: AccountingPageState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: AccountingPageState, value: boolean): void {
    state.queryLoading = value
  },
  SET_TRANSACTIONS_LOADING (state: AccountingPageState, value: boolean): void {
    state.transactionsLoading = value
  },
  UPDATE_TRANSACTIONS (state: AccountingPageState, transactions: Array<Partial<ITransaction>>): void {
    if (!transactions.length) { return }
    transactions.forEach((transaction) => {
      const index = state.pagination?.data.findIndex(t => t.id === transaction.id)
      delete transaction.id
      if (index === -1 || index === undefined) { return }
      state.pagination!.data.splice(index, 1, {
        ...state.pagination!.data[index],
        ...transaction,
      })
    })
  },
  REMOVE_TRANSACTION (state: AccountingPageState, id: string) {
    if (!state.pagination?.data) { return }
    const index = state.pagination?.data.findIndex(t => t.id === id)
    if (index === -1) { return }
    state.pagination?.data.splice(index, 1)
  },
  SET_QUERY (_state: AccountingPageState, query: Partial<AccountingPageQuery>): void {
    _state.query = {
      ...query,
    }
    const newPaginationQuery = state().paginationQuery
    _state.paginationQuery = Object.assign(_state.paginationQuery, newPaginationQuery)
  },
  RESET (_currentState: AccountingPageState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  RESET_QUERY (_currentState: AccountingPageState) {
    const newQuery = state().query
    const newPaginationQuery = state().paginationQuery
    _currentState.query = Object.assign(_currentState.query, newQuery)
    _currentState.paginationQuery = Object.assign(_currentState.paginationQuery, newPaginationQuery)
  },
}

export const actions: ActionTree<any, any> = {
  async setSelectedAccountAndFetchTransactions ({ dispatch, commit }, account: IAccount, query?: any) {
    commit('RESET', state())
    commit('SET_SELECTED_ACCOUNT', account)
    commit('SET_PAGINATION', null)
    await dispatch('fetchTransactions', query)
  },
  async fetchTransactions ({ commit, getters }) {
    commit('SET_TRANSACTIONS_LOADING', true)
    commit('SET_INITIAL_LOADING', true)
    try {
      const pagination = await this.$api.transaction.paginate({
        accountId: getters.selectedAccount?.id || undefined,
        ...getters.paginationQuery as AccountingPagePaginationQuery,
        ...getters.query,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_TRANSACTIONS_LOADING', false)
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  async loadMoreTransactions ({ commit, getters, state }) {
    commit('SET_TRANSACTIONS_LOADING', true)
    try {
      const pagination = await this.$api.transaction.paginate({
        accountId: getters.selectedAccount?.id || undefined,
        ...getters.paginationQuery as AccountingPagePaginationQuery,
        ...getters.query,
      })
      const _data = state.pagination?.data || []
      const newPagination = {
        ...pagination,
        data: [..._data, ...pagination.data],
      }
      commit('SET_PAGINATION', newPagination)
    } finally {
      commit('SET_TRANSACTIONS_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  async deleteTransaction ({ commit }, value: string) {
    await this.$api.transaction.delete(value)
    commit('REMOVE_TRANSACTION', value)
  },
  debounceFetchTransactions: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchTransactions')
  }, 300, { leading: true }),
  updateTransaction ({ commit }, value: Partial<ITransaction>) {
    commit('UPDATE_TRANSACTIONS', [value])
  },
  updateQuery ({ commit, dispatch }, query: Partial<AccountingPageQuery>) {
    commit('SET_QUERY_LOADING', true)
    commit('SET_QUERY', query)
    dispatch('debounceFetchTransactions')
  },
}
