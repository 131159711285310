import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export const useMarkAsNotSigned = () => {
  const busManager = useBusManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    await billingRepository.markAsNotSigned(billing)
    billing.signedAt = undefined
    busManager.emit('billingUpdated', billing)
  }
}
