import { AxiosInstance } from 'axios'

type Axios = AxiosInstance & {
  $get: AxiosInstance['get'],
  $put: AxiosInstance['put'],
  $patch: AxiosInstance['patch'],
  $post: AxiosInstance['post'],
  $delete: AxiosInstance['delete']
};

export class Resource {
  protected axios: Axios;
  constructor (axios: Axios) {
    this.axios = axios
  }
}
