import type {
  QueryStatistics,
  ReadStatisticsByCategory,
  ReadExpensesIncomesByMonth,
  ReadNumberOfUsersPerBrand,
  QueryLimitDashboard,
  ReadLimitDashboard,
  QueryCustomersStatistics,
  ReadCustomersStatistics,
  ReadIncomesOutcomesResults, QueryIncomesOutcomesResults, ReadProductsStatistics, QueryProductsStatistics,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Statistics extends Resource {
  getExpensesByCategory (query?: QueryStatistics): Promise<Array<ReadStatisticsByCategory>> {
    return this.axios.$get('/statistics/expenses', { params: query })
  }

  getIncomesByCategory (query?: QueryStatistics): Promise<Array<ReadStatisticsByCategory>> {
    return this.axios.$get('/statistics/incomes', { params: query })
  }

  getExpensesAndIncomes (query?: QueryStatistics): Promise<ReadExpensesIncomesByMonth> {
    return this.axios.$get('/statistics/incomesExpenses', { params: query })
  }

  getNumberOfUsersPerBrand (): Promise<Array<ReadNumberOfUsersPerBrand>> {
    return this.axios.$get('/statistics/brand/users')
  }

  getEvolutionOfCreationState (): Promise<any> {
    return this.axios.$get('/statistics/evolutionOfCreationState')
  }

  getLimit (query: QueryLimitDashboard): Promise<ReadLimitDashboard[]> {
    return this.axios.$get('/v2/statistics/limit', { params: query })
  }

  getCustomersStatistics (query: QueryCustomersStatistics): Promise<ReadCustomersStatistics> {
    return this.axios.$get('/v2/statistics/customers', { params: query })
  }

  getIncomesOutcomesResultStatistics (query: QueryIncomesOutcomesResults): Promise<ReadIncomesOutcomesResults> {
    return this.axios.$get('/v2/statistics/incomesOutcomesResults', { params: query })
  }

  getProductsStatistics (query: QueryProductsStatistics): Promise<ReadProductsStatistics> {
    return this.axios.$get('/v2/statistics/products', { params: query })
  }
}
