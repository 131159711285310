
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({})
export default class ASActionButtonGroup extends Vue {
  @Prop()
  config!: { label: string, icon: string, actionNumber: string, disabled?: boolean, disabledTooltip?: string, onClick: () => void }[]

  get action1 () {
    return this.config.find(c => c.actionNumber === '1')
  }

  get action2 () {
    return this.config.find(c => c.actionNumber === '2')
  }

  get action3 () {
    return this.config.find(c => c.actionNumber === '3')
  }
}
