import { OrganizationItem } from '~/logic/contexts/client/domain/entity/organizationItem.entity'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useUnarchiveOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()

  return async (organization: OrganizationItem) => {
    await organizationRepository.unarchiveOrganization(organization.id)
    alertManager.success('L\'organisation a été désarchivée avec succès.')
  }
}
