var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AbbyMenu,{attrs:{"model-value":_setup.value,"bottom":"","origin":"bottom left","absolute":"","position-x":_setup.props.position.x,"position-y":_setup.props.position.y,"min-width":"250"},on:{"update:model-value":function($event){_setup.value = $event}}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-1 tw-mt-1"},_vm._l((_setup.displayedActionGroup),function(group,i){return _c('div',{key:group.title,staticClass:"tw-as-caption tw-pt-1 tw-px-2 tw-pb-2.5 tw-text-grey-base tw-border-background-darken1",class:{
        'tw-border-b': i !== _setup.displayedActionGroup.length - 1,
      }},[_c('div',{staticClass:"tw-px-1.5 tw-mb-2 tw-flex tw-gap-2 tw-items-center"},[_vm._v("\n        "+_vm._s(group.title)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-1"},_vm._l((group.actions),function(action){return _c(_setup.AbbyTooltip,{key:action.action,attrs:{"disabled":!action.tooltip,"top":"","max-width":"350px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, bind }){return [_c('div',_vm._g(_vm._b({staticClass:"tw-p-2 tw-py-1.5 tw-rounded tw-font-medium tw-transition-colors tw-text-black-base tw-select-none tw-flex tw-items-start tw-cursor-pointer",class:{
                'hover:tw-bg-background-base hover:tw-text-primary-base': !action.restricted && !action.disabled,
                'hover:tw-bg-yellow-lighten5': action.warning,
                'tw-cursor-not-allowed': action.disabled,
              },on:{"click":function($event){return _setup.handleAction(action)}}},'div',bind,false),on),[_c('div',_vm._g(_vm._b({staticClass:"tw-as-body-3 tw-flex tw-items-center tw-gap-2",class:{
                  'tw-text-grey-lighten1': action.disabled,
                },staticStyle:{"font-size":"15px"}},'div',bind,false),on),[_vm._v("\n                "+_vm._s(action.label)+"\n              ")])])]}}],null,true)},[_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(action.tooltip)}})])}),1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }