
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { BillingState, BillingType, Currency, getCompleteTotalAmount, getDueDate, IBilling, ICompany } from '@abby/core-legacy'

@Component({ components: { } })
export default class BillingInformation extends Vue {
  @Prop()
  billing!: IBilling

  @Getter('company/company')
  company!: ICompany

  @Prop({ default: true })
  amount!: boolean

  @Prop({ default: 'label' })
  type!: 'label' | 'plain'

  get BillingType () {
    return BillingType
  }

  get BillingState () {
    return BillingState
  }

  get dueDate () {
    if (!this.billing.paymentDelay || !this.billing.date) { return null } else if (this.billing.dueDate) { return this.$dayjs(this.billing.dueDate).format('YYYY-MM-DD') }
    return getDueDate(this.billing.paymentDelay, this.billing.date)
  }

  get validityDate () {
    if (!this.billing.validityDate) { return null }
    return this.billing.validityDate
  }

  get currency () {
    return this.billing.currency || Currency.EUR
  }

  get priceWithoutTax (): string {
    const price = getCompleteTotalAmount(this.billing, this.billing.createdFromEstimate || this.billing.createdFromPurchaseOrder)
    return this.$amount.formatCents(typeof price.remainingPriceToPayWithoutTax === 'number' ? price.remainingPriceToPayWithoutTax : price.priceWithoutTax, { currency: this.currency }).text
  }

  get priceTotalTax (): string {
    const price = getCompleteTotalAmount(this.billing, this.billing.createdFromEstimate || this.billing.createdFromPurchaseOrder)
    return this.$amount.formatCents(typeof price.remainingPriceToPayTotalTax === 'number' ? price.remainingPriceToPayTotalTax : price.priceTotalTax, { currency: this.currency }).text
  }

  get isDefaultCurrency () {
    return this.currency === Currency.EUR
  }

  get currencyRate () {
    return this.billing.currencyRate || 1
  }

  get originalPriceWithoutTax (): string {
    const price = getCompleteTotalAmount(this.billing, this.billing.createdFromEstimate || this.billing.createdFromPurchaseOrder, 1)
    return this.$amount.formatCents(price.priceWithoutTax).text
  }

  get originalPriceTotalTax (): string {
    const price = getCompleteTotalAmount(this.billing, this.billing.createdFromEstimate || this.billing.createdFromPurchaseOrder, 1)
    return this.$amount.formatCents(price.priceTotalTax).text
  }

  get paidAt () {
    if (!this.billing.paidAt) { return null }
    return this.$date.formatDDMMMYYYY(this.billing.paidAt)
  }

  get signedAt () {
    if (!this.billing.signedAt) { return null }
    return this.$date.formatDDMMMYYYY(this.billing.signedAt)
  }

  get refusedAt () {
    if (!this.billing.refusedAt) { return null }
    return this.$date.formatDDMMMYYYY(this.billing.refusedAt)
  }

  get dueDateOrValidityColor () {
    if (this.dueDate && [BillingType.ADVANCE, BillingType.ASSET, BillingType.INVOICE].includes(this.billing.billingType)) {
      const diff = this.$dayjs(this.dueDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).diff(this.$dayjs(), 'day')
      return diff < 7 ? diff < 0 ? 'error--text' : 'warning--text' : ''
    } else if (this.validityDate && [BillingType.PURCHASE_ORDER, BillingType.ESTIMATE].includes(this.billing.billingType)) {
      const diff = this.$dayjs(this.validityDate, ['DD/MM/YYYY', 'YYYY-MM-DD']).diff(this.$dayjs(), 'day')
      return diff < 7 ? diff < 0 ? 'error--text' : 'warning--text' : ''
    }
    return ''
  }
}
