import { Resource } from '../Resource';
import { WebinarType } from '../../enums';
import { ReadWebinarEvent } from '../../dto';

export class Webinar extends Resource {
  retrieve(type: WebinarType): Promise<ReadWebinarEvent> {
    return this.http.$get('/webinar', {
      params: { type },
    });
  }
}
