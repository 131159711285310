import {
  advancePrefixs,
  assetPrefixs, BillingColumnName,
  BillingType,
  counters,
  dates,
  estimatePrefixs,
  IBillingConfiguration,
  invoicePrefixs,
  NumberFormat,
  purchaseOrderPrefixs,
  separators,
} from '../../types';

const dayjs = require('dayjs');

export function getNumberFormatWithIds(ids: number[]): Array<NumberFormat> {
  const items = [
    ...invoicePrefixs,
    ...estimatePrefixs,
    ...assetPrefixs,
    ...advancePrefixs,
    ...purchaseOrderPrefixs,
    ...dates,
    ...counters,
    ...separators,
  ];
  const defaultNumberFormatItems: Array<NumberFormat> = [];
  ids.forEach((id) => {
    const indexOfItem = items.findIndex((i) => i.id === id);
    if (indexOfItem === -1) { return; }
    defaultNumberFormatItems.push(items[indexOfItem]);
  });
  return defaultNumberFormatItems;
}

export function defaultNumberFormat(type: BillingType) {
  const defaultItemIds = [12, 6, 12, 10];
  // Injecter les préfixes par défaut selon le type de document
  switch (type) {
    case BillingType.INVOICE: { defaultItemIds.unshift(1); break; }
    case BillingType.ESTIMATE: { defaultItemIds.unshift(17); break; }
    case BillingType.ASSET: { defaultItemIds.unshift(20); break; }
    case BillingType.ADVANCE: { defaultItemIds.unshift(23); break; }
    case BillingType.PURCHASE_ORDER: { defaultItemIds.unshift(25); break; }
    default: break;
  }

  return getNumberFormatWithIds(defaultItemIds);
}

/**
 * @remarks Counter doit être à true si c'est utilisé pour manipuler le compteur de la facturation
 */
export function getTypeBillingSlug({ billingType, counter = true }:{ billingType: BillingType, counter: boolean }) {
  if (billingType === BillingType.INVOICE) return 'invoice';
  if (billingType === BillingType.ESTIMATE) return 'estimate';
  if (billingType === BillingType.ASSET) return 'asset';
  // si cette fonction est utilisé dans le cadre du compteur qui est commun entre les factures et les acomptes on retourne le type facture
  if (billingType === BillingType.ADVANCE) return counter ? 'invoice' : 'advance';
  return 'purchaseOrder';
}

export function documentTextNumber(
  billingConfiguration: Partial<IBillingConfiguration> | null,
  billingType: BillingType,
  date: Date,
  _numberFormat?: Array<NumberFormat>,
) {
  let numberFormatPreview = '';

  // @ts-ignore
  const counter = billingConfiguration?.initialNumber?.[getTypeBillingSlug({ billingType, counter: true })] || 1;
  const numberFormat = _numberFormat || billingConfiguration?.numberFormat?.[getTypeBillingSlug({ billingType, counter: false })] || [];

  numberFormat.forEach((n: any) => {
    if (n.balise?.date) {
      numberFormatPreview += dayjs(date || undefined).format(n.balise.format);
    } if (n.balise?.text) {
      numberFormatPreview += n.balise.value;
    } if (n.balise?.counter) {
      numberFormatPreview += counter.toString().padStart(n.balise.size, '0');
    }
  });
  return numberFormatPreview;
}

export function paymentMethodText() {
  // TODO: avec i18n
}

export function defaultColumnsConfig(params: { hasVat: boolean }) {
  return [{
    name: BillingColumnName.NUMBER,
    order: 1,
    active: true,
    mandatory: false,
  }, {
    name: BillingColumnName.DESCRIPTION,
    order: 2,
    active: true,
    mandatory: true,
  }, {
    name: BillingColumnName.UNITY,
    order: 3,
    active: true,
    mandatory: false,
  }, {
    name: BillingColumnName.QUANTITY,
    order: 4,
    active: true,
    mandatory: false,
  }, {
    name: BillingColumnName.UNIT_PRICE_WITHOUT_TAX,
    order: 5,
    active: true,
    mandatory: true,
  }, {
    name: BillingColumnName.TVA,
    order: 6,
    active: params.hasVat,
    mandatory: true,
  }, {
    name: BillingColumnName.TOTAL_WITHOUT_TAX,
    order: 7,
    active: true,
    mandatory: false,
  }, {
    name: BillingColumnName.TOTAL_WITH_TAX,
    order: 8,
    active: params.hasVat,
    mandatory: false,
  },
  ];
}
