import { OrganizationContactForm } from '~/logic/contexts/client/domain/form/organizationContact.form'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export const useUpdateOrganizationContact = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organizationContact: OrganizationContactForm) => {
    const editedOrganizationContact = await organizationRepository.updateContact(organizationContact)
    alertManager.success('Le contact a bien été mis à jour')
    busManager.emit('organizationContactUpdated', editedOrganizationContact)
    return editedOrganizationContact
  }
}
