import { Resource } from '../Resource';
import { ReadCustomer, ReadCustomerCollection } from '../../dto';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

type CustomerFilterQuery = {
  search?: string,
  organizationId?: string,
  excludeContactOrganization?: boolean,
};

export class Customer extends Resource {
  async paginate(query: QueryParams<{
    page: number,
    limit: number
  } & CustomerFilterQuery>): Promise<ReadCustomerCollection> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/v2/billing/customers', { params, cancelToken });
  }

  async retrieveByLegacyId(legacyId: string): Promise<ReadCustomer> {
    return this.http.$get(`/v2/customer/legacy/${legacyId}`);
  }

  async retrieveByContactId(contactId: string): Promise<ReadCustomer> {
    return this.http.$get(`/v2/customer/contact/${contactId}`);
  }

  async retrieveByOrganizationId(contactId: string): Promise<ReadCustomer> {
    return this.http.$get(`/v2/customer/organization/${contactId}`);
  }
}
