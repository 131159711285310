import { Context, Plugin } from '@nuxt/types'
import { OpportunityType } from '@abby/core-legacy'
import type { IActivity, ICompany } from '@abby/core-legacy'

export interface ExperienceManagerInstance {
  loadExperience: (activityId: number | null) => Promise<void>
  isActivityRegulated: (activityId: number) => boolean
  opportunityWordingFor: (key: string) => string
  opportunityTypeIsSet: () => boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExperienceManagerPlugin: Plugin = ({ store, app, i18n }: Context, inject) => {
  const company = (): ICompany => store.getters['company/company']
  inject('xpm', {
    async loadExperience (activityId: number | null): Promise<void> {
      await store.dispatch('experience/fetchUserExperience', activityId)
    },
    isActivityRegulated (activityId: number): boolean {
      const activities = store.getters['activities/activities']
      const activity = activities.find((a: IActivity) => a.id === activityId)
      return activity?.isRegulated || false
    },
    opportunityWordingFor (key: string) {
      return i18n.t(`opportunity.${company()?.opportunityType || OpportunityType.PROJECTS}.${key}`)
    },
    opportunityTypeIsSet () {
      return !!company()?.opportunityType
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $xpm: ExperienceManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $xpm: ExperienceManagerInstance
  }
  interface Context {
    $xpm: ExperienceManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $xpm: ExperienceManagerInstance
  }
}

export default ExperienceManagerPlugin
