import { Middleware } from '@nuxt/types'
import { retrieveToken } from '~/middleware/auth'

const register: Middleware = async ({ redirect, route, store, $updateManager }) => {
  await $updateManager.checkForUpdate()
  const isAuthenticationInitialized = store.getters['auth/expirationTime'] !== null
  const { token } = retrieveToken()
  const isConnected = !!token

  const isPathOnboarding = route.path.includes('/start/onboarding') ||
      route.path.includes('/start/gestion/custom_experience') ||
      route.path.includes('/start/gestion/company') ||
      route.path.includes('/start/onboarding') ||
      route.path.includes('/start_2/gestion') ||
      route.path.includes('/start_2/gestion/activity') ||
      route.path.includes('/start_3/gestion') ||
      route.path.includes('/start_3/gestion/customize')

  const isPathCreation = route.path.includes('/start/creation') ||
    route.path.includes('/start/creation/activity') ||
    route.path.includes('/start/creation/declaration') ||
    route.path.includes('/start/creation/informations') ||
    route.path.includes('/start/creation/personal') ||
    route.path.includes('/start/creation/signup')

  if (isConnected && isPathCreation && !store.getters['auth/user']) {
    await store.dispatch('initializeUserRessources')
    return
  }
  if (isPathCreation) {
    return
  }

  if (isConnected && !isAuthenticationInitialized && !isPathOnboarding) {
    redirect((route.query?.redirect_to as string) || '/')
  }

  if (isConnected && isPathOnboarding && !store.getters['auth/user']) {
    await store.dispatch('initializeUserRessources')
  }
}

export default register
