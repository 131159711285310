export interface IGroupAccountingAccount {
  id: number,
  name: string,
  color: string,
  icon?: string,
  isPersonal?: boolean
}

export const groupAccountingAccount: IGroupAccountingAccount[] = [{
  id: 1,
  name: 'Capitaux propres',
  color: '#116cde',
}, {
  id: 2,
  name: 'Immobilisations',
  color: '#1bde73',
}, {
  id: 3,
  name: 'Stocks et en cours',
  color: '#e22954',
}, {
  id: 4,
  name: 'Comptes de tiers',
  color: '#fdb422',
}, {
  id: 5,
  name: 'Comptes financiers',
  color: '#b248e5',
}, {
  id: 6,
  name: 'Comptes de charges',
  color: '#11bfb5',
}, {
  id: 7,
  name: 'Comptes de produits',
  color: '#2196f3',
}, {
  id: 8,
  name: 'Comptes spéciaux',
  color: '#7e94e3',
}, {
  id: 9,
  isPersonal: true,
  icon: 'face',
  name: 'Comptes personnels',
  color: '#de5fa8',
}];
