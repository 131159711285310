import { ActionTree, GetterTree } from 'vuex'
import type { ReadAccount } from '@abby/core-legacy'
import { ReadBankAccount, Time } from '@abby/shared'
import { RootState } from '~/store/index'

export interface AccountsState {
  accounts: ReadAccount[];
}

export const state = (): AccountsState => ({
  accounts: [],
})

export const getters: GetterTree<AccountsState, RootState> = {
  hasAccountSynchronized: (state: AccountsState) => state.accounts?.some(b => b.isSynchronized),
  synchronizedAccounts (state) {
    if (!state.accounts || !Array.isArray(state.accounts)) {
      return []
    }
    return state.accounts.filter((account: ReadAccount) => account.isSynchronized)
  },
}

export const mutations = {
  SET_ACCOUNTS (state: AccountsState, accounts: ReadAccount[]): void {
    state.accounts = accounts
  },
  RESET (_currentState: AccountsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

const convertToReadAccount = (account: ReadBankAccount): ReadAccount => {
  return {
    id: account.id,
    accountingAccountId: '',
    name: account.name,
    type: '',
    currencyCode: account.currencyCode,
    bridgeAccountId: account.externalId,
    bridgeItemId: account.bankItem!.externalId,
    bankinItemId: account.bankItem!.id,
    bankListId: account?.bankItem?.bank?.id || 0,
    isSynchronized: account.isSynchronized,
    synchronizedAt: account.updatedAt ? Time.toDate(account.updatedAt) : null,
    desynchronizedAt: null,
    startSynchronizedAt: null,
    firstSynchronizedAt: null,
    bridgeUpdatedAt: Time.toDate(account.updatedAt),
    lastSyncAt: account.externalUpdatedAt || null,
    // startSynchronizedAt: account.startSynchronizedAt,
    // firstSynchronizedAt: account.firstSynchronizedAt,
    // lastSyncAt: account.lastSyncAt,
    companyId: account.companyId,
    // bridgeUpdatedAt: account.bridgeUpdatedAt,
    // numberOfTransactionsToAnnotate: account.numberOfTransactionsToAnnotate,
  }
}

export const actions: ActionTree<any, any> = {
  async fetchBankAccounts ({ commit, getters, rootGetters }) {
    const accounts = await this.$backend.bank.getAccounts()
    const converted = accounts.map((account: ReadBankAccount) => convertToReadAccount(account))
    commit('SET_ACCOUNTS', converted)

    if (!rootGetters['accountingPage/selectedAccount'] && getters.synchronizedAccounts?.length > 0) {
      commit('accountingPage/SET_SELECTED_ACCOUNT', getters.synchronizedAccounts[0], { root: true })
    }
  },
  async connectBank () {
    try {
      const result = await this.$api.bridge.connectV2()
      setTimeout(() => {
        window.location = result.redirectUrl as any
      }, 1500)
    } catch (e) {
      if (e.response?.data?.message === 'bank.email_already_in_use_delete_old_sync') {
        this.$alertsManager.error('Nous avons détecté que vous avez déjà utilisé cette adresse email pour connecter un compte bancaire. Veuillez contacter le support pour que nous vous aidions à résoudre ce problème.')
      } else {
        this.$alertsManager.autoError(e)
      }
    }
  },
}
