import { ReadOrganization, Time } from '@abby/shared'
import { Organization } from '~/logic/contexts/client/domain/entity/organization.entity'

export class OrganizationHttpMapper {
  static toDomain (organization: ReadOrganization) {
    return Organization.create({
      id: organization.id,
      name: organization.name,
      commercialName: organization.commercialName,
      siret: organization.siret,
      vatNumber: organization.vatNumber,
      countContacts: organization.countContacts,
      billingAddress: organization.billingAddress,
      deliveryAddress: organization.deliveryAddress,
      notes: organization.notes,
      language: organization.language,
      currency: organization.currency,
      paymentMethods: organization.paymentMethods,
      createdAt: Time.toDate(organization.createdAt),
      archivedAt: organization.archivedAt ? Time.toDate(organization.archivedAt) : undefined,
      bankInformations: organization.bankInformations,
      hasBillings: organization.hasBillings,
      test: organization.test,
    })
  }
}
