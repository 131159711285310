import { PersonalServiceActivity } from '../enums/personalServiceActivityType.enum';
import { UrssafProductTypeNatureCode } from '../enums/urssafProductTypeNatureCode.enum';

export const mapCodeNatureToActivity = {
  [UrssafProductTypeNatureCode.GARDE_ENFANT_HANDICAPE]: PersonalServiceActivity.GARDE_ENFANT_HANDICAPE,
  [UrssafProductTypeNatureCode.ACCOMPAGNEMENT_ENFANT_HANDICAPE]: PersonalServiceActivity.ACCOMPAGNEMENT_ENFANT_HANDICAPE,
  [UrssafProductTypeNatureCode.AIDE_HUMAINE]: PersonalServiceActivity.AIDE_HUMAINE,
  [UrssafProductTypeNatureCode.CONSUITE_VEHICULE_PERSONEL]: PersonalServiceActivity.CONDUITE_VEHICULE_PERSONNEL,
  [UrssafProductTypeNatureCode.AIDE_AU_DEPLACEMENT]: PersonalServiceActivity.AIDE_AU_DEPLACEMENT,
  [UrssafProductTypeNatureCode.ENTRETIEN_DE_LA_MAISON]: PersonalServiceActivity.ENTRETIEN_DE_LA_MAISON,
  [UrssafProductTypeNatureCode.JARDINAGE]: PersonalServiceActivity.JARDINAGE,
  [UrssafProductTypeNatureCode.BRICOLAGE]: PersonalServiceActivity.BRICOLAGE,
  [UrssafProductTypeNatureCode.GARDE_ENFANT_PLUS_DE_6_ANS]: PersonalServiceActivity.GARDE_ENFANT_PLUS_DE_6_ANS,
  [UrssafProductTypeNatureCode.SOUTIEN_SCOLAIRE_COURS_A_DOMICILE]: PersonalServiceActivity.SOUTIEN_SCOLAIRE_COURS_A_DOMICILE,
  [UrssafProductTypeNatureCode.SOIN_ESTHETIQUE_A_DOMICILE]: PersonalServiceActivity.SOIN_ESTHETIQUE_A_DOMICILE,
  [UrssafProductTypeNatureCode.PREPARATION_REPAS_A_DOMICILE]: PersonalServiceActivity.PREPARATION_REPAS_A_DOMICILE,
  [UrssafProductTypeNatureCode.LIVRAISON_REPAS_A_DOMICILE]: PersonalServiceActivity.LIVRAISON_REPAS_A_DOMICILE,
  [UrssafProductTypeNatureCode.COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE]: PersonalServiceActivity.COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE,
  [UrssafProductTypeNatureCode.LIVRAISON_COURSES_A_DOMICILE]: PersonalServiceActivity.LIVRAISON_COURSES_A_DOMICILE,
  [UrssafProductTypeNatureCode.ASSISTANCE_INFORMATIQUE]: PersonalServiceActivity.ASSISTANCE_INFORMATIQUE,
  [UrssafProductTypeNatureCode.SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES]: PersonalServiceActivity.SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES,
  [UrssafProductTypeNatureCode.MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE]: PersonalServiceActivity.MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE,
  [UrssafProductTypeNatureCode.ASSISTANCE_ADMINISTRATIVE]: PersonalServiceActivity.ASSISTANCE_ADMINISTRATIVE,
  [UrssafProductTypeNatureCode.ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS]: PersonalServiceActivity.ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS,
  [UrssafProductTypeNatureCode.TELEASSISTANCE]: PersonalServiceActivity.TELEASSISTANCE,
  [UrssafProductTypeNatureCode.INTERPRETE_LANGUE_DES_SIGNES]: PersonalServiceActivity.INTERPRETE_LANGUE_DES_SIGNES,
  [UrssafProductTypeNatureCode.CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE]: PersonalServiceActivity.CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE,
  [UrssafProductTypeNatureCode.ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE]: PersonalServiceActivity.ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE,
  [UrssafProductTypeNatureCode.ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE]: PersonalServiceActivity.ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE,
  [UrssafProductTypeNatureCode.COORDINATION_ET_GESTION]: PersonalServiceActivity.COORDINATION_ET_GESTION,
  [UrssafProductTypeNatureCode.DIVERS_NON_ELIGIBLE]: PersonalServiceActivity.DIVERS_NON_ELIGIBLE,
};

export const mapActivityToCodeNature = {
  [PersonalServiceActivity.GARDE_ENFANT_HANDICAPE]: UrssafProductTypeNatureCode.GARDE_ENFANT_HANDICAPE,
  [PersonalServiceActivity.ACCOMPAGNEMENT_ENFANT_HANDICAPE]: UrssafProductTypeNatureCode.ACCOMPAGNEMENT_ENFANT_HANDICAPE,
  [PersonalServiceActivity.AIDE_HUMAINE]: UrssafProductTypeNatureCode.AIDE_HUMAINE,
  [PersonalServiceActivity.CONDUITE_VEHICULE_PERSONNEL]: UrssafProductTypeNatureCode.CONSUITE_VEHICULE_PERSONEL,
  [PersonalServiceActivity.AIDE_AU_DEPLACEMENT]: UrssafProductTypeNatureCode.AIDE_AU_DEPLACEMENT,
  [PersonalServiceActivity.ENTRETIEN_DE_LA_MAISON]: UrssafProductTypeNatureCode.ENTRETIEN_DE_LA_MAISON,
  [PersonalServiceActivity.JARDINAGE]: UrssafProductTypeNatureCode.JARDINAGE,
  [PersonalServiceActivity.BRICOLAGE]: UrssafProductTypeNatureCode.BRICOLAGE,
  [PersonalServiceActivity.GARDE_ENFANT_PLUS_DE_6_ANS]: UrssafProductTypeNatureCode.GARDE_ENFANT_PLUS_DE_6_ANS,
  [PersonalServiceActivity.SOUTIEN_SCOLAIRE_COURS_A_DOMICILE]: UrssafProductTypeNatureCode.SOUTIEN_SCOLAIRE_COURS_A_DOMICILE,
  [PersonalServiceActivity.SOIN_ESTHETIQUE_A_DOMICILE]: UrssafProductTypeNatureCode.SOIN_ESTHETIQUE_A_DOMICILE,
  [PersonalServiceActivity.PREPARATION_REPAS_A_DOMICILE]: UrssafProductTypeNatureCode.PREPARATION_REPAS_A_DOMICILE,
  [PersonalServiceActivity.LIVRAISON_REPAS_A_DOMICILE]: UrssafProductTypeNatureCode.LIVRAISON_REPAS_A_DOMICILE,
  [PersonalServiceActivity.COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE]: UrssafProductTypeNatureCode.COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE,
  [PersonalServiceActivity.LIVRAISON_COURSES_A_DOMICILE]: UrssafProductTypeNatureCode.LIVRAISON_COURSES_A_DOMICILE,
  [PersonalServiceActivity.ASSISTANCE_INFORMATIQUE]: UrssafProductTypeNatureCode.ASSISTANCE_INFORMATIQUE,
  [PersonalServiceActivity.SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES]: UrssafProductTypeNatureCode.SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES,
  [PersonalServiceActivity.MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE]: UrssafProductTypeNatureCode.MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE,
  [PersonalServiceActivity.ASSISTANCE_ADMINISTRATIVE]: UrssafProductTypeNatureCode.ASSISTANCE_ADMINISTRATIVE,
  [PersonalServiceActivity.ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS]: UrssafProductTypeNatureCode.ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS,
  [PersonalServiceActivity.TELEASSISTANCE]: UrssafProductTypeNatureCode.TELEASSISTANCE,
  [PersonalServiceActivity.INTERPRETE_LANGUE_DES_SIGNES]: UrssafProductTypeNatureCode.INTERPRETE_LANGUE_DES_SIGNES,
  [PersonalServiceActivity.CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE]: UrssafProductTypeNatureCode.CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE,
  [PersonalServiceActivity.ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE]: UrssafProductTypeNatureCode.ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE,
  [PersonalServiceActivity.ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE]: UrssafProductTypeNatureCode.ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE,
  [PersonalServiceActivity.COORDINATION_ET_GESTION]: UrssafProductTypeNatureCode.COORDINATION_ET_GESTION,
  [PersonalServiceActivity.DIVERS_NON_ELIGIBLE]: UrssafProductTypeNatureCode.DIVERS_NON_ELIGIBLE,
};
