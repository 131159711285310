import { CreateSMSCode } from '../../dto/auth/CreateSMSCode';
import { Resource } from '../Resource';
import { ReadSMSCode } from '../../dto/auth/ReadSMSCode';
import { CheckSMSCode } from '../../dto/auth/CheckSMSCode';

export class Auth extends Resource {
  initSignInWithSMS(data?: CreateSMSCode): Promise<ReadSMSCode> {
    return this.http.$post('/auth/sms', data);
  }

  signInWithSMS(data?: CheckSMSCode): Promise<string> {
    return this.http.$post('/auth/sms/check', data);
  }
}
