import { useContext, useStore } from '@nuxtjs/composition-api'
import { BillingType } from '@abby/shared'
import { BillingType as BillingTypeLegacy, cleanBillingDocument, IBilling, ProductUnit, SegmentEvent, ICustomer } from '@abby/core-legacy'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { Customer } from '~/logic/contexts/billing/domain/entity/customer.entity'

const mapBillingTypeToLegacy = {
  [BillingType.INVOICE]: BillingTypeLegacy.INVOICE,
  [BillingType.ADVANCE]: BillingTypeLegacy.ADVANCE,
  [BillingType.PURCHASE_ORDER]: BillingTypeLegacy.PURCHASE_ORDER,
  [BillingType.ASSET]: BillingTypeLegacy.ASSET,
  [BillingType.ESTIMATE]: BillingTypeLegacy.ESTIMATE,
}

export const useBillingStore = () => {
  const store = useStore()
  const { $api, $ap, $dayjs, $planManager } = useContext()

  return {
    toggleBillingCreationModal: async (value: boolean, type?: BillingType, customer?: Customer) => {
      if (value) {
        let _customer: ICustomer | undefined
        if (customer) {
          _customer = await $api.billing.getCustomer(customer.legacyId)
        }
        store.dispatch('billingCreation/openCreationBilling', {
          billingDocument: {
            billingType: type ? mapBillingTypeToLegacy[type] : undefined,
            customer: _customer || undefined,
          },
        })
      }
    },
    openBillingSidePanel: async (id: string) => {
      const billing = await $api.billing.get(id)
      store.dispatch('billing/openBillingSidePanel', billing)
    },
    openBillingEditionModal: async (id: string) => {
      const isBillingCreationModalMinimized = store.getters['billingCreation/isBillingCreationModalMinimized']
      const isBillingEditionModalMinimized = store.getters['billingCreation/isBillingEditionModalMinimized']
      const billingDocumentInEdition = store.getters['billingCreation/billingDocument']
      if ((isBillingCreationModalMinimized || isBillingEditionModalMinimized) && billingDocumentInEdition) {
        const confirm = await store.dispatch('billingCreation/askBeforeEraseTheEditingDocument', 'Modifier')
        if (!confirm) {
          return
        }
      }
      const billing = await $api.billing.get(id)
      if (billing.createdFromEstimate) {
        store.commit('billingCreation/SET_CREATE_FROM_BILLING_DOCUMENT', billing.createdFromEstimate)
      } else if (billing.createdFromInvoice) {
        store.commit('billingCreation/SET_CREATE_FROM_BILLING_DOCUMENT', billing.createdFromInvoice)
      } else if (billing.createdFromPurchaseOrder) {
        store.commit('billingCreation/SET_CREATE_FROM_BILLING_DOCUMENT', billing.createdFromPurchaseOrder)
      }
      store.commit('billingCreation/SET_BILLING_DOCUMENT', billing)
      store.commit('billingCreation/SET_IS_BILLING_EDITION_MODAL_OPENED', true)
    },
    openSendBillingByEmailModal: async (billing: BillingItem) => {
      const value = await $api.billing.get(billing.id)
      await store.dispatch('billing/setBillingDocumentForAction', value)
      store.dispatch('billing/setSendBillingByEmailModal', true)
    },
    openBillingUnpaidModal: async (id: string) => {
      const billing = await $api.billing.get(id)
      $ap.segmentTrack(SegmentEvent.UNPAID_INVOICE_GCOLLECT_CLICKED, {
        billingId: billing._id,
      })
      store.dispatch('billing/setBillingDocumentForAction', billing)
      store.dispatch('abbyStore/findPartner', 'gcollect')
    },
    openBillingMarkAsPaidModal: async (id: string) => {
      const billing = await $api.billing.get(id)
      store.dispatch('billing/openMarkAsPaidModal', billing)
    },
    openDuplicateBillingModal: async (id: string, billingType: BillingType) => {
      const billing = await $api.billing.get(id)
      const billingDocument = cleanBillingDocument(billing) as IBilling

      // On réinitialise les infos de la payment Request et du TP
      delete billingDocument.tiersPrestationIsActivatedForThisBilling
      delete billingDocument.paymentRequest
      delete billingDocument.number
      delete billingDocument.attachments
      delete billingDocument.canceledAt
      delete billingDocument.archivedAt

      if (store.getters['company/company'].multipleCurrency ||
          !$planManager.hasAccessTo($planManager.features.billing_multi_currency)
      ) {
        delete billingDocument.currency
        delete billingDocument.currencyRate
        delete billingDocument.currencyDate
      }

      await store.dispatch('billingCreation/openCreationBilling', {
        billingDocument: {
          ...billingDocument,
          billingType: mapBillingTypeToLegacy[billingType],
          validityDate: $dayjs().add(1, 'month').subtract(1, 'day').toDate(),
        },
      })
    },
    openBillingTitleModal: async (id: string) => {
      const billing = await $api.billing.get(id)
      await store.dispatch('billing/setBillingDocumentForAction', billing)
      store.dispatch('billing/openBillingTitleModal')
    },
    openFinalInvoiceCreationModal: async ({ id }: BillingItem) => {
      const billing = await $api.billing.get(id)
      // Create clean billing document to get the right billing style
      const billingDocument = cleanBillingDocument(billing) as IBilling
      // On réinitialise les infos de la payment Request et du TP
      delete billingDocument.tiersPrestationIsActivatedForThisBilling
      delete billingDocument.paymentRequest
      delete billingDocument.number
      delete billingDocument.attachments
      delete billingDocument.canceledAt
      delete billingDocument.archivedAt

      store.dispatch('billingCreation/openCreationBilling', {
        createFormBilling: {
          ...billing,
          useSignature: false,
        },
        billingDocument: {
          ...billingDocument,
          billingType: BillingTypeLegacy.INVOICE,
          product: billing.product,
          discountAmount: billing.discountAmount,
          discountMode: billing.discountMode,
          frequency: billing.frequency,
          currency: billing.currency,
          currencyDate: billing.currencyDate,
          currencyRate: billing.currencyRate,
          locale: billing.locale,
          opportunityId: billing.opportunityId,
        },
      })
    },
    openAdvanceCreationModal: async ({ id }: BillingItem) => {
      const billing = await $api.billing.get(id)
      // Create clean billing document to get the right billing style
      const billingDocument = cleanBillingDocument(billing) as IBilling

      delete billingDocument.number
      delete billingDocument.attachments
      delete billingDocument.canceledAt
      delete billingDocument.archivedAt

      store.dispatch('billingCreation/openCreationBilling', {
        createFormBilling: {
          ...billing,
          useSignature: false,
        },
        billingDocument: {
          ...billingDocument,
          billingType: BillingTypeLegacy.ADVANCE,
          product: [],
          discountAmount: billing.discountAmount,
          discountMode: billing.discountMode,
          frequency: billing.frequency,
          currency: billing.currency,
          currencyDate: billing.currencyDate,
          currencyRate: billing.currencyRate,
          locale: billing.locale,
          opportunityId: billing.opportunityId,
        },
      })
    },
    openAssetCreationModal: async ({ id }: BillingItem) => {
      const billing = await $api.billing.get(id)
      // Create clean billing document to get the right billing style
      const billingDocument = cleanBillingDocument(billing) as IBilling
      delete billingDocument.number
      delete billingDocument.attachments
      delete billingDocument.canceledAt
      delete billingDocument.archivedAt

      store.dispatch('billingCreation/openCreationBilling', {
        createFormBilling: {
          ...billing,
          product: billing.product.map((product) => {
            return ({
              ...product,
              quantity: product.quantity || 1,
              productUnit: product.productUnit || ProductUnit.UNITE,
            })
          }),
          useSignature: false,
        },
        billingDocument: {
          ...billingDocument,
          billingType: BillingTypeLegacy.ASSET,
          product: billing.product,
          discountAmount: billing.discountAmount,
          discountMode: billing.discountMode,
          frequency: billing.frequency,
          currency: billing.currency,
          currencyDate: billing.currencyDate,
          currencyRate: billing.currencyRate,
          locale: billing.locale,
          opportunityId: billing.opportunityId,
        },
      })
    },
    openBillingReminderModal: async (billing: BillingItem) => {
      const value = await $api.billing.get(billing.id)
      await store.dispatch('billing/setBillingDocumentForAction', value)
      store.commit('billing/SET_IS_UPDATE_REMINDER_MODAL_OPENED', true)
    },
    openCancelBillingModal: async ({ id }: BillingItem) => {
      const billing = await $api.billing.get(id)
      await store.dispatch('billing/setBillingDocumentForAction', billing)
      store.dispatch('billing/setBillingCancelModal', true)
    },
    openExpirationModal: async (billing: BillingItem) => {
      const value = await $api.billing.get(billing.id)
      await store.dispatch('billing/setBillingDocumentForAction', value)
      store.dispatch('billing/setBillingValidityDateModal', true)
    },
    openBillingFrequencyModal: async (billing: BillingItem) => {
      const value = await $api.billing.get(billing.id)
      await store.dispatch('billing/setBillingDocumentForAction', value)
      store.commit('billing/SET_IS_BILLING_FREQUENCY_MODAL_OPENED', true)
    },
    openFirstTimeConfigurationModal: () => {
      store.commit('billingConfiguration/OPEN_MODAL', 'firstTimeConfiguration')
    },
    retrieveOnlinePaymentIsActivated: (): boolean => {
      const stripeAccount = store.getters['billingConfiguration/stripeAccount']
      return !!(stripeAccount && (!stripeAccount.requirements || (stripeAccount.requirements && !stripeAccount.requirements.disabled_reason)))
    },
    isVatActivated: (): boolean => {
      const company = store.getters['company/company']
      return !!company.hasVat
    },
    openOpportunityPanel: async (billing: BillingItem) => {
      const opportunity = await $api.opportunity.getOpportunity(billing.opportunity!.id)
      store.dispatch('opportunity/openOpportunityDetails', { opportunity })
    },
    openCustomerUrssafSyncPanel: async (billing: BillingItem) => {
      const customer = await $api.billing.getCustomer(billing.customer.id)
      store.dispatch('billingCreation/openBillingCustomerTPForEmail', customer)
    },
    isTestMode: (): boolean => {
      const billingConfiguration = store.getters['billingConfiguration/billingConfiguration']
      return billingConfiguration.isTestMode
    },
  }
}
