import { formatQuery, normalize } from '@abby/core-legacy/src/utils'
import type { ICreationPackItem } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class CreationPackItem extends Resource {
  async getAll (parentActivityId: number, limit?: number): Promise<ICreationPackItem[]> {
    const query = formatQuery({
      populate: [
        'logo.*',
      ],
      filters: {
        // @ts-ignore
        $or: [
          {
            parentActivityIds: {
              $contains: `${parentActivityId},`,
            },
          },
          {
            parentActivityIds: {
              $contains: `,${parentActivityId},`,
            },
          },
          {
            parentActivityIds: {
              $contains: `,${parentActivityId}`,
            },
          },
          {
            parentActivityIds: {
              $eq: `${parentActivityId}`,
            },
          },
          {
            parentActivityIds: {
              $null: true,
            },
          },
        ],
      },
      pagination: {
        limit: limit || 25,
      },
      sort: ['priority:asc'],
    })
    const result = await this.axios.$get(`/strapi/creation-pack-items${query ? `?${query}` : ''}`, {
      baseURL: '',
      headers: {
        // @ts-ignore
        Authorization: undefined,
      },
    })
    return normalize(result.data)
      .map((cpif: ICreationPackItem) => ({
        ...cpif,
        showDescription: false,
      }))
  }
}
