export enum CreationDashboardStep {
  FILES_TO_VALIDATE = 'files_to_validate',
  DECLARATION_TO_DO = 'declaration_to_do',
  DECLARATION_IN_PROGRESS = 'declaration_in_progress', // a ici
  PENDING_DECLARATION = 'pending_declaration',
  DECLARATION_TO_REPORT = 'declaration_to_report',
  DECLARATION_REFUSED = 'declaration_refused',
  DECLARATION_PENDING_DENIAL = 'declaration_pending_denial',
  DECLARATION_TO_RETRY = 'declaration_to_retry',
  REGULARISATION_GU = 'regularisation_gu',
  REFUS_GU = 'refus_gu',
  TO_CALL_CFE = 'to_call_cfe',
  DECLARED = 'declared',
  DECLARATION_ACCEPTED = 'declaration_accepted',
  TO_CALL = 'to_call',
  ENDED = 'ended',
  ABANDONED = 'abandoned',
  NONE = 'none',
}

export const CreationDashboardStepColor = {
  [CreationDashboardStep.FILES_TO_VALIDATE]: 'orange lighten-2',
  [CreationDashboardStep.DECLARATION_TO_DO]: 'red lighten-1',
  [CreationDashboardStep.DECLARATION_IN_PROGRESS]: 'red lighten-2',
  [CreationDashboardStep.DECLARATION_REFUSED]: 'pink lighten-2',
  [CreationDashboardStep.DECLARATION_PENDING_DENIAL]: 'pink lighten-3',
  [CreationDashboardStep.DECLARATION_TO_RETRY]: 'pink darken-1',
  [CreationDashboardStep.REGULARISATION_GU]: 'pink lighten-2',
  [CreationDashboardStep.REFUS_GU]: 'pink lighten-3',
  [CreationDashboardStep.DECLARATION_ACCEPTED]: 'blue lighten-2',
  [CreationDashboardStep.TO_CALL]: 'blue lighten-3',
  [CreationDashboardStep.DECLARATION_TO_REPORT]: 'pink lighten-1',
  [CreationDashboardStep.TO_CALL_CFE]: 'pink lighten-3',
  [CreationDashboardStep.PENDING_DECLARATION]: 'red lighten-3',
  [CreationDashboardStep.DECLARED]: 'blue lighten-1',
  [CreationDashboardStep.ENDED]: 'purple lighten-2',
  [CreationDashboardStep.ABANDONED]: 'error',

  [CreationDashboardStep.NONE]: 'orange lighten-3',
};
