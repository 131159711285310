import type { ReadOnboarding, UpdateOnboarding } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Onboarding extends Resource {
  get (): Promise<ReadOnboarding> {
    return this.axios.$get('/onboarding')
  }

  getCreationFormUrl (): Promise<{ url: string }> {
    return this.axios.$get('/onboarding/creationForm')
  }

  update (data: UpdateOnboarding): Promise<ReadOnboarding> {
    return this.axios.$put('/onboarding', data)
  }

  submit (): Promise<void> {
    return this.axios.$post('/onboarding/submit')
  }

  finish (): Promise<void> {
    return this.axios.$post('/onboarding/finish')
  }
}
