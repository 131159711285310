import { defineStore, storeToRefs } from 'pinia'
import { Ref, ref } from 'vue'
import {
  CreateOrganizationContact,
  UrssafError,
  InvalidUrssafCustomerRegistrationReason,
} from '@abby/shared'
import { ClientType } from '../../domain/enums/clientType.enum'
import { Contact } from '~/logic/contexts/client/domain/entity/contact.entity'
import { Organization } from '~/logic/contexts/client/domain/entity/organization.entity'
import { ClientStatistics } from '~/logic/contexts/client/domain/valueObject/clientStatistics.valueObject'
import { ContactItem } from '~/logic/contexts/client/domain/entity/contactItem.entity'
import { ClientCopyTiersPrestationMessage } from '~/logic/contexts/client/domain/valueObject/clientCopyTiersPrestationEmailMessage.valueObject'
import { Customer } from '~/logic/contexts/billing/domain/entity/customer.entity'
import { ContactTPForm } from '~/logic/contexts/client/domain/form/contactTP.form'
import { ContactForm } from '~/logic/contexts/client/domain/form/contact.form'
import { OrganizationForm } from '~/logic/contexts/client/domain/form/organization.form'
import { OrganizationContactForm } from '~/logic/contexts/client/domain/form/organizationContact.form'
import { ContactDetailsTabs } from '~/logic/contexts/client/domain/enums/contactDetailsTabs.enum'
import { OrganizationDetailsTabs } from '~/logic/contexts/client/domain/enums/organizationDetailsTabs.enum'

export const useClientStore = () => {
  const useClientStore = defineStore('client', () => {
    const isClientFormSidePanelOpen = ref(false)
    const clientFormSidePanelClientType = ref<ClientType>(ClientType.CONTACT)
    const isContactInfoSidePanelOpen = ref(false)
    const isOrganizationInfoSidePanelOpen = ref(false)
    const selectedOrganization = ref<Organization>()
    const selectedOrganizationContactOrganization = ref<Organization>()
    const selectedContact = ref<Contact>()
    const selectedDefaultContactTab = ref<ContactDetailsTabs>()
    const selectedDefaultOrganizationTab = ref<OrganizationDetailsTabs>()
    const urssafTPErrors = ref<(UrssafError | InvalidUrssafCustomerRegistrationReason)[]>([])
    const contactToEdit = ref<ContactForm>()
    const contactTPToEdit = ref<ContactTPForm>()
    const organizationToEdit = ref<OrganizationForm>()
    const organizationContactToEdit = ref<OrganizationContactForm>()
    const selectedContactStatistics = ref<ClientStatistics>()
    const selectedOrganizationStatistics = ref<ClientStatistics>()
    const associatedOrganizationContacts = ref<ContactItem[]>([])
    const isClientCopyTiersPrestationModalOpen = ref(false)
    const clientCopyTiersPrestationMessage = ref<ClientCopyTiersPrestationMessage>()
    const selectedCustomer = ref<Customer>()
    const isContactTaxReturnModalOpen = ref(false)
    const isInitialLoading = ref(true)

    const toggleClientFormSidePanel = (value: boolean, type?: ClientType) => {
      isClientFormSidePanelOpen.value = value
      if (value && type) {
        clientFormSidePanelClientType.value = type
      }
      if (!value) {
        contactToEdit.value = undefined
        contactTPToEdit.value = undefined
        organizationToEdit.value = undefined
        organizationContactToEdit.value = undefined
        selectedOrganizationContactOrganization.value = undefined
        urssafTPErrors.value = []
        clientFormSidePanelClientType.value = ClientType.CONTACT
      }
    }

    const openEditClientFormSidePanel = (
      client:
        | ContactForm
        | ContactTPForm
        | OrganizationForm
        | CreateOrganizationContact,
      clientType: ClientType,
    ) => {
      isClientFormSidePanelOpen.value = true
      clientFormSidePanelClientType.value = clientType
      switch (clientType) {
      case ClientType.CONTACT:
        contactToEdit.value = client as ContactForm
        break
      case ClientType.CONTACT_PSA:
        contactTPToEdit.value = client as ContactTPForm
        break
      case ClientType.ORGANIZATION:
        organizationToEdit.value = client as OrganizationForm
        break
      case ClientType.CONTACT_ORGANIZATION:
        organizationContactToEdit.value = client as OrganizationContactForm
        break
      default:
        break
      }
    }

    const toggleContactDetailsSidePanel = (open: boolean, contact?: Contact, options?: any) => {
      if (open) {
        selectedContact.value = contact
        if (options?.defaultTab) {
          selectedDefaultContactTab.value = options.defaultTab
        }
        isContactInfoSidePanelOpen.value = true
        return true
      }
      selectedDefaultContactTab.value = undefined
      isContactInfoSidePanelOpen.value = false
    }

    const toggleOrganizationDetailsSidePanel = (open: boolean, organization?: Organization, options?: any) => {
      if (open) {
        selectedOrganization.value = organization
        if (options?.defaultTab) {
          selectedDefaultOrganizationTab.value = options.defaultTab
        }
        isOrganizationInfoSidePanelOpen.value = true
        return true
      }
      selectedDefaultOrganizationTab.value = undefined
      isOrganizationInfoSidePanelOpen.value = false
    }

    const setContactStatistics = (contactStatistic: ClientStatistics) => {
      selectedContactStatistics.value = contactStatistic
    }

    const setOrganizationStatistics = (
      organizationStatistic: ClientStatistics,
    ) => {
      selectedOrganizationStatistics.value = organizationStatistic
    }

    const setAssociatedOrganizationContacts = (contacts: ContactItem[]) => {
      associatedOrganizationContacts.value = contacts
    }

    const toggleClientCopyTiersPrestationModal = (
      open: boolean,
      value?: ClientCopyTiersPrestationMessage,
    ) => {
      isClientCopyTiersPrestationModalOpen.value = open
      if (!open) {
        clientCopyTiersPrestationMessage.value = undefined
        return true
      }
      clientCopyTiersPrestationMessage.value = value
    }

    const toggleContactTaxReturnModal = (open: boolean, value?: Customer) => {
      isContactTaxReturnModalOpen.value = open
      if (!open) {
        selectedCustomer.value = undefined
        return true
      }
      selectedCustomer.value = value
    }

    return {
      isClientFormSidePanelOpen,
      urssafTPErrors,
      contactToEdit,
      contactTPToEdit,
      organizationToEdit,
      organizationContactToEdit,
      isContactInfoSidePanelOpen,
      selectedOrganizationContactOrganization,
      selectedContact,
      selectedDefaultContactTab,
      selectedDefaultOrganizationTab,
      selectedContactStatistics,
      selectedOrganizationStatistics,
      isOrganizationInfoSidePanelOpen,
      selectedOrganization,
      clientFormSidePanelClientType,
      associatedOrganizationContacts,
      isClientCopyTiersPrestationModalOpen,
      clientCopyTiersPrestationMessage,
      isContactTaxReturnModalOpen,
      selectedCustomer,
      isInitialLoading,
      toggleClientFormSidePanel,
      openEditClientFormSidePanel,
      toggleOrganizationDetailsSidePanel,
      toggleContactDetailsSidePanel,
      toggleClientCopyTiersPrestationModal,
      toggleContactTaxReturnModal,
      setContactStatistics,
      setOrganizationStatistics,
      setAssociatedOrganizationContacts,
    }
  })
  const clientStore = useClientStore()
  const {
    isClientFormSidePanelOpen,
    isContactInfoSidePanelOpen,
    urssafTPErrors,
    contactToEdit,
    contactTPToEdit,
    organizationToEdit,
    organizationContactToEdit,
    selectedContact,
    selectedDefaultContactTab,
    selectedDefaultOrganizationTab,
    isOrganizationInfoSidePanelOpen,
    selectedOrganization,
    clientFormSidePanelClientType,
    selectedOrganizationContactOrganization,
    selectedContactStatistics,
    selectedOrganizationStatistics,
    associatedOrganizationContacts,
    isClientCopyTiersPrestationModalOpen,
    clientCopyTiersPrestationMessage,
    isContactTaxReturnModalOpen,
    selectedCustomer,
    isInitialLoading,
  } = storeToRefs(clientStore)

  return {
    isClientFormSidePanelOpen,
    clientFormSidePanelClientType,
    urssafTPErrors,
    contactToEdit,
    contactTPToEdit: contactTPToEdit as Ref<ContactTPForm | undefined>,
    organizationToEdit,
    organizationContactToEdit,
    // @ts-ignore
    selectedContact,
    // @ts-ignore
    selectedOrganization,
    // @ts-ignore
    selectedCustomer,
    // @ts-ignore
    associatedOrganizationContacts,
    selectedOrganizationContactOrganization: selectedOrganizationContactOrganization as Ref<Organization | undefined>,
    selectedDefaultContactTab,
    selectedDefaultOrganizationTab,
    selectedContactStatistics,
    selectedOrganizationStatistics,
    isContactInfoSidePanelOpen,
    isOrganizationInfoSidePanelOpen,
    isClientCopyTiersPrestationModalOpen,
    clientCopyTiersPrestationMessage,
    isContactTaxReturnModalOpen,
    isInitialLoading,
    toggleClientCopyTiersPrestationModal:
      clientStore.toggleClientCopyTiersPrestationModal,
    toggleOrganizationDetailsSidePanel:
      clientStore.toggleOrganizationDetailsSidePanel,
    toggleContactDetailsSidePanel: clientStore.toggleContactDetailsSidePanel,
    toggleContactTaxReturnModal: clientStore.toggleContactTaxReturnModal,
    openEditClientFormSidePanel: clientStore.openEditClientFormSidePanel,
    toggleClientFormSidePanel: clientStore.toggleClientFormSidePanel,
    setContactStatistics: clientStore.setContactStatistics,
    setOrganizationStatistics: clientStore.setOrganizationStatistics,
    setAssociatedOrganizationContacts:
      clientStore.setAssociatedOrganizationContacts,
  }
}
