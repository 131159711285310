import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  AbbyPlans,
  Code,
  LegalStatus,
  ProviderSignUp,
  RoleType, SegmentEvent,
  SignupType,
  Type,
  UpdateUserProvider,
} from '@abby/core-legacy'

import type {
  IUser,
  ISponsorship,
  ReadUserPreferences,
  UpdateUser, UpdateUserEmail,
  UpdateUserPreferences,
} from '@abby/core-legacy'

import { CustomError } from '~/types/CustomError'
import { Loading } from '~/store/app'

export type IAuthUser = IUser & { uid: string, emailVerified: boolean }

export interface AuthState {
  user: IAuthUser | null;
  userHashIntercom: string | null;
  expirationTime: number | null;
  sponsorship: ISponsorship | null
  signUpWithSocialProvider: boolean;
  userPreferences: ReadUserPreferences | null;
  isUpdatePasswordModalOpened: boolean;
  affiliationUrl?: string;
  signupPlan?: string,
  trialMode?: string,
  affilaeClickId?: string;
  fatherSponsorshipCode?: string;
  connectedFromDashboard?: {
    fullName: string;
    sourceId: string;
  } | null;
}

export const state = (): AuthState => ({
  user: null,
  userHashIntercom: null,
  expirationTime: null,
  sponsorship: null,
  signUpWithSocialProvider: false,
  userPreferences: null,
  isUpdatePasswordModalOpened: false,
  affiliationUrl: undefined,
  signupPlan: undefined,
  trialMode: undefined,
  affilaeClickId: undefined,
  fatherSponsorshipCode: undefined,
  connectedFromDashboard: null,
})

export const getters: GetterTree<AuthState, AuthState> = {
  user: (state): IAuthUser | null => state.user,
  userHashIntercom: (state): string | null => state.userHashIntercom,
  expirationTime: (state): number | null => state.expirationTime,
  hasGodFather: state => !!state.sponsorship,
  userPreferences: state => state.userPreferences,
  isUpdatePasswordModalOpened: state => state.isUpdatePasswordModalOpened,
  signUpWithSocialProvider: state => state.signUpWithSocialProvider,
  affiliationUrl: state => state.affiliationUrl,
  affilaeClickId: state => state.affilaeClickId,
  fatherSponsorshipCode: state => state.fatherSponsorshipCode,
  signupPlan: state => state.signupPlan,
  trialMode: state => state.trialMode,
  fromWebsite: state => state.affiliationUrl ? state.affiliationUrl.includes('"ws":"1"') : false,
  connectedFromDashboard: state => state.connectedFromDashboard,
  hasRole: state => (role: RoleType) => !!state.user?.roles?.some(({ name }) => name === role),
}

export const mutations: MutationTree<AuthState> = {
  ON_AUTH_STATE_CHANGED_MUTATION: (state, { authUser }) => {
    if (!authUser) {
      state.user = null
      return
    }
    const { uid, email, emailVerified } = authUser
    state.user = { uid, email, emailVerified, ...state.user }
  },
  SET_USER: (state, value) => {
    state.user = value
  },
  SET_USER_HASH_INTERCOM: (state, value) => {
    state.userHashIntercom = value
  },
  SET_EXPIRATION_TIME: (state, value) => {
    state.expirationTime = value
  },
  SET_SIGN_UP_WITH_SOCIAL_PROVIDER: (state, value: boolean) => {
    state.signUpWithSocialProvider = value
  },
  SET_AFFILIATION_URL: (state, value) => {
    const storedAffiliationUrl = JSON.parse(localStorage.getItem('affiliationUrl') || '{}')
    if (value && value !== {}) {
      state.affiliationUrl = JSON.stringify(value)
      localStorage.setItem('affiliationUrl', JSON.stringify(state.affiliationUrl))
      if (value.aecid) {
        state.affilaeClickId = value.aecid
      }
      if (value.trialMode) {
        state.trialMode = value.trialMode
      }
    } else if (storedAffiliationUrl.length > 0) {
      state.affiliationUrl = storedAffiliationUrl
    }
  },
  SET_SIGNUP_PLAN: (state, value) => {
    state.signupPlan = value
  },
  SET_FATHER_SPONSORSHIP_CODE: (state, value: string) => {
    state.fatherSponsorshipCode = value
  },
  SET_SPONSORSHIP: (state, value: ISponsorship | null) => {
    state.sponsorship = value
  },
  SET_USER_PREFERENCES: (state, value) => {
    state.userPreferences = value
  },
  SET_IS_UPDATE_MODAL_OPENED: (state, value: boolean) => {
    state.isUpdatePasswordModalOpened = value
  },
  SET_CONNECTED_FROM_DASHBOARD: (state, value) => {
    state.connectedFromDashboard = value
  },
  RESET (_currentState: AuthState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<AuthState, AuthState> = {
  setSignUpPlan ({ commit }, payload: AbbyPlans) {
    commit('SET_SIGNUP_PLAN', payload)
  },
  async fetchUser ({ commit, getters }) {
    const result = await this.$api.user.get()
    commit('SET_USER', { ...getters.user, ...result })
  },
  async fetchUserHashIntercom ({ commit, getters }) {
    const result = await this.$api.intercom.retrieveUserHash()
    commit('SET_USER_HASH_INTERCOM', result.userHash)
  },
  async fetchUserPreferences ({ commit }) {
    const result = await this.$api.user.getPreferences()
    commit('SET_USER_PREFERENCES', result)
  },
  async updateUserPreferences ({ commit }, payload: UpdateUserPreferences) {
    const result = await this.$api.user.updatePreferences(payload)
    commit('SET_USER_PREFERENCES', result)
  },
  async updateUser ({ commit }, payload: UpdateUser) {
    const result = await this.$api.user.update(payload)
    commit('SET_USER', result)
  },
  async updateUserProvider ({ commit, dispatch }, payload: UpdateUserProvider) {
    const result = await this.$api.user.changeProvider(payload)
    commit('SET_USER', result)
    setTimeout(() => {
      dispatch('logout')
    }, 2000)
  },
  async updateUserEmail ({ commit, getters, dispatch }, payload: UpdateUserEmail) {
    await this.$api.user.updateEmail(payload)
    const user = getters.user
    commit('SET_USER', {
      ...user,
      email: payload.email,
    })
    setTimeout(() => {
      dispatch('logout')
    }, 2000)
  },
  setExpirationTime ({ commit }, payload: number) {
    commit('SET_EXPIRATION_TIME', payload)
  },
  async onAuthStateChangedAction ({ commit }, { authUser }) {
    // Set dashboard connection
    const result = await this.$fire.auth.currentUser?.getIdTokenResult()
    if (result?.claims?.sourceId) {
      commit('SET_CONNECTED_FROM_DASHBOARD', {
        sourceId: result?.claims?.sourceId,
        fullName: result?.claims?.fullName,
      })
    }
    commit('ON_AUTH_STATE_CHANGED_MUTATION', { authUser })
  },
  async connectWithEmailOrToken ({ dispatch, commit }, data: { token?: string, email: string, password: string, noLoader: boolean }) {
    if (!data.noLoader) {
      dispatch('app/setAppLoading', { key: Loading.AUTH_LOADING, value: true }, { root: true })
    }
    try {
      if (data.token) {
        await this.$fire.signInWithCustomToken(data.token)
        const result = await this.$fire.auth.currentUser?.getIdTokenResult()
        if (result?.claims?.sourceId) {
          commit('SET_CONNECTED_FROM_DASHBOARD', {
            sourceId: result?.claims?.sourceId,
            fullName: result?.claims?.fullName,
          })
        }
      } else {
        await this.$fire.signInWithEmailAndPassword(data.email, data.password)
      }
      const firebaseUser = this.$fire.auth.currentUser
      if (!firebaseUser) {
        throw new CustomError('User not connected with firebase', Code.authenticationFailure, Type.authError)
      }
      const token = await firebaseUser.getIdToken()
      this.$api.setToken(`Bearer ${token}`)
      const result = await this.$api.auth.authenticate()
      // on set le coookies user de l'utilisateur
      if (!this.$cookies.get('ay_user_id')) {
        this.$cookies.set('ay_user_id', result.id)
      }
    } finally {
      dispatch('app/setAppLoading', { key: Loading.AUTH_LOADING, value: false }, { root: true })
    }
  },
  async connectWithSocialProvider ({ dispatch }, data: { provider: ProviderSignUp }) {
    let provider = null
    if (data.provider === ProviderSignUp.GOOGLE) {
      provider = new this.$fire.GoogleAuthProvider()
    } else if (data.provider === ProviderSignUp.FACEBOOK) {
      provider = new this.$fire.FacebookAuthProvider()
    } else if (data.provider === ProviderSignUp.APPLE) {
      provider = new this.$fire.OAuthProvider('apple.com')
    }
    if (!provider) { return }
    try {
      await this.$fire.signInWithPopup(provider)

      const firebaseUser = this.$fire.auth.currentUser
      if (!firebaseUser) {
        throw new CustomError('User not connected with firebase', Code.authenticationFailure, Type.authError)
      }

      const token = await firebaseUser.getIdToken()
      this.$api.setToken(`Bearer ${token}`)
      const result = await this.$api.auth.authenticate({
        provider: data.provider,
      })
      // on set le coookies user de l'utilisateur
      if (!this.$cookies.get('ay_user_id')) {
        this.$cookies.set('ay_user_id', result.id)
      }
    } finally {
      dispatch('app/setAppLoading', { key: Loading.AUTH_LOADING, value: false }, { root: true })
    }
  },
  async resetPassword (_, data: { email: string }) {
    await this.$fire.sendPasswordResetEmail(data.email)
  },
  async updatePassword (_, password: string) {
    if (!this.$fire.auth.currentUser) {
      return
    }
    await this.$fire.updatePassword(password)
  },
  async signUpWithSocialProvider ({ getters, commit, dispatch }, data: { provider: ProviderSignUp, type: SignupType, legalStatus: LegalStatus }) {
    let provider = null
    if (data.provider === ProviderSignUp.GOOGLE) {
      provider = new this.$fire.GoogleAuthProvider()
    } else if (data.provider === ProviderSignUp.FACEBOOK) {
      provider = new this.$fire.FacebookAuthProvider()
    } else if (data.provider === ProviderSignUp.APPLE) {
      provider = new this.$fire.OAuthProvider('apple.com')
    }
    if (!provider) { return }

    commit('SET_SIGN_UP_WITH_SOCIAL_PROVIDER', true)
    await this.$fire.signInWithPopup(provider)
    const firebaseUser = this.$fire.auth.currentUser
    if (!firebaseUser) {
      await this.$fire.auth.signOut()
      throw new CustomError('User not connected with firebase', Code.authenticationFailure, Type.authError)
    }
    const token = await firebaseUser.getIdToken()
    this.$api.setToken(`Bearer ${token}`)
    const { type } = data
    const result = await this.$api.auth.authenticate({
      firstname: firebaseUser?.displayName?.split(' ')[0],
      lastname: firebaseUser?.displayName?.split(' ')[1],
      provider: data.provider,
      type,
      affiliationUrl: getters.affiliationUrl,
      affilaeClickId: getters.affilaeClickId,
      legalStatus: data.legalStatus,
    })
    this.$cookies.set('ay_user_id', result.id)

    if (getters.signupPlan) {
      await dispatch('payment/startSubscriptionTrial', { id: getters.signupPlan, trialMode: getters.trialMode }, { root: true })
    }
    await dispatch('initializeUserRessources', null, { root: true })
  },
  async signUpWithEmail ({ getters, dispatch }, data: { email: string, password: string, company?: string, firstname: string, lastname: string, phone: string, type: SignupType, legalStatus: LegalStatus }) {
    const { email } = data
    // Seules les compte avec le prenom "Audace" peuvent créer des comptes avec emails jetables

    await this.$fire.createUserWithEmailAndPassword(email, data.password)
    const firebaseUser = this.$fire.auth.currentUser
    if (!firebaseUser) {
      await this.$fire.auth.signOut()
      throw new CustomError('User not connected with firebase', Code.authenticationFailure, Type.authError)
    }
    const token = await firebaseUser.getIdToken()
    this.$api.setToken(`Bearer ${token}`)
    const { firstname, lastname, phone, type, company } = data
    const result = await this.$api.auth.authenticate({
      firstname,
      lastname,
      phone,
      type,
      company,
      provider: ProviderSignUp.PASSWORD,
      affiliationUrl: getters.affiliationUrl,
      affilaeClickId: getters.affilaeClickId,
      legalStatus: data.legalStatus,
    })
    this.$cookies.set('ay_user_id', result.id)
    if (getters.signupPlan) {
      await dispatch('payment/startSubscriptionTrial', { id: getters.signupPlan, trialMode: getters.trialMode }, { root: true })
    }
    await dispatch('initializeUserRessources', null, { root: true })
  },
  async finishOnboarding ({ dispatch }) {
    await this.$api.onboarding.finish()
    this.$ap.sendToGTM({ event: SegmentEvent.SIGNED_UP_COMPLETE })
    await dispatch('initializeUserRessources', null, { root: true })
    dispatch('creation/cleanLocalOnboarding', null, { root: true })
    dispatch('gestion/cleanLocalOnboarding', null, { root: true })
  },
  async logout ({ dispatch }) {
    dispatch('app/setAppLoading', { key: Loading.LOGOUT_LOADING, value: true }, { root: true })
    dispatch('gestion/cleanLocalOnboarding', null, { root: true })
    dispatch('creation/cleanLocalOnboarding', null, { root: true })
    await dispatch('cleanupAction', null, { root: true })
    await this.$fire.auth.signOut()
    this.$sdk.resetToken()
    try {
      await this.$router.push('/auth/login')
    } catch {
      window.location.href = '/auth/login'
    }
    this.$help.disconnectIntercom()
    dispatch('app/setAppLoading', { key: Loading.LOGOUT_LOADING, value: false }, { root: true })
    // const channel = window.BroadcastChannel ? new BroadcastChannel('logout') : null
    // channel?.postMessage('logout')
  },
  setFatherSponsorshipCode ({ commit }, code: string) {
    commit('SET_FATHER_SPONSORSHIP_CODE', code)
  },
  async fetchUserSponsorship ({ commit, getters }) {
    const result = await this.$api.sponsorship.getByGodSonId(getters.user?.id)
    commit('SET_SPONSORSHIP', result)
  },
}
