import { Category } from '~/logic/contexts/opportunity/domain/entity/category.entity'
import { Amount } from '~/logic/contexts/shared-kernel/domain/valueObject/amount.valueObject'

export type OpportunityItemProps = {
  id: string
  name: string
  dueDate?: Date
  amount: Amount,
  category: Category
  customerId: string;
  customerName: string;
}

export class OpportunityItem {
  readonly id: string
  readonly name: string
  readonly dueDate?: Date
  readonly amount: Amount
  readonly category: Category
  readonly customerId: string;
  readonly customerName: string;

  private constructor (props: OpportunityItemProps) {
    this.id = props.id
    this.name = props.name
    this.dueDate = props.dueDate
    this.amount = props.amount
    this.category = props.category
    this.customerId = props.customerId
    this.customerName = props.customerName
  }

  public static create (props: OpportunityItemProps): OpportunityItem {
    return new OpportunityItem(props)
  }
}
