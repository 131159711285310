import { ActionTree, GetterTree, MutationTree } from 'vuex'

export interface SignupState {
  isBeforeBeginModalOpened: boolean
}

export const state = (): SignupState => ({
  isBeforeBeginModalOpened: true,
})

export const getters: GetterTree<SignupState, SignupState> = {
  isBeforeBeginModalOpened: state => state.isBeforeBeginModalOpened,
}

export const mutations: MutationTree<SignupState> = {
  SET_IS_BEFORE_BEGIN_MODAL_OPENED (state, value: boolean) {
    state.isBeforeBeginModalOpened = value
  },
  RESET (_currentState: SignupState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<SignupState, SignupState> = {}
