import { ReadBankAccount } from '@abby/shared'
import { BankAccount } from '~/logic/contexts/bank/domain/entity/bankAccount.entity'
import { BankItemMapper } from '~/logic/contexts/bank/infrastructure/mapper/bankItem.mapper'

export class BankAccountMapper {
  static fromHttp (data: ReadBankAccount): BankAccount {
    return BankAccount.create({
      id: data.id,
      name: data.name,
      isSynced: data.isSynchronized,
      balance: data.balance || 0,
      transactionsToAnnotate: data.transactionsToAnnotate,
      ...(data.bankItem && { bankItem: BankItemMapper.fromHttp(data.bankItem) }),
    })
  }
}
