import { formatQuery, normalize } from '@abby/core-legacy/src/utils/strapi/transformer'
import type { IStrapiQuery } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Services extends Resource {
  /*
   Exemple pour tout inclure :

   await this.$api.services.getAll({
      populate: [
        'partners',
        'partners.advantages',
      ],
    })
   */
  async getAll (params?: IStrapiQuery) {
    const query = formatQuery(params || {}) || null
    const result = await this.axios.$get(`/strapi/services${query ? `?${query}` : ''}`, {
      baseURL: '',
      headers: {
        // @ts-ignore
        Authorization: undefined,
      },
    })
    return normalize(result.data)
  }

  async getPartner (params?: IStrapiQuery) {
    const query = formatQuery(params || {}) || null
    // @ts-ignore
    const result = await this.axios.$get(`/strapi/partners${query ? `?${query}` : ''}`, {
      baseURL: '',
      headers: {
        // @ts-ignore
        Authorization: undefined,
      },
    })
    return normalize(result.data[0])
  }
}
