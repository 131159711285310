import type {
  CreateRetrieveFile, ReadFile, ReadRetrieveFile, UploadFile,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class File extends Resource {
  retrieveSignedURL (params: CreateRetrieveFile): Promise<ReadRetrieveFile> {
    return this.axios.$post('/file/signedUrl', params)
  }

  retrieveBrandSignedURL (params: CreateRetrieveFile): Promise<ReadRetrieveFile> {
    return this.axios.$post('/file/brandSignedUrl', params)
  }

  retrieveCompanySignedURL (companyId: string, params: CreateRetrieveFile): Promise<ReadRetrieveFile> {
    return this.axios.$post(`/file/companySignedUrl/${companyId}`, params)
  }

  getFile (id: string): Promise<Buffer> {
    return this.axios.$get(`/file/${id}`)
  }

  getFileInfo (id: string): Promise<ReadFile> {
    return this.axios.$get(`/file/info/${id}`)
  }

  uploadFile (params: UploadFile): Promise<boolean> {
    return this.axios.$post('/file', params)
  }

  removeFile (id: string): Promise<boolean> {
    return this.axios.$delete(`/file/${id}`)
  }

  rollbackFileUpload (fileId: string): Promise<boolean> {
    return this.axios.$delete(`/file/${fileId}/rollback`)
  }
}
