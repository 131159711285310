import { ClientType } from '~/logic/contexts/client/domain/enums/clientType.enum'
import { ContactFormHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactFormHttp.mapper'
import { ContactTPFormHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactTPFormHttp.mapper'
import { OrganizationFormHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/organizationFormHttp.mapper'
import { OrganizationContactFormHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/organizationContactFormHttp.mapper'
import { OrganizationHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/organizationHttp.mapper'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'

export const useOpenClientFormEditionSidePanel = () => {
  const clientStore = useClientStore()
  const organizationRepository = useOrganizationRepository()
  const contactRepository = useContactRepository()

  return async (id: string, clientType: ClientType) => {
    switch (clientType) {
    case ClientType.ORGANIZATION: {
      const organization = await organizationRepository.getOrganizationById(id)
      clientStore.openEditClientFormSidePanel(
        OrganizationFormHttpMapper.toDomain(organization),
        ClientType.ORGANIZATION,
      )
      break
    }
    case ClientType.CONTACT:
    case ClientType.CONTACT_ORGANIZATION:
    case ClientType.CONTACT_PSA: {
      const contact = await contactRepository.getContactById(id)

      if (contact.hasTiersPrestation) {
        const customerTP = await contactRepository.getContactSapData(contact.id)
        if (customerTP) {
          clientStore.openEditClientFormSidePanel(
            ContactTPFormHttpMapper.toDomain(contact, customerTP),
            ClientType.CONTACT_PSA,
          )
        }
      } else if (contact.organization) {
        const organization = await organizationRepository.getOrganizationById(contact.organization.id)
        clientStore.selectedOrganizationContactOrganization.value = OrganizationHttpMapper.toDomain(organization)
        clientStore.openEditClientFormSidePanel(
          OrganizationContactFormHttpMapper.toDomain(contact, organization),
          ClientType.CONTACT_ORGANIZATION,
        )
      } else {
        clientStore.openEditClientFormSidePanel(
          ContactFormHttpMapper.toDomain(contact),
          ClientType.CONTACT,
        )
      }
      break
    }
    default:
      break
    }
  }
}
