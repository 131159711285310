
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { StarterKit } from '@tiptap/starter-kit'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Underline } from '@tiptap/extension-underline'
import { Link } from '@tiptap/extension-link'
import { TaskList } from '@tiptap/extension-task-list'
import { TaskItem } from '@tiptap/extension-task-item'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'

@Component({
  components: { AsTextField, EditorContent, ASDialog },
})
export default class ASEditor extends Vue {
  @Prop()
  value!: string;

  @Prop()
  disabled!: boolean;

  @Prop()
  placeholder!: string;

  @Prop()
  edgeLess!: boolean;

  @Prop({ default: true })
  editable!: boolean;

  @Prop({ default: false })
  displayOnly!: boolean;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ default: false })
  hideUndo!: boolean | string;

  @Prop({ default: false })
  hideTasks!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ required: false, default: false })
  autofocus!: boolean | string;

  // @Watch('value')
  // handleValue (value: string) {
  //   this.editor?.commands.setContent(value)
  // }

  editor: Editor | null = null
  newLinkUrl = ''

  dialog = false

  resolve: any = null
  reject: any = null

  @Watch('editable')
  editableHandler (value: boolean) {
    this.editor?.setOptions({
      editable: value,
    })
  }

  get _hideUndo () {
    return !!this.hideUndo || typeof this.hideUndo === 'string'
  }

  get _hideTasks () {
    return !!this.hideTasks || typeof this.hideTasks === 'string'
  }

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  get _autofocus () {
    return !!this.autofocus || typeof this.autofocus === 'string'
  }

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get isEditing () {
    return this.editor?.isFocused || !this.editor?.isEmpty
  }

  mounted () {
    setTimeout(() => {
      this.editor = new Editor({
        content: this.value || '',
        onUpdate: () => {
          this.updateValue()
        },
        parseOptions: {
          preserveWhitespace: 'full',
        },
        extensions: [
          StarterKit,
          Underline,
          Placeholder.configure({
            placeholder: this.placeholder,
          }),
          Link.configure({
            openOnClick: false,
          }),
          TaskList,
          TaskItem.configure({
            nested: true,
          }),
        ],
      })
      if (!this.editable) {
        this.editor?.setOptions({
          editable: false,
        })
      }
      if (this._autofocus) {
        this.editor?.commands.focus()
      }
      this.$emit('ready')
    }, 100)
  }

  promptUrl (): Promise<string | null> {
    this.dialog = true

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  agree () {
    this.resolve(this.newLinkUrl)
    this.dialog = false
    this.newLinkUrl = ''
  }

  cancel () {
    this.resolve(null)
    this.dialog = false
    this.newLinkUrl = ''
  }

  async setLink () {
    if (!this.editor) {
      return
    }

    const previousUrl = this.editor.getAttributes('link').href
    this.newLinkUrl = previousUrl

    const url = await this.promptUrl()

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return
    }

    // update link
    this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }

  beforeDestroy () {
    this.editor?.destroy()
  }

  updateValue () {
    const content = this.editor?.getHTML()
    this.$emit('input', content === '<p></p>' ? '' : content || '')
  }

  insertTextAtPos (content: string) {
    const transaction = this.editor?.state.tr.insertText(content)
    if (!transaction) { return }
    this.editor?.view.dispatch(transaction)
  }
}
