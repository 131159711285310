import {
  ICompanyStripeProduct,
  ReadCompanyStripeProduct, StripeProductType, UpdateTrial,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Payment extends Resource {
  getSubscriptions (): Promise<ReadCompanyStripeProduct[]> {
    return this.axios.$get('/company/subscriptions')
  }

  getTrials (companyId: string): Promise<ReadCompanyStripeProduct[]> {
    return this.axios.$get(`/company/trials/${companyId}`)
  }

  deleteSubscription (id: string): Promise<void> {
    return this.axios.$delete(`/company/subscription/${id}`)
  }

  reactivateSubscription (id: string): Promise<void> {
    return this.axios.$post(`/company/subscription/${id}/reactivate`)
  }

  startTrial (productId: string, payload?: { trialMode?: string }): Promise<ICompanyStripeProduct> {
    return this.axios.$post(`/company/subscription/${productId}/trial`, payload)
  }

  extendTrial (productId: string) {
    return this.axios.$post(`/company/subscription/${productId}/extendTrial`)
  }

  extendOrStartTrialPro () {
    return this.axios.$post('/company/subscription/extendOrStartTrialPro')
  }

  extendOrStartTrialBusiness () {
    return this.axios.$post('/company/subscription/extendOrStartTrialBusiness')
  }

  addNewTrialForExistingSubscription (plan: StripeProductType) {
    return this.axios.$post('/company/subscription/addNewTrialForExistingSubscription', { plan })
  }

  extendTrialDashboard (payload: UpdateTrial) {
    return this.axios.$post('/company/subscription/extendTrialDashboard', payload)
  }
}
