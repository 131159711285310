import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import type {
  BasePaginateMongo,
  CreateIncomeBookItem,
  ReadIncomeBookItem,
  ReadIncomeBookStatistics,
  UpdateIncomeBook,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface IncomeBookQuery {
  search?: string | undefined,
}
export interface IncomeBookStaticsQuery {
  search?: string | undefined,
  from?: string | undefined,
  to?: string | undefined,
}

export interface IncomeBookPaginationQuery { page: number, limit: number, range?: [string, string]}

export interface IncomeBookState {
  isIncomeBookModalOpened: boolean;
  isIncomeBookImportModalOpened: boolean;
  pagination: BasePaginateMongo<any> | null;
  incomeBookItem: ReadIncomeBookItem | null;
  queryLoading: boolean;
  query: IncomeBookQuery;
  initialLoading: boolean;
  paginationQuery: IncomeBookPaginationQuery
  statistics: ReadIncomeBookStatistics
  statisticsLoading: boolean;
  queryStatistics: IncomeBookStaticsQuery;
  queryStatisticsLoading: boolean;
}

export const state = (): IncomeBookState => ({
  isIncomeBookModalOpened: false,
  isIncomeBookImportModalOpened: false,
  statistics: {
    saleOfGoodsAndManufacturedGoodsWithoutTax: 0,
    serviceDeliveryWithoutTax: 0,
    commercialOrCraftServicesWithoutTax: 0,
    disbursementWithoutTax: 0,
    saleOfGoodsAndManufacturedGoodsTotalTax: 0,
    serviceDeliveryTotalTax: 0,
    commercialOrCraftServicesTotalTax: 0,
  },
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
  },
  query: {
    search: undefined,
  },
  queryStatistics: {
    from: `${dayjs().get('year')}-01-01`,
    to: dayjs().format('YYYY-MM-DD'),
  },
  queryStatisticsLoading: true,
  queryLoading: true,
  initialLoading: true,
  incomeBookItem: null,
  statisticsLoading: true,

})

export const getters: GetterTree<IncomeBookState, RootState> = {
  pagination (state: IncomeBookState) {
    return state.pagination
  },
  query (state: IncomeBookState) {
    return state.query
  },
  paginationQuery (state: IncomeBookState) {
    return state.paginationQuery
  },
  incomeBookItems (state: IncomeBookState) {
    return state.pagination?.docs || []
  },
  isIncomeBookModalOpened (state: IncomeBookState) {
    return state.isIncomeBookModalOpened
  },
  isIncomeBookImportModalOpened (state: IncomeBookState) {
    return state.isIncomeBookImportModalOpened
  },
  queryLoading (state: IncomeBookState) {
    return state.queryLoading
  },
  initialLoading (state: IncomeBookState) {
    return state.initialLoading
  },
  incomeBookItem (state: IncomeBookState) {
    return state.incomeBookItem
  },
  statistics (state: IncomeBookState) {
    return state.statistics
  },
  statisticsLoading (state: IncomeBookState) {
    return state.statisticsLoading
  },
  queryStatistics (state: IncomeBookState) {
    return state.queryStatistics
  },
  queryStatisticsLoading (state: IncomeBookState) {
    return state.queryStatisticsLoading
  },

}

export const mutations = {
  SET_PAGINATION_QUERY (state: IncomeBookState, paginationQuery: IncomeBookPaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: IncomeBookState, pagination: BasePaginateMongo<any> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: IncomeBookState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: IncomeBookState, value: boolean): void {
    state.queryLoading = value
  },
  SET_QUERY (state: IncomeBookState, value: IncomeBookQuery): void {
    state.query = value
  },
  SET_IS_INCOME_BOOK_ITEM_MODAL_OPENED (state: IncomeBookState, value: boolean) {
    state.isIncomeBookModalOpened = value
  },
  SET_IS_INCOME_BOOK_IMPORT_MODAL_OPENED (state: IncomeBookState, value: boolean) {
    state.isIncomeBookImportModalOpened = value
  },
  SET_INCOME_BOOK_ITEM (state: IncomeBookState, value: ReadIncomeBookItem | null) {
    state.incomeBookItem = value
  },

  SET_STATISTICS (state: IncomeBookState, value: any | null): void {
    state.statistics = value
  },

  SET_STATISTICS_LOADING (state: IncomeBookState, value: boolean): void {
    state.statisticsLoading = value
  },
  SET_QUERY_STATISTICS (state: IncomeBookState, value: IncomeBookStaticsQuery): void {
    state.queryStatistics = value
  },
  SET_QUERY_STATISTICS_LOADING (state: IncomeBookState, value: boolean): void {
    state.queryStatisticsLoading = value
  },

  RESET (_currentState: IncomeBookState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  async fetchIncomeBook ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.incomeBook.getPaginated({
        ...getters.paginationQuery as IncomeBookPaginationQuery,
        ...getters.query,
        countWithoutFilters: true,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  debounceFetchIncomeBook: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchIncomeBook')
  }, 300, { leading: true }),
  debounceFetchStatistics: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchStatistics')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<IncomeBookQuery>) {
    commit('SET_QUERY', query)
    dispatch('debounceFetchIncomeBook')
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<IncomeBookPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchIncomeBook')
  },
  setIncomeBookItemModal ({ commit }, value: boolean) {
    commit('SET_IS_INCOME_BOOK_ITEM_MODAL_OPENED', value)
  },
  setIncomeBookImportModal ({ commit }, value: boolean) {
    commit('SET_IS_INCOME_BOOK_IMPORT_MODAL_OPENED', value)
  },
  setIncomeBookItem ({ commit }, value: ReadIncomeBookItem | null) {
    commit('SET_INCOME_BOOK_ITEM', value)
  },

  async addIncomeBookItem ({ dispatch }, value: CreateIncomeBookItem) {
    await this.$api.incomeBook.createItem(value)
    dispatch('fetchIncomeBook')
    dispatch('fetchStatistics')
  },

  async updateIncomeBookItem ({ dispatch }, payload: { value: UpdateIncomeBook, id: string}) {
    await this.$api.incomeBook.updateItem(payload.id, payload.value)
    dispatch('fetchIncomeBook')
    dispatch('fetchStatistics')
  },

  async deleteIncomeBookItem ({ dispatch }, id: string) {
    await this.$api.incomeBook.deleteItem(id)
    dispatch('fetchIncomeBook')
    dispatch('fetchStatistics')
  },

  async addIncomeBookItems ({ dispatch }, value: any[]) {
    const results = await this.$api.incomeBook.createItems(value)
    dispatch('fetchIncomeBook')
    dispatch('fetchStatistics')
    return results
  },
  async fetchStatistics ({ commit, getters }) {
    try {
      commit('SET_STATISTICS_LOADING', true)
      const statistics = await this.$api.incomeBook.getStatistics({
        ...getters.queryStatistics,
      })
      commit('SET_STATISTICS', statistics)
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      commit('SET_STATISTICS_LOADING', false)
    }
  },
  updateQueryStatistics ({ commit, dispatch }, query: Partial<IncomeBookStaticsQuery>) {
    commit('SET_QUERY_STATISTICS', query)
    dispatch('debounceFetchStatistics')
  },
}
