
import { Action, Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { CreateTask, PlanFeature, PlansConfig, RankTaskMode, ReadTask, UpdateTask } from '@abby/core-legacy'
import PlanningDatePicker from '~/components-legacy/cards/tasks/PlanningDatePicker.vue'
import OpportunitySelect from '~/components-legacy/opportunity/OpportunitySelect.vue'
import { CurrentTimer } from '~/store/timeTracking'

@Component({
  components: { PlanningDatePicker, OpportunitySelect },
})
export default class TaskActionMenu extends Vue {
  @Action('task/removeTask') removeTask!: (task: ReadTask) => Promise<void>;
  @Action('task/updateTask') updateTask!: (value: UpdateTask & { id: string }) => Promise<ReadTask>;
  @Action('task/createTask') createTask!: (value: CreateTask) => Promise<ReadTask>;
  @Action('timeTracking/startTimer') startTimer!: (value: CurrentTimer) => void;
  @Getter('task/tasks') _tasks!: ReadTask[];

  @Prop()
  task!: ReadTask;

  @Prop({ required: false })
  tasks?: ReadTask[];

  get innerTasks () {
    return this.tasks || this._tasks
  }

  @Prop({ required: false })
  rankMode!: RankTaskMode;

  @Prop({ required: false, default: false })
  disableInsert!: boolean | string;

  get _disableInsert () {
    return this.disableInsert || typeof this.disableInsert === 'string'
  }

  @Prop({ required: false, default: false })
  disableDuplicate!: boolean | string;

  get _disableDuplicate () {
    return this.disableDuplicate || typeof this.disableDuplicate === 'string'
  }

  get actions () {
    const actions = [{
      icon: 'move_up',
      text: 'Ajouter une tâche ci-dessus',
      action: () => {
        this.$emit('insert', 'up')
        this.close()
      },
    }, {
      icon: 'move_down',
      text: 'Ajouter une tâche ci-dessous',
      action: () => {
        this.$emit('insert', 'down')
        this.close()
      },
    }, {
      icon: 'edit',
      text: 'Modifier la tâche',
      action: () => {
        this.$emit('edit', this.task)
        this.close()
      },
    }]
    if (this._disableInsert) {
      return actions.slice(-1)
    }
    return actions
  }

  get moreActions () {
    return [{
      icon: 'queue',
      text: 'Dupliquer',
      action: () => this.copyTask(),
    }]
  }

  get priorities () {
    return [
      { color: 'red', value: 1 },
      { color: 'orange', value: 2 },
      { color: 'blue', value: 3 },
      { color: 'black', value: 4 },
      { color: 'grey', value: null },
    ]
  }

  get dueDate () {
    if (!this.task.dueDate) { return null }
    return this.$dayjs(this.task.dueDate).toDate()
  }

  sameDate (value: Date) {
    return this.$dayjs(value).isSame(this.$dayjs(this.task.dueDate), 'day')
  }

  get rangeDate () {
    const ranges = [{
      color: 'green darken-2',
      icon: 'today',
      text: 'Aujourd\'hui',
      date: this.$dayjs().startOf('day').toDate(),
      dateText: this.$dayjs().format('ddd'),
    }, {
      color: 'yellow darken-2',
      icon: 'wb_sunny',
      text: 'Demain',
      date: this.$dayjs().add(1, 'day').startOf('day').toDate(),
      dateText: this.$dayjs().add(1, 'day').format('ddd'),
    }, {
      color: 'blue darken-4',
      icon: 'weekend',
      text: 'Ce week-end',
      date: this.$dayjs().startOf('week').add(5, 'day').startOf('day').toDate(),
      dateText: this.$dayjs().startOf('week').add(5, 'day').format('ddd'),
    }, {
      color: 'deep-purple darken-3',
      icon: 'next_week',
      text: 'Semaine prochaine',
      date: this.$dayjs().startOf('week').add(7, 'day').startOf('day').toDate(),
      dateText: this.$dayjs().startOf('week').add(7, 'day').format('ddd D MMM'),
    }, ...this.task.dueDate
      ? [{
        color: 'blue-grey darken-2',
        icon: 'block',
        text: 'Sans date',
        date: null,
        dateText: '',
      }]
      : []]
    return this.$lodash.uniqBy(ranges, (r: any) => this.$dayjs(r.date).format('DD/MM/YYYY'))
  }

  async deleteTask () {
    try {
      this.close()
      const confirm = await this.$confirmManager.ask({
        icon: 'remove',
        title: 'Supprimer une tâche',
        content: 'Voulez vous vraiment supprimer cette tâche ?',
        confirmText: 'Supprimer',
        confirmButton: { color: 'error', depressed: true },
      })
      if (!confirm) { return }
      await this.removeTask(this.task)
      this.$emit('removed')
      this.$alertsManager.success('Tâche supprimée')
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  close () {
    this.$emit('close')
  }

  async copyTask () {
    try {
      const taskIndex = this.innerTasks.findIndex(t => t.id === this.task.id)
      const task = {
        ...this.$lodash.pick(this.task, ['name', 'description', 'dueDate', 'customerId', 'opportunityId', 'priority']),
        afterRank: this.innerTasks[taskIndex + 1]?.rank || '0|zzzzzz:',
        ...(this.rankMode ? { rankMode: this.rankMode } : {}),
      }
      const newTask = await this.createTask(task)
      this.$emit('duplicated', newTask)
    } catch (error) {
      this.$alertsManager.autoError(error)
    } finally {
      this.close()
    }
  }

  async update (value: UpdateTask) {
    try {
      const result = await this.updateTask({
        id: this.task.id,
        ...value,
        ...(this.rankMode ? { rankMode: this.rankMode } : {}),
      })
      this.$emit('update', result)
      this.close()
    } catch (error) {
      this.$alertsManager.autoError(error)
    }
  }

  startTimeTracking () {
    this.startTimer({ name: this.task.name, opportunity: this.task.opportunity, startDate: new Date() })
    this.$alertsManager.success('Nouveau suivi du temps démarré !')
    this.close()
  }

  async mounted () {
    await this.$store.dispatch('timeTracking/fetchTotalCount')
  }
}
