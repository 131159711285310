import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { normalize } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export type QueryLandingPages = {
  page: number;
  limit: number;
}

export interface LandingPageState {
  landingPages: any[];
  paginationQuery: QueryLandingPages;
  queryLoading: boolean;
  page: any | null;
}

export const state = (): LandingPageState => ({
  landingPages: [],
  paginationQuery: {
    page: 1,
    limit: 20,
  },
  queryLoading: false,
  page: null,
})

export const getters: GetterTree<LandingPageState, RootState> = {
  landingPages: state => state.landingPages,
  paginationQuery: state => state.paginationQuery,
  queryLoading: state => state.queryLoading,
  page: state => state.page,
}

export const mutations: MutationTree<LandingPageState> = {
  SET_LANDING_PAGES (state, values: any[]) {
    state.landingPages = values
  },
  SET_PAGINATION_QUERY (state, query: QueryLandingPages) {
    state.paginationQuery = query
  },
  SET_QUERY_LOADING (state, value: boolean) {
    state.queryLoading = value
  },
  SET_PAGE (state, value: any) {
    state.page = value
  },
}

export const actions: ActionTree<LandingPageState, RootState> = {
  async fetchPageActivities ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const results = await this.$axios.$get('/strapi/landing-activity-pages', {
        params: {
          'pagination[page]': getters.paginationQuery.page,
          'pagination[pageSize]': getters.paginationQuery.limit,
        },
        baseURL: '',
        headers: {
          Authorization: undefined,
        },
      })
      commit('SET_LANDING_PAGES', normalize(results))
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  async fetchPage ({ commit }, value: number) {
    const result = await this.$axios.$get(`/strapi/landing-activity-pages/${value}`, {
      params: {
        populate: 'landing_activity_sections.landing_activity_contents',
      },
      baseURL: '',
      headers: {
        Authorization: undefined,
      },
    })
    const data = normalize(result)
    const sections = normalize(data.landing_activity_sections)
      .map((section: any) => ({
        ...section,
        landing_activity_contents: normalize(section.landing_activity_contents),
      }))
    commit('SET_PAGE', {
      ...data,
      landing_activity_sections: sections,
    })
  },
  updateQuery ({ commit, dispatch }, query: QueryLandingPages) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('fetchPageActivities')
  },
  setPage ({ commit }, value: any) {
    commit('SET_PAGE', value)
  },
}
