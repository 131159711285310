export enum ErrorCode {
  INVALID_CREDS = 'INVALID_CREDS',
  WRONG_ID = 'WRONG_ID',
  WRONG_ID_FORMAT = 'WRONG_ID_FORMAT',
  WRONG_PWD = 'WRONG_PWD',
  WRONG_PWD_FORMAT = 'WRONG_PWD_FORMAT',
  WRONG_PWD2 = 'WRONG_PWD2',
  WRONG_PWD2_FORMAT = 'WRONG_PWD2_FORMAT',
  ACCOUNT_TEMPORARILY_BLOCKED = 'ACCOUNT_TEMPORARILY_BLOCKED',
  ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
  ACCOUNT_BLOCKED_CONTACT_BANK = 'ACCOUNT_BLOCKED_CONTACT_BANK',
  TOS_TO_VALIDATE = 'TOS_TO_VALIDATE',
  CONTRACT_TO_SIGN = 'CONTRACT_TO_SIGN',
  INACTIVE_CREDS = 'INACTIVE_CREDS',
  ACCOUNT_TO_ACTIVATE = 'ACCOUNT_TO_ACTIVATE',
  PHONE_NB_TO_CONFIRM = 'PHONE_NB_TO_CONFIRM',
  INFORMATION_TO_REVIEW = 'INFORMATION_TO_REVIEW',
  SMTH_TO_VALIDATE = 'SMTH_TO_VALIDATE',
  OTP_REGISTRATION_TO_DO = 'OTP_REGISTRATION_TO_DO',
  KYC_TO_FILL = 'KYC_TO_FILL',
  AMLCFT_DATA_TO_CONFIRM = 'AMLCFT_DATA_TO_CONFIRM',
  PHONE_NB_TO_SET = 'PHONE_NB_TO_SET',
  PERSO_DATA_TO_CONFIRM = 'PERSO_DATA_TO_CONFIRM',
  FORM_TO_FILL = 'FORM_TO_FILL',
  READONLY_ACCESS_TO_CREATE = 'READONLY',
  UNLOCK_OTP_IN_APP = 'UNLOCK_OTP_IN_APP',
  UNLOCK_OTP_IN_WEB = 'UNLOCK_OTP_IN_WEB',
  CANNOT_ACCESS_SERVICES = 'CANNOT_ACCESS_SERVICES',
  CREDS_UPDATE_MESSAGE = 'CREDS_UPDATE_MESSAGE',
  CONTACT_BANK = 'CONTACT_BANK',
  EXPIRED_ID_CONTACT_BANK = 'EXPIRED_ID_CONTACT_BANK',
  NO_ACCESS_CONTACT_BANK = 'NO_ACCESS_CONTACT_BANK',
  INACTIVE_CREDS_CONTACT_BANK = 'INACTIVE_CREDS_CONTACT_BANK',
  PWD_RENEWAL = 'PWD_RENEWAL',
  PWD_RENEWAL_FIRST_CO = 'PWD_RENEWAL_FIRST_CO',
  PWD_RENEWAL_READONLY_ACCESS = 'PWD_RENEWAL_READONLY_ACCESS',
  OTP_REQUIRED = 'OTP_REQUIRED',
  SCA_REQUIRED_WEBVIEW = 'SCA_REQUIRED_WEBVIEW',
  OTP_FAILED = 'OTP_FAILED',
  WRONG_OTP = 'WRONG_OTP',
  EXPIRED_DEVICE = 'EXPIRED_DEVICE',
  INVALID_PHONE_NB = 'INVALID_PHONE_NB',
  BLOCKED_LIMIT_REACHED = 'BLOCKED_LIMIT_REACHED',
  BLOCKED_SMS_LIMIT_REACHED = 'BLOCKED_SMS_LIMIT_REACHED',
  CNT_CRASH = 'CNT_CRASH',
  CRASH_AFTER_LOGIN = 'CRASH_AFTER_LOGIN',
  NO_ACCOUNT_FOUND = 'NO_ACCOUNT_FOUND',
  BANK_IS_DOWN = 'BANK_IS_DOWN',
  OTP_PARTIAL = 'OTP_PARTIAL',
  OTP_NOT_SUPPORTED = 'OTP_NOT_SUPPORTED',
  PRO_NOT_SUPPORTED = 'PRO_NOT_SUPPORTED',
  PART_IN_PRO_CNT = 'PART_IN_PRO_CNT',
  WRONG_COUNTRY_OR_AREA = 'WRONG_COUNTRY_OR_AREA',
  MIGRATION_TO_NEW_BANK = 'MIGRATION_TO_NEW_BANK',
  NO_ACCESS_TO_ACCOUNT = 'NO_ACCESS_TO_ACCOUNT',
  SUSPENDED_ACCESS = 'SUSPENDED_ACCESS',
  CLOSED_ACCOUNT = 'CLOSED_ACCOUNT',
  NO_ONLINE_ACCESS = 'NO_ONLINE_ACCESS',
  PRO_DATA_TO_CONFIRM = 'PRO_DATA_TO_CONFIRM',
}