import { Middleware } from '@nuxt/types'

// const maintenanceStart = '2024-01-17T19:00'
// const maintenanceEnd = '2024-01-17T23:00'

const maintenanceStart = '2024-03-20T19:00'
const maintenanceEnd = '2024-03-21T02:00'

const maintenance: Middleware = ({ redirect, $dayjs, route, $config }) => {
  const isMaintenanceWindow = $dayjs().isBetween($dayjs(maintenanceStart), $dayjs(maintenanceEnd))
  const currentlyOnMaintenance = route.path.includes('/maintenance')
  // if (!currentlyOnMaintenance && isMaintenanceWindow && $config.nodeEnv === 'production') {
  //   redirect('/maintenance')
  // }
}

export default maintenance
