import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { ReadTimeRecord } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface TimeTrackingSelectionState {
  isActivated: boolean
  timeRecords: ReadTimeRecord[],
}

export const state = (): TimeTrackingSelectionState => ({
  timeRecords: [],
  isActivated: false,
})

export const getters: GetterTree<TimeTrackingSelectionState, RootState> = {
  isActivated: state => state.isActivated,
  timeRecords: state => state.timeRecords,
}

export const mutations: MutationTree<TimeTrackingSelectionState> = {
  SET_IS_SELECTION_MODE_ACTIVATED (state: TimeTrackingSelectionState, value: boolean) {
    state.isActivated = value
  },
  TOGGLE_FROM_SELECTION (state: TimeTrackingSelectionState, timeRecord: ReadTimeRecord) {
    const index = state.timeRecords.findIndex(t => t.id === timeRecord.id)
    if (index === -1) {
      state.timeRecords.push(timeRecord)
    } else {
      state.timeRecords.splice(index, 1)
    }
  },
  CLEAN_SELECTION (state: TimeTrackingSelectionState) {
    state.timeRecords = []
  },
  RESET (_currentState: TimeTrackingSelectionState) {
    const newState = state()
    _currentState = Object.assign(state, newState)
  },
}

export const actions: ActionTree<TimeTrackingSelectionState, RootState> = {
  toggle ({ commit }, timeRecord: ReadTimeRecord) {
    commit('TOGGLE_FROM_SELECTION', timeRecord)
  },
  async delete ({ commit, state }) {
    const ids = state.timeRecords.map(t => t.id)
    await this.$api.timeTracking.bulkDelete(ids)
    ids.forEach((id) => {
      commit('timeTracking/DELETE_TIME_RECORD', id, { root: true })
    })
  },
  activeSelectionMode ({ commit }, timeRecord?: ReadTimeRecord) {
    commit('SET_IS_SELECTION_MODE_ACTIVATED', true)
    if (timeRecord) {
      commit('TOGGLE_FROM_SELECTION', timeRecord)
    }
  },
  turnOffSelectionMode ({ commit }) {
    commit('SET_IS_SELECTION_MODE_ACTIVATED', false)
    commit('CLEAN_SELECTION')
  },
  async createInvoice ({ dispatch, state }) {
    await dispatch('timeTracking/createInvoiceFromTimeRecords', state.timeRecords, { root: true })
  },
  async update () {

  },
}
