export enum FacebookPixelEvent {

  // commercial offer interest
  SERVER_COMMERCIAL_OFFER_INTEREST = 'server_commercial_offer_interest',
  // SERVER_LUKO_INTEREST = 'server_luko_interest',
  // SERVER_SHINE_INTEREST = 'server_shine_interest',
  // SERVER_ASSURUP_INTEREST = 'server_assurup_interest',
  // SERVER_MOBEYE_INTEREST = 'server_mobeye_interest',
  // SERVER_SIMPLIS_INTEREST = 'server_simplis_interest',
  // SERVER_SITE_W_INTEREST = 'server_site_w_interest',
  // SERVER_KANDBAZ_INTEREST = 'server_kandbaz_interest',
  // SERVER_CARAVEL_INTEREST = 'server_caravel_interest',
  // SERVER_FORMATIONS_INTEREST = 'server_formations_interest',
  // SERVER_FORMATION_CLIENT = 'server_formation_client_interest',
  // SERVER_FORMATION_COMMUNIQUER = 'server_formation_communiquer_interest',
  // SERVER_FORMATION_CLIENT_COMMUNIQUER_DISCOUNT = 'server_formation_client_communiquer_discount',
  // SERVER_EDIT_ENTREPRISE_INTEREST = 'server_edit_entreprise_interest',

  SERVER_MEETING_WITH_AN_EXPERT_INTEREST = 'server_meeting_with_an_expert_interest',
  SERVER_TRANSACTION_ANNOTATED = 'server_transaction_annotated',
  SERVER_COMPLETE_REGISTRATION = 'CompleteRegistration',
  SERVER_USE_STRIPE_PAYMENT = 'server_use_stripe_payment',
  SERVER_SEND_BILLING_DOCUMENT_BY_EMAIL = 'server_send_billing_document_by_email',
  SERVER_BILLING_DOCUMENT_CREATED = 'server_billing_document_created',
  SERVER_BANK_SYNCHRONIZED = 'server_bank_synchronized',
  SERVER_BANK_ACCOUNT_USED = 'server_bank_account_used',
  SERVER_BILLING_CONFIGURATION_UPDATED = 'server_billing_configuration_updated',
  SERVER_GENERATED_CERFA_CFE = 'server_generated_cerfa_cfe',
  SERVER_GENERATED_CERFA_ACRE = 'server_generated_cerfa_acre',
  SERVER_PURCHASE = 'Purchase',
  SERVER_ADD_INCOME_BOOK_ITEM = 'server_add_income_book_item',
  SERVER_DOWNLOAD_INCOME_BOOK = 'server_download_income_book',
  SERVER_ADD_PURCHASE_REGISTER_ITEM = 'server_add_purchase_register_item',
  SERVER_DOWNLOAD_PURCHASE_REGISTER_BOOK = 'server_download_purchase_register_book',
  SERVER_READ_DECLARATIONS_TOTALS = 'server_read_declarations_totals',
  SERVER_ADD_CUSTOMER = 'server_add_customer',
  SERVER_ADD_PRODUCT = 'server_add_product',
  SERVER_PAYMENT_INTENT = 'server_payment_intent',
  SERVER_START_TRIAL = 'StartTrial',
  SERVER_EXTEND_TRIAL = 'ExtendTrial',
  SERVER_SUBSCRIBE = 'Subscribe',
}
