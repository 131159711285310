import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { ReadWebhook, UpdateWebhook } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface WebhookState {
  webhooks: ReadWebhook[] | null;
  webhook: ReadWebhook | null;
  webhookLoading: boolean;
  queryLoading: boolean;
  isWebhookModalOpened: boolean;
}

export const state = (): WebhookState => ({
  webhooks: null,
  webhook: null,
  queryLoading: true,
  webhookLoading: true,
  isWebhookModalOpened: false,
})

export const getters: GetterTree<WebhookState, RootState> = {
  webhook: state => state.webhook,
  webhooks: state => state.webhooks,
  queryLoading: state => state.queryLoading,
  webhookLoading: state => state.webhookLoading,
  isWebhookModalOpened: state => state.isWebhookModalOpened,
}

export const mutations: MutationTree<WebhookState> = {
  SET_WEBHOOKS (state: WebhookState, value: ReadWebhook[]) {
    state.webhooks = value
  },
  SET_WEBHOOK (state: WebhookState, value: ReadWebhook) {
    state.webhook = value
  },
  SET_WEBHOOK_LOADING (state: WebhookState, value: boolean) {
    state.webhookLoading = value
  },
  SET_WEBHOOK_MODAL (state: WebhookState, value: boolean) {
    state.isWebhookModalOpened = value
  },
  RESET (_currentState: WebhookState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_QUERY_LOADING (state: WebhookState, value: boolean) {
    state.queryLoading = value
  },
}

export const actions: ActionTree<WebhookState, RootState> = {
  setWebhookModal ({ commit }, value: boolean) {
    commit('SET_WEBHOOK_MODAL', value)
  },
  async fetchWebhook ({ commit }, id: string) {
    commit('SET_WEBHOOK_LOADING', true)
    try {
      const result = await this.$api.webhook.get(id)
      commit('SET_WEBHOOK', result)
    } finally {
      commit('SET_WEBHOOK_LOADING', false)
    }
  },
  async updateWebhook ({ commit, state }, payload: UpdateWebhook) {
    if (!state.webhook) {
      return
    }
    commit('SET_WEBHOOK_LOADING', true)
    try {
      const result = await this.$api.webhook.update(state.webhook?._id, payload)
      commit('SET_WEBHOOK', result)
    } finally {
      commit('SET_WEBHOOK_LOADING', false)
    }
  },
  async fetchWebhooks ({ commit }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const results = await this.$api.webhook.getAll()
      commit('SET_WEBHOOKS', results)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
}
