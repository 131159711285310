
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class TaskPrioritySelect extends Vue {
  priorities = [
    { color: 'red', value: 1 },
    { color: 'orange', value: 2 },
    { color: 'blue', value: 3 },
    { color: 'grey', value: 4 },
    { color: 'none', value: null },
  ]

  isMenuOpened = false

  get hasActivatorSlot () {
    return this.$scopedSlots.activator
  }

  @ModelSync('value', 'input')
  innerValue!: number;

  @Prop({ required: false, default: false })
  dense!: boolean | string;

  setPriority (value: number) {
    this.$emit('input', value)
    this.isMenuOpened = false
  }

  get color () {
    return this.priorities[this.innerValue - 1]?.color || 'grey'
  }

  get _dense () {
    return this.dense || typeof this.dense === 'string'
  }
}
