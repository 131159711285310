import { ReadStockMovement, ReadStockMovementPage } from '@abby/shared'
import { StockMovementsPageItem } from '~/services/stock/StockMovementsPageItem.entity'
import { StockMovementItemMapper } from '~/services/stock/StockMovementItem.mapper'

export class StockMovementsPageMapper {
  static fromHttp (stockMovementsPage: ReadStockMovementPage): StockMovementsPageItem {
    return StockMovementsPageItem.create({
      countWithoutFilters: stockMovementsPage.countWithoutFilters,
      totalDocs: stockMovementsPage.totalDocs,
      limit: stockMovementsPage.limit,
      totalPages: stockMovementsPage.totalPages,
      hasNextPage: stockMovementsPage.hasNextPage,
      hasPrevPage: stockMovementsPage.hasPrevPage,
      nextPage: stockMovementsPage.nextPage,
      page: stockMovementsPage.page,
      prevPage: stockMovementsPage.prevPage,
      docs: stockMovementsPage.docs.map((stockMovement: ReadStockMovement) => StockMovementItemMapper.fromHttp(stockMovement)),
    })
  }
}
