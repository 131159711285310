export enum RequestStatus {
  ALL = 'ALL',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface QueryApiLogs {
  page: number;

  limit: number;

  status: RequestStatus;

  search?: string | null;

  interval?: string[];
}
