import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { CreationFormStep } from '@abby/core-legacy'
import type { CreationForm } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface CreationFormState {
  currentStep?: CreationFormStep | null;
  form?: CreationForm | null;
  steps: any;
  isCreationFormModalOpened: boolean;
}

export const state = (): CreationFormState => ({
  currentStep: undefined,
  form: undefined,
  steps: undefined,
  isCreationFormModalOpened: false,
})

export const getters: GetterTree<CreationFormState, RootState> = {
  currentStep: state => state.currentStep,
  form: state => state.form,
  steps: state => state.steps,
  isCreationFormModalOpened: state => state.isCreationFormModalOpened,
}

export const mutations: MutationTree<CreationFormState> = {
  SET_CURRENT_STEP (state, value) {
    state.currentStep = value
  },
  SET_STEPS (state, value: CreationFormStep | null | undefined) {
    state.steps = value
  },
  SET_FORM (state, value: CreationForm | null | undefined) {
    state.form = value
  },
  SET_IS_CREATION_FORM_MODAL_OPENED (state, value: boolean) {
    state.isCreationFormModalOpened = value
  },
  RESET (_currentState: CreationFormState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<CreationFormState, CreationFormState> = {
  async fetch ({ commit }, { companyId }: { companyId?: string }) {
    const result = await this.$api.creationForm.get(companyId)
    commit('SET_FORM', result?.form)
    commit('SET_STEPS', result?.steps)
    commit('SET_CURRENT_STEP', result?.currentStep)
  },
  async updateStep ({ commit }, { companyId, currentStep }: { companyId?: string, currentStep: CreationFormStep }) {
    const result = await this.$api.creationForm.update({ currentStep }, companyId)
    commit('SET_CURRENT_STEP', result?.currentStep)
  },
  async updateForm ({ commit }, { stepToStart, stepFinished, form, companyId, userId }: { stepToStart: CreationFormStep, stepFinished: CreationFormStep, form: CreationForm, companyId?: string, userId: string }) {
    const result = await this.$api.creationForm.update({
      ...(form ? { form } : {}),
      ...(stepToStart ? { stepToStart } : {}),
      ...(stepFinished ? { stepFinished } : {}),
      ...(userId ? { userId } : {}),
      currentStep: stepToStart,
    }, companyId)
    commit('SET_CURRENT_STEP', result?.currentStep)
    commit('SET_FORM', result?.form)
    commit('SET_STEPS', result?.steps)
  },
}
