
import { Component, ModelSync, Vue, Watch } from 'nuxt-property-decorator'

@Component({})
export default class PlanningDatePicker extends Vue {
  @ModelSync('value', 'input')
  innerValue!: Date | null

  get hasActivatorSlot () {
    return this.$scopedSlots.activator
  }

  get rangeDate () {
    const ranges = [{
      color: 'green darken-2',
      icon: 'calendar_today',
      text: 'Aujourd\'hui',
      date: this.$dayjs().startOf('day').toDate(),
      dateText: this.$dayjs().format('ddd'),
    }, {
      color: 'yellow darken-2',
      icon: 'wb_sunny',
      text: 'Demain',
      date: this.$dayjs().add(1, 'day').startOf('day').toDate(),
      dateText: this.$dayjs().add(1, 'day').format('ddd'),
    }, {
      color: 'blue darken-4',
      icon: 'weekend',
      text: 'Ce week-end',
      date: this.$dayjs().startOf('week').add(5, 'day').startOf('day').toDate(),
      dateText: this.$dayjs().startOf('week').add(5, 'day').format('ddd'),
    }, {
      color: 'deep-purple darken-3',
      icon: 'next_week',
      text: 'Semaine prochaine',
      date: this.$dayjs().startOf('week').add(7, 'day').startOf('day').toDate(),
      dateText: this.$dayjs().startOf('week').add(7, 'day').format('ddd D MMM'),
    }, {
      color: 'blue-grey darken-2',
      icon: 'block',
      text: 'Sans date',
      date: null,
      dateText: '',
    }]
    return this.$lodash.uniqBy(ranges, (r: any) => this.$dayjs(r.date).format('DD/MM/YYYY'))
  }

  fieldDateText = ''
  pickerDate = ''
  selectableDate = ''
  _selectableTemporaryTime = ''
  selectableTimeError = false
  isDateBeforeToday = false

  isMenuTimeOpened = false
  isMenuDateOpened = false
  displayTime = false

  @Watch('innerValue')
  innerValueHandler (value: Date) {
    if (value) {
      const selectableDate = this.$dayjs(value)
      const displayTime = selectableDate.get('hour') !== 0 || selectableDate.get('minute') !== 0
      this.fieldDate = `${selectableDate.format('DD/MM/YYYY')}${displayTime ? ` ${selectableDate.format('HH:mm')}` : ''}`
    }
  }

  mounted () {
    if (this.innerValue) {
      this.innerValueHandler(this.innerValue)
    }
  }

  get isSubmitTimeDisabled () {
    return this.selectableTimeError
  }

  get innerValueText () {
    if (!this.innerValue) {
      return {
        text: 'Planifier',
        color: 'grey',
      }
    }
    const date = this.$dayjs(this.innerValue)
    let result
    if (date.isToday()) {
      result = {
        text: 'Aujourd\'hui',
        color: 'primary',
      }
    } else if (date.startOf('week').isSame(this.$dayjs().startOf('week'))) {
      result = {
        text: date.format('dddd'),
        color: 'purple',
      }
    } else {
      result = {
        color: 'grey',
        text: date.format('ddd D MMMM'),
      }
    }

    const hasTime = date.get('hour') !== 0 || date.get('minute') !== 0
    return {
      ...result,
      ...(hasTime ? { text: `${result.text} ${date.format('HH:mm')}` } : {}),
    }
  }

  allowedDateFormats = [
    'DD MM',
    'D MM',
    'D MMM',
    'D MMMM',
    'dddd',
    'D MM YYYY',
    'D MMM YYYY',
    'D MMMM YYYY',
    'DD MM YYYY',
    'DD MM YYYY',
    'DD MMM',
    'DD MMM YYYY',
    'DD MMMM',
    'DD MMMM YYYY',
    'DD/MM/YYYY',
    'D/M/YYYY',
    'DD/M/YYYY',
    'DD/M/YY',
    'D/MM/YYYY',
    'D/MM/YY',
    'DD/MM/YY',
    'D/M/YY',
    'D/M',
    'D/MM',
    'DD/M',
    'DD/MM']

  allowedTimeFormats = [
    'HH:mm',
    'HH[h]mm',
    'HH[h]',
    'H[h]',
  ]

  get allowedFormats () {
    const combined = this.allowedDateFormats.reduce((acc: string[], d: string) => {
      return [...acc, ...this.allowedTimeFormats.map(t => `${d} ${t}`)]
    }, [])
    return [
      ...this.allowedDateFormats,
      ...this.allowedTimeFormats,
      ...combined,
    ]
  }

  get selectableTemporaryTime () {
    return this._selectableTemporaryTime
  }

  set selectableTemporaryTime (value: string) {
    if (!value) {
      this.selectableTimeError = false
      this._selectableTemporaryTime = value
      return
    }
    try {
      this.selectableTimeError = false
      const selectableTime = this.$dayjs(value, this.allowedTimeFormats, 'fr', true)
      this.selectableTimeError = !selectableTime.isValid()
      this._selectableTemporaryTime = value
    } catch (e) {
      this.selectableTimeError = true
    }
  }

  submitSelectTime () {
    if (this.isSubmitTimeDisabled) { return }
    let dateValue
    if (!this._selectableTemporaryTime && this.fieldDate) {
      dateValue = this.$dayjs(this.fieldDate, this.allowedFormats, 'fr', true).format('DD/MM/YYYY')
      this.displayTime = false
    } else {
      const value = this.$dayjs(this._selectableTemporaryTime, this.allowedTimeFormats, 'fr', true)
      dateValue = `${(this.fieldDate ? this.$dayjs(this.fieldDate, this.allowedFormats, 'fr', true) : this.$dayjs()).format('DD/MM/YYYY')} ${value.format('HH:mm')}`
      this.displayTime = true
    }

    this.fieldDate = dateValue
    this.fieldDateText = dateValue
    this.isMenuTimeOpened = false
  }

  get fieldDate () {
    return this.fieldDateText
  }

  set fieldDate (value: string) {
    try {
      const selectableDate = this.$dayjs(value, this.allowedFormats, 'fr', true)
      this.isDateBeforeToday = selectableDate.isBefore(this.$dayjs().startOf('day'), 'day')
      this.selectableDate = selectableDate.toISOString()
      this.displayTime = selectableDate.get('hour') !== 0 || selectableDate.get('minute') !== 0
    } catch {
      this.selectableDate = ''
    }
    this.fieldDateText = value
  }

  selectDatePicker (value: string) {
    if (this.displayTime) {
      const selectableDate = this.$dayjs(this.selectableDate)
      this.fieldDate = this.$dayjs(value).set('hour', selectableDate.get('hour')).set('minute', selectableDate.get('minute')).format('DD/MM/YYYY HH:mm')
    } else {
      this.selectDate(this.$dayjs(value).toISOString())
    }
  }

  selectDate (value: string | null) {
    this.$emit('input', value === null ? null : this.$dayjs(value).toDate())
    this.close()
  }

  close () {
    this.fieldDateText = ''
    this.pickerDate = ''
    this.selectableDate = ''
    this._selectableTemporaryTime = ''
    this.selectableTimeError = false
    this.isDateBeforeToday = false

    this.isMenuTimeOpened = false
    this.isMenuDateOpened = false
    this.displayTime = false
  }

  clearDate () {
    this.selectableDate = ''
    this.displayTime = false
  }
}
