import type {
  BasePaginateMongo,
  CreatePurchaseRegisterItemLegacy, IIncomeBookItem, QueryDownloadPurchaseRegisterLegacy,
  QueryPurchaseRegister, QueryPurchaseRegisterStatisticsLegacy,
  ReadPurchaseRegisterItemLegacy, UpdatePurchaseRegisterItemLegacy,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class PurchaseRegisterLegacy extends Resource {
  getPaginated (params: QueryPurchaseRegister): Promise<BasePaginateMongo<IIncomeBookItem>> {
    return this.axios.$get('/purchaseRegister/items', { params })
  }

  createItem (payload: CreatePurchaseRegisterItemLegacy): Promise<ReadPurchaseRegisterItemLegacy> {
    return this.axios.$post('/purchaseRegister', payload)
  }

  deleteItem (id: string) {
    return this.axios.$delete(`/purchaseRegister/${id}`)
  }

  updateItem (payload: UpdatePurchaseRegisterItemLegacy): Promise<ReadPurchaseRegisterItemLegacy> {
    return this.axios.$put(`/purchaseRegister/${payload._id}`, payload)
  }

  downloadBook (params: QueryDownloadPurchaseRegisterLegacy) {
    return this.axios.get('/purchaseRegister/download', { params, responseType: 'blob' })
  }

  getStatistics (params: QueryPurchaseRegisterStatisticsLegacy): Promise<number> {
    return this.axios.$get('/purchaseRegister/statistics', { params })
  }
}
