export enum ReminderFrequency {
  ON_DUE_DATE = 'on_due_date',
  EVERY_WEEK = 'every_week',
}

export const MapReminderFrequencyToLegacy = {
  [ReminderFrequency.ON_DUE_DATE]: 1,
  [ReminderFrequency.EVERY_WEEK]: 2,
};

export const MapLegacyReminderFrequencyToReminderFrequency = {
  1: ReminderFrequency.ON_DUE_DATE,
  2: ReminderFrequency.EVERY_WEEK,
};