import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { QueryNova, ReadEma } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface NovaState {
  ema: ReadEma | null,
  loading: boolean,
  query: QueryNova
}

export const state = (): NovaState => ({
  ema: null,
  loading: false,
  query: {},
})

export const getters: GetterTree<NovaState, RootState> = {
  ema: (state: NovaState) => state.ema,
  loading: (state: NovaState) => state.loading,
  query: (state: NovaState) => state.query,
}

export const mutations: MutationTree<NovaState> = {
  SET_EMA (state, value: ReadEma) {
    state.ema = value
  },
  SET_LOADING (state, value: boolean) {
    state.loading = value
  },
  SET_QUERY (state, value: QueryNova) {
    state.query = value
  },
  RESET (_currentState: NovaState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<NovaState, RootState> = {
  async fetchNovaEma ({ commit }, params: QueryNova) {
    commit('SET_LOADING', true)
    try {
      const data = await this.$api.billing.fetchNovaEma(params)
      commit('SET_EMA', data)
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  setEma ({ commit }, value: ReadEma) {
    commit('SET_EMA', value)
  },

  setQuery ({ commit }, value: QueryNova) {
    commit('SET_QUERY', value)
  },
}
