import { GuideStepItem } from '@abby/core-legacy'
import type { IGuideStep } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class GuideStep extends Resource {
  getAll (): Promise<IGuideStep[]> {
    return this.axios.$get('/guideStep')
  }

  completeStep (step: GuideStepItem, params: { skip?: boolean }): Promise<IGuideStep> {
    return this.axios.$post('/guideStep/completeStep', { step, skip: params.skip })
  }
}
