import { Context, Plugin } from '@nuxt/types'
import { CampaignConfig, CampaignType, ReadCampaigns } from '@abby/core-legacy'
import isEqual from 'lodash/isEqual'
import { CampaignsState } from '~/store/campaigns'

export enum CampaignTrigger {
  CLICKS = 'clicks',
  VIEWS = 'views',
  DELAY = 'delay',
  INACTIVITY_DELAY = 'inactivity_delay',
  SCROLL = 'scroll',
  INSTANT = 'instant',
}

export interface CampaignTriggerConfig {
  type: CampaignType,
  trigger: CampaignTrigger,
  action: () => void,
  [CampaignTrigger.CLICKS]?: number // nombre de clics
  [CampaignTrigger.VIEWS]?: number // nombre de page vue
  [CampaignTrigger.DELAY]?: number // timeout en milliseconds
  [CampaignTrigger.INACTIVITY_DELAY]?: number // timeout en milliseconds
  [CampaignTrigger.SCROLL]?: number // total of pixel scrolled
  priority?: number
  maximumDisplayFrequency?: number // en minutes (1440 minutes = 1 jour)
}

export interface CampaignsManagerInstance {
  register: (campaignTrigger: CampaignTriggerConfig) => void;
  count: (trigger: CampaignTrigger, value?: number) => void;
  start: () => Promise<void>;
  display: (campaign: CampaignTriggerConfig) => void;
  doNotDisplayAnymore: (campaign: CampaignType) => Promise<void>;
  updateMeta: (campaign: CampaignType, meta: CampaignConfig['meta']) => Promise<void>;
  meta: (campaign: CampaignType) => CampaignConfig['meta'];
  isDisplayable: (campaign: CampaignType, options?: { trigger?: CampaignTrigger, maximumDisplayFrequency?: number }) => boolean;
  reset(camapign: CampaignType): Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CampaignsManagerPlugin: Plugin = ({ store, app, $site, $config, $dayjs }: Context, inject) => {
  const campaignsQueue = (): CampaignTriggerConfig[] => store.getters['campaigns/campaignsQueue']
  const counter = (): CampaignsState['counter'] => store.getters['campaigns/counter']
  const campaigns = (): CampaignsState['campaigns'] => store.getters['campaigns/campaigns']
  const loaded = (): CampaignsState['loaded'] => store.getters['campaigns/loaded']

  // const company = (): ICompany => store.getters['company/company']
  // const creationSteps = (): CreationCompanySteps => store.getters['company/creation']?.steps || {}

  const display = (campaign: CampaignTriggerConfig) => {
    // IL FAUT ABSOLUMENT METTRE UN PRIORITY: 1 DANS LE PARAMETRE SINON CA MARCHE PAS POUR L'INSTANT
    if (!campaignsQueue().find((c) => { return isEqual({ ...c, priority: c.priority || 0 }, { ...campaign, priority: campaign.priority || 0 }) })) { return }
    if (campaigns()?.campaigns?.[campaign.type]?.doNotConsiderAnymoreAt) {
      store.commit('campaigns/REMOVE_CAMPAIGN_IN_QUEUE', campaign)
      return
    }
    if (campaign.maximumDisplayFrequency) {
      const lastDisplay = process.browser ? localStorage.getItem(`${campaign.type}-${campaign.trigger}`) : null
      if (process.browser && (!lastDisplay || $dayjs(lastDisplay).add(campaign.maximumDisplayFrequency, 'm').diff($dayjs()) <= 0)) {
        campaign.action()
        localStorage.setItem(`${campaign.type}-${campaign.trigger}`, $dayjs().toString())
        store.commit('campaigns/REMOVE_CAMPAIGN_IN_QUEUE', campaign)
      }
    } else {
      campaign.action()
      store.commit('campaigns/REMOVE_CAMPAIGN_IN_QUEUE', campaign)
    }
  }

  const manager = {
    register: (campaignTrigger: CampaignTriggerConfig) => {
      store.commit('campaigns/PUSH_CAMPAIGN_IN_QUEUE', { priority: 0, ...campaignTrigger })
      if (campaignTrigger.trigger === CampaignTrigger.INSTANT) {
        setTimeout(() => {
          display(campaignTrigger)
        }, 1_000)
      }
    },
    display,
    count: (trigger: keyof CampaignsState['counter'], value?: number): void => {
      store.commit('campaigns/INCREMENT_COUNTER', { counter: trigger, value: value || 1 })
      const campaign = campaignsQueue().find(c => c.trigger === trigger)
      const _counter = campaign?.[trigger] || 0
      if (campaign && counter()[trigger] >= _counter) {
        display(campaign)
      }
    },
    doNotDisplayAnymore: async (campaign: CampaignType): Promise<void> => {
      await store.dispatch('campaigns/updateCampaigns', {
        campaign,
        campaignConfig: {
          doNotConsiderAnymoreAt: new Date(),
        },
      })
    },
    reset: async (campaign: CampaignType): Promise<void> => {
      const _campaign: ReadCampaigns['campaigns'] | null = campaigns()?.campaigns || null
      await store.dispatch('campaigns/updateCampaigns', {
        campaign,
        campaignConfig: {},
      })
    },
    updateMeta: async (campaign: CampaignType, meta?: CampaignConfig['meta']): Promise<void> => {
      const _campaign: ReadCampaigns['campaigns'] | null = campaigns()?.campaigns || null
      await store.dispatch('campaigns/updateCampaigns', {
        campaign,
        campaignConfig: {
          doNotConsiderAnymoreAt: _campaign?.[campaign]?.doNotConsiderAnymoreAt,
          meta,
        },
      })
    },
    meta: (campaign: CampaignType) => {
      const _campaigns: ReadCampaigns['campaigns'] | null = campaigns()?.campaigns || null
      return _campaigns?.[campaign]?.meta
    },
    hide: async (campaign: CampaignType): Promise<void> => {
      await store.dispatch('campaigns/updateCampaigns', {
        campaign,
        campaignConfig: {
          doNotConsiderAnymoreAt: new Date(),
        },
      })
    },
  }

  inject('campaignsManager', {
    ...manager,
    async start () {
      if (campaigns()) {
        return
      }
      await store.dispatch('campaigns/fetchCampaigns')
    },
    isDisplayable (campaign: CampaignType, options?: { trigger?: CampaignTrigger, maximumDisplayFrequency?: number }): boolean {
      if (!loaded()) { return false }
      const _campaigns: ReadCampaigns['campaigns'] | null = campaigns()?.campaigns || null
      if (_campaigns?.[campaign]?.doNotConsiderAnymoreAt) {
        return false
      }
      if (options?.trigger && options?.maximumDisplayFrequency) {
        const lastDisplay = process.browser ? localStorage.getItem(`${campaign}-${options.trigger}`) : null
        return process.browser && (!lastDisplay || $dayjs(lastDisplay).add(options.maximumDisplayFrequency, 'm').diff($dayjs()) <= 0)
      }
      return true
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $campaignsManager: CampaignsManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $campaignsManager: CampaignsManagerInstance
  }
  interface Context {
    $campaignsManager: CampaignsManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $campaignsManager: CampaignsManagerInstance
  }
}

export default CampaignsManagerPlugin
