import { OrganizationAction } from '~/logic/contexts/client/domain/enums/organizationAction.enum'

export type OrganizationItemProps = {
  id: string
  name: string
  countContacts?: number
  hasBillings: boolean
  test: boolean
  createdAt: Date
  archivedAt?: Date
}

export class OrganizationItem {
  readonly id: string
  readonly name: string
  readonly countContacts?: number
  readonly hasBillings: boolean
  readonly test: boolean
  readonly createdAt: Date
  readonly archivedAt?: Date

  actions: OrganizationAction[] = [];

  private constructor (props: OrganizationItemProps) {
    this.id = props.id
    this.name = props.name
    this.countContacts = props.countContacts
    this.hasBillings = props.hasBillings
    this.test = props.test
    this.createdAt = props.createdAt
    this.archivedAt = props.archivedAt

    this.computeActions()
  }

  public static create (props: OrganizationItemProps): OrganizationItem {
    return new OrganizationItem(props)
  }

  computeActions () {
    this.actions.push(
      OrganizationAction.EDIT,
      OrganizationAction.ARCHIVE,
      OrganizationAction.UNARCHIVE,
      OrganizationAction.DELETE,
      OrganizationAction.VIEW_DETAILS,
      OrganizationAction.ADMIN_COPY_ORGANIZATION_ID,
      OrganizationAction.CREATE_INVOICE,
      OrganizationAction.CREATE_ESTIMATE,
      OrganizationAction.CREATE_ASSET,
      OrganizationAction.CREATE_PURCHASE_ORDER,
      OrganizationAction.GO_TO_CLIENT_PORTAL,
      OrganizationAction.EDIT_NOTES,
    )
  }
}
