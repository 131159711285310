export enum AcreEligibilityStep {
  HAS_ALREADY_HAD_ACRE = 'has_already_had_acre',
  IS_ASKING_FOR_A_JOB = 'is_asking_for_a_job',
  IS_COMPENSATED = 'is_compensated',
  IS_REGISTERED_IN_POLE_EMPLOI = 'is_registered_in_pole_emploi',
  HAS_RSA = 'has_rsa',
  HAS_ASS = 'has_ass',
  IS_BETWEEN_18_AND_25 = 'is_between_18_and_25',
  HAS_CAPE = 'has_cape',
  IS_IN_QPV = 'is_in_qpv',
  IS_DISABLED_AND_LESS_THAN_30 = 'is_disabled_and_less_than_30',
  IS_NOT_COMPENSATED_AND_LESS_THAN_30 = 'is_not_compensated_and_less_than_30',
  HAS_PREPARE = 'has_prepare',
  EMPLOYEE_OR_DISMISSED = 'employee_or_dismissed',
}
