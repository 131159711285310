export enum Features {
  REGISTER_GESTION = 'registerGestion',
  REGISTER_CREATION = 'registerCreation',
  FORCE_BANKING_SYNCHRONIZE_CREATION = 'forceBankingSynchronizeCreation',
  FORCE_BANKING_SYNCHRONIZE_GESTION = 'forceBankingSynchronizeGestion',
  ENABLE_BILLING = 'enableBilling',
  ENABLE_DOCUMENT = 'enableDocument',
  ENABLE_DECLARATION = 'enableDeclaration',
  ENABLE_URSSAF = 'enableUrssaf',
  ENABLE_ACCOUNTING_DOCUMENTS = 'enableAccountingDocuments',
  ENABLE_PERSONAL_DOCUMENTS = 'enablePersonalDocuments',
  ENABLE_PAYMENT_LINK = 'enablePaymentLink',
  ENABLE_ABBY_STORE = 'enableAbbyStore',
  ENABLE_ABBY_ACADEMY = 'enableAbbyAcademy',
  ENABLE_INTEGRATIONS = 'enableIntegrations',
}
