import { BillingType } from '@abby/shared'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'

export const useOpenDuplicateBillingModal = () => {
  const billingStore = useBillingStore()

  return async (billing: BillingItem, billingType: BillingType) => {
    return await billingStore.openDuplicateBillingModal(billing.id, billingType)
  }
}
