export enum OnboardingStepsCreation {
  ACTIVITY = 'activity',
  PERSONAL = 'personal',
  DECLARATION = 'declaration',
  SIGNUP = 'signup',
  INFORMATIONS = 'informations',
  EXIT = 'exit',
}

export enum OnboardingStepsGestion {
  COMPANY = 'company',
  BILLING = 'billing',
  ACTIVITY = 'activity',
  SIGNUP = 'signup',
  ACCOUNTING = 'accounting',
  CONTACT = 'contact',
  WEBINAR = 'webinar',
  SPONSORSHIP = 'sponsorship',
  IMPORT = 'import',
  ORIGIN = 'origin',
}

export enum RegisterStepGestion {
  CHOOSE = 'choose',
  LEGAL_STATUS = 'legal_status',
  SIGNUP = 'signup',
  CUSTOM_EXPERIENCE = 'custom_experience',
}

export enum Register2StepGestion {

  SIGNUP = 'signup',
  ACTIVITY = 'activity',
}

export enum Register3StepGestion {

  SIGNUP = 'signup',
  INFORMATION = 'information',
  MAIN_FEATURE = 'main_feature',
  LEGAL_STATUS = 'legal_status',
  ACTIVITY = 'activity',
}
