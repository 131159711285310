export enum UrssafError {
  ERREUR_DATE_NAISSANCE_INVALIDE = 'urssaf_tp.birth_date_invalid',
  ERR_DATE_NAISSANCE_FUTUR = 'urssaf_tp.birth_date_futur',
  ERR_COORDONNEES_BANCAIRES = 'urssaf_tp.bank_account_invalid',
  ERREUR_VALIDATION = 'urssaf_tp.validation',
  ERR_DGFIP_R2P_INCONNU = 'urssaf_tp.dgfip_particulier_inconnu',
  ERR_LIEU_NAISSANCE_DEPT = 'urssaf_tp.lieu_naissance_dept',
  ERR_LIEU_NAISSANCE_COMN = 'urssaf_tp.lieu_naissance_comn',
  ERR_LIEU_NAISSANCE_PAYS = 'urssaf_tp.lieu_naissance_pays',
  ERR_DGFIP_IMPOTPART_INCONNU = 'urssaf_tp.dgfip_impotpart_inconnu',
  ERR_CONTACT = 'urssaf_tp.contact',
  ERR_CONTACT_ADRESSE_TYPE_VOIE = 'urssaf_tp.contact_adresse_type_voie',
  ERR_CONTACT_ADRESSE_LETTRE_VOIE = 'urssaf_tp.contact_adresse_lettre_voie',
  ERR_CONTACT_ADRESSE_LIBELLEVOIE = 'urssaf_tp.contact_adresse_libellevoie',
  ERR_CONTACT_ADRESSE_INFORMATION_SUPPLEMENTAIRE = 'urssaf_tp.contact_adresse_information_supplementaire',
  ERR_CONTACT_ADRESSE_COMMUNE = 'urssaf_tp.contact_adresse_commune',
  ERR_COMPTE_BLOQUE = 'urssaf_tp.compte_bloque',
  ERREUR_TECHNIQUE = 'urssaf_tp.api_unavailable',
  ERR_CONTACT_ADRESSE_TYPEVOIE = 'urssaf_tp.type_de_voie_libelle',
  ERR_APPEL_BACB = 'urssaf_tp.appel_bacb',
  ERR_APPEL_BACO = 'urssaf_tp.appel_baco',
  ERREUR_CONTACT_ADRESSE_TYPEVOIE = 'urssaf_tp.adresse_typevoie',
  ERREUR_REFERENCE_PARTICULIER_EXISTANTE_TYPE = 'urssaf_tp.particulier_existant',
}
