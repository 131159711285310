
import { Action, Component, Prop, Vue } from 'nuxt-property-decorator'
import { IBilling, ReadTimeRecord } from '@abby/core-legacy'

@Component
export default class TimeTrackingChips extends Vue {
  @Prop({ required: true })
  timeRecord!: ReadTimeRecord

  @Action('billing/openBillingSidePanel')
  openBillingSidePanel!: (payload: IBilling) => Promise<void>;
}

