import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { PersonaFeature } from '@abby/core-legacy'
import type {
  IBillingConfiguration,
  ICompany,
  IPersona,
  IVatConfiguration,
} from '@abby/core-legacy'
import { IAuthUser } from '~/store/auth'

export interface StartGestionState {
  user: Partial<IAuthUser> | null;
  company: Partial<ICompany> | null;
  persona: Partial<IPersona> | null;
  vatConfiguration: Partial<IVatConfiguration> | null;
  billingConfiguration: Partial<IBillingConfiguration> | null;
  sponsorShipCode: string | null;
}

export const state = (): StartGestionState => ({
  user: null,
  company: null,
  persona: null,
  vatConfiguration: null,
  billingConfiguration: null,
  sponsorShipCode: null,
})

export const getters: GetterTree<StartGestionState, StartGestionState> = {
  user: state => state.user || {},
  company: state => state.company || {},
  persona: state => state.persona || {},
  sponsorShipCode: state => state.sponsorShipCode || null,
  vatConfiguration: state => state.vatConfiguration || {},
  billingConfiguration: state => state.billingConfiguration || {},
  isSAP: state => !!state.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API),
}

export const mutations: MutationTree<StartGestionState> = {
  SET_USER (state: StartGestionState, value: Partial<IAuthUser>) {
    state.user = value
  },
  SET_COMPANY (state: StartGestionState, value: Partial<ICompany>) {
    state.company = value
  },
  UPDATE_USER (state: StartGestionState, value: Partial<IAuthUser>) {
    state.user = {
      ...state.user,
      ...value,
    }
  },
  UPDATE_COMPANY (state: StartGestionState, value: Partial<ICompany>) {
    state.company = {
      ...state.company,
      ...value,
    }
  },
  UPDATE_PERSONA (state: StartGestionState, value: Partial<IPersona>) {
    state.persona = {
      ...state.persona,
      ...value,
    }
  },
  UPDATE_BILLING_CONFIGURATION (state: StartGestionState, value: Partial<IBillingConfiguration>) {
    state.billingConfiguration = {
      ...state.billingConfiguration,
      ...value,
    }
  },
  SET_SPONSORSHIP_CODE (state: StartGestionState, value: string | null) {
    state.sponsorShipCode = value
  },
  RESET (_currentState: StartGestionState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<StartGestionState, StartGestionState> = {
  initializeStartGestion ({ commit, rootGetters }) {
    commit('UPDATE_USER', rootGetters['auth/user'])
    commit('UPDATE_COMPANY', rootGetters['company/company'])
    commit('UPDATE_PERSONA', rootGetters['guide/persona'])
    commit('UPDATE_BILLING_CONFIGURATION', rootGetters['billingConfiguration/billingConfiguration'])
  },
  updateUserState ({ commit }, value: Partial<IAuthUser>) {
    commit('UPDATE_USER', value)
  },
  updateCompanyState ({ commit }, value: Partial<ICompany>) {
    commit('UPDATE_COMPANY', value)
  },
  setCompanyState ({ commit }, value: Partial<ICompany>) {
    commit('SET_COMPANY', value)
  },
  setSponsorshipCodeState ({ commit }, value: string | null) {
    commit('SET_SPONSORSHIP_CODE', value)
  },
  updatePersonaState ({ commit }, value: Partial<IPersona>) {
    commit('UPDATE_PERSONA', value)
  },
  updateBillingConfigurationState ({ commit }, value: Partial<IBillingConfiguration>) {
    commit('UPDATE_BILLING_CONFIGURATION', value)
  },
}
