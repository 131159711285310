import type {
  IHelpCenterUseCase, QueryHelpCenterUseCase,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class HelpCenter extends Resource {
  getUseCases (query: QueryHelpCenterUseCase): Promise<IHelpCenterUseCase[]> {
    return this.axios.$get('/helpCenter/useCase/all', { params: query })
  }
}
