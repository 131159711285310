import type { CreateNote, INote } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Note extends Resource {
  getAll (companyId: string): Promise<INote[]> {
    return this.axios.$get(`/note/${companyId}`)
  }

  get ({ companyId, id } : { companyId: string, id: string }): Promise<INote> {
    return this.axios.$get(`/note/${companyId}/${id}`)
  }

  delete ({ companyId, id } : { companyId: string, id: string }): Promise<INote> {
    return this.axios.$delete(`/note/${companyId}/${id}`)
  }

  create (params: CreateNote): Promise<INote> {
    return this.axios.$post(`/note/${params.companyId}`, { text: params.text })
  }
}
