export enum BridgeStatus {
  OK = 0,
  JUST_ADDED = -2,
  JUST_EDITED = -3,
  LOGIN_FAILED = 402,
  NEEDS_HUMAN_ACTION = 429,
  NEEDS_PASSWORD_ROTATION = 430,
  COULD_NOT_REFRESH = 1003,
  NOT_SUPPORTED = 1005,
  DISABLED_TEMPORARILY = 1007,
  INCOMPLETE = 1009,
  NEEDS_MANUAL_REFRESH = 1010,
  MIGRATION = 1099,
  PRO_ACCOUNT_LOCKED = 1100,
}

export enum CallBackSource {
  CONNECT = 'connect',
  PAY = 'pay',
  MANAGE = 'manage',
}

export enum CallBackSuccess {
  TRUE = 'true',
  FALSE = 'false',
}

export enum CallBackStep {
  WELCOME_PAGE = 'welcome_page',
  EMAIL_VALIDATION_FORM = 'email_validation_form',
  HIGHLIGHTED_BANKS = 'highlighted_banks',
  LINK_IBAN_ERROR = 'link_iban_error',
  LIST_BANKS = 'list_banks',
  SUB_BANKS_LIST = 'sub_banks_list',
  BANK_CREDS_FORM = 'bank_creds_form',
  EDIT_BANK_CREDS_FORM = 'edit_bank_creds_form',
  SYNC_IN_PROGRESS = 'sync_in_progress',
  OTP_FORM = 'otp_form',
  SYNC_SUCCESS = 'sync_success',
  SYNC_ERROR = 'sync_error',
  SYNC_PENDING = 'sync_pending',
  PRO_VALIDATION_FORM = 'pro_validation_form',
}
