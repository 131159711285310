import { BankAction, ErrorCode } from '@abby/shared'

type BankItemProps = {
    id: string;
    name: string;
    logo: string;
    externalId: string;
    lastRefreshedAt?: Date;
    startRefreshAt?: Date;
    archivedAt?: Date;
    error?: ErrorCode;
    action?: BankAction;
}

export class BankItem {
    readonly id: string;
    readonly name: string;
    readonly externalId: string;
    readonly logo: string;
    readonly lastRefreshedAt?: Date;
    startRefreshAt?: Date;
    archivedAt?: Date;
    readonly error?: ErrorCode;
    readonly action?: BankAction;

    private constructor (props: BankItemProps) {
      this.id = props.id
      this.name = props.name
      this.externalId = props.externalId
      this.logo = props.logo
      this.lastRefreshedAt = props.lastRefreshedAt
      this.error = props.error
      this.action = props.action
      this.startRefreshAt = props.startRefreshAt
      this.archivedAt = props.archivedAt
    }

    static create (props: BankItemProps): BankItem {
      return new BankItem(props)
    }
}
