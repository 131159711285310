import type { CreateCreationFile, ReadCreationFile } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class CreationFile extends Resource {
  getAll (companyId?: string): Promise<ReadCreationFile[]> {
    return this.axios.$get(companyId ? `/creationFile/${companyId}` : '/creationFile')
  }

  create (companyId: string, payload: CreateCreationFile): Promise<ReadCreationFile> {
    return this.axios.$post(companyId ? `/creationFile/${companyId}` : '/creationFile', payload)
  }

  delete (id: string): Promise<void> {
    return this.axios.$delete(`/creationFile/${id}`)
  }
}
