var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"amount",class:{
    positive: _vm.amount > 0,
    negative: _vm.amount <= 0 || _setup._defaultColor,
    'amount__small': _vm.size === 'small',
    'amount__normal': _vm.size === 'normal',
    'amount__large': _vm.size === 'large'
  }},[_c('span',{staticClass:"amount__decimals-part"},[_vm._v("\n    "+_vm._s(_setup.formatAmount.decimalsPart)+_vm._s(!_vm.round ? _setup.formatAmount.decimalSeparator : '')+"\n  ")]),_vm._v(" "),(!_vm.round)?_c('span',{staticClass:"amount__cents-part"},[_vm._v("\n    "+_vm._s(_setup.formatAmount.centsPart)+"\n  ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"amount__decimals-part"},[_vm._v("\n    "+_vm._s(_setup.formatAmount.currency)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }