import { Contact } from '~/logic/contexts/client/domain/entity/contact.entity'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useFetchContactStatistics = () => {
  const contactRepository = useContactRepository()
  const clientStore = useClientStore()
  const alertManager = useAlertManager()

  return async (contact: Contact) => {
    try {
      const contactStatistic = await contactRepository.getContactStatisticsById(contact.id)
      clientStore.setContactStatistics(contactStatistic)
    } catch (e) {
      alertManager.autoError(e)
    }
  }
}
