import { Plugin } from '@nuxt/types'

export interface ClipboardInstance {
  copy(value: string): Promise<void> ;
}

const AmountPlugin: Plugin = (_, inject) => {
  inject('clipboard', {
    copy: async (value: string): Promise<void> => {
      await navigator.clipboard.writeText(value)
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $clipboard: ClipboardInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $clipboard: ClipboardInstance
  }
  interface Context {
    $clipboard: ClipboardInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $clipboard: ClipboardInstance
  }
}

export default AmountPlugin
