import { ReconciliateInvoice, Money } from '@abby/shared'
import { BillingType } from '@abby/core-legacy'
import {
  useAccountingBillingRepository,
} from '~/logic/contexts/billing/infrastructure/repository/accountingBilling.repository'
import { AccountingBillingForm } from '~/logic/contexts/billing/domain/form/accountingBilling.form'
import { useToasterManager } from '~/composables/shared/manager/useToasterManager'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingPinia.store'

export const useReconciliateBilling = () => {
  const accountingBillingRepository = useAccountingBillingRepository()
  const toasterManager = useToasterManager()
  const billingStore = useBillingStore()

  return async (
    { id, number, type, remainingAmount, accountingBillings, test }:
    { id: string, number: string, type: BillingType, remainingAmount: Money, accountingBillings: AccountingBillingForm[], test: boolean }) => {

    if (test) {
      toasterManager.warning({
        title: 'Enregistrement impossible',
        message: 'Il est actuellement impossible d\'encaisser et de passer en payé un document lorsque le mode test est activé sur Abby.',
      })
      billingStore.toggleMarkAsPaidModal(false)
      return
    }

    let payload = {
      payments: accountingBillings.map((accountingBilling) => {
        return {
          transactionId: accountingBilling?.transactionId,
          method: accountingBilling.method,
          amount: accountingBilling.amount.value,
          receivedAt: accountingBilling.receivedAt,
          ...(accountingBilling.otherMethod ? { otherMethod: accountingBilling.otherMethod } : {}),
        }
      }),
    } as unknown as ReconciliateInvoice
    const accountingBillingsAmount = accountingBillings.reduce((acc, accountingBilling) => acc + accountingBilling.amount.value, 0)
    const partialReconciliate = (accountingBillingsAmount + remainingAmount.value) !== accountingBillingsAmount
    const hasZeroTotalAmount = accountingBillingsAmount === 0 && remainingAmount.value === 0
    if (hasZeroTotalAmount) {
      payload = { payments: [] }
    }
    await accountingBillingRepository.reconciliateBilling({
      id,
      number,
      type,
      partialReconciliate,
      hasZeroTotalAmount,
      payload,
    })
  }
}
