import type { GeoQuery } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Address extends Resource {
  search (params: GeoQuery): Promise<{ features: [{ properties: any }] }> {
    let query = '?'
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`
    })

    return this.axios.$get(`/search-address/${query}`, {
      baseURL: '',
      headers: {
        // @ts-ignore
        Authorization: undefined,
      },
    })
  }
}
