import { Context, Plugin } from '@nuxt/types'
import { CampaignType, GuideStepItem, GuideLevelItem } from '@abby/core-legacy'
import { GuideType, guideTypeKeys } from '~/logic/contexts/guide/domain/enums/GuideType'

export interface OnboardingManagerInstance {
  getOnboardingGroup : () => GuideType;
  isOnboardingGroup: (group: GuideType) => boolean;
  registerOnboardingGroup: (group: GuideType) => void;
  generateOnboardingGroup: ({ isSAP }: { isSAP?: boolean }) => { group: GuideType, hasAccessToNewOnboarding: boolean };
}

const OnboardingManagerPlugin: Plugin = ({ store, app, $site, $config, $dayjs }: Context, inject) => {

  const isUserCampaignActive = (campaign: CampaignType) => {
    return !app.$campaignsManager.isDisplayable(campaign)
  }

  const getOnboardingGroup = () => {
    for (const guideType of guideTypeKeys) {
      if (isUserCampaignActive(guideType as unknown as CampaignType)) {
        return guideType
      }
    }
    // Return Group A onboarding by default as it is the legacy onboarding
    return GuideType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_A
  }

  const isOnboardingGroup = (group: GuideType) => {
    return getOnboardingGroup() === group
  }

  const generateOnboardingGroup = ({ isSAP }: { isSAP?: boolean }) => {
    const company = store.getters['company/company']
    const isEI = store.getters['company/isEI']
    const hasAccessToNewOnboarding = isEI && !company.hasVat && !isSAP
    const group = (hasAccessToNewOnboarding ? getRandomOnboardingGroup() : CampaignType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_A) as unknown as GuideType
    return { group, hasAccessToNewOnboarding }
  }

  const getRandomOnboardingGroup = () => {
    const abnumberOnboarding = localStorage.getItem('abno2') || (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString()
    localStorage.setItem('abno2', abnumberOnboarding)
    if (Number(abnumberOnboarding) >= 50) {
      return GuideType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_B
    }
    return GuideType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_A
  }

  const registerOnboardingGroup = async (group: GuideType) => {
    await app.$campaignsManager.doNotDisplayAnymore(group as unknown as CampaignType)
    if (group === GuideType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_A) {
      localStorage.setItem('last_guide_level_opened', GuideLevelItem.MEET_ABBY)
      localStorage.setItem('last_guide_step_opened', GuideStepItem.MEET_ABBY)
    } else {
      localStorage.setItem('last_guide_level_opened', GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY)
      localStorage.setItem('last_guide_step_opened', GuideStepItem.CREATE_YOUR_FIRST_DOCUMENT)
    }
  }

  inject('onboardingManager', {
    getOnboardingGroup,
    isOnboardingGroup,
    generateOnboardingGroup,
    registerOnboardingGroup,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $onboardingManager: OnboardingManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $onboardingManager: OnboardingManagerInstance
  }
  interface Context {
    $onboardingManager: OnboardingManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $onboardingManager: OnboardingManagerInstance
  }
}

export default OnboardingManagerPlugin
