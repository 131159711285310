export const colors = {
  primary: {
    base: '#0075eb',
    lighten5: '#E8F3FE',
    lighten4: '#C8E3FF',
    lighten3: '#91C8FF',
    lighten2: '#5AACFF',
    lighten1: '#2391FF',
    darken1: '#005EBC',
    darken2: '#00468D',
    darken3: '#002F5E',
    darken4: '#00172F',
    darken5: '#00172F',
  },
  secondary: {
    base: '#1BB157',
    lighten5: '#E9FCF5',
    lighten4: '#D6F7EB',
    lighten3: '#ADF0D6',
    lighten2: '#84E8C2',
    lighten1: '#5BE1AD',
    darken1: '#21B47C',
    darken2: '#19875D',
    darken3: '#115A3E',
    darken4: '#082D1F',
    darken5: '#082D1F',
  },
  blue: {
    base: '#0075eb',
    lighten5: '#E8F3FE',
    lighten4: '#C8E3FF',
    lighten3: '#91C8FF',
    lighten2: '#5AACFF',
    lighten1: '#2391FF',
    darken1: '#005EBC',
    darken2: '#00468D',
    darken3: '#002F5E',
    darken4: '#00172F',
    darken5: '#00172F',
  },
  green: {
    base: '#1BB157',
    lighten5: '#D6F7EB',
    lighten4: '#D6F7EB',
    lighten3: '#ADF0D6',
    lighten2: '#84E8C2',
    lighten1: '#5BE1AD',
    darken1: '#21B47C',
    darken2: '#19875D',
    darken3: '#115A3E',
    darken4: '#082D1F',
    darken5: '#082D1F',
  },
  yellow: {
    base: '#dd7c00 ',
    lighten5: '#FFF3D6',
    lighten4: '#FFF3D6',
    lighten3: '#FFE6AC',
    lighten2: '#FEDA83',
    lighten1: '#FECD59',
    darken1: '#F0AA01',
    darken2: '#B47F01',
    darken3: '#785501',
    darken4: '#3C2A00',
    darken5: '#3C2A00',
  },
  red: {
    base: '#E94E6E',
    lighten5: '#FBDCE2',
    lighten4: '#FBDCE2',
    lighten3: '#F6B8C5',
    lighten2: '#F295A8',
    lighten1: '#ED718B',
    darken1: '#DD1C44',
    darken2: '#A61533',
    darken3: '#6F0E22',
    darken4: '#370711',
    darken5: '#370711',
  },
  purple: {
    base: '#9043CB',
    lighten5: '#E9D9F5',
    lighten4: '#E9D9F5',
    lighten3: '#D3B4EA',
    lighten2: '#BC8EE0',
    lighten1: '#A669D5',
    darken1: '#9043CB',
    darken2: '#742FA9',
    darken3: '#57237F',
    darken4: '#3A1755',
    darken5: '#3A1755',
  },
  black: {
    base: '#25385E',
    lighten5: '#C9D4EA',
    lighten4: '#C9D4EA',
    lighten3: '#92A8D4',
    lighten2: '#5C7DBF',
    lighten1: '#3A5894',
    darken1: '#1E2D4B',
    darken2: '#162238',
    darken3: '#0F1626',
    darken4: '#070B13',
    darken5: '#070B13',
  },
  grey: {
    base: '#5A7294',
    lighten5: '#bdc7d4',
    lighten4: '#adb9ca',
    lighten3: '#9caabf',
    lighten2: '#8c9cb4',
    lighten1: '#7b8ea9',
    darken1: '#516785',
    darken2: '#485b76',
    darken3: '#3f5068',
    darken4: '#364459',
    darken5: '#2d394a',
  },
  orange: {
    base: '#FF9800',
    lighten5: '#FFF3E0',
    lighten4: '#FFE0B2',
    lighten3: '#FFCC80',
    lighten2: '#FFB74D',
    lighten1: '#FFA726',
    darken1: '#FB8C00',
    darken2: '#F57C00',
    darken3: '#EF6C00',
    darken4: '#E65100',
    darken5: '#E65100',
  },
  pink: {
    base: '#E91E63',
    lighten5: '#FCE4EC',
    lighten4: '#F8BBD0',
    lighten3: '#F48FB1',
    lighten2: '#F06292',
    lighten1: '#EC407A',
    darken1: '#D81B60',
    darken2: '#C2185B',
    darken3: '#AD1457',
    darken4: '#880E4F',
  },
  background: {
    base: '#F6F7FD',
    lighten5: '#F8F9FD',
    lighten4: '#F8F9FD',
    lighten3: '#F8F9FD',
    lighten2: '#F8F9FD',
    lighten1: '#F8F9FD',
    darken1: '#E7E7F3',
    darken2: '#E7E7F3',
    darken3: '#E7E7F3',
    darken4: '#E7E7F3',
    darken5: '#121B52',
  },
};
