import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { QueryRates, ReadRates } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface CurrencyState {
  rates: ReadRates | null,
  date: Date | null,
}

export const state = (): CurrencyState => ({
  rates: null,
  date: null,
})

export const getters: GetterTree<CurrencyState, RootState> = {
  rates: state => state.rates,
  date: state => state.date,
}

export const mutations: MutationTree<CurrencyState> = {
  SET_RATES (state, value) {
    state.rates = value
  },
  SET_DATE (state, value) {
    state.date = value
  },
  RESET (_currentState: CurrencyState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<CurrencyState, CurrencyState> = {
  async fetchRates ({ commit }, params: QueryRates) {
    const { time, rates } = await this.$api.currency.getRates(params)
    commit('SET_RATES', rates)
    commit('SET_DATE', this.$dayjs(time).toDate())
  },
}
