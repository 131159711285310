import {
  Type, Code,
} from '@abby/core-legacy'

import type {
  BasePaginate,
  CreateThirdParty,
  QueryDownloadThirdParty,
  QueryThirdParty,
  ReadThirdParty,
  UpdateThirdParty,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Provider extends Resource {
  paginate (params: QueryThirdParty): Promise<BasePaginate<ReadThirdParty>> {
    return this.axios.$get('/providers', {
      params,
    })
  }

  create (payload: CreateThirdParty): Promise<ReadThirdParty> {
    return this.axios.$post('/provider', payload)
  }

  createMany (payload: CreateThirdParty[]): Promise<void> {
    return this.axios.$post('/providers', payload)
  }

  update (id: string, payload: UpdateThirdParty): Promise<ReadThirdParty> {
    return this.axios.$patch(`/provider/${id}`, payload)
  }

  delete (id: string) {
    return this.axios.$delete(`/provider/${id}`)
  }

  downloadProvider (params: QueryDownloadThirdParty): Promise<any> {
    return this.axios.get('/provider/download', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.provider,
      type: Type.resourceError,
      message: 'provider.export_no_data',
    }))
  }
}
