import { useContext } from '@nuxtjs/composition-api'
import { ContactItem } from '~/logic/contexts/client/domain/entity/contactItem.entity'

export const useCopyContactID = () => {
  const { $clipboard } = useContext()

  return async ({ id }: ContactItem) => {
    await $clipboard.copy(id)
  }
}
