import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { Vat } from '@abby/core-legacy'
import type { IVat } from '@abby/core-legacy'

export interface VatsState {
  vats: IVat[];
}

export interface VatGroup {
  name: string;
  slug: string;
  vats: IVat[];
}

export const state = (): VatsState => ({
  vats: Vat,
})

export const getters: GetterTree<VatsState, VatsState> = {
  vats: state => state.vats,
  groupedVats: (_, getters): Array<VatGroup> => {
    return [
      {
        name: 'Classique',
        slug: 'classic',
        vats: getters.vats.filter((v: IVat) => !v.isODT && !v.isEuropeanUnion && !v.isOutsideEuropeanUnion),
      },
      {
        name: 'DOM-TOM',
        slug: 'ODT',
        vats: getters.vats.filter((v: IVat) => v.isODT),
      }, {
        name: 'Étranger',
        slug: 'abroad',
        vats: getters.vats.filter((v: IVat) => v.isEuropeanUnion || v.isOutsideEuropeanUnion),
      }]
  },
}

export const mutations: MutationTree<VatsState> = {
  RESET (_currentState: VatsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<VatsState, VatsState> = {}
