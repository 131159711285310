export enum AnalysesModules {
  // Création
  INCOMES_OUTCOMES_RESULT_CHART = 'incomes_outcomes_result_chart',
  LATE_WAITING_BILLINGS = 'late_waiting_billings',
  DECLARATIONS = 'declarations',
  BANKS = 'banks',
  CEILING = 'ceiling',
  TASKS = 'tasks',
  PROJECTS = 'projects',
  VAT = 'vat',
  INCOMES_OUTCOMES_TOP = 'incomes_outcomes_top',
  PRODUCTS_INCOMES_QUANTITY = 'products_incomes_quantity',
  CUSTOMERS_INCOMES_LATE = 'customers_incomes_late',
}

export const AnalysesModulesConfig = {
  [AnalysesModules.INCOMES_OUTCOMES_RESULT_CHART]: {
    column: 3,
    row: 2,
    component: 'HomeIncomesOutcomesResultChart',
  },
  [AnalysesModules.LATE_WAITING_BILLINGS]: {
    column: 1,
    row: 2,
    component: 'HomeLateWaitingBillingsCard',
  },
  [AnalysesModules.TASKS]: {
    column: 1,
    row: 2,
    component: 'HomeTasksCard',
  },
  [AnalysesModules.DECLARATIONS]: {
    column: 1,
    row: 1,
    component: 'HomeDeclarationCard',
  },
  [AnalysesModules.BANKS]: {
    column: 1,
    row: 1,
    component: 'HomeBankCard',
  },
  [AnalysesModules.CEILING]: {
    column: 1,
    row: 1,
    component: 'HomeCeilingCard',
  },
  [AnalysesModules.PROJECTS]: {
    column: 1,
    row: 1,
    component: 'HomeOpportunitiesCard',
  },
  [AnalysesModules.INCOMES_OUTCOMES_TOP]: {
    column: 1,
    row: 2,
    component: 'HomeIncomesOutcomesTopCard',
  },
  [AnalysesModules.VAT]: {
    column: 1,
    row: 1,
    component: 'HomeVATCard',
  },
  [AnalysesModules.PRODUCTS_INCOMES_QUANTITY]: {
    column: 1,
    row: 2,
    component: 'HomeProductIncomesQuantityTopCard',
  },
  [AnalysesModules.CUSTOMERS_INCOMES_LATE]: {
    column: 1,
    row: 2,
    component: 'HomeCustomersIncomesLateTopCard',
  },
};
