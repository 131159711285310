export enum SegmentEvent {
  SUBSCRIBE = 'Subscribe',
  START_TRIAL = 'StartTrial',
  PURCHASE = 'Purchase',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  SIGN_UP_FOR_FREE_CLICKED = 'SignUpForFreeClicked',
  SIGNED_UP = 'SignedUp',
  SIGNED_UP_COMPLETE = 'SignedUpComplete',
  PURCHASE_COMPLETED = 'PurchaseCompleted',
  SUBSCRIPTION_STARTED = 'SubscriptionStarted',
  AB_SUBSCRIPTION_STARTED = 'ABSubscriptionStarted',
  UNPAID_INVOICE_GCOLLECT_CLICKED = 'UnpaidInvoiceGcollectClicked',
  UNPAID_INVOICE_GCOLLECT_MODAL_CLICKED = 'UnpaidInvoiceGcollectModalClicked',
  BILLING_TEST_MODE_DISABLED = 'BillingTestModeDisabled',
  PLANS_MODAL_OPENED = 'PlansModalOpened',
  SALE_FEATURE_MODAL_OPENED = 'SaleFeatureModalOpened',
  PAYMENT_MODAL_OPENED = 'PaymentModalOpened',
  PAYMENT_SUCCEED = 'PaymentSucceed',
  CHOOSE_CE_WITH_PLAN = 'ChooseCEWithPlan',
  EXPIRED_TRIAL_MODAL_OPENED = 'ExpiredTrialOpened',
  PLANS_FREQUENCY_CHANGED = 'PlansFrequencyChanged',
  TASK_DELETED = 'TaskDeleted',
  OPPORTUNITY_DELETED = 'OpportunityDeleted',
  OPPORTUNITY_CATEGORY_CREATED = 'OpportunityCategoryCreated',
  CTA_TIERS_PRESTATION_CLICKED = 'CtaTiersPrestationClicked',
  COMPLETE_GUIDE_STEP = 'CompleteGuideStep',
  INTERESTED_BY_EVENT = 'InterestedByEvent',

  // Website
  DEMO_BOOKED_CLICKED = 'DemoBookedClicked',
  SUMUP_OFFER_CLICKED = 'SumupOfferClicked',
  PAGE_VIEW = 'PageView',

  EASTER_EGG_KNOW_MORE = 'EasterEggKnowMore',
  EASTER_EGG_PARTICIPATE = 'EasterEggParticipate',
  EASTER_EGG_GET_GIFT = 'EasterEggGetGift',
  EASTER_EGG_FINISH = 'EasterEggFinish',
  // Action keys
  ACTION_KEY = 'ActionKey',
  // BILLINGS
  BILLING_DOCUMENT_CREATED = 'BillingDocumentCreated',
  BILLING_DOCUMENT_ADVANCE_CREATED = 'BillingDocumentAdvanceCreated',
  BILLING_DOCUMENT_UPDATED = 'AdvanceDocumentUpdated',
  BILLING_DOCUMENT_STATUS_UPDATED = 'BillingDocumentStatusUpdated',
  BILLING_DOCUMENT_DOWNLOADED = 'BillingDocumentDownloaded',
  INVOICE_ADVANCE_PAID_ONLINE = 'InvoiceAdvancePaidOnline',
  ESTIMATE_PURCHASE_ORDER_SIGNED_ONLINE = 'EstimatePurchaseOrderSignedOnline',
  BILLING_DOCUMENT_ARCHIVED = 'BillingDocumentArchived',

  // EXPORTS
  PRODUCTS_DOWNLOADED = 'ProductsDownloaded',
  PROVIDERS_DOWNLOADED = 'ProvidersDownloaded',
  CUSTOMERS_DOWNLOADED = 'CustomersDownloaded',

  // ACCOUNTS
  BANK_TRANSACTION_ANNOTATION = 'BankTransactionAnnotation',
  INCOMEBOOK_ENTRY_ADDED = 'IncomebookEntryAdded',
  INCOMEBOOK_ENTRY_UPDATED = 'IncomebookEntryUpdated',
  PURCHASE_REGISTER_ENTRY_ADDED = 'PurchaseRegisterEntryAdded',
  PURCHASE_REGISTER_ENTRY_UPDATED = 'PurchaseRegisterEntryUpdated',
  BOOK_DOWNLOADED = 'BookDownloaded',
  SOCIAL_SECURITY_CONTRIBUTIONS_FOR_DECLARATION_ESTIMATED = 'SocialSecurityContributionForDeclarationEstimated',
  DECLARATION_MARKED_AS_DECLARED = 'DeclarationMarkedAsDeclared',
  DECLARATION_MARKED_AS_DECLARED_WITH_PAYMENT_ORDER = 'DeclarationMarkedAsDeclaredWithPaymentOrder',

  // PRODUCTIVITY
  TASK_CREATED = 'TaskCreated',
  TASK_UPDATED = 'TaskUpdated',
  TASK_COMPLETED = 'TaskCompleted',
  OPPORTUNITY_CREATED = 'OpportunityCreated',
  OPPORTUNITY_UPDATED = 'OpportunityUpdated',
  OPPORTUNITY_STATUS_UPDATED = 'OpportunityStatusUpdated',
  TIME_TRACKING_ENTRY_CREATED = 'TimeTrackingEntryCreated',
  TIME_TRACKING_ENTRY_UPDATED = 'TimeTrackingEntryUpdated',
  TIME_TRACKING_ENTRY_BILLED = 'TimeTrackingEntryBilled',
  DOCUMENT_ASSOCIATED_WITH_OPPORTUNITY = 'DocumentAssociatedWithOpportunity',
  TASK_ASSOCIATED_WITH_OPPORTUNITY = 'TaskAssociatedWithOpportunity',
  TIME_TRACKING_ASSOCIATED_WITH_OPPORTUNITY = 'TimeTrackingAssociatedWithOpportunity',

  // SUPPORT
  HELP_CENTER_OPENED = 'HelpCenterOpened',
  HELPDESK_ARTICLE_OPENED = 'HelpdeskArticleOpened',
  // CHAT_MESSAGE_SENT = 'ChatMessageSent',
  TICKET_SENT = 'TicketSent',
  FRILL_OPENED = 'FrillOpened',
  HELP_WITH_SUPPORT_SENT = 'HelpWithSupportSent',
  HELP_WITH_SUPPORT_MODAL_OPENED = 'HelpWithSupportModalOpened',
  HELP_WITH_SUPPORT_ARTICLE_OPENED = 'HelpWithSupportArticleOpened',

  // SETUP
  STRIPE_CONFIGURATION_STARTED = 'StripeConfigurationStarted',
  STRIPE_CONFIGURATION_ENDED = 'StripeConfigurationEnded',
  BANK_ACCOUNT_CONNEXION = 'BankAccountConnexion',
  URSSAF_ACCOUNT_CONNEXION = 'UrssafAccountConnexion',
  API_TP_CONNEXION = 'ApiTpConnexion',
  BASIC_DOCUMENT_CUSTOMIZATION = 'BasicDocumentCustomization',
  ADVANCED_DOCUMENT_CUSTOMIZATION = 'AdvancedDocumentCustomization',
  REMOVE_DOCUMENT_BRAND_LOGO = 'RemoveDocumentBrandLogo',
  BILLING_REMINDER_ACTIVATED = 'BillingReminderActivated',
  BILLING_FREQUENCY_ACTIVATED = 'BillingFrequencyActivated',
  RECALL_URSSAF_TAXES_CFE_ACTIVATED = 'RecallUrssafTaxesCfeActivated',
  EMAIL_CUSTOMIZATION = 'EmailCustomization',
  CUSTOMIZED_ACCOUNTING_ACCOUNT_ADDED = 'CustomizedAccountingAccountAdded',
  CUSTOMIZED_ACCOUNTING_ACCOUNT_UPDATED = 'CustomizedAccountingAccountUpdated',
  // MAKE_CONNEXION = 'MakeConnexion',
  COMPLETED_ACTIVITY = 'CompletedActivity',
  ALERT_MANAGER_ERROR = 'AlertManagerError',

  // WEBINAR
  CALENDLY_REDIRECTION = 'CalendlyRedirection',

  // ONBOARDING
  ONBOARDING_DISCOVERY_ORIGIN = 'OnboardingDiscoveryOrigin',

  RELOGGED = 'Relogged',
}

export const SegmentEventWeight = {
  // BILLINGS
  [SegmentEvent.BILLING_DOCUMENT_CREATED]: 5,
  [SegmentEvent.BILLING_DOCUMENT_ADVANCE_CREATED]: 7,
  [SegmentEvent.BILLING_DOCUMENT_UPDATED]: 2,
  [SegmentEvent.BILLING_DOCUMENT_STATUS_UPDATED]: 2,
  [SegmentEvent.BILLING_DOCUMENT_DOWNLOADED]: 2,
  [SegmentEvent.INVOICE_ADVANCE_PAID_ONLINE]: 10,
  [SegmentEvent.ESTIMATE_PURCHASE_ORDER_SIGNED_ONLINE]: 10,
  [SegmentEvent.BILLING_DOCUMENT_ARCHIVED]: 3,

  // ACCOUNTS
  [SegmentEvent.BANK_TRANSACTION_ANNOTATION]: 5,
  [SegmentEvent.INCOMEBOOK_ENTRY_ADDED]: 2,
  [SegmentEvent.INCOMEBOOK_ENTRY_UPDATED]: 1,
  [SegmentEvent.PURCHASE_REGISTER_ENTRY_ADDED]: 2,
  [SegmentEvent.PURCHASE_REGISTER_ENTRY_UPDATED]: 1,
  [SegmentEvent.BOOK_DOWNLOADED]: 2,
  [SegmentEvent.SOCIAL_SECURITY_CONTRIBUTIONS_FOR_DECLARATION_ESTIMATED]: 2,
  [SegmentEvent.DECLARATION_MARKED_AS_DECLARED]: 5,
  [SegmentEvent.DECLARATION_MARKED_AS_DECLARED_WITH_PAYMENT_ORDER]: 15,

  // PRODUCTIVITY
  [SegmentEvent.TASK_CREATED]: 2,
  [SegmentEvent.TASK_UPDATED]: 1,
  [SegmentEvent.TASK_COMPLETED]: 3,
  [SegmentEvent.OPPORTUNITY_CREATED]: 4,
  [SegmentEvent.OPPORTUNITY_UPDATED]: 2,
  [SegmentEvent.OPPORTUNITY_STATUS_UPDATED]: 6,
  [SegmentEvent.TIME_TRACKING_ENTRY_CREATED]: 4,
  [SegmentEvent.TIME_TRACKING_ENTRY_UPDATED]: 2,
  [SegmentEvent.TIME_TRACKING_ENTRY_BILLED]: 6,
  [SegmentEvent.DOCUMENT_ASSOCIATED_WITH_OPPORTUNITY]: 6,
  [SegmentEvent.TASK_ASSOCIATED_WITH_OPPORTUNITY]: 6,
  [SegmentEvent.TIME_TRACKING_ASSOCIATED_WITH_OPPORTUNITY]: 6,

  // SUPPORT
  [SegmentEvent.HELP_CENTER_OPENED]: 2,
  [SegmentEvent.HELPDESK_ARTICLE_OPENED]: 4,
  // [SegmentEvent.CHAT_MESSAGE_SENT]: 1,
  [SegmentEvent.TICKET_SENT]: 3,
  [SegmentEvent.FRILL_OPENED]: 2,
  [SegmentEvent.HELP_WITH_SUPPORT_SENT]: 3,
  [SegmentEvent.HELP_WITH_SUPPORT_MODAL_OPENED]: 3,
  [SegmentEvent.HELP_WITH_SUPPORT_ARTICLE_OPENED]: 3,

  // SETUP
  [SegmentEvent.STRIPE_CONFIGURATION_STARTED]: 5,
  [SegmentEvent.STRIPE_CONFIGURATION_ENDED]: 20,
  [SegmentEvent.BANK_ACCOUNT_CONNEXION]: 20,
  [SegmentEvent.URSSAF_ACCOUNT_CONNEXION]: 20,
  [SegmentEvent.API_TP_CONNEXION]: 20,
  [SegmentEvent.BASIC_DOCUMENT_CUSTOMIZATION]: 0.1,
  [SegmentEvent.ADVANCED_DOCUMENT_CUSTOMIZATION]: 0.5,
  [SegmentEvent.REMOVE_DOCUMENT_BRAND_LOGO]: 5,
  [SegmentEvent.BILLING_REMINDER_ACTIVATED]: 5,
  [SegmentEvent.BILLING_FREQUENCY_ACTIVATED]: 5,
  [SegmentEvent.RECALL_URSSAF_TAXES_CFE_ACTIVATED]: 5,
  [SegmentEvent.EMAIL_CUSTOMIZATION]: 10,
  [SegmentEvent.CUSTOMIZED_ACCOUNTING_ACCOUNT_ADDED]: 10,
  [SegmentEvent.CUSTOMIZED_ACCOUNTING_ACCOUNT_UPDATED]: 10,
  // [SegmentEvent.MAKE_CONNEXION]: 1,
  [SegmentEvent.COMPLETED_ACTIVITY]: 5,

  // WEBINAR
  [SegmentEvent.CALENDLY_REDIRECTION]: 5,
};
