export enum ContactAction {
    ADMIN_COPY_CONTACT_ID = 'adminCopyContactId',

    EDIT = 'edit',
    ARCHIVE = 'archive',
    UNARCHIVE = 'unarchive',
    DELETE = 'delete',
    EDIT_NOTES = 'editNotes',
    VIEW_DETAILS = 'viewDetails',
    DEFINE_AS_PRIMARY_CONTACT = 'defineAsPrimaryContact',

    URSSAF_ACTIVATION_EMAIL_NOT_RECEIVED = 'urssafActivationEmailNotReceived',
    UPDATED_EMAIL_URSSAF_ACCOUNT = 'updatedEmailUrssafAccount',

    CREATE_BILLING = 'createBilling',
    CREATE_INVOICE = 'createInvoice',
    CREATE_ESTIMATE = 'createEstimate',
    CREATE_ASSET = 'createAsset',
    CREATE_PURCHASE_ORDER = 'createPurchaseOrder',

    DOWNLOAD_TAX_RETURN = 'downloadTaxReturn',
    GO_TO_CLIENT_PORTAL = 'goToClientPortal',
    VIEW_ORGANIZATION_DETAILS = 'viewOrganizationDetails',

}
