const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['maintenance'] = require('../middleware/maintenance.ts')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['onboarding'] = require('../middleware/onboarding.ts')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['redirection'] = require('../middleware/redirection.ts')
middleware['redirection'] = middleware['redirection'].default || middleware['redirection']

middleware['register'] = require('../middleware/register.ts')
middleware['register'] = middleware['register'].default || middleware['register']

middleware['sentryHeaderDev'] = require('../middleware/sentryHeaderDev.ts')
middleware['sentryHeaderDev'] = middleware['sentryHeaderDev'].default || middleware['sentryHeaderDev']

middleware['set-maintenance'] = require('../middleware/set-maintenance.ts')
middleware['set-maintenance'] = middleware['set-maintenance'].default || middleware['set-maintenance']

export default middleware
