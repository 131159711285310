import { Plugin } from '@nuxt/types'
import { saveAs } from 'file-saver'
import type { IFile } from '@abby/core-legacy'

interface OptionsFile { isBrand?: boolean, companyId?: string, width?: number }

export interface FileInstance {
  download(fileUrl: string | null | undefined): void;
  saveAs (fileId: string, transformer?: (fileInfo: IFile) => string): Promise<void>;
  upload(file: any, options?: OptionsFile): IFile;
  getMimeTypeURL(mimeType: string): string;
  getFileIcon(iconName: string): string;
  getStrapiUrl(path?: string | null): string;
  convertBase64ToBuffer(base64: string): Promise<File | null>;
}

const envDev = process.env.NODE_ENV === 'development'

const mimeTypesURL = {
  'image/jpeg': 'JPG',
  'image/png': 'PNG',
  'application/pdf': 'PDF',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC',
  'application/msword': 'DOC',
  'image/gif': 'GIF',
  'application/zip': 'ZIP',
}

const FilePlugin: Plugin = ({ $api, $axios, $config }, inject) => {
  inject('file', {
    download: (fileUrl: string | null | undefined) => {
      if (fileUrl) {
        window.open(fileUrl, '_blank')
      }
    },
    async convertBase64ToBuffer (base64: string): Promise<File | null> {
      const res = await fetch(base64)
      if (!res.url.startsWith('data:image/png;base64')) {
        return null
      }

      const arrayBuffer = await res.arrayBuffer()
      return new File([arrayBuffer], 'file.png', { type: 'image/png' })
    },
    upload: async (file: any, options: OptionsFile) => {
      const extension = file?.name?.split('.')?.pop()
      const name = file?.name?.slice(0, -(extension?.length + 1))

      let endpoint = ''
      let _file

      if (options?.isBrand) {
        const result = await $api.file.retrieveBrandSignedURL({
          name,
          extension,
          mimeType: file?.type,
        })
        endpoint = result.endpoint
        _file = result.file
      } else if (options?.companyId) {
        const result = await $api.file.retrieveCompanySignedURL(options?.companyId, {
          name,
          extension,
          mimeType: file?.type,
          width: options?.width,
        })
        endpoint = result.endpoint
        _file = result.file
      } else {
        const result = await $api.file.retrieveSignedURL({
          name,
          extension,
          mimeType: file?.type,
          width: options?.width,
        })
        endpoint = result.endpoint
        _file = result.file
      }

      if (!_file) {
        return
      }

      const isS3 = endpoint.startsWith('https')
      const formData = new FormData()
      formData.append('file', file)

      const headers = (isS3
        ? {
          'Content-Type': _file.mimeType,
          // 'Content-Disposition': `attachment; filename=${encodeURIComponent(_file.name)}.${_file.extension}`,
          'Content-Disposition': 'inline',
        }
        : {}) as Headers

      const requestOptions = {
        method: 'PUT',
        headers,
        body: !isS3 ? formData : file,
      }

      try {
        const response = await fetch(endpoint, requestOptions)
        if (response.ok) { return _file }
        const error: any = new Error(response.statusText)
        /* FIXME: Ugly fix pour s'adapter à l'alerte manager */
        error.response = {
          data: {
            message: 'file.upload_error',
          },
        }
        throw error
      } catch (error) {
        await $api.file.rollbackFileUpload(_file.id)
        throw error
      }
    },
    saveAs: async (id: string, transformer: (fileInfo: IFile) => string) => {
      const infos = await $api.file.getFileInfo(id)
      const { extension, relativeUrl, name } = infos
      const isLocal = !['production', 'staging'].includes($config.nodeEnv)
      const buff = isLocal
        ? await $axios.get(`/file/${id}`, { responseType: 'blob' }).then(({ data }) => data)
        : await $axios.get(relativeUrl, { baseURL: '', responseType: 'blob' }).then(({ data }) => data)

      saveAs(isLocal ? new Blob([buff]) : buff, transformer ? transformer(infos) : `${name}.${extension}`)
    },
    getMimeTypeURL: (mimeType: string) => {
      // @ts-ignore
      const ext = mimeTypesURL[mimeType] ?? 'TXT'
      return `/mimeTypes/${ext}.png`
    },
    getFileIcon: (iconName: string) => {
      return `/advantages/abby-plus/${iconName}.png`
    },
    getStrapiUrl: (path?: string | null) => {
      if (!path) {
        return ''
      }
      if (envDev) {
        return 'http://localhost:1337' + path
      }
      return `/assets-strapi/${path.split('/')[3]}`
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $file: FileInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $file: FileInstance
  }
  interface Context {
    $file: FileInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $file: FileInstance
  }
}

export default FilePlugin
