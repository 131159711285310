import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  LegalStatus,
  ImpositionType,
  OnboardingStepsGestion,
  ReasonTVA,
  Recurrence,
  SignupType,
} from '@abby/core-legacy'
import { $enum } from 'ts-enum-util'

export interface GestionState {
  progression: number;
  newProgression: number;
  step: OnboardingStepsGestion | null;
  form: {
    company: {
      name: string | null,
      siret: string | null,
      creationDate: string | null;
      address: string | null,
      zipCode: string | null,
      cityCode: string | null;
      cityName: string | null;
      longitude: string | null,
      latitude: string | null,
      commercialName: string | null,
      vatNumber: string | null,
    },
    activity: {
      legalStatus: LegalStatus | null,
      activity: number | null,
      otherActivity: string | null,
      nafCode: string | null,
      fiscalRegime: string | null,
    },
    declaration: {
      impositionType: ImpositionType,
      recurrence: Recurrence | null,
      hasAcre: boolean | null
    },
    tva: {
      isActivated: boolean | null,
      reasonForNoTVA: ReasonTVA | null,
      recurrence: Recurrence | null,
      startTVA: string | null,
    },
    exit: {
      keepInformed: boolean;
    }
  };
}

export const state = (): GestionState => ({
  progression: 0,
  newProgression: 0,
  step: OnboardingStepsGestion.COMPANY,
  form: {
    company: {
      name: null,
      siret: null,
      creationDate: null,
      address: null,
      zipCode: null,
      cityCode: null,
      cityName: null,
      longitude: null,
      latitude: null,
      commercialName: null,
      vatNumber: null,
    },
    activity: {
      legalStatus: null,
      activity: null,
      otherActivity: null,
      nafCode: null,
      fiscalRegime: null,
    },
    declaration: {
      impositionType: ImpositionType.CLASSIC,
      recurrence: null,
      hasAcre: null,
    },
    tva: {
      isActivated: false,
      reasonForNoTVA: ReasonTVA.SEUIL,
      recurrence: null,
      startTVA: null,
    },
    exit: {
      keepInformed: true,
    },
  },
})

export const getters: GetterTree<GestionState, GestionState> = {
  step: state => state.step,
  form: state => state.form,
  progression: state => state.progression,
  newProgression: state => state.newProgression,
}

export const mutations: MutationTree<GestionState> = {
  CHANGE_STEP (state, value: OnboardingStepsGestion) {
    state.step = value
    const values = $enum(OnboardingStepsGestion).getValues()
    const position = values.indexOf(value)
    state.progression = Math.round(position / values.length * 100) || 10
  },
  CHANGE_NEW_STEP (state, value: OnboardingStepsGestion) {
    state.step = value
    const values = $enum(OnboardingStepsGestion).getValues()
    const position = values.indexOf(value)
    state.newProgression = Math.round(position / values.length * 100) || 10
  },
  UPDATE_FORM (state, value: any) {
    state.form = {
      ...state.form,
      ...value,
    }
  },
  SET_FORM (state, value: any) {
    state.form = value
  },
  RESET (_currentState: GestionState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
    // if (!process.browser) {
    //   return
    // }
    // delete localStorage.onboardingGestionStep
    // delete localStorage.onboardingGestionForm
  },
}

export const actions: ActionTree<GestionState, GestionState> = {
  async changeStep ({ commit }, value: string) {
    commit('CHANGE_STEP', value)
    await this.$api.onboarding.update({
      step: value,
    })
    const redirect = `/start/gestion/${value}`
    if (this.$router.currentRoute.path === redirect) { return }
    await this.$router.push(redirect)
  },
  async changeLocalStep ({ commit }, value: string) {
    if (!value) {
      this.$router.push('/start/gestion/company')
      return
    }
    commit('CHANGE_NEW_STEP', value)
    localStorage.onboardingGestionStep = value
    const redirect = `/start/gestion/${value}`
    if (this.$router.currentRoute.path === redirect) { return }
    await this.$router.push(window.location.search ? `${redirect}?${window.location.search}` : redirect)
  },
  setCompany ({ commit }, value: any) {
    commit('SET_COMPANY', value)
  },
  setForm ({ commit }, data: any) {
    commit('UPDATE_FORM', data)
  },
  async fetchOnboarding ({ commit }) {
    const result = await this.$api.onboarding.get()
    commit('CHANGE_STEP', result.step)
    commit('UPDATE_FORM', result.gestion)
  },
  loadOnboarding ({ commit, dispatch }) {
    if (!localStorage.onboardingGestionForm) {
      if (this.$router.currentRoute.path === '/start/gestion/company') { return }
      return this.$router.push('/start/gestion/company')
    }

    const form = JSON.parse(localStorage.onboardingGestionForm)
    commit('UPDATE_FORM', form)
    const step = localStorage.onboardingGestionStep
    dispatch('changeLocalStep', step)
  },
  saveOnboarding ({ getters }) {
    localStorage.onboardingGestionStep = getters.step
    localStorage.onboardingGestionForm = JSON.stringify(getters.form)
  },
  cleanLocalOnboarding () {
    delete localStorage.onboardingGestionStep
    delete localStorage.onboardingGestionForm
  },
  async updateOnboarding ({ getters }) {
    await this.$api.onboarding.update({
      gestion: getters.form,
      type: SignupType.GESTION,
    })
  },
  async submitOnboarding () {
    await this.$api.onboarding.submit()
  },
}
