
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { toArray } from '@abby/core-legacy'

@Component({
  components: {},
})
export default class ChoiceSelect extends Vue {
  @Prop({ default: false })
  isThemeLight!: boolean

  @Prop({ default: [] })
  selectedClasses!: string | string[]

  @Prop({ default: false })
  loading!: boolean

  @Prop()
  title!: string

  @Prop()
  subtitle!: string

  @Prop()
  subtitleClass!: string[]

  @Prop()
  success!: boolean

  @Prop()
  disabled!: boolean

  @Prop({ default: true })
  hasChecker!: boolean

  @Prop({ default: false })
  squareChecker!: boolean

  @Prop()
  imageSrc!: string

  @Prop({ default: 44 })

  imageSize!: number

  @Prop({ default: 32 })
  smAndDownImageSize!: number

  @Prop()
  selected!: boolean

  @Prop()
  titleNormal!: boolean

  @Prop({ default: 'start' })
  choiceAlignment!: 'start' | 'end' | 'center'

  get size () {
    return this.$vuetify.breakpoint.smAndDown ? this.smAndDownImageSize : this.imageSize
  }

  get choiceAlignmentClass () {
    return `choice-select-radio__${this.choiceAlignment}`
  }

  get selectedClassesObject () {
    return toArray(this.selectedClasses).reduce((obj, key) => {
      obj[key] = true
      return obj
    }, {})
  }
}
