import type {
  ILesson,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Lesson extends Resource {
  getAll (): Promise<ILesson[]> {
    return this.axios.$get('/academy/lesson/all')
  }
}
