import { Resource } from '../Resource';
import {
  EditBankItem,
  QueryBankAccounts, QueryBankItemConnect,
  ReadBank, ReadBankAccount, ReadBankItem, SynchronizeBankAccount,
} from '../../dto';

export class Bank extends Resource {
  async connect(query: QueryBankItemConnect): Promise<{ redirectUrl: string }> {
    return this.http.$get('/v2/banks/items/connect', {
      params: query,
    });
  }

  async getBanks(): Promise<ReadBank[]> {
    return this.http.$get('/v2/banks');
  }

  async getBankItems(): Promise<ReadBankItem[]> {
    return this.http.$get('/v2/banks/items');
  }

  async getAccountsByItemId(itemId: string): Promise<ReadBankAccount[]> {
    return this.http.$get(`/v2/banks/accounts/${itemId}`);
  }

  async getAccounts(query?: QueryBankAccounts): Promise<ReadBankAccount[]> {
    return this.http.$get('/v2/banks/accounts', { params: query });
  }

  async refreshBankItem(itemId: string): Promise<void> {
    return this.http.$post(`/v2/banks/items/refresh/${itemId}`);
  }

  async editBankItem(itemId: string, payload?: EditBankItem): Promise<{ redirectUrl: string }> {
    return this.http.$post(`/v2/banks/items/edit/${itemId}`, payload);
  }

  async synchronizeBankAccount(bankAccountId: string, payload: SynchronizeBankAccount): Promise<void> {
    return this.http.$post(`/v2/banks/accounts/${bankAccountId}/synchronize`, payload);
  }

  async unSynchronizeBankAccount(bankAccountId: string): Promise<void> {
    return this.http.$post(`/v2/banks/accounts/${bankAccountId}/desynchronize`);
  }

  async archiveBankItem(itemId: string): Promise<void> {
    return this.http.$post(`/v2/banks/items/archive/${itemId}`);
  }

  async deleteBankItem(itemId: string): Promise<void> {
    return this.http.$delete(`/v2/banks/items/${itemId}`);
  }
}
