import { Plugin } from '@nuxt/types'

export type Move = (location: any) => Promise<void>

const MovePlugin: Plugin = ({ app }, inject) => {
  inject('move', async (location: any) => {
    await app?.router?.push(location).catch((_) => {})
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $move: Move
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
   $move: Move
  }
  interface Context {
   $move: Move
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
   $move: Move
  }
}

export default MovePlugin
