import type { CreateApplication, ReadApplication, ReadApplicationCompany } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Application extends Resource {
  create (payload: CreateApplication): Promise<{ result: ReadApplication, secret: string }> {
    return this.axios.$post('/application', payload)
  }

  getAll (): Promise<ReadApplication[]> {
    return this.axios.$get('/application/all')
  }

  get (id: string): Promise<ReadApplication> {
    return this.axios.$get(`/application/${id}`)
  }

  getByClientId (clientId: string): Promise<ReadApplication> {
    return this.axios.$get(`/oauth2/application/${clientId}`)
  }

  getCompanyApplications (): Promise<ReadApplicationCompany[]> {
    return this.axios.$get('/application/company/all')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update (id: string, payload: any) {}
}
