import { ReadOrganization } from '@abby/shared'

export type OrganizationContactFormProps = {
  id?: string;
  firstname: string;
  lastname: string;
  emails?: string[];
  jobTitle?: string;
  phone?: string;
  notes?: string;
  defaultContact?: boolean;
  organization?: ReadOrganization;
};

export class OrganizationContactForm {
  id?: string;
  firstname: string;
  lastname: string;
  emails?: string[];
  jobTitle?: string;
  phone?: string;
  notes?: string;
  defaultContact?: boolean;
  organization?: ReadOrganization;

  private constructor (props: OrganizationContactFormProps) {
    this.id = props.id
    this.firstname = props.firstname
    this.lastname = props.lastname
    this.emails = props.emails
    this.jobTitle = props.jobTitle
    this.phone = props.phone
    this.notes = props.notes
    this.defaultContact = props.defaultContact
    this.organization = props.organization
  }

  public static create (props: OrganizationContactFormProps): OrganizationContactForm {
    return new OrganizationContactForm(props)
  }
}
