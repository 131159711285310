import { ReadBillingPayment, Money } from '@abby/shared'
import { BillingPayment } from '~/logic/contexts/billing/domain/entity/billingPayment.entity'

export class BillingPaymentHttpMapper {
  static toDomain (doc: ReadBillingPayment[]): BillingPayment[] {
    return doc.map((item) => {
      return BillingPayment.create({
        id: item.id,
        transactionId: item?.transactionId,
        method: item.paymentMethod,
        otherMethod: item?.otherMethod,
        amount: Money.create({ amountInCents: item.amount }),
        receivedAt: Number(item.receivedAt),
      })
    })
  }
}
