
import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import type { BasePaginate, CreateThirdParty, ReadThirdParty, UpdateThirdParty } from '@abby/core-legacy'
import { RootState } from '~/store/index'
import { ProviderDetailsPanelConfig } from '~/store/providerDetails'

export interface ProvidersQuery {
  search?: string;
}

export interface ProvidersPaginationQuery { page: number, limit: number, sortBy: string[] | null, sortDesc: boolean[] | null }

export interface ProvidersState {
  isProviderModalOpened: boolean;
  queryLoading: boolean;
  initialLoading: boolean;
  pagination: BasePaginate<ReadThirdParty> | null;
  query: ProvidersQuery;
  paginationQuery: ProvidersPaginationQuery;
  selectedProvider: ReadThirdParty | null;
  isProviderImportModalOpened: boolean;
}

export const state = (): ProvidersState => ({
  isProviderModalOpened: false,
  queryLoading: false,
  initialLoading: true,
  pagination: null,
  query: {},
  paginationQuery: {
    page: 1,
    limit: 15,
    sortBy: null,
    sortDesc: null,
  },
  selectedProvider: null,
  isProviderImportModalOpened: false,
})

export const getters: GetterTree<ProvidersState, RootState> = {
  pagination: state => state.pagination,
  query: state => state.query,
  isProviderModalOpened: state => state.isProviderModalOpened,
  providers: state => state.pagination?.data || [],
  initialLoading: state => state.initialLoading,
  queryLoading: state => state.queryLoading,
  paginationQuery: state => state.paginationQuery,
  selectedProvider: state => state.selectedProvider,
  isProviderImportModalOpened: state => state.isProviderImportModalOpened,
}

export const mutations = {
  SET_PAGINATION (state: ProvidersState, pagination: BasePaginate<ReadThirdParty>) {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: ProvidersState, value: boolean) {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: ProvidersState, value: boolean) {
    state.queryLoading = value
  },
  SET_IS_PROVIDER_MODAL_OPENED (state: ProvidersState, value: boolean) {
    state.isProviderModalOpened = value
  },
  SET_SELECTED_PROVIDER (state: ProvidersState, value: ReadThirdParty | null) {
    state.selectedProvider = value
  },
  SET_PAGINATION_QUERY (state: ProvidersState, value: ProvidersPaginationQuery) {
    state.paginationQuery = value
  },
  UPDATE_QUERY (state: ProvidersState, value: ProvidersQuery) {
    state.query = value
  },
  UPDATE_PROVIDER (state: ProvidersState, value: ReadThirdParty) {
    if (!state.pagination?.data?.length) { return }
    const index = state.pagination?.data?.findIndex(({ id }) => id === value.id)
    if (index === -1) { return }
    state.pagination.data.splice(index, 1, value)
  },
  REMOVE_PROVIDER (state: ProvidersState, _id: string) {
    if (!state.pagination?.data?.length) { return }
    const index = state.pagination?.data?.findIndex(({ id }) => id === _id)
    if (index === -1) { return }
    state.pagination.data.splice(index, 1)
  },
  SET_IS_PROVIDER_IMPORT_MODAL_OPENED (state: ProvidersState, value: boolean) {
    state.isProviderImportModalOpened = value
  },
  RESET (_currentState: ProvidersState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<ProvidersState, RootState> = {
  async fetchProviders ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.provider.paginate({
        ...getters.query,
        ...getters.paginationQuery,
        countWithoutFilters: true,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_QUERY_LOADING', false)
      commit('SET_INITIAL_LOADING', false)
    }
  },
  async createProvider ({ dispatch }, payload: CreateThirdParty) {
    const result = await this.$api.provider.create(payload)
    this.$busManager.emit('providerCreated', result)
    dispatch('fetchProviders')
  },
  setProviderImportModal ({ commit }, value: boolean) {
    commit('SET_IS_PROVIDER_IMPORT_MODAL_OPENED', value)
  },
  debounceFetchProviders: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchProviders')
  }, 300, { leading: true }),
  updatePaginationQuery ({ commit, dispatch }, query: Partial<ProvidersPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchProviders')
  },
  async updateProvider ({ commit, rootGetters }, { id, data }: { id: string, data: UpdateThirdParty }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const result = await this.$api.provider.update(id, data)
      commit('UPDATE_PROVIDER', result)

      const providerDetails = rootGetters['providerDetails/openedProviderDetails']
        .map(({ provider, config }: { provider: ReadThirdParty, config: ProviderDetailsPanelConfig }) => ({
          provider: {
            ...provider,
            ...(provider.id === result.id ? result : {}),
          },
          config,
        }))

      commit('providerDetails/SET_OPENED_PROVIDERS', providerDetails, { root: true })
      this.$busManager.emit('providerUpdated', result)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  setInitialLoading ({ commit }, value: boolean) {
    commit('SET_INITIAL_LOADING', value)
  },
  updateQuery ({ commit, dispatch }, query: Partial<ProvidersQuery>) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchProviders')
  },
  async deleteProvider ({ commit }, id: string) {
    commit('SET_QUERY_LOADING', true)
    try {
      await this.$api.provider.delete(id)
      commit('REMOVE_PROVIDER', id)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  async createProviders (_, payload: CreateThirdParty[]) {
    // try {
    await this.$api.provider.createMany(payload)
    // } catch (e) {
    //
    // }
  },
  setProviderModal ({ commit }, value: boolean) {
    commit('SET_IS_PROVIDER_MODAL_OPENED', value)
  },
  setSelectedProvider ({ commit }, value: ReadThirdParty) {
    commit('SET_SELECTED_PROVIDER', value)
  },
}
