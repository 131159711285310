import type {
  CreateUserLessonProgression,
  CreateUpdateUserAcademyReview,
  IUserAcademyReview,
  IUserLessonProgression,
  ICourse,
  UpdateUserLessonProgression,
  UserAcademyReviewQuery, BasePaginate,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Course extends Resource {
  getAll (): Promise<ICourse[]> {
    return this.axios.$get('/academy/course/all')
  }

  get (slug: string): Promise<ICourse> {
    return this.axios.$get(`/academy/course/${slug}`)
  }

  getUserLessonProgression (): Promise<IUserLessonProgression[]> {
    return this.axios.$get('/academy/userLessonProgression')
  }

  createUserLessonProgression (payload: CreateUserLessonProgression): Promise<IUserLessonProgression> {
    return this.axios.$post('/academy/userLessonProgression', payload)
  }

  updateUserLessonProgression (payload: UpdateUserLessonProgression): Promise<IUserLessonProgression> {
    return this.axios.$patch('/academy/userLessonProgression', payload)
  }

  getUserAcademyReview (params: any): Promise<IUserAcademyReview> {
    return this.axios.$post('/academy/userAcademyReview/get', params)
  }

  getAllUserAcademyReview (params: any): Promise<IUserAcademyReview[]> {
    return this.axios.$post('/academy/userAcademyReview/getAll', params)
  }

  getUserAcademyReviewsPaginated (params: UserAcademyReviewQuery): Promise<BasePaginate<IUserAcademyReview>> {
    return this.axios.$get('/academy/userAcademyReview/paginated', { params })
  }

  createOrUpdateUserAcademyReview (params: CreateUpdateUserAcademyReview): Promise<IUserAcademyReview> {
    return this.axios.$post('/academy/userAcademyReview/createOrUpdate', params)
  }
}
