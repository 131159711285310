import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  DeclarationType,
  GuideStepItem,
} from '@abby/core-legacy'

import type {
  CreateDeclarationStep, CreateRefundRequest,
  CreateVatConfiguration,
  ReadDeclarationPeriod,
  ReadVatConfiguration,
  UpdateVatConfiguration,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export interface VatState {
  vatConfiguration: ReadVatConfiguration | null,
  vatDetails: any[] | null,
  isDeclarationModalOpened: boolean,
  range: [string, string],
  periods: ReadDeclarationPeriod[],
  selectedPeriod: ReadDeclarationPeriod | null,
}

export const state = (): VatState => ({
  vatConfiguration: null,
  vatDetails: null,
  isDeclarationModalOpened: false,
  range: ['2022-01-02T16:22:19.492Z', '2022-08-02T16:22:19.492Z'],
  periods: [],
  selectedPeriod: null,
})

export const getters: GetterTree<VatState, RootState> = {
  vatConfiguration: state => state.vatConfiguration,
  vatDetails: state => state.vatDetails,
  range: state => state.range,
  isDeclarationModalOpened: state => state.isDeclarationModalOpened,
  periods: state => state.periods,
  selectedPeriod: state => state.selectedPeriod,
}

export const mutations: MutationTree<VatState> = {
  SET_VAT_CONFIGURATION (state, value: ReadVatConfiguration) {
    state.vatConfiguration = value
  },
  SET_VAT_DETAILS (state, value: any[]) {
    state.vatDetails = value
  },
  SET_VAT_PERIODS (state, value: ReadDeclarationPeriod[]) {
    state.periods = value
  },
  SET_RANGE (state, value: [string, string]) {
    state.range = value
  },
  SET_IS_DECLARATION_MODAL_OPENED (state, value: boolean) {
    state.isDeclarationModalOpened = value
  },
  SET_SELECTED_PERIOD (state, value: ReadDeclarationPeriod | null) {
    state.selectedPeriod = value
  },
  UPDATE_PERIOD (state, value: ReadDeclarationPeriod) {
    const index = state.periods.findIndex(({ start }) => start === value.start)
    if (index === -1) { return }
    state.periods.splice(index, 1, value)
  },
  RESET (_currentState: VatState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<VatState, RootState> = {
  async createVatConfiguration ({ commit, dispatch }, payload: CreateVatConfiguration) {
    const result = await this.$api.vat.createConfiguration(payload)
    commit('SET_VAT_CONFIGURATION', result)
    dispatch('fetchVatConfiguration')
  },
  async updateVatConfiguration ({ commit, dispatch }, payload: UpdateVatConfiguration) {
    const result = await this.$api.vat.updateConfiguration(payload)
    if (result.hasVat && result.isActivated && result.vatNumber) {
      this.$help.completeGuideStep(GuideStepItem.CONFIGURE_YOUR_VAT)
    }
    commit('SET_VAT_CONFIGURATION', result)
    dispatch('fetchVatConfiguration')
  },
  async fetchVatConfiguration ({ commit }) {
    const result = await this.$api.vat.getConfiguration()
    commit('SET_VAT_CONFIGURATION', result)
  },
  setSelectedPeriod ({ commit }, payload: ReadDeclarationPeriod | null) {
    commit('SET_SELECTED_PERIOD', payload)
  },
  setRange ({ commit }, value: [string, string]) {
    commit('SET_RANGE', value)
  },
  setPeriods ({ commit }, value: ReadDeclarationPeriod[]) {
    commit('SET_VAT_PERIODS', value)
  },
  async fetchPeriods ({ commit }) {
    const result = await this.$api.declaration.getDeclarationPeriods(DeclarationType.VAT)
    commit('SET_VAT_PERIODS', result)
  },
  async updateStep ({ commit }, payload: CreateDeclarationStep) {
    const result = await this.$api.declaration.updateStep(payload)
    commit('SET_SELECTED_PERIOD', result)
    commit('UPDATE_PERIOD', result)
  },
  async updateRefund ({ commit }, payload: CreateRefundRequest) {
    const result = await this.$api.declaration.vatAskForRefund({
      start: this.$dayjs(payload.start).toDate(),
      end: this.$dayjs(payload.end).toDate(),
      amount: payload.amount,
    })
    commit('SET_SELECTED_PERIOD', result)
    commit('UPDATE_PERIOD', result)
  },
  setIsDeclarationModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_DECLARATION_MODAL_OPENED', value)
  },
  async fetchVatDetails ({ commit, getters }) {
    const result = await this.$api.vat.getDetails({
      start: getters.range[0],
      end: getters.range[1],
    })
    commit('SET_VAT_DETAILS', result)
  },
}
