export enum SubscriptionProductType {
  ABBY_FREE = 'abby_free',
  ABBY_START = 'abby_start',
  ABBY_CREATION_START = 'abby_creation_start',
  ABBY_CREATION_START_PREMIUM = 'abby_creation_start_premium',
  ABBY_CREATION_START_BUSINESS = 'abby_creation_start_business',
  ABBY_PRO = 'abby_pro',
  ABBY_BUSINESS = 'abby_business',
  ABBY_CE = 'abby_ce',
}
