
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { IBillingConfiguration, ReadTimeRecord } from '@abby/core-legacy'

@Component
export default class TimeRecordMenu extends Vue {
  @Prop({ required: true })
  timeRecord!: ReadTimeRecord

  @Prop()
  disableSelection!: boolean

  @Prop()
  disableSuppression!: boolean

  @Getter('selection/isActivated')
  isSelectionModeActivated!: boolean

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration | null

  get items () {
    const billingAction = {
      text: 'Facturer',
      action: this.createInvoice,
      disabled: this.billingConfiguration?.test,
    }

    return [
      ...!this.isSelectionModeActivated
        ? [
          ...!this.billingConfiguration?.test
            ? [billingAction]
            : [],
          ...!this.disableSuppression
            ? [{
              text: 'Supprimer',
              action: this.delete,
            }]
            : [],
          {
            text: 'Modifier',
            action: this.edit,
          },
        ]
        : [],
      ...!this.disableSelection
        ? [{
          text: 'Sélectionner',
          action: this.activeSelectionMode,
        }]
        : []]
  }

  async confirmDelete () {
    return await this.$confirmManager.ask({
      title: 'Confirmez',
      content: 'Êtes vous sûr de vouloir supprimer cet enregistrement ?',
      cancelText: 'Annuler',
    })
  }

  async createInvoice () {
    try {
      if (this.billingConfiguration?.test) {
        return
      }
      await this.$store.dispatch('timeTracking/createInvoiceFromTimeRecords', [this.timeRecord])
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  async delete () {
    try {
      if (!(await this.confirmDelete())) {
        return
      }
      await this.$store.dispatch('timeTracking/deleteTimeRecord', this.timeRecord.id)
      this.$alertsManager.success("L'enregistrement a bien été supprimé")
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  edit () {
    this.$store.dispatch('timeTracking/openCreateOrEditPanel', this.timeRecord)
  }

  activeSelectionMode () {
    this.$store.dispatch('selection/activeSelectionMode', this.timeRecord)
  }
}

