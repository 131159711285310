export enum AbbyAdministrator {
  NICOLAS = '60d05212-0627-4b08-9eea-ec4ec3dea987',
  CORENTIN = '6138bd2f-b434-4ba1-954a-0374a49cc10e',
  BENJAMIN = '60be5af8-b917-47e3-bda8-0adce898c29f',
  JEREMY = '60be598d-02cc-4fb1-b8f7-fc06e43cc1a8',
  PATRICIA = '64108686-9571-4cc5-9075-ae183e4f5afd',
  VIRGINIE = '642ad6ef-c5b4-4bc4-8531-fae955efe600',
}

export const SlackChannels = {
  [AbbyAdministrator.BENJAMIN]: 'U02F5MFEXHB',
  [AbbyAdministrator.CORENTIN]: 'U02NVE52HFD',
};
