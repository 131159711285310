import { Resource } from '../Resource';
import { ReadAddress } from '../../dto';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

export class AddressResource extends Resource {
  search(query: QueryParams<{
    search: string
  }>): Promise<ReadAddress[]> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/address', { params, cancelToken });
  }
}
