export enum SignupType {
  GESTION = 'gestion',
  CREATION = 'creation',
  ADMINISTRATION = 'administration',
}

export enum RegisteredType {
  GESTION = 1,
  CREATION = 2,
  ADMINISTRATION = 3,
}

export enum ProviderSignUp {
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
  PASSWORD = 'password',
  CUSTOM = 'custom',
}

export const RegisteredTypeColor = {
  [RegisteredType.GESTION]: 'purple lighten-4',
};
