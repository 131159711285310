import { ReadCustomerStatistics } from '@abby/shared'
import { ClientStatistics } from '~/logic/contexts/client/domain/valueObject/clientStatistics.valueObject'
import { Amount } from '~/logic/contexts/shared-kernel/domain/valueObject/amount.valueObject'

export class ClientStatisticsHttpMapper {
  static toDomain (result: ReadCustomerStatistics): ClientStatistics {
    return ClientStatistics.create({
      countLate: result.count.late,
      amountLate: Amount.create(result.amount.late),
      amountPaid: Amount.create(result.amount.paid),
      revenuesData: result.revenueData,
    })
  }
}
