import {
  QueryIntegration,
} from '@abby/core-legacy'
import type {
  IIntegrationApp, IIntegrationUseCase, StrapiPaginated,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Integration extends Resource {
  getUseCases (query?: QueryIntegration): Promise<StrapiPaginated<IIntegrationUseCase>> {
    return this.axios.$get('/integration/useCase/paginated', { params: query })
  }

  getUseCase (id: number): Promise<IIntegrationUseCase> {
    return this.axios.$get(`/integration/useCase/${id}`)
  }

  getApps (query?: QueryIntegration): Promise<StrapiPaginated<IIntegrationApp>> {
    return this.axios.$get('/integration/app/paginated', { params: query })
  }

  getApp (id: number): Promise<IIntegrationApp> {
    return this.axios.$get(`/integration/app/${id}`)
  }
}
