import { ActionTree, GetterTree } from 'vuex'
import { RootState } from '~/store/index'

export interface CommercialOffersState {
  modals: {
    meetingWithAnExpert: boolean,
  }
}

export type CommercialOffersModals = Extract<keyof CommercialOffersState['modals'], string>

export const state = (): CommercialOffersState => ({
  modals: {
    meetingWithAnExpert: false,
  },
})

export const getters: GetterTree<CommercialOffersState, RootState> = {
  modals (state: CommercialOffersState) {
    return state.modals
  },
}

export const mutations = {
  OPEN_MODAL (state: CommercialOffersState, key: CommercialOffersModals) {
    state.modals[key] = true
  },
  CLOSE_MODAL (state: CommercialOffersState, key: CommercialOffersModals) {
    state.modals[key] = false
  },
  RESET (_currentState: CommercialOffersState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {}
