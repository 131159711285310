import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { ReadCountSignature } from '@abby/core-legacy'

export interface SignatureState {
  counts: ReadCountSignature,
}

export const state = (): SignatureState => ({
  counts: {
    debits: 0,
    credits: 0,
    remaining: 0,
  },
})

export const getters: GetterTree<SignatureState, SignatureState> = {
  counts: state => state.counts,
}

export const mutations: MutationTree<SignatureState> = {
  SET_COUNTS (state, value: ReadCountSignature) {
    state.counts = value
  },
  RESET (_currentState: SignatureState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<SignatureState, SignatureState> = {
  async fetchCounts ({ commit }) {
    const counts = await this.$api.signature.count()
    commit('SET_COUNTS', counts)
  },
}
