import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { CompanyDocumentType, Reason } from '@abby/core-legacy'
import type { IReason } from '@abby/core-legacy'

export interface ReasonState {
  reasons: IReason[];

}

export interface ReasonGroup {
  reasons: IReason[];
}

export const state = (): ReasonState => ({
  reasons: Reason,
})

export const getters: GetterTree<ReasonState, ReasonState> = {
  reasons: state => state.reasons,
  filterReasons: state => (documentType: CompanyDocumentType | null) => {
    if (documentType) {
      return state.reasons.filter((reason: IReason) => reason.documentType === documentType || reason.allDocumentTypes)
    } else {
      return state.reasons.filter((reason: IReason) => !reason.documentType && !reason.allDocumentTypes)
    }
  },
}

export const mutations: MutationTree<ReasonState> = {
}

export const actions: ActionTree<ReasonState, ReasonState> = {

}
