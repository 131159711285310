import { OrganizationForm } from '~/logic/contexts/client/domain/form/organization.form'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export const useUpdateOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organization: OrganizationForm) => {
    const editedOrganization = await organizationRepository.updateOrganization(organization)
    alertManager.success('Le contact a bien été mis à jour')
    busManager.emit('organizationUpdated', editedOrganization)
    return editedOrganization
  }
}
