import { ActionTree, GetterTree } from 'vuex'
import {
  ReminderFrequency,
  cleanBillingConfiguration,
  GuideStepItem,
} from '@abby/core-legacy'

import type {
  IBillingConfiguration,
  ReadStripeAccount,
  ReadStripeAccountLink,
  UpdateBillingConfiguration,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export interface BillingConfigurationState {
  billingConfiguration: IBillingConfiguration | null
  billingConfigurationLoading: boolean
  stripeAccountLink: ReadStripeAccountLink | null
  stripeAccount: ReadStripeAccount | null
  stripeAccountLoading: boolean
  modals: {
    generalTermsAndConditionsOfSale: boolean
    headerNote: boolean
    footerNote: boolean
    numberFormat: boolean
    initialNumber: boolean
    paymentMethods: boolean
    paymentDelay: boolean
    latePenalty: boolean
    vatMention: boolean
    lumpSumCompensation: boolean
    mentionMediator: boolean
    discountAdvancePayment: boolean
    logo: boolean
    additionalLogos: boolean
    shape: boolean
    reminderFrequency: boolean
    bankInformation: boolean
    firstTimeConfiguration: boolean
    style: boolean
    displayFields: boolean
  }
}

export type BillingConfigurationModals = Extract<keyof BillingConfigurationState['modals'], string>

export const state = (): BillingConfigurationState => ({
  billingConfiguration: null,
  billingConfigurationLoading: true,
  stripeAccountLink: null,
  stripeAccount: null,
  stripeAccountLoading: false,
  modals: {
    bankInformation: false,
    generalTermsAndConditionsOfSale: false,
    headerNote: false,
    footerNote: false,
    numberFormat: false,
    initialNumber: false,
    paymentMethods: false,
    paymentDelay: false,
    latePenalty: false,
    vatMention: false,
    lumpSumCompensation: false,
    mentionMediator: false,
    discountAdvancePayment: false,
    logo: false,
    additionalLogos: false,
    shape: false,
    reminderFrequency: false,
    firstTimeConfiguration: false,
    style: false,
    displayFields: false,
  },
})

export const getters: GetterTree<BillingConfigurationState, RootState> = {
  billingConfiguration (state: BillingConfigurationState) {
    return state.billingConfiguration
  },
  stripeAccountLink (state: BillingConfigurationState) {
    return state.stripeAccountLink
  },
  stripeAccount (state: BillingConfigurationState) {
    return state.stripeAccount
  },
  stripeAccountLoading (state: BillingConfigurationState) {
    return state.stripeAccountLoading
  },
  modals (state: BillingConfigurationState) {
    return state.modals
  },
  tiersPrestationValidated (state: BillingConfigurationState) {
    return state.billingConfiguration?.tiersPrestationActivated &&
        state.billingConfiguration?.tiersPrestationValidated &&
        state.billingConfiguration?.tiersPrestationCredentials?.clientSecret &&
        state.billingConfiguration?.tiersPrestationCredentials?.clientId
  },
  defaultReminder (_) {
    return {
      inCopy: true,
      frequency: ReminderFrequency.EVERY_WEEK,
      numberOfRemindersToSend: 3,
      active: false,
      recipients: [],
    }
  },
}

export const mutations = {
  SET_BILLING_CONFIGURATION (state: BillingConfigurationState, value: IBillingConfiguration | null) {
    if (value) {
      delete value.__v
      delete value._id
      // @ts-ignore
      delete value.companyId
    }
    state.billingConfiguration = value
  },
  SET_ACCOUNT_LINK (state: BillingConfigurationState, value: ReadStripeAccountLink | null) {
    state.stripeAccountLink = value
  },
  SET_STRIPE_ACCOUNT (state: BillingConfigurationState, value: ReadStripeAccount | null) {
    state.stripeAccount = value
  },
  SET_STRIPE_ACCOUNT_LOADING (state: BillingConfigurationState, value: boolean) {
    state.stripeAccountLoading = value
  },
  SET_BILLING_CONFIGURATION_LOADING (state: BillingConfigurationState, value: boolean) {
    state.billingConfigurationLoading = value
  },
  OPEN_MODAL (state: BillingConfigurationState, key: BillingConfigurationModals) {
    state.modals[key] = true
  },
  CLOSE_MODAL (state: BillingConfigurationState, key: BillingConfigurationModals) {
    state.modals[key] = false
  },
  RESET (_currentState: BillingConfigurationState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  async updateBillingConfiguration ({ commit }, body: Partial<UpdateBillingConfiguration>) {
    const billingConfiguration = await this.$api.billing.updateConfiguration(cleanBillingConfiguration({
      ...body,
    }))
    // Permet de savoir que l'utilisateur a mis à jour sa billing configuration depuis les réglages. Beaucoup de sous composants sont utilisés donc on test avec le path
    if (window.location.pathname === '/settings/billing') {
      this.$help.completeGuideStep(GuideStepItem.SAVE_TIME_BY_CONFIGURING_DEFAULT_INFORMATION)
    }
    this.$busManager.emit('billingConfigurationUpdated', billingConfiguration)
    commit('SET_BILLING_CONFIGURATION', billingConfiguration)
  },
  async fetchBillingConfiguration ({ commit }) {
    commit('SET_BILLING_CONFIGURATION_LOADING', true)
    try {
      const billingConfiguration = await this.$api.billing.getConfiguration()
      commit('SET_BILLING_CONFIGURATION', billingConfiguration)
    } finally {
      commit('SET_BILLING_CONFIGURATION_LOADING', false)
    }
  },
  async updateNovaNumber ({ commit, dispatch }, novaNumber: string) {
    commit('SET_BILLING_CONFIGURATION_LOADING', true)
    await this.$api.billing.updateNovaNumber(novaNumber)
    await dispatch('fetchBillingConfiguration')
  },
  async fetchBillingPaymentAccountLink ({ state, commit }) {
    try {
      // @ts-ignore
      const referer = this.$config.nodeEnv !== 'development' ? `https://${window.location.hostname}` : undefined
      const result = await this.$api.stripe.getAccountLink({ referer })
      commit('SET_ACCOUNT_LINK', result)
    } catch (e) {
      if (e.response?.status === 400 && state.stripeAccount) {
        commit('SET_ACCOUNT_LINK', null)
        this.$alertsManager.error('Votre compte configuré pour le paiement en ligne rencontre actuellement un problème avec Stripe. Contactez le support Abby pour plus d\'informations.')
      }
    }
  },
  async fetchStripeAccount ({ commit }) {
    try {
      commit('SET_STRIPE_ACCOUNT_LOADING', true)
      const result = await this.$api.stripe.getAccount()
      commit('SET_STRIPE_ACCOUNT', result)
    } finally {
      commit('SET_STRIPE_ACCOUNT_LOADING', false)
    }
  },
  async createStripeAccount ({ commit }) {
    const result = await this.$api.stripe.createAccount()
    commit('SET_STRIPE_ACCOUNT', result)
  },
  openModal ({ commit }, key: BillingConfigurationModals) {
    commit('OPEN_MODAL', key)
  },
  closeModal ({ commit }, key: BillingConfigurationModals) {
    commit('CLOSE_MODAL', key)
  },
  setModal ({ commit }, params: { key: BillingConfigurationModals, value: boolean }) {
    commit(params.value ? 'OPEN_MODAL' : 'CLOSE_MODAL', params.key)
  },
}
