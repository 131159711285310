import { AxiosError } from 'axios'

export const retryFn = (failureCount: number, _error: unknown) => {
  const error = _error as AxiosError
  const isRetryable =
      error.response?.status !== 500 &&
      error.response?.status !== 404 &&
      error.response?.status !== 400

  if (isRetryable && failureCount < 4) {
    return Math.min(1000 * 2 ** failureCount, 30000)
  }
  return false
}
