import { ActionTree, GetterTree } from 'vuex'
import {
  BillingState,
  BillingTemplate,
  BillingType,
  convertCurrencyAmount,
  CreationCompanyStep,
  Currency,
  customerClientFullName,
  customerName,
  defaultColumnsConfig,
  discountAdvancePaymentTraduction,
  documentTextNumber,
  formatCents,
  formatVatRate,
  getCompleteTotalAmount,
  getCompleteVatDetails,
  getLineAmount,
  latePenaltyTraduction,
  LegalStatus,
  lumpSumCompensationTraduction,
  paymentDelayTraduction,
  PaymentMethod,
  paymentMethodsTraduction,
  ProductType,
  productTypeRequiredMentionTraduction,
  productUnitTraduction,
  tpUnitTraduction,
  vatMentionTraduction,
  currencyPattern,
  PaymentDelay,
  getDueDate,
  ParcelAddress,
} from '@abby/core-legacy'
import dayjs from 'dayjs'
import { UrssafAddressLetter, Country } from '@abby/shared'
import type {
  BillingDocumentPDFData,
  IBilling,
  ICompany,
  ICreationCompany,
  ICustomer,
  IPrice,
  ReadTimeRecord,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'
import { IAuthUser } from '~/store/auth'

export interface BillingCustomerTPForEmail {
  modalOpened: boolean,
  customer: ICustomer | null
}

const billingDocumentToBillingDocumentPDFData = ({ billingDocument, createFromBillingDocument, company, creation, user, data }: { billingDocument: Partial<IBilling> | null, createFromBillingDocument?: Partial<IBilling> | null, company: ICompany, creation: ICreationCompany, user: IAuthUser, data?: Partial<BillingDocumentPDFData> }): BillingDocumentPDFData | null => {
  let creationIsFinished = true
  let companyName = company.name
  const locale = data?.locale || 'fr'
  if (company.creationId) {
    // Si la société est en cours de création, alors le nom de la company n'existe pas. Pour que la facture soit valide, le nom, le prénom et la mention SIRET en cours d'attribution sont obligatoires
    creationIsFinished = !!creation?.steps[CreationCompanyStep.COMPANY_CREATED]?.finishedAt
    // Le companyName devient le nom et prénom de l'utilisateur
    companyName = `${user.firstname || ''} ${user.lastname || ''}`.trim()
  }

  const hasCustomerCompany = !!billingDocument?.customer?.customerCompany?.name
  const recipientName = billingDocument?.customer ? customerName(billingDocument?.customer) : ''
  const recipientCommercialName = billingDocument?.customer?.customerCompany?.commercialName
  const recipientCompanyContactName = customerClientFullName(billingDocument?.customer)
  const customer = billingDocument?.customer

  const billingAmount: IPrice = getCompleteTotalAmount(billingDocument as IBilling, createFromBillingDocument as IBilling)

  const currency = billingDocument?.currency || Currency.EUR
  const currencyRate = billingDocument?.currencyRate || 1

  // UGLY FIX
  const isAssetFromAdvance = billingDocument?.billingType === BillingType.ASSET && !!createFromBillingDocument && createFromBillingDocument.billingType === BillingType.ADVANCE
  let vatDetails = billingAmount.vatDetails
  let vatAmount = billingAmount.vatAmount
  let priceTotalTax = billingAmount.priceTotalTax
  const remainingVatDetails = billingAmount.remainingVatDetails

  if (isAssetFromAdvance && (createFromBillingDocument?.createdFromEstimateId || createFromBillingDocument?.createdFromPurchaseOrderId)) {
    vatDetails = getCompleteVatDetails(billingDocument as IBilling, createFromBillingDocument.createdFromEstimate || createFromBillingDocument.createdFromPurchaseOrder)
    vatAmount = vatDetails.reduce((acc, value) => acc + value.amount, 0)
    priceTotalTax += vatAmount
  }

  const products = billingDocument?.product?.map((p) => {
    const lineAmount = getLineAmount(p, currencyRate)
    const linePriceTotalTax = (billingDocument.billingType === BillingType.ADVANCE || isAssetFromAdvance) ? priceTotalTax : lineAmount.priceTotalTax
    const precision = currencyPattern[currency].precision > 0 ? currencyPattern[currency].precision + 1 : 0

    return {
      reference: p.reference || null,
      designation: p.designation || null,
      description: p.description || null,
      quantity: p.quantity,
      productUnit: !billingDocument?.tiersPrestationIsActivatedForThisBilling
        ? p.productUnit ? window.$nuxt.$t(productUnitTraduction(p.productUnit) || '', locale) as string : ''
        : p.tpUnit ? window.$nuxt.$t(tpUnitTraduction(p.tpUnit) || '', locale) as string : '',
      unitPrice: formatCents(convertCurrencyAmount(p.unitPrice || 0, currencyRate), currency, precision).text,
      vatPercentage: formatVatRate(p.vatPercentage || 0),
      priceWithoutTaxBeforeDiscount: formatCents(lineAmount.priceWithoutTaxBeforeDiscount, currency).text,
      priceWithoutTax: formatCents(lineAmount.priceWithoutTax, currency).text,
      priceTotalTax: formatCents(linePriceTotalTax, currency).text,
      discount: lineAmount.discount ? formatCents(-lineAmount.discount, currency).text : undefined,
      productTypeRequiredMention: p.productType !== ProductType.DISBURSEMENT
        ? window.$nuxt.$t(productTypeRequiredMentionTraduction(p.productType
          ? [ProductType.SALE_OF_MANUFACTURED_GOODS, ProductType.SALE_OF_GOODS].includes(p.productType)
          : false,
        p.isDeliveryOfGood) || '', locale) as string || ''
        : '',
    }
  }) || []

  const associatedAdvances = billingAmount.associatedAssetDetails
    ? billingAmount.associatedAssetDetails.map(a => ({
      number: a.number,
      priceTotalTax: formatCents(a.priceTotalTax, currency).text,
    }))
    : null
  const totalAssociatedAsset = billingAmount.totalAssociatedAsset ? formatCents(billingAmount.totalAssociatedAsset, currency).text : null
  const remainingPriceToPayTotalTax = billingAmount.totalAssociatedAsset ? formatCents(billingAmount.remainingPriceToPayTotalTax || 0, currency).text : null
  const remainingPriceToPayWithoutTax = billingAmount.totalAssociatedAsset ? formatCents(billingAmount.remainingPriceToPayWithoutTax || 0, currency).text : null
  const template = data?.template || billingDocument?.template || BillingTemplate.TEMPLATE_1
  const columns = (template === BillingTemplate.TEMPLATE_1) ? defaultColumnsConfig({ hasVat: company?.hasVat || false }) : data?.columns || []

  // @ts-ignore
  delete data.columns
  // @ts-ignore
  delete data.template

  let logosFooter: { image: string, type: string }[] = []
  if (billingDocument?.additionalLogos?.length) {
    logosFooter = billingDocument.additionalLogos.map(ad => ({
      image: (ad.relativeUrl ? ad.relativeUrl : ad.url) || '',
      type: 'url',
    }))
  }

  const urssafAddressLetterToLetter = (urssafAddressLetter?: ParcelAddress) => {
    if (!urssafAddressLetter) { return null }
    const _urssafAddressLetterToLetter = {
      B: UrssafAddressLetter.BIS,
      T: UrssafAddressLetter.TER,
      Q: UrssafAddressLetter.QUATER,
      C: UrssafAddressLetter.QUINQUIES,
    }
    return _urssafAddressLetterToLetter[urssafAddressLetter]
  }

  const address = billingDocument?.customer?.location?.address

  const billingCountry = customer?.location?.country && customer?.location?.country.length > 2
    ? window.$nuxt.$t(`countries.${Country.getCountryCode(customer.location.country)}`, locale).toString()
    : window.$nuxt.$t(`countries.${customer?.location?.country}`, locale).toString()

  const deliveryCountry = customer?.deliveryLocation?.country && customer?.deliveryLocation?.country.length > 2
    ? window.$nuxt.$t(`countries.${Country.getCountryCode(customer.deliveryLocation.country)}`, locale).toString()
    : window.$nuxt.$t(`countries.${customer?.deliveryLocation?.country}`, locale).toString()

  const additionalAddress = billingDocument?.customer?.location?.additionalAddress

  return {
    // Utils
    BillingType,
    BillingState,
    PaymentMethod,
    BillingTemplate,
    LegalStatus,
    // Type
    billingType: billingDocument?.billingType || null,
    billingState: billingDocument?.billingState || null,
    // Number
    number: billingDocument?.number || null,
    // Logo
    logo: billingDocument?.logo?.url || null,
    style: billingDocument?.colors?.primary && !billingDocument?.style
      ? { color: { primary: billingDocument?.colors?.primary }, background: { primary: billingDocument?.colors?.primary } }
      : billingDocument?.style || null,
    shape: billingDocument?.shape?.url || null,
    social: billingDocument?.social || null,
    template,
    // Emitter
    creationIsFinished,
    emitter: {
      name: companyName || null,
      fullName: user.fullName || null,
      email: data?.displayTextEmail || user.email || null,
      phone: data?.displayTextPhoneNumber || user.phone || null,
      commercialName: company.commercialName || null,
      siret: company.siret || null,
      vatNumber: company.vatNumber || null,
      rcsNumber: company.rcsNumber || null,
      address: company.address || null,
      additionalAddress: company.additionalAddress || null,
      zipCode: company.zipCode || null,
      city: company.city?.name || null,
      country: window.$nuxt.$t('countries.FR', locale).toString(),
      hasVat: !!company?.hasVat,
      legalStatus: company.legalStatus || null,
      sapAgreement: company?.siren && billingDocument?.displaySAPAgreement ? `SAP${company?.siren}` : null,
    },
    // Recipient
    recipient: {
      hasCompany: hasCustomerCompany,
      name: recipientName && recipientName !== '' ? recipientName : null,
      recipientCompanyContactName: recipientCompanyContactName || null,
      commercialName: recipientCommercialName || null,
      siret: hasCustomerCompany ? customer?.customerCompany?.siret || null : null,
      vatNumber: hasCustomerCompany ? customer?.customerCompany?.vatNumber || null : null,
      address: address || null,
      additionalAddress: additionalAddress || null,
      zipCode: customer?.location?.zipCode || null,
      city: customer?.location?.city || null,
      deliveryLocationIsSame: customer?.deliveryLocationIsSame || false,
      deliveryAddress: customer?.deliveryLocation?.address || null,
      displayDeliveryAddress: !!billingDocument?.displayDeliveryAddress,
      deliveryAdditionalAddress: customer?.deliveryLocation?.additionalAddress || null,
      deliveryZipCode: customer?.deliveryLocation?.zipCode || null,
      deliveryCity: customer?.deliveryLocation?.city || null,
      country: customer?.location?.country ? billingCountry : null,
      deliveryCountry: customer?.deliveryLocation?.country ? deliveryCountry : null,
    },
    // Payment
    hasTransferPaymentMethod: !!billingDocument?.paymentMethod?.value?.includes(PaymentMethod.TRANSFER),
    paymentDelay: billingDocument?.paymentDelay?.value ? window.$nuxt.$tc(paymentDelayTraduction(billingDocument?.paymentDelay, { showValue: true }) || '', billingDocument?.paymentDelay?.other ? dayjs(billingDocument?.paymentDelay?.other).startOf('d').diff(dayjs(billingDocument?.date).startOf('d'), 'd') : 0, locale) as string : null,
    latePenalty: billingDocument?.latePenalty?.value ? window.$nuxt.$t(latePenaltyTraduction(billingDocument?.latePenalty) || '', locale) as string : null,
    lumpSumCompensation: billingDocument?.lumpSumCompensation?.value && hasCustomerCompany ? window.$nuxt.$t(lumpSumCompensationTraduction(billingDocument?.lumpSumCompensation) || '', locale) as string : null,
    discountAdvancePayment: billingDocument?.discountAdvancePayment?.value ? window.$nuxt.$t(discountAdvancePaymentTraduction(billingDocument?.discountAdvancePayment) || '', locale) as string : null,
    paymentMethod: billingDocument?.paymentMethod?.value && billingDocument?.paymentMethod.value?.length > 0 ? paymentMethodsTraduction(billingDocument?.paymentMethod, window.$nuxt, locale) : null,
    bank: billingDocument?.bankInformation?.bank || null,
    iban: billingDocument?.bankInformation?.iban || null,
    bic: billingDocument?.bankInformation?.bic || null,
    // Notes
    headerNote: billingDocument?.headerNote || null,
    footerNote: billingDocument?.footerNote || null,
    // CGV
    generalTermsAndConditionsOfSale: billingDocument?.generalTermsAndConditionsOfSale || null,
    mentionMediator: !hasCustomerCompany ? billingDocument?.mentionMediator || null : null,
    // Dates
    date: billingDocument?.date ? window.$nuxt.$dayjs(billingDocument?.date).format('DD/MM/YYYY') : window.$nuxt.$dayjs().format('DD/MM/YYYY'),
    dueDate: window.$nuxt.$dayjs(getDueDate(billingDocument?.paymentDelay?.value ? billingDocument.paymentDelay : { value: PaymentDelay.AT_RECEPTION }, billingDocument?.date ? new Date(billingDocument?.date) : new Date())).format('DD/MM/YYYY'),
    validityDate: billingDocument?.validityDate ? window.$nuxt.$dayjs(billingDocument?.validityDate).format('DD/MM/YYYY') : null,
    deliveryDate: billingDocument?.deliveryDate ? window.$nuxt.$dayjs(billingDocument?.deliveryDate).format('DD/MM/YYYY') : null,
    paidDate: billingDocument?.paidAt ? window.$nuxt.$dayjs(billingDocument?.paidAt).format('DD/MM/YYYY') : null,
    // Products
    products,
    // Totals
    priceWithoutTaxBeforeDiscount: formatCents(billingAmount.priceWithoutTaxBeforeDiscount, currency).text as string,
    discount: formatCents(billingAmount.discount, currency).text,
    priceWithoutTax: formatCents(billingAmount.priceWithoutTax, currency).text,
    vatAmount: formatCents(vatAmount, currency).text,
    priceTotalTax: formatCents(priceTotalTax, currency).text,
    vatDetails: vatDetails?.map(v => ({ name: formatVatRate(v.vatPercentage), amount: formatCents(v.amount, currency).text })) || [],
    remainingVatDetails: remainingVatDetails?.map(v => ({ name: formatVatRate(v.vatPercentage), amount: formatCents(v.amount, currency).text })) || [],
    remainingVatAmount: billingAmount?.remainingVatAmount ? formatCents(billingAmount.remainingVatAmount, currency).text : undefined,
    hasAssociatedAdvances: !!(associatedAdvances && associatedAdvances.length > 0),
    hasDiscount: !!billingAmount.discount,
    associatedAdvances,
    totalAssociatedAsset,
    remainingPriceToPayTotalTax,
    remainingPriceToPayWithoutTax,
    // VAT Mention
    vatMention: billingDocument?.vatMention ? window.$nuxt.$t(vatMentionTraduction(billingDocument?.vatMention) || '', locale) as string : null,
    // Information
    displayMemberOfAnApprovedAssociation: !!billingDocument?.displayMemberOfAnApprovedAssociation,
    displayGoodForApproval: !!billingDocument?.displayGoodForApproval,
    displayAbbyLogo: billingDocument?.displayAbbyLogo !== false,
    displayFullName: billingDocument?.displayFullName !== false,
    displayEmail: billingDocument?.displayEmail !== false,
    displayPhoneNumber: billingDocument?.displayPhoneNumber !== false,
    displayLegalStatus: !!billingDocument?.displayLegalStatus || (billingDocument?.displayLegalStatus !== false && [LegalStatus.MICRO_ENTREPRISE, LegalStatus.EI].includes(company.legalStatus || LegalStatus.MICRO_ENTREPRISE)),
    displayRequiredMentionsProduct: !!billingDocument?.displayRequiredMentionsProduct,
    logosFooter,
    columns,
    isDefaultCurrency: currency === Currency.EUR,
    currency,
    currencyRate: billingDocument?.currencyRate || 1,
    currencyDate: billingDocument?.currencyDate || new Date(),
    ...data,
  }
}

export interface BillingCreationState {
  isBillingCreationModalOpened: boolean
  isBillingCreationModalMinimized: boolean,
  isBillingEditionModalOpened: boolean
  isBillingEditionModalMinimized: boolean,
  isBillingDeletionModalOpened: boolean,
  billingDocument: Partial<IBilling> | null,
  billingDocumentStyle: Partial<IBilling> | null,
  createFromBillingDocument: Partial<IBilling> | null,
  createFromTimeRecords: ReadTimeRecord[] | null,
  billingDocumentsLoadingForAction: Array<string>,
  billingStyleFormTab: number,
  billingCustomerTPForEmail: BillingCustomerTPForEmail
  onClose?: () => void
}

export const state = (): BillingCreationState => ({
  isBillingCreationModalOpened: false,
  isBillingCreationModalMinimized: false,
  isBillingEditionModalOpened: false,
  isBillingEditionModalMinimized: false,
  isBillingDeletionModalOpened: false,
  billingDocument: null,
  billingDocumentStyle: null,
  createFromBillingDocument: null,
  createFromTimeRecords: null,
  billingDocumentsLoadingForAction: [],
  billingStyleFormTab: 1,
  billingCustomerTPForEmail: {
    modalOpened: false,
    customer: null,
  },
  onClose: undefined,
})

export const getters: GetterTree<BillingCreationState, RootState> = {
  billingCustomerTPForEmail (state: BillingCreationState) {
    return state.billingCustomerTPForEmail
  },
  isBillingCreationModalOpened (state: BillingCreationState) {
    return state.isBillingCreationModalOpened
  },
  isBillingCreationModalMinimized (state: BillingCreationState) {
    return state.isBillingCreationModalMinimized
  },
  isBillingEditionModalOpened (state: BillingCreationState) {
    return state.isBillingEditionModalOpened
  },
  isBillingEditionModalMinimized (state: BillingCreationState) {
    return state.isBillingEditionModalMinimized
  },
  isBillingDeletionModalOpened (state: BillingCreationState) {
    return state.isBillingDeletionModalOpened
  },
  billingDocument (state: BillingCreationState) {
    return state.billingDocument
  },
  billingDocumentStyle (state: BillingCreationState) {
    return state.billingDocumentStyle
  },
  createFromBillingDocument (state: BillingCreationState) {
    return state.createFromBillingDocument
  },
  createFromTimeRecords (state: BillingCreationState) {
    return state.createFromTimeRecords
  },
  billingDocumentsLoadingForAction (state: BillingCreationState) {
    return state.billingDocumentsLoadingForAction
  },
  billingStyleFormTab (state: BillingCreationState) {
    return state.billingStyleFormTab
  },
  EJSBillingDocumentPreviewData (state: BillingCreationState, _, __, rootGetters: any): BillingDocumentPDFData | null {
    if (!process.browser) { return null }
    const billingConfiguration = rootGetters['billingConfiguration/billingConfiguration']
    let preRenderNumber = null
    if (state.billingDocument && !state.billingDocument.number) {
      preRenderNumber = documentTextNumber(billingConfiguration, state.billingDocument.billingType || 1, state.billingDocument.date || new Date())
    }
    return billingDocumentToBillingDocumentPDFData({
      billingDocument: state.billingDocument,
      company: rootGetters['company/company'],
      creation: rootGetters['company/creation'],
      user: rootGetters['auth/user'],
      createFromBillingDocument: state.createFromBillingDocument,
      data: {
        locale: state.billingDocument?.locale || 'fr',
        logo: billingConfiguration.logo?.url || null,
        style: billingConfiguration?.colors?.primary && !billingConfiguration?.style
          ? { color: { primary: billingConfiguration?.colors?.primary }, background: { primary: billingConfiguration?.colors?.primary } }
          : billingConfiguration?.style || null,
        shape: billingConfiguration?.shape?.url || null, // Sert pour la prévisualisation uniquement, sinon il est injecté dans les paramètres de génération du PDF
        social: billingConfiguration?.social || null,
        template: billingConfiguration.template || BillingTemplate.TEMPLATE_1,
        columns: billingConfiguration.columns,
        displayTextEmail: billingConfiguration?.displayTextEmail,
        displayTextPhoneNumber: billingConfiguration?.displayTextPhoneNumber,
        preRenderNumber,
      },
    })
  },
  EJSBillingDocumentStylePreviewData (state: BillingCreationState, _, __, rootGetters: any): BillingDocumentPDFData | null {
    if (!process.browser) { return null }
    const billingConfiguration = rootGetters['billingConfiguration/billingConfiguration']
    return billingDocumentToBillingDocumentPDFData({
      billingDocument: {
        ...state.billingDocumentStyle || {},
      },
      company: rootGetters['company/company'],
      creation: rootGetters['company/creation'],
      user: rootGetters['auth/user'],
      data: {
        generalTermsAndConditionsOfSale: null,
        columns: billingConfiguration.columns,
        displayTextEmail: billingConfiguration?.displayTextEmail,
        displayTextPhoneNumber: billingConfiguration?.displayTextPhoneNumber,
      },
    })
  },
}

export const mutations = {
  SET_BILLING_CUSTOMER_TP_FOR_EMAIL (state: BillingCreationState, value: BillingCustomerTPForEmail) {
    state.billingCustomerTPForEmail = value
  },
  UPDATE_BILLING_DOCUMENT (state: BillingCreationState, value: Partial<IBilling>): void {
    state.billingDocument = {
      ...state.billingDocument as IBilling,
      ...value,
    }
  },
  UPDATE_BILLING_DOCUMENT_STYLE (state: BillingCreationState, value: Partial<IBilling>): void {
    state.billingDocumentStyle = {
      ...state.billingDocumentStyle as IBilling,
      ...value,
    }
  },
  UPDATE_CREATE_FROM_BILLING_DOCUMENT (state: BillingCreationState, value: Partial<IBilling>): void {
    state.createFromBillingDocument = {
      ...state.createFromBillingDocument as IBilling,
      ...value,
    }
  },
  SET_IS_BILLING_CREATION_MODAL_OPENED (state: BillingCreationState, value: boolean) {
    state.isBillingCreationModalOpened = value
  },
  SET_ON_CLOSE_CALLBACK (state: BillingCreationState, value: () => void) {
    state.onClose = value
  },
  SET_IS_BILLING_CREATION_MODAL_MINIMIZED (state: BillingCreationState, value: boolean) {
    state.isBillingCreationModalMinimized = value
  },
  SET_IS_BILLING_EDITION_MODAL_OPENED (state: BillingCreationState, value: boolean) {
    state.isBillingEditionModalOpened = value
  },
  SET_IS_BILLING_EDITION_MODAL_MINIMIZED (state: BillingCreationState, value: boolean) {
    state.isBillingEditionModalMinimized = value
  },
  SET_IS_BILLING_DELETION_MODAL_OPENED (state: BillingCreationState, value: boolean) {
    state.isBillingDeletionModalOpened = value
  },
  SET_BILLING_DOCUMENT (state: BillingCreationState, value: Partial<IBilling>) {
    state.billingDocument = value
  },
  SET_BILLING_DOCUMENT_STYLE (state: BillingCreationState, value: Partial<IBilling>) {
    state.billingDocumentStyle = value
  },
  SET_CREATE_FROM_BILLING_DOCUMENT (state: BillingCreationState, value: Partial<IBilling>) {
    state.createFromBillingDocument = value
  },
  SET_CREATE_FROM_TIME_RECORDS (state: BillingCreationState, value: ReadTimeRecord[]) {
    state.createFromTimeRecords = value
  },
  SET_BILLING_STYLE_FORM_TAB (state: BillingCreationState, value: number) {
    state.billingStyleFormTab = value
  },
  ADD_BILLING_DOCUMENT_LOADING_FOR_ACTION (state: BillingCreationState, value?: string) {
    if (!value) { return }
    const index = state.billingDocumentsLoadingForAction.indexOf(value)
    // Ne pas dupliquer les entrées
    if (index !== -1) { return }
    state.billingDocumentsLoadingForAction.push(value)
  },
  REMOVE_BILLING_DOCUMENT_LOADING_FOR_ACTION (state: BillingCreationState, value?: string) {
    if (!value) { return }
    const index = state.billingDocumentsLoadingForAction.indexOf(value)
    if (index !== -1) {
      state.billingDocumentsLoadingForAction.splice(index, 1)
    }
  },
  RESET (_currentState: BillingCreationState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<BillingCreationState, RootState> = {
  async askBeforeEraseTheEditingDocument (_, confirmText: string = 'Continuer'): Promise<boolean> {
    return await this.$confirmManager.ask({
      title: 'Document en cours d\'édition',
      icon: 'save',
      content: 'Un autre document est déjà en cours d\'édition. Si vous poursuivez, les modifications non enregistrées de l\'autre document seront perdues.',
      confirmText,
      confirmButton: { color: 'error', depressed: true },
    })
  },
  async openCreationBilling ({ commit, getters, dispatch }, { createFormBilling, billingDocument, createFromTimeRecords, onClose }: { createFormBilling?: IBilling, billingDocument?: Partial<IBilling>, createFromTimeRecords?: ReadTimeRecord[], onClose?: () => void }) {
    if ((getters.isBillingCreationModalMinimized || getters.isBillingEditionModalMinimized) && getters.billingDocument) {
      const confirm = await dispatch('askBeforeEraseTheEditingDocument', 'Continuer')
      if (!confirm) { return }
    }
    commit('SET_CREATE_FROM_BILLING_DOCUMENT', createFormBilling)
    commit('SET_CREATE_FROM_TIME_RECORDS', createFromTimeRecords)
    commit('SET_BILLING_DOCUMENT', billingDocument)
    commit('SET_IS_BILLING_CREATION_MODAL_OPENED', true)
    commit('SET_ON_CLOSE_CALLBACK', onClose)
  },
  async linkTimeRecordsToBilling ({ state, commit, dispatch }, billing: IBilling) {
    const id: string[] = state.createFromTimeRecords?.map(t => t.id) || []
    if (id.length) {
      await this.$api.timeTracking.bulkUpdate({
        billingId: billing._id,
        opportunityId: billing.opportunity?.id,
      }, id)
    }
    await dispatch('timeTracking/getTimeRecordsPaginated', null, { root: true })
    commit('SET_CREATE_FROM_TIME_RECORDS', null)
  },
  setIsBillingEditionModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_BILLING_EDITION_MODAL_OPENED', value)
  },
  setBillingStyleFormTab ({ commit }, value: number) {
    commit('SET_BILLING_STYLE_FORM_TAB', value)
  },
  updateBillingDocument ({ commit }, billing: Partial<IBilling>) {
    commit('UPDATE_BILLING_DOCUMENT', billing)
  },
  updateBillingDocumentStyle ({ commit }, billing: Partial<IBilling>) {
    commit('UPDATE_BILLING_DOCUMENT_STYLE', billing)
  },
  openBillingCustomerTPForEmail ({ commit }, value: ICustomer) {
    commit('SET_BILLING_CUSTOMER_TP_FOR_EMAIL', { customer: value, modalOpened: true })
  },
  closeBillingCustomerTPForEmail ({ commit }) {
    commit('SET_BILLING_CUSTOMER_TP_FOR_EMAIL', { modalOpened: false, customer: null })
  },
}
