
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  components: { },
})
export default class Link extends Vue {
  @Prop()
  text!: string

  @Prop({ default: 'normal' })
  size!: 'x-small' | 'small' | 'normal' | 'large' | 'x-large'

  @Prop()
  icon!: string

  @Prop({ default: 'primary' })
  iconColor!: string

  @Prop({ default: 'tw-text-primary-base' })
  colorClass!: string

  @Prop({ default: 'tw-font-normal' })
  textWeightClass!: string

  @Prop({ default: true })
  textDecoration!: boolean

  @Prop({ default: 'left' })
  direction!: 'left' | 'right'

  @Prop()
  tooltip!: string

  get directionConfig () {
    switch (this.direction) {
    case 'left': {
      return {
        marginClass: 'mr',
      }
    }
    case 'right': {
      return {
        marginClass: 'ml',
      }
    }
    default: {
      return {
        marginClass: 'mr',
      }
    }
    }
  }

  get sizeConfig () {
    switch (this.size) {
    case 'x-small': {
      return {
        iconSize: '12px',
        iconMarginX: '1',
        iconMarginY: '4px',
        fontSize: '12px',
      }
    }
    case 'small': {
      return {
        iconSize: '14px',
        iconMarginX: '1',
        iconMarginY: '2px',
        fontSize: '14px',
      }
    }
    case 'normal': {
      return {
        iconSize: '16px',
        iconMarginX: '1',
        iconMarginY: '4px',
        fontSize: '16px',
      }
    }
    case 'large': {
      return {
        iconSize: '18px',
        iconMarginX: '1',
        iconMarginY: '1px',
        fontSize: '18px',
      }
    }
    case 'x-large': {
      return {
        iconSize: '22px',
        iconMarginX: '2',
        iconMarginY: '-1px',
        fontSize: '22px',
      }
    }
    default : {
      return {
        iconSize: '16px',
        iconMarginX: '1',
        iconMarginY: '4px',
        fontSize: '16px',
      }
    }
    }
  }

  emitClick () {
    this.$emit('click')
  }
}

