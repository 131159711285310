import { IProduct, findVatFromId } from '@abby/core-legacy'
import {
  mapProductTypeToLegacyProductType,
  mapUnitToLegacyUnit,
  mapVatToLegacyVatId,
  mapActivityToCodeNature,
  mapLegacyUnitTpToUnit,
} from '@abby/shared'
import { ReadProductListItemDto } from '@abby/sdk'

export const createMapReadProductToLegacyProduct = (companyId: string) => (item: ReadProductListItemDto) : IProduct => {
  const legacyVat = mapVatToLegacyVatId[item.vatCode]
  return {
    _id: item.id,
    companyId,
    designation: item.designation,
    description: item.description,
    productUnit: mapUnitToLegacyUnit[item.unit],
    productType: mapProductTypeToLegacyProductType[item.type],
    tiersPrestationIsActivatedForThisProduct: !!item.personalServiceActivity,
    unitPrice: item.unitPrice,
    // @ts-ignore
    tpUnit: item.personalServiceActivity && item.unit ? mapLegacyUnitTpToUnit[item.unit] : undefined,
    // @ts-ignore
    codeNature: item.personalServiceActivity ? mapActivityToCodeNature[item.personalServiceActivity] : undefined,
    reference: item.reference || undefined,
    vatPercentage: legacyVat ? findVatFromId(legacyVat)?.rate : 0,
    vatId: legacyVat ? findVatFromId(legacyVat)?.id : 0,
  }
}
