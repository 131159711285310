import { ActionTree, GetterTree, MutationTree } from 'vuex'
import debounce from 'lodash/debounce'
import type {
  BasePaginate,
  CreateAccountingBook,
  QueryPurchaseRegister,
  ReadAccountingBook,
  UpdatePurchaseRegister,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface PurchaseRegisterQuery {
  search?: string | undefined,
  sortBy?: string[];
  sortDesc?: boolean[];
}

export interface PurchaseRegisterState {
  isPurchaseRegisterModalOpened: boolean;
  isAttachmentModalOpened: boolean;
  pagination: BasePaginate<ReadAccountingBook> | null;
  queryLoading: boolean;
  initialLoading: boolean;
  purchaseRegisterItem: ReadAccountingBook | null;
  refundPurchaseRegisterItem: ReadAccountingBook | null;
  paginationQuery: QueryPurchaseRegister,
  query: PurchaseRegisterQuery;
  statistics: number | null;
  statisticsLoading: boolean,
  queryStatisticsLoading: boolean,
}

export const state = (): PurchaseRegisterState => ({
  isPurchaseRegisterModalOpened: false,
  isAttachmentModalOpened: false,
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
  },
  query: {
    search: '',
  },
  queryLoading: true,
  purchaseRegisterItem: null,
  refundPurchaseRegisterItem: null,
  initialLoading: true,
  statistics: null,
  statisticsLoading: true,
  queryStatisticsLoading: true,
})

export const getters: GetterTree<PurchaseRegisterState, PurchaseRegisterState> = {
  pagination (state: PurchaseRegisterState) {
    return state.pagination
  },
  paginationQuery (state: PurchaseRegisterState) {
    return state.paginationQuery
  },
  purchaseRegisterItems: (state: PurchaseRegisterState) => {
    return (state.pagination?.data || [])
  },
  purchaseRegisterItem: (state: PurchaseRegisterState) => {
    return state.purchaseRegisterItem
  },
  isPurchaseRegisterModalOpened (state: PurchaseRegisterState) {
    return state.isPurchaseRegisterModalOpened
  },
  queryLoading (state: PurchaseRegisterState) {
    return state.queryLoading
  },
  initialLoading (state: PurchaseRegisterState) {
    return state.initialLoading
  },
  query (state: PurchaseRegisterState) {
    return state.query
  },
  statistics (state: PurchaseRegisterState) {
    return state.statistics
  },
  refundPurchaseRegisterItem (state: PurchaseRegisterState) {
    return state.refundPurchaseRegisterItem
  },
  statisticsLoading (state: PurchaseRegisterState) {
    return state.statisticsLoading
  },
  queryStatisticsLoading (state: PurchaseRegisterState) {
    return state.queryStatisticsLoading
  },
  isAttachmentModalOpened: state => state.isAttachmentModalOpened,
}

export const mutations: MutationTree<PurchaseRegisterState> = {
  SET_PAGINATION_QUERY (state: PurchaseRegisterState, paginationQuery: QueryPurchaseRegister) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: PurchaseRegisterState, pagination: BasePaginate<ReadAccountingBook> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: PurchaseRegisterState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY (state: PurchaseRegisterState, value: PurchaseRegisterQuery): void {
    state.query = value
  },
  SET_QUERY_LOADING (state: PurchaseRegisterState, value: boolean): void {
    state.queryLoading = value
  },
  SET_IS_PURCHASE_REGISTER_MODAL_OPENED (state: PurchaseRegisterState, value: boolean) {
    state.isPurchaseRegisterModalOpened = value
  },
  SET_PURCHASE_REGISTER_ITEM (state: PurchaseRegisterState, value: ReadAccountingBook | null) {
    state.purchaseRegisterItem = value
  },
  UPDATE_PURCHASE_REGISTER_ITEM (state, value: ReadAccountingBook) {
    if (!state.pagination) { return }
    const index = state.pagination.data.findIndex(item => item.id === value.id)
    if (index === -1) { return }
    state.pagination.data.splice(index, 1, value)
  },
  SET_STATISTICS (state: PurchaseRegisterState, value: any | null): void {
    state.statistics = value
  },
  SET_REFUND_PURCHASE_REGISTER_ITEM (state, value: ReadAccountingBook | null) {
    state.refundPurchaseRegisterItem = value
  },
  SET_STATISTICS_LOADING (state: PurchaseRegisterState, value: boolean): void {
    state.statisticsLoading = value
  },
  SET_QUERY_STATISTICS_LOADING (state: PurchaseRegisterState, value: boolean): void {
    state.queryStatisticsLoading = value
  },
  SET_IS_ATTACHMENT_MODAL_OPENED (state: PurchaseRegisterState, value: boolean): void {
    state.isAttachmentModalOpened = value
  },
  RESET (_currentState: PurchaseRegisterState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<PurchaseRegisterState, RootState> = {
  async fetchPurchaseRegister ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.purchaseRegister.paginate({
        ...getters.paginationQuery as QueryPurchaseRegister,
        ...getters.query,
        countWithoutFilters: true,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  updateQuery ({ commit, dispatch }, query: Partial<PurchaseRegisterQuery>) {
    commit('SET_QUERY', query)
    dispatch('debounceFetchPurchaseRegister')
  },
  async update ({ dispatch, commit }, { id, data }: { id: string, data: UpdatePurchaseRegister }) {
    const result = await this.$api.purchaseRegister.update(id, data)
    commit('UPDATE_PURCHASE_REGISTER_ITEM', result)
    dispatch('fetchStatistics')
  },
  updateRegisterItem ({ commit }, payload: ReadAccountingBook) {
    commit('UPDATE_PURCHASE_REGISTER_ITEM', payload)
  },
  debounceFetchPurchaseRegister: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchPurchaseRegister')
  }, 300, { leading: true }),
  debounceFetchStatistics: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchStatistics')
  }, 300, { leading: true }),
  setPurchaseRegisterItem ({ commit }, value: ReadAccountingBook | null) {
    commit('SET_PURCHASE_REGISTER_ITEM', value)
  },
  setRefundPurchaseRegisterItem ({ commit }, value: ReadAccountingBook | null) {
    commit('SET_REFUND_PURCHASE_REGISTER_ITEM', value)
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<QueryPurchaseRegister>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchPurchaseRegister')
  },
  setPurchaseRegisterModal ({ commit }, value: boolean) {
    commit('SET_IS_PURCHASE_REGISTER_MODAL_OPENED', value)
  },
  setAttachmentModal ({ commit }, value: boolean) {
    commit('SET_IS_ATTACHMENT_MODAL_OPENED', value)
  },
  async create ({ dispatch }, value: CreateAccountingBook) {
    await this.$api.purchaseRegister.create(value)
    dispatch('fetchPurchaseRegister')
    dispatch('fetchStatistics')
  },
  async deletePurchaseRegisterItem ({ dispatch }, value: string) {
    await this.$api.purchaseRegister.delete(value)
    dispatch('fetchPurchaseRegister')
    dispatch('fetchStatistics')
  },
  async fetchStatistics ({ commit, getters }) {
    try {
      commit('SET_STATISTICS_LOADING', true)
      const statistics = await this.$api.purchaseRegister.statistics({
        ...getters.query,
      })
      commit('SET_STATISTICS', statistics)
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      commit('SET_STATISTICS_LOADING', false)
    }
  },
}
