import { Action } from '@abby/shared'
import { useStore } from '@nuxtjs/composition-api'
import { useApi } from '~/composables/shared/useApi'

export const useTrackingManager = () => {
  const store = useStore()
  const api = useApi()
  return {
    track: ({ action, data } : { action: Action, data?: any }) => {
      const user = store.getters['auth/user']
      try {
        const mergedPayload = {
          action,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
          ...data ? { metadata: data } : {},
        }
        if (user) {
          return api.advertising.trackV2(mergedPayload)
        }
        return api.advertising.trackUnauthenticatedV2(mergedPayload)
      } catch {}
    },
  }
}
