import { CurrencyCode, Money } from '@abby/shared'

export type BillingAmountProps = {
    totalAmountWithoutTaxAfterDiscount: number;
    totalAmountWithTaxAfterDiscount: number;
    remainingAmountWithoutTax?: number;
    remainingAmountWithTax?: number;
    billedAmountWithoutTax?: number;
    billedAmountWithTax?: number;
    cancelledAmountWithoutTax: number;
    cancelledAmountWithTax: number;
    remainingReconciliateAmount?: Money;
    currency?: CurrencyCode;
}

export class BillingAmount {
    readonly totalAmountWithoutTaxAfterDiscount: number;
    readonly totalAmountWithTaxAfterDiscount: number;
    readonly remainingAmountWithoutTax?: number;
    readonly remainingAmountWithTax?: number;
    readonly billedAmountWithoutTax?: number;
    readonly billedAmountWithTax?: number;
    readonly cancelledAmountWithoutTax: number;
    readonly cancelledAmountWithTax: number;
    readonly remainingReconciliateAmount?: Money;
    readonly currency: CurrencyCode;

    private constructor (props: BillingAmountProps) {
      this.totalAmountWithoutTaxAfterDiscount = props.totalAmountWithoutTaxAfterDiscount
      this.totalAmountWithTaxAfterDiscount = props.totalAmountWithTaxAfterDiscount
      this.remainingAmountWithoutTax = props.remainingAmountWithoutTax
      this.remainingAmountWithTax = props.remainingAmountWithTax
      this.billedAmountWithoutTax = props.billedAmountWithoutTax
      this.billedAmountWithTax = props.billedAmountWithTax
      this.cancelledAmountWithoutTax = props.cancelledAmountWithoutTax
      this.cancelledAmountWithTax = props.cancelledAmountWithTax
      this.remainingReconciliateAmount = props.remainingReconciliateAmount
      this.currency = props.currency || CurrencyCode.EUR
    }

    static create (props: BillingAmountProps): BillingAmount {
      return new BillingAmount(props)
    }
}
