export enum BillingLocale {
  FR = 'fr',
  EN = 'en',
  DE = 'de',
  IT = 'it',
  NL = 'nl',
  PT = 'pt',
  ES = 'es',
}

export function isValidBillingLocale(value: string): boolean {
  return Object.values(BillingLocale).includes(value?.toLowerCase() as unknown as BillingLocale);
}
