
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { AbbyPlans, PlanFeature } from '@abby/core-legacy'

@Component
export default class AbbyPlanChip extends Vue {
  @Prop()
  feature!: PlanFeature

  @Prop()
  plan!: AbbyPlans

  @Prop({ default: 16 })
  size!: number | string

  @Prop({ default: false })
  left!: boolean

  @Prop({ default: false })
  right!: boolean

  @Prop({ default: false })
  plain!: boolean

  @Prop({ default: false })
  disableModal!: boolean

  @Prop({ default: true })
  outlined!: boolean

  @Prop({ default: true })
  openPlansDirectly!: boolean

  @Prop({ default: false })
  outlinedWhite!: boolean

  @Prop({ default: '' })
  tooltip!: string

  @Prop({ default: false })
  hasCustomHasAccessToRule!: boolean

  @Prop()
  customHasAccessToRule?: boolean

  get isLockedBecauseOfFailedPayment () {
    return this.$planManager?.isLockedBecauseOfFailedPayment()
  }

  get ratio () {
    if (AbbyPlans.ABBY_BUSINESS === this.whichPlan || AbbyPlans.ABBY_BUSINESS === this.plan) { return 549.78 / 187 }
    return 401 / 187
  }

  get width () {
    return +this.size * this.ratio
  }

  openModal () {
    if (this.disableModal) {
      return
    } else if (this.$planManager.isLockedBecauseOfFailedPayment()) {
      window.open(this.$planManager.failedInformation()?.failedInvoiceUrl || '', '_blank')
      return
    }
    this.$planManager.openAbbyPlansModal({ feature: (this.plan || this.feature) as any, openPlan: this.openPlansDirectly || !this.feature })
    this.$emit('close-menu')
  }

  get planText () {
    if (AbbyPlans.ABBY_FREE === this.whichPlan || AbbyPlans.ABBY_FREE === this.plan) { return 'Basique' }
    if (AbbyPlans.ABBY_START === this.whichPlan || AbbyPlans.ABBY_START === this.plan) { return 'Abby Start' }
    if (AbbyPlans.ABBY_PRO === this.whichPlan || AbbyPlans.ABBY_PRO === this.plan) { return 'Abby Pro' }
    if (AbbyPlans.ABBY_BUSINESS === this.whichPlan || AbbyPlans.ABBY_BUSINESS === this.plan) { return 'Abby Business' }
  }

  get planIconUrl () {
    let type = 'outlined'
    if (this.outlinedWhite) { type = 'outlined_white' } else if (this.plain) { type = 'plain' } else if (this.outlined) { type = 'outlined' }
    return `/plans/icon_${this.plan || this.$planManager?.whichPlanFor(this.feature)}_${type}.svg`
  }

  get whichPlan () {
    return this.$planManager?.whichPlanFor(this.feature) as unknown as AbbyPlans
  }

  get hasAccessTo () {
    return (this.hasCustomHasAccessToRule && !this.customHasAccessToRule) ||
      (!this.hasCustomHasAccessToRule && this.$planManager.hasAccessTo(this.feature))
  }
}
