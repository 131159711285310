import { useContext } from '@nuxtjs/composition-api'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useCopyBillingId = () => {
  const { $clipboard } = useContext()
  const alertManager = useAlertManager()

  return async ({ id }: BillingItem) => {
    await $clipboard.copy(`_id: ObjectId("${id}")`)
    alertManager.success('Le message a été copié dans le presse-papier')
  }
}
