import type { ReadActivity, ReadParentActivity } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Activity extends Resource {
  getAll (): Promise<ReadActivity[]> {
    return this.axios.$get('/activity/all')
  }

  getAllParentActivities (): Promise<ReadParentActivity[]> {
    return this.axios.$get('/activity/allParentActivities')
  }

  get (id: number): Promise<ReadActivity> {
    return this.axios.$get(`/activity/${id}`)
  }
}
