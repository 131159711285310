
import { Action, Component, Prop, Vue } from 'nuxt-property-decorator'
import { ReadOpportunity } from '@abby/core-legacy'

@Component
export default class OpportunityChip extends Vue {
  @Prop({ required: true })
  opportunity!: ReadOpportunity

  @Action('opportunity/openOpportunityDetails')
  openOpportunityDetails!: (payload: { opportunity: ReadOpportunity }) => Promise<void>;

  async openOpportunity () {
    await this.openOpportunityDetails({
      opportunity: this.opportunity,
    })
  }
}

