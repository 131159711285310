import { Context, Plugin } from '@nuxt/types'
import { AbbyPlans, colors } from '@abby/core-legacy'

export interface BrandManagerInstance {
  initStyleHead: () => any;
  head: () => any;
  name: string;
  website: string;
  application: string;
  ogImageUrl: string;
  twitterImageUrl: string;
  description: string;
  logoUrl: (config?: { white?: boolean }) => string;
  withAbbyUrl: (config?: { white?: boolean }) => string;
  miniLogoUrl: (config?: { white?: boolean }) => string;
  logoWhiteUrl: string;
  faviconUrl: string;
  emailingLogoUrl: () => string;
  privacyPolicy: () => string;
  generalConditionsOfUse: string;
  generalConditionsOfSale: string;
  legalMention: () => string;
  customDistribution: () => string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BrandManagerPlugin: Plugin = ({ $planManager }: Context, inject) => {
  const manager = {
    name: 'Abby',
    website: 'https://abby.fr',
    application: 'app.abby.fr',
    ogImageUrl: 'https://abby.fr/abby-creation-gestion-og.jpg',
    twitterImageUrl: 'https://abby.fr/abby-creation-gestion-tw.jpg',
    description: 'Créez et/ou gérez votre activité gratuitement et sans engagement. Facturation et déclaration Urssaf simplifiées',
    logoUrl: (config?: { white?: boolean }) => {
      const plan = $planManager?.whichPlanCompanyHas() || AbbyPlans.ABBY_FREE
      return `/plans/logo_${plan}${config?.white ? '_white' : ''}.svg`
    },
    withAbbyUrl: (config?: { white?: boolean }) => {
      return config?.white ? '/avec-abby-white.png' : '/avec-abby.png'
    },
    miniLogoUrl: (config?: { white?: boolean }) => {
      const plan = $planManager?.whichPlanCompanyHas() || AbbyPlans.ABBY_FREE
      return `/plans/mini_${plan || ''}${config?.white ? '_white' : ''}.svg`
    },
    logoWhiteUrl: '/logo-abby-blanc.svg',
    faviconUrl: process.env.NODE_ENV === 'development' ? '/favicon-dev.ico' : '/favicon.ico',
    privacyPolicy: 'https://abby.fr/legals/confidentiality',
    legalMention: 'https://abby.fr/legals/mentions',
    generalConditionsOfUse: 'https://abby.fr/legals/cguv',
    generalConditionsOfSale: 'https://abby.fr/legals/cguv',
  }

  inject('brandManager', {
    head: () => ({
      meta: [
        {
          name: 'Content-Type',
          content: 'UTF-8',
        },
        {
          name: 'Content-Language',
          content: 'fr',
        },
        {
          name: 'initialize',
          content: 'true',
        },
        {
          name: 'msapplication-TileColor',
          content: colors.primary.base,
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          property: 'og:site_name',
          // @ts-ignore
          content: `${manager.name} : ${manager.description}`,
        },
        {
          property: 'article:publisher',
          content: 'https://www.facebook.com/application.Abby',
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'description',
          content: `${manager.name} : ${manager.description}`,
        },
        {
          property: 'og:title',
          content: `${manager.name} : ${manager.description}`,
        },
        {
          property: 'og:description',
          content: `${manager.name} : ${manager.description}`,
        },
        {
          property: 'og:url',
          content: manager.website,
        },
        {
          hid: 'description',
          name: 'description',
          content: `${manager.name} : ${manager.description}`,
        },
        {
          name: 'twitter:title',
          content: `${manager.name} : ${manager.description}`,
        },
        {
          name: 'twitter:description',
          content: `${manager.name} : ${manager.description}`,
        },
        {
          name: 'twitter:image',
          content: manager.twitterImageUrl,
        },
        {
          property: 'og:image',
          content: manager.ogImageUrl,
        },
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: manager.faviconUrl },
        // @ts-ignore
        { rel: 'apple-touch-icon', sizes: '180x180', href: manager.faviconUrl },
        // @ts-ignore
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: manager.faviconUrl },
        // @ts-ignore
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: manager.faviconUrl },
        // @ts-ignore
        { rel: 'mask-icon', href: manager.faviconUrl, color: '#ffffff' },
        {
          hid: 'canonical',
          rel: 'canonical',
          // @ts-ignore
          href: 'https://' + manager.application,
        },
      ],
      script: [
        ...(process.env.NODE_ENV !== 'development'
          ? [
            { async: true, src: '/scripts/hotjar.js' },
          ]
          : []),
      ],
    }),
    ...manager,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $brandManager: BrandManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $brandManager: BrandManagerInstance
  }
  interface Context {
    $brandManager: BrandManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $brandManager: BrandManagerInstance
  }
}

export default BrandManagerPlugin
