import {
  Code, Type,
} from '@abby/core-legacy'
import type {
  BasePaginate,
  UpdatePurchaseRegister,
  QueryDownloadPurchaseRegister,
  QueryPurchaseRegister,
  QueryPurchaseRegisterStatistics,
  ReadAccountingBook,
  CreateAccountingBook,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class PurchaseRegister extends Resource {
  paginate (params: QueryPurchaseRegister): Promise<BasePaginate<ReadAccountingBook>> {
    return this.axios.$get('/v2/purchaseRegister/items', {
      params,
    })
  }

  create (payload: CreateAccountingBook): Promise<ReadAccountingBook> {
    return this.axios.$post('/v2/purchaseRegister', payload)
  }

  delete (id: string) {
    return this.axios.$delete(`/v2/purchaseRegister/${id}`)
  }

  update (id: string, payload: UpdatePurchaseRegister): Promise<ReadAccountingBook> {
    return this.axios.$put(`/v2/purchaseRegister/${id}`, payload)
  }

  downloadBook (params: QueryDownloadPurchaseRegister): Promise<any> {
    return this.axios.get('/v2/purchaseRegister/download', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.purchaseRegister,
      type: Type.resourceError,
      message: 'purchase_register.export_no_data',
    }))
  }

  statistics (params: QueryPurchaseRegisterStatistics): Promise<number> {
    return this.axios.$get('/v2/purchaseRegister/statistics', { params })
  }
}
