import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import type { BasePaginateMongo, CreateProduct, IProduct, UpdateProduct } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ProductsQuery {
  search?: string | undefined,
}

export interface ProductsPaginationQuery { page: number, limit: number }

export interface ProductsState {
  isProductImportModalOpened: boolean;
  isProductCreationModalOpened: boolean;
  isProductEditModalOpened: boolean;
  isProductFormSidePanelOpened: boolean;
  isProductViewDetailsSidePanelOpened: boolean;
  productToEdit: IProduct | null
  productToCreate: Partial<IProduct> & { index?: number } | null
  pagination: BasePaginateMongo<IProduct> | null;
  query: ProductsQuery;
  queryLoading: boolean;
  initialLoading: boolean;
  paginationQuery: ProductsPaginationQuery

}

export const state = (): ProductsState => ({
  isProductImportModalOpened: false,
  isProductCreationModalOpened: false,
  isProductEditModalOpened: false,
  isProductFormSidePanelOpened: false,
  isProductViewDetailsSidePanelOpened: false,
  productToEdit: null,
  productToCreate: null,
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
  },
  query: {
    search: undefined,
  },
  queryLoading: true,
  initialLoading: true,
})

export const getters: GetterTree<ProductsState, RootState> = {
  pagination (state: ProductsState) {
    return state.pagination
  },
  query (state: ProductsState) {
    return state.query
  },
  paginationQuery (state: ProductsState) {
    return state.paginationQuery
  },
  products (state: ProductsState) {
    return state.pagination?.docs || []
  },
  productToEdit (state: ProductsState) {
    return state.productToEdit
  },
  productToCreate (state: ProductsState) {
    return state.productToCreate
  },
  isProductCreationModalOpened (state: ProductsState) {
    return state.isProductCreationModalOpened
  },
  isProductEditModalOpened (state: ProductsState) {
    return state.isProductEditModalOpened
  },
  isProductFormSidePanelOpened (state: ProductsState) {
    return state.isProductFormSidePanelOpened
  },
  queryLoading (state: ProductsState) {
    return state.queryLoading
  },
  initialLoading (state: ProductsState) {
    return state.initialLoading
  },
  isProductImportModalOpened: state => state.isProductImportModalOpened,
}

export const mutations = {
  SET_PAGINATION_QUERY (state: ProductsState, paginationQuery: ProductsPaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: ProductsState, pagination: BasePaginateMongo<IProduct> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: ProductsState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: ProductsState, value: boolean): void {
    state.queryLoading = value
  },
  SET_PRODUCT_TO_EDIT (state: ProductsState, value: IProduct): void {
    state.productToEdit = value
  },
  SET_PRODUCT_TO_CREATE (state: ProductsState, value: Partial<IProduct> & { index?: number }): void {
    state.productToCreate = value
  },
  UPDATE_QUERY (_state: ProductsState, query: Partial<ProductsQuery>): void {
    _state.query = {
      ..._state.query,
      ...query,
    }
    const newPaginationQuery = state().paginationQuery
    _state.paginationQuery = Object.assign(_state.paginationQuery, newPaginationQuery)
  },
  SET_IS_PRODUCT_CREATION_MODAL_OPENED (state: ProductsState, value: boolean) {
    state.isProductCreationModalOpened = value
  },
  SET_IS_PRODUCT_EDIT_MODAL_OPENED (state: ProductsState, value: boolean) {
    state.isProductEditModalOpened = value
  },
  SET_IS_PRODUCT_FORM_SIDE_PANEL_OPENED (state: ProductsState, value: boolean) {
    state.isProductFormSidePanelOpened = value
  },
  RESET (_currentState: ProductsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  RESET_QUERY (_currentState: ProductsState) {
    const newQuery = state().query
    const newPaginationQuery = state().paginationQuery
    _currentState.query = Object.assign(_currentState.query, newQuery)
    _currentState.paginationQuery = Object.assign(_currentState.paginationQuery, newPaginationQuery)
  },
  SET_IS_IMPORT_PRODUCT_MODAL_OPENED (state: ProductsState, value: boolean) {
    state.isProductImportModalOpened = value
  },
}

export const actions: ActionTree<any, any> = {
  async createProduct ({ dispatch, rootGetters, commit }, body: CreateProduct & { index?: number }) {
    const { index, ...data } = body
    const product = await this.$api.billing.createProduct(data)
    this.$busManager.emit('productCreated', product)
    if (rootGetters['billingCreation/isBillingCreationModalOpened'] || rootGetters['billingCreation/isBillingEditionModalOpened']) {
      const products = [...rootGetters['billingCreation/billingDocument']?.product || []]
      if (index !== null && index !== undefined) {
        products[index] = { ...products[index], ...product, hasDescription: !!product.description }
        commit('billingCreation/UPDATE_BILLING_DOCUMENT', { product: products }, { root: true })
      } else {
        commit('billingCreation/UPDATE_BILLING_DOCUMENT', { product: [...products, { ...product, hasDescription: !!product.description, quantity: 1 }] }, { root: true })
      }
    }
    dispatch('fetchProducts')
  },
  async createProducts ({ dispatch }, body: CreateProduct[]) {
    await this.$api.billing.createProducts(body)
    dispatch('fetchProducts')
  },
  async deleteProduct ({ dispatch }, _id: string) {
    await this.$api.billing.deleteProduct(_id)
    dispatch('fetchProducts')
  },
  async updateProduct ({ dispatch }, { id, body }: { id: string, body: UpdateProduct }) {
    await this.$api.billing.updateProduct(id, body)
    dispatch('fetchProducts')
  },
  async fetchProducts ({ commit, getters }) {
    commit('SET_INITIAL_LOADING', true)
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.billing.getAllProduct({
        ...getters.paginationQuery as ProductsPaginationQuery,
        ...getters.query,
        countWithoutFilters: true,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  debounceFetchProducts: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchProducts')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<ProductsQuery>) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchProducts')
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<ProductsPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchProducts')
  },
  setProductImportModal ({ commit }, value: boolean) {
    commit('SET_IS_IMPORT_PRODUCT_MODAL_OPENED', value)
  },
  setProductFormSidePanel ({ commit }, value: boolean) {
    commit('SET_IS_PRODUCT_FORM_SIDE_PANEL_OPENED', value)
  },
}
