import type {
  BasePaginate,
  ITransaction,
  QueryTransaction,
  AnnotateTransaction,
  AnnotateSimilarTransactions,
  ReadTransaction,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Transaction extends Resource {
  paginate (query: QueryTransaction & { cancelToken?: any }): Promise<BasePaginate<ITransaction>> {
    const { cancelToken, ...params } = query
    return this.axios.$get('/transactions', { params, cancelToken })
  }

  annotate (transactionId: string, body: AnnotateTransaction): Promise<ReadTransaction> {
    return this.axios.$post(`/transaction/${transactionId}/annotate`, body)
  }

  getSimilarTransactions (id: string): Promise<Array<ITransaction>> {
    return this.axios.$get(`/transactions/similar/${id}`)
  }

  annotateSimilarTransactions (id: string, body: AnnotateSimilarTransactions): Promise<ReadTransaction[]> {
    return this.axios.$post(`/transactions/similar/${id}/annotate`, body)
  }

  delete (id: string): Promise<boolean> {
    return this.axios.$delete(`/transaction/${id}`)
  }
}
