import { GetterTree, ActionTree, MutationTree } from 'vuex'
import {
  Category,
} from '@abby/core-legacy'

import { ReadEntry } from '@abby/core-legacy/src/types/Interface/api/entry/ReadEntry'
import type {
  AnnotateTransaction,
  CreateAccountingAccount, DeleteAccountingAccount,
  IAccountingAccount,
  ICategory,
  IEntry, IFile, IGroupAccountingAccount,
  ITransaction,
  ReadTransaction,
  UpdateAccountingAccount,
} from '@abby/core-legacy'

// Principally used to define when annotation is required
const defaultAccountingAccountNumber: number = 471000
const defaultCategoryIdForDefaultAccountingAccount: number = 4

export interface GroupedAccountingAccount {
  categoryId: number
  category: ICategory
  accountingAccounts: Array<IAccountingAccount>
}

export type GroupedAccountingAccounts = Array<GroupedAccountingAccount>

export interface AccountingAccountsState {
  accountingAccounts: Array<IAccountingAccount>;
  similarTransactionsToAnnotate: Array<ITransaction>;
  isSelectionModalOpened: boolean;
  isSuggestedModalOpened: boolean;
  isAttachmentTransactionOpened: boolean;
  isPaymentMethodTransactionOpened: boolean;
  attachmentsTransaction: IFile[];
  transactionForSelection: ITransaction | null;
  entryForSelection: ReadEntry | null;
  transactionForSuggestion: ITransaction | null;
  categories: Array<ICategory>
  categoryForCustomization: ICategory | IGroupAccountingAccount | null;
  editAccountingAccount: IAccountingAccount | null;
  isAccountingAccountModalOpened: boolean;
  isDeleteAccountingAccountModalOpened: boolean;
  isDetailedTransactionOpened: boolean;
  detailedTransaction: ITransaction | null;
  deleteAccountingAccount: IAccountingAccount | null;
  countAccountingAccountRelatedEntry: number | null;
  isPersonal: boolean;
  searchAccount: string | null;
  loadingAccountingAccountMenu: boolean;
}

export const state = (): AccountingAccountsState => ({
  accountingAccounts: [],
  similarTransactionsToAnnotate: [],
  attachmentsTransaction: [],
  isSelectionModalOpened: false,
  isSuggestedModalOpened: false,
  isAccountingAccountModalOpened: false,
  isDeleteAccountingAccountModalOpened: false,
  isAttachmentTransactionOpened: false,
  isPersonal: false,
  transactionForSelection: null,
  entryForSelection: null,
  transactionForSuggestion: null,
  categories: Object.entries(Category).map(([_, category]) => category),
  categoryForCustomization: null,
  editAccountingAccount: null,
  deleteAccountingAccount: null,
  isDetailedTransactionOpened: false,
  detailedTransaction: null,
  countAccountingAccountRelatedEntry: null,
  searchAccount: '',
  loadingAccountingAccountMenu: false,
  isPaymentMethodTransactionOpened: false,
})

export const getters: GetterTree<AccountingAccountsState, AccountingAccountsState> = {
  accountingAccounts: state => state.accountingAccounts,
  similarTransactionsToAnnotate: state => state.similarTransactionsToAnnotate,
  deleteAccountingAccount: state => state.deleteAccountingAccount,
  isDeleteAccountingAccountModalOpened: state => state.isDeleteAccountingAccountModalOpened,
  isDetailedTransactionOpened: state => state.isDetailedTransactionOpened,
  detailedTransaction: state => state.detailedTransaction,
  loadingAccountingAccountMenu: state => state.loadingAccountingAccountMenu,
  isAttachmentTransactionOpened: state => state.isAttachmentTransactionOpened,
  isPaymentMethodTransactionOpened: state => state.isPaymentMethodTransactionOpened,
  isPersonal: state => state.isPersonal,
  searchAccount: state => state.searchAccount,
  countAccountingAccountRelatedEntry: state => state.countAccountingAccountRelatedEntry,
  groupedAccountingAccounts: (state, getters, _, rootGetters): GroupedAccountingAccounts => {
    const vatFilter = (isVatRelated: boolean) => {
      if (rootGetters['company/company'].hasVat) {
        return true
      } else {
        return !isVatRelated
      }
    }

    /*
     * Filters:
     * - Perso/Pro depending of selected transaction for annotation
     * - VAT depending of the company configuration and Perso/Pro
     * - Hidden accounting accounts
     */
    const groupedAccountingAccounts = state.accountingAccounts.filter(aa =>
      aa.isPersonal === getters.transactionForSelectionAccountingAccountEntry.isPersonal &&
        vatFilter(!!aa.isVatRelated) && aa.isHidden === false,
    ).reduce((acc: any, accountingAccount) => {
      const _categoryId = accountingAccount.category
      const groupIndex = acc.findIndex(({ categoryId }: any) => categoryId === _categoryId)
      if (groupIndex === -1) {
        return [
          ...acc,
          {
            categoryId: _categoryId,
            category: state.categories.find(c => c.id === _categoryId),
            accountingAccounts: [accountingAccount],
          },
        ]
      }
      acc[groupIndex].accountingAccounts.push(accountingAccount)
      return acc
    }, [])

    // Inject default accounting account "To annotate" in "Travel" category
    const indexOfDefaultCategory = groupedAccountingAccounts.findIndex((gaa:GroupedAccountingAccount) => gaa.categoryId === defaultCategoryIdForDefaultAccountingAccount)
    if (indexOfDefaultCategory !== -1) {
      groupedAccountingAccounts[indexOfDefaultCategory].accountingAccounts.push(getters.defaultAccountingAccount)
    }

    // Filter isHidden categories, sort by display order and return
    return groupedAccountingAccounts.filter((g: GroupedAccountingAccount) => !g.category.isHidden).sort((a: GroupedAccountingAccount, b: GroupedAccountingAccount) => (a.category.displayOrder || 1) - (b.category.displayOrder || 0))
  },
  isSelectionModalOpened: state => state.isSelectionModalOpened,
  isSuggestedModalOpened: state => state.isSuggestedModalOpened,
  transactionForSelection: state => state.transactionForSelection,
  entryForSelection: state => state.entryForSelection,
  transactionForSuggestion: state => state.transactionForSuggestion,
  transactionForSelectionAccountingAccountEntry: (_, getters) => getters.transactionForSelection.entries.find((e: IEntry) => !e.parent && e.vatRate === null),
  categories: state => state.categories,
  defaultAccountingAccount: state => state.accountingAccounts.find(accountingAccount => accountingAccount.number === defaultAccountingAccountNumber),
  defaultAccountingAccountNumber: _ => defaultAccountingAccountNumber,
  categoryForCustomization: state => state.categoryForCustomization,
  editAccountingAccount: state => state.editAccountingAccount,
  isAccountingAccountModalOpened: state => state.isAccountingAccountModalOpened,
  favoriteCategory: state => Category[state.isPersonal ? 'PERSONAL_FAVORITES' : 'PROFESSIONAL_FAVORITES'],
}

export const mutations: MutationTree<AccountingAccountsState> = {
  SET_ACCOUNTING_ACCOUNT (state, accountingAccounts: Array<IAccountingAccount>) {
    state.accountingAccounts = accountingAccounts
  },
  SET_LOADING_ACCOUNTING_ACCOUNT_MENU (state, value: boolean) {
    state.loadingAccountingAccountMenu = value
  },
  SET_TRANSACTION_FOR_SUGGESTION (state, transactionForSuggestion: ITransaction) {
    state.transactionForSuggestion = transactionForSuggestion
  },
  SET_SIMILAR_TRANSACTIONS_TO_ANNOTATE (state, similarTransactionsToAnnotate: Array<ITransaction>) {
    state.similarTransactionsToAnnotate = similarTransactionsToAnnotate
  },
  SET_IS_SELECTION_MODAL_OPENED (state, isSelectionModalOpened: boolean) {
    state.isSelectionModalOpened = isSelectionModalOpened
  },
  SET_IS_DETAILED_TRANSACTION_OPENED (state, value: boolean) {
    state.isDetailedTransactionOpened = value
  },
  SET_DETAILED_TRANSACTION (state, value: ITransaction | null) {
    state.detailedTransaction = value
  },
  SET_IS_SUGGESTED_MODAL_OPENED (state, isSuggestedModalOpened: boolean) {
    state.isSuggestedModalOpened = isSuggestedModalOpened
  },
  SET_TRANSACTION_FOR_SELECTION (state, transactionForSelection: ITransaction | null) {
    state.transactionForSelection = transactionForSelection
  },
  SET_IS_PERSONAL (state, value: boolean) {
    state.isPersonal = value
  },
  SET_SEARCH_ACCOUNT (state, value: string | null) {
    state.searchAccount = value
  },
  UPDATE_TRANSACTION_FOR_SELECTION (state, transactionForSelection: Partial<ITransaction>) {
    state.transactionForSelection = {
      ...state.transactionForSelection as ITransaction,
      ...transactionForSelection,
    }
  },
  UPDATE_TRANSACTION_FOR_SUGGESTION (state, transactionForSuggestion: Partial<ITransaction>) {
    state.transactionForSuggestion = {
      ...state.transactionForSuggestion as ITransaction,
      ...transactionForSuggestion,
    }
  },
  UPDATE_DETAILED_TRANSACTION (state, detailedTransaction: Partial<ITransaction>) {
    state.detailedTransaction = {
      ...state.detailedTransaction as ITransaction,
      ...detailedTransaction,
    }
  },
  RESET (_currentState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_CATEGORY_FOR_CUSTOMIZATION (state, value: ICategory | null) {
    state.categoryForCustomization = value
  },
  SET_EDIT_ACCOUNTING_ACCOUNT (state, value: IAccountingAccount | null) {
    state.editAccountingAccount = value
  },
  SET_IS_ACCOUNTING_ACCOUNT_MODAL_OPENED (state, value: boolean) {
    state.isAccountingAccountModalOpened = value
  },
  SET_IS_DELETE_ACCOUNTING_ACCOUNT_MODAL_OPENED (state, value: boolean) {
    state.isDeleteAccountingAccountModalOpened = value
  },
  SET_DELETE_ACCOUNTING_ACCOUNT (state, value: IAccountingAccount | null) {
    state.deleteAccountingAccount = value
  },
  SET_COUNT_RELATED_ACCOUNTING_ACCOUNT_ENTRY (state, value: number | null) {
    state.countAccountingAccountRelatedEntry = value
  },
  SET_ENTRY_FOR_SELECTION (state, value: ReadEntry | null) {
    state.entryForSelection = value
  },
  SET_IS_ATTACHMENT_MODAL_OPENED (state, value: boolean) {
    state.isAttachmentTransactionOpened = value
  },
  SET_IS_PAYMENT_METHOD_MODAL_OPENED (state, value: boolean) {
    state.isPaymentMethodTransactionOpened = value
  },
}

export const actions: ActionTree<AccountingAccountsState, AccountingAccountsState> = {
  async fetchAccountingAccounts ({ commit }) {
    const accountingAccounts = await this.$api.accountingAccounts.getAll()
    commit('SET_ACCOUNTING_ACCOUNT', accountingAccounts)
  },
  setLoadingAccountingAccountMenu ({ commit }, value) {
    commit('SET_LOADING_ACCOUNTING_ACCOUNT_MENU', value)
  },
  async createAccountingAccount ({ dispatch, commit, getters }, payload: CreateAccountingAccount) {
    const newAccountingAccount = await this.$api.accountingAccounts.create(payload)
    await dispatch('fetchAccountingAccounts')
    commit('SET_ACCOUNTING_ACCOUNT', getters.accountingAccounts.map((a: IAccountingAccount) => ({
      ...a,
      new: a.id === newAccountingAccount.id,
    })))
  },

  async updateAccountingAccount ({ dispatch }, payload: UpdateAccountingAccount) {
    await this.$api.accountingAccounts.update(payload)
    await dispatch('fetchAccountingAccounts')
  },

  setEditAccountingAccount ({ commit }, payload: IAccountingAccount) {
    commit('SET_EDIT_ACCOUNTING_ACCOUNT', payload)
  },

  setAccountingAccountModal ({ commit }, value: boolean) {
    commit('SET_IS_ACCOUNTING_ACCOUNT_MODAL_OPENED', value)
  },

  setIsPersonal ({ commit }, value: boolean) {
    commit('SET_IS_PERSONAL', value)
  },

  setSearchAccount ({ commit }, value: string | null) {
    commit('SET_SEARCH_ACCOUNT', value)
  },

  setAttachmentModal ({ commit }, value: boolean) {
    commit('SET_IS_ATTACHMENT_MODAL_OPENED', value)
  },

  async fetchCountEntryRelatedAccountingAccount ({ commit }, id: string) {
    const count = await this.$api.accountingAccounts.countEntries(id)
    commit('SET_COUNT_RELATED_ACCOUNTING_ACCOUNT_ENTRY', count)
  },

  setAccountingAccountDeleteModal ({ commit }, value: boolean) {
    commit('SET_IS_DELETE_ACCOUNTING_ACCOUNT_MODAL_OPENED', value)
  },

  async openAccountingAccountDeleteModal ({ commit, dispatch, getters }, value: IAccountingAccount) {
    await dispatch('fetchCountEntryRelatedAccountingAccount', value.id)
    const category: ICategory = Number.isInteger(value.category) ? getters.categories.find((c: ICategory) => c.id === value.category) : value.category
    commit('SET_DELETE_ACCOUNTING_ACCOUNT', {
      ...value,
      category,
    })
    commit('SET_IS_DELETE_ACCOUNTING_ACCOUNT_MODAL_OPENED', true)
  },

  async deleteAccountingAccount ({ dispatch }, payload: DeleteAccountingAccount) {
    await this.$api.accountingAccounts.delete(payload)
    await dispatch('fetchAccountingAccounts')
  },

  setTransactionForSelection ({ commit }, payload: ReadTransaction) {
    commit('SET_TRANSACTION_FOR_SELECTION', payload)
  },
  openSelectionModal ({ commit, getters }, payload: { transaction: ReadTransaction, entry: ReadEntry }) {
    commit('SET_TRANSACTION_FOR_SELECTION', payload.transaction)
    commit('SET_ENTRY_FOR_SELECTION', payload.entry)
    commit('SET_IS_SELECTION_MODAL_OPENED', true)
    commit('SET_CATEGORY_FOR_CUSTOMIZATION', getters.favoriteCategory)
  },
  setSelectionModalOpen ({ commit }, value: boolean) {
    commit('SET_IS_SELECTION_MODAL_OPENED', value)
  },
  // async fetchAndDisplaySimilarTransactions ({ commit }, transaction: ITransaction) {
  //   if (!transaction.id) { return }
  //   const result = await this.$api.transaction.getSimilarTransactions(transaction.id)
  //   if (result?.length > 0) {
  //     commit('SET_SIMILAR_TRANSACTIONS_TO_ANNOTATE', result)
  //     commit('SET_TRANSACTION_FOR_SUGGESTION', transaction)
  //     commit('SET_IS_SUGGESTED_MODAL_OPENED', true)
  //   }
  // },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async annotateTransactionWithIsPersonal ({ dispatch }, { transaction, isPersonal, vatEntry }: { transaction: ITransaction, isPersonal: boolean, vatEntry: IEntry }) {
    if (!transaction.id) { return }
    const result = await this.$api.transaction.annotate(transaction.id, {
      entries: [{
        isPersonal,
        vatId: vatEntry?.vatId,
        amount: transaction.amount,
      }],
    })
    await dispatch('updateTransactionInAccountingPageAndAccountingAccountAndSuggestion', result)
  },
  // NEW VERSION ANNOTATION
  async annotateTransaction ({ dispatch, commit }, { transaction, payload }: { transaction: ITransaction, payload: AnnotateTransaction, similarSuggestion: boolean }) {
    if (!transaction.id) { return }
    const result = await this.$api.transaction.annotate(transaction.id, payload)
    commit('accountingPage/UPDATE_TRANSACTIONS', [result], { root: true })
    await dispatch('updateTransactionToAnnotateForAccount', transaction.bridgeAccountId)
    return result
    // if (!similarSuggestion || (similarSuggestion && transaction.entries.length > 1)) {
    // await dispatch('updateTransactionInAccountingPageAndAccountingAccountAndSuggestion', result)
    // await dispatch('fetchAndDisplaySimilarTransactions', {
    //   ...transaction,
    //   ...result,
    // })
  },
  async annotateTransactionWithAccountingAccount ({ dispatch }, { transaction, accountingAccount, vatEntry }: { transaction: ITransaction, accountingAccount: IAccountingAccount, vatEntry?: IEntry, disableSimilarSuggestions?: boolean }) {
    if (!transaction.id) { return }
    const result = await this.$api.transaction.annotate(transaction.id, {
      entries: [{
        isPersonal: accountingAccount.isPersonal,
        accountingAccountNumber: accountingAccount.number,
        vatId: vatEntry?.vatId,
        amount: transaction.amount,
      }],
    })
    await dispatch('updateTransactionInAccountingPageAndAccountingAccountAndSuggestion', result)
    // if (!disableSimilarSuggestions) {
    //   await dispatch('fetchAndDisplaySimilarTransactions', result)
    // }
  },
  async updateTransactionInAccountingPageAndAccountingAccountAndSuggestion ({ commit, dispatch, getters }, transaction: ITransaction) {
    commit('UPDATE_TRANSACTION_FOR_SELECTION', transaction)
    if (getters.isDetailedTransactionOpened) {
      commit('UPDATE_DETAILED_TRANSACTION', transaction)
    }
    commit('accountingPage/UPDATE_TRANSACTIONS', [transaction], { root: true })
    await dispatch('updateTransactionToAnnotateForAccount', transaction.bridgeAccountId)
  },
  async updateTransactionToAnnotateForAccount ({ commit, rootGetters }, bridgeAccountId: number) {
    const numberOfTransactionsToAnnotate = await this.$api.account.transactionsToAnnotate(bridgeAccountId)
    commit('accounts/UPDATE_ACCOUNT', { bridgeAccountId, numberOfTransactionsToAnnotate }, { root: true })
    if (rootGetters['accountingPage/selectedAccount'].bridgeAccountId === bridgeAccountId) {
      commit('accountingPage/UPDATE_SELECTED_ACCOUNT', { numberOfTransactionsToAnnotate }, { root: true })
    }
  },
  // eslint-disable-next-line require-await,@typescript-eslint/no-unused-vars
  async annotateSelectedSuggestedTransactions ({ dispatch, commit }, { transaction, accountingAccount, vatEntry, transactionIds }: { transaction: ITransaction, accountingAccount: IAccountingAccount, vatEntry?: IEntry, transactionIds: Array<string> }) {
    if (!transaction.id) { return }
    const result = await this.$api.transaction.annotateSimilarTransactions(transaction.id, {
      isPersonal: accountingAccount.isPersonal,
      amount: transaction.amount,
      accountingAccountNumber: accountingAccount.number,
      vatId: vatEntry?.vatId,
      transactionIds,
    })
    commit('accountingPage/UPDATE_TRANSACTIONS', result, { root: true })

    await dispatch('updateTransactionToAnnotateForAccount', transaction.bridgeAccountId)
  },
  setCategoryForCustomization ({ commit }, value: ICategory | IGroupAccountingAccount | null) {
    commit('SET_CATEGORY_FOR_CUSTOMIZATION', value)
  },
  setPaymentMethodsModal ({ commit }, value: boolean) {
    commit('SET_IS_PAYMENT_METHOD_MODAL_OPENED', value)
  },
  setDetailedTransaction ({ commit }, value: ITransaction | null) {
    if (!value) {
      commit('SET_IS_DETAILED_TRANSACTION_OPENED', false)
      setTimeout(() => {
        commit('SET_DETAILED_TRANSACTION', value)
      }, 300)
    } else {
      commit('SET_IS_DETAILED_TRANSACTION_OPENED', true)
      commit('SET_DETAILED_TRANSACTION', value)
    }
  },
}
