import { BillingType } from '@abby/shared'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { ContactItem } from '~/logic/contexts/client/domain/entity/contactItem.entity'
import { ContactAction } from '~/logic/contexts/client/domain/enums/contactAction.enum'
import { ClientType } from '~/logic/contexts/client/domain/enums/clientType.enum'
import { ContactDetailsTabs } from '~/logic/contexts/client/domain/enums/contactDetailsTabs.enum'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useCopyContactID } from '~/composables/client/contact/admin/useCopyContactID'
import { useOpenClientFormEditionSidePanel } from '~/composables/client/useOpenClientFormEditionSidePanel'
import { useOpenContactDetailsSidePanel } from '~/composables/client/contact/useOpenContactDetailsSidePanel'
import { useArchiveContact } from '~/composables/client/contact/action/useArchiveContact'
import { useUnarchiveContact } from '~/composables/client/contact/action/useUnarchiveContact'
import { useRemoveContact } from '~/composables/client/contact/action/useRemoveContact'
import {
  useOpenOrganizationDetailsSidePanel,
} from '~/composables/client/organization/useOpenOrganizationDetailsSidePanel'
import {
  useUpdateDefineAsPrimaryContact,
} from '~/composables/client/organization/action/useUpdateDefineAsPrimaryContact'
import {
  useOpenCopyUrssafActionEmailNotReceivedMessage,
} from '~/composables/client/contact/action/useOpenCopyUrssafActionEmailNotReceivedMessage'
import {
  useOpenCopyUrssafActionUpdatedEmailMessage,
} from '~/composables/client/contact/action/useOpenCopyUrssafActionUpdatedEmailMessage'
import { useOpenContactTaxReturnModal } from '~/composables/client/contact/action/useOpenContactTaxReturnModal'
import { useGoToClientPortal } from '~/composables/client/useGoToClientPortal'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'

export const useHandleContactActions = () => {
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const clientStore = useClientStore()
  const billingStore = useBillingStore()

  const copyContactID = useCopyContactID()
  const openClientFormEditionSidePanel = useOpenClientFormEditionSidePanel()
  const openContactDetailsSidePanel = useOpenContactDetailsSidePanel()
  const archiveContact = useArchiveContact()
  const unarchiveContact = useUnarchiveContact()
  const removeContact = useRemoveContact()
  const openOrganizationDetailsSidePanel = useOpenOrganizationDetailsSidePanel()
  const updateDefineAsPrimaryContact = useUpdateDefineAsPrimaryContact()
  const copyUrssafActionEmailNotReceivedMessage = useOpenCopyUrssafActionEmailNotReceivedMessage()
  const copyUrssafActionUpdatedEmailMessage = useOpenCopyUrssafActionUpdatedEmailMessage()
  const openContactTaxReturnModal = useOpenContactTaxReturnModal()
  const goToClientPortal = useGoToClientPortal()

  const openBillingCreationModalWithClient = async (contact: ContactItem, type?: BillingType) => {
    const customer = await billingRepository.fetchCustomerFromContact(contact.id)
    await billingStore.toggleBillingCreationModal(true, type, customer)
    clientStore.toggleContactDetailsSidePanel(false)
  }

  const actions: {
    [key in ContactAction]: {
      run: (contact: ContactItem, options?: any) => Promise<any> | any;
    }
  } = {
    [ContactAction.ADMIN_COPY_CONTACT_ID]: {
      run: contact => copyContactID(contact),
    },

    [ContactAction.EDIT]: {
      run: contact => openClientFormEditionSidePanel(contact.id, ClientType.CONTACT),
    },
    [ContactAction.EDIT_NOTES]: {
      run: contact => openContactDetailsSidePanel(contact.id, { defaultTab: ContactDetailsTabs.INFORMATION }),
    },
    [ContactAction.ARCHIVE]: {
      run: contact => archiveContact(contact),
    },
    [ContactAction.UNARCHIVE]: {
      run: contact => unarchiveContact(contact),
    },
    [ContactAction.DELETE]: {
      run: contact => removeContact(contact),
    },

    [ContactAction.VIEW_DETAILS]: {
      run: contact => openContactDetailsSidePanel(contact.id),
    },
    [ContactAction.VIEW_ORGANIZATION_DETAILS]: {
      run: async (contact) => {
        if (contact.organization) {
          await openOrganizationDetailsSidePanel(contact.organization?.id)
        }
      },
    },
    [ContactAction.DEFINE_AS_PRIMARY_CONTACT]: {
      run: async (contact) => {
        if (contact.organization) {
          await updateDefineAsPrimaryContact(contact.organization.id, { defaultContactId: contact.id })
        }
      },
    },

    [ContactAction.CREATE_BILLING]: {
      run: contact => openBillingCreationModalWithClient(contact),
    },
    [ContactAction.CREATE_INVOICE]: {
      run: contact => openBillingCreationModalWithClient(contact, BillingType.INVOICE),
    },
    [ContactAction.CREATE_ESTIMATE]: {
      run: contact => openBillingCreationModalWithClient(contact, BillingType.ESTIMATE),
    },
    [ContactAction.CREATE_ASSET]: {
      run: contact => openBillingCreationModalWithClient(contact, BillingType.ASSET),
    },
    [ContactAction.CREATE_PURCHASE_ORDER]: {
      run: contact => openBillingCreationModalWithClient(contact, BillingType.PURCHASE_ORDER),
    },

    [ContactAction.URSSAF_ACTIVATION_EMAIL_NOT_RECEIVED]: {
      run: contact => copyUrssafActionEmailNotReceivedMessage(contact),
    },
    [ContactAction.UPDATED_EMAIL_URSSAF_ACCOUNT]: {
      run: contact => copyUrssafActionUpdatedEmailMessage(contact),
    },
    [ContactAction.DOWNLOAD_TAX_RETURN]: {
      run: contact => openContactTaxReturnModal(contact.id),
    },

    [ContactAction.GO_TO_CLIENT_PORTAL]: {
      run: contact => goToClientPortal(contact.id, ClientType.CONTACT),
    },
  }

  return async ({ action, contact, options }: { action: ContactAction, contact: ContactItem, options?: any }) => {
    try {
      await actions[action].run(contact, options)
    } catch (error) {
      alertManager.autoError(error)
    }
  }
}
