import { AbbyPlans } from '@abby/core-legacy'
import { Middleware, Context } from '@nuxt/types'
import { AbbyPixelManagerInstance } from '~/plugins/abbyPixel'

// TYPES

type Route = Context['route']
type Store = Context['store']

// MIDDLEWARE

const onboarding: Middleware = function ({ store, route, redirect, $ap }) {
  const isOnboarding = route.path === '/start/gestion'
  if (!isOnboarding) {
    return
  }
  redirectToOnboarding(route, redirect)
  registerActivity(route)
  signupPlan(store, route)
}

// FUNCTIONS

const redirectToOnboarding = (route: Route, redirect: (path: string) => void) => {
  const abnumberOnboarding = localStorage.getItem('abno') || (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString()
  localStorage.setItem('abno', abnumberOnboarding)
  if (Number(abnumberOnboarding) >= 50) {
    const newRoute = route.path.replace('/start/gestion', '/start_5/gestion')
    redirect(newRoute)
  } else {
    const newRoute = route.path.replace('/start/gestion', '/start_4/gestion')
    redirect(newRoute)
  }
}

const registerActivity = (route: Route) => {
  const activity = route.query.activity as string || ''
  if (activity) {
    localStorage.setItem('activity', activity)
  }
}

const signupPlan = (store: Store, route: Route) => {
  const signupPlan = route.query.signupPlan as unknown as AbbyPlans | null
  if (isValidAbbyPlan(signupPlan)) {
    store.dispatch('auth/setSignUpPlan', signupPlan)
  }
}

const isValidAbbyPlan = (plan: AbbyPlans | null) => {
  return plan && [AbbyPlans.ABBY_START, AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_BUSINESS].includes(plan)
}

export default onboarding
