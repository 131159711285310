import { onMounted, onUnmounted } from 'vue'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export function useBusManagerEvent (event: string | string[], callback: (data: any) => void) {
  const busManager = useBusManager()
  const eventIds: string[] = []
  onMounted(() => {
    if (Array.isArray(event)) {
      event.forEach((e) => {
        const eventId = busManager.on(e, callback)
        eventIds.push(eventId)
      })
    } else {
      const eventId = busManager.on(event, callback)
      eventIds.push(eventId)
    }
  })
  onUnmounted(() => {
    if (eventIds) {
      eventIds.forEach((eventId) => {
        busManager.off(eventId)
      })
    }
  })
}
