import { CreationDashboardStep } from './CreationDashboardStep';

export enum CreationCompanyStep {
  CREATION_REQUESTED = 'creation_requested',
  FILES_REQUESTED = 'files_requested',
  DECLARATION_SENT = 'declaration_sent',
  DECLARATION_REFUSED = 'declaration_refused',
  COMPANY_CREATED = 'company_created',
  URSSAF_ACCOUNT_CREATED = 'urssaf_account_created',
  IMPOTS_ACCOUNT_CREATED = 'impots_account_created',
  ACRE_REQUESTED = 'acre_requested',
  CFE_DECLARED = 'cfe_declared',
  ALL_FILES_DEPOSED = 'all_files_deposed',
  HAS_FILE_REFUSED = 'has_file_refused',
  ALL_FILES_VALIDATED = 'all_files_validated',
  NONE = 'none',
}

export const CreationCompanyStepOrder = {
  [CreationCompanyStep.CREATION_REQUESTED]: 1,
  [CreationCompanyStep.FILES_REQUESTED]: 2,
  [CreationCompanyStep.ALL_FILES_DEPOSED]: 3,
  [CreationCompanyStep.ALL_FILES_VALIDATED]: 4, // ici
  [CreationCompanyStep.HAS_FILE_REFUSED]: 5,
  [CreationCompanyStep.DECLARATION_SENT]: 6,
  [CreationCompanyStep.DECLARATION_REFUSED]: 7,
  [CreationCompanyStep.COMPANY_CREATED]: 8,
  [CreationCompanyStep.URSSAF_ACCOUNT_CREATED]: 9,
  [CreationCompanyStep.ACRE_REQUESTED]: 10,
  [CreationCompanyStep.IMPOTS_ACCOUNT_CREATED]: 11,
  [CreationCompanyStep.CFE_DECLARED]: 12,

  [CreationCompanyStep.NONE]: -1,
};

export const CreationCompanyStepColor = {
  [CreationCompanyStep.CREATION_REQUESTED]: 'orange lighten-2',
  [CreationCompanyStep.FILES_REQUESTED]: 'orange lighten-1',
  [CreationCompanyStep.ALL_FILES_DEPOSED]: 'red lighten-2',
  [CreationCompanyStep.ALL_FILES_VALIDATED]: 'red lighten-1',
  [CreationCompanyStep.HAS_FILE_REFUSED]: 'blue lighten-2',
  [CreationCompanyStep.DECLARATION_SENT]: 'blue lighten-1',
  [CreationCompanyStep.COMPANY_CREATED]: 'purple lighten-2',
  [CreationCompanyStep.URSSAF_ACCOUNT_CREATED]: 'purple lighten-1',
  [CreationCompanyStep.ACRE_REQUESTED]: 'green lighten-2',
  [CreationCompanyStep.IMPOTS_ACCOUNT_CREATED]: 'green lighten-1',
  [CreationCompanyStep.CFE_DECLARED]: 'green lighten-3',
  [CreationCompanyStep.DECLARATION_REFUSED]: 'pink lighten-2',

  [CreationCompanyStep.NONE]: 'orange lighten-3',
};

export const MappingCreationDashboardStepToCreationCompanyStep = {
  [CreationDashboardStep.FILES_TO_VALIDATE]: CreationCompanyStep.ALL_FILES_DEPOSED,
  [CreationDashboardStep.DECLARATION_TO_DO]: CreationCompanyStep.ALL_FILES_VALIDATED,
  [CreationDashboardStep.DECLARATION_IN_PROGRESS]: CreationCompanyStep.ALL_FILES_VALIDATED,
  [CreationDashboardStep.PENDING_DECLARATION]: CreationCompanyStep.ALL_FILES_VALIDATED,
  [CreationDashboardStep.DECLARATION_TO_REPORT]: CreationCompanyStep.ALL_FILES_VALIDATED,
  [CreationDashboardStep.DECLARED]: CreationCompanyStep.DECLARATION_SENT,
  [CreationDashboardStep.ENDED]: CreationCompanyStep.COMPANY_CREATED,
  [CreationDashboardStep.DECLARATION_REFUSED]: CreationCompanyStep.DECLARATION_REFUSED,
  [CreationDashboardStep.DECLARATION_TO_RETRY]: CreationCompanyStep.DECLARATION_REFUSED,
  [CreationDashboardStep.DECLARATION_PENDING_DENIAL]: CreationCompanyStep.DECLARATION_REFUSED,
};

export const MappingCreationCompanyStepToCreationDashboardStep = {
  [CreationCompanyStep.ALL_FILES_DEPOSED]: CreationDashboardStep.FILES_TO_VALIDATE,
  [CreationCompanyStep.HAS_FILE_REFUSED]: CreationDashboardStep.NONE,
  [CreationCompanyStep.FILES_REQUESTED]: CreationDashboardStep.NONE,
  [CreationCompanyStep.ALL_FILES_VALIDATED]: CreationDashboardStep.DECLARATION_TO_DO,
  [CreationCompanyStep.DECLARATION_SENT]: CreationDashboardStep.DECLARED,
  [CreationCompanyStep.COMPANY_CREATED]: CreationDashboardStep.TO_CALL,
  [CreationCompanyStep.DECLARATION_REFUSED]: CreationDashboardStep.DECLARATION_REFUSED,
};
