import fr from 'vuetify/es5/locale/fr'
import _$vuetifyFr from '../../lang/fr/$vuetify_fr'
import '@fortawesome/fontawesome-free/css/all.css'

require('./partials/_typographies.scss')
require('./overrides.scss')

// initialiser toutes les couleurs a #FFFFF pour pouvoir les declarer et les réécrire par la suite

export const preset = {
  lang: {
    locales: {
      fr: {
        ...fr,
        ..._$vuetifyFr,
      },
    },
    current: 'fr',
  },
  rtl: false,
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
  },
}
