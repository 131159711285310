
import { Action, Component, Getter, ModelSync, Ref, Vue, Watch } from 'nuxt-property-decorator'
import { IBillingConfiguration, ICompany, UpdateBillingConfiguration } from '@abby/core-legacy'
import { ValidationProvider } from 'vee-validate'
import HomeGuideModal from '~/components-legacy/home/home-guide/HomeGuideModal.vue'
import ASChoiceInput from '~/components-legacy/inputs/ASChoiceInput.vue'
import ASForm from '~/components-legacy/inputs/ASForm.vue'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import ASDropzone2 from '~/components-legacy/inputs/ASDropzone2.vue'
import Link from '~/components-legacy/utils/Link.vue'
import AsDatePicker from '~/components-legacy/inputs/ASDatePicker.vue'
import { IAuthUser } from '~/store/auth'
import AsListSelection from '~/components-legacy/inputs/ASListSelection.vue'
import { GuideState } from '~/store/guide'

@Component({
  components: {
    AsDatePicker,
    Link,
    ASDropzone2,
    AsTextField,
    ASForm,
    AsListSelection,
    ASChoiceInput,
    HomeGuideModal,
  },
})
export default class HomeGuideModalImmediateAdvance extends Vue {
  @Getter('guide/modal')
  guideModal!: GuideState['modal']

  @Getter('auth/user')
  user!: IAuthUser

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration

  @Getter('company/company')
  company!: ICompany

  @Ref('form') form!: InstanceType<typeof ValidationProvider>

  @Ref('modal') modal!: any

  @Action('billingConfiguration/updateBillingConfiguration')
  updateBillingConfiguration!: (payload: UpdateBillingConfiguration) => Promise<void>;

  step: number = 1
  value1: boolean = true
  value2: number = 1
  clientId: string = ''
  clientSecret: string = ''
  file: File | null = null
  novaNumber: string = 'SAP'
  sapAgreementDate: Date | null = null
  actionLoading: boolean = false
  hasConnexionError: boolean = false
  showFileInput: boolean = true

  @Watch('guideModal.immediateAdvanceModal')
  handleOpened (value: boolean) {
    if (value) {
      this.clientId = this.billingConfiguration?.tiersPrestationCredentials?.clientId || ''
      this.novaNumber = this.company?.siren ? `SAP${this.company?.siren}` : 'SAP'
      this.sapAgreementDate = this.company?.sapAgreementDate || null
      this.file = null
      this.value1 = true
      this.value2 = 1
      this.step = 1
      this.hasConnexionError = false
    }
  }

  @Watch('file')
  async fileHandler (file: File) {
    if (!file) { return }
    const buffer = await file.arrayBuffer()

    const enc = new TextDecoder('utf-8')
    const decodedBuffer = enc.decode(buffer)
    const matchClient = /(client-id :[\r\n])+([^\r\n]+)/gsi.exec(decodedBuffer)
    const matchSecret = /(client-secret :[\r\n])+([^\r\n]+)/gsi.exec(decodedBuffer)

    this.clientId = matchClient?.[2] || ''
    this.clientSecret = matchSecret?.[2] || ''
  }

  get calendlyLink () {
    const query = {
      email: this.user?.email,
      name: this.user?.fullName,
      phone_number: this.user?.phone,
    }

    const serialize = (obj: any) => {
      const str = '?' + Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
      return str
    }
    const _query = serialize(query)
    return `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs-sap/${_query === '?' || _query === '' ? '' : _query || ''}`
  }

  openGetCredentialDocumentation () {
    window.open('https://drive.google.com/file/d/17dbAy0kN7ff_sowlILJmMn2PmSPnnX-o/view', '_blank')
  }

  openGetSAPAgreement () {
    window.open('https://blog.abby.fr/obtenir-agrement-sap-service-a-la-personne/', '_blank')
  }

  get actionText () {
    if (this.step === 1) {
      return 'Continuer'
    } else if (this.step === 2 && this.caseToDisplay === 1) {
      return 'Continuer'
    } else if (this.step === 3 && this.caseToDisplay === 1) {
      return 'Continuer'
    } else if (this.step === 4 && this.caseToDisplay === 1) {
      return 'Fermer et continuer'
    } else {
      return 'Fermer'
    }
  }

  async next () {
    if (this.step === 1) {
      this.step = 2
    } else if (this.step === 2 && this.caseToDisplay === 1) {
      if (!(await this.form?.validate())) { return }
      const connected = await this.connectTp()
      if (!connected) { return }
      this.step = 3
    } else if (this.step === 3 && this.caseToDisplay === 1) {
      if (!(await this.form?.validate())) { return }
      const informationSaved = await this.saveTpInformation()
      if (!informationSaved) { return }
      this.step = 4
      this.modal?.explodeConfettis()
    } else {
      this.close()
    }
  }

  async connectTp () {
    try {
      this.actionLoading = true
      const clientId = this.clientId ?? ''
      const clientSecret = this.clientSecret ?? ''

      await this.$backend.tierPrestation.checkCredentials({
        clientId,
        clientSecret,
      })

      await this.updateBillingConfiguration({
        tiersPrestationCredentials: {
          clientId,
          clientSecret,
        },
      })
      return true
    } catch (e) {
      this.hasConnexionError = true
      this.showFileInput = false
      return false
    } finally {
      this.actionLoading = false
    }
  }

  async saveTpInformation () {
    try {
      this.actionLoading = true
      await this.$backend.tierPrestation.setCredentials({
        clientId: this.clientId,
        clientSecret: this.clientSecret,
        novaNumber: this.novaNumber,
      })
      await this.updateBillingConfiguration({
        tiersPrestationValidated: true,
      })
      await this.$store.dispatch('company/updateCompany', {
        id: this.company.id,
        payload: {
          sapAgreementDate: this.sapAgreementDate,
        },
      })

      return true
    } catch (e) {
      this.hasConnexionError = true
      this.$alertsManager.autoError(e)
      return false
    } finally {
      this.actionLoading = false
    }
  }

  get caseToDisplay () {
    if (this.value1 && this.value2 === 1) {
      return 1
    } else if (this.value1 && this.value2 === 2) {
      return 2
    } else if (this.value1 && this.value2 === 3) {
      return 3
    } else if (!this.value1) {
      return 4
    }
  }

  get itemsValue1 () {
    return [
      { value: true, text: 'Oui' },
      { value: false, text: 'Non' },
    ]
  }

  get itemsValue2 () {
    return [
      { value: 1, text: 'Oui' },
      { value: 2, text: 'Je suis en attente de leur réception' },
      { value: 3, text: 'Je souhaite faire la demande' },
    ]
  }

  close () {
    this.$store.dispatch('guide/closeImmediateAdvanceModal')
  }
}

