import { ReadOpportunity } from '@abby/core-legacy'
import { useOpportunityStore } from '~/logic/contexts/opportunity/infrastructure/store/opportunityPinia.store'

export const useToggleOpportunityInfoSidePanel = () => {
  const opportunityStore = useOpportunityStore()

  return async (open: boolean, _opportunity?: ReadOpportunity) => {
    return await opportunityStore.toggleOpportunityInfoSidePanel(open, _opportunity)
  }
}
