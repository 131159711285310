import { Resource } from '../Resource';
import {
  CheckCredentials,
  RegisterCustomer,
  SetCredentials,
} from '../../dto';

export class TierPrestation extends Resource {
  setCredentials(params: SetCredentials): Promise<void> {
    return this.http.$post('/v2/tier-prestation/credentials', params);
  }

  checkCredentials(params: CheckCredentials): Promise<void> {
    return this.http.$post('/v2/tier-prestation/check-credentials', params);
  }

  registerCustomer(customer: RegisterCustomer): Promise<void> {
    return this.http.$post('/v2/tier-prestation/register-customer', customer);
  }
}
