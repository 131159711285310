
import { Action, Component, Getter, ModelSync, Vue } from 'nuxt-property-decorator'
import {
  BillingType,
  documentTextNumber,
  IBillingConfiguration, PersonaFeature, ReadStripeAccount,
  ReadStripeAccountLink,
  SegmentEvent,
  PlanFeature,
} from '@abby/core-legacy'
import HomeGuideModal from '~/components-legacy/home/home-guide/HomeGuideModal.vue'
import { BillingConfigurationModals } from '~/store/billingConfiguration'
import { IAuthUser } from '~/store/auth'
import { GuideState } from '~/store/guide'
import AbbyPlanButton from '~/components-legacy/chips/AbbyPlanButton.vue'

@Component({
  components: { HomeGuideModal, AbbyPlanButton },
})
export default class HomeGuideModalOnlinePayment extends Vue {
  @Getter('guide/modal')
  guideModal!: GuideState['modal']

  redirectLoading: boolean = false

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration

  @Getter('auth/user')
  user!: IAuthUser

  @Action('billingConfiguration/openModal') openBillingConfigurationModal!: (key: BillingConfigurationModals) => void

  @Getter('billingConfiguration/stripeAccountLink')
  stripeAccountLink!: ReadStripeAccountLink | null;

  @Getter('billingConfiguration/stripeAccount')
  stripeAccount!: ReadStripeAccount | null;

  get calendlyLink () {
    const query = {
      email: this.user?.email,
      name: this.user?.fullName,
      phone_number: this.user?.phone,
    }

    function serialize (obj: any) {
      const str = '?' + Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
      return str
    }

    const _query = serialize(query)
    return `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs/${_query === '?' || _query === '' ? '' : _query || ''}`
  }

  get onlinePaymentIsEnabled () {
    return this.stripeAccount && (!this.stripeAccount.requirements || (this.stripeAccount.requirements && !this.stripeAccount.requirements.disabled_reason))
  }

  get steps () {
    return [
      {
        title: 'Lancez la configuration de votre compte Stripe Express',
        subtitle: 'Nous avons sélectionné Stripe pour vous permettre de recevoir des paiements en ligne sécurisés. <u>Si vous avez déjà un compte Stripe</u>, vous devrez en créer un nouveau associé au compte Stripe Connect d\'Abby.',
      },
      {
        title: 'Renseignez toutes les informations nécessaires',
        subtitle: 'Pour que vos clients puissent payer en ligne, vous devrez renseigner des informations sur votre entreprise et valider votre identité.',
      },
      {
        title: 'Recevez des paiement par carte bancaire',
        subtitle: 'Vos clients auront accès à leur propre espace pour régler leurs factures par carte bancaire. Vous pourrez également leur envoyer un lien de paiement par email. Vous serez notifié en cas de paiement et votre comptabilité se mettra à jour automatiquement.',
      },
    ]
  }

  async goToStripeConfiguration () {
    try {
      this.redirectLoading = true
      await this.$store.dispatch('billingConfiguration/createStripeAccount')
      await this.$store.dispatch('billingConfiguration/fetchBillingPaymentAccountLink')
      if (this.stripeAccountLink?.url) {
        // @ts-ignore
        window.location = this.stripeAccountLink?.url
        this.$ap.sendSegmentTrack({
          event: SegmentEvent.ACTION_KEY,
          uniqueIdentifier: this.stripeAccount?.id,
          data: {
            eventType: SegmentEvent.STRIPE_CONFIGURATION_STARTED,
            stripeAccount: this.stripeAccount,
          },
        })
      } else {
        this.$alertsManager.error('Le lien de configuration n\'a pas été reçu')
      }
    } catch (e) {
      this.$alertsManager.autoError(e)
      this.redirectLoading = false
    }
  }

  close () {
    this.$store.dispatch('guide/closeOnlinePaymentModal')
  }

  get PlanFeature () {
    return PlanFeature
  }

  get hasAccessToBillingOnlinePayment () {
    return this.$planManager.hasAccessTo(PlanFeature.BILLING_ONLINE_PAYMENT)
  }
}

