import { StockEvent } from '@abby/shared'

export interface StockManagementConfigurationItemProps {
  id: string;
  companyId: string;
  isActivated: boolean;
  stockEvent: StockEvent;
  createdAt: number;
  updatedAt: number;
}

export class StockManagementConfigurationItem {
  readonly id: string;
  readonly companyId: string;
  readonly isActivated: boolean;
  readonly stockEvent: StockEvent;
  readonly createdAt: number;
  readonly updatedAt: number;

  constructor (props: StockManagementConfigurationItemProps) {
    this.id = props.id
    this.companyId = props.companyId
    this.isActivated = props.isActivated
    this.stockEvent = props.stockEvent
    this.createdAt = props.createdAt
    this.updatedAt = props.updatedAt
  }

  static create (props: StockManagementConfigurationItemProps): StockManagementConfigurationItem {
    return new StockManagementConfigurationItem(props)
  }
}
