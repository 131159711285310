export enum Category {
  PROFESSIONAL_FAVORITES = 'PROFESSIONAL_FAVORITES',
  PERSONAL_FAVORITES = 'PERSONAL_FAVORITES',
  INCOMES = 'INCOMES',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  OPERATION = 'OPERATION',
  TRAVEL = 'TRAVEL',
  FIXED_COSTS = 'FIXED_COSTS',
  CONTRIBUTIONS_AND_TAXES = 'CONTRIBUTIONS_AND_TAXES',
  ACCOUNTING = 'ACCOUNTING',
  /// Catégorie personnelle
  SUBSCRIPTION = 'SUBSCRIPTION',
  BUYING_AND_SHOPPING = 'BUYING_AND_SHOPPING',
  FOOD_AND_RESTAURANT = 'FOOD_AND_RESTAURANT',
  CAR_AND_TRANSPORT = 'CAR_AND_TRANSPORT',
  BANK = 'BANK',
  VARIOUS = 'VARIOUS',
  AESTHETICS_AND_CARE = 'AESTHETICS_AND_CARE',
  TAXES_AND_DUTIES = 'TAXES_AND_DUTIES',
  HOUSING = 'HOUSING',
  LEISURE_AND_OUTINGS = 'LEISURE_AND_OUTINGS',
  CHEQUE_AND_BANK_TRANSFER_WITHDRAWALS = 'CHEQUE_AND_BANK_TRANSFER_WITHDRAWALS',
  HEALTH = 'HEALTH',
  SCHOOLING_AND_CHILDREN = 'SCHOOLING_AND_CHILDREN',
  PERSONAL_INCOMES = 'PERSONAL_INCOMES',
}