import { GetterTree, ActionTree, MutationTree } from 'vuex'
import debounce from 'lodash/debounce'
import type { QueryStatistics, ReadExpensesIncomesByMonth } from '@abby/core-legacy'
import { AccountingPageQuery } from '~/store/accountingPage'

export interface StatisticsState {
  expenses: [] | null,
  incomes: [] | null,
  balance: [] | null,
  incomesAndExpenses: ReadExpensesIncomesByMonth | null
  query: QueryStatistics;
  queryLoading: boolean;
  initialLoading: boolean;
}

export const state = (): StatisticsState => ({
  expenses: null,
  incomes: null,
  balance: null,
  incomesAndExpenses: null,
  // FIXME: impossible d'utiliser $daysjs ici donc date par défaut à gérer..
  query: {
    to: new Date(),
    from: new Date(),
    isPersonal: undefined,
  },
  queryLoading: true,
  initialLoading: true,
})

export const getters: GetterTree<StatisticsState, StatisticsState> = {
  expenses: state => state.expenses,
  incomes: state => state.incomes,
  balance: state => state.balance,
  queryLoading: state => state.queryLoading,
  initialLoading: state => state.initialLoading,
  query: state => state.query,
  incomesAndExpenses: state => state.incomesAndExpenses,
  statisticsAreAlreadySet: state => state.balance && state.incomes && state.expenses && state.incomesAndExpenses,
}

export const mutations: MutationTree<StatisticsState> = {
  SET_EXPENSES (state, expenses: []) {
    state.expenses = expenses
  },
  SET_INCOMES (state, incomes: []) {
    state.incomes = incomes
  },
  SET_INCOMES_AND_EXPENSES (state, incomesAndExpenses: ReadExpensesIncomesByMonth) {
    state.incomesAndExpenses = incomesAndExpenses
  },
  SET_BALANCE (state, balance: []) {
    state.balance = balance
  },
  SET_QUERY_LOADING (state, value: boolean) {
    state.queryLoading = value
  },
  SET_INITIAL_LOADING (state, value: boolean) {
    state.initialLoading = value
  },
  UPDATE_QUERY (state: StatisticsState, query: Partial<StatisticsState>): void {
    state.query = {
      ...state.query,
      ...query,
    }
  },
  RESET (_currentState: StatisticsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  RESET_QUERY (_currentState: StatisticsState) {
    const newQuery = state().query
    _currentState.query = Object.assign(_currentState.query, newQuery)
  },
}

export const actions: ActionTree<StatisticsState, StatisticsState> = {
  async fetchExpensesAndIncomes ({ commit }, timeRange: QueryStatistics) {
    const result = await this.$api.statistics.getExpensesAndIncomes(timeRange)
    commit('SET_INCOMES_AND_EXPENSES', result)
  },
  async fetchExpenses ({ commit }, query: QueryStatistics) {
    const result = await this.$api.statistics.getExpensesByCategory(query)
    commit('SET_EXPENSES', result)
  },
  async fetchIncomes ({ commit }, query: QueryStatistics) {
    const result = await this.$api.statistics.getIncomesByCategory(query)
    commit('SET_INCOMES', result)
  },
  async fetchBalance ({ commit }, query: QueryStatistics) {
    const result = await this.$api.balance.getBalanceByMonth(query)
    commit('SET_BALANCE', result)
  },
  async fetchStatistics ({ dispatch, commit, getters }, query: QueryStatistics) {
    try {
      await dispatch('fetchExpensesAndIncomes', { ...query, ...getters.query })
      await dispatch('fetchExpenses', { ...query, ...getters.query })
      await dispatch('fetchIncomes', { ...query, ...getters.query })
      await dispatch('fetchBalance', { ...query, ...getters.query })
    } finally {
      commit('SET_QUERY_LOADING', false)
      commit('SET_INITIAL_LOADING', false)
    }
  },
  debounceFetchStatistics: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchStatistics')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<AccountingPageQuery>) {
    commit('SET_QUERY_LOADING', true)
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchStatistics')
  },
}
