import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import { customerEmail } from '@abby/core-legacy'
import type {
  BasePaginateMongo,
  CreateCustomer,
  ICustomer,
  ReadCustomer,
  UpdateCustomer,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'
import { CustomerDetailsPanelConfig } from '~/store/customerDetails'

export interface CustomersQuery {
  search?: string,
  professional?: boolean
  forCertificate: boolean
}

export interface CustomersPaginationQuery { page: number, limit: number, sortBy: string[] | null, sortDesc: boolean[] | null }

export interface CustomersState {
  isCustomerCreationOpened: boolean;
  isCustomerEditOpened: boolean;
  isCustomerImportModalOpened: boolean;
  isCustomerTaxReturnDocumentModalOpened: boolean;
  customerToCreate: Partial<ICustomer & { search?: string }> | null
  customerToEdit: ICustomer | null
  pagination: BasePaginateMongo<ICustomer> | null;
  query: CustomersQuery;
  queryLoading: boolean;
  initialLoading: boolean;
  forCertificate?: boolean;
  paginationQuery: CustomersPaginationQuery

}

export const state = (): CustomersState => ({
  isCustomerCreationOpened: false,
  isCustomerEditOpened: false,
  isCustomerTaxReturnDocumentModalOpened: false,
  customerToCreate: null,
  customerToEdit: null,
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
    sortBy: null,
    sortDesc: null,
  },
  query: {
    search: undefined,
    professional: undefined,
    forCertificate: false,
  },
  queryLoading: true,
  initialLoading: true,
  isCustomerImportModalOpened: false,
})

export const getters: GetterTree<CustomersState, RootState> = {
  pagination (state: CustomersState) {
    return state.pagination
  },
  query (state: CustomersState) {
    return state.query
  },
  paginationQuery (state: CustomersState) {
    return state.paginationQuery
  },
  customers (state: CustomersState) {
    return state.pagination?.docs || []
  },
  customerToCreate (state: CustomersState) {
    return state.customerToCreate
  },
  customerToEdit (state: CustomersState) {
    return state.customerToEdit
  },
  isCustomerCreationOpened (state: CustomersState) {
    return state.isCustomerCreationOpened
  },
  isCustomerEditOpened (state: CustomersState) {
    return state.isCustomerEditOpened
  },
  queryLoading (state: CustomersState) {
    return state.queryLoading
  },
  initialLoading (state: CustomersState) {
    return state.initialLoading
  },
  isCustomerImportModalOpened: state => state.isCustomerImportModalOpened,
  isCustomerTaxReturnDocumentModalOpened: state => state.isCustomerTaxReturnDocumentModalOpened,
}

export const mutations = {
  SET_PAGINATION_QUERY (state: CustomersState, paginationQuery: CustomersPaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: CustomersState, pagination: BasePaginateMongo<ICustomer> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: CustomersState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: CustomersState, value: boolean): void {
    state.queryLoading = value
  },
  SET_CUSTOMER_TO_CREATE (state: CustomersState, value: Partial<ICustomer>): void {
    state.customerToCreate = value
  },
  SET_CUSTOMER_TO_EDIT (state: CustomersState, value: ICustomer | null): void {
    state.customerToEdit = value
  },
  UPDATE_QUERY (_state: CustomersState, query: Partial<CustomersQuery>): void {
    _state.query = {
      ..._state.query,
      ...query,
    }
    const newPaginationQuery = state().paginationQuery
    _state.paginationQuery = Object.assign(_state.paginationQuery, newPaginationQuery)
  },
  SET_IS_CUSTOMER_CREATION_MODAL_OPENED (state: CustomersState, value: boolean) {
    state.isCustomerCreationOpened = value
  },
  SET_IS_CUSTOMER_EDIT_MODAL_OPENED (state: CustomersState, value: boolean) {
    state.isCustomerEditOpened = value
  },
  SET_IS_CUSTOMER_IMPORT_MODAL_OPENED (state: CustomersState, value: boolean) {
    state.isCustomerImportModalOpened = value
  },
  SET_IS_CUSTOMER_TAX_REUTRN_DOCUMENT_MODAL_OPENED (state: CustomersState, value: boolean) {
    state.isCustomerTaxReturnDocumentModalOpened = value
  },
  RESET (_currentState: CustomersState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  RESET_QUERY (_currentState: CustomersState) {
    const newQuery = state().query
    const newPaginationQuery = state().paginationQuery
    _currentState.query = Object.assign(_currentState.query, newQuery)
    _currentState.paginationQuery = Object.assign(_currentState.paginationQuery, newPaginationQuery)
  },
}

export const actions: ActionTree<any, any> = {
  async createCustomer ({ dispatch, rootGetters, commit }, body: CreateCustomer) {
    const customer = await this.$api.billing.createCustomer(body)
    if (rootGetters['billingCreation/isBillingCreationModalOpened'] || rootGetters['billingCreation/isBillingEditionModalOpened']) {
      commit('billingCreation/UPDATE_BILLING_DOCUMENT', { customer }, { root: true })
      if (rootGetters['billingCreation/createFromBillingDocument']) {
        commit('billingCreation/UPDATE_CREATE_FROM_BILLING_DOCUMENT', { customer }, { root: true })
      }
    }
    if (rootGetters['opportunity/isOpportunityCreationOpened'] || rootGetters['opportunity/isOpportunityEditOpened']) {
      commit('opportunity/UPDATE_OPPORTUNITY', { customer, customerId: customer._id }, { root: true })
    }
    dispatch('fetchCustomers')
  },
  async deleteCustomer ({ dispatch }, _id: string) {
    await this.$api.billing.deleteCustomer(_id)
    dispatch('fetchCustomers')
  },
  async updateCustomer ({ dispatch, rootGetters, commit }, { id, body }: {id: string, body: UpdateCustomer }) {
    const customer = await this.$api.billing.updateCustomer(id, body)
    if (rootGetters['billingCreation/isBillingCreationModalOpened'] || rootGetters['billingCreation/isBillingEditionModalOpened']) {
      commit('billingCreation/UPDATE_BILLING_DOCUMENT', {
        customer,
        reminder: {
          ...rootGetters['billingCreation/billingDocument'].reminder,
          recipients: [customerEmail(customer) || ''],
        },
      }, { root: true })
      if (rootGetters['billingCreation/createFromBillingDocument']) {
        commit('billingCreation/UPDATE_CREATE_FROM_BILLING_DOCUMENT', { customer }, { root: true })
      }
    }

    const customerDetails = rootGetters['customerDetails/openedCustomerDetails'].map(({ customer: c, config }: { customer: ReadCustomer, config: CustomerDetailsPanelConfig }) => ({
      customer: {
        ...c,
        ...(c._id === customer._id ? customer : {}),
      },
      config,
    }))

    commit('customerDetails/SET_OPENED_CUSTOMERS', customerDetails, { root: true })
    this.$busManager.emit('customerUpdated', customer)
    dispatch('fetchCustomers')
  },
  async fetchCustomers ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.billing.getAllCustomers({
        ...getters.paginationQuery as CustomersPaginationQuery,
        ...getters.query,
        countWithoutFilters: true,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  debounceFetchCustomers: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchCustomers')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<CustomersQuery>) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchCustomers')
  },
  updateQueryWithoutFetch ({ commit }, query: Partial<CustomersQuery>) {
    commit('UPDATE_QUERY', query)
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<CustomersPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchCustomers')
  },
  setCustomerImportModal ({ commit }, value: boolean) {
    commit('SET_IS_CUSTOMER_IMPORT_MODAL_OPENED', value)
  },
  async createCustomers ({ dispatch }, body: CreateCustomer[]) {
    await this.$api.billing.createCustomers(body)
    await dispatch('fetchCustomers')
  },
  setCustomerTaxReturnDocumentModal ({ commit }, value: boolean) {
    commit('SET_IS_CUSTOMER_TAX_REUTRN_DOCUMENT_MODAL_OPENED', value)
  },
  setCustomerToEdit ({ commit }, customer: ICustomer | null) {
    commit('SET_CUSTOMER_TO_EDIT', customer)
  },
}
