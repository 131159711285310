import { CompanyDocumentType } from '@abby/core-legacy'
import type {
  CreateAcreDocument,
  CreateCMATiersAttestationFile,
  CreateCommercialTask,
  CreateCompanyStripeProduct,
  IBankinConfiguration,
  ReadCommercialTask,
  ReadCompany,
  ReadCompanyFile,
  ReadCompanyStripeProduct,
  ReadCreationCompany,
  ReadStripeProduct,
  ReadTrackingCompanyStep,
  ReadUser,
  UpdateCompany,
  UpdateCompanyFile,
  UpdateCompanyFilesToActivate,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Company extends Resource {
  get (id?: string): Promise<ReadCompany> {
    return this.axios.$get(id ? `/company/admin/${id}` : '/company')
  }

  getUserByCompanyId (id: string): Promise<ReadUser> {
    return this.axios.$get(`/company/admin/${id}`)
  }

  getCreation (): Promise<ReadCreationCompany> {
    return this.axios.$get('/company/creation')
  }

  getCommercialTasks (): Promise<ReadCommercialTask> {
    return this.axios.$get('/commercialtask')
  }

  completeCommercialTask (payload: CreateCommercialTask): Promise<ReadCommercialTask> {
    return this.axios.$post('/commercialtask', payload)
  }

  update (id: string, data: UpdateCompany): Promise<ReadCompany> {
    delete data.id
    if ((data as any)?.UserCompany) {
      // eslint-disable-next-line no-param-reassign
      delete (data as any).UserCompany
    }
    if ((data as any)?.old) {
      // eslint-disable-next-line no-param-reassign
      delete (data as any).old
    }
    if ((data as any)?.additionalAllowedFeature) {
      // eslint-disable-next-line no-param-reassign
      delete (data as any).additionalAllowedFeature
    }
    return this.axios.$patch(`/company/${id}`, data)
  }

  updateFile (id: string, data: UpdateCompanyFile): Promise<ReadCompanyFile> {
    return this.axios.$patch(`/company/file/${id}`, data)
  }

  generateFile ({ companyId, documentType }: { companyId: string, documentType: CompanyDocumentType }): Promise<ReadCompanyFile> {
    return this.axios.$post(`/company/${companyId}/generateFile`, { documentType })
  }

  updateFilesIsActivated (userId: string, companyId: string, payload: UpdateCompanyFilesToActivate): Promise<ReadCompanyFile[]> {
    return this.axios.$patch(`/company/files/${userId}/${companyId}`, payload)
  }

  getCompanyFiles (): Promise<ReadCompanyFile[]> {
    return this.axios.$get('/company/files')
  }

  submitAcre (payload: CreateAcreDocument) {
    return this.axios.$post('/acre', payload)
  }

  getCompanyFilesById (id: string): Promise<ReadCompanyFile[]> {
    return this.axios.$get(`/company/${id}/files`)
  }

  getCompanyFilesMissing (): Promise<ReadCompanyFile[]> {
    return this.axios.$get('/company/files/missing')
  }

  generateFiles (): Promise<void> {
    return this.axios.$post('/company/generateFiles')
  }

  switchToGestion (id: string): Promise<boolean> {
    return this.axios.$post(`/company/${id}/switchToGestion`)
  }

  getTrackingCompanyStep (id: string): Promise<ReadTrackingCompanyStep[]> {
    return this.axios.$get(`/company/trackingCompanyStep/${id}`)
  }

  getPlans (): Promise<ReadStripeProduct[]> {
    return this.axios.$get('/company/plans')
  }

  launchCreation (id: string): Promise<boolean> {
    return this.axios.$post(`/company/creation/${id}`)
  }

  getCompanyVerificationFiles (): Promise<{ companyId: string | null, files: ReadCompanyFile[], total: number }> {
    return this.axios.$get('/company/verification')
  }

  changeProductConsumedState (item: any) {
    return this.axios.$patch(`/company/companyStripeProduct/${item?.id}/${!item.consumedAtBool ? 1 : 0}`)
  }

  addCompanyStripeProduct (payload: CreateCompanyStripeProduct): Promise<ReadCompanyStripeProduct> {
    return this.axios.$post('/companyStripeProduct', payload)
  }

  deleteCompanyStripeProduct (id: string) {
    return this.axios.$delete(`/companyStripeProduct/${id}`)
  }

  getCMATiersAttestation (form: CreateCMATiersAttestationFile) {
    return this.axios.get('/company/cmaThirdPartyAttestation', { responseType: 'blob', params: form })
  }
}
