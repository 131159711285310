import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { WebinarType, ReadWebinarSchedule, ReadWebinarEvent } from '@abby/shared'
import { PersonaFeature } from '@abby/core-legacy'
import _ from 'lodash'
import { RootState } from '~/store/index'

export interface WebinarState {
  webinar: ReadWebinarEvent | null;
  queryLoading: boolean,
}

export const state = (): WebinarState => ({
  webinar: null,
  queryLoading: false,
})

export const getters: GetterTree<WebinarState, RootState> = {
  webinar: state => state.webinar,
  queryLoading: state => state.queryLoading,
}

export const mutations: MutationTree<WebinarState> = {
  SET_WEBINAR (state: WebinarState, value: ReadWebinarEvent) {
    state.webinar = value
  },
  SET_QUERY_LOADING (state: WebinarState, value: boolean) {
    state.queryLoading = value
  },
}

export const actions: ActionTree<WebinarState, RootState> = {
  async fetchWebinar ({ commit, rootGetters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const hasTiersPrestation = rootGetters['guide/persona']?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || rootGetters['billingConfiguration/billingConfiguration']?.tiersPrestationActivated
      const result = await this.$backend.webinar.retrieve(hasTiersPrestation
        ? WebinarType.SAP
        : WebinarType.FREELANCE)
      const user = rootGetters['auth/user']
      const _result = _.cloneDeep(result)
      _result.schedules = _.cloneDeep(result.schedules.map((schedule: ReadWebinarSchedule) => {
        const _schedule = { ...schedule }
        _schedule.startAt = new Date(schedule.startAt)
        _schedule.schedulingUrl = schedule.schedulingUrl + `?name=${user.fullName}&email=${user.email}&phone_number=${user.phone}`
        return _schedule
      }))
      commit('SET_WEBINAR', _result)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
}
