import type {
  CreateInvitationSponsorship,
  CreateSponsorship,
  CreateSponsorshipBalance,
  QuerySponsorship,
  ReadSponsorshipBalance,
  ReadSponsorships,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Sponsorship extends Resource {
  create (data: CreateInvitationSponsorship[]): Promise<ReadSponsorships> {
    return this.axios.$post('/sponsorship/create', data)
  }

  delete (id: string): Promise<number> {
    return this.axios.$delete(`/sponsorship/delete/${id}`)
  }

  notify (id: string): Promise<void | Error> {
    return this.axios.$post(`/sponsorship/notify/${id}`)
  }

  invite (emails: CreateInvitationSponsorship[]): Promise<void | Error> {
    return this.axios.$post('/sponsorship/invite', emails)
  }

  getAll (params: QuerySponsorship): Promise<ReadSponsorships> {
    return this.axios.$post('/sponsorship/godSons', params)
  }

  getByGodSonId (id: string): Promise<ReadSponsorships> {
    return this.axios.$get(`/sponsorship/byGodSon/${id}`)
  }

  getTotalsAmounts (): Promise<{ toWin: number, won: number, withdrew: number }> {
    return this.axios.$get('/sponsorship/totalsAmounts')
  }

  update (payload: CreateSponsorship): Promise<boolean> {
    return this.axios.$post('/sponsorship/update', payload)
  }

  createBalance (payload: CreateSponsorshipBalance): Promise<ReadSponsorshipBalance> {
    return this.axios.$post('/sponsorshipBalance/', payload)
  }
}
