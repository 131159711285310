import { BrowserProfilingIntegration, BrowserTracing } from '@sentry/browser'
import { Replay } from '@sentry/vue'

export default function (context) {
  return [
    new BrowserProfilingIntegration(),
    new BrowserTracing(),
    new Replay({
      maskAllText: false,
    }),
  ]
}
