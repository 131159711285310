import { Middleware } from '@nuxt/types'

// const maintenanceStart = '2024-03-21T16:00'
// const maintenanceEnd = '2024-03-22T06:00'

const redirection: Middleware = ({ $config, store, route }) => {
  // const isMaintenanceWindow = $dayjs().isBetween($dayjs(maintenanceStart), $dayjs(maintenanceEnd))
  // const currentlyOnMaintenance = route.path.includes('/maintenance')
  // const skipMaintenance = localStorage.getItem('skipMaintenance')
  // if (!currentlyOnMaintenance && isMaintenanceWindow && $config.nodeEnv === 'production' && !skipMaintenance) {
  //   redirect('/maintenance')
  // }

  // DÉBUT : Migration du domaine avec redirection automatique
  if (window.location.hostname !== 'app.abby.fr' && $config.nodeEnv === 'production') {
    const key = Object.keys(localStorage).find(i => i.includes('firebase:authUser')) || ''
    const token = localStorage.getItem(key || '') || ''
    if (store.getters['auth/connectedFromDashboard']) {
      // Ne rien faire
    } else {
      window.location.replace(`https://app.abby.fr/firebase-connect?redirect_to=${btoa(route.fullPath)}&key=${btoa(key)}&token=${btoa(token)}`)
    }
  }

  if (window.location.pathname.includes('/billing/documents/v2/')) {
    window.location.replace(window.location.pathname.replace('/billing/documents/v2/', '/billing/documents/'))
  }

  // FIN : Migration du domaine avec redirection automatique
}

export default redirection
