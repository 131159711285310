
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import VimeoPlayer from '~/components-legacy/academy/VimeoPlayer.vue'

@Component({
  components: { VimeoPlayer },
})
export default class CallToAction extends Vue {
  @Prop()
  overline!: string

  @Prop()
  title!: string

  @Prop()
  description!: string

  @Prop()
  descriptions?: string

  @Prop()
  image!: { src: string }

  @Prop()
  video!: { src: string }

  @Prop()
  keys!: Array<string>

  @Prop()
  primaryAction!: { title: string }

  @Prop()
  secondaryAction!: { title: string }

  @Prop({ default: '1000px' })
  maxWidth?: string

  @Prop({ default: '500px' })
  imageMaxWidth?: string

  get style () {
    return {
      maxWidth: this.maxWidth,
      width: '100%',
    }
  }

  get imageStyle () {
    return {
      maxWidth: this.imageMaxWidth,
      width: '100%',
    }
  }
}
