export enum AcreDashboardStep {
  TODO = 'todo',
  PENDING = 'pending',
  DOING = 'doing',
  DONE = 'done',
}

export const AcreDashboardStepColor = {
  [AcreDashboardStep.TODO]: 'red lighten-2',
  [AcreDashboardStep.PENDING]: 'orange lighten-1',
  [AcreDashboardStep.DOING]: 'blue lighten-1',
  [AcreDashboardStep.DONE]: 'green lighten-3',
};
