
import { Action, Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { ReadOpportunity } from '@abby/core-legacy'
import CustomerLabel from '~/components-legacy/customerDetails/CustomerLabel.vue'
import OpportunityDetailsStateSelector from '~/components-legacy/opportunity/OpportunityDetailsStateSelector.vue'

@Component({
  components: { OpportunityDetailsStateSelector, CustomerLabel },
})
export default class OpportunityDetailsHeader extends Vue {
  @Prop({ required: true })
  opportunity!: ReadOpportunity

  @Getter('opportunity/openedOpportunityDetails') openedOpportunityDetails!: ReadOpportunity[];
  @Action('opportunity/openOpportunityEdit') openOpportunityEdit!: (payload: ReadOpportunity) => Promise<void>;
  @Action('opportunity/removeOpportunity') removeOpportunity!: (payload: ReadOpportunity) => Promise<void>;
  @Action('opportunity/setOpenedOpportunityDetails') setOpenedOpportunityDetails!: (payload: ReadOpportunity[]) => Promise<void>;

  get customer () {
    return this.opportunity.customer
  }

  get opportunityDate () {
    if (this.opportunity.dueDate) {
      const diff = this.$dayjs(this.opportunity.dueDate).diff(this.$dayjs(), 'day')
      return {
        text: this.$date.formatDDMMMYYYY(this.opportunity.dueDate),
        color: diff < 2 ? diff < 0 ? 'error--text' : 'warning--text' : '',
      }
    }
    return null
  }

  editOpportunity () {
    if (!this.opportunity) { return }
    this.openOpportunityEdit(this.opportunity)
  }

  async deleteOpportunity () {
    try {
      const confirm = await this.$confirmManager.ask({
        icon: 'remove',
        title: this.$xpm.opportunityWordingFor('delete') as string,
        content: `${this.$xpm.opportunityWordingFor('sureToDelete')} : <b>${this.opportunity.name}</b> ?`,
        confirmText: 'Supprimer',
        confirmButton: { depressed: true, color: 'error' },
      })
      if (!confirm) { return }
      await this.removeOpportunity(this.opportunity)
      await this.setOpenedOpportunityDetails(this.openedOpportunityDetails.filter(o => o.id !== this.opportunity.id))
      this.$emit('close')
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }
}

