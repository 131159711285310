import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'

export const useMarkAsRefused = () => {
  const { $confirmManager, i18n } = useContext()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    const confirm = await $confirmManager.ask({
      title: i18n.t('confirmBilling.MARK_AS_REFUSED.title') as string,
      content: i18n.t('confirmBilling.MARK_AS_REFUSED.content') as string,
      confirmText: i18n.t('confirmBilling.MARK_AS_REFUSED.button') as string,
      confirmButton: { color: 'primary', depressed: true },
    })
    if (!confirm) {
      return
    }

    await billingRepository.markAsRefused(billing.id)
  }
}
