export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF',
  USD = 'USD',
  AUD = 'AUD',
  CAD = 'CAD',
  JPY = 'JPY',
  CNH = 'CNH',
  CZK = 'CZK',
  DKK = 'DKK',
  BGN = 'BGN',
  PLN = 'PLN',
  HUF = 'HUF',
  RON = 'RON',
  SEK = 'SEK',
  NOK = 'NOK',
  TRY = 'TRY',
  BRL = 'BRL',
  HKD = 'HKD',
  ILS = 'ILS',
  INR = 'INR',
  KRW = 'KRW',
  MXN = 'MXN',

}

export enum CurrencySymbol {
  EUR = '€',
  USD = '$',
  GBP = '£',
  CHF = 'CHF',
  AUD = 'A$',
  CAD = 'C$',
  JPY = '¥',
  CNH = 'CN¥',
  CZK = 'Kč',
  DKK = 'kr',
  BGN = 'лв',
  PLN = 'zł',
  HUF = 'Ft',
  RON = 'lei',
  SEK = 'kr',
  NOK = 'kr',
  TRY = '₺',
  BRL = 'R$',
  HKD = 'HK$',
  ILS = '₪',
  INR = '₹',
  KRW = '₩',
  MXN = 'Mex$',
}
