import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { PersonaFeature, RegisteredType, SegmentEvent } from '@abby/core-legacy'
import dayjs from 'dayjs'

export interface CommunityEvent {
  title?: string,
  description?: string,
  date?: string,
  previewImage?: string,
  image?: string,
  url: string,
  type: RegisteredType[] | RegisteredType
  banner?: boolean,
  menu?: boolean,
  continuous?: boolean,
  bannerTitle?: string,
  newUser: boolean
}

export enum Loading {
  NUXT_CLIENT_LOADING= 'nuxtClientLoading',
  DEFAULT_LOADING= 'defaultLoading',
  LOGOUT_LOADING= 'logoutLoading',
  AUTH_LOADING= 'authLoading',
}

export interface AppState {
  loading: {
    nuxtClientLoading: boolean,
    defaultLoading: boolean,
    logoutLoading: boolean,
    authLoading: boolean,
  },
  isUpdateAvailable: boolean
  baseUrl: string
  apiHost: string
  strapiApiHost: string
  isContainerFullHeight: boolean
  parentPageHeaderHeight: number
  initTimestamp: number
  frillIsOpen: boolean,
  discordCommunityIsOpen: boolean,
  communityEventInBanner: CommunityEvent | null
  communityEventInModal: CommunityEvent | null,
  communityEventInNotification: CommunityEvent | null,
  mobileMenuOpened: boolean
}

export const state = (): AppState => ({
  loading: {
    nuxtClientLoading: false,
    defaultLoading: false,
    logoutLoading: false,
    authLoading: false,
  },
  baseUrl: 'http://localhost:3000',
  apiHost: 'http://localhost:3030',
  strapiApiHost: 'http://localhost:1337/api',
  isContainerFullHeight: false,
  parentPageHeaderHeight: 0,
  initTimestamp: 0,
  frillIsOpen: false,
  discordCommunityIsOpen: false,
  communityEventInBanner: null,
  communityEventInModal: null,
  communityEventInNotification: null,
  isUpdateAvailable: false,
  mobileMenuOpened: false,
})

export const getters: GetterTree<AppState, AppState> = {
  isUpdateAvailable: state => state.isUpdateAvailable,
  loading: state => state.loading,
  appLoading: state => (state.loading.nuxtClientLoading || state.loading.defaultLoading || state.loading.logoutLoading),
  mobileMenuOpened: state => state.mobileMenuOpened,
  initTimestamp: state => state.initTimestamp,
  baseUrl: state => state.baseUrl,
  apiHost: state => state.apiHost,
  strapiApiHost: state => state.strapiApiHost,
  isContainerFullHeight: state => state.isContainerFullHeight,
  frillIsOpen: state => state.frillIsOpen,
  discordCommunityIsOpen: state => state.discordCommunityIsOpen,
  communityEventInBanner: state => state.communityEventInBanner,
  communityEventInModal: state => state.communityEventInModal,
  communityEventInNotification: state => state.communityEventInNotification,
  communityEvents: (_, __, ___, rootGetters): CommunityEvent[] => {
    const query = {
      email: rootGetters['auth/user']?.email,
      phone_number: rootGetters['auth/user']?.phone,
      name: rootGetters['auth/user']?.fullName,
    }

    const serialize = (obj: any) => {
      const str = Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
      return str
    }

    const _query = serialize(query)

    let calendlyDemoGestionLink
    if (rootGetters['guide/persona']?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API)) {
      calendlyDemoGestionLink = `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs-sap?${_query ? _query + '&' : ''}`
    } else {
      calendlyDemoGestionLink = `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs?${_query ? _query + '&' : ''}`
    }

    return [{
      title: 'Activité Skribbl : Dessinez et deviner pour marquer des points',
      description: 'Participez avec nous à la soirée Skribbl, un jeu gratuit en ligne de dessin et devinette. Venez nous montrer de quoi vous êtes capable. Récompenses pour les meilleurs d\'entre vous 🎁',
      date: '2022-10-06 19:00',
      previewImage: '/events/event-1.png',
      image: '/events/event-1.png',
      url: 'https://discord.gg/Gsb4mCzNUS',
      type: RegisteredType.GESTION,
      newUser: false,
    }, {
      title: 'Grande réunion : Keynote, la suite avec Abby',
      description: 'Votre avis compte beaucoup et on aimerait vous partager les nouveautés prévues pour la suite avec Abby. Ce sera une réunion où chacun peut s\'exprimer si il le souhaite. Évidemment on vous réserve pleins de surprises 🚀',
      date: '2022-10-12 19:00',
      previewImage: '/events/event-2.png',
      image: '/events/event-2.png',
      url: `https://calendly.com/corentin-de-abby/grande-reunion-keynote-la-suite-avec-abby?${_query ? _query + '&' : ''}month=2022-10&date=2022-10-12`,
      banner: true,
      menu: true,
      bannerTitle: '<b>Grande réunion</b>, la suite avec Abby : Participez aux échanges le&nbsp;<b>12 Octobre 🕖 19h00</b>',
      type: RegisteredType.GESTION,
      newUser: false,
    }, {
      title: 'Comment mêler investissement et micro-entreprise ?',
      description: 'Axel Paris vient pour nous parler d\'investissement. Comment faire ? Par ou débuter ? 💸',
      date: '2022-11-28 19:00',
      previewImage: '/events/event-7.png',
      image: '/events/event-7.png',
      url: `https://calendly.com/corentin-de-abby/meler-investissement-et-micro/2022-11-28T19:00:00+01:00?${_query ? _query + '&' : ''}month=2022-11&date=2022-11-28`,
      banner: true,
      menu: true,
      bannerTitle: '<b>Axel Paris</b> : Comment mêler investissement et micro-entreprise ? Le&nbsp;<b>28 Novembre 🕖 19h00</b>',
      type: RegisteredType.GESTION,
      newUser: false,
    }, {
      title: 'FAQ : On répond à toutes vos questions',
      description: 'On a déjà reçu des dizaines de questions à propos d\'Abby. Venez découvrir les réponses et poser de nouvelles questions lors de ce moment d\'échange 💙',
      date: '2022-10-18 19:00',
      previewImage: '/events/event-3.png',
      image: '/events/event-3.png',
      url: `https://calendly.com/corentin-de-abby/faq?${_query ? _query + '&' : ''}month=2022-10&date=2022-10-18`,
      menu: true,
      type: RegisteredType.GESTION,
      newUser: false,
    }, {
      title: 'Application mobile : On design ensemble',
      description: 'Elle est tant attendue et comme d\'habitude on veut créer quelque chose qui vous ressemble. Alors avec Romain, on fait appel à vous pour nous aider à créer la toute première version 🎉',
      date: '2022-10-24 19:00',
      previewImage: '/events/event-4.png',
      image: '/events/event-4.png',
      url: `https://calendly.com/corentin-de-abby/app-mobile?${_query ? _query + '&' : ''}month=2022-10&date=2022-10-24`,
      type: RegisteredType.GESTION,
      menu: true,
      newUser: false,
    }, {
      title: 'Prévision de trésorerie : Comment faire ?',
      description: 'Nicolas vous montrera ses techniques, astuces et outils pour vous aider à faire des prévisions de trésorerie et d\'investissement aussi fiable que possible 🏆',
      date: '2022-11-08 19:00',
      previewImage: '/events/event-5.png',
      image: '/events/event-5.png',
      url: `https://calendly.com/corentin-de-abby/prevision-tresorerie?${_query ? _query + '&' : ''}month=2022-11&date=2022-11-08`,
      type: RegisteredType.GESTION,
      menu: true,
      newUser: false,
    }, {
      title: 'Comment être pro et visible sur Google My Business & Facebook ?',
      description: 'Comment augmenter la notoriété de sa marque ? On vous donne toutes nos bottes secrètes pour y arriver ! 🏆',
      date: '2022-12-07 19:00',
      previewImage: '/events/event-8.png',
      image: '/events/event-8.png',
      url: `https://calendly.com/corentin-de-abby/etre-pro-et-visible/2022-12-07T19:00:00+01:00?${_query ? _query + '&' : ''}month=2022-12&date=2022-12-07`,
      type: RegisteredType.GESTION,
      menu: true,
      newUser: false,
    }, {
      title: 'Découvrir le Plan d\'Épargne Retraite (PER) avec Caravel',
      description: 'J\'ouvre mon PER chez Caravel. Je m\'assure moi-même de me constituer un complément de revenu pour ma retraite et ça commence maintenant. En compagnie d\'Olivier Rull, CEO de Caravel, j\'ouvre mon PER avec vous',
      date: '2022-12-013 19:00',
      previewImage: '/events/event-9.png',
      image: '/events/event-9.png',
      url: `https://calendly.com/corentin-de-abby/decouvrir-le-per/2022-12-13T19:00:00+01:00?${_query ? _query + '&' : ''}`,
      type: RegisteredType.GESTION,
      menu: true,
      newUser: false,
    }, {
      title: 'Le CE des indeps : économisez entre 50 et 100€ par mois',
      description: "Tu as maintenant accès à des milliers d’offres chez des dizaines de marchands qui vont te permettre de gagner entre 50 et 100 € par mois. On va te montrer comment tu vas pouvoir économiser de l'argent grâce au CE des indeps.",
      date: '2023-02-21 19:00',
      previewImage: '/events/event-10.png',
      image: '/events/event-10.png',
      url: `https://calendly.com/corentin-de-abby/ce-des-indeps/2023-02-21T19:00:00+01:00?${_query ? _query + '&' : ''}`,
      banner: true,
      menu: true,
      bannerTitle: '<b>Le CE des indeps</b> : économisez entre 50 et 100€ par mois le&nbsp;<b>21 Février 🕖 19h00</b>',
      type: RegisteredType.GESTION,
      newUser: false,
    }, {
      title: 'Session Gartic Phone ! On teste nos artistes chez Abby',
      description: 'Envie de jouer un peu avec la team Abby pour décompresser et t\'amuser ? 😁 Viens donc jouer avec nous à Gartic Phone, un jeu qui se veut le mélange entre un Pictionnary et un téléphone arabe 😋 Fous rires garantis !',
      date: '2022-11-21 19:00',
      previewImage: '/events/event-6.png',
      image: '/events/event-6.png',
      url: 'https://discord.gg/Gsb4mCzNUS',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().add(4, 'hour').isAfter(dayjs('2022-11-21 19:00')),
    },
    {
      title: 'Afterwork : Session Gartic Phone',
      description: 'Il faut parfois se détendre tous ensemble ! Samuel de la team Abby vous propose de passer un moment convivial sur le jeu Gartic Phone : montrez-nous votre âme d\'artiste sur ce petit jeu de dessin ! 😁',
      date: '2023-03-23 18:30',
      previewImage: '/events/event-11.png',
      image: '/events/event-11.png',
      url: 'https://discord.gg/Gsb4mCzNUS',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-03-23 18:30')),
    },
    {
      title: 'L\'importance de faire le tri sur ses réseaux',
      description: 'Amandine, la bosse de la commu Abby vous briefera sur l’importance de faire du tri sur ses réseaux. 😊',
      date: '2023-03-29 18:00',
      previewImage: '/events/event-13.png',
      image: '/events/event-13.png',
      url: 'https://discord.gg/Gsb4mCzNUS',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-03-29 18:00')),
    },
    {
      title: 'Comment se faire faire payer plus rapidement ?',
      description: 'Ras-le-bol de devoir courir après vos clients ? Corentin va vous montrer toutes les techniques pour vous faire payer plus rapidement et contractualiser plus simplement avec vos clients 💶',
      date: '2023-04-06 19:00',
      previewImage: '/events/event-12.png',
      image: '/events/event-12.png',
      url: `https://calendly.com/corentin-de-abby/comment-se-faire-payer-rapidement/2023-04-06T19:00:00+02:00?${_query ? _query + '&' : ''}`,
      banner: dayjs().isAfter(dayjs('2023-04-01 8:00')),
      bannerTitle: '<b>Comment se faire faire payer plus rapidement ?</b> le&nbsp;<b>6 Avril 🕖 19h00</b>',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-04-06 19:00')),
    },
    {
      title: 'On appuie sur pause pour attaquer Mai en forme !',
      description: 'Jouons et discutons un peu ensemble avec Amandine, la boss de la commu\' Abby 🫶',
      date: '2023-04-27 18:00',
      previewImage: '/events/event-16.png',
      image: '/events/event-16.png',
      url: 'https://discord.gg/Gsb4mCzNUS',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-04-27 19:00')),
    },
    {
      title: 'Indépendants : Comment se préparer aux risques ?',
      description: 'Avec Dorian, Co-fondateur d\'Olino et Corentin, Co-fondateur d\'Abby, on parlera des risques en tant qu\'indépendants.',
      date: '2023-05-10 11:30',
      previewImage: '/events/event-15.png',
      image: '/events/event-15.png',
      url: `https://calendly.com/corentin-de-abby/se-preparer-aux-risques/2023-05-10T11:30:00?${_query ? _query + '&' : ''}`,
      banner: dayjs().isAfter(dayjs('2023-05-03 08:00')),
      bannerTitle: '<b>Indépendants : Comment se préparer aux risques ?</b> le&nbsp;<b>10 Mai 🕖 11h30</b>',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-05-10 11:30')),
    },
    {
      title: 'L\'importance de s\'entourer pour travailler quand on est indépendant',
      description: 'Samuel de la team Abby vous explique comment et quels sont les bénéfices de s’entourer d’autres indépendants dans votre activité. 💁‍',
      date: '2023-04-13 18:00',
      previewImage: '/events/event-14.png',
      image: '/events/event-14.png',
      url: 'https://discord.gg/Gsb4mCzNUS',
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-04-13 19:00')),
    },

    {
      title: 'Discussion & formation à la nouvelle interface (programme beta)',
      description: 'Échangez avec nous autour de la mise à jour de l’interface. Toute l’équipe produit a besoin de votre ressenti et de vos suggestions pour avancer et proposer cette nouvelle version à tous les utilisateurs d’Abby.',
      date: '2023-07-18 18:00',
      previewImage: '/events/event-17.png',
      image: '/events/event-17.png',
      url: `https://calendly.com/corentin-de-abby/formation-nouvelle-interface/2023-07-18T18:00:00?${_query ? _query + '&' : ''}`,
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-07-18 19:00')),
    },
    {
      title: 'Discussion & formation à la nouvelle interface (programme beta)',
      description: 'Échangez avec nous autour de la mise à jour de l’interface. Toute l’équipe produit a besoin de votre ressenti et de vos suggestions pour avancer et proposer cette nouvelle version à tous les utilisateurs d’Abby.',
      date: '2023-07-24 18:00',
      previewImage: '/events/event-18.png',
      image: '/events/event-18.png',
      url: `https://calendly.com/corentin-de-abby/formation-nouvelle-interface/2023-07-24T18:00:00?${_query ? _query + '&' : ''}`,
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-07-24 19:00')),
    },
    {
      title: 'Discussion & formation à la nouvelle interface (programme beta)',
      description: 'Échangez avec nous autour de la mise à jour de l’interface. Toute l’équipe produit a besoin de votre ressenti et de vos suggestions pour avancer et proposer cette nouvelle version à tous les utilisateurs d’Abby.',
      date: '2023-07-31 12:00',
      previewImage: '/events/event-19.png',
      image: '/events/event-19.png',
      url: `https://calendly.com/corentin-de-abby/formation-nouvelle-interface/2023-07-31T12:00:00+02:00?${_query ? _query + '&' : ''}`,
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2023-07-31 13:00')),
    },
    {
      url: `https://calendly.com/abby-customer-success/creez-votre-entreprise-avec-abby?${_query ? _query + '&' : ''}`,
      continuous: true,
      type: RegisteredType.CREATION,
      newUser: true,
      banner: true,
      bannerTitle: 'Créez votre entreprise avec Abby. Présentation en direct par un conseiller',
    }, {
      url: calendlyDemoGestionLink,
      continuous: true,
      type: RegisteredType.GESTION,
      newUser: true,
      banner: true,
      bannerTitle: 'Tout savoir sur Abby pour démarrer. Présentation en direct par un conseiller',
    },
    {
      title: 'Trouver des clients plus facilement',
      description: 'Des techniques pour cibler sa clientèle, maitriser son pitch et passer à l\'action via une stratégie de prospection efficace.',
      date: '\'2024-05-27 19:00',
      previewImage: '/events/event-20.png',
      image: '/events/event-20.png',
      url: `https://calendly.com/corentin-de-abby/ateliers-trouver-des-clients?${_query ? _query + '&' : ''}`,
      type: RegisteredType.GESTION,
      newUser: false,
      menu: !dayjs().isAfter(dayjs('2024-05-27 19:00')),
    },

    ].filter(e => !e.date || dayjs(e.date).isSameOrAfter(dayjs().add(1, 'h')))
  },
}

export const mutations: MutationTree<AppState> = {
  SET_MOBILE_MENU_OPENED: (state, value: boolean) => {
    state.mobileMenuOpened = value
  },
  SET_NUXT_CLIENT_LOADING: (state, value: boolean) => {
    state.loading.nuxtClientLoading = value
  },
  SET_DEFAULT_LOADING: (state, value: boolean) => {
    state.loading.defaultLoading = value
  },
  SET_LOGOUT_LOADING: (state, value: boolean) => {
    state.loading.logoutLoading = value
  },
  SET_AUTH_LOADING: (state, value: boolean) => {
    state.loading.authLoading = value
  },
  SET_BASE_URL: (state, baseUrl: string) => {
    state.baseUrl = baseUrl
  },
  SET_API_HOST: (state, baseUrl: string) => {
    state.apiHost = baseUrl
  },
  SET_STRAPI_API_HOST: (state, strapiApiHost: string) => {
    state.strapiApiHost = strapiApiHost
  },
  SET_IS_CONTAINER_FULL_HEIGHT: (state, value: boolean) => {
    state.isContainerFullHeight = value
  },
  SET_PARENT_PAGE_HEADER_HEIGHT: (state, value: number) => {
    state.parentPageHeaderHeight = value
  },
  SET_INIT_TIMESTAMP: (state, value: number) => {
    state.initTimestamp = value
  },
  SET_FRILL_IS_OPEN (state, value: boolean) {
    state.frillIsOpen = value
  },
  SET_COMMUNITY_EVENT_IN_MODAL (state, value: CommunityEvent) {
    state.communityEventInModal = value
  },
  SET_COMMUNITY_EVENT_IN_NOTIFICATION (state, value: CommunityEvent) {
    state.communityEventInNotification = value
  },
  SET_COMMUNITY_EVENT_IN_BANNER (state, value: CommunityEvent) {
    state.communityEventInBanner = value
  },
  SET_DISCORD_COMMUNITY_IS_OPEN (state, value: boolean) {
    state.discordCommunityIsOpen = value
  },
  SET_UPDATE_IS_AVAILABLE (state, value: boolean) {
    state.isUpdateAvailable = value
  },
  RESET (_currentState: AppState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<AppState, AppState> = {
  setAppLoading: ({ commit }, params: { key: Loading, value: boolean}) => {
    if (params.key === Loading.NUXT_CLIENT_LOADING) {
      commit('SET_NUXT_CLIENT_LOADING', params.value)
    } else if (params.key === Loading.DEFAULT_LOADING) {
      commit('SET_DEFAULT_LOADING', params.value)
    } else if (params.key === Loading.LOGOUT_LOADING) {
      commit('SET_LOGOUT_LOADING', params.value)
    } else if (params.key === Loading.AUTH_LOADING) {
      commit('SET_AUTH_LOADING', params.value)
    }
  },
  setUpdateIsAvailable: ({ commit }, value: boolean) => {
    commit('SET_UPDATE_IS_AVAILABLE', value)
  },
  openFrill ({ commit }) {
    this.$ap.sendSegmentTrack({
      event: SegmentEvent.ACTION_KEY,
      data: {
        eventType: SegmentEvent.FRILL_OPENED,
      },
    })
    commit('SET_FRILL_IS_OPEN', true)
  },
  closeFrill ({ commit }) {
    commit('SET_FRILL_IS_OPEN', false)
  },
  openDiscordCommunity ({ commit }) {
    commit('SET_DISCORD_COMMUNITY_IS_OPEN', true)
  },
  closeDiscordCommunity ({ commit }) {
    commit('SET_DISCORD_COMMUNITY_IS_OPEN', false)
  },
  openCommunityEventInModal ({ commit }, value: CommunityEvent) {
    commit('SET_COMMUNITY_EVENT_IN_MODAL', value)
  },
  closeCommunityEventInModal ({ commit }) {
    commit('SET_COMMUNITY_EVENT_IN_MODAL', null)
  },
  openCommunityEventInNotification ({ commit }, value: CommunityEvent) {
    commit('SET_COMMUNITY_EVENT_IN_NOTIFICATION', value)
  },
  closeCommunityEventInNotification ({ commit }) {
    commit('SET_COMMUNITY_EVENT_IN_NOTIFICATION', null)
  },
  openCommunityEventInBanner ({ commit }, value: CommunityEvent) {
    commit('SET_COMMUNITY_EVENT_IN_BANNER', value)
  },
  closeCommunityEventInBanner ({ commit }) {
    commit('SET_COMMUNITY_EVENT_IN_BANNER', null)
  },
  openMobileMenu ({ commit }) {
    commit('SET_MOBILE_MENU_OPENED', true)
  },
  closeMobileMenu ({ commit }) {
    commit('SET_MOBILE_MENU_OPENED', false)
  },
}
