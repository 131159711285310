import { CreateContact } from '@abby/shared'
import { PlanFeature } from '@abby/core-legacy'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useBusManager } from '~/composables/shared/manager/useBusManager'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'
import { useToasterManager } from '~/composables/shared/manager/useToasterManager'

export const useCreateContact = () => {
  const contactRepository = useContactRepository()
  const toasterManager = useToasterManager()
  const busManager = useBusManager()

  const planManager = usePlanManager()

  const createContact = async (contact: CreateContact) => {
    const newContact = await contactRepository.createContact(contact)
    toasterManager.success({ message: 'Le contact a bien été créé' })
    busManager.emit('contactCreated', newContact)
    return newContact
  }

  const handleError = (error: any) => {
    if (error?.response?.data?.message === 'client.max_contacts_reached') {
      planManager.openPlansModal(PlanFeature.BILLING_CONTACT_ORGANIZATION_CREATION)
    }
  }

  return async (contact: CreateContact) => {
    try {
      return await createContact(contact)
    } catch (error) {
      handleError(error)
    }
  }
}
