import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useBillingResolveNumber = () => {
  const billingRepository = useBillingRepository()
  const alertManager = useAlertManager()

  return async (billing: BillingItem) => {
    await billingRepository.regenerateNumber(billing)
    await billingRepository.regenerateBilling(billing)
    alertManager.success('Le numéro de facture a bien été régénéré.')
  }
}
