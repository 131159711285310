import { Resource } from '../Resource'

export class Sirene extends Resource {
  getBySiren (siren: string): Promise<any> {
    return this.axios.$get(`/sirene/siren/${siren}`)
  }

  getBySiret (siret: string): Promise<any> {
    return this.axios.$get(`/sirene/siret/${siret}`)
  }
}
