
import { Action, Component, Getter, Vue } from 'nuxt-property-decorator'
import { PlanFeature, PlansConfig } from '@abby/core-legacy'

@Component
export default class TimeTrackingTimer extends Vue {
  @Getter('timeTracking/timeInSeconds')
  timeInSeconds!: number | null

  @Getter('timeTracking/timerIsRunning')
  timerIsRunning!: boolean

  @Action('timeTracking/startTimer')
  startTimer!: () => void

  start () {
    if (this.hasAccessToTimeTracking) {
      this.startTimer()
    } else {
      this.$planManager.openAbbyPlansModal({
        feature: PlanFeature.PRODUCTIVITY_TIME_TRACKING,
      })
    }
  }

  @Action('timeTracking/stopTimer')
  stopTimer!: () => void

  get limitNumber () {
    const plan = this.$planManager.whichPlanCompanyHas()
    return PlansConfig[plan].timeTracking.max
  }

  get hasAccessToTimeTracking () {
    return this.$planManager.hasAccessTo(PlanFeature.PRODUCTIVITY_TIME_TRACKING)
  }

  get timerText () {
    return this.$dayjs.duration(this.timeInSeconds || 0, 'seconds').format('HH:mm:ss')
  }

  get nbOfDays () {
    return +this.$dayjs.duration(this.timeInSeconds || 0, 'seconds').format('DD')
  }

  mounted () {
    if (!this.$planManager.hasAccessTo(PlanFeature.PRODUCTIVITY_TIME_TRACKING) && this.timerIsRunning) {
      this.stopTimer()
    }
  }
}

