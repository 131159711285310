import { IEmailCustomisationVariable } from '../Interface/models/IEmailCustomisationVariable';
import { BillingTypeText } from './BillingConfiguration';

export const EmailCustomisationVariable: Array<IEmailCustomisationVariable> = [

  /**
   * BILLING
   */

  {
    tag: '{{ prenom }}',
    type: BillingTypeText.BILLING,
    description: 'Mon prénom',
  },
  {
    tag: '{{ nom }}',
    type: BillingTypeText.BILLING,
    description: 'Mon nom de famille',
  },
  {
    tag: '{{ prenom client }}',
    type: BillingTypeText.BILLING,
    description: 'Prénom du client',
  },
  {
    tag: '{{ nom client }}',
    type: BillingTypeText.BILLING,
    description: 'Nom de famille du client',
  },
  {
    tag: '{{ numero du document }}',
    type: BillingTypeText.BILLING,
    description: 'Numéro du document',
  },
  {
    tag: '{{ montant HT }}',
    type: BillingTypeText.BILLING,
    description: 'Montant total HT',
  },
  {
    tag: '{{ montant TTC }}',
    type: BillingTypeText.BILLING,
    description: 'Montant total TTC',
  },
  {
    tag: '{{ date emission }}',
    type: BillingTypeText.BILLING,
    description: 'Date d\'émission',
  },
  {
    tag: '{{ date echeance }}',
    type: BillingTypeText.BILLING,
    description: 'Date d\'échéance de paiement',
  },
  {
    tag: '{{ type de document }}',
    type: BillingTypeText.BILLING,
    description: 'Type de document',
  },
  {
    tag: '{{ page de paiement }}',
    type: BillingTypeText.INVOICE,
    description: 'Lien vers la page de paiement',
  },
  {
    tag: '{{ page de paiement }}',
    type: BillingTypeText.ADVANCE,
    description: 'Lien vers la page de paiement',
  },
  {
    tag: '{{ page de signature du devis }}',
    type: BillingTypeText.ESTIMATE,
    description: 'Lien vers la page de signature du devis',
  },

];
