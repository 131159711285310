
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { useSidepanelState } from '~/composables/useSidepanel'

@Component({
  name: 'as-dialog',
  setup () {
    const { highestZIndex } = useSidepanelState()
    return {
      highestZIndex,
    }
  },
})
export default class ASDialog extends Vue {
  highestZIndex!: number

  style: { zIndex?: number } = {}

  @Prop()
  classes!: string

  @Prop({ default: '0.75' })
  overlayOpacity!: string

  @Watch('$attrs.value')
  valueHandler (value: boolean) {
    if (value) {
      const overlay: any = document.querySelector('.overlay--active')
      if (overlay) {
        const zIndex = (elm: any) => +(window.getComputedStyle(elm, null).getPropertyValue('z-index')) || 300
        this.style.zIndex = zIndex(overlay) + 1
        this.highestZIndex = this.style.zIndex
      }
    } else {
      const array = [...document.querySelectorAll<HTMLElement>('.v-dialog__content--active')].map(el => Number(el.style.zIndex))
      const content = array.reduce((max, value) => max + value, 0)
    }
  }
}
