
import Vue from 'vue'
import { Component, ModelSync, Prop, Watch } from 'nuxt-property-decorator'
import ASDatePicker from '~/components-legacy/inputs/ASDatePicker.vue'

@Component({
  components: {
    ASDatePicker,
  },
})
export default class BillingValidityForm extends Vue {
  @ModelSync('value', 'input', { default: null })
  validityDate!: string | null

  @Prop({ default: '' })
  date!: Date

  get validityDatePresetItems () {
    return [
      {
        text: '7 jours',
        date: this.$dayjs().add(7, 'day').format('YYYY-MM-DD'),
      }, {
        text: '14 jours',
        date: this.$dayjs().add(14, 'day').format('YYYY-MM-DD'),
      }, {
        text: '30 jours',
        date: this.$dayjs().add(30, 'day').format('YYYY-MM-DD'),
      }, {
        text: '45 jours',
        date: this.$dayjs().add(45, 'day').format('YYYY-MM-DD'),
      }, {
        text: '2 mois',
        date: this.$dayjs().add(2, 'month').format('YYYY-MM-DD'),
      }, {
        text: '3 mois',
        date: this.$dayjs().add(3, 'month').format('YYYY-MM-DD'),
      },
    ]
  }

  mounted () {
    if (!this.validityDate) {
      this.validityDate = this.validityDatePresetItems[2].date
    }
  }

  @Watch('date')
  changeDate () {
    // @ts-ignore
    this.$refs.validityDate?.validator.validate()
  }

  get validityDateToDisplay () {
    return this.validityDate ? `Valide jusqu'au ${this.$date.formatddddDMMMMYYYY(this.validityDate)}` : ''
  }

  updateValidityDate (value: string) {
    this.$emit('input', value)
  }
}
