import type { CreateCredentials } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class UrssafTp extends Resource {
  setCredentials (body: CreateCredentials): Promise<{
    tiersPrestationValidated: boolean,
  }> {
    return this.axios.$post('/urssaftp/credentials', body)
  }

  checkUrssafTpBillingStatut (id: string): Promise<any> {
    return this.axios.$get(`/urssaftp/checkBillingStatus/${id}`)
  }

  checkConnection (): Promise<Promise<{
    tiersPrestationValidated: boolean,
  }>> {
    return this.axios.$get('/urssaftp/connection')
  }
}
