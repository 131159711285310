// Kept the name in French as it would take a lot of time to translate
// and would be useless
export enum StreetType {
  ALLEE = 1,
  AVENUE = 2,
  BOULEVARD = 3,
  CARREFOUR = 4,
  CITE = 5,
  CORNICHE = 6,
  COURS = 7,
  ECART = 8,
  GRANDE_RUE = 9,
  HAMEAU = 10,
  IMPASSE = 11,
  JETEE = 12,
  MONTEE = 13,
  PASSAGE = 14,
  PLACE = 15,
  PROMENADE = 16,
  QUAI = 17,
  RUEE = 18,
  SQUARE = 19,
  TERRE_PLEIN = 20,
  TRAVERSE = 21,
  TERTRE = 22,
  VILLA = 23,
  RUE = 24,
}