import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'

export const useMarkAsNotRefused = () => {
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    await billingRepository.markAsNotRefused(billing.id)
  }
}
