import { QueryIntegration } from '@abby/core-legacy'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { IIntegrationApp, IIntegrationUseCase, StrapiPaginated } from '@abby/core-legacy'

export interface IntegrationState {
  defaultUseCases: IIntegrationUseCase[]
  suggestedUseCases: IIntegrationUseCase[]
  paginatedUseCases: StrapiPaginated<IIntegrationUseCase> | null
  paginatedApps: StrapiPaginated<IIntegrationApp> | null
  apps: IIntegrationApp[]
  useCase: IIntegrationUseCase | null
  app: IIntegrationApp | null
  modals: {
    useCaseArticle: boolean
    appArticle: boolean
  },
  panels: {
    suggestedUseCases: boolean
  }
}

export type IntegrationModals = Extract<keyof IntegrationState['modals'], string>
export type IntegrationPanels = Extract<keyof IntegrationState['panels'], string>

export const state = (): IntegrationState => ({
  defaultUseCases: [],
  suggestedUseCases: [],
  paginatedUseCases: null,
  paginatedApps: null,
  apps: [],
  useCase: null,
  app: null,
  modals: {
    useCaseArticle: false,
    appArticle: false,
  },
  panels: {
    suggestedUseCases: false,
  },
})

export const getters: GetterTree<IntegrationState, IntegrationState> = {
  defaultUseCases: state => state.defaultUseCases,
  suggestedUseCases: state => state.suggestedUseCases,
  paginatedUseCases: state => state.paginatedUseCases,
  paginatedApps: state => state.paginatedApps,
  apps: state => state.apps,
  app: state => state.app,
  useCase: state => state.useCase,
  modals: state => state.modals,
  panels: state => state.panels,
}

export const mutations: MutationTree<IntegrationState> = {
  SET_DEFAULT_USE_CASES (state, value) {
    state.defaultUseCases = value
  },
  SET_SUGGESTED_USE_CASES (state, value) {
    state.suggestedUseCases = value
  },
  SET_PAGINATED_USE_CASES (state, value) {
    state.paginatedUseCases = value
  },
  SET_PAGINATED_APPS (state, value) {
    state.paginatedApps = value
  },
  SET_APPS (state, value) {
    state.apps = value
  },
  SET_APP (state, value) {
    state.app = value
  },
  SET_USE_CASE (state, value) {
    state.useCase = value
  },
  OPEN_MODAL (state, key: IntegrationModals) {
    state.modals[key] = true
  },
  CLOSE_MODAL (state, key: IntegrationModals) {
    state.modals[key] = false
  },
  OPEN_PANEL (state, key: IntegrationPanels) {
    state.panels[key] = true
  },
  CLOSE_PANEL (state, key: IntegrationPanels) {
    state.panels[key] = false
  },
}

export const actions: ActionTree<IntegrationState, IntegrationState> = {
  async fetchDefaultUseCases ({ commit }) {
    const { items } = await this.$api.integration.getUseCases({ limit: 6 })
    commit('SET_DEFAULT_USE_CASES', items)
  },
  async fetchSuggestedUseCases ({ commit }) {
    const { items } = await this.$api.integration.getUseCases({ limit: 10 })
    commit('SET_SUGGESTED_USE_CASES', items)
  },
  async fetchUseCasesPaginated ({ commit }, query: QueryIntegration) {
    const result = await this.$api.integration.getUseCases(query)
    commit('SET_PAGINATED_USE_CASES', result)
  },
  async fetchAppsPaginated ({ commit }, query: QueryIntegration) {
    const result = await this.$api.integration.getApps(query)
    commit('SET_PAGINATED_APPS', result)
  },
  resetUseCasesPaginated ({ commit }) {
    commit('SET_PAGINATED_USE_CASES', null)
  },
  resetAppsPaginated ({ commit }) {
    commit('SET_PAGINATED_APPS', null)
  },
  async fetchApps ({ commit }) {
    const { items } = await this.$api.integration.getApps()
    commit('SET_APPS', items)
  },
  async openModalAppArticleModal ({ commit }, { appId, useCaseId }: { appId: number, useCaseId: number }) {
    commit('OPEN_MODAL', 'appArticle')
    const app = await this.$api.integration.getApp(appId)
    let useCase = null

    if (useCaseId) {
      useCase = await this.$api.integration.getUseCase(useCaseId)
    }
    commit('SET_APP', app)
    commit('SET_USE_CASE', useCase)
  },
  closeModalAppArticleModal ({ commit }) {
    commit('CLOSE_MODAL', 'appArticle')
    setTimeout(() => {
      commit('SET_APP', null)
    }, 300)
  },
  async openSuggestedUseCasesPanel ({ commit, dispatch }) {
    commit('OPEN_PANEL', 'suggestedUseCases')
    await dispatch('fetchSuggestedUseCases')
  },
  closeSuggestedUseCasesPanel ({ commit }) {
    commit('CLOSE_PANEL', 'suggestedUseCases')
  },
}
