
import { Component, ModelSync, Vue, Watch } from 'nuxt-property-decorator'
import { PlanFeature, ReadOpportunity } from '@abby/core-legacy'

@Component({
  components: { },
})
export default class OpportunitySelect extends Vue {
  isMenuOpened = false

  search = ''
  loading = false

  @ModelSync('value', 'input')
  innerValue!: ReadOpportunity | null;

  _opportunityId: string | null = null;

  get hasAccessToOpportunity () {
    return this.$planManager.hasAccessTo(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)
  }

  get opportunityId () {
    return this._opportunityId
  }

  get hasActivatorSlot () {
    return this.$scopedSlots.activator
  }

  resetOpportunity () {

  }

  set opportunityId (value: string | null) {
    const opportunity = this.opportunities.find(o => o.id === value)
    if (opportunity) {
      this.innerValue = opportunity
    }
    this._opportunityId = value
    this.isMenuOpened = false
  }

  opportunities: ReadOpportunity[] = [];

  @Watch('isMenuOpened')
  async isMenuOpenedHandler (value: boolean) {
    if (!value) { return }
    if (this.innerValue) {
      this._opportunityId = this.innerValue.id
    }
    await this.fetchOpportunities()
    this.$nextTick(() => (this.$refs.opportunity as any)?.activateMenu())
  }

  async fetchOpportunities (search?: string) {
    if (this.loading) { return }
    try {
      this.loading = true
      const results = await this.$api.opportunity.getOpportunities({ limit: 50, search })
      await this.$store.dispatch('opportunity/fetchTotalCount')
      this.opportunities = results.data
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      this.loading = false
    }
  }

  @Watch('search')
  async searchHandler (value: string) {
    await this.fetchOpportunities(value)
  }
}
