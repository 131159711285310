import { Plugin } from '@nuxt/types'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/fr'

import dayjs from 'dayjs'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)
dayjs.extend(duration)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('fr')

const DayjsPlugin: Plugin = (_, inject) => {
  inject('dayjs', dayjs)
}

// type Dayjs = (date?: Date | null | any | undefined) => dayjs.Dayjs
type Dayjs = any

declare module 'vue/types/vue' {
    interface Vue {
        $dayjs: Dayjs
    }
}

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $dayjs: Dayjs
    }
    interface Context {
        $dayjs: Dayjs
    }
}

declare module 'vuex/types/index' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Store<S> {
        $dayjs: Dayjs
    }
}

export default DayjsPlugin
