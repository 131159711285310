import type { ReadBridgeItem } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Bridge extends Resource {
  connectV2 (bankId?: number): Promise<{ redirectUrl: string }> {
    return this.axios.$get('v2/banks/items/connect', { params: { bankId } })
  }

  getAll (): Promise<Array<ReadBridgeItem>> {
    return this.axios.$get('/bridge/items')
  }

  getAllByCompanyId (companyId: string): Promise<Array<ReadBridgeItem>> {
    return this.axios.$get(`/bridge/items/${companyId}`)
  }

  forceSync (companyId: string, range: Array<string>) {
    return this.axios.$get(`/bridge/forceSync/${companyId}`, { params: { range } })
  }

  synchronizedAccount (bridgeAccountId: number, startSynchronizedAt: Date): Promise<ReadBridgeItem> {
    return this.axios.$post(`/bridge/synchronizedAccount/${bridgeAccountId}`, { startSynchronizedAt })
  }

  desynchronizedAccount (bridgeAccountId: number): Promise<ReadBridgeItem> {
    return this.axios.$post(`/bridge/desynchronizedAccount/${bridgeAccountId}`)
  }

  callBackSynchronization (body: { userUuid: string, itemId: number }): Promise<ReadBridgeItem> {
    return this.axios.$post('/bridge/callBackSynchronization', body)
  }

  callBackRefresh (itemId: number, referer?: string | undefined): Promise<string> {
    return this.axios.$get(`/bridge/item/${itemId}/refresh`, { params: { referer } })
  }

  deleteItem (itemId: number): Promise<boolean> {
    return this.axios.$delete(`/bridge/item/${itemId}`)
  }

  deleteItemFromDashboard (itemId: number): Promise<boolean> {
    return this.axios.$delete(`/bridge/item/${itemId}/dashboard`)
  }

  deleteAll (companyId: string): Promise<boolean> {
    return this.axios.$delete(`/bridge/${companyId}`)
  }

  forceSyncCompanyAccount () {
    return this.axios.$get('/bridge/forceSync')
  }

  deleteAllBridgeData (companyId: string) {
    return this.axios.$delete(`/bridge/deleteAllBridgeData/${companyId}`)
  }
}
