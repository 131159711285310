import type { ReadCreationForm, UpdateCreationForm } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class CreationForm extends Resource {
  get (companyId?: string): Promise<ReadCreationForm | undefined> {
    return this.axios.$get(`/creationForm${companyId ? `/${companyId}` : ''}`)
  }

  update (data: UpdateCreationForm, companyId?: string): Promise<ReadCreationForm | undefined> {
    return this.axios.$patch(`/creationForm${companyId ? `/${companyId}` : ''}`, data)
  }

  create (companyId?: string): Promise<ReadCreationForm | undefined> {
    return this.axios.$post(`/creationForm${companyId ? `/${companyId}` : ''}`)
  }
}
