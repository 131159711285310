import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { ReadBillingFrequency } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface FrequencyPaginationQuery { page: number, limit: number, totalItems: number, maxPages: number }

export interface FrequencyState {
  items: ReadBillingFrequency[];
  initialLoading: boolean;
  queryLoading: boolean;
  paginationQuery: FrequencyPaginationQuery;
}

export const state = (): FrequencyState => ({
  items: [],
  initialLoading: false,
  queryLoading: false,
  paginationQuery: {
    page: 1,
    limit: 15,
    totalItems: 0,
    maxPages: 1,
  },
})

export const getters: GetterTree<FrequencyState, RootState> = {
  paginationQuery: state => state.paginationQuery,
  initialLoading: state => state.initialLoading,
  queryLoading: state => state.queryLoading,
  items: state => state.items,
}

export const mutations: MutationTree<FrequencyState> = {
  SET_ITEMS (state, items: ReadBillingFrequency[]) {
    state.items = items
  },
  SET_QUERY_LOADING (state, value: boolean) {
    state.queryLoading = value
  },
  SET_PAGINATION_QUERY (state, value: FrequencyPaginationQuery) {
    state.paginationQuery = value
  },
  RESET (_currentState: FrequencyState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<FrequencyState, RootState> = {
  async fetchFrequencies ({ commit, getters }) {
    try {
      commit('SET_QUERY_LOADING', true)
      const results = await this.$api.frequency.paginate({
        page: getters.paginationQuery.page,
        limit: getters.paginationQuery.limit,
      })
      commit('SET_ITEMS', results.data)
      commit('SET_PAGINATION_QUERY', {
        ...getters.paginationQuery,
        page: results.currentPage,
        totalItems: results.totalItems,
        maxPages: results.maxPages,
      })
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  setPaginationQuery ({ commit, dispatch }, value: FrequencyPaginationQuery) {
    commit('SET_PAGINATION_QUERY', value)
    dispatch('fetchFrequencies')
  },
}
