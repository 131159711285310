import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { CreationCompanyStep, SegmentEvent } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface OnboardingState {
  loadingConnectBank: boolean;
}

export const state = (): OnboardingState => ({
  loadingConnectBank: false,
})

export const getters: GetterTree<OnboardingState, RootState> = {
  // TODO
  nbOfStepsCompleted: (_, getters) => getters.steps.filter((step: any) => step.complete).length,
  nbOfStepsNotCompleted: (_, getters) => getters.steps.filter((step: any) => !step.complete).length,
  allStepsAreCompleted: (_, getters) => getters.nbOfStepsCompleted === getters.steps.length,
  loadingConnectBank: state => state.loadingConnectBank,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  steps: (_, getters, __, rootGetters) => {
    if (!process.browser) { return [] }
    const { name, nafCode, siret, siren, address } = rootGetters['company/company'] || {}
    return [{
      icon: 'pending_actions',
      title: 'Commencer à créer mon auto-entreprise',
      subtitle: 'Choisissez une formule pour démarrer les démarches',
      show: rootGetters['company/isInCreation'],
      complete: !window.$nuxt.$planManager.displayAbbyCreationPlans(),
      action: () => {
        window.$nuxt.$ap.sendToGTM({ event: SegmentEvent.SIGNED_UP_COMPLETE })
        window.$nuxt.$move('/company/creation')
      },
    }, {
      icon: 'person',
      title: 'Remplir le formulaire de création',
      subtitle: 'Déterminez si vous pouvez créer votre entreprise avec Abby',
      show: rootGetters['company/isInCreation'],
      complete: !!rootGetters['company/creation']?.steps?.[CreationCompanyStep.CREATION_REQUESTED]?.finishedAt,
      action: () => {
        window.$nuxt.$store.commit('creationForm/SET_IS_CREATION_FORM_MODAL_OPENED', true)
      },
    }, {
      icon: 'description',
      title: 'Transmettre mes documents',
      subtitle: `Il vous reste ${rootGetters['company/numberOfFilesMissing']} documents à nous transmettre`,
      show: rootGetters['company/isInCreation'] && !!rootGetters['company/creation']?.steps?.[CreationCompanyStep.CREATION_REQUESTED]?.finishedAt && !window.$nuxt.$planManager.displayAbbyCreationPlans(),
      complete: rootGetters['company/numberOfFilesMissing'] === 0,
      action: () => {
        window.$nuxt.$store.commit('company/SET_IS_CREATION_DOCUMENT_DEPOSIT_MODAL_OPEN', true)
      },
    }, {
      icon: 'person',
      title: 'Compléter mes informations',
      subtitle: 'Nous avons besoin de ces informations : Raison sociale, SIRET, SIREN, Adresse, Code NAF',
      show: !rootGetters['company/isInCreation'] || !!rootGetters['company/creation']?.steps?.[CreationCompanyStep.COMPANY_CREATED]?.finishedAt,
      complete: [name, nafCode, siret, siren, address].every(item => item),
      action: () => {
        window.$nuxt.$store.commit('company/SET_IS_EDIT_MODAL_OPENED', true)
      },
    }, {
      icon: 'account_balance',
      title: 'Connecter ma banque',
      subtitle: 'Synchronisez votre compte bancaire et profitez à 100% de l\'application',
      show: !rootGetters['company/isInCreation'] || !!rootGetters['company/creation']?.steps?.[CreationCompanyStep.DECLARATION_SENT]?.finishedAt,
      complete: rootGetters['accounts/hasAccountSynchronized'],
      action: async () => {
        await window.$nuxt.$store.dispatch('onboarding/setLoadingConnectBank', true)
        await window.$nuxt.$store.dispatch('accounts/connectBank')
        await window.$nuxt.$store.dispatch('onboarding/setLoadingConnectBank', false)
      },
    }].filter(step => step.show)
      .sort((a: any, b: any) => a.complete - b.complete)
  },
}

export const mutations: MutationTree<OnboardingState> = {
  SET_LOADING_CONNECT_BANK (state, value: boolean) {
    state.loadingConnectBank = value
  },
  RESET (_currentState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<OnboardingState, RootState> = {
  setLoadingConnectBank ({ commit }, value: boolean) {
    commit('SET_LOADING_CONNECT_BANK', value)
  },
}
