
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-list-selection',
  components: {
    ValidationProvider,
  },
})
export default class ASListSelection extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: [] })
  items!: Array<any>;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ default: false })
  dense!: boolean | string;

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.dense || typeof this.dense === 'string'
  }

  @Prop({ default: false })
  row!: boolean | string;

  get _row () {
    return !!this.row || typeof this.row === 'string'
  }

  @ModelSync('value', 'input')
  innerValue: any;
}
