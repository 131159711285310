import { Resource } from '../Resource';
import { PreprocessClient, ImportClient, ClientImportPayload } from '../../dto';

export class Client extends Resource {
  async preprocessing(formData: FormData, importType: string): Promise<PreprocessClient> {
    return this.http.$post('/v2/client/import/preprocessing', formData, {
      params: { importType },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async validate(data: ClientImportPayload, importType: string): Promise<PreprocessClient> {
    return this.http.$post('/v2/client/import/validate', data, {
      params: { importType },
    });
  }

  async import(data: ClientImportPayload, importType: string): Promise<ImportClient> {
    return this.http.$post('/v2/client/import', data, {
      params: { importType },
    });
  }
}
