import type {
  BasePaginate,
  CreateBillingFrequency, IBilling, QueryBillingFrequency, ReadBillingFrequency, UpdateBillingFrequency,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Frequency extends Resource {
  paginate (params: QueryBillingFrequency): Promise<BasePaginate<ReadBillingFrequency>> {
    return this.axios.$get('/billing/frequencies', {
      params,
    })
  }

  createFrequency (payload: CreateBillingFrequency): Promise<ReadBillingFrequency> {
    return this.axios.$post('/billing/frequency', payload)
  }

  updateFrequency (id: string, payload: UpdateBillingFrequency): Promise<IBilling> {
    return this.axios.$patch(`/billing/${id}/frequency`, payload)
  }
}
