import { GetterTree, ActionTree, MutationTree } from 'vuex'
// eslint-disable-next-line import/named
import { Options as VimeoPlayerOptions } from '@vimeo/player'
import { RootState } from '~/store/index'

export interface VideoState {
  vimeoVideoOptions: VimeoPlayerOptions;
  isVimeoModalOpened: boolean;
}

export const state = (): VideoState => ({
  vimeoVideoOptions: {},
  isVimeoModalOpened: false,
})

export const getters: GetterTree<VideoState, RootState> = {
  vimeoVideoOptions: state => state.vimeoVideoOptions,
  isVimeoModalOpened: state => state.isVimeoModalOpened,
}

export const mutations: MutationTree<VideoState> = {
  SET_VIMEO_VIDEO_OPTIONS (state, value: VimeoPlayerOptions) {
    state.vimeoVideoOptions = value
  },
  SET_IS_VIMEO_MODAL_OPENED (state, value: boolean) {
    state.isVimeoModalOpened = value
  },
}

export const actions: ActionTree<VideoState, RootState> = {
  setVimeoVideoOptions ({ commit }, value: VimeoPlayerOptions) {
    commit('SET_VIMEO_VIDEO_OPTIONS', value)
  },
  setIsVimeoModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_VIMEO_MODAL_OPENED', value)
  },
}
