
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { customerClientFullName, customerName, ICustomer, ReadCustomer } from '@abby/core-legacy'
import CustomerDetailsQuickPreview from '~/components-legacy/customerDetails/CustomerDetailsQuickPreview.vue'
import AbbyAvatar from '~/components/abby/status/AbbyAvatar.vue'
import { useOpenContactDetailsSidePanel } from '~/composables/client/contact/useOpenContactDetailsSidePanel'
import {
  useOpenOrganizationDetailsSidePanel,
} from '~/composables/client/organization/useOpenOrganizationDetailsSidePanel'
import ClientBadge from '~/components/client/items/ClientBadge.vue'

@Component({
  components: { ClientBadge, CustomerDetailsQuickPreview, AbbyAvatar },
  setup () {
    const openContactDetailsSidePanel = useOpenContactDetailsSidePanel()
    const openOrganizationDetailsSidePanel = useOpenOrganizationDetailsSidePanel()
    return { openContactDetailsSidePanel, openOrganizationDetailsSidePanel }
  },
})
export default class CustomerLabel extends Vue {
  @Getter('customerDetails/isAlreadyOpenedOnSidePanel')
  _isAlreadyOpenedOnSidePanel!: (id: string) => boolean

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  @Getter('billingCreation/isBillingCreationModalOpened')
  isBillingCreationModalOpened!: boolean

  @Getter('billingCreation/isBillingEditionModalOpened')
  isBillingEditionModalOpened!: boolean

  @Prop({ required: false })
  customer!: ICustomer | undefined

  @Prop({ required: true })
  customerId!: string

  @Prop({ default: null })
  search!: string | null

  @Prop({ default: 'normal' })
  size!: 'large' | 'normal'

  @Prop({ default: 'label' })
  type!: 'label' | 'plain'

  @Prop({ default: false })
  fetchOnMounted!: boolean

  @Prop({ default: true })
  quickPreview!: boolean

  @Prop({ default: true })
  openDetails!: boolean

  @Prop({ default: true })
  enableTpSyncIcon!: boolean

  serverCustomer: ICustomer | null = null

  openContactDetailsSidePanel!: (id: string) => void
  openOrganizationDetailsSidePanel!: (id: string) => void

  get isModalOpened () {
    return this.isBillingCreationModalOpened || this.isBillingEditionModalOpened
  }

  get customerToDisplay () {
    return this.serverCustomer || this.customer || null
  }

  get avatarHeight () {
    if (this.isNormal) {
      return 12
    } else if (this.isLarge) {
      return 24
    }
  }

  get isLabel () {
    return this.type === 'label'
  }

  get isNormal () {
    return this.size === 'normal'
  }

  get isLarge () {
    return this.size === 'large'
  }

  get avatarSize () {
    if (this.isNormal) {
      return 7
    }
    if (this.isLarge) {
      return 12
    }
    return 10
  }

  get tpError () {
    if (!this.customerToDisplay?.tiersPrestationError) { return '' }
    return `<br><br>${this.$t(`error.urssaf_tp.${this.customerToDisplay?.tiersPrestationError}`)} (${this.customerToDisplay?.tiersPrestationError})`
  }

  get syncWithTiersPrestation () {
    return this.customerToDisplay?.tiersPrestationIsActivatedForThisCustomer && this.customerToDisplay?.tiersPrestation?.registeredAt
  }

  get hasNotRegisteredToUrssaf () {
    return this.customerToDisplay?.tiersPrestationIsActivatedForThisCustomer && this.customerToDisplay?.tiersPrestation && !this.customerToDisplay?.tiersPrestation?.idClient && !this.customerToDisplay?.tiersPrestation?.registeredAt
  }

  get companyDisplayName () {
    if (!this.customerToDisplay) { return false }
    return customerName(this.customerToDisplay, true)
  }

  get customerDisplayName () {
    if (!this.customerToDisplay) { return '' }
    return customerClientFullName(this.customerToDisplay)
  }

  openCustomerDetails () {
    if (this.isModalOpened) { return }
    if (this.customerToDisplay?.contactId) {
      this.openContactDetailsSidePanel(this.customerToDisplay.contactId)
      return
    }
    if (this.customerToDisplay?.organizationId) {
      this.openOrganizationDetailsSidePanel(this.customerToDisplay.organizationId)
    }
  }

  customerUpdatedId = ''

  async mounted () {
    this.customerUpdatedId = this.$busManager.on('customerUpdated', (c: ReadCustomer) => {
      if (c?._id === this.customerId) {
        this.serverCustomer = c
      }
    })
    // @ts-ignore
    if (!this.customerId || this.customer?.test || !this.fetchOnMounted) { return }
    const result = await this.$api.billing.getCustomer(this.customerId)
    this.serverCustomer = result as unknown as ICustomer
  }

  beforeDestroy () {
    this.$busManager.off(this.customerUpdatedId)
  }
}

