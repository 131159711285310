
import { Action, Component, Getter, ModelSync, Ref, Vue, Watch } from 'nuxt-property-decorator'
import {
  IBillingConfiguration,
  ICompany, MandatState, ReadUrssafJourneyDocument, SegmentEvent,
  UpdateUser,
} from '@abby/core-legacy'
import { ValidationProvider } from 'vee-validate'
import { Action as AbbyAction } from '@abby/shared'
import HomeGuideModal from '~/components-legacy/home/home-guide/HomeGuideModal.vue'
import { BillingConfigurationModals } from '~/store/billingConfiguration'
import ASForm from '~/components-legacy/inputs/ASForm.vue'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import ASDatePicker from '~/components-legacy/inputs/ASDatePicker.vue'
import { IAuthUser } from '~/store/auth'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { UrssafState } from '~/store/urssaf'
import Link from '~/components-legacy/utils/Link.vue'
import { GuideState } from '~/store/guide'

@Component({
  components: { Link, AbbyPlanChip, ASDatePicker, AsTextField, ASForm, HomeGuideModal },
})
export default class HomeGuideModalConnectUrssaf extends Vue {
  @Getter('guide/modal')
  guideModal!: GuideState['modal']

  @Ref('form') form!: InstanceType<typeof ValidationProvider>;
  @Ref('modal') modal!: any;

  @Getter('company/company')
  company!: ICompany

  @Getter('urssaf/mandatBelongsToUs')
  mandatBelongsToUs!: boolean

  @Getter('auth/user')
  user!: IAuthUser

  @Getter('urssaf/urssafJourney')
  urssafJourney!: ReadUrssafJourneyDocument | null

  @Getter('urssaf/loadingCreationMandat')
  loadingCreationMandat!: boolean

  @Getter('urssaf/errors')
  urssafErrors!: UrssafState['errors']

  @Getter('urssaf/mandatState')
  mandatState!: MandatState | null

  siret: string = ''
  birthDate: string = ''
  hasCreationError: boolean = false
  displaySuccess: boolean = false
  recoveringLoading: boolean = false
  reminderLoading: boolean = false
  reminderActivated = false

  async updateReminder (value: boolean) {
    try {
      if (value && !this.hasAccessToDeclarationReminder) {
        this.openAbbyPlansModal()
        return
      }
      this.reminderLoading = true
      await this.$store.dispatch('urssaf/updateUrssafJourney', { isDeclarationReminderActivated: this.reminderActivated })
      this.$ap.sendSegmentTrack({
        event: SegmentEvent.ACTION_KEY,
        data: {
          eventType: SegmentEvent.RECALL_URSSAF_TAXES_CFE_ACTIVATED,
          activated: value,
        },
      })
      if (value) {
        this.$ap.track(AbbyAction.URSSAF_REMINDER_ACTIVATED)
      } else {
        this.$ap.track(AbbyAction.URSSAF_REMINDER_DEACTIVATED)
      }
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      this.reminderLoading = false
    }
  }

  get belongsToAnother () {
    return this.mandatState === MandatState.BELONGS_TO_ANOTHER
  }

  get hasError () {
    return this.hasCreationError || this.belongsToAnother
  }

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration

  @Action('auth/updateUser')
  updateUser!: (payload: UpdateUser) => Promise<void>;

  @Action('billingConfiguration/openModal') openBillingConfigurationModal!: (key: BillingConfigurationModals) => void

  @Watch('guideModal.urssafDeclarationModal')
  handleOpened (value: boolean) {
    if (!value) {
      this.siret = ''
      this.birthDate = ''
      this.form?.reset()
      this.displaySuccess = false
    } else {
      this.siret = this.company.siret || ''
      this.birthDate = this.user.birthDate ? this.$dayjs(this.user.birthDate).format('YYYY-MM-DD') : ''
      this.displaySuccess = this.mandatBelongsToUs
    }
  }

  openUrssafWebsite () {
    window.open('https://www.autoentrepreneur.urssaf.fr/', '_blank')
  }

  async verifyHasMandatWithUs () {
    try {
      await this.$store.dispatch('urssaf/verifyHasMandatWithUs', {
        siret: this.company?.siret,
        recurrence: this.company?.declarationRecurrence,
      })
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  async validateForm () {
    const isValid = await this.form.validate()
    if (!isValid) {
      const element = document.getElementById('home-guide-field-siret')
      if (!element) { return }
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
    }
    return isValid
  }

  async tryToRecover () {
    try {
      if (!(await this.validateForm())) {
        return
      }
      this.recoveringLoading = true
      await this.updateUserAndCompanyInformation()
      const doneSuccessfully = await this.$store.dispatch('urssaf/revokeMandat', {
        birthDate: this.user.birthDate,
        siret: this.company?.siret,
      })

      if (!doneSuccessfully) {
        this.$alertsManager.error('Il semblerait qu\'il y ait toujours un problème. Contactez le support')
        return
      }

      await this.verifyHasMandatWithUs()
      this.$store.commit('urssaf/SET_CURRENT_ESTIMATIONS_FROM_URSSAF', null)
      this.$alertsManager.success('Vous pouvez tenter à nouveau de connecter votre compte Urssaf')
    } catch (e) {
      this.$alertsManager.error('Il semblerait qu\'il y ait toujours un problème. Contactez le support')
    } finally {
      this.recoveringLoading = false
    }
  }

  openAbbyPlansModal () {
    this.$planManager.openAbbyPlansModal({ feature: this.$planManager.features.accounting_urssaf_cfe_impots_reminders })
  }

  get hasAccessToDeclarationReminder () {
    return this.$planManager.hasAccessTo(this.$planManager.features.accounting_urssaf_cfe_impots_reminders)
  }

  async updateUserAndCompanyInformation () {
    await this.$store.dispatch('company/updateCompany', { id: this.company?.id, payload: { siret: this.siret } })
    await this.updateUser({
      birthDate: new Date(this.birthDate),
    })
  }

  async createMandat () {
    if (!(await this.validateForm())) {
      return
    }

    try {
      this.hasCreationError = false
      this.$store.commit('urssaf/SET_LOADING_CREATION_MANDAT', true)
      await this.updateUserAndCompanyInformation()
      // create mandat
      await this.$store.dispatch('urssaf/createMandat', {
        birthDate: this.$dayjs(this.user.birthDate).format('YYYY-MM-DD'),
        siret: this.company.siret,
      })

      await this.$store.dispatch('urssaf/verifyHasMandatWithUs', {
        siret: this.company.siret,
      })

      if (!this.mandatBelongsToUs) {
        this.$alertsManager.warning('Une erreur en provenance de l\'Urssaf a empêché la connexion avec Abby. Veuillez ressayer plus tard ou contacter nos conseillers', {
          dismissible: true,
        })
        this.hasCreationError = true
        return
      }

      this.$emit('submit')

      await this.$store.dispatch('urssaf/fetchMandatsSepa')
      await this.$store.dispatch('urssaf/fetchCurrentEstimationsFromUrssaf')
      this.$alertsManager.success('Le mandat a été créé avec succès')
      this.displaySuccess = true
      this.modal.explodeConfettis()
    } catch (e) {
      if (e?.message === 'Le SIRET est déjà mandaté') {
        this.$store.commit('urssaf/SET_MANDAT_STATE', MandatState.BELONGS_TO_ANOTHER)
        this.$alertsManager.warning('La connexion n\' a pas réussie car il semblerait que votre compte Urssaf soit associé à une autre plateforme. Révoquez la connexion sur l\'autre plateforme puis essayer à nouveau sur Abby', {
          dismissible: true,
        })
      } else {
        this.$alertsManager.warning('Une erreur en provenance de l\'Urssaf a empêché la connexion avec Abby. Veuillez ressayer plus tard ou contacter nos conseillers', {
          dismissible: true,
        })
      }
      this.hasCreationError = true
    } finally {
      this.$store.commit('urssaf/SET_LOADING_CREATION_MANDAT', false)
    }
  }

  close () {
    this.$store.dispatch('guide/closeUrssafDeclarationModal')
  }
}

