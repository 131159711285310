export enum DelivererTransport {
  BIKE = 'bike',
  MOTORBIKE = 'motorbike',
  CAR = 'car',
}

export enum DelivererActivity {
  FOOD = 'food',
  PACKAGE = 'package',
  COURSES = 'courses',
}
