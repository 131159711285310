import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { Toaster } from '~/plugins/toasterManager'

export const useToasterManagerStore = () => {
  const useToasterManagerStore = defineStore('toasterManager', () => {
    const toasters = ref<Toaster[]>([])

    const removeToaster = (toaster: Toaster) => {
      toaster.exiting = true
      toasters.value.splice(toasters.value.indexOf(toaster), 1, toaster)
      setTimeout(() => {
        toasters.value = toasters.value.filter(t => t !== toaster)
      }, 200)
    }

    const addToaster = (toaster: Toaster) => {
      toaster.paused = false
      toasters.value = [
        ...toasters.value,
        toaster,
      ]
      toaster.timerId = Number(setTimeout(() => {
        if (!toaster.paused) {
          removeToaster(toaster)
        }
      }, toaster.timeout))
    }

    const pauseToaster = (toaster: Toaster) => {
      toaster.paused = true
      if (toaster.timerId) {
        clearTimeout(toaster.timerId)
      }
    }

    const resumeToaster = (toaster: Toaster) => {
      toaster.paused = false
      toaster.timerId = Number(setTimeout(() => removeToaster(toaster), toaster.timeout))
    }

    return {
      toasters,
      addToaster,
      removeToaster,
      pauseToaster,
      resumeToaster,
    }
  })
  const toasterManagerStore = useToasterManagerStore()
  const {
    toasters,
  } = storeToRefs(toasterManagerStore)

  return {
    toasters,
    addToaster: toasterManagerStore.addToaster,
    removeToaster: toasterManagerStore.removeToaster,
    pauseToaster: toasterManagerStore.pauseToaster,
    resumeToaster: toasterManagerStore.resumeToaster,
  }
}
