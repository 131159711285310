export enum InvalidUrssafCustomerRegistrationReason {
  API_UNAVAILABLE = 'urssaf_tp.api_unavailable',
  INVALID_EMAIL = 'urssaf_tp.invalid_email',
  INVALID_PHONE_NUMBER = 'urssaf_tp.invalid_phoneNumber',
  UNAVAILABLE_2042 = 'urssaf_tp.unavailable_2042',
  UNAVAILABLE_2044 = 'urssaf_tp.unavailable_2044',
  UNAVAILABLE_4101 = 'urssaf_tp.unavailable_4101',
  NUMERO_VOIE_SANS_LETTRE = 'urssaf_tp.numero_voie_sans_lettre',
  NOM_NAISSANCE_CARACTERE = 'urssaf_tp.nom_naissance_caractere',
  NOM_USAGE_CARACTERE = 'urssaf_tp.nom_usage_caractere',
  PRENOMS_CARACTERE = 'urssaf_tp.prenoms_caractere',
  PRESTATAIRE_INCONNU = 'urssaf_tp.prestataire_inconnu',
  PARTICULIER_EXISTANT = 'urssaf_tp.particulier_existant',

  UNKNOWN_ERROR = 'urssaf_tp.erreur_inconnue',
}
