import { PlanFeature } from '@abby/core-legacy'
import { Category } from '~/logic/contexts/opportunity/domain/entity/category.entity'
import { Customer } from '~/logic/contexts/billing/domain/entity/customer.entity'
import { useOpportunityStore } from '~/logic/contexts/opportunity/infrastructure/store/opportunityPinia.store'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'

export const useOpenOpportunityFormSidePanel = () => {
  const opportunityStore = useOpportunityStore()
  const planManager = usePlanManager()

  return async ({ category, customer }: { category: Category, customer?: Customer }) => {
    if (!planManager.hasAccessToFeature(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)) {
      return planManager.openPlansModal(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)
    }
    return await opportunityStore.openOpportunityFormSidePanel({ category, customer })
  }
}
