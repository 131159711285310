import Vue from 'vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { retryFn } from '~/logic/contexts/shared-kernel/infrastructure/repository/queryRetry.axios'

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: (attemptIndex: number, error: unknown) => retryFn(attemptIndex, error),
      },
    },
  },
}

Vue.use(VueQueryPlugin, vueQueryPluginOptions)
