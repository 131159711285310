import type {
  ReadAcreDocumentKanban, QueryAcreDocument, UpdateAcreDocument, ReadAcreDocument,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class AcreDocument extends Resource {
  getAllKanban (params: QueryAcreDocument): Promise<ReadAcreDocumentKanban[]> {
    return this.axios.$get('/acredocuments', { params })
  }

  getLastDocumentByCompanyId (companyId: string): Promise<ReadAcreDocument> {
    return this.axios.$get(`/acredocuments/${companyId}`)
  }

  update (id: string, payload: UpdateAcreDocument): Promise<ReadAcreDocument> {
    return this.axios.$put(`/acredocuments/${id}`, payload)
  }

  delete (id: string): Promise<boolean> {
    return this.axios.$delete(`/acredocuments/${id}`)
  }
}
