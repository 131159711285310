import { Context, Plugin } from '@nuxt/types'
import {
  Civility,
  ReadCompanyStripeProduct,
  RegisteredType,
  SegmentEvent,
  StripeProductType,
  TrackAdvertising,
  whichPlanCompanyHas,
} from '@abby/core-legacy'
import { Action } from '@abby/shared'
import { AuthState } from '~/store/auth'
import { CompanyState } from '~/store/company'

export interface AbbyPixelManagerInstance {
  segmentTrack: (event: string, params: any) => void;
  sendSegmentTrack: (payload: TrackAdvertising) => void;
  identify: (params?: any) => void;
  page: () => void;
  sendToGTM: (payload: TrackAdvertising) => void;
  track: (action: Action, params?: any) => void;
  startExperimentation: (params: { experimentName: string, variantName: string }) => void;
}

const dontSendEvent = false

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AbbyPixelManagerPlugin: Plugin = ({ store, app, $config, $dayjs, $backend }: Context, inject) => {
  const user = (): AuthState['user'] => store.getters['auth/user']
  const company = (): CompanyState['company'] => store.getters['company/company']
  const products = (): ReadCompanyStripeProduct[] => store.getters['company/plans']

  const convertRegistredType = (registeredType: number | null) => {
    if (!registeredType === null) { return undefined }
    if (registeredType === RegisteredType.GESTION) { return 'Gestion' }
    if (registeredType === RegisteredType.CREATION) { return 'Creation' }
  }
  const generateRandomString = () => {
    return `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 30)
      .toUpperCase()}-${(Math.floor(Math.random() * 900000) + 100000).toString()}`
  }

  const convertGender = (civility: number | null) => {
    if (civility === Civility.MISS) { return 'm' }
    if (civility === Civility.MISTER) { return 'f' }
    return undefined
  }

  const track = (action: Action, params?: any) => {
    if (!process.browser) { return }
    store.dispatch('tracking/trackNew', { action, ...params })
  }

  const sendToGTM = (payload: TrackAdvertising): void => {
    if (!process.browser) { return }
    // if (process.env.NODE_ENV !== 'production') { return }
    // Ne pas détruire la fonction la, si besoin en parler avec @Benjamin 🙏🏼
    let event = payload.event
    if (event === SegmentEvent.ACTION_KEY) {
      event = payload.data?.eventType
    }
    const _user = user()
    const _company = company()

    if (dontSendEvent || _user?.registeredType === RegisteredType.ADMINISTRATION) { return }

    const phone = _user?.phone === null || _user?.phone === '+33' ? undefined : _user?.phone
    const age = isNaN($dayjs().diff(_user?.birthDate, 'y')) ? undefined : $dayjs().diff(_user?.birthDate, 'y')

    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    // @ts-ignore
    window.dataLayer.push({
      event,
      event_id: generateRandomString(),
      user_data: {
        id: _user?.id || undefined,
        email: _user?.email || undefined,
        firstname: _user?.firstname || undefined,
        lastname: _user?.lastname || undefined,
        phone,
        birthday: _user?.birthDate ? $dayjs(_user?.birthDate).format('DD/MM/YYYY') : undefined,
        age,
        zipCode: _company?.zipCode || undefined,
        city: _company?.city?.name || undefined,
        street: _company?.address || undefined,
        registeredType: convertRegistredType(_company?.registeredType || null),
        gender: convertGender(_user?.civility || null),
      },
      data: {
        ...payload.data,
      },
    })
  }

  const startExperimentation = (params: { experimentName: string, variantName: string }) => {
    if (!process.browser) { return }
    const data = {
      ...params,
    }
    $backend.analytics.startAuthenticatedExperimentation(data)
  }

  const manager = {
    sendToGTM,
    track,
    startExperimentation,
    watch: (event: string): void => {
      try {
        if ($config.nodeEnv === 'production') {
          // @ts-ignore
          window.hj('event', event)
        } else {
          // eslint-disable-next-line no-console
          console.log('[DEV]: Save Hotjar event :', event)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    segmentTrack: (event: string, params: any) => {
      if (!process.browser) { return }
      // @ts-ignore
      if (!window.analytics) { return }

      // TODO refacto les data _user et _company pour ne faire qu'une seule fonction
      const _user = user()
      const _company = company()
      if (!_user?.id) {
        if (dontSendEvent) {
          // eslint-disable-next-line no-console
          console.log('[DEV]: Sent event to segment', event, params)
        } else {
          // @ts-ignore
          window.analytics.track(event, params)
        }
        return
      }

      const age = $dayjs().diff(_user.birthDate, 'y')
      const payload = {
        userId: _user.id,
        name: _user.fullName || null,
        email: _user.email,
        createdAt: _user.createdAt,
        firstname: _user.firstname,
        lastname: _user.lastname,
        gender: _user.civility,
        phone: _user.phone,
        birthday: _user.birthDate,
        age: age || undefined,
        company: {
          id: _company?.id,
          name: _company?.name,
        },
        address: {
          city: _company?.city?.name,
          country: 'fr',
          postalCode: _company?.zipCode,
          street: _company?.address,
        },
      }
      if (dontSendEvent) {
        // eslint-disable-next-line no-console
        console.log('[DEV]: Sent event to segment', event, { ...params, ...payload })
        return
      }

      // @ts-ignore
      window.analytics.track(event, { ...params, ...payload })
    },

    sendSegmentTrack: (payload: TrackAdvertising): void => {
      if (dontSendEvent) {
        // eslint-disable-next-line no-console
        console.log('[DEV]: Sent event to segment', payload)
      } else {
        store.dispatch('tracking/track', { ...payload })
      }
    },

    page: ():void => {
      if (process.browser) {
        const payload = {
          type: 'page',
          name: window.document.title,
          properties: {
            title: window.document.title,
            url: window.location.href,
          },
        }
        if (dontSendEvent) {
          // eslint-disable-next-line no-console
          console.log('[DEV]: Sent page to segment', payload)
        } else {
          // @ts-ignore
          window.analytics.page(payload)
        }
      }
    },
    identify: (): void => {
      try {
        const _company = company()
        const _user = user()
        const _plan = whichPlanCompanyHas(products())
        const _product = products().find(c => c.productId === _plan as unknown as StripeProductType)
        if (process.browser) {
          if (!_user?.id) {
            if (dontSendEvent && $config.nodeEnv !== 'production') {
              // eslint-disable-next-line no-console
              console.log('[DEV]: Identify with Segment as anonymous')
            } else {
              // @ts-ignore
              window.analytics.identify()
            }
          } else if (dontSendEvent) {
            // eslint-disable-next-line no-console
            console.log('[DEV]: Identify with Segment as connected user')
          } else {
            // store.dispatch('tracking/identify')
            const age = $dayjs().diff(_user.birthDate, 'y')
            const payload = {
              userId: _user.id,
              name: _user.fullName || null,
              email: _user.email,
              createdAt: _user.createdAt,
              firstname: _user.firstname,
              lastname: _user.lastname,
              gender: _user.civility,
              phone: _user.phone,
              birthday: _user.birthDate,
              user,
              age: age || undefined,
              company,
              plan: _product || { productId: 'abby_free', product: { id: 'abby_free' } },
              address: {
                city: _company?.city?.name,
                country: 'fr',
                postalCode: _company?.zipCode,
                street: _company?.address,
              },
            }
            // @ts-ignore
            window.analytics.identify(_user.userId, payload)
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Identify error', e)
      }
    },
  }
  inject('ap', {
    ...manager,
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $ap: AbbyPixelManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $ap: AbbyPixelManagerInstance
  }
  interface Context {
    $ap: AbbyPixelManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $ap: AbbyPixelManagerInstance
  }
}

export default AbbyPixelManagerPlugin
