import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { Loading } from '~/store/app'

export interface RootState {}

export const state = (): RootState => ({})

export const getters: GetterTree<RootState, RootState> = {}

export const mutations: MutationTree<RootState> = {}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtClientInit ({ dispatch, commit }) {
    dispatch('app/setAppLoading', { key: Loading.NUXT_CLIENT_LOADING, value: true })
    commit('app/SET_BASE_URL', process.env.baseAppUrl)
    commit('app/SET_INIT_TIMESTAMP', Date.now())
    commit('app/SET_API_HOST', process.env.apiHost)
    commit('app/SET_STRAPI_API_HOST', process.env.strapiApiHost)
    commit('app/SET_STRAPI_API_HOST', '/strapi')

    await dispatch('initializeUserRessources', null, { root: true })
    dispatch('app/setAppLoading', { key: Loading.NUXT_CLIENT_LOADING, value: false })
  },
  cleanupAction ({ commit }) {
    commit('academy/RESET')
    commit('accounting/RESET')
    commit('accountingAccounts/RESET')
    commit('accountingPage/RESET')
    commit('accounts/RESET')
    commit('startGestion/RESET')
    commit('activities/RESET')
    commit('administration/RESET')
    commit('alertsManager/RESET')
    commit('auth/RESET')
    commit('billing/RESET')
    commit('billingConfiguration/RESET')
    commit('billingCreation/RESET')
    commit('clients/RESET')
    commit('creationForm/RESET')
    commit('company/RESET')
    commit('confirmManager/RESET')
    commit('creation/RESET')
    commit('customers/RESET')
    commit('urssaf/RESET')
    commit('gestion/RESET')
    commit('products/RESET')
    commit('signup/RESET')
    commit('sponsorship/RESET')
    commit('dashboardStatistics/RESET')
    commit('start/RESET')
    commit('statistics/RESET')
    commit('incomeBook/RESET')
    commit('purchaseRegister/RESET')
    commit('user/RESET')
    commit('vats/RESET')
    commit('commercialOffers/RESET')
    commit('opportunity/RESET')
    commit('payment/RESET')
    commit('declarations/RESET')
    commit('experience/RESET')
    commit('exports/RESET')
    commit('customerDetails/RESET')
    commit('taskDetails/RESET')
    commit('productDetails/RESET')
    commit('providerDetails/RESET')
    commit('providers/RESET')
    commit('guide/RESET')
    commit('helpCenter/RESET')
    commit('nova/RESET')
    commit('onboarding/RESET')
    commit('selection/RESET')
    commit('timeTracking/RESET')
    commit('upsells/RESET')
    commit('task/RESET')
    commit('signature/RESET')
    commit('vat/RESET')
    commit('frequency/RESET')
  },
  async initializeCommonRessources ({ dispatch }) {
    await dispatch('activities/fetchParentActivities')
    await dispatch('activities/fetchActivities')
  },
  async initializeUserRessources ({ dispatch, getters }) {
    await Promise.all([
      await dispatch('auth/fetchUser'),
      await dispatch('auth/fetchUserPreferences'),
      await dispatch('company/fetchPlans'),
      await dispatch('company/fetchCompany'),
      await dispatch('company/fetchCreation'),
      await dispatch('accounts/fetchBankAccounts'),
      await dispatch('vat/fetchVatConfiguration'),
      await dispatch('guide/fetchGuideSteps'),
      await dispatch('campaigns/fetchCampaigns'),
      await dispatch('guide/fetchPersona'),
    ])
    if (process.env.NODE_ENV !== 'development') {
      this.$sentry.setUser({
        lastname: getters['auth/user']?.firstname,
        firstname: getters['auth/user']?.lastname,
        email: getters['auth/user']?.email,
        id: getters['auth/user']?.id,
      })
    }
  },
}
