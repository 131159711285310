import { Stripe as StripeType } from 'stripe'

import {
  AbbyPlans,
  StripeProductFrequency,
  StripeProductType,
} from '@abby/core-legacy'

import type {
  CreateSetupCard,
  QueryPayment,
  QueryStripeAmount,
  ReadPayment,
  ReadStripeAccount,
  ReadStripeAccountLink,
  ReadStripeCheckoutAmount,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Stripe extends Resource {
  getAccount (): Promise<ReadStripeAccount | null> {
    return this.axios.$get('/stripe/account')
  }

  createAccount (): Promise<ReadStripeAccount> {
    return this.axios.$post('/stripe/account')
  }

  getAccountLink (params?: { referer?: string | undefined }): Promise<ReadStripeAccountLink> {
    return this.axios.$get('/stripe/accountLink', { params })
  }

  createPayment (id?: StripeProductType, params?: QueryPayment): Promise<ReadPayment> {
    return this.axios.$get(`/stripe/payment/${id}`, { params })
  }

  getPaymentMethods (): Promise<StripeType.PaymentMethod[]> {
    return this.axios.$get('/stripe/paymentMethods')
  }

  getCoupon (promoCode: string, params?: { frequency?: StripeProductFrequency }): Promise<StripeType.Coupon> {
    return this.axios.$get('/stripe/coupon', {
      params: {
        promoCode,
        ...params,
      },
    })
  }

  createPaymentMethod (params?: CreateSetupCard): Promise<string> {
    return this.axios.$post('/stripe/paymentMethod', params)
  }

  deletePaymentMethod (id: string): Promise<string> {
    return this.axios.$delete(`/stripe/paymentMethod/${id}`)
  }

  getProductAmount (productId?: string | AbbyPlans | StripeProductType, options?: QueryStripeAmount): Promise<ReadStripeCheckoutAmount> {
    return this.axios.$get(`/stripe/productAmount/${productId}`, {
      params: {
        ...(options?.frequency ? { frequency: options.frequency } : {}),
        ...(options?.abGroup ? { abGroup: options.abGroup } : {}),
        ...(options?.promoCode ? { promoCode: options.promoCode } : {}),
        ...(options?.options ? { options: options.options } : {}),
      },
    })
  }

  getRefuelSignatureUrl (): Promise<string> {
    return this.axios.$get('/stripe/refuelSignatureUrl')
  }
}
