import type { ReadRedirect } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Redirect extends Resource {
  create ({ slug, url }: { slug: string, url: string }): Promise<ReadRedirect> {
    return this.axios.$post('/redirect', { slug, url })
  }

  getAll (): Promise<Array<ReadRedirect>> {
    return this.axios.$get('/redirect/all')
  }
}
