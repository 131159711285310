export enum CompanyDocumentCreation {
  EXTRAIT_IMMATRICULATION_REGISTRE_ENTREPRISE = 'EXTRAIT_IMMATRICULATION_REGISTRE_ENTREPRISE',
  KBIS = 'KBIS',
  SIRENE_SITUATION = 'SIRENE_SITUATION',
  MEMENTO_FISCAL = 'MEMENTO_FISCAL',
  PASSWORD_IMPOTS_GOUV = 'PASSWORD_IMPOTS_GOUV',
  FORM_CFE = 'FORM_CFE',
  P_0 = 'P_0',
  INVOICE = 'INVOICE',
  IMMATRICULATION_REPERTOIRE_METIER = 'IMMATRICULATION_REPERTOIRE_METIER',
  ACRE_FORM = 'ACRE_FORM',
  DECLARATION_CFE_A_REMPLIR = 'DECLARATION_CFE_A_REMPLIR', // Déclarations scannées par un référent
  DECLARATION_CFE = 'DECLARATION_CFE', // Déclarations générées (Abby+)
  QUESTIONNAIRE_ACTIVITE_PRO = 'QUESTIONNAIRE_ACTIVITE_PRO', // Déclarations générées (Abby+)
  RECEPISSE_DEPOT_DOSSIER = 'RECEPISSE_DEPOT_DOSSIER', // Récépissé de dépôt de dossier (CMA en général)
  EXTRAIT_IMMATRICULATION_AGENT_COMMERCIAUX = 'EXTRAIT_IMMATRICULATION_AGENT_COMMERCIAUX', // agent commerciaux équivalent KBIS
  RECEPISSE_DEPOT_ACTES = 'RECEPISSE_DEPOT_ACTES',
  ATTESTATION_ACRE = 'ATTESTATION_ACRE',
  NOTIFICATION_AFFILIATION_SECU = 'NOTIFICATION_AFFILIATION_SECU',
  SYNTHESE_CREATION_INPI_LOI_PACTE = 'SYNTHESE_CREATION_INPI_LOI_PACTE',
}
