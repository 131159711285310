import type {
  CreateMessage,
  CreateTicket,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Intercom extends Resource {
  sendMessage (data: CreateMessage): Promise<boolean> {
    return this.axios.$post('/intercom/sendMessage', data)
  }

  sendTicket (data: CreateTicket): Promise<boolean> {
    return this.axios.$post('/intercom/sendTicket', data)
  }

  sendInappMessage (data: CreateTicket): Promise<boolean> {
    return this.axios.$post('/intercom/sendInappMessage', data)
  }

  retrieveUserHash (): Promise<{userHash: string}> {
    return this.axios.$get('/v2/intercom/identifier')
  }
}
