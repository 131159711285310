
import { Action, Component, Prop, Vue } from 'nuxt-property-decorator'
import draggable from 'vuedraggable'
import { RankTaskMode, ReadOpportunity, ReadTask, UpdateTask } from '@abby/core-legacy'
import TaskLine from '~/components-legacy/task/TaskLine.vue'
import TaskActionMenu from '~/components-legacy/task/TaskActionMenu.vue'

@Component({
  components: {
    draggable,
    TaskLine,
    TaskActionMenu,
  },
})
export default class TaskColumnList extends Vue {
  @Action('task/updateTask') updateTask!: (payload: UpdateTask & { id: string }) => Promise<void>;
  @Action('task/setTasks') setTasks!: (payload: ReadTask[]) => Promise<void>;

  @Prop({ required: false })
  items!: ReadTask[]

  @Prop({ required: false, default: false })
  hideDetails!: boolean | string;

  @Prop({ required: false, default: false })
  hideDescription!: boolean | string;

  @Prop({ required: false, default: false })
  disableInsert!: boolean | string;

  @Prop({ required: false, default: false })
  disableDuplicate!: boolean | string;

  @Prop({ required: false })
  rankMode?: RankTaskMode;

  @Prop({ required: false, default: false })
  disableMove!: boolean | string;

  @Prop({ required: false, default: false })
  titleClass!: string[];

  indexEdit: string | null = null;

  get _hideDetails () {
    return this.hideDetails || typeof this.hideDetails === 'string'
  }

  get _hideDescription () {
    return this.hideDescription || typeof this.hideDescription === 'string'
  }

  get computeDisableInsert () {
    return this.disableInsert || typeof this.disableInsert === 'string'
  }

  get computeDisableMove () {
    return this.disableMove || typeof this.disableMove === 'string'
  }

  get computeDisableDuplicate () {
    return this.disableDuplicate || typeof this.disableDuplicate === 'string'
  }

  get tasks () {
    return this.items
  }

  set tasks (value: ReadTask[]) {
    this.$emit('update:items', value)
  }

  onMovedElement ({ added, moved, removed }: {
    added?: { element: ReadOpportunity, newIndex: number },
    removed?: { element: ReadOpportunity, oldIndex: number },
    moved?: any
  }) {
    if (added) {
      this.$emit('added', added)
      return
    }

    if (removed) {
      this.$emit('removed', removed)
      return
    }

    if (moved && !this.computeDisableMove) {
      const afterRank = this.tasks[moved.newIndex + 1]?.rank || '0|zzzzzz:'
      this.updateTask({
        id: moved.element.id,
        ...(this.rankMode ? { rankMode: this.rankMode } : {}),
        afterRank,
      })
    }
  }

  menuX = 0
  menuY = 0
  showMenu = false
  showMenuTask: ReadTask | null = null

  openEdit (task: ReadTask) {
    this.indexEdit = task.id
    this.$busManager.emit('insertTask', task.id)
  }

  showFloatingMenu (task: ReadTask, event: any) {
    event.preventDefault()
    this.showMenuTask = task
    this.showMenu = false
    this.menuX = event.clientX
    this.menuY = event.clientY
    this.$nextTick(() => {
      this.showMenu = true
    })
  }

  closeFloatingMenu () {
    this.showMenu = false
  }

  insertTask (direction: string) {
    if (!this.showMenuTask) { return }
    const taskIndex = this.tasks.findIndex(t => t.id === this.showMenuTask!.id)
    this.$busManager.emit('insertNextTask', {
      id: this.tasks[taskIndex]?.id,
      direction,
    })
  }
}
