import { Plugin } from '@nuxt/types'

export interface DateParams {
  date: string | Date
}

export interface DateInstance {
  formatYYYYMMDD(value: string | Date): string ;
  formatDDMMYYYY(value: string | Date): string ;
  formatDDMMYYYYHmm(value: string | Date): string ;
  formatddddDMMMMYYYYHmm(value: string | Date): string ;
  formatHmm(value: string | Date): string ;
  formatDDMMMYYYY(value: string | Date): string ;
  formatMMMMYYYY(value: string | Date): string ;
  formatddddDMMMMYYYY(value: string | Date): string ;
  remainingFromTodayFormatD(value: string | Date): string ;
}

const DatePlugin: Plugin = ({ $dayjs }, inject) => {
  inject('date', {
    formatYYYYMMDD: (value: string | Date): string => {
      return $dayjs(value).format('YYYY-MM-DD')
    },
    formatDDMMYYYY: (value: string | Date): string => {
      return $dayjs(value).format('DD/MM/YYYY')
    },
    formatDDMMYYYYHmm: (value: string | Date): string => {
      return $dayjs(value).format('DD/MM/YYYY H:mm')
    },
    formatddddDMMMMYYYYHmm: (value: string | Date): string => {
      return $dayjs(value).format('dddd D MMMM YYYY H:mm')
    },
    formatHmm: (value: string | Date): string => {
      return $dayjs(value).format('H:mm')
    },
    formatDDMMMYYYY: (value: string | Date): string => {
      return $dayjs(value).format('DD MMM YYYY')
    },
    formatMMMMYYYY: (value: string | Date): string => {
      return $dayjs(value).format('MMMM YYYY')
    },
    formatddddDMMMMYYYY: (value: string | Date): string => {
      return $dayjs(value).format('dddd D MMMM YYYY')
    },
    remainingFromTodayFormatD: (value: string | Date): number => {
      return $dayjs(value).diff($dayjs().toString(), 'day', false)
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $date: DateInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $date: DateInstance
  }
  interface Context {
    $date: DateInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $date: DateInstance
  }
}

export default DatePlugin
