
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import AlertsManager from '~/components-legacy/layout/managers/AlertsManager.vue'
import AbbyPlansModal from '~/components-legacy/modals/commercialOffers/AbbyPlansModal.vue'

@Component({
  middleware: ['redirection', 'auth'],
  components: {
    AbbyPlansModal,
    AlertsManager,
  },
})
export default class empty extends Vue {
  @Getter('app/isContainerFullHeight')
  isContainerFullHeight!: boolean

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  head () {
    return this.$brandManager.head()
  }
}
