import { PlanFeature } from '@abby/core-legacy'
import { useProductRepository } from '~/logic/contexts/product/infrastructure/repository/product.repository'
import { ProductForm } from '~/logic/contexts/product/domain/form/product.form'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'
import { useToasterManager } from '~/composables/shared/manager/useToasterManager'

export const useCreateProduct = () => {
  const productRepository = useProductRepository()
  const planManager = usePlanManager()
  const toasterManager = useToasterManager()

  const createProduct = async (product: ProductForm) => {
    const productToCreate = { ...product }
    const newProduct = await productRepository.createProduct(productToCreate)
    toasterManager.success({ message: 'Le produit a bien été créé' })
    return newProduct
  }

  const handleError = (error: any) => {
    toasterManager.autoError(error)
    if (error?.response?.data?.message === 'catalog.product.too_many_products') {
      planManager.openPlansModal(PlanFeature.CREATE_PRODUCT)
    }
  }

  return async (product: ProductForm) => {
    try {
      return await createProduct(product)
    } catch (e) {
      handleError(e)
    }
  }
}
