
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import AbbyPlansModal from '~/components-legacy/modals/commercialOffers/AbbyPlansModal.vue'

@Component({
  middleware: ['redirection'],
  components: {
    AbbyPlansModal,
    AlertsManager: () => import('~/components-legacy/layout/managers/AlertsManager.vue'),
  },
})
export default class Auth extends Vue {
  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  head () {
    return this.$brandManager.head()
  }

  mounted () {
    // TODO: reactivate when help is ready
    // this.$help.init()
  }
}

