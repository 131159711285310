import { ActionTree, GetterTree, MutationTree } from 'vuex'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import type { ReadIncomeStatement } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface IncomeStatementPaginationQuery { range: [string, string] }

export interface AccountingState {
  incomeStatement: ReadIncomeStatement | null;
  paginationQuery: IncomeStatementPaginationQuery
  queryLoading: boolean;
}

export const state = (): AccountingState => ({
  incomeStatement: null,
  paginationQuery: {
    range: [`${dayjs().get('year')}-01-01`, dayjs().format('YYYY-MM-DD')],
  },
  queryLoading: false,
})

export const getters: GetterTree<AccountingState, RootState> = {
  incomeStatement: state => state.incomeStatement,
  paginationQuery: state => state.paginationQuery,
  queryLoading: state => state.queryLoading,
}

export const mutations: MutationTree<AccountingState> = {
  SET_INCOME_STATEMENT (state, value: any) {
    state.incomeStatement = value
  },
  SET_PAGINATION_QUERY (state, value: IncomeStatementPaginationQuery) {
    state.paginationQuery = value
  },
  SET_QUERY_LOADING (state, value: boolean) {
    state.queryLoading = value
  },
  RESET (_currentState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<AccountingState, RootState> = {
  async fetchIncomeStatement ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const result = await this.$api.accounting.getIncomeStatement(getters.paginationQuery)
      commit('SET_INCOME_STATEMENT', result)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  debounceFetchIncomeStatement: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchIncomeStatement')
  }, 300, { leading: true }),
  updatePaginationQuery ({ commit, dispatch }, query: Partial<IncomeStatementPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchIncomeStatement')
  },
}
