import { ClientType } from '~/logic/contexts/client/domain/enums/clientType.enum'
import { Customer } from '~/logic/contexts/billing/domain/entity/customer.entity'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'

export const useGoToClientPortal = () => {
  const billingRepository = useBillingRepository()

  return async (id: string, clientType: ClientType) => {
    let billingCustomer: Customer
    if (clientType === ClientType.ORGANIZATION) {
      billingCustomer = await billingRepository.fetchCustomerFromOrganization(id)
    } else {
      billingCustomer = await billingRepository.fetchCustomerFromContact(id)
    }
    window.open(`${process.env.baseAppUrl}/clientPortal?id=${billingCustomer.legacyId}&token=${billingCustomer.token}`, '_blank')
  }
}
