import { Currency, formatCents } from '@abby/core-legacy'

export class Amount {
    readonly cents: number;
    private constructor (value: number) {
      this.cents = value
    }

    static create (value: number) {
      return new Amount(value)
    }

    get text () {
      return formatCents(this.cents, Currency.EUR).text
    }
}
