
import { Component, Vue } from 'nuxt-property-decorator'
import { Alert } from '~/plugins/alertsManager'

@Component
export default class AlertsManager extends Vue {
    snackbar = true

    get marginTop () {
      return `margin-top: calc(${this.$vuetify.application.top}px + 0.5rem)`
    }

    get snack (): Alert | undefined {
      return this.$store.getters['alertsManager/snack']
    }

    get alerts (): Array<Alert> {
      return this.$store.getters['alertsManager/alerts']
    }

    removeAlert (alert: Alert) {
      this.$store.commit('alertsManager/removeAlert', alert)
    }

    removeSnack () {
      this.snackbar = false
      this.$store.commit('alertsManager/removeAlert', this.snack)
      setTimeout(() => {
        this.snackbar = true
      }, 300)
    }
}

