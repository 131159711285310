import { ClientPreferences, BankInformations, ClientAddress } from '@abby/shared'

export type ContactFormProps = {
  id?: string;
  firstname: string;
  lastname: string;
  emails?: string[];
  jobTitle?: string;
  phone?: string;
  billingAddress?: ClientAddress;
  deliveryAddress?: ClientAddress;
  bankInformations?: BankInformations;
  preferences?: ClientPreferences;
};

export class ContactForm {
  id?: string;
  firstname: string;
  lastname: string;
  emails?: string[];
  jobTitle?: string;
  phone?: string;
  notes?: string;
  billingAddress?: ClientAddress;
  deliveryAddress?: ClientAddress;
  bankInformations?: BankInformations;
  preferences?: ClientPreferences;

  private constructor (props: ContactFormProps) {
    this.id = props.id
    this.firstname = props.firstname
    this.lastname = props.lastname
    this.emails = props.emails
    this.jobTitle = props.jobTitle
    this.phone = props.phone
    this.billingAddress = props.billingAddress
    this.deliveryAddress = props.deliveryAddress
    this.bankInformations = props.bankInformations
    this.preferences = props.preferences
  }

  public static create (props: ContactFormProps): ContactForm {
    return new ContactForm(props)
  }
}
