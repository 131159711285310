export enum MainActivityPrecision {
  PRESTATION_DE_SERVICES = 8,
  COMMERCE_DE_DETAIL_EN_MAGASIN = 1,
  COMMERCE_DE_DETAIL_SUR_LE_MARCHE = 2,
  COMMERCE_DE_DETAIL_SUR_INTERNET = 3,
  FABRICATION_PRODUCTION = 4,
  BATIMENT_TRAVAUX_PUBLICS = 5,
  COMMERCE_DE_GROS = 6,
  AUTRE = 7,
}
