import { BillingType, BillingLocale } from '@abby/shared'
import { BillingAction } from '~/logic/contexts/billing/domain/enum/billingAction.enum'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useCopyBillingCustomerPortalLink } from '~/composables/billing/action/useCopyBillingCustomerPortalLink'
import { useOpenBillingFrequencyModal } from '~/composables/billing/action/useOpenBillingFrequencyModal'
import { useMarkAsNotSigned } from '~/composables/billing/action/useMarkAsNotSigned'
import { useMarkAsSigned } from '~/composables/billing/action/useMarkAsSigned'
import { useActivateOnlineSignature } from '~/composables/billing/action/useActivateOnlineSignature'
import { useArchiveBilling } from '~/composables/billing/action/useArchiveBilling'
import { useUnarchiveBilling } from '~/composables/billing/action/useUnarchiveBilling'
import { useOpenBillingDetailPanel } from '~/composables/billing/action/useOpenBillingDetailPanel'
import { useOpenBillingEditionModal } from '~/composables/billing/action/useOpenBillingEditionModal'
import { useDownloadBilling } from '~/composables/billing/action/useDownloadBilling'
import { useOpenSendBillingByEmailModal } from '~/composables/billing/useOpenSendBillingByEmailModal'
import { useOpenBillingUnpaidModal } from '~/composables/billing/action/useOpenBillingUnpaidModal'
import { useOpenBillingMarkAsPaidModal } from '~/composables/billing/action/useOpenBillingMarkAsPaidModal'
import { useMarkAsUnpaid } from '~/composables/billing/action/useMarkAsUnpaid'
import { useMarkAsRefused } from '~/composables/billing/action/useMarkAsRefused'
import { useMarkAsNotRefused } from '~/composables/billing/action/useMarkAsNotRefused'
import { useMarkAsFinalized } from '~/composables/billing/action/useMarkAsFinalized'
import { useMarkAsFinalizedAndSend } from '~/composables/billing/action/useMarkAsFinalizedAndSend'
import { useOpenDuplicateBillingModal } from '~/composables/billing/action/useOpenDuplicateBillingModal'
import { useOpenBillingTitleModal } from '~/composables/billing/action/useOpenBillingTitleModal'
import { useOpenFinalInvoiceCreationModal } from '~/composables/billing/action/useOpenFinalInvoiceCreationModal'
import { useRemoveBilling } from '~/composables/billing/action/useRemoveBilling'
import { useOpenAdvanceCreationModal } from '~/composables/billing/action/useOpenAdvanceCreationModal'
import { useOpenAssetCreationModal } from '~/composables/billing/action/useOpenAssetCreationModal'
import { useDownloadSignatureProof } from '~/composables/billing/action/useDownloadSignatureProof'
import { useOpenBillingReminderModal } from '~/composables/billing/action/useOpenBillingReminderModal'
import { useOpenCancelBillingModal } from '~/composables/billing/action/useOpenCancelBillingModal'
import { useActivateOnlinePayment } from '~/composables/billing/action/useActivateOnlinePayment'
import { useOpenOpportunityPanel } from '~/composables/billing/action/useOpenOpportunityPanel'
import { useCopyBillingId } from '~/composables/billing/admin/useCopyBillingId'
import { useCopyBillingConfigurationId } from '~/composables/billing/admin/useCopyBillingConfigurationId'
import { useUnlinkBilling } from '~/composables/billing/admin/useUnlinkBilling'
import { useRemoveBillingAdmin } from '~/composables/billing/admin/useRemoveBillingAdmin'
import { useCopyTiersPrestationError } from '~/composables/billing/admin/useCopyTiersPrestationError'
import { useRegenerateBilling } from '~/composables/billing/admin/useRegenerateBilling'
import { useFixCustomerAddress } from '~/composables/billing/admin/useFixCustomerAddress'
import { useOpenContactDetailsSidePanel } from '~/composables/client/contact/useOpenContactDetailsSidePanel'
import {
  useOpenOrganizationDetailsSidePanel,
} from '~/composables/client/organization/useOpenOrganizationDetailsSidePanel'
import { useFetchBillingUrssafSyncStatus } from '~/composables/billing/action/useFetchBillingUrssafSyncStatus'

import { useBillingResolveNumber } from '~/composables/billing/admin/useBillingResolveNumber'

export const useHandleBillingAction = () => {
  const alertManager = useAlertManager()
  const openBillingDetailPanel = useOpenBillingDetailPanel()

  const copyBillingCustomerPortalLink = useCopyBillingCustomerPortalLink()
  const openBillingFrequencyModal = useOpenBillingFrequencyModal()
  const markAsNotSigned = useMarkAsNotSigned()
  const markAsSigned = useMarkAsSigned()
  const activateOnlineSignature = useActivateOnlineSignature()
  const archiveBilling = useArchiveBilling()
  const unarchiveBilling = useUnarchiveBilling()
  const openBillingEditionModal = useOpenBillingEditionModal()
  const downloadBilling = useDownloadBilling()
  const openSendBillingByEmailModal = useOpenSendBillingByEmailModal()
  const openBillingUnpaidModal = useOpenBillingUnpaidModal()
  const openBillingMarkAsPaidModal = useOpenBillingMarkAsPaidModal()
  const markAsUnpaid = useMarkAsUnpaid()
  const markAsRefused = useMarkAsRefused()
  const markAsNotRefused = useMarkAsNotRefused()
  const markAsFinalized = useMarkAsFinalized()
  const markAsFinalizedAndSend = useMarkAsFinalizedAndSend()
  const openDuplicateBillingModal = useOpenDuplicateBillingModal()
  const openBillingTitleModal = useOpenBillingTitleModal()
  const openFinalInvoiceCreationModal = useOpenFinalInvoiceCreationModal()
  const removeBilling = useRemoveBilling()
  const openAdvanceCreationModal = useOpenAdvanceCreationModal()
  const openAssetCreationModal = useOpenAssetCreationModal()
  const downloadSignatureProof = useDownloadSignatureProof()
  const openBillingReminderModal = useOpenBillingReminderModal()
  const openCancelBillingModal = useOpenCancelBillingModal()
  const activateOnlinePayment = useActivateOnlinePayment()
  const openOpportunityPanel = useOpenOpportunityPanel()
  const copyBillingId = useCopyBillingId()
  const copyBillingConfigurationId = useCopyBillingConfigurationId()
  const unlinkBilling = useUnlinkBilling()
  const removeBillingAdmin = useRemoveBillingAdmin()
  const copyTiersPrestationError = useCopyTiersPrestationError()
  const regenerateBilling = useRegenerateBilling()
  const fixCustomerAddress = useFixCustomerAddress()
  const openContactDetailsSidePanel = useOpenContactDetailsSidePanel()
  const openOrganizationDetailsSidePanel = useOpenOrganizationDetailsSidePanel()
  const fetchBillingUrssafSyncStatus = useFetchBillingUrssafSyncStatus()
  const resolveNumber = useBillingResolveNumber()

  const actions: {
    [key in BillingAction]: {
      run: (billing: BillingItem, options?: any) => Promise<any> | any;
    }
  } = {
    [BillingAction.GET_SIGNATURE_LINK]: {
      run: billing => copyBillingCustomerPortalLink(billing),
    },
    [BillingAction.MANAGE_RECURRING]: {
      run: billing => openBillingFrequencyModal(billing),
    },
    [BillingAction.GET_PAYMENT_LINK]: {
      run: billing => copyBillingCustomerPortalLink(billing),
    },
    [BillingAction.MARK_AS_NOT_SIGNED]: {
      run: billing => markAsNotSigned(billing),
    },
    [BillingAction.MARK_AS_SIGNED]: {
      run: billing => markAsSigned(billing),
    },
    [BillingAction.ACTIVATE_ONLINE_SIGNATURE]: {
      run: billing => activateOnlineSignature(billing),
    },
    [BillingAction.ARCHIVE]: {
      run: billing => archiveBilling(billing),
    },
    [BillingAction.UNARCHIVE]: {
      run: billing => unarchiveBilling(billing),
    },
    [BillingAction.VIEW_DETAILS]: {
      run: billing => openBillingDetailPanel(billing.id),
    },
    [BillingAction.EDIT]: {
      run: billing => openBillingEditionModal(billing),
    },
    [BillingAction.DOWNLOAD]: {
      run: billing => downloadBilling(billing),
    },
    [BillingAction.SEND]: {
      run: billing => openSendBillingByEmailModal(billing),
    },
    [BillingAction.UNPAID_BILLING]: {
      run: billing => openBillingUnpaidModal(billing),
    },
    [BillingAction.MARK_AS_PAID]: {
      run: billing => openBillingMarkAsPaidModal({ billingItem: billing }),
    },
    [BillingAction.MARK_AS_PAID_ASSET]: { // only for asset
      run: billing => openBillingMarkAsPaidModal({ billingItem: billing }),
    },
    [BillingAction.MARK_AS_UNPAID]: {
      run: billing => markAsUnpaid(billing),
    },
    [BillingAction.MARK_AS_REFUSED]: {
      run: billing => markAsRefused(billing),
    },
    [BillingAction.MARK_AS_NOT_REFUSED]: {
      run: billing => markAsNotRefused(billing),
    },
    [BillingAction.FINALIZE]: {
      run: billing => markAsFinalized(billing),
    },
    [BillingAction.FINALIZE_AND_SEND]: {
      run: billing => markAsFinalizedAndSend(billing),
    },
    [BillingAction.DUPLICATE_TO_INVOICE]: {
      run: billing => openDuplicateBillingModal(billing, BillingType.INVOICE),
    },
    [BillingAction.DUPLICATE_TO_ESTIMATE]: {
      run: billing => openDuplicateBillingModal(billing, BillingType.ESTIMATE),
    },
    [BillingAction.DUPLICATE_TO_PURCHASE_ORDER]: {
      run: billing => openDuplicateBillingModal(billing, BillingType.PURCHASE_ORDER),
    },
    [BillingAction.EDIT_TITLE]: {
      run: billing => openBillingTitleModal(billing),
    },
    [BillingAction.CREATE_FINAL_INVOICE]: {
      run: billing => openFinalInvoiceCreationModal(billing),
    },
    [BillingAction.DELETE]: {
      run: billing => removeBilling(billing),
    },
    [BillingAction.VIEW_CONTACT]: {
      run: async (billing) => {
        if (billing.customer.contactId) {
          await openContactDetailsSidePanel(billing.customer.contactId)
        }
      },
    },
    [BillingAction.VIEW_ORGANIZATION]: {
      run: async (billing) => {
        if (billing.customer.organizationId) {
          await openOrganizationDetailsSidePanel(billing.customer.organizationId)
        }
      },
    },
    [BillingAction.CREATE_ADVANCE]: {
      run: billing => openAdvanceCreationModal(billing),
    },
    [BillingAction.CREATE_ASSET]: {
      run: billing => openAssetCreationModal(billing),
    },
    [BillingAction.DOWNLOAD_SIGNATURE_PROOF]: {
      run: billing => downloadSignatureProof(billing),
    },
    [BillingAction.MANAGE_REMINDER]: {
      run: billing => openBillingReminderModal(billing),
    },
    [BillingAction.CANCEL]: {
      run: billing => openCancelBillingModal(billing),
    },
    [BillingAction.ACTIVATE_ONLINE_PAYMENT]: {
      run: billing => activateOnlinePayment(billing),
    },
    [BillingAction.CHECK_URSSAF_TP_STATUT]: {
      run: billing => fetchBillingUrssafSyncStatus(billing),
    },
    [BillingAction.DOWNLOAD_FRENCH_LOCALE]: {
      // @ts-ignore
      run: billing => downloadBilling(billing, { locale: BillingLocale.FR }),
    },
    [BillingAction.VIEW_OPPORTUNITY]: {
      run: billing => openOpportunityPanel(billing),
    },
    // ADMIN ACTIONS
    [BillingAction.ADMIN_COPY_DOCUMENT_ID]: {
      run: billing => copyBillingId(billing),
    },
    [BillingAction.ADMIN_COPY_BILLING_CONFIGURATION_ID]: {
      run: (_: BillingItem) => copyBillingConfigurationId(),
    },
    [BillingAction.ADMIN_UNLINK_FROM_CREATE_FROM]: {
      run: billing => unlinkBilling(billing),
    },
    [BillingAction.ADMIN_DELETE_DOCUMENT]: {
      run: billing => removeBillingAdmin(billing),
    },
    [BillingAction.ADMIN_COPY_ISSUE_PARTICULIER_INCONNU]: {
      run: billing => copyTiersPrestationError(billing),
    },
    [BillingAction.ADMIN_REGENERATE_DOCUMENT]: {
      run: billing => regenerateBilling(billing),
    },
    [BillingAction.ADMIN_FIX_CUSTOMER_ADDRESS]: {
      run: billing => fixCustomerAddress(billing),
    },
    [BillingAction.ADMIN_RESOLVE_NUMBER]: {
      run: billing => resolveNumber(billing),
    },
  }

  return async ({ action, billing, options }: { action: BillingAction, billing: BillingItem, options?: any }) => {
    try {
      await actions[action].run(billing, options)
    } catch (error) {
      alertManager.autoError(error)
    }
  }

}
