import { ActionTree, GetterTree } from 'vuex'
import type { IProduct } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export enum ProductDetailsTabs {
  INFORMATION,
}

export interface ProductDetailsState {
  product: IProduct | null;
  openedProductDetails: Array<{ product: IProduct }>
}

export const state = (): ProductDetailsState => ({
  product: null,
  openedProductDetails: [],
})

export const getters: GetterTree<ProductDetailsState, RootState> = {
  openedProductDetails: state => state.openedProductDetails,
  product: state => state.product,
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.openedProductDetails.slice(-1)?.[0]?.product._id === id,
}

export const mutations = {
  SET_OPENED_PRODUCTS (state: ProductDetailsState, payload: Array<{ product: IProduct }>) {
    state.openedProductDetails = payload
  },
  SPLICE_OPENED_PRODUCTS (state: ProductDetailsState, index: number) {
    state.openedProductDetails.splice(index)
  },
  RESET (_currentState: ProductDetailsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  openProductDetails ({ commit, getters }, product: IProduct) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(product._id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('SET_OPENED_PRODUCTS', [
      ...getters.openedProductDetails,
      { product },
    ])
  },
  cleanProductDetailsConfig ({ commit, getters }) {
    commit('SET_OPENED_PRODUCTS', getters.openedProductDetails.map(({ product }: { product: IProduct }) => ({ product })))
  },
  closeProductDetails ({ commit }, index: number) {
    commit('SPLICE_OPENED_PRODUCTS', index)
  },
}
