
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { confetti } from 'dom-confetti'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'

@Component({
  components: { ASDialog },
})
export default class HomeGuideModal extends Vue {
  @ModelSync('value', 'input', {
    default: () => false,
  })
  opened!: boolean

  scrolled: boolean = false
  scrolledSize: number = 0
  homeGuideModalId: string = ''

  @Prop()
  imageSrc!: string

  @Prop()
  title!: string

  @Prop()
  subtitle!: string

  explodeConfettis () {
    // @ts-ignore
    confetti(document.getElementById(`home-guide-modal-__${this.homeGuideModalId}`), {
      elementCount: 150,
      angle: 45,
      startVelocity: 60,
      duration: 4000,
    })
  }

  get titleFontSize () {
    if (this.scrolledSize <= 0) { return 24 } else if (this.scrolledSize > 27) { return 20 }
    const y = 24 - this.scrolledSize * (4 / 27)
    return y
  }

  makeid () {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() *
          charactersLength))
    }
    return result
  }

  close () {
    this.$emit('close')
  }

  testIfScrolled () {
    const doc = document.getElementById(`home-guide-modal-scroll__${this.homeGuideModalId}`)
    this.scrolled = (doc?.scrollTop || 0) >= 27
    this.scrolledSize = doc?.scrollTop || 0
  }

  mounted () {
    this.homeGuideModalId = this.makeid()
    document.addEventListener('scroll', this.testIfScrolled, true)
    document.addEventListener('wheel', this.testIfScrolled, true)
    document.addEventListener('touchmove', this.testIfScrolled, true)
  }

  beforeDestroy () {
    document.removeEventListener('scroll', this.testIfScrolled, true)
    document.removeEventListener('wheel', this.testIfScrolled, true)
    document.removeEventListener('touchmove', this.testIfScrolled, true)
    this.$help.toggleChat(false, { open: false })
  }
}

