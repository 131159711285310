import { BillingLocale, ProductType, ProductUnit } from '../../../enums';

export interface UpdateDraftEstimate {
  id: string;
  emittedAt: number;
  expiredAt: number;
  deliveredAt?: number;
  locale: BillingLocale;
  lines?: Line[];
  discount?: Discount;
  withElectronicSignature: boolean;
  title?: string
  customerId: string;
  customerCurrencyCode?: string;
  displaySettings: {
    displayAbbyLogo: boolean;
    displayEmail: boolean;
    displayFullName: boolean;
    displayLegalStatus: boolean;
    displayMemberOfAnApprovedAssociation: boolean;
    displayPhoneNumber: boolean;
    displayRequiredMentionsProduct: boolean;
    displaySAPAgreement: boolean;
    displayGoodForApproval: boolean;
  },
  billingLegals: {
    headerNote?: string;
    footerNote?: string;
    generalTermsAndConditionsOfSale?: string;
    vatMention?: string;
    paymentMethods?: string[];
  }
}

interface Line {
  id?: string;
  quantity: number;
  quantityUnit: ProductUnit;
  unitPrice: number;
  type: ProductType;
  designation: string;
  reference?: string;
  description?: string;
  vatPercentage?: number;
  discount?: Discount;
}

interface Discount {
  mode: DiscountMode;
  amount: number;
}

enum DiscountMode {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}