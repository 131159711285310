import type {
  CreateAccountingAccount,
  UpdateAccountingAccount,
  ReadAccountingAccount,
  DeleteAccountingAccount,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class AccountingAccounts extends Resource {
  getAll (query?: any): Promise<Array<any>> {
    return this.axios.$get('/accountingAccounts', { params: query })
  }

  create (payload: CreateAccountingAccount): Promise<ReadAccountingAccount> {
    return this.axios.$post('/accountingAccounts', payload)
  }

  update (payload: UpdateAccountingAccount): Promise<ReadAccountingAccount> {
    return this.axios.$put('/accountingAccounts', payload)
  }

  delete (payload: DeleteAccountingAccount) {
    return this.axios.$delete('/accountingAccounts', {
      data: payload,
    })
  }

  countEntries (id: string) {
    return this.axios.$get(`/accountingAccounts/count/${id}`)
  }
}
