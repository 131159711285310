import { BridgeStatus } from '../../types';

export const bridgeErrors: {
  [K in BridgeStatus]?: {
    [T in string]: {
      text: string
    }
  }
} = {
  [-2]: {
    '': {
      text: 'Une mise à jour de votre connexion est en cours chez notre partenaire, merci de patienter quelques instants',
    },
  },
  [-3]: {
    '': {
      text: 'Une mise à jour de votre connexion est en cours chez notre partenaire, merci de patienter quelques instants',
    },
  },
  402: {
    INVALID_CREDS: {
      text: 'Les identifiants bancaires fournis ne nous permettent pas de synchroniser vos comptes. Veuillez les modifier (attention aux fautes de frappe).',
    },
    WRONG_ID: {
      text: 'L\'identifiant bancaire entré est erroné. Veuillez le modifier (attention aux fautes de frappe).',
    },
    WRONG_ID_FORMAT: {
      text: 'L\'identifiant bancaire n\'est pas au bon format. Veuillez le modifier (attention aux fautes de frappe).',
    },
    WRONG_PWD: {
      text: 'Le mot de passe bancaire entré est erroné. Veuillez le modifier (attention aux fautes de frappe).',
    },
    WRONG_PWD_FORMAT: {
      text: 'Le mot de passe bancaire entré n\'est pas au bon format. Veuillez le modifier (attention aux fautes de frappe).',
    },
    WRONG_PWD2: {
      text: 'Le second champ secret entré est erroné. Veuillez le modifier (attention aux fautes de frappe).',
    },
    WRONG_PWD2_FORMAT: {
      text: 'Le second champ secret n\'est pas au bon format. Veuillez le modifier (attention aux fautes de frappe).',
    },
    ACCOUNT_TEMPORARILY_BLOCKED: {
      text: 'Votre accès semble temporairement bloqué. Veuillez ré-essayer plus tard.',
    },
    ACCOUNT_BLOCKED: {
      text: 'Votre accès semble bloqué. Vous pouvez contacter votre conseiller bancaire pour plus d\'informations.',
    },
    ACCOUNT_BLOCKED_CONTACT_BANK: {
      text: 'Votre accès semble bloqué. Vous pouvez contacter votre conseiller bancaire pour plus d\'informations.',
    },
  },
  429: {
    TOS_TO_VALIDATE: {
      text: 'Vous devez valider les CGUs sur le site ou l\'app de votre banque.',
    },
    CONTRACT_TO_SIGN: {
      text: 'Vous devez signer un contrat sur le site ou l\'app de votre banque.',
    },
    INACTIVE_CREDS: {
      text: 'Vous devez activer vos identifiants en vous connectant au site ou à l\'app de votre banque.',
    },
    ACCOUNT_TO_ACTIVATE: {
      text: 'Vous devez activer votre compte en vous connectant au site ou à l\'app de votre banque.',
    },
    PHONE_NB_TO_CONFIRM: {
      text: 'Vous devez confirmer votre numéro de téléphone sur le site ou l\'app de votre banque.',
    },
    INFORMATION_TO_REVIEW: {
      text: 'Vous devez valider des informations sur le site ou l\'app de votre banque.',
    },
    SMTH_TO_VALIDATE: {
      text: 'Vous devez valider un message sur le site ou l\'app de votre banque.',
    },
    OTP_REGISTRATION_TO_DO: {
      text: 'Vous devez renseigner des informations pour vous authentifier sur le site ou l\'app de votre banque.',
    },
    KYC_TO_FILL: {
      text: 'Vous devez compléter un formulaire de données personnelles sur le site ou l\'app de votre banque.',
    },
    AMLCFT_DATA_TO_CONFIRM: {
      text: 'Vous devez confirmer des données personnelles sur le site ou l\'app de votre banque.',
    },
    PHONE_NB_TO_SET: {
      text: 'Vous devez enregistrer votre numéro de téléphone sur le site ou l\'app de votre banque.',
    },
    PERSO_DATA_TO_CONFIRM: {
      text: 'Vous devez confirmer et / ou modifier vos coordonnées sur le site ou l\'app de votre banque.',
    },
    FORM_TO_FILL: {
      text: 'Vous devez remplir un formulaire sur le site ou l\'app de votre banque.',
    },
    READONLY_ACCESS_TO_CREATE: {
      text: 'Vous devez créer un compte en lecture seule depuis le site de votre banque. Notre support est là pour vous aider.',
    },
    UNLOCK_OTP_IN_APP: {
      text: 'Votre authentification forte a été verrouillée (trop d\'essais infructueux). Veuillez la réactiver sur l\'app de votre banque.',
    },
    UNLOCK_OTP_IN_WEB: {
      text: 'Votre authentification forte a été verrouillée (trop d\'essais infructueux). Veuillez la réactiver sur le site de votre banque.',
    },
    CANNOT_ACCESS_SERVICES: {
      text: 'Votre banque ne vous permet pas d\'accéder aux services en ligne, nous ne pouvons donc pas synchroniser votre compte. ',
    },
    NO_ONLINE_ACCESS: {
      text: 'Votre banque ne vous donne accès à aucun compte en ligne, nous ne pouvons donc pas synchroniser votre compte. ',
    },
    CREDS_UPDATE_MESSAGE: {
      text: 'Vos identifiants de connexion évoluent. Consultez le site ou l\'app de votre banque pour en savoir plus.',
    },
    CONTACT_BANK: {
      text: 'Vous devez contacter le Service Clients de votre banque pour que les synchronisations reprennent.',
    },
    EXPIRED_ID_CONTACT_BANK: {
      text: 'Vos identifiants ont expiré. Veuillez contacter votre banque pour les renouveller. ',
    },
    NO_ACCESS_CONTACT_BANK: {
      text: 'L\'accès à vos comptes est impossible pour l\'instant. Merci de contacter votre banque pour rétablir l\'accès.',
    },
    INACTIVE_CREDS_CONTACT_BANK: {
      text: 'Vos identifiants ne sont pas actifs. Vous devez contacter le Service Clients de votre banque pour les activer. ',
    },
    PWD_RENEWAL: {
      text: 'Votre banque vous demande de changer votre mot de passe. Il faut le faire depuis l\'app ou le site de votre banque.',
    },
    PWD_RENEWAL_FIRST_CO: {
      text: 'Vous devez vous connecter une première fois sur le site ou l\'app de votre banque pour créer votre mot de passe bancaire. ',
    },
    PWD_RENEWAL_READONLY_ACCESS: {
      text: 'Connectez-vous sur le site de votre banque avec votre compte en lecture seule pour changer le mot de passe de ce compte.',
    },
  },
  1003: {
    '': {
      text: 'Nous rencontrons un problème de synchronisation avec votre banque, nous travaillons sur un correctif. Veuillez ré-essayer demain.',
    },
    INVALID_CREDS: {
      text: 'Les identifiants ne permettent pas de synchroniser vos comptes. Veuillez les modifier (attention aux fautes de frappe).',
    },
    CNT_CRASH: {
      text: 'Nous rencontrons un problème de synchronisation avec votre banque, nous travaillons sur un correctif. Veuillez ré-essayer demain.',
    },
    CRASH_AFTER_LOGIN: {
      text: 'Nous rencontrons un problème de synchronisation avec votre banque, nous travaillons sur un correctif. Veuillez ré-essayer demain.',
    },
    NO_ACCOUNT_FOUND: {
      text: 'Vos identifiants ne nous permettent pas d\'accéder aux détails de vos comptes. Veuillez ré-essayer avec des identifiants ayant des droits suffisants.',
    },
    BANK_IS_DOWN: {
      text: 'L\'interface de votre banque ne fonctionne pas pour le moment. Veuillez ré-essayer demain.',
    },
    OTP_PARTIAL: {
      text: 'Nous rencontrons un problème de synchronisation avec votre banque, nous travaillons sur un correctif. Veuillez ré-essayer demain.',
    },
  },
  1005: {
    OTP_NOT_SUPPORTED: {
      text: 'L\'authentification forte mise en place par votre banque n\'est pas encore supportée.',
    },
    PRO_NOT_SUPPORTED: {
      text: 'Nous avons détecté un compte non supporté.',
    },
    PART_IN_PRO_CNT: {
      text: 'Nous avons détecté un compte non supporté.',
    },
    WRONG_COUNTRY_OR_AREA: {
      text: 'Vos identifiants bancaires ne correspondent pas à la banque choisie. Veuillez ré-essayer en sélectionnant une autre banque.',
    },
    MIGRATION_TO_NEW_BANK: {
      text: 'Suite à un changement de votre banque, nous sommes entrain de migrer vos comptes. Merci de votre patience.',
    },
    NO_ACCESS_TO_ACCOUNT: {
      text: 'Vos identifiants ne nous permettent pas d\'accéder aux détails de vos comptes. Veuillez ré-essayer avec des identifiants ayant des droits suffisants.',
    },
    SUSPENDED_ACCESS: {
      text: 'Votre compte bancaire semble suspendu, veuillez contacter votre banque pour avoir plus d\'informations.',
    },
    CLOSED_ACCOUNT: {
      text: 'Votre compte bancaire semble clôturé, veuillez contacter votre banque pour avoir plus d\'informations.',
    },
    NO_ONLINE_ACCESS: {
      text: 'Vos identifiants ne nous permettent pas d\'accéder aux détails de vos comptes. Veuillez ré-essayer avec des identifiants ayant des droits suffisants.',
    },
  },
  1010: {
    OTP_REQUIRED: {
      text: 'Veuillez renseigner l\'authentification forte (SMS, notifications, etc) fournie par votre banque',
    },
    OTP_FAILED: {
      text: 'L\'authentification forte a échoué à cause d\'un soucis technique, merci de recommencer.',
    },
    WRONG_OTP: {
      text: 'L\'authentification forte renseignée est erronée, merci de recommencer (attention aux fautes de frappe).',
    },
    EXPIRED_DEVICE: {
      text: 'L\'authentification forte a échoué car vous n\'avez pas entré le code assez vite. Merci de recommencer.',
    },
    INVALID_PHONE_NB: {
      text: 'L\'authentification forte a échoué car le numéro de téléphone renseigné semble incorrect.',
    },
    BLOCKED_LIMIT_REACHED: {
      text: 'L\'authentification forte est temporairement bloquée à cause d\'un trop grand nombre d\'essais. Merci de recommencer dans quelques minutes.',
    },
    BLOCKED_SMS_LIMIT_REACHED: {
      text: 'L\'authentification forte est temporairement bloquée à cause d\'un trop grand nombre d\'essais. Merci de recommencer dans quelques minutes.',
    },
    OTP_NOT_SUPPORTED: {
      text: 'Votre banque a mis en place l\'authentification forte, nous travaillons actuellement dessus pour que vous puissiez rétablir la connexion.',
    },
  },
  1100: {
    '': {
      text: 'Vous devez vérifier votre compte professionnel. Merci de recommencer.',
    },
  },
};
