import { GetterTree, ActionTree, MutationTree } from 'vuex'
import type {
  CreateInvitationSponsorship,
  CreateSponsorship, CreateSponsorshipBalance,
  IReadSponsorship,
  QuerySponsorship, ReadSponsorships,
} from '@abby/core-legacy'

export interface SponsorshipState {
  godSons: ReadSponsorships | null;
  paginationQuery: QuerySponsorship;
  amountToWin: number,
  amountWon: number,
  amountWithdrew: number,
}

export const state = (): SponsorshipState => ({
  godSons: null,
  paginationQuery: {
    page: 1,
    limit: 10,
  },
  amountToWin: 0,
  amountWon: 0,
  amountWithdrew: 0,
})

export const getters: GetterTree<SponsorshipState, SponsorshipState> = {
  godSons: state => state.godSons,
  amountToWin: state => state.amountToWin,
  amountWon: state => state.amountWon,
  amountWithdrew: state => state.amountWithdrew,
  paginationQuery: state => state.paginationQuery,
}

export const mutations: MutationTree<SponsorshipState> = {
  SET_GOD_SONS (state, godSons: ReadSponsorships) {
    state.godSons = godSons
  },
  UPDATE_GOD_SON (state: SponsorshipState, godSon: Partial<IReadSponsorship>): void {
    if (!godSon.id) { return }
    const index = state.godSons?.data?.findIndex(gs => gs.godSons.id === godSon.id)
    delete godSon.id
    if (index === -1 || index === undefined) { return }
    state.godSons?.data!.splice(index, 1, {
      ...state.godSons?.data[index],
      ...godSon,
    })
  },
  REMOVE_GOD_SON (state: SponsorshipState, id: string) {
    if (!state.godSons) { return }
    const index = state.godSons.data.findIndex(godSon => godSon.godSons.id === id)
    if (index === -1) { return }
    state.godSons.data.splice(index, 1)
  },
  RESET (_currentState: SponsorshipState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_PAGINATION_QUERY (state: SponsorshipState, paginationQuery: QuerySponsorship) {
    state.paginationQuery = paginationQuery
  },
  SET_AMOUNT_TO_WIN (state: SponsorshipState, amount: number) {
    state.amountToWin = amount
  },
  SET_AMOUNT_WON (state: SponsorshipState, amount: number) {
    state.amountWon = amount
  },
  SET_AMOUNT_WITHDREW (state: SponsorshipState, amount: number) {
    state.amountWithdrew = amount
  },
}

export const actions: ActionTree<SponsorshipState, SponsorshipState> = {
  async fetchGodSons ({ state, commit, dispatch }) {
    const result = await this.$api.sponsorship.getAll(state.paginationQuery)
    commit('SET_GOD_SONS', result)
    dispatch('getTotalsAmounts')
  },
  async sendNotificationToGoSon ({ commit }, godSon: IReadSponsorship) {
    if (!godSon.id) { return }
    await this.$api.sponsorship.notify(godSon.id)
    commit('UPDATE_GOD_SON', { id: godSon.id, isAlreadyRemindByEmail: true })
  },
  async sendInviteByEmail (_, emails: CreateInvitationSponsorship[]) {
    await this.$api.sponsorship.invite(emails)
  },
  async createAndSendInvitations ({ commit, dispatch }, emails: CreateInvitationSponsorship[]) {
    const result = await this.$api.sponsorship.create(emails)
    commit('SET_GOD_SONS', result)
    dispatch('getTotalsAmounts')
  },
  async removeSponsorship ({ commit, dispatch }, id: string) {
    await this.$api.sponsorship.delete(id)
    commit('REMOVE_GOD_SON', id)
    dispatch('getTotalsAmounts')
  },
  async update (_, payload: CreateSponsorship) {
    return await this.$api.sponsorship.update(payload)
  },
  setPaginationQuery ({ commit }, params: QuerySponsorship) {
    commit('SET_PAGINATION_QUERY', params)
  },
  async getTotalsAmounts ({ commit }) {
    const amount = await this.$api.sponsorship.getTotalsAmounts()
    commit('SET_AMOUNT_TO_WIN', amount?.toWin)
    commit('SET_AMOUNT_WON', amount?.won)
    commit('SET_AMOUNT_WITHDREW', amount?.withdrew)
  },
  async createBalanceLine (_, payload: CreateSponsorshipBalance) {
    await this.$api.sponsorship.createBalance(payload)
  },
}
