import { GuideLevelItem, GuideStepItem, PersonaFeature } from '@abby/core-legacy'
import { BillingCycle } from '@abby/core-legacy/src'

export const configBuilderGroupA = (getters: any, rootGetters: any, xpm: any) => [
  {
    title: 'Faites connaissance avec Abby',
    level: GuideLevelItem.MEET_ABBY,
    duration: 'Moins d\'une min',
    iconUrl: '/onboarding/guide/levels/meet.png',
    steps: [
      {
        step: GuideStepItem.MEET_ABBY,
        title: 'Découvrez Abby en 1 minute',
        content: 'Bienvenue sur Abby, un outil 100% conforme qui regroupe tout ce dont tu as besoin pour gérer votre entreprise et réussir.<br><br> Premièrement, regardez cette courte vidéo qui va vous donner un aperçu rapide des étapes à suivre pour vous aider à comprendre et vous installer rapidement sur Abby.',
        iconUrl: '',
        imageUrl: '/discover-abby.png',
        imageBoxShadow: true,
        videoUrl: 'https://vimeo.com/810222535/fdb6f7f29d',
        action: {
          text: 'C\'est parti !',
          icon: 'play_circle',
          iconDirection: 'left',
        },
      },
    ],
  },
  {
    title: 'Facturez en quelques clics',
    duration: 'Environ 8 min',
    level: GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY,
    iconUrl: '/onboarding/guide/levels/facturation.png',
    steps: [
      {
        step: GuideStepItem.CUSTOM_YOUR_THEME,
        title: 'Personnalisez le style de vos documents',
        content: 'L’image de votre entreprise est importante. Avoir des beaux documents à leur envoyer est nécessaire. Avec notre outil de facturation, vous pouvez configurer vos documents à votre image.<br><br> Vous allez pouvoir y mettre votre logo, y mettre votre couleur, y ajouter un fond et y faire figurer vos réseaux sociaux.',
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/theme-document.png',
        videoUrl: '',
        action: {
          text: 'Personnaliser mes documents',
          icon: 'palette',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.CONFIGURE_YOUR_NUMBERING_FORMAT,
        title: 'Configurez votre format de numérotation',
        content: 'Avoir un format et une suite chronologique dans vos numéros de document est obligatoire. Vous allez pouvoir le configurer comme bon vous semble.<br><br> Vous avez peut-être déjà fait des factures avec un format de numérotation déjà défini ? Si c’est le cas, vous pourrez reprendre le même pour l\'utiliser sur Abby.',
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/numerotation.png',
        videoUrl: '',
        action: {
          text: 'Configurer mon format',
          icon: 'settings',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.CONFIGURE_YOUR_BILLING_COUNTER,
        title: 'Réglez votre compteur de départ',
        content: 'Avoir un format et une suite chronologique dans ses numéros de document est obligatoire. Vous pourrez le configurer comme bon vous semble.<br><br> Vous avez peut-être déjà fait des factures auparavant ? Si c’est le cas, vous pourrez indiquer le prochain numéro de facture à utiliser sur Abby.',
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/numerotation.png',
        videoUrl: '',
        action: {
          text: 'Régler mon compteur',
          icon: 'settings',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: [BillingCycle.ESTIMATE_ADVANCE_FINAL_INVOICE, BillingCycle.ESTIMATE_FINAL_INVOICE].includes(getters.persona?.billingCycle),
        step: GuideStepItem.CREATE_YOUR_FIRST_ESTIMATE_OR_PURCHASE_ORDER,
        title: 'Créez votre premier devis ou bon de commande',
        content: `Les devis et les bons de commande font office de contrat entre vous et votre client. Vous pourrez les générer très facilement ici.
                      <br><br>Une fois votre devis finalisé, vous pourrez créer un ou plusieurs acomptes puis une facture finale (reste à payer). 
                      <br><br>Si vous voulez que vos clients signe plus vite et plus simplement, vous pouvez utiliser la signature de vos documents en ligne. (+ article)`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/devis.png',
        videoUrl: '',
        action: {
          text: 'Créer un devis',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: getters.persona?.billingCycle === BillingCycle.ESTIMATE_ADVANCE_FINAL_INVOICE,
        step: GuideStepItem.CREATE_YOUR_FIRST_ADVANCE,
        title: 'Créez votre premier acompte',
        content: `Vous pouvez très facilement créer une facture d’acompte pour que votre client vous règle une partie du montant de votre devis ou de votre bon de commande.
                          <br><br>Vous pourrez toujours retrouver les documents qui sont associés entre eux et Abby qui indiquera les montants qui ont déjà été facturés depuis un devis.`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/acompte.png',
        videoUrl: '',
        action: {
          text: 'Créer un acompte',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: [BillingCycle.ESTIMATE_ADVANCE_FINAL_INVOICE, BillingCycle.ESTIMATE_FINAL_INVOICE].includes(getters.persona?.billingCycle),
        step: GuideStepItem.CREATE_YOUR_FIRST_FINAL_INVOICE_STILL_TO_BE_PAID,
        title: 'Créez votre première facture finale (reste à payer)',
        content: `Dès que vous voulez vous faire payer le reste du montant d’un devis ou d'un bon de commande, vous pouvez créer une facture finale (reste à payer). 
                          <br><br>Abby va automatiquement vous indiquer le montant restant à facturer et l'indiquer dans l’espace de votre document les numéros et montants des acomptes payés s'il y en a.`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/facture.png',
        videoUrl: '',
        action: {
          text: 'Créer une facture finale',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: getters.persona?.billingCycle === BillingCycle.ONLY_INVOICES,
        step: GuideStepItem.CREATE_YOUR_FIRST_INVOICE,
        title: 'Créez votre première facture',
        content: `Les devis et les bons de commande font office de contrat entre vous et votre client. Vous pourrez les générer très facilement ici.
                      <br><br>Une fois votre devis finalisé, vous pourrez créer un ou plusieurs acomptes puis une facture finale (reste à payer). 
                      <br><br>Si vous voulez que vos clients signe plus rapidement et plus simplement, vous pouvez utiliser la signature de vos documents en ligne. (+ article)`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/facture.png',
        videoUrl: '',
        action: {
          text: 'Créer une facture',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.SAVE_TIME_BY_CONFIGURING_DEFAULT_INFORMATION,
        title: 'Gagnez du temps en configurant vos informations par défaut',
        content: `Si vous voulez créer vos documents de facturation encore plus vite et ne pas avoir à configurer ou écrire toujours la même chose, vous pouvez configurer tes paramètres par défaut qui s’appliqueront automatiquement à vos documents lorsque vous en créerez de nouveaux.
                          <br><br>Configurez facilement vos informations de paiement, conditions générales, informations complémentaires, etc...`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/config-document.png',
        videoUrl: '',
        action: {
          text: 'Configurer mes paramètres',
          icon: 'settings',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.ACTIVATE_ONLINE_PAYMENT_OF_YOUR_INVOICE,
        title: 'Activez le paiement en ligne de vos factures',
        content: `Vous voulez que vos clients vous paient plus vite et plus simplement ? Lorsque que vous décidez d’utiliser le paiement en ligne pour un document, un lien sera automatiquement généré pour donner à votre client un accès à son portail client. 
                          <br><br>Vos clients pourront vous régler par carte bancaire ou prélèvement SEPA. Vous pourrez ensuite effectuer des virements vers votre compte bancaire pour récupérer votre rémunération.`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/paiement-en-ligne.png',
        videoUrl: '',
        action: {
          text: 'Activer le paiement en ligne',
          icon: 'toggle_on',
          iconDirection: 'left',
        },
      }, {
        isDisplayable: getters.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || rootGetters['billingConfiguration/billingConfiguration']?.tiersPrestationActivated,
        step: GuideStepItem.ACTIVATE_URSSAF_IMMEDIATE_ADVANCE,
        title: 'Activez l’avance immédiate automatique',
        content: `Vous faites du service à la personne et vous avez envie que vos clients bénéficient de l’avance immédiate de crédit d'impôt ? Avec Abby tout se fait tout seul !
                          <br><br>Une fois l’avance immédiate activée, vous pourrez créer vos clients et vos factures pour qu’ils soient gérés automatiquement avec l’Urssaf via l’API de tierce prestation.`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/avance-immediate.png',
        videoUrl: '',
        action: {
          text: 'Configurer l’avance immédiate',
          icon: 'toggle_on',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.IMPORT_YOUR_CLIENTS_AND_INVOICES,
        title: 'Importez vos clients et / ou vos factures',
        content: `Vous avez déjà un historique ? Sur Abby, vous pouvez importer vos factures dans votre livre des recettes et ajouter toute votre base de clients.
                          <br><br>Si vous venez d’un autre logiciel, vous devrez pouvoir exporter vos données assez simplement. En cas de problème lors l’import, contactez le support ! On pourra vous aider à vous installer.`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/client.png',
        videoUrl: '',
        action: {
          text: 'Importer mes données',
          icon: 'import_export',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.DISABLE_TEST_MODE,
        title: 'Désactivez le mode test',
        content: `Ça y est, vous avez pu tester l’outil de facturation comme vous le vouliez ? Vous êtes prêts à passer à la suite ? Une fois le mode test désactivé, la mention “SPECIMEN” ne sera plus présente et vos factures marquées comme payées s’ajouteront automatiquement à votre livre des recettes.
                          <br><br>Prenez le temps de bien vérifier votre format de numérotation et votre compteur de départ pour bien (re)démarrer votre facturation.`,
        iconUrl: '',
        imageUrl: '/onboarding/guide/steps/test-mode.png',
        videoUrl: '',
        action: {
          text: 'Désactiver le mode test',
          icon: 'toggle_off',
          iconDirection: 'left',
        },
      },
    ],
  },
  {
    title: 'Laissez nous faire la comptabilité à votre place',
    level: GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY,
    duration: 'Environ 5 min',
    iconUrl: '/onboarding/guide/levels/comptabilite.png',
    steps: [
      {
        isDisplayable: !!rootGetters['company/isEI'],
        step: GuideStepItem.CONFIGURE_YOUR_CONTRIBUTION_RATES,
        title: 'Configurez vos taux de cotisations',
        iconUrl: '',
        content: `Abby va vous faire gagner un temps fou en faisant votre comptabilité automatiquement. Vous pouvez décider de vous baser sur votre livre des recettes (par défaut) ou votre compte bancaire. (+ articles)
                          <br><br>Pour être cohérent dans vos estimations de cotisations sociales, configurez correctement vos taux de cotisations. Si vous ne les connaissez pas précisément, vous pouvez toujours aller vérifier sur votre dernière déclaration sur le site de l’Urssaf.`,
        imageUrl: '/onboarding/guide/steps/config-cotisations.png',
        videoUrl: '',
        action: {
          text: 'Configurer mes taux',
          icon: 'settings',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: !!rootGetters['company/company']?.hasVat,
        step: GuideStepItem.CONFIGURE_YOUR_VAT,
        title: 'Configurez votre TVA sur Abby',
        iconUrl: '',
        content: 'Vous allez gagner un temps précieux car Abby vous permet de calculer automatiquement votre TVA collectée et déductible (bientôt). Ainsi vous pourrez télé-déclarer votre TVA en un clic depuis ici (bientôt).',
        imageUrl: '/onboarding/guide/steps/tva.png',
        videoUrl: '',
        action: {
          text: 'Configurer ma TVA',
          icon: 'settings',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: !!rootGetters['company/isEI'],
        step: GuideStepItem.ESTIMATE_YOUR_SOCIAL_SECURITY_CONTRIBUTIONS,
        title: 'Faites une estimation de vos cotisations sociales',
        iconUrl: '',
        content: `À tout moment, vous pouvez faire une estimation de vos cotisations sociales. Si vous avez déjà réalisé du chiffre d’affaires, alors Abby le récupère par défaut pour vous aider à faire vos calculs rapidement. 
                          <br>Dans tous les cas, vous pouvez faire vos propres estimations en modifiant le montant de votre chiffre d’affaires.`,
        imageUrl: '/onboarding/guide/steps/estimation-cotisations.png',
        videoUrl: '',
        action: {
          text: 'Faire une estimation',
          icon: 'visibility',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.CONNECT_YOUR_BANK_ACCOUNT,
        title: 'Connectez votre compte bancaire',
        iconUrl: '',
        content: `Vous voulez avoir une visibilité accrue sur vos dépenses et revenus ? Synchronisez votre compte bancaire sur Abby. Vous verrez toutes les transactions bancaires que vous pourrez catégoriser pour suivre parfaitement l’évolution de votre trésorerie.
                <br><br>Abby peut se connecter à plus de 300 banques. Vos données sont sécurisées et le protocole de connexion utilisé ne permet en aucun cas de pouvoir effectuer des paiements, prélèvements ou virement depuis Abby. Vous serez le seul à pouvoir accéder à vos données.
                <br><br>Vous pouvez décider de baser votre comptabilité sur votre compte bancaire. Par défaut, votre comptabilité est basée sur votre livre des recettes.`,
        imageUrl: '/onboarding/guide/steps/connexion-bancaire.png',
        videoUrl: '',
        action: {
          text: 'Connecter ma banque',
          icon: 'sensors',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.ANNOTATE_YOUR_FIRST_TRANSACTION,
        title: 'Annotez votre première transaction',
        iconUrl: '',
        content: `Catégorisez vos dépenses et revenus facilement. Cela vous permettra d’avoir une meilleure visibilité sur votre trésorerie pour prendre de meilleures décisions.
                <br><br>Abby vous suggère des catégories automatiquement grâce à l’intelligence artificielle. Si vous avez l’habitude d’annoter un type de transaction avec une catégorie, alors Abby la catégorisera automatiquement à l’avenir.`,
        imageUrl: '/onboarding/guide/steps/annoter.png',
        videoUrl: '',
        action: {
          text: 'Annoter une transaction',
          icon: 'announcement',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: true,
        step: GuideStepItem.ADD_AN_ENTRY_TO_YOUR_PURCHASES_BOOK,
        title: 'Ajoutez une entrée dans votre livre des achats',
        iconUrl: '',
        content: `Si vous devez entretenir un livre des achats pour votre activité, pas de panique. Avec Abby, vous pouvez répertorier vos dépenses liées à votre activité en un seul et même endroit.
                <br><br>Vous pouvez également associer vos documents à vos entrées pour pouvoir retrouver une facture d’achat à n’importe quel moment.`,
        imageUrl: '/onboarding/guide/steps/livre-achats.png',
        videoUrl: '',
        action: {
          text: 'Ajouter un achat',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        isDisplayable: !!rootGetters['company/isEI'],
        step: GuideStepItem.CONNECT_YOUR_URSSAF_ACCOUNT,
        title: 'Connectez votre compte Urssaf',
        iconUrl: '',
        content: `Ici, votre comptabilité se fait automatiquement. Connectez votre compte Urssaf à Abby pour déclarer et payer vos cotisations en quelques clics.
                <br><br>Abby est partenaire certifié Urssaf. À tout moment, vous pouvez vérifier ou modifier vos déclarations sur le site officiel de l’Urssaf.`,
        imageUrl: '/onboarding/guide/steps/urssaf.png',
        videoUrl: '',
        action: {
          text: 'Connecter mon compte',
          icon: 'sensors',
          iconDirection: 'left',
        },
      },
    ],
  },
  {
    title: 'Organisez votre travail',
    level: GuideLevelItem.GET_ORGANIZE_EASILY,
    duration: 'Environ 2 min',
    iconUrl: '/onboarding/guide/levels/organisation.png',
    steps: [
      {
        step: GuideStepItem.CREATE_YOUR_FIRST_TASK,
        title: 'Créez votre première tâche',
        iconUrl: '',
        content: 'Vous pouvez gérer vos tâches simplement avec Abby. Vous recevrez un e-mail de notification pour vous rappeler vos tâches en retard et du jour.' +
        // @ts-ignore
        // eslint-disable-next-line no-undef
                    `<br><br>Associez vos tâches à vos ${process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('plural') : 'une carte'} pour avoir enfin vos rappels, vos factures et le suivi de vos clients au même endroit.`,
        imageUrl: '/onboarding/guide/steps/tache.png',
        videoUrl: '',
        action: {
          text: 'Créer une tâche',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.CREATE_CARD_IN_THE_TRACKING_TABLE,
        // @ts-ignore
        // eslint-disable-next-line no-undef
        title: process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('guideStepTitle') : 'Créez une carte dans le tableau de suvi',
        iconUrl: '',
        content: `Si vous êtes quelqu’un d’organisé, vous devriez apprécier de pouvoir suivre vos clients, votre facturation et votre comptabilité au même endroit !
                  <br><br>Vous pouvez créer le tableau de suivi qui vous ressemble en ajoutant ou modifiant vos colonnes. Tous vos documents, tâches, notes et suivi du temps enfin centralisés.`,
        imageUrl: '/onboarding/guide/steps/opportunite.png',
        videoUrl: '',
        action: {
          // @ts-ignore
          // eslint-disable-next-line no-undef
          text: process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('create') : 'Crée une carte',
          icon: 'add',
          iconDirection: 'left',
        },
      },
      {
        step: GuideStepItem.CREATE_YOUR_FIRST_TIME_TRACKING,
        title: 'Créez votre premier suivi du temps',
        iconUrl: '',
        content: `Adepte de la maîtrise de votre temps ? Suivez-le avec Abby et affectez-lui une valeur financière. Facturez en un clic vos clients après un projet ou en fin de mois.
                    <br><br>Pratique pour savoir si vous êtes rentable, ou si d’habitude vous enregistrez vos heures travaillées sur Excel, votre agenda ou sur papier. ` +
        // @ts-ignore
        // eslint-disable-next-line no-undef
                    `<br><br>Associez votre temps à ${process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('guideStepContentSingular') : 'une carte'} pour tout avoir au même endroit !`,
        imageUrl: '/onboarding/guide/steps/suivi-du-temps.png',
        videoUrl: '',
        action: {
          text: 'Suivre mon temps',
          icon: 'add',
          iconDirection: 'left',
        },
      },
    ],
  },
]
