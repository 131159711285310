import { ReadCustomerInformations } from '@abby/shared'

interface ClientCopyTiersPrestationMessageProps {
    title: string
    customer: ReadCustomerInformations
    subject: string
    content: string
    hint?: string
}

export class ClientCopyTiersPrestationMessage {
    readonly title: string
    readonly customer: ReadCustomerInformations
    readonly subject: string
    readonly content: string
    readonly hint?: string

    private constructor (props: ClientCopyTiersPrestationMessageProps) {
      this.title = props.title
      this.customer = props.customer
      this.subject = props.subject
      this.content = props.content
      this.hint = props.hint
    }

    static create (props: ClientCopyTiersPrestationMessageProps): ClientCopyTiersPrestationMessage {
      return new ClientCopyTiersPrestationMessage(props)
    }
}
