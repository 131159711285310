import { ActionTree } from 'vuex'
import { ConfirmParams } from '~/plugins/confirmManager'

export interface ConfirmManagerState {
  confirm: ConfirmParams | null
}

export const state = (): ConfirmManagerState => ({
  confirm: null,
})

export const getters = {
  confirm (state: ConfirmManagerState): ConfirmParams | null {
    return state.confirm
  },
}

export const mutations = {
  SET_CONFIRM (state: ConfirmManagerState, value: ConfirmParams | null) {
    state.confirm = value
  },
  RESET (_currentState: ConfirmManagerState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<ConfirmManagerState, ConfirmManagerState> = {
  clearConfirm ({ commit }) {
    commit('SET_CONFIRM', null)
  },
}
