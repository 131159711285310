import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type {
  CreateCompanyOpportunityCategory, CreateOpportunity, QueryOpportunities,
  ReadCompanyOpportunityCategory, ReadOpportunity, UpdateCompanyOpportunityCategory, UpdateOpportunity,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export type Pagination = { totalAmount: number, totalItems: number, loading: boolean, lastOpportunity?: string }

export interface OpportunityState {
  categories: ReadCompanyOpportunityCategory[],
  isCategoryModalOpened: boolean,
  currentCategory: ReadCompanyOpportunityCategory | null,
  opportunity: ReadOpportunity | null,
  isOpportunityCreationOpened: boolean,
  isOpportunityEditOpened: boolean,
  opportunities: {
    [K: string]: ReadOpportunity[]
  },
  openedOpportunityDetails: ReadOpportunity[],
  pagination: {
    [K: string]: Pagination
  },
  search: string,
  range: string[],
  queryLoading: boolean,
}

export const state = (): OpportunityState => ({
  categories: [],
  isCategoryModalOpened: false,
  opportunity: null,
  isOpportunityCreationOpened: false,
  isOpportunityEditOpened: false,
  opportunities: {},
  openedOpportunityDetails: [],
  pagination: {},
  search: '',
  range: [],
  queryLoading: false,
  currentCategory: null,
})

export const mutations: MutationTree<OpportunityState> = {
  SET_CATEGORIES (state, value: ReadCompanyOpportunityCategory[]) {
    state.categories = value
  },
  SET_IS_CATEGORY_MODAL_OPENED (state, value: boolean) {
    state.isCategoryModalOpened = value
  },
  SET_OPPORTUNITY (state, value: ReadOpportunity | null) {
    state.opportunity = value
  },
  SET_IS_OPPORTUNITY_CREATION_OPENED (state, value: boolean) {
    state.isOpportunityCreationOpened = value
  },
  SET_IS_OPPORTUNITY_EDIT_OPENED (state, value: boolean) {
    state.isOpportunityEditOpened = value
  },
  SET_OPPORTUNITIES (state, payload: { id: string, data: ReadOpportunity[] }) {
    state.opportunities = {
      ...state.opportunities,
      [payload.id]: payload.data,
    }
  },
  PUSH_OPENED_OPPORTUNITIES (state, payload: ReadOpportunity) {
    state.openedOpportunityDetails.push(payload)
  },
  SET_OPENED_OPPORTUNITIES (state, payload: ReadOpportunity[]) {
    state.openedOpportunityDetails = payload
  },
  UPDATE_OPPORTUNITY (state, value: Partial<ReadOpportunity>): void {
    state.opportunity = {
      ...state.opportunity as ReadOpportunity,
      ...value,
    }
  },
  SPLICE_OPENED_OPPORTUNITIES (state, index: number) {
    state.openedOpportunityDetails.splice(index)
  },
  SET_PAGINATION (state, value: Pagination & { categoryId: string }) {
    const { categoryId, ...data } = value
    if (!categoryId) { return }
    state.pagination = {
      ...state.pagination,
      [categoryId]: data,
    }
  },
  SET_CURRENT_CATEGORY (state, value: ReadCompanyOpportunityCategory) {
    state.currentCategory = value
  },
  SET_SEARCH (state, value: string) {
    state.search = value
  },
  SET_RANGE (state, value: string[]) {
    state.range = value
  },
  SET_QUERY_LOADING (state, value: boolean) {
    state.queryLoading = value
  },
  RESET (_currentState: OpportunityState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const getters: GetterTree<OpportunityState, RootState> = {
  categories: state => state.categories,
  opportunity: state => state.opportunity,
  isCategoryModalOpened: state => state.isCategoryModalOpened,
  isOpportunityCreationOpened: state => state.isOpportunityCreationOpened,
  isOpportunityEditOpened: state => state.isOpportunityEditOpened,
  opportunities: state => state.opportunities,
  openedOpportunityDetails: state => state.openedOpportunityDetails,
  pagination: state => state.pagination,
  search: state => state.search,
  range: state => state.range,
  category: state => (id: string) => state.categories.find(c => c.id === id),
  queryLoading: state => state.queryLoading,
  currentCategory: state => state.currentCategory,
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.openedOpportunityDetails.slice(-1)?.[0]?.id === id,
}

export const actions: ActionTree<OpportunityState, RootState> = {
  async fetchCategories ({ commit }) {
    const results = await this.$api.opportunity.getCategories()
    commit('SET_CATEGORIES', results)
    return results
  },
  async createCategory ({ commit, getters }, payload: CreateCompanyOpportunityCategory) {
    const category = await this.$api.opportunity.createCategory(payload)
    const categories = [
      ...getters.categories,
      category,
    ].sort((a, b) => a.rank?.localeCompare?.(b.rank))
    commit('SET_CATEGORIES', categories)
    commit('SET_OPPORTUNITIES', {
      id: category.id,
      data: [],
    })
    commit('SET_PAGINATION', {
      categoryId: category.id,
      totalItems: 0,
      lastOpportunity: null,
    })
  },
  setCurrentCategory ({ commit }, value: ReadCompanyOpportunityCategory) {
    commit('SET_CURRENT_CATEGORY', value)
  },
  async createOpportunity ({ commit, getters, dispatch }, payload: CreateOpportunity) {
    const result = await this.$api.opportunity.createOpportunity(payload)
    this.$busManager.emit('opportunityCreated', result)
    dispatch('openOpportunityDetails', { opportunity: result })
    const data = [
      result,
      ...getters.opportunities?.[result?.categoryId] ? getters.opportunities[result.categoryId] : [],
    ]
    commit('SET_OPPORTUNITIES', {
      id: result.categoryId,
      data,
    })
    commit('SET_PAGINATION', {
      categoryId: result.categoryId,
      totalItems: data.length,
    })
  },
  setPagination ({ commit }, value: { totalItems: number, categoryId: string }) {
    commit('SET_PAGINATION', value)
  },
  setSearch ({ commit }, value: string) {
    commit('SET_SEARCH', value)
  },
  setRange ({ commit }, value: string[]) {
    commit('SET_RANGE', value)
  },
  setOpenedOpportunityDetails ({ commit }, value: ReadOpportunity[]) {
    commit('SET_OPENED_OPPORTUNITIES', value)
  },
  async updateOpportunity ({ commit, getters, state, dispatch }, payload: UpdateOpportunity & { id: string }) {
    const result = await this.$api.opportunity.updateOpportunity(payload)
    this.$busManager.emit('opportunityUpdated', result)
    if (!state.opportunities[result.categoryId]) {
      return
    }

    const data = getters
      .opportunities[result.categoryId]
      .map((opportunity: ReadOpportunity) => opportunity.id === result.id ? result : opportunity)

    commit('SET_OPPORTUNITIES', {
      id: result.categoryId,
      data,
    })
    commit('SET_OPENED_OPPORTUNITIES', getters.openedOpportunityDetails.map((o: ReadOpportunity) => ({
      ...o,
      ...(o.id === result.id ? result : {}),
    })))
    return result
  },
  setOpportunities ({ commit }, payload: { id: string, data: ReadOpportunity[] }) {
    commit('SET_OPPORTUNITIES', payload)
  },
  async updateCategory ({ dispatch }, payload: UpdateCompanyOpportunityCategory & { id: string }) {
    await this.$api.opportunity.updateCategory(payload)
    dispatch('fetchCategories')
  },
  setCategoryModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_CATEGORY_MODAL_OPENED', value)
  },
  openOpportunityDetails ({ commit, getters }, { opportunity } : { opportunity: ReadOpportunity }) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(opportunity.id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('PUSH_OPENED_OPPORTUNITIES', opportunity)
  },
  closeOpportunityDetails ({ commit }, index: number) {
    commit('SPLICE_OPENED_OPPORTUNITIES', index)
  },
  openOpportunityCreation ({ commit }, payload: Partial<ReadOpportunity>) {
    commit('SET_OPPORTUNITY', payload)
    commit('SET_IS_OPPORTUNITY_CREATION_OPENED', true)
  },
  closeOpportunityCreation ({ commit }) {
    commit('SET_IS_OPPORTUNITY_CREATION_OPENED', false)
  },
  openOpportunityEdit ({ commit }, payload: Partial<ReadOpportunity>) {
    commit('SET_OPPORTUNITY', payload)
    commit('SET_IS_OPPORTUNITY_EDIT_OPENED', true)
  },
  closeOpportunityEdit ({ commit }) {
    commit('SET_IS_OPPORTUNITY_EDIT_OPENED', false)
  },
  cleanOpportunity ({ commit }) {
    commit('SET_OPPORTUNITY', null)
  },
  setQueryLoading ({ commit }, value: boolean) {
    commit('SET_QUERY_LOADING', value)
  },
  setOpportunity ({ commit }, value: ReadOpportunity | null) {
    commit('SET_OPPORTUNITY', value)
  },
  async removeCategory ({ commit, getters, dispatch }, payload: { id: string, target?: string }) {
    try {
      await this.$api.opportunity.removeCategory(payload.id, payload.target)

      commit('SET_CATEGORIES', getters.categories.filter((category: ReadCompanyOpportunityCategory) => category.id !== payload.id))
    } catch (e) {
      dispatch('fetchCategories')
      throw e
    }
  },
  async removeOpportunity ({ commit, getters, state, dispatch }, opportunity: ReadOpportunity) {
    await this.$api.opportunity.removeOpportunity(opportunity.id)
    this.$busManager.emit('opportunityRemoved', opportunity)

    if (!state.opportunities[opportunity.categoryId]) {
      return
    }
    commit('SET_OPPORTUNITIES', {
      id: opportunity.categoryId,
      data: getters.opportunities[opportunity.categoryId]?.filter((o: ReadOpportunity) => o.id !== opportunity.id),
    })

    const pagination = getters.pagination[opportunity.categoryId]
    commit('SET_PAGINATION', {
      ...pagination,
      totalItems: pagination?.totalItems - 1,
    })
  },
  async fetchOpportunities ({ commit, getters, dispatch }, payload: QueryOpportunities & { truncate?: boolean }) {
    if (!payload.category) { return }

    if (payload?.truncate) {
      commit('SET_OPPORTUNITIES', {
        id: payload.category,
        data: [],
      })
    }

    const results = await this.$api.opportunity.getOpportunities({
      lastOpportunity: payload.lastOpportunity,
      limit: payload.limit,
      category: payload.category,
      ...(getters.search.length
        ? {
          search: getters.search,
        }
        : {}),
      ...(getters.range.length
        ? {
          range: getters.range,
        }
        : {}),
    })
    commit('SET_OPPORTUNITIES', {
      id: payload.category,
      data: [
        ...(getters.opportunities?.[payload.category] || []),
        ...results.data,
      ],
    })
    commit('SET_PAGINATION', {
      totalAmount: results.totalAmount,
      categoryId: payload.category,
      totalItems: results.totalItems,
      lastOpportunity: payload.lastOpportunity,
    })
  },
}
