import type { CreatePersona, ReadPersona } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Persona extends Resource {
  get (): Promise<ReadPersona> {
    return this.axios.$get('/persona')
  }

  update (payload: CreatePersona): Promise<ReadPersona> {
    return this.axios.$put('/persona', payload)
  }
}
