export enum BillingCycle {
  ESTIMATE_ADVANCE_FINAL_INVOICE = 'estimate_advance_final_invoice',
  ESTIMATE_FINAL_INVOICE = 'estimate_final_invoice',
  ONLY_INVOICES = 'only_invoices',
}

export enum TurnoverEstimation {
  BETWEEN_0_AND_10K = 'between_0_and_10k',
  BETWEEN_10_AND_30K = 'between_10_and_30k',
  BETWEEN_30_AND_70K = 'between_10_and_30k',
  MORE_THAN_70 = 'more_than_70',
}

export enum PersonaFeature {
  ONLINE_INVOICE_PAYMENT = 'online_invoice_payment',
  SIGNING_ESTIMATE_ONLINE = 'signing_estimate_online',

  URSSAF_DECLARATION = 'urssaf_declaration',
  BANK_SYNCHRO = 'bank_synchro',
  INCOME_STATEMENT = 'income_statement',

  TIME_TRACKING = 'time_tracking',
  PROJECT_TRACKING = 'project_tracking',
  TASK_MANAGEMEMENT = 'task_managemement',

  BILLING_ESTIMATE = 'billing_estimate',
  PRODUCTIVITY = 'productivity',

  BILLING_INVOICE_ESTIMATE = 'billing_invoice_estimate',
  ACCOUNTING_URSSAF = 'accounting_urssaf',
  ACCOUNTING_INCOME_BOOK = 'accounting_income_book',
  IMMEDIATE_ADVANCE_API = 'immediate_advance_api',
  ACCOUNTING_BANK = 'accounting_bank',
  ACCOUNTING_VAT = 'accounting_vat',
}
export const PersonaFeatureGroup: any = [
  PersonaFeature.URSSAF_DECLARATION,
  PersonaFeature.ONLINE_INVOICE_PAYMENT,
  PersonaFeature.SIGNING_ESTIMATE_ONLINE,
  PersonaFeature.IMMEDIATE_ADVANCE_API,
  PersonaFeature.BANK_SYNCHRO,
  PersonaFeature.INCOME_STATEMENT,
];
