
import { Component, Getter, Vue, Watch } from 'nuxt-property-decorator'

@Component({})
export default class AppLoader extends Vue {
  @Getter('app/appLoading') appLoading!: boolean;

  displayMayHaveErrorMessage: boolean = false

  startLoading () {
    setTimeout(() => {
      this.displayMayHaveErrorMessage = true
    }, 19_000)
  }

  @Watch('appLoading')
  appLoadingHandler (value: boolean) {
    this.displayMayHaveErrorMessage = false
    if (value) {
      this.startLoading()
    }
  }

  reloadPage () {
    // @ts-ignore
    window.location = '/'
  }
}

