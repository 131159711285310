import { Context } from '@nuxt/types'
import { wrap } from 'comlink'
import type { GenerateBillingPdfOptions, Overwrite } from '@abby/core-legacy'

type BillingMethods = {
  generateBillingPdf: (data: Overwrite<Omit<GenerateBillingPdfOptions, 'transformHTMLtoPDFMake'>, { shapeUrl?: string, data: Overwrite<GenerateBillingPdfOptions['data'], { headerNote?: any, footerNote?: any, generalTermsAndConditionsOfSale?: any, mentionMediator?: any }> }>) => Promise<string>;
}

export default (_: Context, inject:any) => {
  let worker: any | null = null
  inject(
    'workerBilling',
    {
      async loadBillingWorker () {
        if (worker) { return }
        const { default: BillingWorker }: any = await import('./billing.worker')
        worker = wrap(new BillingWorker())
      },
      generateBillingPdf: (params: any) => worker.generatePdf(params),
    })
}

type Worker = BillingMethods & { loadBillingWorker: () => Promise<void> }

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $workerBilling: Worker;
  }
  interface Context {
    $workerBilling: Worker;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $workerBilling: Worker
  }
}
