export enum ExportFileType {
  CSV = 'csv',
  XLSX = 'xlsx',
  PDF = 'pdf',
}

export const FileTypeMimeType = {
  [ExportFileType.CSV]: 'text/csv',
  [ExportFileType.PDF]: 'application/pdf',
  [ExportFileType.XLSX]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
