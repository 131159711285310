
import Vue from 'vue'
import { Component, Prop, ModelSync } from 'nuxt-property-decorator'

import { PaymentMethod, PaymentMethodItems } from '@abby/core-legacy'
import { $enum } from 'ts-enum-util'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import ASForm from '~/components-legacy/inputs/ASForm.vue'
import AsSelect from '~/components-legacy/inputs/ASSelect.vue'

@Component({
  components: {
    AsSelect,
    AsTextField,
    ASForm,
  },
})
export default class BillingPaymentMethodsForm extends Vue {
  @ModelSync('value', 'input', { default: () => ({ list: [], other: '' }) })
  paymentMethod!: PaymentMethodItems

  @Prop({ default: false })
  loading!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: false })
  unique!: boolean

  @Prop({ default: false })
  removeOtherOption!: boolean

  @Prop({ default: () => {} })
  submit!: () => void

  reset () {
    // @ts-ignore
    this.$refs.form.reset()
  }

  get displayOtherField () {
    if (!Array.isArray(this.paymentMethod?.value)) {
      return this.paymentMethod?.value === PaymentMethod.OTHER
    }
    return !!this.paymentMethod?.value?.includes(PaymentMethod.OTHER)
  }

  get items () {
    if (this.removeOtherOption) {
      return $enum(PaymentMethod).getEntries().map(([k, v]) => ({ text: this.$t(`paymentMethods.${k}`), value: v })).filter(item => item.value !== PaymentMethod.OTHER)
    }
    return $enum(PaymentMethod).getEntries().map(([k, v]) => ({ text: this.$t(`paymentMethods.${k}`), value: v }))
  }

  updatePaymentMethodsList (value: PaymentMethod[] | PaymentMethod) {
    const otherPayment = (Array.isArray(value) && value.includes(PaymentMethod.OTHER)) || value === PaymentMethod.OTHER
    this.$emit('input', {
      value,
      other: otherPayment ? this.paymentMethod.other || '' : '',
    })
  }

  updatePaymentMethodsOther (value: string) {
    this.$emit('input', {
      value: this.paymentMethod.value,
      other: value,
    })
  }

  resetPaymentMethods () {
    this.$emit('input', {
      list: [],
      other: '',
    })
  }
}
