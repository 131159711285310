
import { Component, Vue, PropSync, Getter, Prop } from 'nuxt-property-decorator'

@Component
export default class IntercomButton extends Vue {
  @PropSync('chatIsOpen')
  chatIsOpenValue!: boolean

  @Prop({ default: 'modal' })
  componentToOpen!: 'chat' | 'modal'

  @Getter('helpCenter/helpCenterIsOpen')
  helpCenterIsOpen!: boolean

  openHelpcenter () {
    if (!this.chatIsOpenValue) {
      if (this.componentToOpen === 'modal') {
        this.$help.toggleHelpCenter(!this.helpCenterIsOpen)
      } else {
        this.$help.toggleChat(true, { open: true })
      }
    } else {
      this.$help.toggleChat(false, { open: false })
    }
  }
}
