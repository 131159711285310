export enum FromSoftware {
  FREEBE = 'freebe',
  HENRII = 'henrii',
  PORTAIL_AUTO_ENTREPRENEUR = 'portail-auto-entrepreneur',
  SINAO = 'sinao',
  QUICKBOOKS = 'quickbooks',
  TIIME = 'tiime',
  SHINE = 'shine',
  INDY = 'indy',
  ZERVANT = 'zervant',
  FACTURENET = 'facturenet',
  MYAE = 'myae',
  AXONAUT = 'axonaut',
  EXCELWORD = 'excelword',
  THE_GOOD_COUNT = 'the-good-count',
  OTHER = 'other',
  NONE = 'none',
}
