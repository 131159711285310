import { ReadStockMovementPage } from '../../dto/stock/ReadStockMovementPage';
import { Resource } from '../Resource';
import { StockEvent } from '../../enums/stock/stockEvent.enum';
import { ReadStockConfiguration } from '../../dto/stock/ReadStockConfiguration';

export class Stock extends Resource {
  // STOCK CONFIGURATION

  retrieveStockConfiguration(): Promise<ReadStockConfiguration> {
    return this.http.$get('/stock/configuration');
  }

  enableStockManagement(): Promise<ReadStockConfiguration> {
    return this.http.$patch('/stock/enable');
  }

  disableStockManagement(): Promise<ReadStockConfiguration> {
    return this.http.$patch('/stock/disable');
  }

  updateStockManagementEvent(stockConfigurationPatch: { stockEvent: StockEvent }) : Promise<ReadStockConfiguration> {
    return this.http.$patch('/stock/configuration', stockConfigurationPatch);
  }

  updateProductStock(productId: string, stock: number) : Promise<ReadStockConfiguration> {
    return this.http.$put(`/v2/catalog/product/${productId}/stock`, { stock });
  }

  // STOCK MOVEMENTS

  async retrieveStockMovementsPage({ limit, page } : { limit: number, page: number }): Promise<ReadStockMovementPage> {
    return this.http.$get(`/stock/movements?page=${page}&limit=${limit}`);
  }

  retrieveStockMovementsPageForProduct(productId: string, { page, limit }: { page: number, limit: number }): Promise<ReadStockMovementPage> {
    return this.http.$get(`/stock/${productId}/movements?page=${page}&limit=${limit}`);
  }

  async deleteStockMovement(id: string): Promise<void> {
    if (localStorage.STOCK_THROW_ON_DELETE === 'true') {
      throw new Error('Error delete stock');
    }
    return this.http.$delete(`/stock/movement/${id}`);
  }
}
