
import { Action, Component, Getter, Prop, Vue, Watch } from 'nuxt-property-decorator'
import { RankTaskMode, ReadOpportunity, ReadTask, UpdateTask } from '@abby/core-legacy'
import TaskActionMenu from '~/components-legacy/task/TaskActionMenu.vue'
import AddTaskCard from '~/components-legacy/cards/tasks/AddTaskCard.vue'
import TaskPrioritySelect from '~/components-legacy/task/TaskPrioritySelect.vue'
import PlanningDatePicker from '~/components-legacy/cards/tasks/PlanningDatePicker.vue'
import OpportunityChip from '~/components-legacy/task/OpportunityChip.vue'

@Component({
  components: {
    OpportunityChip,
    PlanningDatePicker,
    TaskActionMenu,
    AddTaskCard,
    TaskPrioritySelect,
  },
})
export default class TaskLine extends Vue {
  @Prop()
  task!: ReadTask;

  @Prop({ required: false, default: false })
  disableInsert!: boolean | string;

  @Prop({ required: false, default: false })
  hideDetails!: boolean | string;

  @Prop({ required: false, default: false })
  hideDescription!: boolean | string;

  @Prop({ required: false, default: false })
  editMode!: boolean;

  @Prop({ required: false })
  rankMode!: RankTaskMode;

  @Prop({ required: false, default: false })
  titleClass!: string[];

  get _disableInsert () {
    return this.disableInsert || typeof this.disableInsert === 'string'
  }

  @Getter('task/tasks') tasks!: ReadTask[];
  @Action('task/updateTask') updateTask!: (value: UpdateTask & { id: string }) => Promise<ReadTask>;
  @Action('task/setTasks') setTasks!: (value: ReadTask[]) => Promise<void>;
  @Action('task/openTaskSidePanel') openTaskSidePanel!: (value: ReadTask) => Promise<void>;

  toggle = false
  menuIsOpen = false
  edit = false
  insert: string | null = null

  @Watch('editMode')
  editModeHandler (value: boolean) {
    this.edit = value
  }

  @Prop({ required: false, default: false })
  disableDuplicate!: boolean | string;

  get _disableDuplicate () {
    return this.disableDuplicate || typeof this.disableDuplicate === 'string'
  }

  get _hideDetails () {
    return this.hideDetails || typeof this.hideDetails === 'string'
  }

  get _hideDescription () {
    return this.hideDescription || typeof this.hideDescription === 'string'
  }

  get description () {
    if (!this.task.description) { return '' }
    return `${this.task.description?.replace(/<[^>]+>/g, ' ')?.replace(/\n/g, ' ') || ''}`
  }

  get afterRank () {
    const taskIndex = this.tasks.findIndex(t => t.id === this.task.id)
    return this.tasks[taskIndex + 1]?.rank || '0|zzzzzz:'
  }

  get priorities () {
    return [
      { color: 'red', value: 1 },
      { color: 'orange', value: 2 },
      { color: 'blue', value: 3 },
      { color: 'black', value: 4 },
      { color: 'grey', value: null },
    ]
  }

  get dueDateValue () {
    if (!this.task.dueDate) { return null }
    return this.$dayjs(this.task.dueDate).toDate()
  }

  get priorityColor () {
    const value = this.priorities.find(p => p.value === this.task.priority)
    return value?.color || 'grey'
  }

  get dueDate () {
    const dueDate = this.$dayjs(this.task.dueDate)
    let value
    if (dueDate.isToday()) {
      value = {
        text: 'Aujourd\'hui',
        color: 'primary',
      }
    } else if (dueDate.startOf('week').isSame(this.$dayjs().startOf('week'))) {
      value = {
        text: dueDate.format('dddd'),
        color: 'purple',
      }
    } else {
      value = {
        color: 'grey',
        text: dueDate.format('ddd D MMMM'),
      }
    }

    const hasTime = dueDate.get('hour') !== 0 || dueDate.get('minute') !== 0
    return {
      ...value,
      ...(hasTime ? { text: `${value.text} ${dueDate.format('HH:mm')}` } : {}),
    }
  }

  get isDone () {
    return !!this.task.doneAt
  }

  get toggleButtonIcon () {
    return this.toggle || this.isDone ? 'check' : 'radio_button_unchecked'
  }

  async toggleButton () {
    this.toggle = !this.toggle
    try {
      const task = await this.updateTask({
        id: this.task.id,
        doneAt: this.task.doneAt ? null : new Date(),
      })
      this.$emit('done', task)
      this.$emit('update', task)
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  openSidePanel () {
    this.openTaskSidePanel(this.task)
  }

  insertNextTaskId = ''
  opportunityRemovedId = ''

  mounted () {
    this.toggle = !!this.task.doneAt
    this.insertNextTaskId = this.$busManager.on('insertNextTask', (payload: { id: string, direction: string }) => {
      if (payload.id === this.task.id) {
        this.insertTask(payload.direction)
      }
    })

    this.opportunityRemovedId = this.$busManager.on('opportunityRemoved', (opportunity: ReadOpportunity) => {
      if (opportunity.id !== this.task.opportunityId) { return }
      this.$store.commit('task/UPDATE_TASK', {
        ...this.task,
        opportunityId: null,
        opportunity: null,
      })
    })
  }

  beforeDestroy () {
    this.$busManager.off(this.insertNextTaskId)
    this.$busManager.off(this.insertTaskId)
    this.$busManager.off(this.opportunityRemovedId)
  }

  insertTaskId = ''

  insertTask (direction: string | null) {
    this.$busManager.emit('insertTask', this.task.id)
    this.$nextTick(() => {
      this.insertTaskId = this.$busManager.on('insertTask', (id: string) => {
        this.cancel()
        if (this.task.id !== id) {
          this.$busManager.off(this.insertTaskId)
        }
      })
      this.insert = direction
    })
  }

  openNextForm (direction: string) {
    const taskIndex = this.tasks.findIndex(t => t.id === this.task.id)
    this.$busManager.emit('insertNextTask', {
      id: this.tasks[direction === 'up' ? taskIndex - 1 : taskIndex + 1]?.id,
      direction,
    })
  }

  cancel () {
    this.insert = null
  }

  closeEdit (task?: ReadTask) {
    this.edit = false
    if (task) {
      this.$emit('update', task)
      return
    }
    this.$emit('cancel')
  }

  editTask () {
    this.edit = true
    this.menuIsOpen = false
    this.$busManager.emit('insertTask', this.task.id)
  }

  async update (value: UpdateTask) {
    try {
      await this.updateTask({ id: this.task.id, ...value })
      this.$emit('update', { ...this.task, ...value })
    } catch (error) {
      this.$alertsManager.autoError(error)
    }
  }
}
