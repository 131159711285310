import { Plugin } from '@nuxt/types'
import { IApiError, SegmentEvent } from '@abby/core-legacy'
import { AxiosError } from 'axios'

export type AlertType = 'info' | 'success' | 'warning' | 'error'

export type AlertComponent = 'alert' | 'snack'

export interface AlertParams {
  type?: AlertType
  component?: AlertComponent
  dismissible?: boolean
}

export interface Alert {
  message: string
  type: AlertType
  component: AlertComponent
  dismissible: boolean
  errorId: string
}

export interface AlertsManagerInstance {
  error(message: string, params?: AlertParams): void;
  success(message: string, params?: AlertParams): void;
  warning(message: string, params?: AlertParams): void;
  info(message: string, params?: AlertParams): void;
  autoError(error: IApiError, params?: AlertParams): void
}

const AlertsManagerPlugin: Plugin = (context, inject) => {
  const defaultSnackConfig: { dismissible: boolean, component: AlertComponent } = {
    dismissible: true,
    component: 'snack',
  }

  const calculateTimeNeededToReadMessage = (message: string) => {
    const readingTimeNeeded = message.split(' ').length * 240 + 500
    return readingTimeNeeded > 3000 ? readingTimeNeeded : 3000
  }

  inject('alertsManager', {
    error: (message: string, params?: AlertParams) => {
      context.store.commit('alertsManager/addAlert', {
        message,
        type: 'error',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    success: (message: string, params?: AlertParams) => {
      context.store.commit('alertsManager/addAlert', {
        message,
        type: 'success',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    warning: (message: string, params?: AlertParams) => {
      context.store.commit('alertsManager/addAlert', {
        message,
        type: 'warning',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    info: (message: string, params?: AlertParams) => {
      context.store.commit('alertsManager/addAlert', {
        message,
        type: 'info',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
      })
    },
    autoError: (error: AxiosError & { config: any, response?: any, status: number, message?: string }, params?: AlertParams) => {
      context?.$hotjar?.sendHotjarEvent('error')
      if (['production', 'staging'].includes(context.$config.nodeEnv as string)) {
        if (error?.status && error?.status !== 401) {
          // context.$sentry.withScope((scope) => {
          //   if (context.store.getters['auth/user']) {
          //     scope.setUser({
          //       lastname: context.store.getters['auth/user']?.firstname,
          //       firstname: context.store.getters['auth/user']?.lastname,
          //       email: context.store.getters['auth/user']?.email,
          //       id: context.store.getters['auth/user']?.id,
          //     })
          //   }
          //
          //   context.$sentry.captureException(new Error(error.message || error.response?.data?.message || error.response?.data?.code),
          //     {
          //       tags: {
          //         section: context.route.path,
          //       },
          //       contexts: {
          //         request: {
          //           path: `${error.config.baseURL}${error.config.url}`,
          //           method: error.config.method,
          //           status: error.status,
          //           ...(error.config?.params ? { params: error.config?.params } : {}),
          //         },
          //       },
          //     })
          // })
        }
      }
      if (!['production', 'staging'].includes(context.$config.nodeEnv as string)) {
        // eslint-disable-next-line no-console
        console.trace(error)
      }

      let message: string = context.app.i18n.t('error.basic_error') as string
      const code = error.response?.data?.code || error?.code

      const errorId = error.response?.data?.errorId as string

      if (error.response?.data.message) {
        message = context.app.i18n.t(`error.${error.response.data.message as string}`) as string
      } else if (code) {
        const errorMessage = context.app.i18n.t(`error.${code as string}`)
        if (errorMessage !== `error.${code as string}`) {
          message = errorMessage as string
        }
      }
      // @ts-ignore
      context.store.commit('alertsManager/addAlert', {
        message,
        type: 'error',
        ...defaultSnackConfig,
        ...params,
        timeout: calculateTimeNeededToReadMessage(message),
        errorId,
      })

      if (!context.store.getters['auth/user']) { return }
      context.$ap.sendSegmentTrack({
        event: SegmentEvent.ALERT_MANAGER_ERROR,
        data: {
          message,
          requestBaseUrl: error?.config?.baseUrl,
          requestUrl: error?.config?.url,
          requestMethod: error?.config?.method,
          requestData: error?.config?.data ? JSON.stringify(error?.config?.data) : undefined,
          responseData: error?.response?.data ? JSON.stringify(error?.response?.data) : undefined,
          responseStatus: error?.response?.status,
          responseStatusText: error?.response?.statusText,
        },
      })
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $alertsManager: AlertsManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $alertsManager: AlertsManagerInstance
  }
  interface Context {
    $alertsManager: AlertsManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $alertsManager: AlertsManagerInstance
  }
}

export default AlertsManagerPlugin
