export enum FormationInitial {
  BAC_PLUS_3 = 1,
  BAC_PLUS_2,
  BAC,
  CAP_BEP,
  CAP_BEP_SANS_DIPLOME,
  INFERIEUR_CAP_BEP,
  ETUDES_PRIMAIRES,
}

export enum MotifPoleEmploi {
  LICENCIEMENT_ECONOMIQUE = '1',
  RUPTURE_CONVENTIONNELLE = '2',
  AUTRE_LICENCIEMENT = '3',
  DEMISSION = '4',
  FIN_DE_CDD = '5',
  FIN_DE_MISSION_INTERIM = '6',
  RECHERCHE_PREMIER_EMPLOI = '7',
  FIN_PERIODE_INACTIVITE = '8',
  FIN_CONTRAT_AIDE = '9',
  AUTRE = '0',
}

export enum QualificationDernierEmploi {
  OUVRIER = 1,
  EMPLOYE_TECHNICIEN = 2,
  AGENT_DE_MAITRISE = 3,
  PROFESSION_INTERMEDIAIRE_CADRE = 4,
  ARTISAN_COMMERCANT = 5,
  PROFESSION_LIBERALE = 6,
  AGRICULTEUR = 7,
  SANS_QUALIFICATION = 8,
}
