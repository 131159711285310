
interface CategoryProps {
    id: string
    name: string
    showCustomer: boolean
    showDueDate: boolean
    showAmount: boolean
}

export class Category {
    readonly id: string
    readonly name: string
    readonly showCustomer: boolean
    readonly showDueDate: boolean
    readonly showAmount: boolean

    private constructor (props: CategoryProps) {
      this.id = props.id
      this.name = props.name
      this.showCustomer = props.showCustomer
      this.showDueDate = props.showDueDate
      this.showAmount = props.showAmount
    }

    public static create (props: CategoryProps): Category {
      return new Category(props)
    }
}
