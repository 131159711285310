import { ContactItem } from '~/logic/contexts/client/domain/entity/contactItem.entity'
import {
  ClientCopyTiersPrestationMessageHttpMapper,
} from '~/logic/contexts/client/infrastructure/mapper/clientCopyTiersPrestationEmailMessage.mapper'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'

export const useOpenCopyUrssafActionEmailNotReceivedMessage = () => {
  const contactRepository = useContactRepository()
  const clientStore = useClientStore()

  return async (contact: ContactItem) => {
    const customer = await contactRepository.getContactSapData(contact.id)
    if (customer) {
      const result = ClientCopyTiersPrestationMessageHttpMapper.toDomain({
        customer,
        title: 'Email d\'activation non reçu',
        subject: 'Email d\'activation du compte Urssaf particulier non reçu',
        content: `Bonjour,<br><br>
        Le particulier avec l'id : ${customer.id} ne reçoit pas le mail pour activer son compte.<br>
        Son adresse email est : ${customer.email}<br><br>
        Merci d'avance,<br><br>
        Bien cordialement`,
      })
      clientStore.toggleClientCopyTiersPrestationModal(true, result)
    }
  }
}
