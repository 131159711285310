
import { Component, Getter, Vue, Action, Watch } from 'nuxt-property-decorator'
import { IBilling } from '@abby/core-legacy'
import Modal from '~/components-legacy/layout/containers/Modal.vue'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import BillingValidityForm from '~/components-legacy/forms/billingDocument/BillingValidityForm.vue'

@Component({
  components: {
    BillingValidityForm,
    Modal,
    ASDialog,
  },
})
export default class BillingValidityDateSignatureModal extends Vue {
  @Getter('billing/isBillingValidityDateModalOpened') isBillingValidityDateModalOpened!: boolean
  @Getter('billing/billingDocumentForAction') billingDocumentForAction!: IBilling
  @Action('billing/setBillingDocumentForAction') setBillingDocumentForAction!: (value: IBilling | null) => Promise<void>
  @Action('billing/setBillingValidityDateModal') setBillingValidityDateModal!: (value: boolean) => Promise<void>
  @Action('billing/activateOnlineSignature') activateOnlineSignature!: ({ id, validityDate }: { id: string, validityDate: Date }) => Promise<void>;

  validityDate = ''
  loading = false

  @Watch('isBillingValidityDateModalOpened')
  handleIsBillingValidityDateModalOpenedChange (value: boolean) {
    if (value) {
      this.validityDate = this.$dayjs().add(1, 'month').format('YYYY-MM-DD')
    }
  }

  // update validity date and set online signature to true
  async update () {
    this.loading = true
    if (!this.billingDocumentForAction._id) { return }
    try {
      if (!this.validityDate || this.$dayjs(this.billingDocumentForAction?.validityDate).isAfter(this.validityDate)) {
        this.$alertsManager.error('La nouvelle date de validité doit être supérieure à la date actuelle.')
        return
      }
      const billing = await this.activateOnlineSignature({ id: this.billingDocumentForAction._id, validityDate: this.$dayjs(this.validityDate).toDate() })
      this.$busManager.emit('billingUpdated', billing)
      this.$alertsManager.success('La signature en ligne a été activée avec succès sur votre document')
      this.close()
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      this.loading = false
    }
  }

  close () {
    this.setBillingDocumentForAction(null)
    this.setBillingValidityDateModal(false)
  }
}
