import { Auth } from './resources/Auth'
import { Bridge } from './resources/Bridge'
import { User } from './resources/User'
import { Onboarding } from './resources/Onboarding'
import { Account } from './resources/Account'
import { Transaction } from './resources/Transaction'
import { AccountingAccounts } from './resources/AccountingAccounts'
import { Activity } from './resources/Activity'
import { Company } from './resources/Company'
import { Statistics } from './resources/Statistics'
import { Sponsorship } from './resources/Sponsorship'
import { Balance } from './resources/Balance'
import { Address } from './resources/Address'
import { File } from './resources/File'
import { Urssaf } from './resources/Urssaf'
import { Billing } from './resources/Billing'
import { Accounting } from './resources/Accounting'
import { CreationCompany } from './resources/CreationCompany'
import { Log } from './resources/Log'
import { Webhook } from './resources/Webhook'
import { CreationForm } from './resources/CreationForm'
import { Stripe } from './resources/Stripe'
import { Note } from './resources/Note'
import { IncomeBook } from './resources/IncomeBook'
import { PurchaseRegister } from './resources/PurchaseRegister'
import { Declaration } from './resources/Declaration'
import { CreationFile } from './resources/CreationFile'
import { Cerfa } from './resources/Cerfa'
import { Campaigns } from './resources/Campaigns'
import { Services } from './resources/Services'
import { Experience } from './resources/Experience'
import { Course } from './resources/Course'
import { StripeProduct } from './resources/StripeProduct'
import { Lesson } from './resources/Lesson'
import { Opportunity } from './resources/Opportunity'
import { Payment } from './resources/Payment'
import { AcreDocument } from './resources/AcreDocument'
import { Task } from './resources/Task'
import { OAuth } from './resources/OAuth'
import { Application } from './resources/Application'
import { ApiKey } from './resources/ApiKey'
import { Integration } from './resources/Integration'
import { HelpCenter } from './resources/HelpCenter'
import { CreationPackItem } from './resources/CreationPackItem'
import { TimeTracking } from './resources/TimeTracking'
import { Signature } from './resources/Signature'
import { Redirect } from './resources/Redirect'
import { UrssafTp } from './resources/UrssafTp'
import { Advertising } from './resources/Advertising'
import { Vat } from './resources/Vat'
import { GuideStep } from './resources/GuideStep'
import { Persona } from './resources/Persona'
import { Intercom } from './resources/Intercom'
import { Frequency } from './resources/Frequency'
import { Sirene } from './resources/Sirene'
import { Provider } from './resources/Provider'
import { PurchaseRegisterLegacy } from './resources/PurchaseRegisterLegacy'
import { BankList } from './resources/BankList'
import { Currency } from './resources/Currency'

export const resources = {
  acreDocument: AcreDocument,
  auth: Auth,
  bridge: Bridge,
  user: User,
  onboarding: Onboarding,
  account: Account,
  bankList: BankList,
  transaction: Transaction,
  accountingAccounts: AccountingAccounts,
  activity: Activity,
  cerfa: Cerfa,
  company: Company,
  statistics: Statistics,
  sponsorship: Sponsorship,
  balance: Balance,
  address: Address,
  urssaf: Urssaf,
  declaration: Declaration,
  campaigns: Campaigns,
  file: File,
  billing: Billing,
  accounting: Accounting,
  creationCompany: CreationCompany,
  log: Log,
  webhook: Webhook,
  creationForm: CreationForm,
  note: Note,
  stripe: Stripe,
  intercom: Intercom,
  incomeBook: IncomeBook,
  purchaseRegister: PurchaseRegister,
  purchaseRegisterLegacy: PurchaseRegisterLegacy,
  creationFile: CreationFile,
  services: Services,
  creationPackItem: CreationPackItem,
  stripeProduct: StripeProduct,
  course: Course,
  experiences: Experience,
  opportunity: Opportunity,
  payment: Payment,
  lesson: Lesson,
  task: Task,
  oauth: OAuth,
  application: Application,
  apiKey: ApiKey,
  integration: Integration,
  helpCenter: HelpCenter,
  timeTracking: TimeTracking,
  signature: Signature,
  redirect: Redirect,
  urssafTp: UrssafTp,
  advertising: Advertising,
  vat: Vat,
  guideStep: GuideStep,
  persona: Persona,
  frequency: Frequency,
  sirene: Sirene,
  provider: Provider,
  currency: Currency,
}
