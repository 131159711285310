import { PersonalServiceActivity } from '@abby/shared'
import { ProductForm } from '~/logic/contexts/product/domain/form/product.form'
import { useProductRepository } from '~/logic/contexts/product/infrastructure/repository/product.repository'
import { useToasterManager } from '~/composables/shared/manager/useToasterManager'

export const useUpdateProductPSA = () => {
  const productRepository = useProductRepository()
  const toasterManager = useToasterManager()

  return async (product: ProductForm) => {
    if (product.id) {
      const editedProduct = await productRepository.updateProductPersonalServiceActivity({
        ...product,
        id: product.id,
        personalServiceActivity: product.personalServiceActivity || PersonalServiceActivity.AIDE_HUMAINE, // uniquement à but de typage
      })
      toasterManager.success({ message: 'Le produit a bien été modifié' })
      return editedProduct
    }
  }
}
