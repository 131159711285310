import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'

export const useArcadePiniaStore = () => {
  const useArcadePiniaStore = defineStore('arcadePiniaStore', () => {
    const isOpened = ref(false)

    const toggleArcadePiniaModal = (value: boolean) => {
      isOpened.value = value
    }

    return {
      isOpened,
      toggleArcadePiniaModal,
    }
  })

  const arcadePiniaStore = useArcadePiniaStore()
  const { toggleArcadePiniaModal } = arcadePiniaStore
  const {
    isOpened,
  } = storeToRefs(arcadePiniaStore)

  return {
    isOpened,
    toggleArcadePiniaModal,
  }
}
