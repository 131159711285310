import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { ReadApplication } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ApplicationState {
  applications: ReadApplication[] | null;
  application: ReadApplication | null;
  applicationLoading: boolean;
  queryLoading: boolean;
  isApplicationModalOpened: boolean;
}

export const state = (): ApplicationState => ({
  applications: null,
  application: null,
  queryLoading: true,
  applicationLoading: true,
  isApplicationModalOpened: false,
})

export const getters: GetterTree<ApplicationState, RootState> = {
  application: state => state.application,
  applications: state => state.applications,
  queryLoading: state => state.queryLoading,
  applicationLoading: state => state.applicationLoading,
  isApplicationModalOpened: state => state.isApplicationModalOpened,
}

export const mutations: MutationTree<ApplicationState> = {
  SET_APPLICATIONS (state: ApplicationState, value: ReadApplication[]) {
    state.applications = value
  },
  SET_APPLICATION (state: ApplicationState, value: ReadApplication) {
    state.application = value
  },
  SET_APPLICATION_LOADING (state: ApplicationState, value: boolean) {
    state.applicationLoading = value
  },
  SET_APPLICATION_MODAL (state: ApplicationState, value: boolean) {
    state.isApplicationModalOpened = value
  },
  RESET (_currentState: ApplicationState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_QUERY_LOADING (state: ApplicationState, value: boolean) {
    state.queryLoading = value
  },
}

export const actions: ActionTree<ApplicationState, RootState> = {
  setApplicationModal ({ commit }, value: boolean) {
    commit('SET_APPLICATION_MODAL', value)
  },
  async fetchApplication ({ commit }, id: string) {
    commit('SET_APPLICATION_LOADING', true)
    try {
      const result = await this.$api.application.get(id)
      commit('SET_APPLICATION', result)
    } finally {
      commit('SET_APPLICATION_LOADING', false)
    }
  },
  async updateApplication ({ commit, state }, payload: any) {
    if (!state.application) {
      return
    }
    commit('SET_APPLICATION_LOADING', true)
    try {
      const result = await this.$api.application.update(state.application?.id, payload)
      commit('SET_APPLICATION', result)
    } finally {
      commit('SET_APPLICATION_LOADING', false)
    }
  },
  async fetchApplications ({ commit }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const results = await this.$api.application.getAll()
      commit('SET_APPLICATIONS', results)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
}
