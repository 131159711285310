export enum CreationFormStep {
  CREATION = 'creation',
  IDENTITY = 'identity',
  HOME_ADDRESS = 'home_address',
  MARITAL_INFO = 'marital_info',
  PARTNER_INFO = 'partner_info',
  COMPLEMENTARY_INFO = 'complementary_info',
  ACTIVITY_START = 'activity_start',
  COMPANY_INFO = 'company_info',
  COMPANY_ADDRESS = 'company_address',
  SOCIAL_DECLARATION = 'social_declaration',
  CONTACT = 'contact',
}
