import type {
  ICreationCompany, UpdateCreationCompany,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class CreationCompany extends Resource {
  getByCompanyId (companyId: string): Promise<ICreationCompany> {
    return this.axios.$get(`/creationCompany/${companyId}`)
  }

  update (creationCompanyId: string, data: UpdateCreationCompany) {
    return this.axios.$put(`/creationCompany/${creationCompanyId}`, data)
  }

  get (): Promise<ICreationCompany> {
    return this.axios.$get('/creationCompany')
  }
}
