import Vue from 'vue'

Vue.filter('text_search_highlighter', function (text: string, keywords: string, min = 3, start = false) {
  if (!keywords || !text || keywords.length < min) { return text }

  const e = ['È', 'É', 'Ê', 'Ë', 'è', 'é', 'ê', 'ë', 'e', 'E']
  const a = ['à', 'á', 'â', 'ã', 'ä', 'å', 'æ', 'À', 'Á', 'Â', 'Ã', 'Ä', 'Å', 'Æ', 'a', 'A']
  const o = ['ô', 'Ô', 'o', 'O']

  const substitutions: any = {
    e,
    E: e,
    a,
    A: a,
    o,
    O: o,
    é: e,
    è: e,
    à: a,
    '-': [' '],
    ' ': ['-'],
  }
  const pattern: any = Array.from(keywords.trim()).map(c => substitutions[c] ? `[${c}${substitutions[c].join('')}]` : c).join('')
  const search = new RegExp((start ? '^' : '') + pattern, 'ig')
  return text.replace(search, function (match, _, __) {
    return ('<span class="text-search-highlighter">' + match + '</span>')
  })
})
