import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'

export const useOpenContactTaxReturnModal = () => {
  const clientStore = useClientStore()
  const billingRepository = useBillingRepository()

  return async (contactId: string) => {
    const billingCustomer = await billingRepository.fetchCustomerFromContact(contactId)
    clientStore.toggleContactTaxReturnModal(true, billingCustomer)
  }
}
