import { ReadBankItem, Time } from '@abby/shared'
import { BankItem } from '~/logic/contexts/bank/domain/entity/bankItem.entity'

export class BankItemMapper {
  static fromHttp (response: ReadBankItem): BankItem {
    return BankItem.create({
      id: response.id,
      name: response?.bank?.name || '',
      externalId: `${response.externalId}`,
      logo: response?.bank?.logoUrl || '',
      ...(response.lastRefreshedAt && {
        lastRefreshedAt: Time.toDate(response.lastRefreshedAt),
      }),
      ...(response.startRefreshedAt && {
        startRefreshAt: Time.toDate(response.startRefreshedAt),
      }),
      ...(response.archivedAt && {
        archivedAt: Time.toDate(response.archivedAt),
      }),
      ...(response.error && {
        error: response.error,
        action: response.action,
      }),
    })
  }
}
