export enum LiberalType {
  REGULATED = 1,
  NO_REGULATED = 2,
  NO = 3,
}

export enum StatusType {
  EMPLOYEE = 1,
  FARM_EMPLOYEE = 2,
  OFFICIAL = 3,
  RETIRED = 4,
  OTHER = 5,
}
