import { Plugin } from '@nuxt/types'
import { IAuthUser } from '~/store/auth'

export interface IHelpdeskArticlePreview {
  title: string,
  content: string,
  url: string,
  id: string | null,
}

export interface HotjarInstance {
  init(params?: { hide?: boolean }): void
  sendHotjarEvent(event: string, metadata?: any): void
  waitUntilHotjarsSet(callback?: () => any): Promise<void>
  updateHotjarData(): Promise<void>
}

const HotjarPlugin: Plugin = ({ store }, inject) => {
  const getUser = (): IAuthUser => store.getters?.['auth/user']
  const getIsConnectedFromDashboard = (): boolean => store.getters['auth/connectedFromDashboard']

  const updateHotjarData = () => {
    const user = getUser()
    if (!user) { return }
    // @ts-ignore
    window?.hj?.('identify', user.id, {
      email: user?.email,
    })
  }

  const waitUntilHotjarIsSet = (callback?: () => any) => {
    if (!process.browser) { return }
    // @ts-ignore
    if (typeof window?.hj !== 'undefined') {
      callback?.()
    } else if (getIsConnectedFromDashboard()) {
      // Ne rien faire et quitter la boucle
    } else {
      setTimeout(() => { waitUntilHotjarIsSet(callback) }, 1000)
    }
  }

  inject('hotjar', {
    init () {
      waitUntilHotjarIsSet(() => {
        updateHotjarData()
      })
    },
    waitUntilHotjarIsSet,
    updateHotjarData () {
      updateHotjarData()
    },
    sendHotjarEvent (event: string) {
      try {
        // @ts-ignore
        window?.hj?.('event', event)
      } catch (_) {
        //
      }
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $hotjar: HotjarInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $hotjar: HotjarInstance
  }
  interface Context {
    $hotjar: HotjarInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $hotjar: HotjarInstance
  }
}

export default HotjarPlugin
