import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useCopyBillingConfigurationId = () => {
  const { $clipboard } = useContext()
  const billingRepository = useBillingRepository()
  const alertManager = useAlertManager()
  const billingConfiguration = billingRepository.fetchConfiguration()

  return async () => {
    await $clipboard.copy(billingConfiguration.value.id)
    alertManager.success('Le message a été copié dans le presse-papier')
  }
}
