import { Alert } from '~/plugins/alertsManager'

export interface AlertsManagerState {
  alerts: Array<Alert>
}

export const state = (): AlertsManagerState => ({
  alerts: [],
})

export const getters = {
  alerts (state: AlertsManagerState): Array<Alert> {
    return state.alerts.filter(a => a.component === 'alert')
  },
  snack (state: AlertsManagerState): Alert | undefined {
    return state.alerts.find(a => a.component === 'snack')
  },
}

export const mutations = {
  addAlert (state: AlertsManagerState, alert: Alert) {
    state.alerts = [
      // ...state.alerts,
      ...[alert],
    ]
  },
  setAlerts (state: AlertsManagerState, alerts: Array<Alert>) {
    state.alerts = alerts
  },
  removeAlert (state: AlertsManagerState, alert: Alert) {
    state.alerts = state.alerts.filter(a => a !== alert)
  },
  RESET (_currentState: AlertsManagerState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions = {
  addAlert ({ commit }: any, alert: Alert) {
    commit('addAlert', alert)
  },
}
