
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import { BillingRangeType, BillingState, BillingType, IBilling, toArray } from '@abby/core-legacy'
import BillingLine from '~/components-legacy/BillingLine.vue'
import { BillingConfigurationState } from '~/store/billingConfiguration'

@Component({ components: { BillingLine } })
export default class BillingLines extends Vue {
  @Prop({ default: {} })
  filter!: { customerId?: string, billingType?: BillingType[] | BillingType, opportunityId?: string, billingState?: BillingState[] | BillingState, search?: string, range?: [string, string], rangeType?: BillingRangeType }

  @Prop({ default: true })
  expanded!: boolean

  @Prop({ default: true })
  billingSummaryMode!: boolean

  @Prop({ default: '' })
  contentClass!: string

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: BillingConfigurationState['billingConfiguration']

  billings: IBilling[] = []
  count: number = 0

  get hasBillings () {
    return !!this.billings.length
  }

  async mounted () {
    try {
      const result = await this.$api.billing.getPaginated({
        customerId: this.filter?.customerId,
        opportunityId: this.filter?.opportunityId,
        billingState: this.filter?.billingState ? toArray(this.filter?.billingState) : undefined,
        billingType: this.filter?.billingType ? toArray(this.filter?.billingType) : undefined,
        search: this.filter?.search,
        test: this.billingConfiguration?.test || false,
        range: this.filter?.range,
        rangeType: this.filter?.rangeType,
        page: 1,
        limit: 10,
      })
      this.billings = result.docs
      this.count = result.totalDocs
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }
}
