import { ITrackCode } from '../Interface/models/ITrackCode';

export const TrackCode: Array<ITrackCode> = [
  {
    code: 'ALL',
    name: 'Allée',
  },
  {
    code: 'AV',
    name: 'Avenue',
  },
  {
    code: 'BAT',
    name: 'Batiment',
  },
  {
    code: 'BD',
    name: 'Boulevard',
  },
  {
    code: 'C',
    name: 'Cours',
  },
  {
    code: 'CAN',
    name: 'Canal',
  },
  {
    code: 'CAS',
    name: 'Caserne',
  },
  {
    code: 'CAV',
    name: 'Cavee',
  },
  {
    code: 'CEI',
    name: 'Ceinture',
  },
  {
    code: 'CEL',
    name: 'Cellier',
  },
  {
    code: 'CEN',
    name: 'Centre',
  },
  {
    code: 'CGE',
    name: 'College',
  },
  {
    code: 'CH',
    name: 'Chasse',
  },
  {
    code: 'CHA',
    name: 'Chateau',
  },
  {
    code: 'CHE',
    name: 'Chemin',
  },
  {
    code: 'CHN',
    name: 'Chantier',
  },
  {
    code: 'CHP',
    name: 'Chapelle',
  },
  {
    code: 'CHS',
    name: 'Chaussée',
  },
  {
    code: 'CHT',
    name: 'Cheminement',
  },
  {
    code: 'CHV',
    name: 'Chemin Vicinal',
  },
  {
    code: 'CI',
    name: 'Cité',
  },
  {
    code: 'CIM',
    name: 'Cimetiere',
  },
  {
    code: 'CIN',
    name: 'Cinema',
  },
  {
    code: 'CL',
    name: 'Clos',
  },
  {
    code: 'CLB',
    name: 'CLUB',
  },
  {
    code: 'CLI',
    name: 'Clinique',
  },
  {
    code: 'CLL',
    name: 'Col',
  },
  {
    code: 'CLO',
    name: 'Cloitre',
  },
  {
    code: 'CN',
    name: 'Coin',
  },
  {
    code: 'CO',
    name: 'Cour',
  },
  {
    code: 'COE',
    name: 'Colline',
  },
  {
    code: 'COG',
    name: 'Cottage',
  },
  {
    code: 'COL',
    name: 'Colonie',
  },
  {
    code: 'COM',
    name: 'Commissariat',
  },
  {
    code: 'COR',
    name: 'Corniche',
  },
  {
    code: 'COT',
    name: 'Cote',
  },
  {
    code: 'COU',
    name: 'Couloir',
  },
  {
    code: 'CRO',
    name: 'Carroi',
  },
  {
    code: 'CRX',
    name: 'CROIX',
  },
  {
    code: 'CTE',
    name: 'Central Telephonique',
  },
  {
    code: 'CUR',
    name: 'Cure',
  },
  {
    code: 'DEG',
    name: 'Degre',
  },
  {
    code: 'DEP',
    name: 'Depot',
  },
  {
    code: 'DES',
    name: 'Descente',
  },
  {
    code: 'DIG',
    name: 'Digue',
  },
  {
    code: 'DOM',
    name: 'Domaine',
  },
  {
    code: 'DON',
    name: 'Direction',
  }, {
    code: 'ECA',
    name: 'Ecart',
  }, {
    code: 'ECL',
    name: 'Ecluse',
  }, {
    code: 'ECO',
    name: 'Ecole',
  }, {
    code: 'ECV',
    name: 'Enclave',
  }, {
    code: 'EGL',
    name: 'Eglise',
  }, {
    code: 'EMP',
    name: 'Emplacement',
  }, {
    code: 'EN',
    name: 'Enceinte',
  }, {
    code: 'ENC',
    name: 'Enclos',
  }, {
    code: 'ENT',
    name: 'Entrepot',
  }, {
    code: 'ESC',
    name: 'Escalier',
  }, {
    code: 'ESP',
    name: 'Esplanade',
  }, {
    code: 'ETA',
    name: 'Etablissement',
  }, {
    code: 'ETG',
    name: 'Etang',
  }, {
    code: 'FAC',
    name: 'Facade',
  }, {
    code: 'FCU',
    name: 'Faculte',
  }, {
    code: 'FER',
    name: 'Ferme',
  },
  {
    code: 'FG',
    name: 'Faubourg',
  },
  {
    code: 'FOL',
    name: 'Folie',
  },
  {
    code: 'FON',
    name: 'Fontaine',
  },
  {
    code: 'FOR',
    name: 'Foret',
  },
  {
    code: 'FOS',
    name: 'Fosse',
  },
  {
    code: 'FOY',
    name: 'Foyer',
  },
  {
    code: 'FRI',
    name: 'Friche',
  },
  {
    code: 'FT',
    name: 'Fort',
  }, {
    code: 'GA',
    name: 'Gare',
  }, {
    code: 'GAL',
    name: 'Galerie',
  }, {
    code: 'GDI',
    name: 'Grand impasse',
  }, {
    code: 'GED',
    name: 'Gendarmerie',
  }, {
    code: 'GEN',
    name: 'Grand ensemble',
  }, {
    code: 'GGE',
    name: 'Garage',
  }, {
    code: 'GLA',
    name: 'Glacis',
  }, {
    code: 'GPE',
    name: 'G',
  }, {
    code: 'GR',
    name: 'Grand Rue',
  }, {
    code: 'GRI',
    name: 'Grille',
  }, {
    code: 'GRN',
    name: 'Garenne',
  }, {
    code: 'GRP',
    name: 'Groupement',
  }, {
    code: 'GSC',
    name: 'Groupe Scolaire',
  }, {
    code: 'GUE',
    name: 'Gue',
  }, {
    code: 'HA',
    name: 'Halage',
  }, {
    code: 'HAL',
    name: 'Halle',
  },
  {
    code: 'HAM',
    name: 'Hameau',
  },
  {
    code: 'HCE',
    name: 'Hospice',
  },
  {
    code: 'HIP',
    name: 'Hippodrome',
  },
  {
    code: 'HLM',
    name: 'H.L.M.',
  }, {
    code: 'HOP',
    name: 'Hopital',
  }, {
    code: 'HOT',
    name: 'Hotel',
  }, {
    code: 'HUT',
    name: 'Hutte',
  },
  {
    code: 'ILE',
    name: 'Ile',
  },
  {
    code: 'ILO',
    name: 'Ilot',
  },
  {
    code: 'IMB',
    name: 'Immeuble',
  },
  {
    code: 'IMP',
    name: 'Impasse',
  }, {
    code: 'INS',
    name: 'Institut',
  }, {
    code: 'ISP',
    name: 'Inspection',
  }, {
    code: 'JAR',
    name: 'Jardin',
  }, {
    code: 'JET',
    name: 'Jetee',
  }, {
    code: 'JUS',
    name: 'Justice',
  }, {
    code: 'LD',
    name: 'Lieu dit',
  }, {
    code: 'LEV',
    name: 'Levee',
  },
  {
    code: 'LOT',
    name: 'Lotissement',
  },
  {
    code: 'LYC',
    name: 'Lycee',
  }, {
    code: 'MA',
    name: 'Mare',
  }, {
    code: 'MAG',
    name: 'Magasin',
  },
  {
    code: 'MAI',
    name: 'Maison',
  }, {
    code: 'MAN',
    name: 'Manoir',
  }, {
    code: 'MAR',
    name: 'Marche',
  }, {
    code: 'MAS',
    name: 'Mas',
  }, {
    code: 'MAT',
    name: 'Maternite',
  }, {
    code: 'MET',
    name: 'Metro',
  }, {
    code: 'MF',
    name: 'Maison forestiere',
  }, {
    code: 'MG',
    name: 'Maison de garde',
  }, {
    code: 'ML',
    name: 'Mail',
  }, {
    code: 'MOU',
    name: 'Moulin',
  }, {
    code: 'MRI',
    name: 'Mairie',
  },
  {
    code: 'MTE',
    name: 'Montée',
  },
  {
    code: 'MUS',
    name: 'Musee',
  },
  {
    code: 'NTE',
    name: 'Nouvelle route',
  },
  {
    code: 'OBS',
    name: 'Observatoire',
  },
  {
    code: 'OCT',
    name: 'Octroi',
  }, {
    code: 'PA',
    name: 'Parvis',
  }, {
    code: 'PAE',
    name: 'Petite avenue',
  }, {
    code: 'PAL',
    name: 'Palais',
  },
  {
    code: 'PAR',
    name: 'Parc',
  },
  {
    code: 'PAS',
    name: 'Passage',
  },
  {
    code: 'PAV',
    name: 'Pavillon',
  }, {
    code: 'PBY',
    name: 'Presbytere',
  }, {
    code: 'PCE',
    name: 'Porche',
  }, {
    code: 'PER',
    name: 'Perystile',
  }, {
    code: 'PHA',
    name: 'Pharmacie',
  }, {
    code: 'PIE',
    name: 'Piece',
  }, {
    code: 'PIM',
    name: 'Petite impasse',
  }, {
    code: 'PKG',
    name: 'Parking',
  },
  {
    code: 'PL',
    name: 'Place',
  }, {
    code: 'PLA',
    name: 'Plaine',
  }, {
    code: 'PLC',
    name: 'Placis',
  }, {
    code: 'PLE',
    name: 'Passerelle',
  }, {
    code: 'PLT',
    name: 'Plateau',
  }, {
    code: 'PLY',
    name: 'Petit lycee',
  }, {
    code: 'PO',
    name: 'Pont',
  }, {
    code: 'POI',
    name: 'Pointe',
  },
  {
    code: 'POR',
    name: 'Porte',
  }, {
    code: 'POT',
    name: 'Poterne',
  }, {
    code: 'POU',
    name: 'Pourtour',
  }, {
    code: 'PPA',
    name: 'Petit passage',
  }, {
    code: 'PR',
    name: 'Petite rue',
  }, {
    code: 'PRA',
    name: 'Prairie',
  }, {
    code: 'PRE',
    name: 'Pre',
  }, {
    code: 'PRO',
    name: 'Promenade',
  }, {
    code: 'PRQ',
    name: 'Presquile',
  }, {
    code: 'PRT',
    name: 'Petite route',
  }, {
    code: 'PRU',
    name: 'Preau',
  }, {
    code: 'PT',
    name: 'Port',
  },
  {
    code: 'PTE',
    name: 'Poste',
  },
  {
    code: 'Q',
    name: 'Quai',
  },
  {
    code: 'QU',
    name: 'Quartier',
  },
  {
    code: 'R',
    name: 'Rue',
  }, {
    code: 'RAC',
    name: 'Raccourci',
  },
  {
    code: 'RAM',
    name: 'Rampe',
  }, {
    code: 'REM',
    name: 'Rempart',
  },
  {
    code: 'RES',
    name: 'Résidence',
  }, {
    code: 'RNT',
    name: 'Restaurant',
  }, {
    code: 'ROC',
    name: 'Rocade',
  }, {
    code: 'ROQ',
    name: 'Roquet',
  }, {
    code: 'ROT',
    name: 'Rotonde',
  }, {
    code: 'ROU',
    name: 'Roulade',
  },
  {
    code: 'RP',
    name: 'Rond point',
  },
  {
    code: 'RTE',
    name: 'Route',
  }, {
    code: 'RUL',
    name: 'Ruelle',
  }, {
    code: 'SAN',
    name: 'Sanatorium',
  }, {
    code: 'SAP',
    name: 'Sapum',
  }, {
    code: 'SAS',
    name: 'SAS',
  }, {
    code: 'SCE',
    name: 'Service',
  }, {
    code: 'SEC',
    name: 'Section',
  }, {
    code: 'SEM',
    name: 'Seminaire',
  },
  {
    code: 'SEN',
    name: 'Sentier',
  }, {
    code: 'SNT',
    name: 'Sente',
  }, {
    code: 'SOC',
    name: 'Societe',
  },
  {
    code: 'SQ',
    name: 'Square',
  }, {
    code: 'STA',
    name: 'Stade',
  }, {
    code: 'STN',
    name: 'Stand',
  }, {
    code: 'STO',
    name: 'Station',
  }, {
    code: 'TE',
    name: 'Terrasse',
  }, {
    code: 'TEA',
    name: 'Terrain',
  }, {
    code: 'TEN',
    name: 'Tenue',
  }, {
    code: 'TER',
    name: 'Tertre',
  }, {
    code: 'THE',
    name: 'Theatre',
  }, {
    code: 'TOU',
    name: 'Tour',
  }, {
    code: 'TP',
    name: 'Terre plein',
  }, {
    code: 'TRA',
    name: 'Traverse',
  }, {
    code: 'TRG',
    name: 'Triege',
  }, {
    code: 'TRI',
    name: 'Triage',
  }, {
    code: 'TUI',
    name: 'Tuilerie',
  }, {
    code: 'USI',
    name: 'Usine',
  }, {
    code: 'VAL',
    name: 'Val',
  }, {
    code: 'VCH',
    name: 'Vieux chemin',
  }, {
    code: 'VEL',
    name: 'Velodrome',
  }, {
    code: 'VEN',
    name: 'Venelle',
  }, {
    code: 'VGE',
    name: 'Village',
  }, {
    code: 'VIA',
    name: 'Viaduc',
  }, {
    code: 'VIL',
    name: 'Ville',
  }, {
    code: 'VLA',
    name: 'Villa',
  },
  {
    code: 'VLE',
    name: 'Vallee',
  },
  {
    code: 'VNO',
    name: 'Ville nouvelle',
  },
  {
    code: 'VO',
    name: 'Voie',
  }, {
    code: 'VON',
    name: 'Vallon',
  }, {
    code: 'VOY',
    name: 'Voyeul',
  }, {
    code: 'ZAC',
    name: 'ZAC',
  }, {
    code: 'ZI',
    name: 'Zone industrielle',
  }, {
    code: 'ZUP',
    name: 'Z.U.P.',
  },
];
