import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { OrganizationHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/organizationHttp.mapper'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'

export const useFetchOrganization = () => {
  const organizationRepository = useOrganizationRepository()
  const billingRepository = useBillingRepository()

  return async (organizationId: string) => {
    const readOrganization = await organizationRepository.getOrganizationById(organizationId)
    const organization = OrganizationHttpMapper.toDomain(readOrganization)
    const billingCustomer = await billingRepository.fetchCustomerFromOrganization(organizationId)
    organization.customerId = billingCustomer.id
    return organization
  }
}
