import { useContext } from '@nuxtjs/composition-api'
import { PlanFeature } from '@abby/core-legacy'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'
import { useMoveManager } from '~/composables/shared/manager/useMoveManager'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useActivateOnlinePayment = () => {
  const { $confirmManager } = useContext()
  const moveManager = useMoveManager()
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    if (!billingStore.retrieveOnlinePaymentIsActivated()) {
      moveManager.move('/settings/billing/payment')
      return
    }
    const confirm = await $confirmManager.ask({
      title: 'Activer le paiement en ligne',
      confirmText: 'Activer',
      content: `Souhaitez-vous activer le paiement en ligne pour le document ${billing.number} ?`,
      confirmButton: { color: 'primary', depressed: true },
      feature: PlanFeature.BILLING_ONLINE_PAYMENT,
    })
    if (!confirm) { return }

    await billingRepository.activateOnlinePayment(billing)
    alertManager.success('Le paiement en ligne a été activé avec succès sur votre document')
  }
}
