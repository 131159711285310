
import { Action, Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import { AbbyPlans, CampaignType, ICompany, StripeProductFrequency } from '@abby/core-legacy'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import AbbyGestionPlans from '~/components-legacy/modals/commercialOffers/AbbyGestionPlans.vue'
import Page from '~/components-legacy/layout/containers/Page.vue'
import { PaymentParams } from '~/store/payment'

@Component({
  components: {
    Page,
    AbbyGestionPlans,
    ASDialog,
  },
})
export default class AbbyPlansModal extends Vue {
  @Getter('payment/isAbbyPlansModalOpened') isAbbyPlansModalOpened!: boolean;
  @Action('payment/fetchSubscriptions') fetchSubscriptions!: () => Promise<void>;
  @Getter('company/isInCreation') isInCreation!: boolean
  @Getter('company/company') company!: ICompany
  @Getter('payment/params') params!: PaymentParams
  @Action('payment/setAbbyPlansModalOpened') setAbbyPlansModalOpened!: (value: boolean) => Promise<void>;

  loading: boolean = true
  closeDelay: number = 0
  displaySpecialMessage: boolean = false

  promotionTimer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    daysStr1: '0',
    daysStr2: '0',
    hoursStr1: '0',
    hoursStr2: '0',
    minutesStr1: '0',
    minutesStr2: '0',
    secondsStr1: '0',
    secondsStr2: '0',
  }

  mounted () {
    setInterval(() => {
      const endDate = this.$dayjs('2024-11-03').endOf('d').toDate().getTime()
      const now = this.$dayjs().toDate().getTime()
      const diff = endDate - now

      let seconds = Math.floor(diff / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      seconds %= 60
      minutes %= 60
      hours %= 24

      this.promotionTimer = {
        days,
        hours,
        minutes,
        seconds,
        daysStr1: days.toString().padStart(2, '0').charAt(0),
        daysStr2: days.toString().padStart(2, '0').charAt(1),
        hoursStr1: hours.toString().padStart(2, '0').charAt(0),
        hoursStr2: hours.toString().padStart(2, '0').charAt(1),
        minutesStr1: minutes.toString().padStart(2, '0').charAt(0),
        minutesStr2: minutes.toString().padStart(2, '0').charAt(1),
        secondsStr1: seconds.toString().padStart(2, '0').charAt(0),
        secondsStr2: seconds.toString().padStart(2, '0').charAt(1),
      }
    }, 1000)
  }

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get is3daysRemaining () {
    const days = this.$dayjs('2025-03-31').diff(this.$dayjs(), 'days')
    return days <= 3
  }

  @Watch('isOpen')
  async handleIsOpen (value: boolean) {
    if (value) {
      try {
        this.loading = true
        await this.$stripe.loadStripe()
        await this.fetchSubscriptions()
        await this.$store.dispatch('auth/fetchUserSponsorship')
        await this.$store.dispatch('company/fetchPlans')
        if (this.params?.step) {
          this.$store.dispatch('payment/openPaymentModal', { product: this.params.product, frequency: this.params.frequency, step: this.params.step })
        } else if (this.params.closeDelay) {
          this.displaySpecialMessage = true
          this.closeDelay = this.params.closeDelay / 1000
          const interval = setInterval(() => {
            if (this.closeDelay <= 0) { this.closeDelay = 0; clearInterval(interval) } else {
              this.closeDelay -= 1
            }
          }, 1000)
        } else {
          this.displaySpecialMessage = false
          this.closeDelay = 0
        }
      } catch (e) {
        //
      } finally {
        this.loading = false
      }
    }
  }

  get isPromotionDay () {
    // return this.$dayjs().isBetween('2024-02-01', '2025-03-31', 'day', '[]')
    return false
  }

  get isVP45PromotionActive () {
    return !this.$campaignsManager.isDisplayable(CampaignType.ABBY_BUSINESS_LAUNCH) &&
      this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO &&
      this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
  }

  get promotion () {
    if (
      !this.$campaignsManager.isDisplayable(CampaignType.ABBY_BUSINESS_LAUNCH) &&
      this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO &&
      this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    ) {
      return {
        text: '-45 % en souscrivant maintenant avec le code ',
        code: 'VP45',
      }
    }
    return {
      text: '-40 % en souscrivant maintenant avec le code ',
      code: 'BUSINESS40',
    }
  }

  get isOpen () {
    return this.isAbbyPlansModalOpened
  }

  set isOpen (value: boolean) {
    this.setAbbyPlansModalOpened(value)
  }

  close () {
    if (this.closeDelay) { return }
    this.isOpen = false
  }

  get bannerConfig () {
    // TODO : Ajouter des conditions pour créer des messages personnalisés
    // Inscris en janvier & créateurs
    return {
      title: 'Un logiciel tout en un pour votre activité',
      subtitle: 'Choisissez le forfait le plus adapté à votre entreprise',
    }
  }
}
