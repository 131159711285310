
export enum BillingAction {
    EDIT = 'edit',
    FINALIZE = 'finalize',
    FINALIZE_AND_SEND = 'finalizeAndSend',
    DOWNLOAD = 'download',
    MARK_AS_PAID = 'markAsPaid',
    MARK_AS_PAID_ASSET = 'markAsPaidAsset',
    SEND = 'send',
    MARK_AS_UNPAID = 'markAsUnpaid',
    MARK_AS_SIGNED = 'markAsSigned',
    MARK_AS_NOT_SIGNED = 'markAsUnsigned',
    MARK_AS_REFUSED = 'markAsRefused',
    DELETE = 'delete',
    MARK_AS_NOT_REFUSED = 'markAsNotRefused',
    DOWNLOAD_SIGNATURE_PROOF = 'downloadSignatureProof',
    GET_PAYMENT_LINK = 'getPaymentLink',
    GET_SIGNATURE_LINK = 'getSignatureLink',
    VIEW_DETAILS = 'viewDetails',
    VIEW_OPPORTUNITY = 'viewOpportunity',
    CREATE_ASSET = 'createAsset',
    CREATE_FINAL_INVOICE = 'createFinalInvoice',
    CREATE_ADVANCE = 'createAdvance',
    DUPLICATE_TO_ESTIMATE = 'duplicateToEstimate',
    DUPLICATE_TO_INVOICE = 'duplicateToInvoice',
    DUPLICATE_TO_PURCHASE_ORDER = 'duplicateToPurchaseOrder',
    ARCHIVE = 'archive',
    UNARCHIVE = 'unarchive',
    EDIT_TITLE = 'editTitle',
    CANCEL = 'cancel',
    MANAGE_RECURRING = 'manageRecurring',
    MANAGE_REMINDER = 'manageReminder',
    UNPAID_BILLING = 'unpaidBilling',
    VIEW_CONTACT = 'viewContact',
    VIEW_ORGANIZATION = 'viewOrganization',
    ACTIVATE_ONLINE_SIGNATURE = 'activateOnlineSignature',
    ACTIVATE_ONLINE_PAYMENT = 'activateOnlinePayment',
    CHECK_URSSAF_TP_STATUT = 'checkUrssafTpStatut',
    DOWNLOAD_FRENCH_LOCALE = 'downloadFrenchLocale',
    // admin actions
    ADMIN_DELETE_DOCUMENT = 'adminDeleteDocument',
    ADMIN_COPY_DOCUMENT_ID = 'adminCopyDocumentId',
    ADMIN_COPY_ISSUE_PARTICULIER_INCONNU = 'adminCopyIssueParticulierInconnu',
    ADMIN_COPY_BILLING_CONFIGURATION_ID = 'adminCopyBillingConfigurationId',
    ADMIN_REGENERATE_DOCUMENT = 'adminRegenerateDocument',
    ADMIN_FIX_CUSTOMER_ADDRESS = 'adminFixCustomerAddress',
    // deprecated actions
    ADMIN_UNLINK_FROM_CREATE_FROM = 'adminUnlinkFromCreateFrom',
    // resolve issue
    ADMIN_RESOLVE_NUMBER = 'adminResolveNumber',
}
