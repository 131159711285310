import {
  Type,
  ProductType,
  Code,
} from '@abby/core-legacy'
import type {
  BasePaginateMongo,
  CreateIncomeBookItem,
  QueryDownloadIncomeBook,
  QueryIncomeBook,
  QueryIncomeBookStatistics,
  ReadIncomeBookItem,
  ReadIncomeBookStatistics,
  UpdateIncomeBook,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class IncomeBook extends Resource {
  getPaginated (params: QueryIncomeBook): Promise<BasePaginateMongo<ReadIncomeBookItem>> {
    return this.axios.$get('/incomeBook/items', { params })
  }

  createItem (payload: CreateIncomeBookItem) {
    return this.axios.$post('/incomeBook', payload)
  }

  updateItem (id: string, payload: UpdateIncomeBook | { productType: ProductType }) {
    return this.axios.$put(`/incomeBook/${id}`, payload)
  }

  createItems (payload: CreateIncomeBookItem[]): Promise<ReadIncomeBookItem[]> {
    return this.axios.$post('/incomeBook/items', payload)
  }

  deleteItem (id: string) {
    return this.axios.$delete(`/incomeBook/${id}`)
  }

  downloadBook (params: QueryDownloadIncomeBook): Promise<any> {
    return this.axios.get('/incomeBook/download', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.incomeBook,
      type: Type.resourceError,
      message: 'income_book.export_no_data',
    }))
  }

  getStatistics (params: QueryIncomeBookStatistics): Promise<ReadIncomeBookStatistics> {
    return this.axios.$get('incomeBook/statistics', { params })
  }

  regenerateIncomeBook (companyId: string): Promise<any[]> {
    return this.axios.$post(`/migration/migrate-billing-company/${companyId}`)
  }
}
