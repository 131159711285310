import { PlanFeature } from '@abby/core-legacy'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export const useArchiveBilling = () => {
  const planManager = usePlanManager()
  const busManager = useBusManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    if (!planManager.hasAccessToFeature(PlanFeature.BILLING_ARCHIVE_DOCUMENTS)) {
      return planManager.openPlansModal(PlanFeature.BILLING_ARCHIVE_DOCUMENTS)
    }
    await billingRepository.archiveBilling(billing.id)
    billing.archivedAt = new Date()
    busManager.emit('billingUpdated', billing)
  }
}
