export enum UrssafLaneType {
  ALLEE = 'allee',
  AVENUE = 'avenue',
  BATIMENT = 'batiment',
  BOULEVARD = 'boulevard',
  COURS = 'cours',
  CANAL = 'canal',
  CASERNE = 'caserne',
  CAVEE = 'cavee',
  CEINTURE = 'ceinture',
  CELLIER = 'cellier',
  CENTRE = 'centre',
  COLLEGE = 'college',
  CHASSE = 'chasse',
  CHATEAU = 'chateau',
  CHEMIN = 'chemin',
  CHANTIER = 'chantier',
  CHAPELLE = 'chapelle',
  CHAUSSEE = 'chaussee',
  CHEMINEMENT = 'cheminement',
  CHEMIN_VICINAL = 'chemin_vicinal',
  CITE = 'cite',
  CIMETIERE = 'cimetiere',
  CINEMA = 'cinema',
  CLOS = 'clos',
  CLUB = 'club',
  CLINIQUE = 'clinique',
  COL = 'col',
  CLOITRE = 'cloitre',
  COIN = 'coin',
  COUR = 'cour',
  COLLINE = 'colline',
  COTTAGE = 'cottage',
  COLONIE = 'colonie',
  COMMISSARIAT = 'commissariat',
  CORNICHE = 'corniche',
  COTE = 'cote',
  COULOIR = 'couloir',
  CARROI = 'carroi',
  CROIX = 'croix',
  CENTRAL_TELEPHONIQUE = 'central_telephonique',
  CURE = 'cure',
  DEGRE = 'degre',
  DEPOT = 'depot',
  DESCENTE = 'descente',
  DIGUE = 'digue',
  DOMAINE = 'domaine',
  DIRECTION = 'direction',
  ECART = 'ecart',
  ECLUSE = 'ecluse',
  ECOLE = 'ecole',
  ENCLAVE = 'enclave',
  EGLISE = 'eglise',
  EMPLACEMENT = 'emplacement',
  ENCEINTE = 'enceinte',
  ENCLOS = 'enclos',
  ENTREPOT = 'entrepot',
  ESCALIER = 'escalier',
  ESPLANADE = 'esplanade',
  ETABLISSEMENT = 'etablissement',
  ETANG = 'etang',
  FACADE = 'facade',
  FACULTE = 'faculte',
  FERME = 'ferme',
  FAUBOURG = 'faubourg',
  FOLIE = 'folie',
  FONTAINE = 'fontaine',
  FORET = 'foret',
  FOSSE = 'fosse',
  FOYER = 'foyer',
  FRICHE = 'friche',
  FORT = 'fort',
  GARE = 'gare',
  GALERIE = 'galerie',
  GRAND_IMPASSE = 'grand_impasse',
  GENDARMERIE = 'gendarmerie',
  GRAND_ENSEMBLE = 'grand_ensemble',
  GARAGE = 'garage',
  GLACIS = 'glacis',
  G = 'g',
  GRAND_RUE = 'grand_rue',
  GRILLE = 'grille',
  GARENNE = 'garenne',
  GROUPEMENT = 'groupement',
  GROUPE_SCOLAIRE = 'groupe_scolaire',
  GUE = 'gue',
  HALAGE = 'halage',
  HALLE = 'halle',
  HAMEAU = 'hameau',
  HOSPICE = 'hospice',
  HIPPODROME = 'hippodrome',
  HLM = 'hlm',
  HOPITAL = 'hopital',
  HOTEL = 'hotel',
  HUTTE = 'hutte',
  ILE = 'ile',
  ILOT = 'ilot',
  IMMEUBLE = 'immeuble',
  IMPASSE = 'impasse',
  INSTITUT = 'institut',
  INSPECTION = 'inspection',
  JARDIN = 'jardin',
  JETEE = 'jetee',
  JUSTICE = 'justice',
  LIEU_DIT = 'lieu_dit',
  LEVEE = 'levee',
  LOTISSEMENT = 'lotissement',
  LYCEE = 'lycee',
  MARE = 'mare',
  MAGASIN = 'magasin',
  MAISON = 'maison',
  MANOIR = 'manoir',
  MARCHE = 'marche',
  MAS = 'mas',
  MATERNITE = 'maternite',
  METRO = 'metro',
  MAISON_FORESTIERE = 'maison_forestiere',
  MAISON_DE_GARDE = 'maison_de_garde',
  MAIL = 'mail',
  MOULIN = 'moulin',
  MAIRIE = 'mairie',
  MONTEE = 'montee',
  MUSEE = 'musee',
  NOUVELLE_ROUTE = 'nouvelle_route',
  OBSERVATOIRE = 'observatoire',
  OCTROI = 'octroi',
  PARVIS = 'parvis',
  PETITE_AVENUE = 'petite_avenue',
  PALAIS = 'palais',
  PARC = 'parc',
  PASSAGE = 'passage',
  PAVILLON = 'pavillon',
  PRESBYTERE = 'presbytere',
  PORCHE = 'porche',
  PERYSTILE = 'perystile',
  PHARMACIE = 'pharmacie',
  PIECE = 'piece',
  PETITE_IMPASSE = 'petite_impasse',
  PARKING = 'parking',
  PLACE = 'place',
  PLAINE = 'plaine',
  PLACIS = 'placis',
  PASSERELLE = 'passerelle',
  PLATEAU = 'plateau',
  PETIT_LYCEE = 'petit_lycee',
  PONT = 'pont',
  POINTE = 'pointe',
  PORTE = 'porte',
  POTERNE = 'poterne',
  POURTOUR = 'pourtour',
  PETIT_PASSAGE = 'petit_passage',
  PETITE_RUE = 'petite_rue',
  PRAIRIE = 'prairie',
  PRE = 'pre',
  PROMENADE = 'promenade',
  PRESQUILE = 'presquile',
  PETITE_ROUTE = 'petite_route',
  PREAU = 'preau',
  PORT = 'port',
  POSTE = 'poste',
  QUAI = 'quai',
  QUARTIER = 'quartier',
  RUE = 'rue',
  RACCOURCI = 'raccourci',
  RAMPE = 'rampe',
  REMPART = 'rempart',
  RESIDENCE = 'residence',
  RESTAURANT = 'restaurant',
  ROCADE = 'rocade',
  ROQUET = 'roquet',
  ROTONDE = 'rotonde',
  ROULADE = 'roulade',
  ROND_POINT = 'rond_point',
  ROUTE = 'route',
  RUELLE = 'ruelle',
  SANATORIUM = 'sanatorium',
  SAPUM = 'sapum',
  SAS = 'sas',
  SERVICE = 'service',
  SECTION = 'section',
  SEMINAIRE = 'seminaire',
  SENTIER = 'sentier',
  SENTE = 'sente',
  SOCIETE = 'societe',
  SQUARE = 'square',
  STADE = 'stade',
  STAND = 'stand',
  STATION = 'station',
  TERRASSE = 'terrasse',
  TERRAIN = 'terrain',
  TENUE = 'tenue',
  TERTRE = 'tertre',
  THEATRE = 'theatre',
  TOUR = 'tour',
  TERRE_PLEIN = 'terre_plein',
  TRAVERSE = 'traverse',
  TRIEGE = 'triege',
  TRIAGE = 'triage',
  TUILERIE = 'tuilerie',
  USINE = 'usine',
  VAL = 'val',
  VIEUX_CHEMIN = 'vieux_chemin',
  VELODROME = 'velodrome',
  VENELLE = 'venelle',
  VILLAGE = 'village',
  VIADUC = 'viaduc',
  VILLE = 'ville',
  VILLA = 'villa',
  VALLEE = 'vallee',
  VILLE_NOUVELLE = 'ville_nouvelle',
  VOIE = 'voie',
  VALLON = 'vallon',
  VOYEUL = 'voyeul',
  ZAC = 'zac',
  ZONE_INDUSTRIELLE = 'zone_industrielle',
  ZUP = 'zup',
}
