import { ImpositionType } from './ImpositionType';
import { Location } from './Location';

export enum UrssafRateType {
  VENTE_DE_MARCHANDISES_BIC = 'VENTE_DE_MARCHANDISES_BIC',
  PRESTATIONS_DE_SERVICES_BIC = 'PRESTATIONS_DE_SERVICES_BIC',
  PRESTATIONS_DE_SERVICES_BNC = 'PRESTATIONS_DE_SERVICES_BNC',
  CONTRIBUTION_FORMATION_PROFESSIONNELLE = 'CONTRIBUTION_FORMATION_PROFESSIONNELLE',
  TAXE_CHAMBRE_CONSULAIRE_VENTES = 'TAXE_CHAMBRE_CONSULAIRE_VENTES',
  TAXE_CHAMBRE_CONSULAIRE_PRESTATIONS_SERVICES = 'TAXE_CHAMBRE_CONSULAIRE_PRESTATIONS_SERVICES',
  VERSEMENT_LIBERATOIRE_PRESTATIONS_BIC = 'VERSEMENT_LIBERATOIRE_PRESTATIONS_BIC',
  VERSEMENT_LIBERATOIRE_VENTE_BIC = 'VERSEMENT_LIBERATOIRE_VENTE_BIC',
  VERSEMENT_LIBERATOIRE_PRESTATIONS_BNC = 'VERSEMENT_LIBERATOIRE_PRESTATIONS_BNC',
}

export enum UrssafInformationCase {
  BEFORE_2018 = 'BEFORE_2018',
  AFTER_2018 = 'AFTER_2018',
  CIPAV = 'CIPAV',
  DEFAULT = 'DEFAULT',
}

export const defaultUrssafRateConfigurationItems = [{
  type: UrssafRateType.VENTE_DE_MARCHANDISES_BIC,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
  [Location.DROM_COM]: {
    [UrssafInformationCase.DEFAULT]: {
      acreValue: 8.2,
      value: 8.2,
    },
  },

  [Location.METROPOLE]: {
    [UrssafInformationCase.DEFAULT]: {
      acreValue: 6.2,
      value: 12.3,
    },
  },
},
{
  type: UrssafRateType.PRESTATIONS_DE_SERVICES_BIC,
  [Location.DROM_COM]: {
    [UrssafInformationCase.DEFAULT]: {
      acreValue: 14.2,
      value: 14.2,
    },
  },
  [Location.METROPOLE]: {
    [UrssafInformationCase.DEFAULT]: {
      acreValue: 10.6,
      value: 21.2,
    },
  },
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.PRESTATIONS_DE_SERVICES_BNC,
  [Location.DROM_COM]: {
    [UrssafInformationCase.AFTER_2018]: {
      acreValue: 14.10,
      value: 14.10,
    },
    [UrssafInformationCase.BEFORE_2018]: {
      acreValue: 14.10,
      value: 14.10,
    },
    [UrssafInformationCase.CIPAV]: {
      acreValue: 14.20,
      value: 14.20,
    },
  },
  [Location.METROPOLE]: {
    [UrssafInformationCase.AFTER_2018]: {
      acreValue: 11.55,
      value: 23.1,
    },
    [UrssafInformationCase.BEFORE_2018]: {
      acreValue: 10.6,
      value: 23.2,
    },
    [UrssafInformationCase.CIPAV]: {
      acreValue: 11.6,
      value: 23.2,
    },
  },

  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.CONTRIBUTION_FORMATION_PROFESSIONNELLE,
  value: 0.1,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.TAXE_CHAMBRE_CONSULAIRE_VENTES,
  value: 0,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.TAXE_CHAMBRE_CONSULAIRE_PRESTATIONS_SERVICES,
  value: 0,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.VERSEMENT_LIBERATOIRE_PRESTATIONS_BIC,
  value: 1.7,
  impositionTypes: [ImpositionType.LIBERATOIRE],
},
{
  type: UrssafRateType.VERSEMENT_LIBERATOIRE_VENTE_BIC,
  value: 1,
  impositionTypes: [ImpositionType.LIBERATOIRE],
},
{
  type: UrssafRateType.VERSEMENT_LIBERATOIRE_PRESTATIONS_BNC,
  value: 2.2,
  impositionTypes: [ImpositionType.LIBERATOIRE],
}];

export const defaultUrssafRateConfiguration = [{
  type: UrssafRateType.VENTE_DE_MARCHANDISES_BIC,
  value: 12.3,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.PRESTATIONS_DE_SERVICES_BIC,
  value: 21.2,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.PRESTATIONS_DE_SERVICES_BNC,
  value: 23.1,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.CONTRIBUTION_FORMATION_PROFESSIONNELLE,
  value: 0.1,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.TAXE_CHAMBRE_CONSULAIRE_VENTES,
  value: 0,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.TAXE_CHAMBRE_CONSULAIRE_PRESTATIONS_SERVICES,
  value: 0,
  impositionTypes: [ImpositionType.LIBERATOIRE, ImpositionType.CLASSIC],
},
{
  type: UrssafRateType.VERSEMENT_LIBERATOIRE_PRESTATIONS_BIC,
  value: 1.7,
  impositionTypes: [ImpositionType.LIBERATOIRE],
},
{
  type: UrssafRateType.VERSEMENT_LIBERATOIRE_VENTE_BIC,
  value: 1,
  impositionTypes: [ImpositionType.LIBERATOIRE],
},
{
  type: UrssafRateType.VERSEMENT_LIBERATOIRE_PRESTATIONS_BNC,
  value: 2.2,
  impositionTypes: [ImpositionType.LIBERATOIRE],
}];
