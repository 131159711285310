import { useStore, useContext } from '@nuxtjs/composition-api'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { ReadOpportunity, ICustomer } from '@abby/core-legacy'
import { Category } from '~/logic/contexts/opportunity/domain/entity/category.entity'
import { Customer } from '~/logic/contexts/billing/domain/entity/customer.entity'

export const useOpportunityStore = () => {
  const store = useStore()
  const { $api } = useContext()
  const useOpportunityStore = defineStore('opportunity', () => {
    const isOpportunityInfoSidePanelOpen = ref(false)
    const opportunity = ref<ReadOpportunity>()

    const toggleOpportunityInfoSidePanel = async (open: boolean, _opportunity?: ReadOpportunity) => {
      if (open) {
        await store.dispatch('opportunity/fetchCategories')
        opportunity.value = _opportunity
        isOpportunityInfoSidePanelOpen.value = true
        return true
      }
      opportunity.value = undefined
      isOpportunityInfoSidePanelOpen.value = false
    }

    return {
      isOpportunityInfoSidePanelOpen,
      opportunity,
      toggleOpportunityInfoSidePanel,
    }
  })

  const openOpportunityFormSidePanel = async (params: { category: Category, customer?: Customer }) => {
    const categories = await store.dispatch('opportunity/fetchCategories')
    let _customer: ICustomer | undefined
    // TODO: This should be refactored when opportunity store is refactored
    if (params.customer) {
      _customer = await $api.billing.getCustomer(params.customer.legacyId)
    }
    const category = categories.find((c: Category) => c.id === params.category.id)
    await store.dispatch('opportunity/openOpportunityCreation', {
      category,
      customerId: _customer?._id,
      customer: _customer || undefined,
    })
  }

  const opportunityStore = useOpportunityStore()
  const {
    isOpportunityInfoSidePanelOpen,
    opportunity,
  } = storeToRefs(opportunityStore)

  return {
    isOpportunityInfoSidePanelOpen,
    // @ts-ignore
    opportunity,
    openOpportunityFormSidePanel,
    toggleOpportunityInfoSidePanel: opportunityStore.toggleOpportunityInfoSidePanel,
  }
}
