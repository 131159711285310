import { ref } from 'vue'

export function useLayout () {
  const offsetTop = ref(0)

  const bodyElement = document.querySelector('body')
  const setOffsetTop = () => {
    if (!bodyElement) { return }
    const computedMarginTop = window.getComputedStyle(bodyElement).marginTop
    offsetTop.value = parseInt(computedMarginTop, 10)
  }
  if (bodyElement) {
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      setTimeout(() => {
        setOffsetTop()
      }, 300)
    })
    observer.observe(bodyElement, {
      attributes: true,
      attributeFilter: ['style'],
    })
  }
  setOffsetTop()
  return {
    offsetTop,
  }
}
