import { ContactHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactHttp.mapper'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'

export const useOpenContactDetailsSidePanel = () => {
  const contactRepository = useContactRepository()
  const billingRepository = useBillingRepository()
  const clientStore = useClientStore()

  return async (contactId: string, options?: any) => {
    const readContact = await contactRepository.getContactById(contactId)
    const contact = ContactHttpMapper.toDomain(readContact)

    const billingCustomer = await billingRepository.fetchCustomerFromContact(contact.id)
    if (contact.hasTiersPrestation) {
      contact.tiersPrestation = await contactRepository.getContactSapData(contact.id)
    }
    contact.customerId = billingCustomer.id
    clientStore.toggleContactDetailsSidePanel(true, contact, options)
  }
}
