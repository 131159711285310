
import { Action, Component, ModelSync, Vue, Getter } from 'nuxt-property-decorator'
import { BillingType, documentTextNumber, IBillingConfiguration } from '@abby/core-legacy'
import HomeGuideModal from '~/components-legacy/home/home-guide/HomeGuideModal.vue'
import { BillingConfigurationModals } from '~/store/billingConfiguration'
import { useDisableBillingTestMode } from '~/composables/billing/useDisableBillingTestMode'
import { GuideState } from '~/store/guide'

@Component({
  components: { HomeGuideModal },
  setup () {
    const disableTestMode = useDisableBillingTestMode()
    return {
      disableTestMode,
    }
  },
})
export default class HomeGuideModalDeactivateTestBilling extends Vue {
  @Getter('guide/modal')
  guideModal!: GuideState['modal']

  deactivateLoading: boolean = false
  disableTestMode!: Function

  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration

  @Action('billingConfiguration/openModal') openBillingConfigurationModal!: (key: BillingConfigurationModals) => void

  async deactivateTestMode () {
    try {
      this.deactivateLoading = true
      await this.disableTestMode()
      await this.$store.dispatch('billingConfiguration/updateBillingConfiguration', { test: false }) // TODO à réecrire et dégager lorsque la facturation sera réecrite
      this.close()
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      this.deactivateLoading = false
    }
  }

  get invoiceNumberFormatPreview () {
    return documentTextNumber(this.billingConfiguration, BillingType.INVOICE, this.$dayjs().toDate())
  }

  get advanceNumberFormatPreview () {
    return documentTextNumber(this.billingConfiguration, BillingType.ADVANCE, this.$dayjs().toDate())
  }

  get estimateNumberFormatPreview () {
    return documentTextNumber(this.billingConfiguration, BillingType.ESTIMATE, this.$dayjs().toDate())
  }

  get assetNumberFormatPreview () {
    return documentTextNumber(this.billingConfiguration, BillingType.ASSET, this.$dayjs().toDate())
  }

  get purchaseOrderNumberFormatPreview () {
    return documentTextNumber(this.billingConfiguration, BillingType.PURCHASE_ORDER, this.$dayjs().toDate())
  }

  close () {
    this.$store.dispatch('guide/closeDeactivateBillingTestModeModal')
  }
}

