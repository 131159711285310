import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  CreationCompanyStep,
  CreationDashboardStep,
  RegisteredType,
  RoleType,
} from '@abby/core-legacy'

import debounce from 'lodash/debounce'
import type {
  BasePaginate,
  ReadCompany, ReadNote,
  ReadUser,
  ReadUsersKanban,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

export interface ClientsBoardQuery {
    search?: string | undefined,
    referentIds?: string[] | undefined,
    roles?: RoleType[];
    mode?: RegisteredType;
    currentStep?: CreationCompanyStep;
    inBoard: boolean;
}

export interface ClientsBoardPaginationQuery { page: number, limit: number }

export interface ClientsBoardState {
    query: ClientsBoardQuery;
    clientId: string | null;
    isClientPanelOpened: boolean;
    defaultPaginationLimit: number;
    defaultPaginationPage: number;
    queryLoading: boolean;
    paginationQuery: { [key in CreationDashboardStep]?: { page: number, limit: number } };
    boardColumns: ReadUsersKanban[];
    clientsCol: BasePaginate<ReadUser & { isInCreationMode: boolean }> | null;
}

export const state = (): ClientsBoardState => ({
  clientId: null,
  isClientPanelOpened: false,
  defaultPaginationLimit: 10,
  defaultPaginationPage: 1,
  queryLoading: true,
  paginationQuery: {},
  query: {
    search: undefined,
    referentIds: undefined,
    mode: RegisteredType.CREATION,
    currentStep: undefined,
    roles: [RoleType.CLIENT],
    inBoard: true,
  },
  boardColumns: [],
  clientsCol: null,

})

export const getters: GetterTree<ClientsBoardState, RootState> = {
  clientId: (state: ClientsBoardState) => state.clientId,
  isClientPanelOpened: (state: ClientsBoardState) => state.isClientPanelOpened,
  defaultPaginationLimit: (state: ClientsBoardState) => state.defaultPaginationLimit,
  queryLoading: (state: ClientsBoardState) => state.queryLoading,
  paginationQuery: (state: ClientsBoardState) => state.paginationQuery,
  query: (state: ClientsBoardState) => state.query,
  boardColumns: (state: ClientsBoardState) => state.boardColumns,
  clientsCol: (state: ClientsBoardState) => state.clientsCol,
}

export const mutations: MutationTree<ClientsBoardState> = {
  SET_CLIENT_ID (state: ClientsBoardState, value: string) {
    state.clientId = value
  },
  SET_CLIENT_PANEL_OPENED (state: ClientsBoardState, value: boolean) {
    state.isClientPanelOpened = value
  },
  SET_QUERY_LOADING (state: ClientsBoardState, value: boolean) {
    state.queryLoading = value
  },
  SET_PAGINATION_QUERY (state: ClientsBoardState, paginationQuery: { [key in CreationDashboardStep]?: { page: number, limit: number } }) {
    state.paginationQuery = paginationQuery
  },
  UPDATE_PAGINATION_QUERY (state: ClientsBoardState, {
    dashboardStep,
    paginationQuery,
  }: { dashboardStep: CreationDashboardStep, paginationQuery: { limit: number, page: number } }) {
    state.paginationQuery[dashboardStep] = paginationQuery
  },
  UPDATE_QUERY (state: ClientsBoardState, query: ClientsBoardQuery) {
    state.query = Object.assign(state.query, query)
  },
  SET_BOARD_COLUMNS (state: ClientsBoardState, boardColumns: ReadUsersKanban[]) {
    state.boardColumns = boardColumns
  },
  SET_BOARD_COLUMN (state: ClientsBoardState, column: ReadUsersKanban) {
    const columnIndex = state.boardColumns.findIndex(cl => cl.dashboardStep === column.dashboardStep)
    state.boardColumns.splice(columnIndex, 1, column)
  },
  ADD_CARD_IN_COLUMN (state: ClientsBoardState, {
    dashboardStep,
    cardIndex,
    client,
  }: { dashboardStep: CreationDashboardStep, cardIndex: number, client: ReadUser }) {
    const column = state.boardColumns.find(column => column.dashboardStep === dashboardStep)

    if (!column) {
      return
    }
    column.data.splice(cardIndex, 0, client)
    column.totalItems += 1
  },
  REMOVE_CARD_IN_COLUMN (state: ClientsBoardState, {
    dashboardStep,
    cardIndex,
  }: { dashboardStep: CreationDashboardStep, cardIndex: number }) {
    const column = state.boardColumns.find(column => column.dashboardStep === dashboardStep)

    if (!column) {
      return
    }
    column.data.splice(cardIndex, 1)
    column.totalItems -= 1
  },
  UPDATE_MOVED_CARD (state: ClientsBoardState, { company, cardIndex }: { company: ReadCompany, cardIndex: number }): void {
    const column = state.boardColumns.find(column => column.dashboardStep === company.currentDashboardStep)
    if (!column) {
      return
    }

    const _client = {
      ...column.data[cardIndex],
      companies: [company],
    }

    column.data.splice(cardIndex, 1, _client)
  },
  UPDATE_CARD (state: ClientsBoardState, { company, client }: { company: ReadCompany, client?: ReadUser }): void {
    if (!company) {
      return
    }
    state.boardColumns.forEach((column) => {
      // Recherche la card si elle existe dans une colonne (parcequ'on ne sait où elle est)
      const card: ReadUser | undefined = column.data.find(user => user.companies?.[0].id === company.id)
      if (!card) {
        return
      }

      const cardIndex = column.data.findIndex(user => user.companies?.[0].id === company.id)
      if (cardIndex === -1) {
        return
      }

      const _company = card.companies?.[0]
      // Crée l'objet de la card mis à jour avec toutes les données
      const _client = {
        ...column.data[cardIndex],
        companies: [{ ..._company, ...company }],
        ...client,
      }

      // On met à jour la card
      column.data.splice(cardIndex, 1, _client)

      // Si il y a une modification du currentDashboardStep
      if (company.currentDashboardStep !== _company?.currentDashboardStep) {
        const columnIndex = state.boardColumns.findIndex(column => column.dashboardStep === company.currentDashboardStep)
        if (columnIndex === -1) {
          return
        }
        // On ajoute la card dans la nouvelle colonne avec les données mises à jour
        state.boardColumns?.[columnIndex]?.data.push(_client)
        // On incrémente le compteur des totalItems dans la nouvelle colonne
        state.boardColumns[columnIndex].totalItems += 1
        // Supprime la card dans l'acienne colonne
        column.data.splice(cardIndex, 1)
        // On décrémente le compteur des totalItems dans l'ancienne colonne
        column.totalItems -= 1
      }
    })
  },
  UPDATE_CARD_COUNT_NOTE (state: ClientsBoardState, { notes, clientId }: { notes: ReadNote[], clientId: string }) {
    if (!clientId || !notes) {
      return
    }
    state.boardColumns.forEach((column) => {
      // Recherche la card si elle existe dans une colonne (parcequ'on ne sait où elle est)
      const card: ReadUser | undefined = column.data.find(user => user.id === clientId)
      if (!card) { return }

      const cardIndex = column.data.findIndex(user => user.id === clientId)
      if (cardIndex === -1) { return }

      const _company = card.companies?.[0]
      // Crée l'objet de la card mis à jour avec toutes les données
      const _client = {
        ...column.data[cardIndex],
        companies: [{ ..._company, notes }] as ReadCompany[],
      }
      // On met à jour la card
      column.data.splice(cardIndex, 1, _client)
    })
  },
}

export const actions: ActionTree<ClientsBoardState, RootState> = {
  async fetchBoardClients ({ commit, state }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const results = await this.$api.user.getAllKanban({
        page: state.defaultPaginationPage,
        limit: state.defaultPaginationLimit,
        ...state.query,
      }) as ReadUsersKanban[]

      results.forEach((c) => {
        commit('UPDATE_PAGINATION_QUERY', {
          dashboardStep: c.dashboardStep,
          paginationQuery: {
            page: state.defaultPaginationPage,
            limit: state.defaultPaginationLimit,
          },
        })
      })
      commit('SET_BOARD_COLUMNS', results)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  async loadMoreClientsInCol ({ commit, state }, { dashboardStep }: { dashboardStep: CreationDashboardStep }) {
    const result = await this.$api.user.getOneColKanban({
      ...state.paginationQuery[dashboardStep] as { page: number, limit: number },
      currentDashboardStep: dashboardStep,
      ...state.query,
    }) as ReadUsersKanban

    const _data = state.boardColumns.find(cl => cl.dashboardStep === dashboardStep)?.data || []
    const newPagination = result
    newPagination.data = [
      ..._data,
      ...newPagination.data,
    ]
    commit('SET_BOARD_COLUMN', { ...newPagination })
  },
  updateAllQuery ({ commit, dispatch }, query: ClientsBoardQuery) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchBoardClients')
  },
  debounceFetchBoardClients: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchBoardClients')
  }, 300),
  async updateClientCard ({ commit }, { currentDashboardStep, companyId }: { currentDashboardStep: CreationDashboardStep, companyId?: string }): Promise<ReadCompany | undefined> {
    if (!companyId) {
      return
    }
    const result = await this.$api.company.update(companyId, { currentDashboardStep })
    commit('UPDATE_CARD', { company: result })
    return result
  },
  async moveClientCard ({ commit }, { currentDashboardStep, client, cardIndex }: { currentDashboardStep: CreationDashboardStep, client: ReadUser, cardIndex: number }): Promise<ReadCompany | undefined> {
    const _company = client?.companies?.[0]
    if (!_company) {
      return
    }
    const company = await this.$api.company.update(_company.id, { currentDashboardStep })
    commit('UPDATE_MOVED_CARD', { company: { ..._company, ...company }, cardIndex })
  },
  openClientPanel ({ commit }, value: boolean) {
    commit('SET_CLIENT_PANEL_OPENED', value)
  },
  setClientManagerOpened ({ commit }, { open, clientId }: {open: boolean, clientId: string | null}) {
    commit('SET_CLIENT_PANEL_OPENED', open)
    commit('SET_CLIENT_ID', clientId)
  },
  setClientId ({ commit }, id: string) {
    commit('SET_CLIENT_ID', id)
  },
}
