
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { Currency, CurrencySymbol } from '@abby/core-legacy'
import { $enum } from 'ts-enum-util'
@Component({})
export default class BillingDefaultCurrencySelect extends Vue {
  @ModelSync('value', 'input')
  innerValue!: any;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: false })
  clearable!: boolean;

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  get isClearable () {
    return !!this.clearable || typeof this.clearable === 'string'
  }

  @Prop({ required: false })
  tooltip!: string;

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  search = ''

  isFocused = false

  get items () {
    return $enum(Currency)
      .getValues()
      .map(currency => ({
        value: currency,
        text: this.$t(`currency.${currency}`),
        symbol: CurrencySymbol[currency],
      }))
  }

  selectLocale () {
    this.isFocused = false
    // @ts-ignore
    this.$refs.select.blur()
  }

  get text () {
    return this.items.find(item => item.value === this.innerValue)?.text || ''
  }

  filterCurrency (_: string, queryText: string, itemText: string) {
    const hasValue = (val: string | null) => val !== null ? val : ''
    const text = hasValue(itemText)
    const query = hasValue(queryText)

    return text.toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase())
  }
}
