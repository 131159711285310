import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { ProductForm } from '../../domain/form/product.form'
import { ProductItem } from '~/logic/contexts/product/domain/entity/productItem.entity'

export const useProductStore = () => {
  const useProductStore = defineStore('product', () => {
    const isProductFormSidePanelOpen = ref(false)
    const isProductImportModalOpen = ref(false)
    const productFormToEdit = ref<ProductForm>()
    const detailedProduct = ref<ProductItem | null>(null)

    const toggleProductFormSidePanel = (value: boolean) => {
      isProductFormSidePanelOpen.value = value
      if (!value) {
        productFormToEdit.value = undefined
      }
    }

    const openEditProductFormSidePanel = (product: ProductForm) => {
      productFormToEdit.value = product
      isProductFormSidePanelOpen.value = true
    }

    const toggleProductImportModal = (value: boolean) => {
      isProductImportModalOpen.value = value
    }

    return {
      isProductFormSidePanelOpen,
      productFormToEdit,
      detailedProduct,
      isProductImportModalOpen,
      openEditProductFormSidePanel,
      toggleProductFormSidePanel,
      toggleProductImportModal,
    }
  })
  const productStore = useProductStore()
  const {
    isProductFormSidePanelOpen,
    productFormToEdit,
    detailedProduct,
    isProductImportModalOpen,
  } = storeToRefs(productStore)

  return {
    productFormToEdit,
    detailedProduct,
    isProductFormSidePanelOpen,
    isProductImportModalOpen,
    openEditProductFormSidePanel: productStore.openEditProductFormSidePanel,
    toggleProductFormSidePanel: productStore.toggleProductFormSidePanel,
    toggleProductImportModal: productStore.toggleProductImportModal,
  }
}
