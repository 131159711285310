import { ActionTree, GetterTree } from 'vuex'
import { CampaignType, StripeProductType, toArray } from '@abby/core-legacy'
import { RootState } from '~/store/index'

type UpsellsCallbacks = {
  succeed?: () => Promise<void>,
  failed?: () => Promise<void>,
  canceled?: () => Promise<void>
}

type ModalData = {
  campaign?: CampaignType
}

export interface UpsellsState {
  products: StripeProductType[],
  selectedProduct: StripeProductType | null,
  callbacks: UpsellsCallbacks,
  modals: {
    upsells: boolean,
    formations: boolean,
    finfrog: boolean,
    cfe: boolean,
    resetBillingNumber: boolean
  }
  promoCode: string,
  modalsData: {
    [k in keyof UpsellsState['modals']]?: ModalData
  } | null
}

export type UpsellsModals = Extract<keyof UpsellsState['modals'], string>

export const state = (): UpsellsState => ({
  products: [],
  selectedProduct: null,
  callbacks: {},
  promoCode: '',
  modals: {
    upsells: false,
    formations: false,
    finfrog: false,
    cfe: false,
    resetBillingNumber: false,
  },
  modalsData: null,
})

export const getters: GetterTree<UpsellsState, RootState> = {
  modals (state: UpsellsState) {
    return state.modals
  },
  modalsData (state: UpsellsState) {
    return state.modalsData
  },
  products (state: UpsellsState) {
    return state.products
  },
  selectedProduct (state: UpsellsState) {
    return state.selectedProduct
  },
  callbacks (state: UpsellsState) {
    return state.callbacks
  },
  promoCode (state: UpsellsState) {
    return state.promoCode
  },
}

export const mutations = {
  SET_PRODUCTS (state: UpsellsState, value: StripeProductType[] | StripeProductType) {
    state.products = toArray(value)
  },
  SET_SELECTED_PRODUCT (state: UpsellsState, value: StripeProductType | null) {
    state.selectedProduct = value
  },
  SET_PROMO_CODE (state: UpsellsState, value: string) {
    state.promoCode = value
  },
  SET_CALLBACKS (state: UpsellsState, value: UpsellsCallbacks) {
    state.callbacks = value
  },
  OPEN_MODAL (state: UpsellsState, key: UpsellsModals) {
    state.modals[key] = true
  },
  OPEN_MODAL_WITH_DATA (state: UpsellsState, { modal, data }: { modal: UpsellsModals, data: ModalData }) {
    state.modals[modal] = true
    if (state.modalsData) {
      state.modalsData[modal] = data
    } else {
      state.modalsData = {
        [modal]: data,
      }
    }
  },
  CLOSE_MODAL (state: UpsellsState, key: UpsellsModals) {
    state.modals[key] = false
    if (state.modalsData) {
      state.modalsData[key] = undefined
    }
  },
  RESET (_currentState: UpsellsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  openUpsellsModal ({ commit, rootGetters }, { products, selectedProduct, succeed, failed, canceled }: { products: StripeProductType | StripeProductType[], selectedProduct: StripeProductType | null } & UpsellsCallbacks) {
    const _products: StripeProductType[] = toArray(products).filter((p) => {
      return !((p === StripeProductType.ABBY_PLUS || p === StripeProductType.ABBY_CONTACT) && !rootGetters['company/isInCreation'])
    })
    commit('SET_PRODUCTS', _products)
    commit('SET_CALLBACKS', { succeed, failed, canceled })
    commit('SET_SELECTED_PRODUCT', selectedProduct || _products[0])
    commit('OPEN_MODAL', 'upsells')
  },

  closeUpsellsModal ({ commit }) {
    commit('CLOSE_MODAL', 'upsells')
    commit('SET_PRODUCTS', [])
    commit('SET_SELECTED_PRODUCT', null)
  },
  setPromoCode ({ commit }, promoCode: string) {
    commit('SET_PROMO_CODE', promoCode)
  },
}
