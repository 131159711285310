import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  CompanyDocumentStatus,
  CompanyDocumentType,
  CreationCompanyStep,
  CreationDashboardStep,
  DeclarationPlatform,
  MandatState,
  Periodicite,
  Recurrence,
  RegisteredType,
  RoleType,
} from '@abby/core-legacy'

import Vue from 'vue'
import debounce from 'lodash/debounce'
import type {
  BasePaginate,
  CreateCompanyStripeProduct,
  ICompanyFile,
  ICreationCompany,
  QueryUser,
  ReadCompanyStripeProduct,
  ReadIdentification,
  ReadTrackingCompanyStep,
  ReadUser,
  ReadUsers,
  UpdateCompany,
  UpdateCompanyFile,
  UpdateCompanyFilesToActivate,
  UpdateCreationCompany,
  UpdateUser,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ClientsQuery {
  search?: string | null,
  referentIds?: string[] | null,
  roles?: RoleType[] | null;
  mode?: RegisteredType | null;
  currentStep?: CreationCompanyStep;
  declarationPlatform?: DeclarationPlatform
}

export interface ClientsState {
  query: ClientsQuery;
  queryLoading: boolean;
  clients: BasePaginate<ReadUser & { isInCreationMode: boolean }> | null;
  paginationQuery: QueryUser;
  client: ReadUser | null;
  files: ICompanyFile[] | null;
  adminUsers: ReadUser[] | null;
  filesLoading: boolean;
  clientLoading: boolean;
  clientManagerLoading: boolean;
  isRegisterCollaboratorModalOpened: boolean;
  numberOfFilesMissing: number | undefined;
  isUserUpdateModalOpened: boolean;
  isAbbyReferentModalOpened: boolean;
  isSendMessageModalOpened: boolean;
  isUpdateUploadDownloadFileOpened: boolean;
  isTrackingCompanyStepModalOpened: boolean;
  isDeclarationPlatformModalOpened: boolean;
  isConfirmCallModalOpened: boolean;
  isAddCompanyStripeProductModalModalOpened: boolean;
  selectedDocumentTypeForSendMessageModal: CompanyDocumentType | null;
  trackingCompanySteps: ReadTrackingCompanyStep[] | null;
  creationCompany: ICreationCompany & { _id: string; } | null;
  isLaunchDeclarationModalOpened: boolean;
  urssafMandatState: MandatState | null
  urssafIdentification: ReadIdentification | null
}

export const state = (): ClientsState => ({
  query: {
    search: undefined,
    referentIds: undefined,
    mode: undefined,
    currentStep: undefined,
    roles: [RoleType.CLIENT],
  },
  queryLoading: true,
  clients: null,
  client: null,
  files: null,
  adminUsers: null,
  filesLoading: false,
  paginationQuery: {
    page: 1,
    limit: 25,
  },
  numberOfFilesMissing: undefined,
  clientLoading: false,
  clientManagerLoading: false,
  isRegisterCollaboratorModalOpened: false,
  isUserUpdateModalOpened: false,
  isAbbyReferentModalOpened: false,
  isSendMessageModalOpened: false,
  isUpdateUploadDownloadFileOpened: false,
  isTrackingCompanyStepModalOpened: false,
  isDeclarationPlatformModalOpened: false,
  isConfirmCallModalOpened: false,
  isAddCompanyStripeProductModalModalOpened: false,
  selectedDocumentTypeForSendMessageModal: null,
  trackingCompanySteps: null,
  creationCompany: null,
  isLaunchDeclarationModalOpened: false,
  urssafMandatState: null,
  urssafIdentification: null,
})

export const getters: GetterTree<ClientsState, RootState> = {
  query: (state: ClientsState) => state.query,
  queryLoading: (state: ClientsState) => state.queryLoading,
  clients: (state: ClientsState) => state.clients,
  paginationQuery: (state: ClientsState) => state.paginationQuery,
  client: (state: ClientsState) => state.client,
  clientCompany: (state: ClientsState) => state.client?.companies?.[0],
  files: (state: ClientsState) => state.files,
  adminUsers: (state: ClientsState) => state.adminUsers,
  filesLoading: (state: ClientsState) => state.filesLoading,
  urssafMandatState: (state: ClientsState) => state.urssafMandatState,
  urssafIdentification: (state: ClientsState) => state.urssafIdentification,
  clientLoading: (state: ClientsState) => state.clientLoading,
  clientManagerLoading: (state: ClientsState) => state.clientLoading,
  isRegisterCollaboratorModalOpened: (state: ClientsState) => state.isRegisterCollaboratorModalOpened,
  isUserUpdateModalOpened: (state: ClientsState) => state.isUserUpdateModalOpened,
  isAbbyReferentModalOpened: (state: ClientsState) => state.isAbbyReferentModalOpened,
  isSendMessageModalOpened: (state: ClientsState) => state.isSendMessageModalOpened,
  isUpdateUploadDownloadFileOpened: (state: ClientsState) => state.isUpdateUploadDownloadFileOpened,
  isTrackingCompanyStepModalOpened: (state: ClientsState) => state.isTrackingCompanyStepModalOpened,
  isDeclarationPlatformModalOpened: (state: ClientsState) => state.isDeclarationPlatformModalOpened,
  isConfirmCallModalOpened: (state: ClientsState) => state.isConfirmCallModalOpened,
  isAddCompanyStripeProductModalModalOpened: (state: ClientsState) => state.isAddCompanyStripeProductModalModalOpened,
  selectedDocumentTypeForSendMessageModal: (state: ClientsState) => state.selectedDocumentTypeForSendMessageModal,
  trackingCompanySteps: (state: ClientsState) => state.trackingCompanySteps,
  creationCompany: state => state.creationCompany,
  isLaunchDeclarationModalOpened: state => state.isLaunchDeclarationModalOpened,
  numberOfFilesMissing: (state) => {
    return (state.files || []).filter((file: ICompanyFile) => [CompanyDocumentStatus.NOT_UPLOADED, CompanyDocumentStatus.INVALIDATED].includes(file.status)).length
  },
  isInCreation: (state) => {
    const { registeredType, verifiedAt } = state.client?.companies?.[0] || {}
    return !verifiedAt && registeredType === RegisteredType.CREATION
  },
  wasInCreation: (state) => {
    const { registeredType, verifiedAt } = state.client?.companies?.[0] || {}
    return verifiedAt && registeredType === RegisteredType.CREATION
  },
}

export const mutations: MutationTree<ClientsState> = {
  UPDATE_QUERY (state: ClientsState, query: ClientsQuery) {
    state.query = Object.assign(state.query, query)
  },
  SET_QUERY_LOADING (state: ClientsState, value: boolean) {
    state.queryLoading = value
  },
  SET_CLIENTS (state: ClientsState, clients: ReadUsers) {
    state.clients = clients as ClientsState['clients']
  },
  SET_CREATION_COMPANY (state: ClientsState, creationCompany: ICreationCompany & { _id: string; __v: any } | null) {
    state.creationCompany = creationCompany
  },
  RESET (_currentState: ClientsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_CLIENT (state: ClientsState, client: ReadUser) {
    state.client = client
  },
  SET_CLIENT_LOADING (state: ClientsState, value: boolean) {
    state.clientLoading = value
  },
  SET_CLIENT_MANAGER_LOADING (state: ClientsState, value: boolean) {
    state.clientManagerLoading = value
  },
  SET_FILE: (state, updatedFile: ICompanyFile) => {
    const index : number | undefined = state.files?.findIndex((file: ICompanyFile) => file._id === updatedFile._id)
    if (state.files && (index !== undefined && index !== -1)) {
      state.files[index] = updatedFile
    }
  },
  SET_FILES (state: ClientsState, files: ICompanyFile[]): void {
    state.files = files
  },
  SET_URSSAF_IDENTIFICATION (state: ClientsState, identification: ReadIdentification): void {
    state.urssafIdentification = identification
  },
  SET_URSSAF_MANDAT_STATE (state: ClientsState, mandatState: MandatState): void {
    state.urssafMandatState = mandatState
  },
  SET_ADMIN_USERS (state: ClientsState, adminUsers: ReadUser[]): void {
    state.adminUsers = adminUsers
  },
  SET_FILES_LOADING (state: ClientsState, value: boolean) {
    state.filesLoading = value
  },
  SET_REGISTER_COLLABORATOR_MODAL (state: ClientsState, value: boolean) {
    state.isRegisterCollaboratorModalOpened = value
  },
  SET_IS_UPDATE_USER_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isUserUpdateModalOpened = value
  },
  SET_IS_ABBY_REFERENT_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isAbbyReferentModalOpened = value
  },
  SET_IS_UPDATE_UPLOAD_DOWNLOAD_FILE (state: ClientsState, value: boolean) {
    state.isUpdateUploadDownloadFileOpened = value
  },
  SET_IS_TRACKING_COMPANY_STEP_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isTrackingCompanyStepModalOpened = value
  },
  SET_IS_DECLARATION_PLATFORM_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isDeclarationPlatformModalOpened = value
  },
  SET_SEND_MESSAGE_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isSendMessageModalOpened = value
  },
  SET_SELECTED_DOCUMENT_TYPE_FOR_SEND_MESSAGE_MODAL (state: ClientsState, value: CompanyDocumentType | null) {
    state.selectedDocumentTypeForSendMessageModal = value
  },
  SET_TRACKING_COMPANY_STEPS (state: ClientsState, value: ReadTrackingCompanyStep[] | null) {
    state.trackingCompanySteps = value
  },
  SET_LAUNCH_DECLARATION_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isLaunchDeclarationModalOpened = value
  },
  SET_IS_CONFIRM_CALL_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isConfirmCallModalOpened = value
  },
  SET_IS_ADD_COMPANY_STRIPE_PRODUCT_MODAL_OPENED (state: ClientsState, value: boolean) {
    state.isAddCompanyStripeProductModalModalOpened = value
  },
  SORT_COMPANY_STRIPE_PRODUCTS (state: ClientsState) {
    if (state.client?.companies?.[0].productsCompany) {
      state.client.companies[0].productsCompany = state.client?.companies?.[0]?.productsCompany?.sort((x: any, y: any) => +new Date(y?.createdAt) - +new Date(x?.createdAt))
    }
  },
  UPDATE_PAYMENT_HISTORY (state: ClientsState, updatedRow: ReadCompanyStripeProduct) {
    const products = state.client?.companies?.[0].productsCompany
    if (products && updatedRow) {
      const product = products.find((p: any) => p.id === updatedRow.id)
      if (!product) { return }
      product.consumedAt = updatedRow.consumedAt
    }
  },
  REFRESH_COMPANY_STRIPE_PRODUCT (state: ClientsState, id: string) {
    const index = state.client?.companies?.[0]?.productsCompany?.findIndex(csp => csp.id === id)
    const indexStr = index?.toString()
    if (!indexStr || indexStr === '-1') { return }
    state.client?.companies?.[0]?.productsCompany?.splice(+indexStr, 1)
  },
  ADD_COMPANY_STRIPE_PRODUCT (state: ClientsState, payload: ReadCompanyStripeProduct) {
    state.client?.companies?.[0]?.productsCompany?.push(payload)
  },
}

export const actions: ActionTree<ClientsState, RootState> = {
  async fetchClients ({ commit, state }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const results = await this.$api.user.getAll({
        ...state.paginationQuery,
        ...state.query,
      }) as ClientsState['clients']

      commit('SET_CLIENTS', results)
    } finally {
      commit('SET_QUERY_LOADING', false)
    }
  },
  async fetchClient ({ commit, getters, dispatch }, id: string) {
    commit('SET_CLIENT_LOADING', true)
    try {
      const client: ReadUser = await this.$api.user.getById(id)
      commit('SET_CLIENT', client)
      const companyId = client?.companies?.[0]?.id

      if ((getters.isInCreation || getters.wasInCreation) && companyId) {
        const creationCompany = await this.$api.creationCompany.getByCompanyId(companyId)
        commit('SET_CREATION_COMPANY', creationCompany)
        await dispatch('fetchFiles', companyId)
        await dispatch('creationForm/fetch', { companyId }, { root: true })
      }
    } finally {
      commit('SET_CLIENT_LOADING', false)
    }
  },
  async getIdentification ({ state, commit }) {
    try {
      const { siret } = state.client?.companies?.[0] || {}
      if (!siret) {
        return
      }
      const identification = await this.$api.urssaf.getIdentification({ siret })

      commit('SET_URSSAF_IDENTIFICATION', identification)
    } catch {
      commit('SET_URSSAF_IDENTIFICATION', null)
    }
  },
  async getMandatState ({ state, commit }) {
    try {
      const { siret } = state.client?.companies?.[0] || {}
      if (!siret || !state.urssafIdentification?.periodicite) {
        return
      }
      const recurrence = state.urssafIdentification.periodicite === Periodicite.T ? Recurrence.QUARTER : Recurrence.MONTH

      const { mandatState } = await this.$api.urssaf.hasMandatWithUs({ siret, recurrence })
      commit('SET_URSSAF_MANDAT_STATE', mandatState)
    } catch {
      commit('SET_URSSAF_MANDAT_STATE', null)
    }
  },
  async fetchFiles ({ commit }, id: string) {
    commit('SET_FILES_LOADING', true)
    try {
      const files = await this.$api.company.getCompanyFilesById(id)
      commit('SET_FILES', files)
    } finally {
      commit('SET_FILES_LOADING', false)
    }
  },
  async fetchAdminUsers ({ commit }) {
    const adminUsers = await this.$api.user.getAdminUsers()
    commit('SET_ADMIN_USERS', adminUsers)
  },
  async updateCreationCompanyStep ({ dispatch }, { id, steps, userId }: UpdateCreationCompany & { id: string }) {
    await this.$api.creationCompany.update(id, { steps, userId })
    await dispatch('fetchClient', userId)
  },
  async updateFile ({ commit }, { id, payload }: { id: string, payload: UpdateCompanyFile }) {
    const result = await this.$api.company.updateFile(id, payload)
    commit('SET_FILE', result)
  },
  async generateFile ({ commit }, { companyId, documentType }: { companyId: string, documentType: CompanyDocumentType }) {
    const result = await this.$api.company.generateFile({ companyId, documentType })
    commit('SET_FILE', result)
  },
  async updateFilesIsActivated ({ commit }, { userId, companyId, payload }: { userId: string, companyId: string, payload: UpdateCompanyFilesToActivate }) {
    const result = await this.$api.company.updateFilesIsActivated(userId, companyId, payload)
    commit('SET_FILES', result)
  },
  setClient ({ commit }, payload: ReadUser | null) {
    commit('SET_CLIENT', payload)
  },
  async updateClient ({ getters, dispatch, commit }, payload: UpdateUser) {
    if (!getters.client) { return }
    const result = await this.$api.user.updateById(getters.client.id, payload)
    await dispatch('fetchClient', getters.client.id)
    if (!getters.client?.companies?.[0]) { return }
    commit('clientsBoard/UPDATE_CARD', { company: getters.client.companies[0], client: result }, { root: true })
  },
  async updateCompany ({ state, dispatch, commit }, { id, payload }: { id: string, payload: UpdateCompany }) {
    if (!state.client) { return }
    const result = await this.$api.company.update(id, payload)
    await dispatch('fetchClient', state.client.id)
    commit('clientsBoard/UPDATE_CARD', { company: result }, { root: true })
  },
  setRegisterCollaboratorModal ({ commit }, value: boolean) {
    commit('SET_REGISTER_COLLABORATOR_MODAL', value)
  },
  debounceFetchClients: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchClients')
  }, 300),
  updateQuery ({ commit, dispatch }, query: ClientsQuery) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchClients')
  },
  async launchCompanyDeclaration ({ getters, commit }) {
    const id = getters.client?.companies?.[0]?.id
    commit('SET_CLIENT', {
      ...getters.client,
      companies: [{
        ...getters.client?.companies?.[0],
        currentDashboardStep: CreationDashboardStep.DECLARATION_IN_PROGRESS,
      }],
    })
    return await this.$api.company.launchCreation(id)
  },
  async openTrackingModal ({ commit, getters }) {
    commit('SET_CLIENT_LOADING', true)
    if (!getters.client?.companies?.[0]?.id) { return }
    commit('SET_IS_TRACKING_COMPANY_STEP_MODAL_OPENED', true)
    try {
      const results = await this.$api.company.getTrackingCompanyStep(getters.client?.companies?.[0]?.id)
      commit('SET_TRACKING_COMPANY_STEPS', results)
    } catch (e) {
      this.$alertsManager.autoError(e)
    } finally {
      commit('SET_CLIENT_LOADING', false)
    }
  },
  setLaunchDeclarationModal ({ commit }, value: boolean) {
    commit('SET_LAUNCH_DECLARATION_MODAL_OPENED', value)
  },
  setConfirmCallModal ({ commit }, value: boolean) {
    commit('SET_IS_CONFIRM_CALL_MODAL_OPENED', value)
  },
  sortCompanyStripeProducts ({ commit }) {
    commit('SORT_COMPANY_STRIPE_PRODUCTS')
  },
  async addCompanyStripeProduct ({ commit }, payload: CreateCompanyStripeProduct) {
    try {
      const result = await this.$api.company.addCompanyStripeProduct(payload)
      commit('ADD_COMPANY_STRIPE_PRODUCT', result)
      commit('SORT_COMPANY_STRIPE_PRODUCTS')
      this.$alertsManager.success('Produit ajouté')
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
  async removeCompanyStripeProduct ({ commit }, id: string) {
    try {
      await this.$api.company.deleteCompanyStripeProduct(id)
      commit('REFRESH_COMPANY_STRIPE_PRODUCT', id)
      commit('SORT_COMPANY_STRIPE_PRODUCTS')
      this.$alertsManager.success('Produit supprimé')
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
}
