import { ReadOpportunityListItem, Time } from '@abby/shared'
import { OpportunityItem } from '~/logic/contexts/opportunity/domain/entity/opportunityItem.entity'
import { Amount } from '~/logic/contexts/shared-kernel/domain/valueObject/amount.valueObject'

export class OpportunityItemHttpMapper {
  static toDomain (item: ReadOpportunityListItem): OpportunityItem {
    return OpportunityItem.create({
      id: item.id,
      name: item.name,
      ...item.dueDate && { dueDate: Time.toDate(item.dueDate) },
      amount: Amount.create(item.amount),
      customerId: item.customer.id,
      customerName: item.customer.name,
      category: {
        ...item.category,
        showCustomer: true,
        showDueDate: true,
        showAmount: true,
      },
    })
  }
}
