
import { Action, Component, Prop, Vue } from 'nuxt-property-decorator'
import { Editor, EditorContent, Extension } from '@tiptap/vue-2'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Text } from '@tiptap/extension-text'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Node } from '@tiptap/core'
import { CreateTask, RankTaskMode, ReadOpportunity, ReadTask, UpdateTask } from '@abby/core-legacy'
import { StarterKit } from '@tiptap/starter-kit'
import { Underline } from '@tiptap/extension-underline'
import { Link } from '@tiptap/extension-link'
import { TaskList } from '@tiptap/extension-task-list'
import { TaskItem } from '@tiptap/extension-task-item'
import TaskPrioritySelect from '~/components-legacy/task/TaskPrioritySelect.vue'
import OpportunitySelect from '~/components-legacy/opportunity/OpportunitySelect.vue'
import PlanningDatePicker from '~/components-legacy/cards/tasks/PlanningDatePicker.vue'

@Component({
  components: {
    TaskPrioritySelect,
    OpportunitySelect,
    PlanningDatePicker,
    EditorContent,
  },
})
export default class AddTaskCard extends Vue {
  @Action('task/createTask') createTask!: (payload: CreateTask) => Promise<void>;
  @Action('task/updateTask') updateTask!: (payload: UpdateTask & { id: string }) => Promise<void>;

  editor: Editor | null = null
  descriptionEditor: Editor | null = null

  @Prop({ required: false, default: null })
  afterRank!: string | null;

  @Prop()
  prefillDueDate!: string;

  @Prop({ required: false })
  rankMode?: RankTaskMode;

  @Prop()
  prefillOpportunity!: ReadOpportunity | null;

  @Prop({ required: false, default: false })
  hideDescription!: boolean | string;

  get _hideDescription () {
    return this.hideDescription || typeof this.hideDescription === 'string'
  }

  @Prop()
  task!: ReadTask;

  name = ''
  description: string | null = ''
  dueDate?: Date | null = null
  priority: number | null = null
  opportunity: ReadOpportunity | null = null

  get isDisabled () {
    return !(this.name?.trim())
  }

  mounted () {
    if (this.task) {
      this.name = this.task.name
      this.description = this.task.description
      this.dueDate = this.task.dueDate
      this.priority = this.task.priority || null
      if (this.task.opportunity) {
        this.opportunity = this.task.opportunity
      }
    }

    if (!this.task && this.prefillDueDate) {
      this.dueDate = this.$dayjs(this.prefillDueDate).toDate()
    }

    if (!this.task && this.prefillOpportunity) {
      this.opportunity = this.prefillOpportunity
    }

    const OneLiner = Node.create({
      name: 'oneLiner',
      topNode: true,
      content: 'block',
    })
    this.editor = new Editor({
      content: this.name || '',
      onUpdate: () => {
        this.updateValue()
      },
      extensions: [
        OneLiner,
        Text,
        Paragraph,
        Placeholder.configure({
          placeholder: 'ex. : Commander les cartons pour emballer le produit',
        }),
        Extension.create({
          addKeyboardShortcuts: () => {
            return {
              Enter: () => {
                this.submit()
                return true
              },
              Escape: () => {
                this.cancel()
                return true
              },
            }
          },
        }),
      ],
    })
    this.editor.commands.focus()

    this.descriptionEditor = new Editor({
      content: this.description || '',
      onUpdate: () => {
        this.updateDescriptionValue()
      },
      extensions: [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: 'Description',
        }),
        Link.configure({
          openOnClick: false,
        }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Extension.create({
          addKeyboardShortcuts: () => {
            return {
              Escape: () => {
                this.cancel()
                return true
              },
            }
          },
        }),
      ],
    })
  }

  updateValue () {
    this.name = this.editor?.getText() || ''
  }

  updateDescriptionValue () {
    this.description = this.descriptionEditor?.getHTML() || ''
  }

  beforeUnmount () {
    this.editor?.destroy()
  }

  clean () {
    this.name = ''
    this.description = ''
    this.dueDate = null
    this.opportunity = null
    this.priority = null
    this.editor?.commands?.clearContent()
    this.descriptionEditor?.commands?.clearContent()

    if (this.prefillDueDate) {
      this.dueDate = this.$dayjs(this.prefillDueDate).toDate()
    }

    if (this.prefillOpportunity) {
      this.opportunity = this.prefillOpportunity
    }
  }

  async submit () {
    try {
      if (!(this.name?.trim())) { return }
      const payload = {
        name: this.name,
        description: this.description,
        dueDate: this.dueDate,
        ...(this.afterRank ? { afterRank: this.afterRank } : {}),
        ...(this.opportunity ? { opportunityId: this.opportunity.id } : {}),
        ...(this.priority ? { priority: this.priority } : {}),
        ...(this.rankMode ? { rankMode: this.rankMode } : {}),
      }

      if (this.task) {
        await this.updateTask({
          ...payload,
          id: this.task.id,
        })
        this.$emit('submit', { ...this.task, ...payload })
      } else {
        const result = await this.createTask(payload)
        this.$emit('submit', result)
      }
      this.clean()
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  cancel () {
    this.$emit('cancel')
    this.clean()
  }
}
