export enum BillingRangeType {
  PAID_AT = 'paidAt',
  SIGNED_AT = 'signedAt',
  REFUSED_AT = 'refusedAt',
  DATE = 'date',
  VALIDITY_DATE = 'validityDate',
  DUE_DATE = 'dueDate',
}

export enum PaymentMethod {
  TRANSFER = 1,
  DIRECT_DEBIT = 2,
  CREDIT_CARD = 3,
  CHEQUE = 4,
  UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE = 5,
  CASH = 6,
  PAYPAL = 7,
  OTHER = 8,
  STRIPE = 9,
}

export enum PaymentDelay {
  AT_RECEPTION = 1,
  END_OF_MONTH = 2,
  SEVEN_DAYS = 14,
  TEN_DAYS = 3,
  FIFTEEN_DAYS = 13,
  THIRTY_DAYS = 4,
  THIRTY_DAYS_END_OF_MONTH = 5,
  FORTY_FIVE_DAYS = 15,
  FORTY_FIVE_DAYS_END_OF_MONTH = 6,
  SIXTY_DAYS = 7,
  SIXTY_DAYS_END_OF_MONTH = 8,
  NINETY_DAYS = 9,
  NINETY_DAYS_END_OF_MONTH = 10,
  ONE_HUNDRED_AND_TWENTY_DAYS = 11,
  OTHER = 12,
}

export enum LatePenalty {
  THREE_TIME_LEGAL_RATE = 1,
  BCE_DIRECTOR_RATE_PLUS_TEN_POINTS = 2,
  OTHER = 3,
}

export enum VatMention {
  NON_ASSUJETTI = 1,
  BIEN_OCCASION = 2,
  OBJET_ART = 3,
  OBJET_COLLECTION_ANTIQUITE = 4,
  AGENCE_VOYAGE = 5,
  // les valeurs 6 et 7 sont pour la facturation vers des pays étrangers 👉🏼 https://debitoor.fr/blog/modele-de-facture-sans-tva-pour-un-client-a-l-etranger
  VAT_NON_APPLICABLE = 6,
  VAT_NON_APPLICABLE_2 = 13,
  VAT_EXONERATION = 7,
  VAT_EXONERATION_VENTE_EU = 8,
  VAT_EXONERATION_SERVICE_EU = 9,
  VAT_BTP = 10,
  VAT_PSYCHOLOGUE = 11,
  VAT_PSYCHOLOGUE_2 = 12,
  VAT_AUTO_LIQUIDATION = 14,
  VAT_AUTO_LIQUIDATION_2 = 15,
  VAT_AUTO_LIQUIDATION_3 = 16,
  VAT_TAUX_REDUIT = 17,
  VAT_ARTICLE_261C_CGI = 18,
}

export enum LumpSumCompensation {
  QUARANTE_EUROS = 1,
  OTHER = 2,
}

export enum DiscountAdvancePayment {
  NONE = 1,
  OTHER = 2,
}

export interface InitialNumber {
  invoice?: number,
  estimate?: number,
  asset?: number,
  purchaseOrder?: number,
}

export enum BillingType {
  INVOICE = 1,
  ESTIMATE = 2,
  ASSET = 3,
  ADVANCE = 4,
  PURCHASE_ORDER = 5,
}
export enum BillingTypeText {
  INVOICE = 'invoice',
  ESTIMATE = 'estimate',
  ASSET = 'asset',
  ADVANCE = 'advance',
  PURCHASE_ORDER = 'purchase_order',
  BILLING = 'billing',
}

export function convertBillingTypeToText(billingType: BillingType): BillingTypeText {
  switch (billingType) {
    case BillingType.INVOICE:
      return BillingTypeText.INVOICE;
    case BillingType.ESTIMATE:
      return BillingTypeText.ESTIMATE;
    case BillingType.ASSET:
      return BillingTypeText.ASSET;
    case BillingType.ADVANCE:
      return BillingTypeText.ADVANCE;
    case BillingType.PURCHASE_ORDER:
      return BillingTypeText.PURCHASE_ORDER;
    default:
      return BillingTypeText.BILLING;
  }
}

export enum BillingState {
  DRAFT = 1,
  FINALIZED = 2,
  PAID = 3, // seulement pour les factures et les acomptes (à voir pour les bons de commande 🤷🏼‍♂️)
  SIGNED = 4, // seulement pour les devis
  REFUSED = 5, // seulement pour les devis
}

export enum ReminderFrequency {
  ON_DUE_DATE = 1,
  EVERY_WEEK,
}

export enum BillingActionHistory {
  DOCUMENT_CREATION = 1,
  CHANGE_OF_STATE = 2,
  FILE_GENERATED = 3,
  SEND_BY_EMAIL = 4,
  FILE_GENERATED_VIA_FREQUENCY = 5,
  SEND_REMINDER = 6,
  HAS_GENERATED_FREQUENCY_DOCUMENT = 7,
  DOWNLOAD_FILE = 8,
}

export type NumberFormatBalise = {
  text?: boolean, value?: string,
  date?: boolean, format?: string,
  counter?: boolean, size?: number
};

export interface NumberFormat {
  id?: number,
  balise: NumberFormatBalise
}

export interface PaymentMethodItems {
  value: Array<PaymentMethod> | null,
  other?: string
}

export interface PaymentMethodItemsUsed {
  value: PaymentMethod | null,
  other?: string | null,
  priceTotalTax: number | null
}

export interface PaymentDelayItems {
  value: PaymentDelay | null,
  other?: string
}

export interface LatePenaltyItems {
  value: LatePenalty | null,
  other?: string
}

export interface LumpSumCompensationItems {
  value: LumpSumCompensation | null,
  other?: string
}

export interface DiscountAdvancePaymentItems {
  value: DiscountAdvancePayment | null,
  other?: string
}

export type VatMentionItems = VatMention | null;

export const invoicePrefixs: Array<NumberFormat> = [
  {
    id: 1,
    balise: { text: true, value: 'F' },
  },
  {
    id: 2,
    balise: { text: true, value: 'FA' },
  },
  {
    id: 3,
    balise: { text: true, value: 'FACTURE' },
  },
];

export const estimatePrefixs: Array<NumberFormat> = [
  {
    id: 17,
    balise: { text: true, value: 'D' },
  },
  {
    id: 18,
    balise: { text: true, value: 'DEVIS' },
  },
];

export const assetPrefixs: Array<NumberFormat> = [
  {
    id: 19,
    balise: { text: true, value: 'A' },
  },
  {
    id: 20,
    balise: { text: true, value: 'AV' },
  },
  {
    id: 21,
    balise: { text: true, value: 'AVOIR' },
  },
];

export const advancePrefixs: Array<NumberFormat> = [
  {
    id: 22,
    balise: { text: true, value: 'FAC' },
  },
  {
    id: 23,
    balise: { text: true, value: 'AC' },
  },
  {
    id: 24,
    balise: { text: true, value: 'ACOMPTE' },
  },
];

export const purchaseOrderPrefixs: Array<NumberFormat> = [
  {
    id: 25,
    balise: { text: true, value: 'BDC' },
  },
  {
    id: 26,
    balise: { text: true, value: 'B' },
  },
  {
    id: 27,
    balise: { text: true, value: 'BON DE COMMANDE' },
  },
];

export const dates: Array<NumberFormat> = [
  {
    id: 4,
    balise: { date: true, format: 'DD-MM-YYYY' },
  },
  {
    id: 5,
    balise: { date: true, format: 'MM-YYYY' },
  },
  {
    id: 6,
    balise: { date: true, format: 'YYYY' },
  },
  {
    id: 7,
    balise: { date: true, format: 'YY' },
  },
  {
    id: 8,
    balise: { date: true, format: 'MM' },
  },
  {
    id: 9,
    balise: { date: true, format: 'DD' },
  },
];

export const counters: Array<NumberFormat> = [
  {
    id: 8,
    balise: { counter: true, size: 2 },
  },
  {
    id: 9,
    balise: { counter: true, size: 3 },
  },
  {
    id: 10,
    balise: { counter: true, size: 4 },
  },
  {
    id: 11,
    balise: { counter: true, size: 5 },
  },
];

export const separators: Array<NumberFormat> = [
  {
    id: 12,
    balise: { text: true, value: '-' },
  },
  {
    id: 13,
    balise: { text: true, value: '_' },
  },
  {
    id: 14,
    balise: { text: true, value: '.' },
  },
  {
    id: 15,
    balise: { text: true, value: ' ' },
  },
  {
    id: 16,
    balise: { text: true, value: '#' },
  },
];
