import { IErrorCodeUrssafTp } from '../Interface/models/IErrorCodeUrssafTp';
import { PaymentRequestStatusUrssafTp } from './PaymentRequestStatusUrssafTp';

export const ErrorCodeUrssafTp: Array<IErrorCodeUrssafTp> = [

  {
    code: PaymentRequestStatusUrssafTp.ERR_DATE_FUTUR,
    text: 'Erreur - Date future',
    detail: 'La date est dans le futur<br>'
        + 'Info complémentaire non transmise par API:<br>'
        + 'Erreur si la période d’emploi de la Demande de Paiement est dans le futur',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_DATE_PAIEMENT_AVANT_EXP,
    text: 'Erreur - Date paiement antérieure à l\'expérimentation',
    detail: 'La date de paiement est antérieure à la date de lancement de l’expérimentation\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Erreur si la période d’emploi de la Demande de Paiement est antérieure à la date de lancement de l’expérimentation',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_PERIODE_EMPLOI_MOIS_NON_UNIQUE,
    text: 'Erreur - Période d\'emploi mois non unique',
    detail: 'La période d’emploi de la demande de paiement est sur deux mois calendaires',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_DATE_FIN_AVANT_DATE_DEB,
    text: 'Erreur - Date fin avat date début',
    detail: 'La date de fin de période est inférieure à la date de début de période d’emploi',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_MONTANT_ACOMPTE,
    text: 'Erreur - Montant acompte',
    detail: 'Le montant de l’acompte supérieur au montant total de la facture',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_TOTAL_PRESTATIONS,
    text: 'Erreur - Montant total prestations',
    detail: 'Le montant total des listes de prestation est différent du montant total de la facture\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Erreur si le montant de la somme des listes de prestation est différent (modulo une tolérance liée aux arrondis) du '
        + 'montant total de la facture',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CODE_NATURE,
    text: 'Erreur - Code nature inconnu',
    detail: 'Code nature inexistant\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Le code nature doit être connu du référentiel des natures',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CODE_ACTIVITE,
    text: 'Erreur - Code activité inconnu',
    detail: 'Code activité inexistant\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Le code activité doit être connu du référentiel des activités',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CODE_ACTIVITE_NATURE,
    text: 'Erreur - Code activité nature',
    detail: 'Le code activité n’est pas rattaché à cette nature',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_MNT_PREST_HT_TVA,
    text: 'Erreur - Montant prestation HT / TVA pas correct',
    detail: 'Le montant prestation HT ou TVA n’est pas correct\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Le montant prestation HT + montant prestation TVA =\n'
        + 'montant prestation TTC (modulo une tolérance liée aux\n'
        + 'arrondis)',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_MNT_PREST_TTC,
    text: 'Erreur - Montant TTC pas égal',
    detail: 'Le montant prestation TTC n’est pas égal à la quantité * montant unitaire\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Contrôler le montant de prestation TTC = quantité * montant unitaire (modulo une tolérance liée aux arrondis)',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_NBRE_PREST_MAX,
    text: 'Erreur - Nombre max prestations',
    detail: 'Le nombre maximum de transmission de factures a été dépassé\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Ne dois pas dépasser la valeur max définie par paramètre. (intialisé à 10)',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_VALEUR_NEGATIVE,
    text: 'La valeur d\'un champ est négative',
    detail: 'La valeur d\'un champ est négative alors qu\'elle doit être positive',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_PARTICULIER_INCONNU,
    text: 'Erreur - Particulier inconnu',
    detail: 'L\'identifiant du particulier n’est pas reconnu ou la date de\n'
        + 'naissance ne correspond pas à celle connue',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_PRESTATAIRE_EMETTEUR_INCONNU,
    text: 'Erreur - Tiers de prestation inconnu',
    detail: 'Seconde vérification de l’identifiant du tiers de prestation\n'
        + 'qui doit ête connu et actif. ',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_LIEN_PARTICULIER_PRESTATAIRE,
    text: 'Erreur - Lien particulier / prestataire inconnu',
    detail: 'Il n’existe aucun lien entre le particulier et le prestataire sur la période d’emploi de la demande de paiement\n'
        + 'Info complémentaire non transmise par API:\n'
        + 'Veuillez vérifier que le particulier a bien activé son compte\n'
        + 'et que la date/heure de début de la période d\'emploi est\n'
        + 'bien postérieure ou égale à la date/heure d\'activation de\n'
        + 'l\'inscription du particulier',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_PRESTATAIRE_FACTURATION_INCONNU,
    text: 'Erreur - Tiers de prestation facturé inconnu',
    detail: 'L’identifiant du tiers de prestation facturé doit être connu de l’annuaire (et être actif)',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON,
    text: 'Erreur - Référence de facture déjà existante',
    detail: 'Si le numéro de facture du prestataire a déjà une demande\n'
        + 'de paiement enregistrée, la nouvelle demande de\n'
        + 'paiement sera rejetée.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CRITERE_RECHERCHE_VIDE,
    text: 'Erreur - Aucun critère de recherche renseigné.',
    detail: 'Un critère de recherche doit être renseigné ',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_DGFIP_R2P_INCONNU,
    text: 'Erreur - Identification DGFIP non abouti',
    detail: 'Aucun SPI ne correspond à la personne après l\'appel à l\'API\n'
        + 'DGFiP R2P, vérifier l’état civil communiqué',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_DATE_NAISSANCE_FUTUR,
    text: 'Erreur - Date de naissance dans le futur',
    detail: 'La date de naissance est dans le futur',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_LIEU_NAISSANCE_DEPT,
    text: 'Erreur - Département non renseigné',
    detail: 'Le Code INSEE du département de naissance est\n'
        + 'obligatoire quand le code Pays de naissance correspond à\n'
        + 'celui de la France.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_LIEU_NAISSANCE_COMN,
    text: 'Erreur - Commune non renseignée',
    detail: 'Le Code INSEE de la commune de naissance est obligatoire\n'
        + 'quand le code Pays de naissance correspond à celui de la France',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_LIEU_NAISSANCE_PAYS,
    text: 'Erreur - Département / commune renseigné',
    detail: 'Le Code INSEE de la commune de naissance ou du\n'
        + 'département ne doivent pas être renseigné quand le code\n'
        + 'Pays n\'est pas la France',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_DGFIP_IMPOTPART_INCONNU,
    text: 'Erreur - Aucune période taxée',
    detail: 'Aucune période taxée n’est retournée pour le particulier\n'
        + 'après \'appel à l\'API DGFiP ImpôtPart. Le particulier ne peut\n'
        + 'pas avoir accès à l’avance immédiate de crédit d’impôt\n'
        + 'pour la période. Réessayer après le 1er août de la même\n'
        + 'année ou après le 1er janvier de l’année suivante.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT,
    text: 'Erreur - Contact',
    detail: 'Des informations concernant le téléphone, mail ou adresse\n'
        + 'ne sont pas complètes ou invalides.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT_ADRESSE_TYPE_VOIE,
    text: 'Erreur - Type de voie contact introuvable',
    detail: 'Le type de voie renseigné est introuvable.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT_ADRESSE_LETTRE_VOIE,
    text: 'Erreur - Lettre de voie contact introuvable',
    detail: 'La Lettre de voie renseignée est introuvable.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT_ADRESSE_TYPEVOIE,
    text: 'Erreur - Type de voie contact introuvable',
    detail: 'Un type de voie doit être sélectionné si un libellé est renseigné.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT_ADRESSE_LIBELLEVOIE,
    text: 'Erreur - Libellé voie contact introuvable',
    detail: 'Un libellé doit être renseigné si un type de voie est sélectionné.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT_ADRESSE_INFORMATION_SUPPLEMENTAIRE,
    text: 'Erreur - Informations contact incomplètes',
    detail: 'Le type de voie et le libellé ou le complément d\'adresse ou\n'
        + 'le lieu dit doit être renseigné.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_CONTACT_ADRESSE_COMMUNE,
    text: 'Erreur - Commune contact introuvable',
    detail: 'La commune renseignée est introuvable.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_COORDONNEES_BANCAIRES,
    text: 'Erreur - BIC ou IBAN invalide',
    detail: 'Le bic ou l\'iban est invalide.',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_COMPTE_BLOQUE,
    text: 'Erreur - Compte indisponible',
    detail: 'Compte indisponible.\n'
        + 'Le compte du particulier a été bloqué suite à un retour en\n'
        + 'impayé. L’action souhaitée (création d’une demande de\n'
        + 'paeiement ou inscription) ne pourra pas aboutir. ',
  },

  {
    code: PaymentRequestStatusUrssafTp.ERR_FORMAT_VALEUR,
    text: 'Erreur - Format de la valeur',
    detail: 'Vous avez utilisé des montants avec des virgules.\n'
      + 'Cela provoque un problème d\'arrondi du côté de l\'Urssaf.\n'
      + 'Veuillez contacter le support Abby\n',
  },

];
