import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { ReadBankList } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface BankListState {
  bankList: ReadBankList[]
}

export const state = (): BankListState => ({
  bankList: [],
})

export const getters: GetterTree<BankListState, RootState> = {
  bankList: (state: BankListState) => state.bankList,
}

export const mutations: MutationTree<BankListState> = {
  SET_BANK_LIST (state, values: ReadBankList[]) {
    state.bankList = values
  },
}

export const actions: ActionTree<BankListState, RootState> = {
  async getAllBanks ({ commit }) {
    try {
      const data = await this.$api.bankList.getAll()
      commit('SET_BANK_LIST', data)
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
}
