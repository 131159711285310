import { ActionTree, GetterTree } from 'vuex'
import type { ICustomer, ReadCustomer } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export enum CustomerDetailsTabs {
  STATISTIC,
  OPPORTUNITY,
  INFORMATION,
}

export type CustomerDetailsPanelConfig = {
  focusOnNote?: boolean
}

export interface CustomerDetailsState {
  customer: ICustomer | null;
  openedCustomerDetails: Array<{ customer: ReadCustomer, config?: CustomerDetailsPanelConfig }>
}

export const state = (): CustomerDetailsState => ({
  customer: null,
  openedCustomerDetails: [],
})

export const getters: GetterTree<CustomerDetailsState, RootState> = {
  openedCustomerDetails: state => state.openedCustomerDetails,
  customer: state => state.customer,
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.openedCustomerDetails.slice(-1)?.[0]?.customer._id === id,
}

export const mutations = {
  SET_OPENED_CUSTOMERS (state: CustomerDetailsState, payload: Array<{ customer: ReadCustomer, config?: CustomerDetailsPanelConfig }>) {
    state.openedCustomerDetails = payload
  },
  SPLICE_OPENED_CUSTOMERS (state: CustomerDetailsState, index: number) {
    state.openedCustomerDetails.splice(index)
  },
  RESET (_currentState: CustomerDetailsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  openCustomerDetails ({ commit, getters }, { customer, config } : { customer: ReadCustomer, config?: CustomerDetailsPanelConfig }) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(customer._id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('SET_OPENED_CUSTOMERS', [
      ...getters.openedCustomerDetails,
      { customer, config },
    ])
  },
  cleanCustomerDetailsConfig ({ commit, getters }) {
    commit('SET_OPENED_CUSTOMERS', getters.openedCustomerDetails.map(({ customer }: { customer: ReadCustomer }) => ({ customer })))
  },
  closeCustomerDetails ({ commit }, index: number) {
    commit('SPLICE_OPENED_CUSTOMERS', index)
  },
}
