import { PaymentMethod } from '../enums/paymentMethod.enum';

enum PaymentMethodLegacy {
  TRANSFER = 1,
  DIRECT_DEBIT = 2,
  CREDIT_CARD = 3,
  CHEQUE = 4,
  UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE = 5,
  CASH = 6,
  PAYPAL = 7,
  OTHER = 8,
  STRIPE = 9,
}

export const mapPaymentMethodFromLegacy = {
  [PaymentMethodLegacy.TRANSFER]: PaymentMethod.TRANSFER,
  [PaymentMethodLegacy.DIRECT_DEBIT]: PaymentMethod.DIRECT_DEBIT,
  [PaymentMethodLegacy.CREDIT_CARD]: PaymentMethod.CREDIT_CARD,
  [PaymentMethodLegacy.CHEQUE]: PaymentMethod.CHEQUE,
  [PaymentMethodLegacy.UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE]: PaymentMethod.UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE,
  [PaymentMethodLegacy.CASH]: PaymentMethod.CASH,
  [PaymentMethodLegacy.PAYPAL]: PaymentMethod.PAYPAL,
  [PaymentMethodLegacy.STRIPE]: PaymentMethod.STRIPE,
  [PaymentMethodLegacy.OTHER]: PaymentMethod.OTHER,
};

export const mapPaymentMethodToLegacyPaymentMethod = {
  [PaymentMethod.TRANSFER]: PaymentMethodLegacy.TRANSFER,
  [PaymentMethod.DIRECT_DEBIT]: PaymentMethodLegacy.DIRECT_DEBIT,
  [PaymentMethod.CREDIT_CARD]: PaymentMethodLegacy.CREDIT_CARD,
  [PaymentMethod.CHEQUE]: PaymentMethodLegacy.CHEQUE,
  [PaymentMethod.UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE]: PaymentMethodLegacy.UNIVERSAL_EMPLOYMENT_SERVICE_CHEQUE,
  [PaymentMethod.CASH]: PaymentMethodLegacy.CASH,
  [PaymentMethod.PAYPAL]: PaymentMethodLegacy.PAYPAL,
  [PaymentMethod.STRIPE]: PaymentMethodLegacy.STRIPE,
  [PaymentMethod.OTHER]: PaymentMethodLegacy.OTHER,
};
