import { SubscriptionProductType } from '../../enums';
import { Resource } from '../Resource';

export class Subscription extends Resource {
  cancel(product: SubscriptionProductType) {
    return this.http.$post('/subscription/unsubscribe', {
      product,
    });
  }
}
