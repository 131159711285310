import { GetterTree, ActionTree, MutationTree } from 'vuex'

export interface AdministrationState {
  company: any | null;
}

export const state = (): AdministrationState => ({
  company: null,
})

export const getters: GetterTree<AdministrationState, AdministrationState> = {
  company: state => state.company,
}

export const mutations: MutationTree<AdministrationState> = {
  SET_COMPANY (state, company: any) {
    state.company = company
  },
  RESET (_currentState: AdministrationState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<AdministrationState, AdministrationState> = {
  fetchCompany ({ commit }) {
    // const company = await this.$api.company.get()
    commit('SET_COMPANY', {})
  },
}
