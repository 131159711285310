import { Recurrence } from '../types';

const dayjs = require('dayjs');

export function getTextMonth(value: number): string {
  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
    'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre',
  ];
  return months[value];
}

const recurrenceToDayJs = {
  [Recurrence.MONTH]: 'month',
  [Recurrence.QUARTER]: 'quarter',
  [Recurrence.YEAR]: 'year',
};

export function statisticPeriod(recurrence: Recurrence, start: Date, end: Date): Array<{
  start: Date,
  end: Date
}> {
  const endPeriod = dayjs(end).endOf(recurrenceToDayJs[recurrence]);

  let cursor = dayjs(start).startOf(recurrenceToDayJs[recurrence]);

  const periods = [];

  while (cursor <= endPeriod) {
    periods.push({
      start: cursor.toDate(),
      end: cursor.endOf(recurrenceToDayJs[recurrence]).toDate(),
    });
    cursor = cursor.add(1, recurrenceToDayJs[recurrence]);
  }
  return periods;
}

export function declarationPeriod(recurrence: Recurrence): Array<{
  isNext: boolean,
  isCurrent: boolean,
  start: Date,
  end: Date,
  declarationLimit: Date,
  recurrence: string,
  frequency: number,
}> {
  const _recurrence = recurrence === Recurrence.MONTH ? 'month' : 'quarter';
  // todo on doit ajouter x jours en fonction de ou le type se situe
  const numberOfDayToAdd = 15;

  // si la limite de déclaration est entre les deux bornes ou égale à la borne de fin (currentEnd) des dates , cela veut dire que la déclaration actuelle est celle du mois d'après
  const _tmpDeclarationLimit = dayjs().startOf(_recurrence).add(numberOfDayToAdd, 'day');

  let currentPeriodIsMonthBefore = false;
  if (dayjs().startOf(_recurrence) <= _tmpDeclarationLimit && _tmpDeclarationLimit <= dayjs().endOf(_recurrence)) currentPeriodIsMonthBefore = true;

  const currentStartPeriod = dayjs().subtract(currentPeriodIsMonthBefore ? 1 : 0, _recurrence).startOf(_recurrence).toISOString();
  const currentEndPeriod = dayjs().subtract(currentPeriodIsMonthBefore ? 1 : 0, _recurrence).endOf(_recurrence).toISOString();
  const currentDeclarationLimit = dayjs().startOf(_recurrence).add(numberOfDayToAdd, 'day').toISOString();

  const nextStartPeriod = dayjs().add(currentPeriodIsMonthBefore ? 0 : 1, _recurrence).startOf(_recurrence).toISOString();
  const nextEndPeriod = dayjs().add(currentPeriodIsMonthBefore ? 0 : 1, _recurrence).endOf(_recurrence).toISOString();
  const nextDeclarationLimit = dayjs()
    .add(currentPeriodIsMonthBefore ? 0 : 1, _recurrence)
    .startOf(_recurrence)
    .add(numberOfDayToAdd, 'day')
    .toISOString();

  return [{
    // déclaration en cours
    isCurrent: true,
    isNext: false,
    start: currentStartPeriod,
    end: currentEndPeriod,
    declarationLimit: currentDeclarationLimit,
    recurrence: _recurrence,
    frequency: dayjs().subtract(currentPeriodIsMonthBefore ? 1 : 0, _recurrence).startOf(_recurrence).get(_recurrence),
  }, {
    // prochaine déclaration
    isNext: true,
    isCurrent: false,
    start: nextStartPeriod,
    end: nextEndPeriod,
    declarationLimit: nextDeclarationLimit,
    recurrence: _recurrence,
    frequency: dayjs().subtract(currentPeriodIsMonthBefore ? 1 : 0, _recurrence).add(1, _recurrence).startOf(_recurrence)
      .get(_recurrence),
  }];
}
