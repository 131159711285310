import { OrganizationContactForm } from '~/logic/contexts/client/domain/form/organizationContact.form'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export const useCreateOrganizationContact = () => {
  const organizationRepository = useOrganizationRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (organizationId: string, contact: OrganizationContactForm) => {
    const newContact = await organizationRepository.createContact({ organizationId, payload: contact })
    alertManager.success("Le contact d'organisation a bien été créé")
    busManager.emit('organizationContactCreated', newContact)
    return newContact
  }
}
