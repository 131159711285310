import type { CreateWebhook, ReadWebhook, UpdateWebhook } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Webhook extends Resource {
  getAll (): Promise<ReadWebhook[]> {
    return this.axios.$get('/webhooks/brand')
  }

  create (payload: CreateWebhook) {
    return this.axios.$post('/webhook/brand', payload)
  }

  get (id: string): Promise<ReadWebhook> {
    return this.axios.$get(`/webhook/brand/${id}`)
  }

  delete (id: string): Promise<void> {
    return this.axios.$delete(`/webhook/brand/${id}`)
  }

  update (id: string, payload: UpdateWebhook): Promise<ReadWebhook> {
    return this.axios.$put(`/webhook/brand/${id}`, payload)
  }
}
