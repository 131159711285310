export enum ErrorEstimations {
  'CPTE_INCONNU' = 'CPTE_INCONNU',
  'MDT_INCONNU' = 'MDT_INCONNU',
  'ERR_TECHNIQUE' = 'ERR_TECHNIQUE',
  'PARAM_INVALIDE' = 'PARAM_INVALIDE',
  'MDT_HORS_PERIODE' = 'MDT_HORS_PERIODE',
}

export enum ErrorIdentification {
  PARAM_INVALIDE = 'PARAM_INVALIDE',
  CPTE_INCONNU = 'CPTE_INCONNU',
  ABN_INCONNU = 'ABN_INCONNU',
  ERR_TECHNIQUE = 'ERR_TECHNIQUE',
}
